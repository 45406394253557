import * as React from "react";

import { BulkyActionPicker, IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { Parcel } from "../../../model/parcel";
import { ShipmentParcelActionType } from "../../../constants/constants";
import { observer } from "mobx-react";
import { Permission } from "../../../../context/permission";
import { operationContext } from "../../../../context/operation-context";

interface IParcelShippedHeaderProps {
    actionsList: Array<IActionCommand<Parcel, ShipmentParcelActionType>>;
    reloadParcelsList: () => Promise<void>;
}

@observer
export class ParcelShippedHeader extends React.Component<IParcelShippedHeaderProps, {}> {

    public render() {
        return <div className="list-actions-container">
            <div className="list-actions">
                <BulkyActionPicker withScanMode={true}
                                   propertyKey={operationContext.operationCodeOrEmpty}
                                   scanFieldType="text"
                                   inputLabel=""
                                   contentPrefix="parcelShipped.action"
                                   actions={this.props.actionsList}
                                   permission={Permission.FrontWrite} />
            </div>
        </div>;
    }
}

import React, { ReactNode } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography, List, ListItem } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { t } from "i18next";
import { PageRouteType, SectionRouteType } from "../../../front-office/constants/constants";
import { PanelMenuType } from "../../../constants/constants";
import { IPanelMenuItem } from "./vp-menu";
import { useVpMenuContext } from "./vp-menu-context";

export interface IVpMenuCategoryProps {
    children: ReactNode;
    itemPanel: IPanelMenuItem<SectionRouteType, PageRouteType>;
}

export const VpMenuCategory = (props: IVpMenuCategoryProps) => {
    const { expandedPanel, setExpandedPanel, isExpandedEnabled } = useVpMenuContext();

    const handlePanelChange = (expanded: boolean, panel: PanelMenuType) => {
        setExpandedPanel(expanded ? panel : undefined);
    };

    return <ListItem data-testid={`menu-section-${props.itemPanel.key.toLowerCase()}`} key={props.itemPanel.key.toLowerCase()} className="level1">
        <Accordion className="expansionPanel"
                          elevation={0}
                          expanded={!isExpandedEnabled || expandedPanel === props.itemPanel.key}
                          onChange={(_event, expanded) => {
                              if (isExpandedEnabled) {
                                  handlePanelChange(expanded, props.itemPanel.key);
                              }
                          }}>
            {isExpandedEnabled &&
                <AccordionSummary expandIcon={<ExpandMore />} classes={{expandIconWrapper: "expansion-panel__expand-icon"}} className="expansionPanelSummary">
                    <Typography variant="h6" className="summaryTitle">{t(props.itemPanel.label)}</Typography>
                </AccordionSummary>}
            <AccordionDetails className="expansionPanelDetail">
                <List>
                    {props.children}
                </List>
            </AccordionDetails>
        </Accordion>
    </ListItem>;
};

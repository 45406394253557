import React, { useState } from "react";
import { Parcel } from "../../../model/parcel";
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { t } from "i18next";
import { ScanFieldMultiple } from "../../../../common/component/scan-field-multple/scan-field-multiple";

interface ILabelAndAssignScanParcelsProps {
    getParcelItemByScan: (parcelId: string) => Parcel | undefined;

    onCancel: () => void;
    onFinished: (parcelList: Parcel[]) => void;
}

export const LabelAndAssignScanParcels = (props: ILabelAndAssignScanParcelsProps) => {
    const [scannedParcelsList, setScannedParcelsList] = useState<Parcel[]>([]);

    const handleItemScanned = (scanValue: string) => {
        const item = props.getParcelItemByScan(scanValue);

        if (item === undefined) {
            throw new Error(`Parcel ${scanValue} was not found`);
        }

        setScannedParcelsList(s => [...s, item]);
    };

    return <>
        <DialogTitle>
            {(t("components.parcel.labelAndAssign.title"))}
        </DialogTitle>
        <DialogContent>
            <Typography className={"label-and-assign__description"}>
                {t("components.parcel.labelAndAssign.descriptionParcelScan")}
            </Typography>
            <ScanFieldMultiple
                autoFocus
                label={t("model.parcel.parcelId")}
                onScan={scanValue => handleItemScanned(scanValue)} />
        </DialogContent>
        <DialogActions>
            <Button variant="outlined"
                    type="submit"
                    key="cancel-btn"
                    onClick={async () => props.onCancel()}>
                {t("components.popin.cancel")}
            </Button>
            <Button color="secondary"
                    variant="contained"
                    type="submit"
                    key="submit-btn"
                    disabled={scannedParcelsList.length === 0}
                    onClick={async () => props.onFinished(scannedParcelsList)}>
                {t("components.parcel.labelAndAssign.continueConfigureDocument")}
            </Button>
        </DialogActions>
    </>;
};

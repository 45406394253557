import React from "react";

import { observer } from "mobx-react";
import { Button } from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { palletPickupFormDialogModel } from "./pallet-pickup-form-dialog-model";

export const PalletPickupFormWidget = observer(() => {
    return <Button onClick={() => palletPickupFormDialogModel.openDialog()}
                   data-testid={"pallet-schedule-icon"}
                   variant={"outlined"}
                   size={"large"}>
        <DateRange />
    </Button>;
});

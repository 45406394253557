import { computed } from "mobx";
import { DocumentFormat } from "../document-printer";

export interface IParcelDocument {
    documentFormat: DocumentFormat;
    contents: File | undefined;
}

export class ParcelDocument implements IParcelDocument {
    private readonly _parcelId: number;
    private readonly _documentFormat: DocumentFormat;
    private readonly _contents: File | undefined;

    constructor(parcelId: number, documentFormat: DocumentFormat, contents: File | undefined) {
        this._parcelId = parcelId;
        this._documentFormat = documentFormat;
        this._contents = contents;
    }

    public get parcelId(): number {
        return this._parcelId;
    }

    public get contents(): File | undefined {
        return this._contents;
    }

    get documentFormat(): DocumentFormat {
        return this._documentFormat;
    }

    @computed
    public get hasContents(): boolean {
        return this._contents !== undefined;
    }
}

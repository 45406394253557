import * as Excel from "exceljs";
import { ValueType, CellRichTextValue, CellHyperlinkValue } from "exceljs";
import moment from "moment";

export class ImportData {
    public async readUploadedFile(inputFile: File): Promise<Array<Map<string, string>>> {
        const fileReader = new FileReader();

        return await new Promise(resolve => {
            fileReader.onload = async () => {
                const workbook = new Excel.Workbook();
                const validatedWorksheets = (await workbook.xlsx.load(fileReader.result as ArrayBuffer))
                    .worksheets
                    .filter(data => data !== undefined);

                resolve(validatedWorksheets.length > 0 && validatedWorksheets[0] !== undefined ?
                    this.readWorksheet(validatedWorksheets[0]) : []);
            };
            fileReader.readAsArrayBuffer(inputFile);
        });
    }

    private readWorksheet(worksheet: Excel.Worksheet): Array<Map<string, string>> {
        const header = this.getHeadersFromWorksheet(worksheet);
        const output: Array<Map<string, string>> = [];
        let rowData: string[];

        for (let r = 2; r <= worksheet.actualRowCount; r++) {
            rowData = [];
            worksheet.getRow(r).eachCell({ includeEmpty: true }, c => {
                if (c.type === ValueType.Formula) {
                    rowData.push(c.result.toString() ?? "");
                } else if (c.type === ValueType.Date) {
                    rowData.push(moment(c.value as Date).toISOString());
                } else if (c.type === ValueType.Hyperlink) {
                    const hyperLinkValue = ((c.value as CellHyperlinkValue).text) as any;
                    if (hyperLinkValue.richText) {
                        rowData.push(hyperLinkValue.richText.map((t: {text: string}) => t.text).join(""));
                    } else {
                        rowData.push(hyperLinkValue as string);
                    }
                } else if (c.type === ValueType.RichText) {
                    rowData.push((c.value as CellRichTextValue).richText.join(","));
                } else {
                    rowData.push(c.text);
                }
            });

            output.push(this.createDataRow(rowData, header));
        }

        return output;
    }

    private createDataRow(rowData: string[], header: string[]): Map<string, string> {
        const output: Map<string, string> = new Map<string, string>();
        header.forEach((column, index) => {
            output.set(column, rowData[index] !== undefined ? rowData[index] : "");
        });

        return output;
    }

    private getHeadersFromWorksheet(worksheet: Excel.Worksheet) {
        const output: string[] = new Array(worksheet.columnCount);
        worksheet.getRow(1).eachCell((cell, colNumber) => output[colNumber - 1] = cell.text);

        return output.map(value => value !== "" ? value : "__EMPTY");
    }
}

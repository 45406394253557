export interface IPalletShippingParcelDependency {
    operationCode: string;
    batchId: number;
    parcelId: number;
}

export class PalletShippingParcelDependency {
    private readonly _operationCode: string;
    private readonly _parcelId: number;

    constructor(data: IPalletShippingParcelDependency) {
        this._operationCode = data.operationCode;
        this._parcelId = data.parcelId;
    }

    get operationCode(): string {
        return this._operationCode;
    }

    get parcelId(): number {
        return this._parcelId;
    }

    get label() {
        return `${this.parcelId} (${this.operationCode})`;
    }
}

import { AreaDetail } from "./area-detail";
import { Parcel } from "../../../model/parcel";
import { DeliveryOrder } from "../../../model/delivery-order";
import { computed } from "mobx";
import { sumBy } from "lodash";
import { identifier } from "../../../../domain/identifier";
import { domain, label } from "../../../../common/dto/dto-annotation";
import { weight } from "../../../../domain/weight";
import { label255, label50 } from "../../../../domain/label";

export class Area {
    private readonly _operationCode: string;
    private readonly _batchId: number;
    private readonly _areaNumber: number;
    private readonly _parcelId: number;
    private readonly _orderId: number;
    private readonly _deliveryOrderId: number;
    private readonly _carrier: string;
    private readonly _address: string;
    private readonly _details: AreaDetail[] = [];

    constructor(
        operationCode: string,
        batchId: number,
        areaNumber: number,
        parcelId: number,
        orderId: number,
        deliveryOrderId: number,
        carrier: string,
        address: string,
        details: AreaDetail[],
    ) {
        this._operationCode = operationCode;
        this._batchId = batchId;
        this._areaNumber = areaNumber;
        this._parcelId = parcelId;
        this._orderId = orderId;
        this._deliveryOrderId = deliveryOrderId;
        this._carrier = carrier;
        this._address = address;
        this._details = details;
    }

    public static createForParcel(parcel: Parcel, deliveryOrder: DeliveryOrder) {
        const parcelDetails = parcel.details.map(parcelDetail => {
            const product = deliveryOrder.details.find(orderDetail => orderDetail.productId === parcelDetail.productId);

            if (product === undefined) {
                throw new Error("Product was not found in this order.");
            }

            return AreaDetail.createFromParcelDetail(parcelDetail, product);
        });

        const orderDetails = deliveryOrder.details
            .filter(orderDetail => orderDetail.quantityParcelled === 0)
            .map(orderDetail => AreaDetail.createFromOrderDetail(orderDetail));

        return new Area(
            deliveryOrder.operationCode,
            deliveryOrder.batchId,
            parcel.area ?? 1,
            parcel.id,
            parcel.orderId,
            parcel.deliveryOrderId,
            parcel.carrier,
            parcel.address,
            [...parcelDetails, ...orderDetails],
        );
    }

    get operationCode(): string {
        return this._operationCode;
    }

    get batchId(): number {
        return this._batchId;
    }

    get areaNumber(): number {
        return this._areaNumber;
    }

    @label("model.area.parcelId")
    @domain(identifier)
    get parcelId(): number {
        return this._parcelId;
    }

    @label("model.area.orderId")
    @domain(identifier)
    get orderId(): number {
        return this._orderId;
    }

    @label("model.area.deliveryOrderId")
    @domain(identifier)
    get deliveryOrderId(): number {
        return this._deliveryOrderId;
    }

    @label("model.area.carrier")
    @domain(label50)
    get carrier(): string {
        return this._carrier;
    }

    @label("model.area.address")
    @domain(label255)
    get address(): string {
        return this._address;
    }

    get details(): AreaDetail[] {
        return this._details;
    }

    @computed
    @label("model.area.weight")
    @domain(weight)
    get weight(): number {
        return sumBy(this.details, "productWeight");
    }

    @computed
    @label("model.area.detailsQuantity")
    get detailsQuantity(): number {
        return sumBy(this.details, "parcelledQuantity");
    }

    @computed
    get isFulfilled() {
        return this._details.every(d => d.availableQuantity === 0);
    }
}

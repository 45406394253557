import { ParcelDetail } from "../../../model/parcel-detail";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { domain } from "../../../../common/dto/dto-annotation";
import { quantity } from "../../../../domain/quantity";
import { weight } from "../../../../domain/weight";
import { action, observable } from "mobx";

export class AreaDetail {
    private readonly _productId: number;
    private readonly _eansList: string[];
    private readonly _supplierReference: string;
    private readonly _labelReference: string;
    private readonly _parcelledQuantity: number;
    @observable private _availableQuantity: number;
    @observable private _stockoutQuantity: number;
    private readonly _productWeight: number;

    constructor(
        productId: number,
        eansList: string[],
        supplierReference: string,
        labelReference: string,
        parcelledQuantity: number,
        availableQuantity: number,
        stockoutQuantity: number,
        productWeight: number,
    ) {
        this._productId = productId;
        this._eansList = eansList;
        this._supplierReference = supplierReference;
        this._labelReference = labelReference;
        this._parcelledQuantity = parcelledQuantity;
        this._availableQuantity = availableQuantity;
        this._stockoutQuantity = stockoutQuantity;
        this._productWeight = productWeight;
    }

    public static createFromParcelDetail(parcelDetail: ParcelDetail, orderDetail: DeliveryOrderDetail) {
        return new AreaDetail(
            parcelDetail.productId,
            parcelDetail.ean13List,
            parcelDetail.supplierReference,
            parcelDetail.labelReference,
            parcelDetail.quantity,
            orderDetail.quantityAvailable,
            orderDetail.quantityStockout,
            parcelDetail.weight,
        );
    }

    public static createFromOrderDetail(orderDetail: DeliveryOrderDetail) {
        return new AreaDetail(
            orderDetail.productId,
            orderDetail.ean13List,
            orderDetail.supplierReference,
            orderDetail.labelReference,
            orderDetail.quantityParcelled,
            orderDetail.quantityAvailable,
            orderDetail.quantityStockout,
            orderDetail.weight,
        );
    }

    get productId(): number {
        return this._productId;
    }

    get eansList(): string[] {
        return this._eansList;
    }

    get supplierReference(): string {
        return this._supplierReference;
    }

    get labelReference(): string {
        return this._labelReference;
    }

    @domain(quantity)
    get parcelledQuantity(): number {
        return this._parcelledQuantity;
    }

    @domain(quantity)
    get availableQuantity(): number {
        return this._availableQuantity;
    }

    @domain(quantity)
    get stockoutQuantity(): number {
        return this._stockoutQuantity;
    }

    @action
    public stockoutRemainingQuantity() {
        this._stockoutQuantity = this._availableQuantity;
        this._availableQuantity = 0;
    }

    @domain(weight)
    get productWeight(): number {
        return this._productWeight;
    }
}

import * as React from "react";
import { Dialog, DialogContent } from "@mui/material";

interface IPdfViewerDialogProps {
    link: string;
    onClose: () => void;
}

export const PdfViewerDialog = (props: IPdfViewerDialogProps) => {
    return (
        <Dialog maxWidth={"xl"} onClose={props.onClose} open={true}>
            <DialogContent className={"dialog__content"}>
                <div>
                    <embed src={props.link} type="application/pdf" width={window.innerWidth * 0.8} height={window.innerHeight}/>
                </div>
            </DialogContent>
        </Dialog>
    );
};

import { IActionCommand } from "./bulky-action-picker";

import { DialogMessageIcon, dialogModel } from "../../dialog/dialog-model";
import { IParamActionValidator } from "./interface-param-action-validator";
import { ParamActionValidationStatus } from "./param-action-validation-result";

import { loaderModel } from "../../loader/loader-model";
import { notificationModel } from "../../notifications/notification-model";
import { t } from "i18next";

export class BulkyActionPickerHandler {
    private readonly _action: IActionCommand<any, any>;

    public constructor(action: IActionCommand<any, any>) {
        this._action = action;
    }

    public async execute() {
        const params = this._action.loadExecuteParam(false);

        if (params.length === 0) {
            notificationModel.addErrorMessage(t("components.selectAction.noElementSelected"));
        } else {
            if (this._action.paramValidator === undefined) {
                await this.processSelectedAction(params, !this._action.hideExecutionConfirmation);
            } else {
                await this.validateAndExecuteAction(this._action.paramValidator, params);
            }
        }
    }

    public async scan(scannedValue: any) {
        const params = this._action.loadExecuteParam(true, scannedValue);

        if (params.length === 0) {
            notificationModel.addErrorMessage(t("components.scanAction.noElementSelected"));
        } else {
            return await this._action.execute(params);
        }
    }

    private async processSelectedAction(params: any[], withConfirmationBox: boolean) {
        if (withConfirmationBox) {
            dialogModel.confirmBox(
                t(this._action.label),
                t(this._action.description || "components.selectAction.confirm", {
                    action: t(this._action.label),
                    nbElement: params.length,
                }),
                async () => await this.executeAction(params));
        } else {
            await this.executeAction(params);
        }
    }

    private async validateAndExecuteAction(validator: IParamActionValidator<any>, params: any[]) {
        const validationResult = validator.validate(params);

        if (validationResult.status === ParamActionValidationStatus.Error) {
            dialogModel.alert(
                t(this._action.label),
                validationResult.violationMessage,
                DialogMessageIcon.Error,
            );
        }

        if (validationResult.status === ParamActionValidationStatus.Success) {
            await this.processSelectedAction(params, !this._action.hideExecutionConfirmation);
        }

        if (validationResult.status === ParamActionValidationStatus.Warning) {
            await this.processSelectedActionWithWarning(params, validationResult.violationMessage);
        }
    }

    private async processSelectedActionWithWarning(params: any[], warningMessage: string) {
        dialogModel.confirmBox(
            t(this._action.label),
            warningMessage,
            async () => await this.executeAction(params),
            DialogMessageIcon.Warning);
    }

    private async executeAction(params: any[]) {
        if (this._action.hideActionProgressLoader) {
            this._action.execute!(params);
        } else {
            const actionId = loaderModel.foregroundActionStart(t("components.actionLoader.pendingAction", {
                actionName: t(this._action.label),
            }));

            try {
                await this._action.execute!(params);
            } finally {
                loaderModel.foregroundActionEnd(actionId);
            }

        }
    }
}

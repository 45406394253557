import { parcelsService } from "../../../services/parcels";
import { IParcelListDataProvider } from "./parcel-list-data-provider-interface";
import { ParcelStatusType } from "../../../constants/constants";

export class ParcelListDefaultDataProvider implements IParcelListDataProvider {
    private readonly _status: ParcelStatusType;

    constructor(status: ParcelStatusType) {
        this._status = status;
    }

    public async load(operationCode: string, batchId: number) {
        return await parcelsService.loadParcels(operationCode, batchId, this._status);
    }
}

import { Dictionary } from "lodash";
import { AdditionalValuesLocator } from "./additional-values-locator";

export class AdditionalValuesItem {
    private readonly _locators: AdditionalValuesLocator[];
    private readonly _valueMap: Dictionary<string>;

    public constructor(locators: AdditionalValuesLocator[], valueMap: Dictionary<string>) {
        this._locators = locators;
        this._valueMap = valueMap;
    }

    public get locators(): AdditionalValuesLocator[] {
        return this._locators;
    }

    public get valueMap(): Dictionary<string> {
        return this._valueMap;
    }
}

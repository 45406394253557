import { Menu } from "@mui/material";
import React, { ReactNode, ReactNodeArray } from "react";

import "./styles.scss";

interface IUserAccountWidgetMenuProps {
    anchor: HTMLElement;
    onClose: () => void;
    children: ReactNode | ReactNodeArray;
}

export const UserAccountWidgetMenu = (props: IUserAccountWidgetMenuProps) => {
    return <Menu open={true}
                 className={"user-account-menu"}
                 elevation={0}
                 anchorEl={props.anchor}
                 anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                 onClose={props.onClose}>
        {props.children}
    </Menu>;
};

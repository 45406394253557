import { observable, action, computed} from "mobx";
import { mapValuesToReadonlyArray } from "../../utils/array";
import { Guid } from "guid-typescript";

class LoaderModel {

    @observable private _backgroundActionMap: Map<string, string> = new Map<string, string>();
    @observable private _foregroundActionMap: Map<string, string> = new Map<string, string>();

    @computed
    public get hasBackgroundAction() {
        return this._backgroundActionMap.size > 0;
    }

    @computed
    public get hasForegroundAction() {
        return this._foregroundActionMap.size > 0;
    }

    @computed
    public get foregroundActionList() {
        return mapValuesToReadonlyArray(this._foregroundActionMap);
    }

    @action
    public backgroundActionStart(actionDescription: string): string {
        const id = Guid.raw();
        this._backgroundActionMap.set(id, actionDescription);

        return id;
    }

    @action
    public backgroundActionEnd(id: string) {
        this._backgroundActionMap.delete(id);
    }

    @action
    public foregroundActionStart(actionDescription: string): string {
        const id = Guid.raw();
        this._foregroundActionMap.set(id, actionDescription);

        return id;
    }

    @action
    public foregroundActionEnd(id: string) {
        this._foregroundActionMap.delete(id);
    }
}

export const loaderModel = new LoaderModel();

import { IBulkParcelUploadImportValidationConstraint } from "../bulk-parcel-upload-import-validator";
import { BulkParcelUploadDataReader } from "../bulk-parcel-upload-data-reader";
import { t } from "i18next";
import { context } from "../../../../../../context/context";
import { FeatureToggle } from "../../../../../../context/app-configuration";

export class BulkParcelUploadStockoutConstraint implements  IBulkParcelUploadImportValidationConstraint {
    public isEligible() {
        return context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS);
    }

    public validateHeader(headerColumns: string[]) {
        if (headerColumns.filter(c => c === BulkParcelUploadDataReader.columnNameQuantityStockout).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerQuantityStockoutMissing"));
        }

        return true;
    }

    public validateLine(lineNumber: number, content: Map<string, string>) {
        const quantityStockout = content.get(BulkParcelUploadDataReader.columnNameQuantityStockout);

        if (Number(quantityStockout).toString() !== quantityStockout) {
            throw new Error(t("bulkParcelUpload.error.contentQuantityStockoutNotNumber", {lineNumber}));
        }

        return true;
    }
}

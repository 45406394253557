import { A4AutoPrinterConfig, A4LandscapePrinterConfig, PrinterExtensionType } from "../../constants/constants";
import FileSave from "file-saver";
import { QzModel } from "./qz-model";
import RSVP from "rsvp";
import Sha256 from "../../../static/js/sha-256.min.js";
import { context } from "../../context/context";
import { httpGet } from "../../common/network/fetch";
import { loaderModel } from "../../common/component/loader/loader-model";
import { notificationModel } from "../../common/component/notifications/notification-model";
import qz from "qz-tray";
import { t } from "i18next";

export enum PageSettings {
    Default,
    A4WithAutoOrientation,
}

class QzManager {

    private qzModel = new QzModel();

    constructor() {
        (window as any).RSVP = RSVP;
        (window as any).Sha256 = Sha256;
    }

    /**
     * Get the certificate from server and set to Qz service
     */
    public qzSetCertificate() {
        qz.security.setCertificatePromise((resolve: any, reject: any) => {
            httpGet<string>({url: `/print/certificate`, versionApi: false}).then(resolve, reject);
        });
    }

    /**
     * Sign private key and set to Qz service
     */
    public qzSetSignature() {
        qz.security.setSignaturePromise((toSign: any) => {
            return (resolve: any, reject: any) => {
                httpGet<string>({url: `/print/signature/${toSign}`, versionApi: false}).then(resolve, reject);
            };
        });
    }

    /**
     * Initialize a new connection to Qz websocket.
     */
    public async qzConnect(): Promise<boolean> {
        this.qzModel.setStatus("Connecting");

        try {
            await qz.websocket.connect();
            this.qzModel.setStatus("Connected");
            notificationModel.addSuccessMessage("print.message.connect");
        } catch (error) {
            this.qzModel.setStatus("NotConnected");
            return false;
        }
        return true;
    }

    public qzHealthTimer() {
        this.qzInitialization();
        context.qzTimerId = setInterval(async () => await this.qzInitialization(), 60000);
    }

    /**
     * Initialize a new connection to Qz websocket.
     */
    public async qzInitialization() {
        if (!this.isActive()) {
            this.qzSetCertificate();
            this.qzSetSignature();
            return await this.qzConnect();
        }
        return true;
    }

    public async checkPrintConfig() {
        const actionId = loaderModel.foregroundActionStart(t("print.checkPrintValid"));

        const isQzStarted = !await this.qzInitialization();
        loaderModel.foregroundActionEnd(actionId);
        if (isQzStarted) {
            return t("error.print.qzNotConnected");
        }
        if (!context.settings.printerName) {
            return t("error.print.missingPrinterName");
        }
        return undefined;
    }

    /**
     * Disconnect active socket
     */
    public qzDisconnect(): Promise<any> {
        return qz.websocket.disconnect();
    }

    /**
     * Print or download file with Qz service depending on download settings.
     *
     * @param file file to print
     * @param printerType file type
     */
    public async qzPrintOrDownloadFile(file: File, printerType: PrinterExtensionType) {
        if (context.settings.isDownload) {
            return this.downloadFile(file);
        } else {
            return this.qzPrintFile(file, printerType, PageSettings.Default);
        }
    }

    /**
     * Downloads file.
     *
     * There is no reliable method to trigger download of blobs generated on the front-end and the FileSave library
     * has issues with printing multiple files at the same time, so we need to add setTimeout as workaround.
     * @see https://github.com/eligrey/FileSaver.js/issues/331
     *
     * @param {File} file
     * @returns {Promise<any>}
     */
    public async downloadFile(file: File) {
        FileSave.saveAs(file);
        return new Promise(resolve => setTimeout(resolve, 100));
    }

    /**
     * Prints file.
     *
     * @param {File} file
     * @param {PrinterExtensionType} printerType
     * @param {PageSettings?} pdfPageSettings
     */
    public async qzPrintFile(file: File, printerType: PrinterExtensionType, pdfPageSettings?: PageSettings) {
        if (printerType === "Pdf") {
            return this.qzPrintPdf(file, pdfPageSettings === undefined ? PageSettings.Default : pdfPageSettings);
        } else {
            return this.qzPrintRawFile(file);
        }
    }

    public async qzPrintPdf(file: File, pageSettings: PageSettings) {
        return await this.qzPrintPdfOnPrinter(file, context.settings.printerName, pageSettings);
    }

    public async qzPrintRawFile(file: File) {
        return await this.qzPrintRawFileOnPrinter(file, context.settings.printerName);
    }

    /**
     * Print PDF file using qzTray
     *
     * @param {File} file
     * @param printerName
     * @param pageSettings
     * @returns {Promise<any>}
     */
    public async qzPrintPdfOnPrinter(file: File, printerName: string, pageSettings: PageSettings) {
        let optionsConfig: { [key: string]: any };

        optionsConfig = {
            ...{
                rasterize: false,
                scaleContent: false,
                units: "in",
            }, ...(await this.getPdfPageConfig(file, pageSettings)),
        };

        const config = qz.configs.create(printerName, optionsConfig);

        return qz.print(config, [{
            data: await this.getFileContentAsBase64(file),
            format: "base64",
            type: "pdf",
        }]);
    }

    public async qzPrintRawFileOnPrinter(file: File, printerName: string) {
        const base64Data = await this.getFileContentAsBase64(file);
        const config = qz.configs.create(printerName);

        return qz.print(config, [{
            data: base64Data,
            type: "raw",
            format: "base64",
        }]);
    }

    public async getPdfPageConfig(file: File, pageSettings: PageSettings) {
        if (pageSettings === PageSettings.Default) {
            return context.settings.printerPaperFormat === "A4" ?
                A4LandscapePrinterConfig : await this.getPageSettingsForFile(file);
        }

        if (pageSettings === PageSettings.A4WithAutoOrientation) {
            return A4AutoPrinterConfig;
        }

        return {};
    }

    public async getPageSettingsForFile(file: File) {
        return {
            size: await this.getPdfSizeInches(file),
            interpolation: "nearest-neighbor",
            scaleContent: true,
            margins: 0.1,
        };
    }

    public async getPdfSizeInches(file: File) {
        const fileContent = await this.getFileContentAsText(file);
        const match: string[] = fileContent.match(/MediaBox[ ]*\[.* .* (.*) (.*)\]/);
        if (match && match.length === 3) {
            return {
                width: (+match[1] / 72),
                height: (+match[2] / 72),
            };
        }
        return undefined;
    }

    private async getFileContentAsText(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = e => {
                return resolve((e.target as any).result);
            };
            reader.onerror = e => {
                reject(new Error(`${t("error.general.file.reading")}  ${file.name} :  ${(e.target as any).result}`));
            };
            reader.readAsText(file);
        }).then(content => content);
    }

    /**
     * Load all printers connected to network.
     */
    public async loadAllPrinters(): Promise<string[]> {
        const initialized = await this.qzInitialization();
        if (initialized) {
            const printers = await qz.printers.find();
            return printers;
        }
        return [];
    }

    /**
     * Chek if a websocket is active
     */
    public isActive() {
        return qz.websocket.isActive();
    }

    private async getFileContentAsBase64(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = e => {
                return resolve((e.target as any).result);
            };
            reader.onerror = e => {
                reject(new Error(`${t("error.general.file.reading")}  ${file.name} :  ${(e.target as any).result}`));
            };
            reader.readAsDataURL(file);
        }).then((content: string) => content.slice(content.indexOf(";base64,") + 8));
    }
}

export const qzManager = new QzManager();

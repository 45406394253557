import { RefObject, useEffect } from "react";
import { BrowserBarcodeReader } from "@zxing/library";

export const useZxingBarcodeDetector = (cameraDeviceId: string,
                                        videoElement: RefObject<HTMLVideoElement>,
                                        onValueScanned: (value: string) => void,
                                        valueValidator: (value: string) => boolean
                                            = (_value: string) => true) => {
    const cameraCodeReader = new BrowserBarcodeReader();

    return useEffect(() => {
        if (!videoElement.current) {
            return;
        }

        (async () => {
            await cameraCodeReader.decodeFromVideoDevice(cameraDeviceId, videoElement.current,
                (result, _error) => {
                    if (result && valueValidator(result.getText())) {
                        onValueScanned(result.getText());
                    }
                });
        })();

        return () => cameraCodeReader.reset();
    }, [cameraDeviceId, videoElement]);
};

import { ISettings, Settings } from "./settings";
import { Profile } from "./profile";
import { LocaleType, PrinterExtensionType, PrinterPaperFormat } from "../constants/constants";
import { profileService } from "../services/profile";
import { UserWebhook } from "./user-webhook";

export class SettingsManager {
    public async update(settings: Settings, key: keyof ISettings & keyof Settings, value: string) {
        const updatedSettings = new Settings(
            key === "username" ? value : settings.username,
            key === "contactEmail" ? value : settings.contactEmail,
            key === "printerType" ? value as PrinterExtensionType : settings.printerType,
            key === "printerName" ? value : settings.printerName,
            key === "printerPaperFormat" ? value as PrinterPaperFormat : settings.printerPaperFormat,
            key === "isDownload" ? (value === "true") : settings.isDownload,
            key === "lang" ? value as LocaleType : settings.lang,
            key === "muted" ? (value === "true") : settings.muted,
            settings.webhookId,
            key === "webhookUrl" ? value : settings.webhookUrl,
        );
        localStorage.setItem("user_settings", JSON.stringify(updatedSettings));

        if (this.requireUpdateOfProfile(key)) {
            try {
                switch (key) {
                    case "webhookUrl":
                        await profileService.saveWebhookUrl({
                            id: settings.webhookId,
                            url: value,
                        });
                        break;
                    default:
                        await profileService.saveProfile(this.getProfileFromSettings(updatedSettings));
                }
            } catch (err) {
                return settings;
            }
        }

        return updatedSettings;
    }

    private requireUpdateOfProfile(key: keyof ISettings & keyof Settings) {
        return key === "printerType" || key === "printerPaperFormat" || key === "isDownload" || key === "lang" ||
            key === "printerName" || key === "contactEmail" || key === "webhookUrl";
    }

    private getProfileFromSettings(settings: Settings) {
        return new Profile({
            isDownload: settings.isDownload,
            contactEmail: settings.contactEmail,
            printerPaperFormat: "A4",
            lang: settings.lang,
            updateDate: "",
            username: settings.username,
            webhooks: [new UserWebhook({id: settings.webhookId, url: settings.webhookUrl})],
        });
    }
}

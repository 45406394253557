import * as React from "react";

import {
    BulkyActionPicker,
    IActionCommand,
} from "../../../../common/component/action/action-select/bulky-action-picker";
import { observer } from "mobx-react";
import { Permission } from "../../../../context/permission";
import { context } from "../../../../context/context";
import { ManagePalletActionType } from "../../../constants/constants";
import { PalletsListItem } from "../pallets-list/pallets-list-item";

export interface IPalletShippedHeaderProps {
    actionsList: Array<IActionCommand<PalletsListItem, ManagePalletActionType>>;
    reloadPalletList: () => Promise<void>;
}

@observer
export class PalletShippingHeader extends React.Component<IPalletShippedHeaderProps, {}> {
    public render() {
        return <div className="list-actions-container">
            <div className="list-actions">
                <BulkyActionPicker withScanMode={true}
                                   propertyKey={context.warehouseCode}
                                   scanFieldType="text"
                                   inputLabel=""
                                   contentPrefix="palletShipping.action"
                                   actions={this.props.actionsList}
                                   permission={Permission.FrontWrite} />
            </div>
        </div>;
    }
}

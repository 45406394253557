import { createMuiTheme } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { blue, pink } from "./colors";

export const VPUITheme = createMuiTheme({
    palette: {
        error: red,
        primary: pink,
        secondary: blue,
    },
    typography: {
        fontFamily: "VPSans",
        h2: {
            fontWeight: "bold",
            fontSize: "30px",
            color: pink[500],
        },
        h3: {
            fontWeight: "bold",
            fontSize: "20px",
            color: "default",
        },
        h4: {
            fontWeight: "bold",
            fontSize: "12px",
            color: "#637381",
        },
        subtitle1: {
            fontWeight: "normal",
            fontSize: "12px",
        },
        subtitle2: {
            fontWeight: "normal",
            fontSize: "11px",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
                text: {
                    color: "#637381",
                },
                contained: {
                    "boxShadow": "none",
                    "&:hover": {
                        boxShadow: "none",
                    },
                },
                outlined: {
                    borderColor: "#EEEEEE",
                },
                sizeSmall: {
                    minWidth: 0,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    overflow: "visible",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation0: {
                    borderWidth: "1px",
                    borderColor: "#EEEEEE",
                    borderStyle: "solid",
                },
                elevation10: {
                    borderWidth: "1px",
                    borderColor: grey.A100,
                    borderStyle: "solid",
                    boxShadow: "none",
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderWidth: "1px",
                    borderColor: "#DBDBDB",
                    borderStyle: "solid",
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderTopColor: "#E8E8E8",
                    borderTopStyle: "solid",
                    borderTopWidth: "2px",
                    borderBottomColor: "#E8E8E8",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "2px",
                    boxShadow: "none",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: "#EEEEEE",
                    fontFamily: "Roboto",
                },
                body: {
                    color: "#404040",
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    borderBottomWidth: "1px",
                    borderBottomColor: "#E8E8E8",
                    borderBottomStyle: "solid",
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    marginLeft: "2px",
                    marginRight: "2px",
                },
                sizeSmall: {
                    fontSize: "12px",
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    borderBottomWidth: "1px",
                    borderBottomColor: "#E8E8E8",
                    borderBottomStyle: "solid",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    marginTop: "10px",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    borderTopWidth: "1px",
                    borderTopColor: "#E8E8E8",
                    borderTopStyle: "solid",
                    paddingBottom: "12px",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    paddingTop: "12px",
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "color": "#444444",
                    "&.Mui-focused": {
                        color: "#444",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: grey[700],
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderWidth: 1,
                        borderColor: blue[500],
                    },
                    "&.Mui-disabled": {
                        backgroundColor: grey[100],
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: grey[300],
                    },
                },
                notchedOutline: {
                    borderColor: grey[300],
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                MenuProps: {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    elevation: 0,
                    MenuListProps: {
                        style: {
                            paddingTop: "0",
                        },
                    },
                    style: {
                        marginTop: "8px",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                },
            },
            styleOverrides: {
                iconOutlined: {
                    right: "9px",
                    color: grey[600],
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    color: "#637381",
                    fontSize: "12px",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                },
            },
        },
    },
});

VPUITheme.typography.fontFamily = "VPSans";
VPUITheme.typography.body1.fontFamily = "VPSans";
VPUITheme.typography.body2.fontFamily = "VPSans";

VPUITheme.typography.h6.fontFamily = "VPSans";
VPUITheme.typography.h6.color = "default";
VPUITheme.typography.h6.textAlign = "center";

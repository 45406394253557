import * as React from "react";

import { TableCell, TableRow } from "@mui/material";

import { AreaListModel } from "./area-list-model";
import { observer } from "mobx-react";
import { t } from "i18next";
import { context } from "../../../../context/context";
import { FeatureToggle } from "../../../../context/app-configuration";

interface IAreaSubLine {
    areaListModel: AreaListModel;
}

@observer
export class AreaListSubLine extends React.Component<IAreaSubLine, {}> {

    public render() {
        return <TableRow className="level2 header">
            <TableCell />
            {this.renderColumnSort("model.areaDetail.productId", "details.productId")}
            {this.renderColumnSort("model.areaDetail.ean13List", "details.ean13List")}
            {this.renderColumnSort("model.areaDetail.supplierReference", "details.supplierReference")}
            {this.renderColumnSort("model.areaDetail.labelReference", "details.labelReference", 2)}
            {this.renderColumnSort("model.areaDetail.quantityParcelled", "details.quantityParcelled")}
            {this.renderColumnSort("model.areaDetail.quantityAvailable", "details.quantityAvailable")}
            {context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) &&
                this.renderColumnSort("model.areaDetail.quantityStockout", "details.quantityStockout")}
            {this.renderColumnSort("model.areaDetail.weight", "details.weight")}
        </TableRow>;
    }

    protected renderColumnSort(labelKey: string, fieldName: string, colSpan?: number) {
        return <TableCell className="cellHeader" colSpan={colSpan} data-testid={`column-lvl2-${fieldName}`}>
            {t(labelKey)}
        </TableCell>;
    }
}

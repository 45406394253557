import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { stringValue } from "../../../../common/field/renderer";
import { Parcel } from "../../../model/parcel";
import { UnCodeIcon } from "../un-code-icon/un-code-icon";

interface IParcelContentsProps {
    parcel: Parcel;
    additionalDataFields: string[];
}

export const ParcelContents = (props: IParcelContentsProps) => {
    return <>
        {props.parcel.details.map((detail, index) =>
            <TableRow key={"detail-" + index}
                      data-testid={`par-${props.parcel.id}-prd-${detail.productId}`}
                      className={"search-item__detail-line"}>
            <TableCell className={"search-item__detail-cell"} />
            <TableCell className={"search-item__detail-cell"}>{stringValue(detail, "productId")}</TableCell>
            <TableCell className={"search-item__detail-cell"}>
                <ul>{detail.ean13List.map(ean => <li key={ean}>{ean}</li>)}</ul>
            </TableCell>
            <TableCell className={"search-item__detail-cell"}>{stringValue(detail, "supplierReference")}</TableCell>
            <TableCell className={"search-item__detail-cell"} colSpan={2}>
                {detail.unCode && <UnCodeIcon value={detail.unCode}></UnCodeIcon>}
                {stringValue(detail, "labelReference")}
            </TableCell>
            <TableCell className={"search-item__detail-cell"}>{stringValue(detail, "quantity")}</TableCell>
            <TableCell className={"search-item__detail-cell"}>{stringValue(detail, "weight")}</TableCell>
            {props.additionalDataFields.map(additionalDataItem => {
                const additionalValue = (detail as any)[additionalDataItem];
                return <TableCell  className={"search-item__detail-cell"} key={additionalDataItem}>
                    {additionalValue || ""}
                </TableCell>;
            })}
        </TableRow>)}
    </>;
};

import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { palletService } from "../../../services/pallet";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { t } from "i18next";

export class PalletCloseHandler {
    public static async handle(palletList: PalletsListItem[], comment: string) {
        let nbFailed = 0;
        await Promise.all(palletList.map(async pallet => {
            try {
                await palletService.closePallet(pallet.id, comment);
            } catch (_err) {
                nbFailed++;
            }
        }));

        if (palletList.length !== nbFailed) {
            notificationModel.addSuccessMessage(t(`palletManagement.action.close.success` + (palletList.length - nbFailed > 1 ? "Multiple" : ""),
                { count: palletList.length - nbFailed }));
        } else {
            notificationModel.addErrorMessage(t(`palletManagement.action.close.noItems`));
        }
    }
}

import { domain, label } from "../../common/dto/dto-annotation";

import { assign } from "lodash";
import { localDateTime } from "../../domain/local-date";
import { identifier } from "../../domain/identifier";
import { label255 } from "./../../domain/label";
import { label50 } from "../../domain/label";

export class LabelError {

    constructor(labelError?: LabelError) {
        assign(this, labelError);
    }

    @label("model.deliveryOrderFull.operationCode")
    @domain(label50)
    public operationCode: string;

    @label("model.deliveryOrderFull.batchId")
    @domain(identifier)
    public batchId: number;

    @label("model.deliveryOrderFull.id")
    @domain(identifier)
    public deliveryOrderId: number;

    @label("model.parcel.id")
    @domain(identifier)
    public parcelId: number;

    @label("model.labelError.exceptionMessage")
    @domain(label255)
    public exceptionMessage: string;

    @label("model.labelError.exceptionDescription")
    @domain(label255)
    public exceptionDescription: string;

    @label("model.labelError.creationDate")
    @domain(localDateTime)
    public creationDate: string;

    @label("model.labelError.endDate")
    @domain(localDateTime)
    public endDate?: string;
}

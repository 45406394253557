import { httpGet } from "../common/network/fetch";
import { IAppConfiguration } from "../context/app-configuration";

declare global {
    const APPLICATION_NAME: string;
}

class ConfigurationService {

    public async load() {
        return await httpGet<IAppConfiguration>({url: `/configuration/${APPLICATION_NAME}`, versionApi: false});
    }
}

export const configurationService = new ConfigurationService();

import React, { useState } from "react";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Typography,
} from "@mui/material";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";
import { blue } from "@mui/material/colors";

import "./style.scss";
import { t } from "i18next";

interface IPalletFulfillmentParcelsScanProps {
    pallet: PalletsListItem;
    hasNextElement: boolean;

    onScan: (pallet: PalletsListItem, parcelLocator: string) => void;

    onCompleted: (pallet: PalletsListItem) => void;
}

export const PalletFulfillmentParcelsScan = (props: IPalletFulfillmentParcelsScanProps) => {
    const [textValue, setTextValue] = useState("");

    const handleOnKeyDown = async (keyDown: string) => {
        if (keyDown !== "Enter" || textValue === "") {
            return;
        }

        try {
            await props.onScan(props.pallet, textValue.trim());
        } finally {
            setTextValue("");
        }
    };

    return <>
        <DialogTitle>
            {t("components.pallet.fulfillment.titleWithPallet", {
                code: props.pallet.code,
            })}
        </DialogTitle>
        <DialogContent>
            <div className={"pallet-fulfillment-summary"}>
                <Typography>
                    {t("components.pallet.fulfillment.description")}
                </Typography>
                <div className={"pallet-fulfillment-summary__list"}>
                    <SquaredChip propertyKey={`item-${props.pallet.code}`} color={blue} label={props.pallet.code} />
                </div>
            </div>
        </DialogContent>
        <DialogContent>
            <div className={"pallet-fulfillment-parcel-scan"}>
                <Typography>{t("components.pallet.fulfillment.informationTitle")}</Typography>
                <Typography>{t("components.pallet.fulfillment.informationDetails")}</Typography>
                <FormControl variant={"outlined"}
                             fullWidth>
                    <TextField id={"parcel-code-scan"}
                               value={textValue}
                               variant={"outlined"}
                               autoFocus
                               onKeyDown={async (event: React.KeyboardEvent<any>) => await handleOnKeyDown(event.key)}
                               onChange={event => setTextValue(event.target.value ?? "")}
                    />
                </FormControl>
            </div>
        </DialogContent>
        <DialogActions>
            <Button color="secondary"
                    variant="contained"
                    type="submit"
                    data-testid={props.hasNextElement ? "pallet-fulfillment-next" : "pallet-fulfillment-finish"}
                    key="parcel-submit-btn"
                    onClick={() => props.onCompleted(props.pallet)}>
                {props.hasNextElement ?
                    t("components.pallet.fulfillment.nextPallet") :
                    t("components.pallet.fulfillment.finish")}
            </Button>
        </DialogActions>
    </>;
};

import * as React from "react";
import { SelectAutocomplete } from "../select-autocomplete/select-autocomplete";

export class InputMultipleSelectOption {
    constructor(public label: string, public value: string) {}
}

export interface IInputMultipleSelectProps {
    list: InputMultipleSelectOption[];
    onSelectMulti: (item: InputMultipleSelectOption[]) => void;
    label: string;
    selectedItems: InputMultipleSelectOption[];
    propertyKey: string;
    disabled?: boolean;
    placeholder: string;
    noOptionsMessage: string;
}

export const InputMultipleSelect = (props: IInputMultipleSelectProps) => {
    return <SelectAutocomplete {...props}
                               isMulti={true}
                               getOptionValue={option => option.value}
                               getOptionLabel={option => option.label}
                               formatOptionLabel={option => option.label} />;
};

export interface IProductComponent {
    componentProductId: string;
    productId: number;
    supplierReference: string;

}

export class ProductComponent {
    constructor(productComponent: IProductComponent) {
        this._componentProductId = productComponent.componentProductId;
        this._productId = productComponent.productId;
        this._supplierReference = productComponent.supplierReference;
    }

    private readonly _componentProductId: string;
    private readonly _productId: number;
    private readonly _supplierReference: string;

    public get componentProductId(): string {
        return this._componentProductId;
    }

    public get productId(): number {
        return this._productId;
    }

    public get supplierReference(): string {
        return this._supplierReference;
    }
}

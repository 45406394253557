import React from "react";
import { Button, CardContent, Typography } from "@mui/material";
import { t } from "i18next";

interface IResetPasswordNotificationProps {
    text: string;
    onClose: () => void;
}

export const ResetPasswordNotification = (props: IResetPasswordNotificationProps) => {
    return <CardContent>
        <Typography className={"password-reset__description"}>
            {props.text}
        </Typography>
        <div className={"password-reset__button-container"}>
            <Button variant="contained"
                    color={"inherit"}
                    onClick={() => props.onClose()}
                    key="cancel-btn">
                {t("components.passwordReset.close")}
            </Button>
        </div>
    </CardContent>;
};

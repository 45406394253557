import { OperationCounterType, PalletCounterType } from "../constants/constants";

export const routeOperationPreparation: PreparationSectionType = "operations";
export const routePalletPreparation: PreparationSectionType = "pallet";

export const routeBatchPreparation: OperationPageType = "batch-preparation";
export const routeParcelManagement: OperationPageType = "parcel-management";
export const routeShipParcel: OperationPageType = "ship-parcel";
export const routeConsultShippedParcel: OperationPageType = "consult-shipped-parcel";
export const routePrepareParcelShipping: OperationPageType = "prepare-parcel-shipping";
export const routeDeliveryOrderCanceled: OperationPageType = "do-canceled";
export const routeReporting: OperationPageType = "reporting";
export const routePickAndPack: OperationPageType = "pick-and-pack";
export const routeBulkParcelUpload: OperationPageType = "bulk-parcel-upload";
export const routeExportOperationProducts = "export-operation-products";
export const routeImportData: OperationPageType = "import-data";
export const routeStockouts: OperationPageType = "stockouts";

export const routePalletManagement: PalletPageType = "pallet-management";
export const routePalletShipping: PalletPageType = "pallet-shipping";
export const routeShippedPallets: PalletPageType = "shipped-pallets";

export type PreparationSectionType = "operations" | "pallet";

export type OperationPageType = "batch-preparation" | "parcel-management" | "do-canceled"
                                | "ship-parcel" | "consult-shipped-parcel" | "reporting"
                                | "pick-and-pack" | "import-data" | "stockouts" | "prepare-parcel-shipping"
                                | "bulk-parcel-upload" | "export-operation-products";

export type PalletPageType = "pallet-management" | "pallet-shipping" | "shipped-pallets";

export type PreparationPageType = OperationPageType | PalletPageType;

export type CounterType = OperationCounterType | PalletCounterType;

export interface IOperationRouteProps {
    page: PreparationPageType | undefined;
    operationCode?: string;
    batchId?: number;
}

export interface IPalletRouteProps {
    page: PalletPageType | undefined;
    warehouse?: string;
}

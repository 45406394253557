import {DeliveryOrder} from "../../../model/delivery-order";
import {operationsService} from "../../../services/operations";
import {DeliveryOrderStatusType} from "../../../../constants/constants";
import { filter } from "lodash";
import {OrdersDataProvider} from "./order-data-provider";

export class DefaultOrderDataProvider extends OrdersDataProvider {

    constructor(private deliveryOrderStatus: DeliveryOrderStatusType) {
        super();
    }

    public async loadList(operationCode: string, batchId: number): Promise<DeliveryOrder[]> {
        return filter((await operationsService.loadDeliveryOrderList(
            operationCode,
            batchId,
            this.deliveryOrderStatus)
        )).sort((first, second) => {
            if (first.deliveryOrderId > second.deliveryOrderId) {
                return 1;
            }

            if (first.deliveryOrderId < second.deliveryOrderId) {
                return -1;
            }

            return 0;
        });
    }

    public isInPageScope(deliveryOrder: DeliveryOrder) {
        return  deliveryOrder.status === this.deliveryOrderStatus;
    }
}

export class AdditionalValuesLocator {
    private readonly _locatorName: string;
    private readonly _locatorValue: string;

    public constructor(locatorName: string, locatorValue: string) {
        this._locatorName = locatorName;
        this._locatorValue = locatorValue;
    }

    public get locatorName(): string {
        return this._locatorName;
    }

    public get locatorValue(): string {
        return this._locatorValue;
    }
}

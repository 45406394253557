import React, { useState } from "react";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    ThemeProvider,
    TextField,
    Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { t } from "i18next";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";
import { blue } from "@mui/material/colors";
import { PalletParcelRemoveHandler } from "./pallet-parcel-remove-handler";
import { notificationModel } from "../../../../common/component/notifications/notification-model";

import "./style.scss";

interface IPalletParcelRemoveDialogProps {
    palletList: PalletsListItem[];
    getPalletByParcelLocator: (locator: string) => PalletsListItem | undefined;
    onCancel: () => void;
    onPalletRemove: () => void;
}

export const PalletParcelRemoveDialog = (props: IPalletParcelRemoveDialogProps) => {
    const [palletList] = useState(props.palletList);
    const [textValue, setTextValue] = useState("");

    const handleOnKeyDown = async (keyDown: string) => {
        if (keyDown !== "Enter" || textValue === "") {
            return;
        }

        try {
            const cleanTextValue = textValue.trim();

            const pallet = props.getPalletByParcelLocator(cleanTextValue);
            if (pallet === undefined || (palletList.length > 0 && palletList.find(p => p.code === pallet.code) === undefined)) {
                notificationModel.addErrorMessage(t("components.pallet.removeParcel.parcelNotFound", {
                    locator: cleanTextValue,
                }));

                return;
            }

            await PalletParcelRemoveHandler.handle(pallet, cleanTextValue);
            await props.onPalletRemove();
        } finally {
            setTextValue("");
        }
    };

    return <Dialog open={true}>
        <ThemeProvider theme={VPUITheme}>
            <div className={"pallet-parcel-removal-dialog"}
                 data-testid={"pallet-parcel-removal-dialog"}>
                <div className="pallet-parcel-removal-dialog__close">
                    <IconButton onClick={() => props.onCancel()}
                                data-testid={"close"}>
                        <Close />
                    </IconButton>
                </div>
                <DialogTitle>
                    {t("components.pallet.removeParcel.title")}
                </DialogTitle>
                <DialogContent>
                    <div className={"pallet-parcel-removal-summary"}>
                        <Typography>
                            {palletList.length === 0 ?
                                t("components.pallet.removeParcel.description") :
                                t("components.pallet.removeParcel.descriptionWithSelectedPallets")}
                        </Typography>
                        {palletList.length > 0 &&
                            <div className={"pallet-parcel-removal-summary__list"}>
                                {palletList.map(pallet =>
                                    <SquaredChip propertyKey={`item-${pallet.code}`} color={blue} label={pallet.code} />)}
                            </div>}
                    </div>
                </DialogContent>
                <DialogContent>
                    <div className={"pallet-parcel-removal-scan"}>
                        <Typography>{t("components.pallet.removeParcel.scanLabel")}</Typography>
                        <FormControl variant={"outlined"}
                                     fullWidth>
                            <TextField id={"parcel-code-scan"}
                                       value={textValue}
                                       variant={"outlined"}
                                       autoFocus
                                       onKeyDown={async (event: React.KeyboardEvent<any>) => await handleOnKeyDown(event.key)}
                                       onChange={event => setTextValue(event.target.value ?? "")}
                            />
                        </FormControl>
                    </div>
                </DialogContent>
            </div>
        </ThemeProvider>
    </Dialog>;
};

import "./style.scss";

import * as React from "react";
import { inject, observer } from "mobx-react";
import { AreaList } from "../area-list/area-list";
import { AreaListModel } from "../area-list/area-list-model";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { pickAndPackCommand } from "./pick-and-pack-command";
import { pickAndPackModel } from "./pick-and-pack-model";
import { PickAndPackHeader } from "./pick-and-pack-header";
import { IReactionDisposer, reaction } from "mobx";
import { context } from "../../../../context/context";
import { Permission } from "../../../../context/permission";
import { IWithRouterProps, withRouter } from "../../../../common/component/hoc/withRouter";

@inject("routing")
@observer
class PickAndPackComponent extends React.Component<IWithRouterProps & IHistoryProps, {}> {

    private areaListModel: AreaListModel = new AreaListModel();

    private autoload: IReactionDisposer;

    constructor(props: IWithRouterProps & IHistoryProps) {
        super(props);

        this.callKeyDownEvent = this.callKeyDownEvent.bind(this);
        this.callKeyUpEvent = this.callKeyUpEvent.bind(this);
    }

    public async componentDidMount() {
        window.addEventListener("keydown", this.callKeyDownEvent);
        window.addEventListener("keyup", this.callKeyUpEvent);

        this.autoload = reaction(
            () => this.props.params.batchId,
            async () => this.isOperationAndBatchSelected() &&
                await this.areaListModel.load(this.props.params.operationCode, Number(this.props.params.batchId)),
            {
                fireImmediately: true,
            },
        );
    }

    public async componentWillUnmount() {
        window.removeEventListener("keydown", this.callKeyDownEvent);
        window.removeEventListener("keyup", this.callKeyUpEvent);

        if (this.autoload) {
            this.autoload();
        }
    }

    public render() {
        if (!this.isOperationAndBatchSelected()) {
            return <div/>;
        }

        if (!this.isPickAndPackAvailable()) {
            return <div/>;
        }

        return <div>
            <PickAndPackHeader />
            <AreaList
                areaListModel={this.areaListModel}
                executeStockoutProductFromArea={(area, areaDetail) => pickAndPackCommand.stockoutProductFromArea(area, areaDetail)}
            />
        </div>;
    }

    private isPickAndPackAvailable(): boolean {
        return !context.hasPermission(Permission.FeatureBlueBrand);
    }

    private isOperationAndBatchSelected(): boolean {
        return this.props.params.operationCode !== undefined && this.props.params.batchId !== undefined;
    }

    private callKeyUpEvent(e: any) {
        e.stopPropagation();
        e.preventDefault();
        pickAndPackModel.fireEanStreamed(e, this.onScan.bind(this));
    }

    private callKeyDownEvent(e: any) {
        e.stopPropagation();
        e.preventDefault();
        pickAndPackModel.listenNumericKeyBoard(e);
    }

    private async onScan(value: string) {
        await pickAndPackCommand.pickAndPack(value,
            this.props.params.operationCode,
            Number(this.props.params.batchId));

        await this.areaListModel.reload();
    }
}

export const PickAndPack = withRouter(PickAndPackComponent);

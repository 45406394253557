import * as React from "react";

import { autorun } from "mobx";
import { createOperationUrl, parseOperationUrl } from "../../../navigation/operation-route-parser";
import { inject, observer } from "mobx-react";
import { t } from "i18next";
import { Batch } from "../../../model/batch";
import { BatchSelect } from "./batch-select";
import { Card, Grid, ThemeProvider } from "@mui/material";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { Operation } from "../../../model/operation";
import { OperationSelect } from "./operation-select";
import { find } from "lodash";
import { operationsService } from "../../../services/operations";
import { menuOperationModel } from "../../preparation-menu/preparation-menu-model";
import { OperationBatchSelectorModel } from "./operation-batch-selector-model";
import { operationContext } from "../../../../context/operation-context";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import { IWithRouterProps, withRouter } from "../../../../common/component/hoc/withRouter";
import { matchPath, PathMatch } from "react-router-dom";

import "./style.scss";

interface IOperationBatchSelectorProps {
    notDisplayBatchSelector: boolean;
}

@inject("routing")
@observer
class OperationBatchSelectorComponent extends React.Component<IHistoryProps & IOperationBatchSelectorProps & IWithRouterProps, {}> {

    private autoload: any;
    private operationBatchSelectorModel = new OperationBatchSelectorModel();
    private match: PathMatch<any> | null;

    public async componentWillMount() {
        this.operationBatchSelectorModel.resetOperationList(await operationsService.loadOperations());

        this.autoload = autorun(async () => {
            this.match = matchPath("operations/:sublist/:operationCode?/:batchId?", this.props.location.pathname);

            if (this.match === null) {
                return;
            }

            if (this.match.params.operationCode && !this.props.notDisplayBatchSelector) {
                this.operationBatchSelectorModel.resetBatchList(
                    await operationsService.loadBatches(this.match.params.operationCode),
                );
            }
            if (this.match.params.operationCode && this.match.params.batchId) {
                await menuOperationModel.refreshCounters(
                    this.match.params.operationCode, Number(this.match.params.batchId));
            } else {
                menuOperationModel.updateCounters([]);
            }

            if (this.selectedOperation) {
                operationContext.setOperation(this.selectedOperation);
            } else {
                operationContext.removeOperation();
            }

            if (this.selectedBatch) {
                operationContext.setBatchId(this.selectedBatch);
            } else {
                operationContext.removeBatch();
            }
        });
    }

    public async componentWillUnmount() {
        if (this.autoload) {
            this.autoload();
        }
    }

    public render() {
        return <ThemeProvider theme={VPUITheme}>
            <Card className="batch-selector" elevation={0}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={this.props.notDisplayBatchSelector ? 12 : 6}
                          data-testid={"operation-select"}>
                        <OperationSelect
                            propertyKey="operation-select"
                            list={this.operationBatchSelectorModel.operationListOrdered.slice()}
                            label={t("components.operationSelector.operation")}
                            placeholder={t("components.operationSelector.placeholder")}
                            noOptionsMessage={t("components.operationSelector.noResults")}
                            onSelect={operation => this.selectOperation(operation)}
                            selectedItem={this.selectedOperation}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className={this.props.notDisplayBatchSelector ? "grid-batch-hidden" : ""}
                          data-testid={"batch-select"}>
                        <BatchSelect
                            disabled={this.match?.params.operationCode === undefined}
                            propertyKey="batch-select"
                            list={this.operationBatchSelectorModel.batchList.slice()}
                            label={t("components.batchSelector.batch")}
                            placeholder={t("components.batchSelector.placeholder")}
                            noOptionsMessage={t("components.batchSelector.noResults")}
                            onSelect={batch => this.selectBatch(batch)}
                            selectedItem={this.selectedBatch}
                        />
                    </Grid>
                </Grid>
            </Card>
        </ThemeProvider>;
    }

    private get selectedOperation() {
        return find(this.operationBatchSelectorModel.operationList,
            operation => operation.code === this.match?.params.operationCode) || null;
    }

    private get selectedBatch() {
        return this.match?.params.batchId ?
            find(this.operationBatchSelectorModel.batchList,
                batch => batch.id === Number(this.match?.params.batchId)) ?? null : null;
    }

    private async selectOperation(operation: Operation | null) {
        const isReset = operation === null;
        this.operationBatchSelectorModel.resetBatchList(
            isReset ? [] : await operationsService.loadBatches(operation!.code),
        );
        const url = createOperationUrl({
            batchId: undefined,
            operationCode: operation?.code,
            page: parseOperationUrl(this.props.routing.location.pathname).page,
        });
        this.props.routing.push(url);
    }

    private selectBatch(batch: Batch | null) {
        const url = createOperationUrl({
            batchId: batch?.id,
            operationCode: this.match?.params.operationCode,
            page: parseOperationUrl(this.props.routing.location.pathname).page,
        });
        this.props.routing.push(url);
    }
}

export const OperationBatchSelector = withRouter(OperationBatchSelectorComponent);

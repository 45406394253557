import { observable, computed, action } from "mobx";
import { FilterActionData } from "./filter-action-data";
import { ISearchListSubRow, ISearchListRow } from "../search-list/search-list";

export class FormFilterModel<TFilter extends {}, TRow extends ISearchListRow<TSubRow>, TSubRow extends ISearchListSubRow> {

    @observable private _filterActionMap = new Map<keyof TFilter, FilterActionData<TFilter, TRow, TSubRow>>();

    @computed
    public get filterActionList() {
        const list: Array<FilterActionData<TFilter, TRow, TSubRow>> = [];
        this._filterActionMap.forEach((filterAction, _key) => {
            list.push(filterAction);
        });
        return list;
    }

    public getFilterAction(filterKey: keyof TFilter) {
        return this._filterActionMap.get(filterKey);
    }

    @action
    public resetFilterActionMap(filterActionDataList: Array<FilterActionData<TFilter, TRow, TSubRow>>) {
        this._filterActionMap.clear();
        filterActionDataList.forEach(filterActionData => {
            this._filterActionMap.set(filterActionData.filterField, filterActionData);
        });
    }

    @action
    public setFormFilterValue(
            filterKey: keyof TFilter,
            filterValue: FilterActionData<TFilter, TRow, TSubRow>) {
        this._filterActionMap.set(filterKey, filterValue);
    }
}

import { Parcel } from "../../../model/parcel";
import React, { useState } from "react";
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { ScanFieldMultiple } from "../../../../common/component/scan-field-multple/scan-field-multiple";
import { t } from "i18next";

interface IPalletAssignmentScanParcelsProps {
    getParcelItemByScan: (palletIdentifier: string) => Parcel | undefined;

    onCancel: () => void;
    onFinished: (parcelList: Parcel[]) => void;
}

export const PalletAssignmentScanParcels = (props: IPalletAssignmentScanParcelsProps) => {
    const [scannedPalletsList, setScannedPalletsList] = useState<Parcel[]>([]);

    const handleItemScanned = (scanValue: string) => {
        const item = props.getParcelItemByScan(scanValue);

        if (item === undefined) {
            throw new Error(`Parcel ${scanValue} was not found`);
        }

        setScannedPalletsList(s => [...s, item]);
    };

    return <>
        <DialogTitle>
            {t("components.pallet.assignment.title")}
        </DialogTitle>
        <DialogContent>
            <Typography className={"pallet-assignment-dialog__description"}>
                {t("components.pallet.assignment.scanParcels")}
            </Typography>
            <ScanFieldMultiple
                autoFocus
                label={t("model.parcel.parcelId")}
                onScan={scanValue => handleItemScanned(scanValue)} />
        </DialogContent>
        <DialogActions>
            <Button variant="outlined"
                    type="submit"
                    key="cancel-btn"
                    onClick={async () => props.onCancel()}>
                {t("components.popin.cancel")}
            </Button>
            <Button color="secondary"
                    variant="contained"
                    type="submit"
                    key="submit-btn"
                    disabled={scannedPalletsList.length === 0}
                    onClick={async () => props.onFinished(scannedPalletsList)}>
                {t("components.pallet.assignment.continue")}
            </Button>
        </DialogActions>
    </>;
};

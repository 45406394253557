import * as moment from "moment";

import { IDomain, HtmlType } from "../common/dto/idomain";
import { context } from "../context/context";
import { InputComponentType } from "../common/component/input/input";

class LocalDateDomain implements IDomain<Date> {

    public htmlType: HtmlType = "text";
    public inputComponent: InputComponentType = "textField";
    public _format: string;

    public constructor(format: string) {
        this._format = format;
    }

    public format(valueToFormat?: Date) {
        if (valueToFormat) {
            moment.locale(context.settings.lang);
            return moment(valueToFormat).format(this._format);
        }
        return "";
    }

    public unformat(valueToUnformat?: string) {
        if (valueToUnformat) {
            moment.locale(context.settings.lang);
            return moment(valueToUnformat).toDate();
        }
        return;
    }

    public validators = [];
}

export const localDate = new LocalDateDomain("L");
export const localDateTime = new LocalDateDomain("L LT");

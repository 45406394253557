export interface IParcelCreationErrorItem {
    deliveryOrderId: number;
    errors: string[];
}

export class ParcelCreationErrorItem {
    private readonly _deliveryOrderId: number;
    private readonly _errors: string[];

    public constructor(item: IParcelCreationErrorItem) {
        this._deliveryOrderId = item.deliveryOrderId;
        this._errors = item.errors;
    }

    public get deliveryOrderId(): number {
        return this._deliveryOrderId;
    }

    public get errors(): string[] {
        return this._errors;
    }
}

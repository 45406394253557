export type BreadcrumbColor = "default" | "production" | "testing";

export class BreadcrumbItem {
    private readonly _label: string;
    private readonly _url: string;
    private readonly _color: BreadcrumbColor;

    constructor(label: string, url: string, color: BreadcrumbColor = "default") {
        this._label = label;
        this._url = url;
        this._color = color;
    }

    get label() {
        return this._label;
    }

    get url() {
        return this._url;
    }

    get color() {
        return this._color;
    }
}

import React, { useState } from "react";
import { ExitToApp, FolderOpenOutlined, KeyboardArrowDown, Notifications, Person, Settings } from "@mui/icons-material";
import { Badge, Button } from "@mui/material";
import { useUserNotificationCenter, useUserNotificationCenterDispatcher } from "../../../common/component/notifications/notification-center/user-notification-center-context-provider";
import { UserAccountWidgetMenu } from "../../../common/component/user-account-widget/user-account-widget-menu";
import { UserAccountWidgetMenuItem } from "../../../common/component/user-account-widget/user-account-widget-menu-item";
import { t } from "i18next";

import "./style.scss";

interface IUserAccountWidgetProps {
    name: string;
    logoutText: string;
    onSettingsOpen?: () => void;
    onBusinessDocumentationOpen?: () => void;
    onLogout: () => Promise<void>;
}

export const UserAccountWidget = (props: IUserAccountWidgetProps) => {
    const [userAccountWidgetMenuAnchor, setUserAccountWidgetMenuAnchor] = useState<HTMLElement | null>(null);
    const notificationCenter = useUserNotificationCenter();
    const notificationCenterDispatcher = useUserNotificationCenterDispatcher();

    const handleNotificationCenterOpen = () => {
        setUserAccountWidgetMenuAnchor(null);
        notificationCenterDispatcher({
            type: "open",
        });
    };

    return <>
        <Badge variant={"dot"}
               color={"primary"}
               invisible={notificationCenter.unreadNotificationsCount === 0}>
            <Button variant={"outlined"}
                    className={"user-account-selector__button"}
                    color={userAccountWidgetMenuAnchor === null ? "inherit" : "secondary"}
                    data-testid={"user-account-selector"}
                    onClick={event => setUserAccountWidgetMenuAnchor(event.currentTarget)}
                    startIcon={<Person />}
                    endIcon={<KeyboardArrowDown />}
                    size={"large"}>
                {props.name}
            </Button>
        </Badge>
        {userAccountWidgetMenuAnchor && <UserAccountWidgetMenu anchor={userAccountWidgetMenuAnchor}
                                                               onClose={() => setUserAccountWidgetMenuAnchor(null)}>
            {props.onSettingsOpen && <UserAccountWidgetMenuItem label={"Settings"}
                                                                propertyKey={"settings"}
                                                                icon={<Settings />}
                                                                onClick={props.onSettingsOpen} />}
            <UserAccountWidgetMenuItem label={t("navigation.notifications")}
                                       propertyKey={"notifications"}
                                       icon={<Notifications />}
                                       badge={notificationCenter.unreadNotificationsCount}
                                       onClick={handleNotificationCenterOpen} />
            {props.onBusinessDocumentationOpen && <UserAccountWidgetMenuItem label={t("navigation.businessDocumentation")}
                                       propertyKey={"businessDocumentation"}
                                       icon={<FolderOpenOutlined />}
                                       onClick={props.onBusinessDocumentationOpen} />}
            <UserAccountWidgetMenuItem label={props.logoutText}
                                       propertyKey={"logout"}
                                       icon={<ExitToApp />}
                                       onClick={props.onLogout} />
        </UserAccountWidgetMenu>}
    </>;
};

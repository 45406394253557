import React, { Fragment, useEffect, useReducer } from "react";
import { Divider, Drawer, IconButton, List, ListItem, ListItemText, ThemeProvider, Typography } from "@mui/material";
import { notificationService } from "../../../../front-office/services/notifications-service";
import { useUserNotificationCenter, useUserNotificationCenterDispatcher } from "./user-notification-center-context-provider";
import { UserNotificationMessagesReducer } from "./user-notification-messages-reducer";
import { UserNotification } from "../../../model/user-notification";
import { NotificationLevel } from "../../../model/notification-with-delivery-details";
import { VPUITheme } from "../../../theme/vpui-theme";
import { Close } from "@mui/icons-material";
import { t } from "i18next";

import "./style.scss";

interface IUserNotificationCenterMessageProps {
    data: UserNotification;
    isUnread: boolean;
}

export const UserNotificationCenter = () => {
    const [notificationMessagesList, notificationMessageDispatcher] = useReducer(UserNotificationMessagesReducer, []);
    const notificationCenter = useUserNotificationCenter();
    const userNotificationCenterDispatcher = useUserNotificationCenterDispatcher();

    useEffect(() => {
        (async () => {
            await handleNotificationFetch();
        })();

        const interval = setInterval(async () => {
            await handleNotificationFetch();
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        userNotificationCenterDispatcher({
            type: "newNotification",
            unreadNotificationsCount: notificationMessagesList
                .filter(notification =>
                    notification.createdAt.isAfter(notificationCenter.notificationCenterLastOpen, "second"))
                .length,
        });
    }, [notificationMessagesList.length]);

    const handleNotificationFetch = async () => {
        (await notificationService.fetchForUser())
            .forEach(notificationMessageDispatcher);
    };

    const handleNotificationCenterClose = () => {
        userNotificationCenterDispatcher({
            type: "close",
        });
    };

    return <ThemeProvider theme={VPUITheme}>
        <Drawer open={notificationCenter.isOpen}
                onClose={handleNotificationCenterClose}
                className={"notification-center"}
                anchor={"right"} variant={"temporary"}>
            <div className={"notification-center__content"}>
                <IconButton className={"notification-center__close"} onClick={handleNotificationCenterClose}>
                    <Close />
                </IconButton>
                <Typography variant={"h2"} className={"notification-center__title"}>
                    {t("notificationCenter.title")}
                </Typography>
                <List>
                    {notificationMessagesList.map(e =>
                        <UserNotificationCenterMessage data={e}
                                                       isUnread={e.createdAt.isAfter(notificationCenter.notificationCenterLastOpen, "second")} />)}
                </List>
            </div>
        </Drawer>
    </ThemeProvider>;
};

const UserNotificationCenterMessage = (props: IUserNotificationCenterMessageProps) => {
    const renderClassName = () => {
        if (props.data.level === NotificationLevel.Critical) {
            return "notification-center__message notification-center__message--urgent";
        }

        if (props.data.level === NotificationLevel.Warning) {
            return "notification-center__message notification-center__message--warning";
        }

        if (props.isUnread) {
            return "notification-center__message notification-center__message--unread";
        }

        return "notification-center__message";
    };

    const Message = <Fragment>
        <Typography variant={"body2"}>
            {props.data.message}
        </Typography>
        <Typography className={"notification-center__message-footer"}>
            <em>
                {t("notificationCenter.message.details", {
                    author: props.data.createdBy,
                    date: props.data.createdAt.format("YYYY-MM-DD HH:mm"),
                })}
            </em>
        </Typography>
    </Fragment>;

    return <>
        <ListItem className={renderClassName()}>
            <ListItemText primary={props.data.title}
                          primaryTypographyProps={{color: props.isUnread ? "textPrimary" : "textSecondary"}}
                          secondary={Message}
                          secondaryTypographyProps={{color: props.isUnread ? "textPrimary" : "textSecondary"}} />
        </ListItem>
        <Divider />
    </>;
};

import { blue as MuiBlue, pink as MuiPink } from "@mui/material/colors";

export const blue = {
    ...MuiBlue,
    500: "#1A94FF",
};

export const pink = {
    ...MuiPink,
    500: "#EC008C",
};

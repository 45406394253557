import React, { createContext, useContext, ReactNode, Dispatch, SetStateAction, useState } from "react";
import { PanelMenuType } from "../../../constants/constants";

export interface IVpMenuContext {
    expandedPanel: PanelMenuType | undefined;
    setExpandedPanel: Dispatch<SetStateAction<PanelMenuType | undefined>>;
    isExpandedEnabled: boolean;
    setIsExpandedEnabled: Dispatch<SetStateAction<boolean>>;
}

const VpMenuContext = createContext<IVpMenuContext | null>(null);

export interface IVpMenuContextProviderProps {
    children: ReactNode;
}

export const VpMenuContextProvider = (props: IVpMenuContextProviderProps) => {
    const [expandedPanel, setExpandedPanel] = useState<PanelMenuType | undefined>(undefined);
    const [isExpandedEnabled, setIsExpandedEnabled] = useState(false);

    return <VpMenuContext.Provider value={{
        expandedPanel, setExpandedPanel,
        isExpandedEnabled, setIsExpandedEnabled,
    }}>
        {props.children}
    </VpMenuContext.Provider>;
};

export const useVpMenuContext = () => {
    const context = useContext(VpMenuContext);
    if (!context) {
        throw new Error("useVpMenuContext must be used within VpMenuContextProvider");
    }

    return context;
};

import { CarrierCountryGroup } from "../../../carrier-country-group";

export class CreatePalletRequestCarrierCountryItem {
    private readonly _carrierCountryGroup: CarrierCountryGroup;
    private readonly _quantity: number;

    public constructor(carrierCountryGroup: CarrierCountryGroup, quantity: number) {
        this._carrierCountryGroup = carrierCountryGroup;
        this._quantity = quantity;
    }

    public get carrierCountryGroup(): CarrierCountryGroup {
        return this._carrierCountryGroup;
    }

    public get quantity(): number {
        return this._quantity;
    }

    public isSameCarrierCountry(reference: CreatePalletRequestCarrierCountryItem) {
        return this.carrierCountryGroup.carrier === reference.carrierCountryGroup.carrier &&
            this.carrierCountryGroup.countryGroupName === reference.carrierCountryGroup.countryGroupName;
    }
}

import * as React from "react";

import { FilterBlock } from "../../../../common/component/filter/filter-block";
import { FormFilter } from "../../../../common/component/list/filter/form-filter";
import { observer } from "mobx-react";
import { t } from "i18next";
import { PalletFilter } from "./pallet-filter";
import { PalletsListItem } from "./pallets-list-item";
import { PalletsListSubItem } from "./pallets-list-sub-item";
import { computed } from "mobx";
import { uniqBy } from "lodash";
import { ISelectItem } from "../../../../common/component/input/iselect-item";

@observer
export class PalletsListFilter extends
    FormFilter<PalletFilter, PalletsListItem, PalletsListSubItem> {

    protected renderForm(data: PalletFilter) {
        return <div className="containerFilter">
            <div className="multiBlockFilter">
                <FilterBlock
                    className=""
                    label={t("filter.category.pallet")}
                    filterBlock={this.renderPalletFilterBlock(data)}
                />
                <FilterBlock
                    className=""
                    label={t("filter.category.parcel")}
                    filterBlock={this.renderParcelFilterBlock(data)}
                />
            </div>
        </div>;
    }

    private renderPalletFilterBlock(data: PalletFilter) {
        return <div className="pallet">
            {this.getFieldFilter(data, "code")}
            {this.getFieldFilter(data, "carrier", this.carrierList)}
            {this.getFieldFilter(data, "country", this.countryList)}
            {this.getFieldFilter(data, "parcelCount")}
            {this.getFieldFilter(data, "weight")}
            {this.getFieldFilter(data, "isLabelPrinted")}
        </div>;
    }

    private renderParcelFilterBlock(data: PalletFilter) {
        return <div className="parcel">
            {this.getFieldSubFilter(data, "operationCode", "details")}
            {this.getFieldSubFilter(data, "batchId", "details")}
            {this.getFieldSubFilter(data, "parcelId", "details")}
            {this.getFieldSubFilter(data, "parcelTracker", "details")}
            {this.getFieldSubFilter(data, "deliveryOrderId", "details")}
            {this.getFieldSubFilter(data, "orderId", "details")}
            {this.getFieldSubFilter(data, "zipCode", "details")}
        </div>;
    }

    @computed
    private get carrierList() {
        return uniqBy(this.props.searchListModel.list, "carrier").map(pallet => {
            return {
                display: pallet.carrier,
                value: pallet.carrier,
            };
        }) as Array<ISelectItem<string>>;
    }

    @computed
    private get countryList() {
        return uniqBy(this.props.searchListModel.list, "country").map(pallet => {
            return {
                display: pallet.country,
                value: pallet.country,
            };
        }) as Array<ISelectItem<string>>;
    }
}

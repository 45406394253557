import React, { useEffect, useState } from "react";
import { CarrierCountryGroup } from "../../../carrier-country-group";
import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { t } from "i18next";
import { CreatePalletRequestCarrierCountryItem } from "./create-pallet-request-carrier-country-item";
import { uniqueValuesArrayFilter } from "../../../../common/utils/array";

interface ISingleCarrierCountryGroupSelectProps {
    carrierCountryGroupList: CarrierCountryGroup[];
    onChange: (carrierCountryGroup?: CreatePalletRequestCarrierCountryItem) => void;
}

interface ICountryGroupSelectorProps {
    countryGroupList: string[];
    value: string;
    disabled: boolean;
    onChange: (countryGroup: string) => void;
}

interface ICarrierSelectorProps {
    carrierList: string[];
    onChange: (carrier: string) => void;
}

export const SingleCarrierCountryGroupSelect = (props: ISingleCarrierCountryGroupSelectProps) => {
    const [countryGroup, setCountryGroup] = useState("");
    const [carrier, setCarrier] = useState("");

    useEffect(() => {
        setCountryGroup("");

        const countryGroupsList = props.carrierCountryGroupList.filter(c => c.carrier === carrier);
        if (countryGroupsList.length === 1) {
            setCountryGroup(countryGroupsList[0].countryGroupName);
        }
    }, [carrier]);

    useEffect(() => {
        if (countryGroup === "") {
            props.onChange();

            return;
        }

        const item = props.carrierCountryGroupList.find(c => c.carrier === carrier && c.countryGroupName === countryGroup);
        if (item) {
            props.onChange(new CreatePalletRequestCarrierCountryItem(item, 1));
        }

    }, [countryGroup]);

    return <div className={"carrier-select__container"}>
        <FormControl variant={"outlined"}
                     fullWidth>
            <FormLabel htmlFor={"carrier-select"}>
                {t("components.pallet.createPalletDialog.carrierLabel")}
            </FormLabel>
            <CarrierSelector carrierList={props.carrierCountryGroupList.map(item => item.carrier).filter(uniqueValuesArrayFilter)}
                             onChange={item => setCarrier(item)} />
        </FormControl>
        <FormControl variant={"outlined"}
                     fullWidth>
            <FormLabel htmlFor={"country-group-select"}>
                {t("components.pallet.createPalletDialog.countryLabel")}
            </FormLabel>
            <CountryGroupSelector countryGroupList={props.carrierCountryGroupList.filter(c => c.carrier === carrier).map(i => i.countryGroupName)}
                                  value={countryGroup}
                                  disabled={carrier === ""}
                                  onChange={item => setCountryGroup(item)} />
        </FormControl>
    </div>;
};
const CarrierSelector = (props: ICarrierSelectorProps) => {
    const [value, setValue] = useState("");

    useEffect(() => {
        props.onChange(value);
    }, [value]);

    return <Select id="carrier-select"
                   data-testid={"create-pallet-carrier-select"}
                   value={value}>
        {props.carrierList.map(carrier =>
            <MenuItem onClick={() => setValue(carrier)}
                      data-testid={`carrier-select-item-${carrier}`}
                      key={carrier}
                      value={carrier}>
            {carrier}
        </MenuItem>)}
    </Select>;
};

const CountryGroupSelector = (props: ICountryGroupSelectorProps) => {
    return <Select id="country-group-select"
                   data-testid={"create-pallet-country-select"}
                   disabled={props.disabled}
                   value={props.value}>
        {props.countryGroupList.map(countryGroup =>
            <MenuItem onClick={() => props.onChange(countryGroup)}
                      data-testid={`country-select-item-${countryGroup}`}
                      key={countryGroup}
                      value={countryGroup}>
                {t(`components.pallet.createPalletDialog.country.${countryGroup}`)}
            </MenuItem>)}
    </Select>;
};

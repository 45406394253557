import { ParcelCustomCreationCommand } from "./parcel-custom-creation-command";
import { ParcelCustomCreationResponse } from "./parcel-custom-creation-response";
import { ParcelBulkCreationMode } from "../../../../constants/constants";
import { parcelsService } from "../../../services/parcels";
import { DeliveryOrder } from "../../../model/delivery-order";
import { ParcelCreationResponse } from "../../../model/parcel-creation-response";

export class ParcelCustomCreationCommandHandler {
    public async handle(command: ParcelCustomCreationCommand) {
        if (command.deliveryOrderList.length === 0) {
            return ParcelCustomCreationResponse.empty();
        }

        const parcelCreationResponses: ParcelCreationResponse[] = [];
        for (const strategy of this.parcelCreationStrategiesSorted(command)) {
            const response = await this.handleCreation(command.operationCode, command.batchId,
                command.deliveryOrderList, strategy);

            parcelCreationResponses.push(response);
        }

        return ParcelCustomCreationResponse.fromParcelCreationResponses(parcelCreationResponses);
    }

    private async handleCreation(operationCode: string, batchId: number, deliveryOrderList: ReadonlyArray<DeliveryOrder>,
                                 strategy: ParcelBulkCreationMode) {
        return await parcelsService.createParcelsBulk(operationCode, batchId, deliveryOrderList.map(dor => dor.id),
            strategy);
    }

    private parcelCreationStrategiesSorted(command: ParcelCustomCreationCommand) {
        const list: ParcelBulkCreationMode[] = [];

        if (command.isOneParcelPerProductWithMonoParcelFlagSelected) {
            list.push("OneParcelPerProductWithMonoParcelFlag");
        }

        if (command.isAnyBasicStrategySelected) {
            list.push(command.isOneParcelPerOrderSelected ? "OneParcelPerOrder" : "OneParcelPerProductItem");

            return list as ReadonlyArray<ParcelBulkCreationMode>;
        }

        if (command.isMultiRefSelected) {
            list.push("OneParcelPerOrderWithMultiRef");
        }

        if (command.isMonoRefSelected) {
            list.push("OneParcelPerOrderWithMonoRef");
        }

        if (command.isComponentsSelected) {
            list.push("OneParcelPerComponentProduct");
        }

        return list as ReadonlyArray<ParcelBulkCreationMode>;
    }
}

import React from "react";
import { Dialog } from "@mui/material";
import { ProgressDialogContent } from "./progress-dialog-content";

interface IProgressDialogProps {
    open: boolean;
    message: string;
    className?: string;
}

export const ProgressDialog = (props: IProgressDialogProps) => {
    return <Dialog open={props.open}>
        <ProgressDialogContent className={props.className} message={props.message} />
    </Dialog>;
};

import { IBulkParcelUploadImportValidationConstraint } from "../bulk-parcel-upload-import-validator";
import { t } from "i18next";
import { BulkParcelUploadDataReader } from "../bulk-parcel-upload-data-reader";

export class BulkParcelUploadBaseConstraint implements IBulkParcelUploadImportValidationConstraint {
    public validateHeader(headerColumns: string[]) {
        if (headerColumns.filter(c => c === t(BulkParcelUploadDataReader.columnNameDeliveryOrderId)).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerDeliveryOrderMissing"));
        }

        if (headerColumns.filter(c => c === t(BulkParcelUploadDataReader.columnNameProductId)).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerProductIdMissing"));
        }

        if (headerColumns.filter(c => c === t(BulkParcelUploadDataReader.columnNameQuantityAssigned)).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerQuantityAssignedMissing"));
        }

        if (headerColumns.filter(c => c === t(BulkParcelUploadDataReader.columnNameParcelIdentifier)).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerParcelIndexMissing"));
        }

        if (headerColumns.filter(c => c === t(BulkParcelUploadDataReader.columnParcelId)).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerParcelIdMissing"));
        }

        return true;
    }

    public validateLine(lineNumber: number, content: Map<string, string>) {
        if (!this.isNumber(content.get(t(BulkParcelUploadDataReader.columnNameDeliveryOrderId)))) {
            throw new Error(t("bulkParcelUpload.error.contentDeliveryOrderIdNotNumber", {lineNumber}));
        }

        if (!this.isNumber(content.get(t(BulkParcelUploadDataReader.columnNameProductId)))) {
            throw new Error(t("bulkParcelUpload.error.contentProductIdIdNotNumber", {lineNumber}));
        }

        if (!this.isNumber(content.get(t(BulkParcelUploadDataReader.columnNameQuantityAssigned)))) {
            throw new Error(t("bulkParcelUpload.error.contentQuantityNotNumber", {lineNumber}));
        }

        if (content.get(t(BulkParcelUploadDataReader.columnParcelId)) !== "" &&
            !this.isNumber(content.get(t(BulkParcelUploadDataReader.columnParcelId)))
        ) {
            throw new Error(t("bulkParcelUpload.error.contentParcelIdNotNumber", {lineNumber}));
        }

        return true;
    }

    private isNumber(value: string | undefined): boolean {
        return Number(value).toString() === value;
    }
}

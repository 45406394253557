import { Parcel } from "../../../../model/parcel";
import { parcelsService } from "../../../../services/parcels";
import { IParcelDocumentProvider } from "./parcel-document-provider-interface";
import { ParcelDocument } from "./parcel-document";
import { DocumentFormat } from "../document-printer";
import { MultiParcelDocument } from "./multiparcel-document";
import { IMultiParcelDocumentProvider } from "./multiparcel-document-provider-interface";

class PdfLabelProvider implements IParcelDocumentProvider, IMultiParcelDocumentProvider {
    private readonly _hasLabel = true;
    private readonly _hasPreparationOrder = false;

    public get hasLabel(): boolean {
        return this._hasLabel;
    }

    public get hasPreparationOrder(): boolean {
        return this._hasPreparationOrder;
    }

    public async fetch(operationCode: string, batchId: number, parcel: Parcel): Promise<ParcelDocument> {
        const response = await parcelsService.label(operationCode, batchId, parcel.id, "Pdf");

        return new ParcelDocument(parcel.id, DocumentFormat.Pdf, response.succeeded ? response.Value! : undefined);
    }

    public async fetchMultiparcel(operationCode: string, batchId: number, parcel: Parcel[]): Promise<MultiParcelDocument> {
        const response = await parcelsService.multipageDocument(operationCode, batchId, parcel.map(p => p.id), "PdfLabel203dpi");

        return new MultiParcelDocument(response.succeededParcelIds, DocumentFormat.Pdf, response.file);
    }
}

export const pdfLabelProvider = new PdfLabelProvider();

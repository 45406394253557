import { Parcel } from "../../../../model/parcel";
import { IParcelDocumentProvider } from "./parcel-document-provider-interface";
import { parcelsService } from "../../../../services/parcels";
import { ParcelDocument } from "./parcel-document";
import { DocumentFormat } from "../document-printer";
import { MultiParcelDocument } from "./multiparcel-document";
import { IMultiParcelDocumentProvider } from "./multiparcel-document-provider-interface";
import { ParcelDetailsArrangement } from "../../../../model/parcel-details-arrangement";

class PreparationOrderAndLabelOnOnePageProvider implements IParcelDocumentProvider, IMultiParcelDocumentProvider {
    private readonly _hasLabel = true;
    private readonly _hasPreparationOrder = true;

    public get hasLabel(): boolean {
        return this._hasLabel;
    }

    public get hasPreparationOrder(): boolean {
        return this._hasPreparationOrder;
    }

    public async fetch(operationCode: string, batchId: number, parcel: Parcel): Promise<ParcelDocument> {
        const response = await parcelsService.multipageDocument(operationCode, batchId, [parcel.id], "LabelAndPreparationOrderOnSamePage",
            [ParcelDetailsArrangement.createFromParcel(parcel)]);

        return new ParcelDocument(parcel.id, DocumentFormat.PdfLandscape, response.file);
    }

    public async fetchMultiparcel(operationCode: string, batchId: number, parcel: Parcel[]): Promise<MultiParcelDocument> {
        const response = await parcelsService.multipageDocument(operationCode, batchId, parcel.map(p => p.id), "LabelAndPreparationOrderOnSamePage",
            parcel.map(p => ParcelDetailsArrangement.createFromParcel(p)));

        return new MultiParcelDocument(response.succeededParcelIds, DocumentFormat.PdfLandscape, response.file);
    }
}

export const preparationOrderAndLabelOnOnePageProvider = new PreparationOrderAndLabelOnOnePageProvider();

import "./style.scss";

import { Dialog, Card, Grid, Typography } from "@mui/material";

import { Icon } from "../../../../common/component/icon/icon";
import { ParcellingDeliveryOrderDetail } from "./parcelling-delivery-order-detail";
import { ParcellingParcelList } from "./parcelling-parcel-list";
import React from "react";
import { Title } from "../../../../common/component/title/title";
import { observer } from "mobx-react";
import { parcellingCommand } from "./parcelling-command";
import { parcellingModel } from "./parcelling-model";
import { t } from "i18next";
import { DialogMessageIcon, dialogModel } from "../../../../common/component/dialog/dialog-model";
import { DeliveryOrderCancellationRequestStatus } from "../../../model/delivery-order-cancellation-request-status";

@observer
export class Parcelling extends React.Component<{}, {}> {

    public render() {
        if (!parcellingModel.deliveryOrder) {
            return <div />;
        }

        return <div>
            <Dialog
                disableEscapeKeyDown
                disableEnforceFocus
                className="delivery-order-parcelling"
                fullScreen
                open={parcellingModel.deliveryOrder !== undefined}
                data-testid="parcelling">
                <div className="delivery-order-parcelling-inner">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className="list-header">
                                <Card className="pinkCard">
                                    <Title title="deliveryOrderParcelling.title"
                                    className={"delivery-order-parcelling__card-title"} />
                                </Card>
                                <div>
                                    <Icon
                                        align={"right"}
                                        rightText={t("deliveryOrderParcelling.back")}
                                        icon="arrow_back" color="primary"
                                        onClick={
                                            () => parcellingCommand.closeParcelling()
                                        }
                                        disabled={!parcellingModel.canExit}
                                    />
                                    <Typography
                                        className={"errorParcelling" + (parcellingModel.canExit ? " hidden" : "")}>
                                        {t("deliveryOrderParcelling.parcellingNotCompleted")}
                                    </Typography>
                                </div>
                            </div>
                            <Card className="parcellingCard">
                                <ParcellingDeliveryOrderDetail />
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="list-header">
                                <Card className="pinkCard">
                                    <Title title="deliveryOrderParcelling.parcelList.parcelList"
                                           className={"delivery-order-parcelling__card-title"} />
                                </Card>
                                <Icon icon="add" color="primary" className="button50h"
                                    disabled={
                                        parcellingModel.hasParcelInEditMode || parcellingModel.isDeliveryOrderParcelled
                                    }
                                    rightText={t("deliveryOrderParcelling.addNewParcel")}
                                    onClick={() => this.executeAddParcel()}
                                      contained
                                />
                            </div>
                            <Card className="parcellingCard">
                                <ParcellingParcelList
                                    operationCode={parcellingModel.operationCode}
                                    batchId={parcellingModel.batchId} />
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </div>;
    }

    private executeAddParcel() {
        switch (parcellingModel.deliveryOrder!.cancellationRequestStatus) {
            case DeliveryOrderCancellationRequestStatus.Pending:
                dialogModel.confirmBox(
                    t("deliveryOrderParcelling.addNewParcel"),
                    t("deliveryOrderParcelling.validation.orderPendingCancellationWarning"),
                    () => parcellingModel.addParcel(parcellingModel.batchId),
                    DialogMessageIcon.Warning);
                break;
            case DeliveryOrderCancellationRequestStatus.Confirmed:
                dialogModel.alert(
                    t("deliveryOrderParcelling.addNewParcel"),
                    t("deliveryOrderParcelling.validation.orderPendingCancellationError"),
                    DialogMessageIcon.Error,
                );
                break;
            default:
                parcellingModel.addParcel(parcellingModel.batchId);
        }
    }
}

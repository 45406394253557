import { IParcel } from "./parcel";
import { IDeliveryOrder } from "./delivery-order";

export enum ParcelTrackingDataStatus {
    Announced,
    Altered,
}

export class ParcelTrackingData {
    private _carrier: string;
    private _tracker: string;
    private _status: ParcelTrackingDataStatus;

    public constructor(carrier: string, tracker?: string) {
        this._carrier = carrier;
        this._tracker = tracker || "";
        this._status = ParcelTrackingDataStatus.Announced;
    }

    public static buildFromParcel(parcel: IParcel, deliveryOrder?: IDeliveryOrder): ParcelTrackingData {
        return new ParcelTrackingData(
            (parcel.carrier ?? deliveryOrder?.carrier) || "",
            parcel.parcelTracker || "",
        );
    }

    public get carrier(): string {
        return this._carrier;
    }

    public get tracker(): string {
        return this._tracker;
    }

    public get status(): ParcelTrackingDataStatus {
        return this._status;
    }

    public updateParcelTrackingData(carrier: string, tracker: string) {
        if (this._carrier === carrier && this._tracker === tracker) {
            return;
        }

        this._carrier = carrier;
        this._tracker = tracker;
        this._status = ParcelTrackingDataStatus.Altered;
    }
}

import { DeliveryOrder } from "../../../model/delivery-order";
import { t } from "i18next";
import { stringValue } from "../../../../common/field/renderer";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { ExportDataItem } from "../../../model/export-data-item";
import { ExportDataContents, ExportType } from "../../../model/export-data-contents";
import { context } from "../../../../context/context";
import { FeatureToggle } from "../../../../context/app-configuration";

interface IRefProductDataEntry {
    toDataItem(includeQuantity: boolean): ExportDataItem;
    incQuantity(quantity: number): void;
    quantity: number;
}

export class OrderDataExporter {

    constructor(private name: string, private isProductRefExportAvailable: boolean = false) {
    }

    public getExportData(
        operationCode: string,
        batchId: number,
        additionalFields: string[],
        sortFilterData: ReadonlyArray<DeliveryOrder>,
        exportType: ExportType): ExportDataContents {

        if (exportType === ExportType.OnlyRefProducts || exportType === ExportType.PickingList) {
            return ExportDataContents.forOperationBatchWithName(
                `${this.name}_${exportType === ExportType.OnlyRefProducts ? "ref_products" : "picking_list"}`,
                operationCode,
                batchId,
                this.getHeaderForRefProductsData(exportType === ExportType.PickingList),
                this.getOnlyRefProductsData(sortFilterData, exportType === ExportType.PickingList),
            );
        } else {
            return ExportDataContents.forOperationBatchWithName(
                `${this.name}_all_columns`,
                operationCode,
                batchId,
                this.getHeaderForAllColumnsData(additionalFields),
                this.getAllColumnsData(sortFilterData, additionalFields),
            );
        }
    }

    private getHeaderForAllColumnsData(additionalFields: string[]): ExportDataItem {
        return new ExportDataItem([
            t("model.deliveryOrder.batchId"),
            t("model.deliveryOrder.orderId"),
            t("model.deliveryOrder.deliveryOrderId"),
            t("model.deliveryOrder.parcelCount"),
            t("model.deliveryOrder.carrier"),
            t("model.deliveryOrder.address"),
            t("model.deliveryOrderDetail.productId"),
            t("model.deliveryOrderDetail.ean13List"),
            t("model.deliveryOrderDetail.supplierReference"),
            t("model.deliveryOrderDetail.name"),
            t("model.deliveryOrderDetail.quantity"),
            t("model.deliveryOrderDetail.quantityParcelled"),
            t("model.deliveryOrderDetail.quantityLabeled"),
            t("model.deliveryOrderDetail.quantityShipped"),
            ...(context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) ?
                [t("model.deliveryOrderDetail.quantityStockout")] : []),
            t("model.deliveryOrderDetail.weight"),
            ...additionalFields.map(field => field),
        ]);
    }

    private getAllColumnsData(sortFilterData: ReadonlyArray<DeliveryOrder>, additionalFields: string[]): ExportDataItem[] {
        const items: ExportDataItem[] = [];
        sortFilterData.forEach(deliveryOrder => {
            deliveryOrder.details.forEach(deliveryOrderDetails => items.push(this.getAllColumnsDataItem(deliveryOrder, deliveryOrderDetails, additionalFields)));

        });

        return items;
    }

    private getAllColumnsDataItem(deliveryOrder: DeliveryOrder, deliveryOrderDetail: DeliveryOrderDetail, additionalFields: string[]): ExportDataItem {
        return new ExportDataItem([
            deliveryOrder.batchId,
            deliveryOrder.orderId,
            deliveryOrder.id,
            deliveryOrder.parcelCount,
            stringValue(deliveryOrder, "carrier"),
            stringValue(deliveryOrder, "address"),
            deliveryOrderDetail.productId,
            deliveryOrderDetail.ean13List.map(ean => ean).join(", "),
            stringValue(deliveryOrderDetail, "supplierReference"),
            stringValue(deliveryOrderDetail, "name"),
            deliveryOrderDetail.quantity,
            deliveryOrderDetail.quantityParcelled,
            deliveryOrderDetail.quantityLabeled,
            deliveryOrderDetail.quantityShipped,
            ...(context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) ?
                [deliveryOrderDetail.quantityStockout] : []),
            stringValue(deliveryOrderDetail, "weight"),
            ...additionalFields.map(field => stringValue(deliveryOrderDetail, field as any)),
        ]);
    }

    private getHeaderForRefProductsData(includeQuantity: boolean = false): ExportDataItem {
        const header = [
            t("model.deliveryOrderDetail.productId"),
            t("model.deliveryOrderDetail.ean13List"),
            t("model.deliveryOrderDetail.supplierReference"),
            t("model.deliveryOrderDetail.name")];

        if (includeQuantity) {
            header.push(t("model.deliveryOrderDetail.quantity"));
        }

        return new ExportDataItem(header);
    }

    private getOnlyRefProductsData(sortFilterData: ReadonlyArray<DeliveryOrder>, includeQuantity: boolean = false): ExportDataItem[] {
        if (!this.isProductRefExportAvailable) {
            throw new Error(`Product references cannot be exported in ${this.name}.`);
        }

        const dataMap: Map<number, IRefProductDataEntry> = new Map<number, IRefProductDataEntry>();

        sortFilterData.forEach(deliveryOrder => {
            deliveryOrder.details.forEach(deliveryOrderDetails => {
                const refProductDataEntry = OrderDataExporter.RefProductDataEntry.FromDeliveryOrderDetail(deliveryOrderDetails);

                if (dataMap.has(refProductDataEntry.productId)) {
                    dataMap.get(refProductDataEntry.productId)!.incQuantity(refProductDataEntry.quantity);
                } else {
                    dataMap.set(refProductDataEntry.productId, refProductDataEntry);
                }
            });
        });

        return Array
            .from(dataMap.values())
            .filter(entry => !includeQuantity || entry.quantity > 0)
            .map(entry => entry.toDataItem(includeQuantity));
    }

    private static RefProductDataEntry = class implements IRefProductDataEntry {
        constructor(private _productId: number, private _ean13List: string, private _supplierReference: string, private _name: string, private _quantity: number) {}

        public toDataItem(includeQuantity: boolean): ExportDataItem {
            const values = [
                this._productId,
                this._ean13List,
                this._supplierReference,
                this._name,
            ];

            if (includeQuantity) {
                values.push(this._quantity);
            }

            return new ExportDataItem(values);
        }

        public get productId() {
            return this._productId;
        }

        public get quantity() {
            return this._quantity;
        }

        public incQuantity(quantity: number) {
            return this._quantity += quantity;
        }

        public static FromDeliveryOrderDetail(deliveryOrderDetail: DeliveryOrderDetail) {
            return new OrderDataExporter.RefProductDataEntry(
                deliveryOrderDetail.productId,
                deliveryOrderDetail.ean13List.map(ean => ean).join(", "),
                stringValue(deliveryOrderDetail, "supplierReference"),
                stringValue(deliveryOrderDetail, "name"),
                deliveryOrderDetail.quantityAvailable,
            );
        }
    };
}

import React, { ReactNode } from "react";
import { Grid, Typography } from "@mui/material";
import { t } from "i18next";

interface IBusinessDocumentationCategoryProps {
    category: string;
    children: ReactNode;
}

export const BusinessDocumentationCategory = (props: IBusinessDocumentationCategoryProps) => {
    return <>
        <Typography variant={"h3"}>{t(`businessDocumentation.category.${props.category}`)}</Typography>
        <Grid className={"business-documentation__category"} container spacing={20} columns={{xs: 2, sm: 4, md: 6, lg: 10, xl: 12}}>
            {props.children}
        </Grid>
    </>;
};

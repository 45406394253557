export type ExportDataValue = string | number;

export class ExportDataItem {
    private readonly _columns: ExportDataValue[];

    constructor(cells: ExportDataValue[]) {
        this._columns = cells;
    }

    get columns(): ExportDataValue[] {
        return this._columns;
    }
}

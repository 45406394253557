import * as React from "react";

import { TableCell, TableRow } from "@mui/material";

import { DeliveryOrder } from "../../../model/delivery-order";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { DeliveryOrderFilter } from "../../../model/delivery-order-filter";
import { ListSort } from "../../../../common/component/list/search-list/list-sort";
import { observer } from "mobx-react";
import { context } from "../../../../context/context";
import { FeatureToggle } from "../../../../context/app-configuration";

@observer
export class DeliveryOrderListSubSort extends ListSort<DeliveryOrder, DeliveryOrderDetail, DeliveryOrderFilter, {}> {

    public render() {
        return <TableRow className="search-item__sub-header">
            <TableCell colSpan={2} />
            {this.renderColumnSort("model.deliveryOrderDetail.productId", "details.productId")}
            {this.renderColumnSort("model.deliveryOrderDetail.ean13List", "details.ean13List")}
            {this.renderColumnSort("model.deliveryOrderDetail.supplierReference", "details.supplierReference")}
            {this.renderColumnSort("model.deliveryOrderDetail.name", "details.name")}
            {this.renderColumnSort("model.deliveryOrderDetail.quantity", "details.quantity")}
            {this.renderColumnSort("model.deliveryOrderDetail.quantityParcelled", "details.quantityParcelled")}
            {this.renderColumnSort("model.deliveryOrderDetail.quantityLabeled", "details.quantityLabeled")}
            {this.renderColumnSort("model.deliveryOrderDetail.quantityShipped", "details.quantityShipped")}
            {context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) && this.renderColumnSort("model.deliveryOrderDetail.quantityStockout", "details.quantityStockout")}
            {this.renderColumnSort("model.deliveryOrderDetail.weight", "details.weight")}
            {this.renderAdditionalColumnSubSort()}
        </TableRow >;
    }
}

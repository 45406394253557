import * as React from "react";

import { inject, observer } from "mobx-react";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { context } from "../../../../context/context";
import { getLabelFormat } from "../../../../constants/constants";
import { Permission } from "../../../../context/permission";
import { PalletManagementHeader } from "./pallet-management-header";
import { PalletsList } from "../pallets-list/pallets-list";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { PalletsListSubItem } from "../pallets-list/pallets-list-sub-item";
import { PalletFilter } from "../pallets-list/pallet-filter";
import { PalletDataProvider } from "../pallets-list/pallet-data-provider";
import { filter } from "lodash";
import { PalletFulfillmentDialog } from "../pallet-fulfillment-dialog/pallet-fulfillment-dialog";
import { palletService } from "../../../services/pallet";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { t } from "i18next";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { ManagePalletActionType } from "../../../constants/constants";
import { emptyPalletParamActionValidator } from "./empty-pallet-param-action-validator";
import { qzManager } from "../../../qz/qz-manager";
import { PalletParcelRemoveDialog } from "../pallet-parcel-remove-dialog/pallet-parcel-remove-dialog";
import { SingleActionPicker } from "../../../../common/component/action/action-select/single-action-picker";
import { PalletCloseDialog } from "../pallet-closing/close-pallet-dialog";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Sound, useSound } from "../../../../common/hooks/use-sound";
import { documentService } from "../../../services/document";

const parcelManagementModel = new SearchListModel<PalletsListItem, PalletsListSubItem, PalletFilter>();

interface IPalletManagementState {
    isPalletFulfillmentDialogOpen: boolean;
    palletFulfillmentDialogPallets: PalletsListItem[];

    isPalletClosingDialogOpen: boolean;
    palletClosingDialogPallets: PalletsListItem[];

    isParcelRemovalDialogOpen: boolean;
    parcelRemovalPallets: PalletsListItem[];

    singleActionPickerAnchorElement: HTMLElement | null;
    singleActionPickerPalletItem: PalletsListItem | null;
}

@inject("routing")
@observer
export class PalletManagement extends PalletsList<IPalletManagementState> {
    public state: IPalletManagementState = {
        isPalletFulfillmentDialogOpen: false,
        palletFulfillmentDialogPallets: [],

        isPalletClosingDialogOpen: false,
        palletClosingDialogPallets: [],

        isParcelRemovalDialogOpen: false,
        parcelRemovalPallets: [],

        singleActionPickerAnchorElement: null,
        singleActionPickerPalletItem: null,
    };

    constructor(props: IHistoryProps) {
        super(props, parcelManagementModel, new PalletDataProvider("Open"));
    }

    protected renderActions() {
        return <>
            <PalletManagementHeader actionsList={this.actionsList}
                                    reloadPalletList={() => this.reloadPalletList()} />
            {this.state.isPalletFulfillmentDialogOpen &&
                <PalletFulfillmentDialog palletsList={this.state.palletFulfillmentDialogPallets}
                                         getPalletItemByScan={scanValue => this.getPalletItemByScan(scanValue)}
                                         onCancel={() => this.closePalletAssignment()}
                                         onSubmit={async (pallet, parcelLocator) =>
                                             await this.pushParcelToPallet(pallet, parcelLocator)} />}
            {this.state.isParcelRemovalDialogOpen &&
                <PalletParcelRemoveDialog palletList={this.state.parcelRemovalPallets}
                                          getPalletByParcelLocator={(locator: string) =>
                                              this.getPalletByParcelLocator(locator)}
                                          onCancel={() => this.closeParcelRemovalDialog()}
                                          onPalletRemove={() => this.reloadPalletList()} />}
            {this.state.isPalletClosingDialogOpen &&
                <PalletCloseDialog closingPalletsList={this.state.palletClosingDialogPallets}
                                   getPalletItemByScan={palletCode => this.getPalletItemByScan(palletCode)}
                                   onPalletClosed={() => this.reloadPalletList()}
                                   onClose={() => this.closePalletDialog()} />}
            {this.state.singleActionPickerAnchorElement &&
                <SingleActionPicker anchorElement={this.state.singleActionPickerAnchorElement}
                                    item={this.state.singleActionPickerPalletItem}
                                    list={this.singleActionsList}
                                    onActionComplete={() => this.reloadPalletList()}
                                    onPickerClose={() => this.closeSingleActionPickerMenu()} />}
        </>;
    }

    protected get actionsList(): Array<IActionCommand<PalletsListItem, ManagePalletActionType>> {
        return [
            {
                label: t("palletManagement.action.fulfillment.title"),
                value: "fulfillment" as ManagePalletActionType,
                loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
                execute: identifierList => this.openPalletFulfillment(identifierList),
                executeCustomScanMode: () => this.openPalletFulfillment([]),
                hideExecutionConfirmation: true,
                disableSingleActionPicker: true,
            },
            {
                label: t("palletManagement.action.printLabel.title"),
                value: "printLabel" as ManagePalletActionType,
                loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
                execute: async identifierList => await this.printLabel(identifierList),
            },
            {
                label: t("palletManagement.action.close.title"),
                value: "close" as ManagePalletActionType,
                loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
                execute: async identifierList => this.openClosePalletDialog(identifierList),
                executeCustomScanMode: () => this.openPalletCloseDialogForScan(),
                hideExecutionConfirmation: true,
            },
            {
                label: t("palletManagement.action.parcelRemoval.title"),
                value: "parcelRemoval" as ManagePalletActionType,
                loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
                execute: identifierList => this.openParcelRemoval(identifierList),
                executeCustomScanMode: () => this.openParcelRemoval([]),
                hideExecutionConfirmation: true,
                disableSingleActionPicker: true,
            },
            {
                label: t("palletManagement.action.cancel.title"),
                value: "cancel" as ManagePalletActionType,
                loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
                execute: async identifierList => await this.cancelPallet(identifierList),
                paramValidator: emptyPalletParamActionValidator,
            },
        ];
    }

    protected openPalletFulfillmentForSinglePallet(identifier: PalletsListItem) {
        this.setState({
            isPalletFulfillmentDialogOpen: true,
            palletFulfillmentDialogPallets: [identifier],
        });
    }

    private openPalletFulfillment(identifierList: PalletsListItem[] = []) {
        this.setState({
            isPalletFulfillmentDialogOpen: true,
            palletFulfillmentDialogPallets: identifierList,
        });
    }

    private closePalletAssignment() {
        this.setState({
            isPalletFulfillmentDialogOpen: false,
            palletFulfillmentDialogPallets: [],
        });
    }
    private openParcelRemoval(identifierList: PalletsListItem[] = []) {
        this.setState({
            isParcelRemovalDialogOpen: true,
            parcelRemovalPallets: identifierList,
        });
    }

    private closeParcelRemovalDialog() {
        this.setState({
            isParcelRemovalDialogOpen: false,
            parcelRemovalPallets: [],
        });
    }

    protected openSingleActionPickerMenu(anchor: any, item: PalletsListItem) {
        this.setState({
            singleActionPickerAnchorElement: anchor,
            singleActionPickerPalletItem: item,
        });
    }

    private closeSingleActionPickerMenu() {
        this.setState({
            singleActionPickerPalletItem: null,
            singleActionPickerAnchorElement: null,
        });
    }

    private openClosePalletDialog(identifierList: PalletsListItem[]) {
        this.setState({
            isPalletClosingDialogOpen: true,
            palletClosingDialogPallets: identifierList,
        });
    }

    private openPalletCloseDialogForScan() {
        this.setState({
            isPalletClosingDialogOpen: true,
            palletClosingDialogPallets: [],
        });
    }

    private closePalletDialog() {
        this.setState({
            isPalletClosingDialogOpen: false,
            palletClosingDialogPallets: [],
        });
    }

    private async printLabel(identifierList: PalletsListItem[]) {
        if (context.settings.printerType === "Pdf") {
            await this.printMultipleLabelPdf(identifierList);
        } else {
            await this.printLabelZpl(identifierList.map(p => p.id));
        }

        notificationModel.addSuccessMessage(t(`palletManagement.action.printLabel.multiple.success`, { count: identifierList.length }));

        await this.reloadPalletList();
    }

    private async printMultipleLabelPdf(identifierList: PalletsListItem[]) {
        const documentId = await documentService.createPalletLabel(identifierList.map(p => p.id), getLabelFormat(context.settings.printerType));
        const document = await documentService.getDocument(documentId, 50000);

        if (document.status === "Failed") {
            notificationModel.addErrorMessage(t(`palletManagement.action.printLabel.multiple.error`, { palletsCodes: identifierList.map(p => p.code).join(", ") }));
            return;
        }

        await qzManager.qzPrintOrDownloadFile(document.file!, context.settings.printerType);
    }

    private async printLabelZpl(palletsIds: string[]) {
        for (const p of palletsIds) {
            const data = await palletService.label(p, getLabelFormat(context.settings.printerType));
            await qzManager.qzPrintOrDownloadFile(data.Value!, context.settings.printerType);
        }
    }

    private async cancelPallet(list: PalletsListItem[]) {
        let nbFailed = 0;
        for (const p of list) {
            try {
                await palletService.cancelPallet(p.id);
            } catch (_err) {
                nbFailed++;
            }
        }

        await this.reloadPalletList();

        if (list.length !== nbFailed) {
            notificationModel.addSuccessMessage(this.getSuccessMessage("cancel", list.length - nbFailed));
        } else {
            notificationModel.addErrorMessage(t(`palletManagement.action.cancel.noItems`));
        }
    }

    private async pushParcelToPallet(pallet: PalletsListItem, parcelLocator: string) {
        try {
            await palletService.pushParcelOnPallet(pallet.palletId, parcelLocator);
        } catch (err) {
            useSound(Sound.Error);
            throw err;
        }

        notificationModel.addSuccessMessage(t(`components.pallet.fulfillment.success`, {
            palletCode: pallet.code,
            parcelId: parcelLocator,
        }));

        await this.refreshPalletItem(pallet, parcelLocator);
    }

    private loadExecuteParam(isScan: boolean, param: string) {
        return isScan ?
            this.getPalletListByScan(param) :
            this.searchListModel.selectedItemsList;
    }

    protected get isSelectionEnabled() {
        return context.hasPermission(Permission.FrontWrite);
    }

    protected get expandOnDefault() {
        return true;
    }

    protected customItemActionComponent(pallet: PalletsListItem) {
        return <Button key={"add-to-pallet"}
                       variant={"contained"}
                       color={"primary"}
                       size={"small"}
                       startIcon={<Add />}
                       data-testid={"add"}
                       onClick={() => this.openPalletFulfillmentForSinglePallet(pallet)}>
            {t("palletParcelList.addParcels")}
        </Button>;
    }

    protected getPalletListByScan(scannedValue: string): PalletsListItem[] {
        return filter(this.searchListModel.list, pallet => pallet.code.toString() === scannedValue);
    }

    private getPalletByParcelLocator(locator: string): PalletsListItem | undefined {
        return this.searchListModel.list.find(pallet => pallet.hasParcelWithLocator(locator));
    }

    private getPalletItemByScan(scanValue: string) {
        const result = this.getPalletListByScan(scanValue);

        return result.length > 0 ? result[0] : undefined;
    }

    private getSuccessMessage(action: ManagePalletActionType, nbProcessed: number) {
        return t(`palletManagement.action.${action}.success` + (nbProcessed > 1 ? "Multiple" : ""),
            { count: nbProcessed });
    }
}

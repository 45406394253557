import * as React from "react";
import { observer } from "mobx-react";
import { Input } from "../input/input";

interface IDisplayFieldProps {
    propertyKey: string;
    label: string;
    value: string;
}

@observer
export class DisplayField extends React.Component<IDisplayFieldProps, {}> {

    public render() {
        return <Input propertyKey={this.props.propertyKey}
            errors={[]}
            label={this.props.label}
            value={this.props.value}
            disabled={true}
            onChange={() => { return; }} />;
    }
}

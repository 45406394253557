import * as React from "react";
import { t } from "i18next";
import * as lithiumIcon from "../../../../common/component/img/lithium.svg";
import * as un_code from "../../../../common/component/img/un.svg";
import { Tooltip } from "@mui/material";
import { lithiumUnCodeList } from "../../../../constants/constants";

import "./style.scss";

interface IUnCodeIconProps {
    value: string;
}

export const UnCodeIcon = (props: IUnCodeIconProps) => {
    if (lithiumUnCodeList.indexOf(props.value) > -1) {
        return <Tooltip title={t("components.uncodeIcon.tooltip", { code: props.value })}>
            <img src={lithiumIcon}
                 className={"un-code-icon"}
                 alt={t("components.uncodeIcon.tooltip", { code: props.value })} />
        </Tooltip>;
    }

    return <Tooltip title={t("components.uncodeIconGeneral.tooltip")}>
        <img src={un_code}
             className="un-code-icon"
             alt={t("components.uncodeIconGeneral.tooltip")} />
    </Tooltip>;
};

export class MultiparcelPrintSummary {
    private readonly _nbParcelsPrinted: number;
    private readonly _nbFailedParcels: number;

    constructor(nbParcelsPrinted: number, nbFailedParcels: number) {
        this._nbParcelsPrinted = nbParcelsPrinted;
        this._nbFailedParcels = nbFailedParcels;
    }

    public get nbParcelsPrinted(): number {
        return this._nbParcelsPrinted;
    }

    public get nbFailedParcels(): number {
        return this._nbFailedParcels;
    }
}

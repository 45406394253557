import React, { ReactNode, ReactNodeArray } from "react";

export interface IHeaderWidgetProps {
    children: ReactNode | ReactNodeArray;
}

export const HeaderWidgetGroup = (props: IHeaderWidgetProps) => {
    return <div className={"main-header__group"}>
        {props.children}
    </div>;
};

import { BulkParcelUploadExportLine } from "../../../../model/bulk-parcel-upload-export-line";
import { flatMap, groupBy, mapValues, sumBy } from "lodash";
import { DeliveryOrder } from "../../../../model/delivery-order";
import { ParcelExportStrategy } from "./parcel-export-strategy";
import { ProductExportStrategy } from "./product-export-strategy";
import { ExportDataContents } from "../../../../model/export-data-contents";
import { IExportDataFormat } from "./data-format/export-data-format";
import { ExportDataItem } from "../../../../model/export-data-item";
import { Pallet } from "../../../../model/pallet";
import { ExportDataWorksheet } from "../../../../model/export-data-worksheet";
import { CarrierCountryGroup } from "../../../../carrier-country-group";
import { t } from "i18next";

export class BulkParcelDataExporter {
    private readonly _operationCode: string;
    private readonly _batchId: number | undefined;
    private readonly _parcelExportStrategy: ParcelExportStrategy;
    private readonly _productExportStrategy: ProductExportStrategy;
    private readonly _dataFormatStrategy: IExportDataFormat;
    private readonly _excludeShippedDeliveryOrders: boolean;

    constructor(
        operationCode: string,
        batchId: number | undefined,
        parcelExportStrategy: ParcelExportStrategy,
        productExportStrategy: ProductExportStrategy,
        dataFormatStrategy: IExportDataFormat,
        excludeShippedDeliveryOrders: boolean,
    ) {
        this._operationCode = operationCode;
        this._batchId = batchId;
        this._parcelExportStrategy = parcelExportStrategy;
        this._productExportStrategy = productExportStrategy;
        this._dataFormatStrategy = dataFormatStrategy;
        this._excludeShippedDeliveryOrders = excludeShippedDeliveryOrders;
    }

    public getExportData(deliveryOrderList: Map<number, DeliveryOrder>, warehouseOpenPalletList: Pallet[],
                         carrierCountryGroups?: CarrierCountryGroup[]) {

        const exportWorksheets: ExportDataWorksheet[] = [];
        const operationExportWorksheetData: ExportDataItem[] = [];
        deliveryOrderList.forEach(deliveryOrder => {
            if (this._excludeShippedDeliveryOrders && deliveryOrder.status === "Shipped") {
                return;
            }

            operationExportWorksheetData.push(...this.exportDataFromDeliveryOrder(deliveryOrder, warehouseOpenPalletList)
               .map(l => this._dataFormatStrategy.getDataForLine(l)));
        });
        exportWorksheets.push(new ExportDataWorksheet(this._dataFormatStrategy.header, operationExportWorksheetData));

        if (carrierCountryGroups) {
            exportWorksheets.push(new ExportDataWorksheet(
                new ExportDataItem([t("model.parcelExport.palletCarrier"), t("model.parcelExport.palletCountry")]),
                carrierCountryGroups.map(g => new ExportDataItem([g.carrier, g.countryGroupName])),
                `${t("model.parcelExport.palletCarrier")} - ${t("model.parcelExport.palletCountry")}`,
            ));
        }

        return ExportDataContents.forOperationBatchWithMultipleWorksheets("parcel_bulk_export", this._operationCode,
            this._batchId, exportWorksheets);
    }

    private exportDataFromDeliveryOrder(deliveryOrder: DeliveryOrder, warehouseOpenPalletList: Pallet[]) {
        return [
            ...this.exportParcelsFromDeliveryOrder(deliveryOrder, warehouseOpenPalletList),
            ...this.exportOrderDetails(deliveryOrder),
        ];
    }

    private exportOrderDetails(deliveryOrder: DeliveryOrder): BulkParcelUploadExportLine[] {
        const parcelledProducts = this.getParcelledProducts(deliveryOrder);

        let currentParcelIndex = deliveryOrder.parcels.length + 1;

        return deliveryOrder.details
            .filter(detail => parcelledProducts[detail.productId] === undefined || parcelledProducts[detail.productId] !== detail.quantity)
            .flatMap(detail => {
                let stockoutQuantity = detail.quantityStockout;

                return this._productExportStrategy.getQuantitiesPerLine(detail).map(quantity => {
                    const lineStockoutQuantity = stockoutQuantity > 0 ? Math.min(quantity, stockoutQuantity) : 0;
                    stockoutQuantity -= lineStockoutQuantity;

                    const exportLine = new BulkParcelUploadExportLine(
                        deliveryOrder.operationCode,
                        deliveryOrder.batchId,
                        deliveryOrder.orderId,
                        deliveryOrder.deliveryOrderId,
                        detail.productId,
                        detail.ean13List.join(","),
                        detail.supplierReference,
                        detail.labelReference,
                        quantity,
                        0,
                        lineStockoutQuantity,
                        currentParcelIndex,
                        deliveryOrder.carrier,
                        deliveryOrder.shippingAddress);

                    currentParcelIndex = this._parcelExportStrategy.nextIndex(currentParcelIndex);

                    return exportLine;
                });
            });
    }

    private exportParcelsFromDeliveryOrder(deliveryOrder: DeliveryOrder, warehouseOpenPalletList: Pallet[]) {
        return flatMap(deliveryOrder.parcels.map((parcel, parcelIndex) => {
            const pallet = parcel.palletCode !== undefined ?
                warehouseOpenPalletList.find(p => p.code === parcel.palletCode) : undefined;
            return parcel.details.map(detail => {
                return new BulkParcelUploadExportLine(
                    deliveryOrder.operationCode,
                    deliveryOrder.batchId,
                    deliveryOrder.orderId,
                    deliveryOrder.deliveryOrderId,
                    detail.productId,
                    detail.ean13List.join(","),
                    detail.supplierReference,
                    detail.labelReference,
                    this.quantityAvailableForProduct(deliveryOrder, detail.productId),
                    detail.quantity,
                    0,
                    parcelIndex + 1,
                    parcel.carrier,
                    deliveryOrder.shippingAddress,
                    parcel.parcelId,
                    parcel.parcelTracker !== null ? parcel.parcelTracker : "",
                    parcel.palletCode,
                    pallet?.externalId ?? "",
                    pallet?.countryGroup ?? "",
                    pallet?.carrier ?? "",
                    pallet?.creationComment ?? "",
                );
            });
        }));
    }

    private quantityAvailableForProduct(deliveryOrder: DeliveryOrder, productId: number) {
        const product = deliveryOrder.details.find(p => p.productId === productId);

        return product !== undefined ? product.quantityAvailable : 0;
    }

    private getParcelledProducts(deliveryOrder: DeliveryOrder) {
        return mapValues(groupBy(flatMap(deliveryOrder.parcels, p => p.details), line => line.productId),
            product => sumBy(product, line => line.quantity));
    }
}

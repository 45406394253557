import { IBulkParcelUploadImportValidationConstraint } from "../bulk-parcel-upload-import-validator";
import { BulkParcelUploadDataReader } from "../bulk-parcel-upload-data-reader";
import { t } from "i18next";

export class BulkParcelUploadBatchIdConstraint implements IBulkParcelUploadImportValidationConstraint {
    private readonly _validBatchIds: number[];

    constructor(validBatchIds: number[]) {
        this._validBatchIds = validBatchIds;
    }

    public validateHeader(headerColumns: string[]) {
        if (headerColumns.filter(c => c === BulkParcelUploadDataReader.columnNameBatchId).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerBatchIdMissing"));
        }

        return true;
    }

    public validateLine(lineNumber: number, content: Map<string, string>) {
        if (this._validBatchIds.includes(Number(content.get(BulkParcelUploadDataReader.columnNameBatchId)))) {
            return true;
        }

        throw new Error(t("bulkParcelUpload.error.invalidBatchIdProvided", {
            value: content.get(BulkParcelUploadDataReader.columnNameBatchId),
            lineNumber,
        }));
    }
}

import "./style.scss";

import * as React from "react";

import { NotificationBar } from "./notification-bar";
import { Snackbar } from "@mui/material";
import { notificationModel } from "../notification-model";
import { observer } from "mobx-react";

@observer
export class Message extends React.Component<{}, {}> {

  public render() {
    return <div>
      <Snackbar className="notificationSnackBar"
        open={notificationModel.persistentMessageList.length > 0}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      ><div>
          {notificationModel.persistentMessageList.map((message, idx) =>
            <NotificationBar
              dataTestid="error-msg"
              key={message.message}
              iconClassName={message.level}
              message={message.message}
              action={message.action}
              removeNotification={() => notificationModel.removePersistantMessage(idx)}
              index={idx}
              iconType="error"
            />)}</div>
      </Snackbar>
      <Snackbar className="notificationSnackBar"
        open={notificationModel.temporaryMessageList.length > 0}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        autoHideDuration={5000}
        onClose={() => notificationModel.removeTemporaryMessage(0)}
      ><div>
          {notificationModel.temporaryMessageList.map((message, idx) =>
            <NotificationBar
              dataTestid="success-msg"
              key={message.message}
              iconClassName={message.level}
              message={message.message}
              action={message.action}
              removeNotification={() => notificationModel.removeTemporaryMessage(idx)}
              index={idx}
              iconType={message.icon}
            />)}
        </div>
      </Snackbar>
    </div>;
  }
}

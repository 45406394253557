import "./style.scss";

import * as React from "react";

import {
    Card,
    Drawer,
    Button,
    Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import { t } from "i18next";
import { Icon } from "../icon/icon";
import { Progression } from "../progression/progression";
import { ErrorList } from "../error-list/error-list";

interface IProgressDrawerProps {
    onCancel?: React.ReactEventHandler<{}>;
    onClose?: React.ReactEventHandler<{}>;
    total: number;
    current: number;
    errors?: ReadonlyArray<string>;
}

@observer
export class ProgressDrawer extends React.Component<IProgressDrawerProps, {}> {

    public render() {
        return <Drawer
            disableEscapeKeyDown
            anchor="right"
            open={this.shouldBeOpen}
            data-testid="progress-drawer">
            <div className="progress-drawer">
                <div className="progress-drawer__section progress-drawer__section--align-self-end">
                    <Icon icon="close" onClick={this.props.onClose} tooltipPlacement="top-end"/>
                </div>
                <div className="progress-drawer__section">
                    <Progression
                        size={250}
                        nbCurrent={this.props.current}
                        nbLimit={this.props.total}/>
                </div>
                <div className="progress-drawer__section">
                    {this.props.current < this.props.total ?
                        <Button color="primary" onClick={this.props.onCancel}>
                            {t("components.progressDrawer.cancel")}
                        </Button> :
                        <Typography>{t("components.progressDrawer.done")}</Typography>
                    }
                </div>
                {this.props.children && <Card className="progress-drawer__section progress-drawer__section--summary">
                    {this.props.children}
                </Card>}
                {this.props.errors && <div className="progress-drawer__section">
                    <ErrorList errors={this.props.errors}/>
                </div>}
            </div>
        </Drawer>;
    }

    private get shouldBeOpen() {
        return this.props.current < this.props.total ||
            this.props.errors !== undefined && this.props.errors.length > 0;
    }
}

import React from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardHeader, Icon, Typography } from "@mui/material";
import { OperationProductsEditorModel } from "../../../../common/model/operation-products-editor-model";
import { t } from "i18next";

import "./style.scss";

export const ExportOperationProducts = () => {
    const params = useParams();
    const model: OperationProductsEditorModel = new OperationProductsEditorModel(params.operationCode ?? "");

    return <div className="operation-content-management">
        <Card className={"operation-content-management__section"}>
            <CardHeader title={t("components.exportOperationProducts.title")} />
            <div className={"operation-content-management__container"}>
                <Typography className={"operation-products-editor__description"} color={"textSecondary"} >
                    {t("components.exportOperationProducts.description")}
                </Typography>
                <Button component="span" variant="contained" color="primary" startIcon={<Icon>cloud_download</Icon>}
                        onClick={async () => await model.downloadTemplateWithExportedData(`${params.operationCode}_operation_products`)}>
                    {t("components.exportOperationProducts.download")}
                </Button>
            </div>
        </Card>
    </div>;
};

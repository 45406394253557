import React from "react";
import { observer } from "mobx-react";
import { IImportModuleProps } from "./iimport-module-props";
import { t } from "i18next";
import { Icon } from "../../../../common/component/icon/icon";
import { Typography } from "@mui/material";
import { find } from "lodash";

@observer
export class SummaryFileImport extends React.Component<IImportModuleProps> {

    public getImportedColumns() {
        if (this.props.importFileModel.selectedCheckBoxList.length > 0) {
            return this.props.importFileModel.selectedCheckBoxList.map(value =>
                <div key={value} className="imported-col-name" data-testid={`col-to-import-${value}`}>{value}</div>,
            );
        } else {
            return <div>{t("components.importList.nothingImportMessage")}</div>;
        }
    }

    public render() {
        const importedDataList = this.getImportedColumns();

        return <Typography component="div" className="container-summary">
            {
                this.props.importFileModel.refColumnSelected.map(column => {
                    const refPropertyLabel = find(
                        this.props.importFileModel.availableReferenceColumns,
                        item => item.value === this.props.importFileModel.refPropertyColumnToReferenceMap[column],
                    )!.display;

                    return (
                        <div className="summary-row">
                            <div className="summary-title"><Icon icon="note_add" /></div>
                            <span className="ref-col-name" data-testid={`ref-col-${column}`}>
                                {column}
                                </span>
                            <Icon icon="settings_ethernet" />
                            <span className="ref-prop-name" data-testid={`ref-prop-${refPropertyLabel}`}>{refPropertyLabel}</span>
                            <div className="summary-title"><Icon icon="cloud" /></div>
                        </div>
                    );
                })
            }
            <div className="summary-row imported-data">
                <div className="summary-title">{t("components.importList.importedData")}</div>
                <div className="imported-col-container">{importedDataList}</div>
            </div>
        </Typography>;
    }
}

import { CreatePalletCommand } from "./create-pallet-command";
import { palletService } from "../../services/pallet";
import { PalletAddParcelHandler } from "../pallet-add-parcel/pallet-add-parcel-handler";
import { PalletAddParcelCommand } from "../pallet-add-parcel/pallet-add-parcel-command";
import { t } from "i18next";

export class CreatePalletHandler {
    public async handle(command: CreatePalletCommand) {
        const palletCountryGroup = command.palletCountryGroup ??
            await this.fetchPalletCountryForCarrierGroup(command.warehouseCode, command.palletCarrierGroup);

        let pallet = !command.externalId ?
            await this.createPallet(command.warehouseCode, palletCountryGroup, command.palletCarrierGroup,
                command.maxParcelWeight, command.maxWeight, command.creationComment) :
            await this.createPalletWithExternalId(command.warehouseCode, command.externalId, palletCountryGroup,
                command.palletCarrierGroup, command.maxParcelWeight, command.maxWeight, command.creationComment);

        const handler = new PalletAddParcelHandler();
        for (const parcel of command.parcels) {
            pallet = await handler.handle(PalletAddParcelCommand.createWithParcelId(pallet, parcel.id));
        }

        return pallet;
    }

    private async fetchPalletCountryForCarrierGroup(warehouseCode: string, carrierGroup: string) {
        const carrierCountryGroups = await palletService.loadCarrierCountryList(warehouseCode);
        const countryGroup = carrierCountryGroups.filter(c => c.carrier === carrierGroup);

        if (countryGroup.length !== 1) {
            throw new Error(t("error.command.createPallet.unableToLocateCountryGroup"));
        }

        return countryGroup[0].countryGroupName;
    }

    private async createPallet(warehouseCode: string, palletCountryGroup: string, palletCarrierGroup: string,
                               maxParcelWeight: number, maxWeight: number, creationComment: string) {
        return (await palletService.createPallet(warehouseCode, palletCountryGroup,
            palletCarrierGroup, 1, maxParcelWeight, maxWeight, creationComment))[0];
    }

    private async createPalletWithExternalId(warehouseCode: string, externalId: string[], palletCountryGroup: string,
                                             palletCarrierGroup: string, maxParcelWeight: number, maxWeight: number,
                                             creationComment: string) {
        return (await palletService.createPalletWithExternalId(warehouseCode, externalId, palletCountryGroup,
            palletCarrierGroup, 1, maxParcelWeight, maxWeight, creationComment))[0];
    }
}

import { useEffect } from "react";
import { MobileLoginQrCode } from "../../../common/model/mobile-login-qr-code";
import { useRemotePrinterContextDispatcher } from "../../context/printer-context/remote-printer-context";
import { authenticationService } from "../../../services/authentication";
import { User } from "../../../context/user";
import { context } from "../../../context/context";
import { t } from "i18next";

export const useMobileLogin = (mobileQrCode: MobileLoginQrCode | undefined,
                               onSuccess: () => void,
                               onErrorMessage: (error: string) => void) => {
    const remotePrinterContextDispatcher = useRemotePrinterContextDispatcher();

    return useEffect(() => {
        if (!mobileQrCode) {
            return;
        }

        (async () => {
            try {
                const response = await authenticationService.loginWithMobileToken(mobileQrCode.mobileToken);

                if (mobileQrCode.printer) {
                    remotePrinterContextDispatcher({
                        action: "set",
                        printerId: mobileQrCode.printer.id,
                        printerName: mobileQrCode.printer.name,
                        isZpl: mobileQrCode.printer.type === "zpl",
                    });
                }

                const user = new User(mobileQrCode.userName, response.access_token, "ds");
                context.setCurrentUser(user);
                await context.initAuthenticatedContextAsync(true);

                onSuccess();
            } catch (err) {
                onErrorMessage(t("components.scanAndShip.login.error.invalidToken"));
            }
        })();
    }, [mobileQrCode]);
};

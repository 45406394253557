import { RefObject } from "react";
import { useBrowserBarcodeDetector } from "./use-browser-barcode-detector";
import { useZxingBarcodeDetector } from "./use-zxing-barcode-detector";

export const useCameraBarcodeScanner = (cameraDeviceId: string,
                                        inputScannerRef: RefObject<HTMLVideoElement>,
                                        onValueScanned: (value: string) => void,
                                        valueValidator: (value: string) => boolean
                                            = (_value: string) => true) => {
    try {
        useBrowserBarcodeDetector(inputScannerRef, onValueScanned, valueValidator);
    } catch (err) {
        useZxingBarcodeDetector(cameraDeviceId, inputScannerRef, onValueScanned, valueValidator);
    }
};

import {
    IPalletShippingDeliveryOrderDependency,
    PalletShippingDeliveryOrderDependency,
} from "./pallet-shipping-delivery-order-dependency";
import { IPalletShippingParcelDependency, PalletShippingParcelDependency } from "./pallet-shipping-parcel-dependency";
import { IPalletShippingPalletDependency, PalletShippingPalletDependency } from "./pallet-shipping-pallet-dependency";

export interface IPalletShippingDependencies {
    deliveryOrders: IPalletShippingDeliveryOrderDependency[];
    parcels: IPalletShippingParcelDependency[];
    pallets: IPalletShippingPalletDependency[];
}

export class PalletShippingDependencies {
    private readonly _deliveryOrders: PalletShippingDeliveryOrderDependency[];
    private readonly _parcels: PalletShippingParcelDependency[];
    private readonly _pallets: PalletShippingPalletDependency[];

    constructor(data: IPalletShippingDependencies) {
        this._deliveryOrders = data.deliveryOrders.map(d => new PalletShippingDeliveryOrderDependency(d));
        this._parcels = data.parcels.map(p => new PalletShippingParcelDependency(p));
        this._pallets = data.pallets.map(p => new PalletShippingPalletDependency(p));
    }

    get deliveryOrders(): PalletShippingDeliveryOrderDependency[] {
        return this._deliveryOrders;
    }

    get parcels(): PalletShippingParcelDependency[] {
        return this._parcels;
    }

    get pallets(): PalletShippingPalletDependency[] {
        return this._pallets;
    }

    get hasDeliveryOrderDependencies() {
        return this._deliveryOrders.length > 0;
    }

    get hasParcelDependencies() {
        return this._parcels.length > 0;
    }

    get hasPalletDependencies() {
        return this._pallets.length > 0;
    }
}

import { DeliveryOrder } from "../../../model/delivery-order";
import { ParcelBulkCreationMode } from "../../../../constants/constants";

export class ParcelCustomCreationCommand {
    private readonly _operationCode: string;
    private readonly _batchId: number;
    private readonly _deliveryOrderList: ReadonlyArray<DeliveryOrder>;
    private readonly _strategyList: ParcelBulkCreationMode[];

    public constructor(operationCode: string, batchId: number, deliveryOrderList: ReadonlyArray<DeliveryOrder>,
                       selectedStrategies: ParcelBulkCreationMode[]) {
        this._operationCode = operationCode;
        this._batchId = batchId;
        this._deliveryOrderList = deliveryOrderList;
        this._strategyList = selectedStrategies;
    }

    get operationCode(): string {
        return this._operationCode;
    }

    get batchId(): number {
        return this._batchId;
    }

    get deliveryOrderList(): ReadonlyArray<DeliveryOrder> {
        return this._deliveryOrderList;
    }

    get isOneParcelPerOrderSelected() {
        return this._strategyList.includes("OneParcelPerOrder");
    }

    get isOneParcelPerProductItemSelected() {
        return this._strategyList.includes("OneParcelPerProductItem");
    }

    get isOneParcelPerProductWithMonoParcelFlagSelected() {
        return this._strategyList.includes("OneParcelPerProductWithMonoParcelFlag");
    }

    get isMonoRefSelected() {
        return this._strategyList.includes("OneParcelPerOrderWithMonoRef");
    }

    get isMultiRefSelected() {
        return this._strategyList.includes("OneParcelPerOrderWithMultiRef");
    }

    get isComponentsSelected() {
        return this._strategyList.includes("OneParcelPerComponentProduct");
    }

    public get isAnyBasicStrategySelected() {
        return this.isOneParcelPerOrderSelected || this.isOneParcelPerProductItemSelected;
    }
}

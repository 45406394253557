import { Settings } from "../../../context/settings";

export enum SupportTopic {
    SupplierSupport = "SupplierSupport",
}

export enum SupportTicketLanguage {
    English = "English",
    French = "French",
}

export enum SupportTeam {
    France = "France",
}

export enum SupportSupplier {
    Brand = "Brand",
}

export enum SupportSeverity {
    ProductionSlowedDown = "ProductionSlowedDown",
}

export enum SupportTicketIssue {
    LabelPrinting = "LabelPrinting",
    AdditionalData = "AdditionalData",
    ClickAndShipSettings = "ClickAndShipSettings",
    CredentialsReminder = "CredentialsReminder",
    ParcelsModification = "ParcelsModification",
    ShippingConfirmation = "ShippingConfirmation",
}

export enum SupportTicketTechnology {
    ClickAndShip = "ClickAndShip",
    Api = "Api",
}

export class SupportTicketOptionsProvider {
    public provideLanguageList() {
        return [
            SupportTicketLanguage.English,
            SupportTicketLanguage.French,
        ];
    }

    public defaultLanguageFromContext(settings: Settings) {
        return settings.lang === "fr-FR" ? SupportTicketLanguage.French : SupportTicketLanguage.English;
    }

    public provideIssueList() {
        return [
            SupportTicketIssue.LabelPrinting,
            SupportTicketIssue.AdditionalData,
            SupportTicketIssue.ClickAndShipSettings,
            SupportTicketIssue.CredentialsReminder,
            SupportTicketIssue.ParcelsModification,
            SupportTicketIssue.ShippingConfirmation,
        ];
    }

    public provideTechnologyList() {
        return [
            SupportTicketTechnology.ClickAndShip,
            SupportTicketTechnology.Api,
        ];
    }
}


import { assign } from "lodash";
import { observable } from "mobx";
import { label, domain } from "../../dto/dto-annotation";
import { label50 } from "../../../domain/label";
import { password } from "../../../domain/password";

export class LoginForm {

    constructor(login?: LoginForm) {
        assign(this, login);
    }

    @label("model.login.login")
    @domain(label50)
    @observable
    public login: string;

    @label("model.login.passsword")
    @domain(password)
    @observable
    public password: string;
}

import React, { SyntheticEvent } from "react";

import { Button, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { SearchListItemSelector } from "../../../../common/component/list/search-list/search-list-item-selector";
import { SearchListHeaderLabel } from "../../../../common/component/list/search-list/search-list-header-label";
import { Cancel, DoubleArrowOutlined, MoreVert } from "@mui/icons-material";
import { getFieldLabel, stringValue } from "../../../../common/field/renderer";
import { DeliveryOrder } from "../../../model/delivery-order";
import { observer } from "mobx-react";
import { t } from "i18next";
import { operationContext } from "../../../../context/operation-context";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";
import { pink } from "@mui/material/colors";
import { context } from "../../../../context/context";
import { FeatureToggle } from "../../../../context/app-configuration";
import { DeliveryOrderCancellationRequestStatus } from "../../../model/delivery-order-cancellation-request-status";

interface IDeliveryOrderHeaderLineProps {
    deliveryOrder: DeliveryOrder;
    isItemSelected: boolean;
    isExpanded: boolean;
    displaySingleActionPickerButton: boolean;
    additionalDataSize: number;

    onHeaderClick: (deliveryOrder: DeliveryOrder) => void;
    onSelectionToggle: (value: boolean)  => void;
    onSingleActionMenuClick: (target: EventTarget) => void;
    onCancellationRequestMenuOpen: (anchor: HTMLElement) => void;
    onExpandToggle: (isExpanded: boolean) => void;
}

interface IPendingCancellationIconProps {
    deliveryOrder: DeliveryOrder;
    onCancellationRequestMenuOpen: (anchor: HTMLElement) => void;
}

interface IPendingCancellationWithMenu {
    cancellationRequestStatus: DeliveryOrderCancellationRequestStatus;
    onCancellationRequestMenuOpen: (anchor: HTMLElement) => void;
}

interface IDeliveryOrderContentTypeChipProps {
    contentType: "MonoReference" | "SingleReference" | "MultiReference";
}

export const DeliveryOrderHeaderLine = (props: IDeliveryOrderHeaderLineProps) => {

    const handleLineExpandCollapseToggle = (event: SyntheticEvent, isExpanded: boolean) => {
        event.stopPropagation();
        props.onExpandToggle(isExpanded);
    };

    return <TableRow key={props.deliveryOrder.id}
                     data-testid={`dor-line-${props.deliveryOrder.id}`}
                     onClick={() => props.onHeaderClick(props.deliveryOrder)}
                     className={"search-item__row list-row--level1"}>
        <TableCell colSpan={11 + (context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) ? 1 : 0)  + props.additionalDataSize}
                   className={"search-item__header"}>
            <div className={"search-item__header-contents"}>
                <SearchListItemSelector onToggle={value => props.onSelectionToggle(value)}
                                        isSelected={props.isItemSelected} />
                <IconButton onClick={event => handleLineExpandCollapseToggle(event, !props.isExpanded)}
                            data-testid={`expand-toggle-${props.isExpanded ? "expanded" : "collapsed"}`}>
                    <Tooltip title={t(`components.searchList.${props.isExpanded ? "collapse" : "expand"}`)}
                             placement={"top-start"}>
                        <DoubleArrowOutlined
                            className={`search-item__expand-toggle-icon${!props.isExpanded ? "--collapsed" : ""}`} />
                    </Tooltip>
                </IconButton>
                {props.deliveryOrder.isPendingCancellationOrCancelled &&
                    <PendingCancellationIcon deliveryOrder={props.deliveryOrder}
                                             onCancellationRequestMenuOpen={props.onCancellationRequestMenuOpen}/>}
                <div className={"search-item__header-details-id"}>
                    <SearchListHeaderLabel propertyKey={"orderId"}
                                           label={getFieldLabel(props.deliveryOrder, "orderId")}
                                           value={stringValue(props.deliveryOrder, "orderId")}
                                           chip={<DeliveryOrderContentTypeChip contentType={props.deliveryOrder.type} />} />
                </div>
                <div className={"search-item__header-details"}>
                    <SearchListHeaderLabel propertyKey={"id"}
                                           label={getFieldLabel(props.deliveryOrder, "id")}
                                           value={stringValue(props.deliveryOrder, "id")} />
                    <SearchListHeaderLabel propertyKey={"parcelCount"}
                                           label={getFieldLabel(props.deliveryOrder, "parcelCount")}
                                           value={stringValue(props.deliveryOrder, "parcelCount")} />
                    <SearchListHeaderLabel label={getFieldLabel(props.deliveryOrder, "carrier")}
                                           value={stringValue(props.deliveryOrder, "carrier")} />
                    <SearchListHeaderLabel label={getFieldLabel(props.deliveryOrder, "address")}
                                           value={stringValue(props.deliveryOrder, "address")} />
                </div>
                {props.displaySingleActionPickerButton &&
                    <div className={"search-item__header-action"}>
                        <Button variant={"outlined"}
                                onClick={event => {
                                    event.stopPropagation();
                                    props.onSingleActionMenuClick(event.target);
                                }}
                                size={"small"}
                                data-testid={"single-action-picker-button"}>
                            <MoreVert />
                        </Button>
                    </div>}
            </div>
        </TableCell>
    </TableRow>;
};

const PendingCancellationIcon = observer((props: IPendingCancellationIconProps) => {
    return  <div className={"cancellation-request__container"}>
        {operationContext.isBlueBrandOperation && props.deliveryOrder.isPendingCancellation ?
            <PendingCancellationWithMenu cancellationRequestStatus={props.deliveryOrder.cancellationRequestStatus}
                                         onCancellationRequestMenuOpen={props.onCancellationRequestMenuOpen} />
            : <Cancel data-testid={"order-cancellation-request-icon"} />}
    </div>;
});

const PendingCancellationWithMenu = (props: IPendingCancellationWithMenu) => {
    return <IconButton className={"cancellation-request__button"}
                       data-testid={"order-cancellation-request-menu-icon"}
                       onClick={event => {
                           props.onCancellationRequestMenuOpen(event.currentTarget);
                           event.stopPropagation();
                       }}>
        <Tooltip title={t(`deliveryOrderList.tooltip.orderWith${props.cancellationRequestStatus}CancellationRequest`)}>
            <Cancel data-testid={"order-cancellation-request-icon"} />
        </Tooltip>
        <MoreVert fontSize={"small"} className={"cancellation-request__menu-opener"} />
    </IconButton>;
};

const DeliveryOrderContentTypeChip = (props: IDeliveryOrderContentTypeChipProps) => {
    return <SquaredChip color={pink} propertyKey={"content-type"} label={
        t(`model.deliveryOrder.detailContentType.${props.contentType}`)
    } />;
};

import { ExportDataItem } from "./export-data-item";
import moment from "moment";
import { ExportDataWorksheet } from "./export-data-worksheet";

export enum ExportType {
    AllColumns = 0,
    OnlyRefProducts = 1,
    PickingList = 2,
}

export class ExportDataContents {
    private readonly _fileName: string;
    private readonly _operationCode: string;
    private readonly _batchId: number;
    private readonly _worksheets: ExportDataWorksheet[];

    public static forOperationBatchWithName(name: string, operationCode: string, batchId: number,
                                            header: ExportDataItem, items: ExportDataItem[]) {
        return ExportDataContents.withDateSuffix(ExportDataContents.buildFilenameWithBatchId(name, operationCode, batchId),
            new ExportDataWorksheet(header, items, operationCode));
    }

    public static forOperationBatchWithMultipleWorksheets(name: string, operationCode: string,
                                                          batchId: number | undefined, sheet: ExportDataWorksheet[]) {
        return batchId ?
            new ExportDataContents(ExportDataContents.buildFilenameWithBatchId(name, operationCode, batchId), sheet) :
            new ExportDataContents(ExportDataContents.buildFilenameWithoutBatchId(name, operationCode), sheet);
    }

    private static withDateSuffix(fileName: string, sheet: ExportDataWorksheet) {
        return new ExportDataContents( `${fileName}_${moment().format("YYYYMMDD-HHmm")}`, [sheet]);
    }

    public static rawContentsForSingleWorksheet(fileName: string, header: ExportDataItem, items: ExportDataItem[]) {
        return new ExportDataContents( fileName, [new ExportDataWorksheet(header, items)]);
    }

    constructor(fileName: string, sheets: ExportDataWorksheet[]) {
        this._fileName = fileName;
        this._worksheets = sheets;
    }

    get fileName(): string {
        return this._fileName;
    }

    get operationCode(): string {
        return this._operationCode;
    }

    get batchId(): number {
        return this._batchId;
    }

    get worksheets(): ExportDataWorksheet[] {
        return this._worksheets;
    }

    get headerColumns() {
        return this._worksheets.map(w => w.header).flatMap(h => h.columns);
    }

    public static buildFilenameWithBatchId(name: string, operationCode: string, batchId: number) {
        return `${name.toLowerCase().replace(" ", "_")}_export_${operationCode}_${batchId.toString()}`;
    }

    public static buildFilenameWithoutBatchId(name: string, operationCode: string) {
        return `${name.toLowerCase().replace(" ", "_")}_export_${operationCode}`;
    }
}

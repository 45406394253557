import { Parcel } from "../../../model/parcel";

export class ParcelPrintSummary {
    private readonly _nbParcelsPrinted: number;
    private readonly _nbPreparationOrderPrinted: number;
    private readonly _printedParcelsList: Parcel[];

    constructor(printedParcelsList: Parcel[], nbParcelsPrinted: number, nbPreparationOrderPrinted: number) {
        this._printedParcelsList = printedParcelsList;
        this._nbParcelsPrinted = nbParcelsPrinted;
        this._nbPreparationOrderPrinted = nbPreparationOrderPrinted;
    }

    public static emptyResponse() {
        return new ParcelPrintSummary([], 0, 0);
    }

    get printedParcelsList(): Parcel[] {
        return this._printedParcelsList;
    }

    public get nbParcelsPrinted(): number {
        return this._nbParcelsPrinted;
    }

    public get nbPreparationOrderPrinted(): number {
        return this._nbPreparationOrderPrinted;
    }
}

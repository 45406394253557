import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    ThemeProvider,
    TextField,
    Typography,
} from "@mui/material";
import { IActionCommand } from "../action-select/bulky-action-picker";
import { VPUITheme } from "../../../theme/vpui-theme";
import { Close } from "@mui/icons-material";
import { t } from "i18next";
import "./style.scss";

interface IBulkyActionScanDialogProps {
    contentPrefix: string;
    action: IActionCommand<any, string>;
    onClose: () => void;
    open: boolean;
    onScan: (scannedValue: any) => Promise<any>;
    scanFieldType: "text" | "number";
}

export const BulkyActionScanDialog = (props: IBulkyActionScanDialogProps) => {
    const [textValue, setTextValue] = useState("");

    const handleOnKeyDown = async (keyDown: string) => {
        if (keyDown !== "Enter" || textValue === "") {
            return;
        }

        await props.onScan(textValue.trim());
        setTextValue("");
    };

    return <Dialog open={true}>
        <ThemeProvider theme={VPUITheme}>
            <div className={"action-scan-dialog"}
                 data-testid={"bulky-action-scan-dialog"}>
                <div className="action-scan-dialog__close">
                    <IconButton onClick={() => props.onClose()}>
                        <Close data-testid="close" />
                    </IconButton>
                </div>
                <DialogTitle>
                    {t(`${props.contentPrefix}.${props.action.value}.title`)}
                </DialogTitle>
                <DialogContent className={"action-scan-dialog__content"}>
                    <Typography className={"action-scan-dialog__description"}>
                        {t(`${props.contentPrefix}.${props.action.value}.scan`)}
                    </Typography>
                    <FormControl variant={"outlined"}
                                 fullWidth>
                        <TextField id={"scan-field"}
                                   value={textValue}
                                   variant={"outlined"}
                                   autoFocus
                                   onKeyDown={async (event: React.KeyboardEvent<any>) => await handleOnKeyDown(event.key)}
                                   onChange={event => setTextValue(event.target.value ?? "")}
                        />
                    </FormControl>
                </DialogContent>
            </div>
        </ThemeProvider>
    </Dialog>;
};

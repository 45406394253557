import { QzServiceStatusType } from "../constants/constants";
import { action, computed, observable } from "mobx";

export class QzModel {

    @observable private _status: QzServiceStatusType = "NotConnected";
    @computed public get status() {
        return this._status;
    }

    @action
    public setStatus(status: QzServiceStatusType) {
        this._status = status;
    }
}

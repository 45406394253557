import {AdditionalValuesItem} from "./additional-values-item";

export class AdditionalValues {
    public constructor(type: string) {
        this._type = type;
        this._items = [];
    }

    private _type: string;
    private _items: AdditionalValuesItem[];

    public get type(): string {
        return this._type;
    }

    public get items(): AdditionalValuesItem[] {
        return this._items;
    }
}

import * as React from "react";
import { FormControl, FormLabel, TextField } from "@mui/material";
import { t } from "i18next";

export interface IPalletPickupFormNotesProps {
    closingCommentValue: string;
    onClosingCommentUpdate: (value: string) => void;

    withCreationComment: boolean;
    creationCommentValue: string;
    onCreationCommentUpdate: (value: string) => void;

    isReadOnly: boolean;
}

export const PalletPickupFormComments = (props: IPalletPickupFormNotesProps) => {
    if (props.isReadOnly && props.closingCommentValue === "" && props.creationCommentValue === "") {
        return <></>;
    }

    return <div className={"pallet-pickup-form__comments"}>
        <FormControl variant={"outlined"} fullWidth={true}>
            <FormLabel htmlFor={`pallet-closing-comment`}>
                {t("palletPickup.declarationForm.palletClosingComment.label")}
            </FormLabel>
            <TextField id={`pallet-closing-comment`}
                       multiline={true}
                       maxRows={8}
                       variant={"outlined"}
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onClosingCommentUpdate(e.target.value)}
                       value={props.closingCommentValue}
                       disabled={props.isReadOnly} />
        </FormControl>
        {props.withCreationComment && <FormControl variant={"outlined"} fullWidth={true}>
            <FormLabel htmlFor={`pallet-closing-comment`}>
                {t("palletPickup.declarationForm.palletCreationComment.label")}
            </FormLabel>
            <TextField id={`pallet-creation-comment`}
                       multiline={true}
                       maxRows={8}
                       variant={"outlined"}
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onCreationCommentUpdate(e.target.value)}
                       value={props.creationCommentValue}
                       disabled={props.isReadOnly} />
        </FormControl>}
    </div>;
};

import { PalletsListItem } from "./pallets-list-item";
import { palletService } from "../../../services/pallet";
import { context } from "../../../../context/context";
import { PalletStatusType } from "../../../constants/constants";

export class PalletDataProvider {
    private readonly _provideForStatus: PalletStatusType;

    constructor(provideForStatus: PalletStatusType) {
        this._provideForStatus = provideForStatus;
    }

    public async loadList() {
        if (context.warehouseContext?.code === undefined) {
            return [];
        }

        return (await palletService.loadPallets(context.warehouseContext.code, this._provideForStatus))
            .map(p => new PalletsListItem(p));
    }
}

import React from "react";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { t } from "i18next";
import { Error } from "@mui/icons-material";
import { PrinterDevice } from "../../model/printer-device";

interface IPrinterSelectorProps {
    value: PrinterDevice | undefined;
    list: PrinterDevice[] | undefined;
    onChange: (printer?: PrinterDevice) => void;
}

export const PrinterSelector = (props: IPrinterSelectorProps) => {
    const hasPrinters = (props.list?.length ?? 0);

    return <Select value={props.value?.name ?? "placeholder"}
                   className={"mobile-connector__printer-select"}
                   classes={{disabled: "mobile-connector__printer-select--not-available"}}
                   disabled={!hasPrinters}>
        {props.list === undefined &&
            <MenuItem value={"placeholder"} key={"printer-loading"}>
                <CircularProgress size={20} />
                {t("components.mobileConnector.printer.loading")}
            </MenuItem>}
        {props.list && props.list.length === 0 &&
            <MenuItem value={"placeholder"} key={"printer-none"}>
                <Error fontSizeAdjust={"20"} />
                {t("components.mobileConnector.printer.notFound")}
            </MenuItem>}
        {props.list && props.list.length > 0 &&
            <MenuItem value={"placeholder"} key={"printer-none"} onClick={() => props.onChange(undefined)}>
                {t("components.mobileConnector.printer.noPrinter")}
            </MenuItem>}
        {props.list && props.list.map(p =>
            <MenuItem key={`printer-${p.id}`} value={p.name} onClick={() => props.onChange(p)}>
                {p.name}
            </MenuItem>)}
    </Select>;
};

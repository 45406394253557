import { assign } from "lodash";
import { domain } from "../../common/dto/dto-annotation";
import { identifier } from "../../domain/identifier";
import { label50 } from "../../domain/label";
import { observable } from "mobx";

export class Stockout {

    constructor(stockout?: Stockout) {
        assign(this, stockout);
    }

    @domain(identifier)
    @observable
    public readonly batchId: number;

    @domain(identifier)
    @observable
    public readonly deliveryOrderId: number;

    @domain(label50)
    @observable
    public readonly operationCode: string;

    @domain(identifier)
    @observable
    public readonly productId: number;

    @observable
    public readonly quantity: number;

    @observable
    public readonly status: StockoutStatus = StockoutStatus.Requested;
}

export enum StockoutStatus {
    Requested,
    Processed,
}

import { action, computed, observable } from "mobx";
import { filter, find, findIndex, remove } from "lodash";

import { DeliveryOrder } from "../../../model/delivery-order";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { Parcel } from "../../../model/parcel";
import { ParcelDetail } from "../../../model/parcel-detail";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { stocksService } from "../../../services/stocks";

class ParcellingModel {
    // No need to set thoses fields as observable
    private _operationCode: string;
    private _batchId: number;
    private _onClose: () => void;
    @observable private _actionDod: DeliveryOrderDetail | null = null;
    @observable private _deliveryOrder: DeliveryOrder | undefined;
    @observable private _parcels: Parcel[] = [];
    @observable private _editingParcelId?: number;
    @observable private _exitIsAvailable: boolean;
    @observable private _expandedParcelState = new Map<number, boolean>();
    @observable private _initialParcelMap = new Map<number, Parcel>();
    @observable private _actionsMenuAnchorEl: HTMLElement | null = null;

    @computed public get operationCode() {
        return this._operationCode;
    }
    @computed public get batchId() {
        return this._batchId;
    }

    @computed public get deliveryOrder() {
        return this._deliveryOrder;
    }
    @computed public get parcels() {
        return this._parcels as ReadonlyArray<Parcel>;
    }
    @computed public get editingParcelId() {
        return this._editingParcelId;
    }
    @computed public get hasParcelInEditMode() {
        return this.editingParcelId !== undefined;
    }

    @computed public get isDeliveryOrderParcelled() {
        return !find(this.deliveryOrder!.details, detail => detail.quantityAvailable > 0);
    }

    @computed public get actionsMenuOpen() {
        return this._actionsMenuAnchorEl !== null;
    }

    @computed public get actionsMenuAnchorEl(): any {
        return this._actionsMenuAnchorEl;
    }

    @computed public get actionDodIsFulfilled(): any {
        return this._actionDod ? this._actionDod.isFulfilled : false;
    }

    @computed public get actionDodHasStockouts(): any {
        return this._actionDod ? this._actionDod.quantityStockout > 0 : false;
    }

    @action
    public onActionsMenuOpen(anchorElement: HTMLElement, dod: DeliveryOrderDetail) {
        this._actionDod = dod;
        this._actionsMenuAnchorEl = anchorElement;
    }

    @action
    public onActionsMenuClose() {
        this._actionsMenuAnchorEl = null;
        this._actionDod = null;
    }

    public async onMarkAsStockout() {
        if (this._actionDod != null && this._deliveryOrder !== undefined) {
            await stocksService.stockoutDeliveryOrderDetailByProductId(
                this._operationCode,
                this._batchId,
                this._deliveryOrder.deliveryOrderId,
                this._actionDod.productId,
            );
            this._actionDod.stockout();
            this.calcFullFilled();
            notificationModel.addSuccessMessage("deliveryOrderParcelling.stockouts.createSuccess");
        }
        this.onActionsMenuClose();
    }

    public async onRemoveStockout() {
        if (this._actionDod != null && this._deliveryOrder !== undefined) {
            await stocksService.unstockoutDeliveryOrderDetailByProductId(
                this._operationCode,
                this._batchId,
                this._deliveryOrder.deliveryOrderId,
                this._actionDod.productId,
            );
            this._actionDod.removeStockout();
            this.calcFullFilled();
            notificationModel.addSuccessMessage("deliveryOrderParcelling.stockouts.deleteSuccess");
        }
        this.onActionsMenuClose();
    }

    public get onClose() {
        return this._onClose;
    }

    public isEdit(uniqueParcelIdentifier: number) {
        return this.editingParcelId === uniqueParcelIdentifier;
    }

    public isExpanded(uniqueParcelIdentifier: number) {
        return !!this._expandedParcelState.get(uniqueParcelIdentifier) ||
            this.parcels.length === 1;
    }

    @computed public get canExit() {
        return this._exitIsAvailable;
    }

    @action
    private calcFullFilled() {
        if (this._parcels.length === 0) {
            this._exitIsAvailable = true;
        } else if (this.deliveryOrder) {
            const fullFilledDetails = filter(this.deliveryOrder.details,
                (detail: DeliveryOrderDetail) => detail.isFulfilled === true);
            const fullFilledCount = fullFilledDetails.length || 0;

            this._exitIsAvailable = !(fullFilledCount > 0 && fullFilledCount < this.deliveryOrder.details.length);
        }
    }

    @action
    public initParcellingData(
        operationCode: string,
        batchId: number,
        deliveryOrder: DeliveryOrder,
        onClose: () => void) {
        this._operationCode = operationCode;
        this._batchId = batchId;
        this._onClose = onClose;
        this.resetParcelling(deliveryOrder);
    }

    private resetParcelling(deliveryOrder: DeliveryOrder) {
        this._deliveryOrder = deliveryOrder;
        this._actionsMenuAnchorEl = null;
        this._actionDod = null;
        this._parcels = deliveryOrder.parcels.map(parcel => this.addDeliveryOrderDetailToParcel(parcel));
        this._editingParcelId = undefined;
        this._expandedParcelState = new Map<number, boolean>();
        this._initialParcelMap = new Map<number, Parcel>();
        this.calcFullFilled();
    }

    @action
    public refreshParcel(parcel: Parcel) {
        const idx = findIndex(this._parcels, p => p.id === parcel.id);
        this._parcels[idx] = parcel;
    }

    @action
    public addDeliveryOrderDetailToParcel(parcel: Parcel) {
        this._deliveryOrder!.details.forEach(deliveryOrderDetail => {
            const productId = deliveryOrderDetail.productId;
            const matchedParcelDetail = parcel.details.find(parcelDetail => parcelDetail.productId === productId);
            if (matchedParcelDetail) {
                matchedParcelDetail.assignDeliveryOrderDetailData(deliveryOrderDetail);
            } else {
                const detail = new ParcelDetail();
                detail.assignDeliveryOrderDetailData(deliveryOrderDetail);
                parcel.details.push(detail);
            }
        });
        parcel.sortDetails();
        return parcel;
    }

    @action
    public assignParcelProductQuantity(parcelId: number, initialData: Array<{ productId: number, quantity: number }>) {
        const parcel = find(this._parcels, p => p.id === parcelId)!;
        initialData.forEach(initialDetail => {
            const actualParcelDetail = find(parcel.details, d => d.productId === initialDetail.productId)!;
            const detailDo = find(this._deliveryOrder!.details,
                d => d.productId === initialDetail.productId)!;

            detailDo.quantityParcelled += initialDetail.quantity - actualParcelDetail.quantity;
            actualParcelDetail.quantity = initialDetail.quantity;
        });
    }

    @action
    public onParcelDeleted(parcelToDelete: Parcel) {
        notificationModel.addSuccessMessage("deliveryOrderParcelling.parcelList.notifications.deleteSuccess");
        remove(this._parcels, parcel => parcel.id === parcelToDelete.id);
        this._deliveryOrder!.cancelParcel(parcelToDelete.parcelId);
        this.calcFullFilled();
    }

    @action
    public onParcelSaved(deliveryOrder: DeliveryOrder, isCreation: boolean) {
        notificationModel.addSuccessMessage(
            `deliveryOrderParcelling.parcelList.notifications.saveSuccess${isCreation ? "Create" : "Update"}`);
        this.resetParcelling(deliveryOrder);
    }

    @action
    public cancelNewParcel(index: number, newParcel: Parcel) {
        newParcel.details.forEach(parcelDetail => {
            const detailDo = find(
                this._deliveryOrder!.details,
                d => d.productId === parcelDetail.productId)!;
            detailDo.quantityParcelled -= parcelDetail.quantity;
        });
        this._parcels.splice(index, 1);
        this.calcFullFilled();
    }

    @action
    public addParcel(batchId: number) {
        const details = this._deliveryOrder!.details.map(deliveryOrderDetail => {
            const detail = new ParcelDetail();
            detail.assignDeliveryOrderDetailData(deliveryOrderDetail);
            return detail;
        });
        const newParcel = new Parcel({
            batchId,
            operationCode: this._deliveryOrder!.operationCode,
            deliveryOrderId: this._deliveryOrder!.id,
            details,
            weight: 0,
            orderId: this._deliveryOrder!.orderId,
            status: "New",
            zipCode: this._deliveryOrder!.zipCode,
            city: this._deliveryOrder!.city,
            country: this._deliveryOrder!.country,
            isMonoRef: this._deliveryOrder!.isMonoRef,
            isSingleRef: this._deliveryOrder!.isSingleRef,
            orderCancellationRequestStatus: this._deliveryOrder!.cancellationRequestStatus,
        });
        const index = 0;

        this._parcels.splice(index, 0, newParcel);
        this._editingParcelId = 0;
        this._expandedParcelState.clear();
        this._expandedParcelState.set(0, true);
        this.calcFullFilled();
    }

    @action
    public toggleEdit(parcel: Parcel, uniqueParcelIdentifier: number, isEditing: boolean, index: number) {
        if (isEditing) {
            this._editingParcelId = uniqueParcelIdentifier;
            this._expandedParcelState.set(uniqueParcelIdentifier, true);
            this._initialParcelMap.set(uniqueParcelIdentifier, new Parcel(parcel));
        } else if (!parcel.id) {
            this.cancelNewParcel(index, parcel);
            this._editingParcelId = undefined;
        } else {
            this._editingParcelId = undefined;
            const initialData = this._initialParcelMap.get(uniqueParcelIdentifier)!.details.map(detail => {
                return {
                    productId: detail.productId,
                    quantity: detail.quantity,
                };
            });
            this.assignParcelProductQuantity(parcel.id, initialData);
            this._initialParcelMap.delete(uniqueParcelIdentifier);
        }
    }

    @action
    public expandParcel(uniqueParcelIdentifier: number) {
        this._expandedParcelState.set(
            uniqueParcelIdentifier,
            !this.isExpanded(uniqueParcelIdentifier));
    }

    @action
    public closeParcelling() {
        if (this._deliveryOrder && findIndex(this._deliveryOrder!.details, d => !d.isFulfilled) !== -1) {
            notificationModel.addWarningMessage("deliveryOrderParcelling.close.deliveryOrderNotFulfilled");
        }
        this._deliveryOrder = undefined;
    }
}

export const parcellingModel = new ParcellingModel();

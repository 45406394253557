import * as React from "react";
import { Dialog, DialogContent } from "@mui/material";
import ReactPlayer from "react-player";

interface IVideoPlayerDialogProps {
    link: string;
    onClose: () => void;
}

export const VideoPlayerDialog = (props: IVideoPlayerDialogProps) => {
    return (
        <Dialog onClose={props.onClose} open={true}>
            <DialogContent className={"dialog__content"}>
                <ReactPlayer
                    url={props.link}
                    width={600}
                    height={350}
                    controls={true}/>
            </DialogContent>
        </Dialog>
    );
};

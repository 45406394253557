import { assign } from "lodash";
import { observable } from "mobx";
import { label, domain } from "../../common/dto/dto-annotation";
import { UserSummary } from "./user-summary";
import { yesNo } from "../../domain/yes-no";
import { roleAdmin, roleInternalService, roleMerchant, roleSuperAdmin, roleSupervisor, roleSupplier, roleBiDataExtractor, roleReadOnlyIntegrator, UserRoleType } from "../../constants/constants";

export class User extends UserSummary {

    constructor(user?: User) {
        super(user);
        assign(this, user);

        if (user !== undefined) {
            this.level = user.roles.filter(item => User.isUserLevelRole(item)).shift()! as UserRoleType;
            this.featureRoles = user.roles.filter(item => !User.isUserLevelRole(item));
        }
    }

    @label("model.user.canGenerateLabelsOnTestEnvironment")
    @domain(yesNo)
    @observable
    public canGenerateLabelsOnTestEnvironment: boolean;

    @label("model.user.level")
    @observable
    public level: UserRoleType;

    @label("model.user.features")
    @observable
    public featureRoles: string[];

    @label("model.user.attributes")
    @observable
    public attributes: string[];

    @observable
    public roles: string[];

    @label("model.user.owners")
    @observable
    public ownerGuids: string[];

    @label("model.user.operations")
    @observable
    public operationCodes: string[];

    public static isUserLevelRole(role: string): role is UserRoleType {
        return [
            roleSuperAdmin,
            roleAdmin,
            roleSupervisor,
            roleSupplier,
            roleMerchant,
            roleInternalService,
            roleBiDataExtractor,
            roleReadOnlyIntegrator,
        ].includes(role as UserRoleType);
    }
}

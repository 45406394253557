import React from "react";
import { Button, Icon, Typography } from "@mui/material";
import { t } from "i18next";

import "./style.scss";

export interface IImpersonationAppBarProps {
    userName: string;
    onImpersonationEnd: () => void;
}

export const ImpersonationAppBar = (props: IImpersonationAppBarProps) => {
    const onBackOfficeHandle = () => {
        props.onImpersonationEnd();
        window.location.replace(`${window.location.origin}/back-office/user`);
    };

    return <div className={"impersonation-app-bar"}>
        <Typography className={"impersonation-app-bar__description"}>
            {t("components.impersonationAppBar.description", {userName: props.userName})}
        </Typography>
        <Button className={"impersonation-app-bar__button"} variant="outlined" size="small" endIcon={<Icon>exit_to_app</Icon>}
                onClick={onBackOfficeHandle}>
            {t("navigation.backToBackoffice")}
        </Button>
    </div>;
};

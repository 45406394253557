import React, { useState } from "react";
import { Parcel } from "../../../model/parcel";
import {
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Typography,
} from "@mui/material";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";
import { blue } from "@mui/material/colors";
import { t } from "i18next";

interface IPalletAssignmentSelectPalletProps {
    parcelsList: Parcel[];

    onScan: (parcelsList: Parcel[], palletCode: string) => void;
}

export const PalletAssignmentSelectPallet = (props: IPalletAssignmentSelectPalletProps) => {
    const [textValue, setTextValue] = useState("");

    const handleOnKeyDown = async (keyDown: string) => {
        if (keyDown !== "Enter" || textValue === "") {
            return;
        }

        await props.onScan(props.parcelsList, textValue.trim());
    };

    return <>
        <DialogTitle>
            {t("components.pallet.assignment.title")}
        </DialogTitle>
        <DialogContent>
            <div className={"pallet-assignment-summary"}>
                <Typography>
                    {t("components.pallet.assignment.description", {
                        count: props.parcelsList.length,
                    })}
                </Typography>
                <div className={"pallet-shipping-summary__list"}>
                    {props.parcelsList.map(c => <SquaredChip propertyKey={`item-${c.id}`} color={blue} label={c.id.toString()} />)}
                </div>
            </div>
        </DialogContent>
        <DialogContent>
            <div className={"pallet-assignment-form"}>
                <Typography className={"pallet-shipping-form__headline"}>
                    {t("components.pallet.assignment.informationTitle")}
                </Typography>
                <Typography>
                    {t("components.pallet.assignment.informationDetails")}
                </Typography>
                <FormControl variant={"outlined"}
                             fullWidth>
                    <TextField id={"assignment-code-scan"}
                               value={textValue}
                               variant={"outlined"}
                               autoFocus
                               onKeyDown={async (event: React.KeyboardEvent<any>) => await handleOnKeyDown(event.key)}
                               onChange={event => setTextValue(event.target.value ?? "")}
                    />
                </FormControl>
            </div>
        </DialogContent>
    </>;
};

import * as React from "react";

import { Avatar, TableRow, TableCell } from "@mui/material";

import { getReadOnlyField } from "../../../../common/field/renderer";
import { observer } from "mobx-react";
import { Area } from "./area";
import { context } from "../../../../context/context";
import { FeatureToggle } from "../../../../context/app-configuration";

interface IAreaLine {
    areaItem: Area;
}

@observer
export class AreaLine extends React.Component<IAreaLine, {}> {

    public render() {
        return <TableRow
            data-testid={`area-line-${this.props.areaItem.areaNumber}`}
            key={this.props.areaItem.parcelId}
            className="delivery-order-row level1">
            <TableCell>
                <Avatar>{getReadOnlyField(this.props.areaItem, "areaNumber")}</Avatar>
            </TableCell>
            <TableCell colSpan={this.cellColSpanSize}>
                <div className="level1-field-container">
                    <div>{getReadOnlyField(this.props.areaItem, "parcelId")}</div>
                    <div>{getReadOnlyField(this.props.areaItem, "orderId")}</div>
                    <div>{getReadOnlyField(this.props.areaItem, "deliveryOrderId")}</div>
                    <div>{getReadOnlyField(this.props.areaItem, "carrier")}</div>
                    <div>{getReadOnlyField(this.props.areaItem, "weight")}</div>
                    <div>{getReadOnlyField(this.props.areaItem, "address")}</div>
                </div>
            </TableCell>
        </TableRow>;
    }

    private get cellColSpanSize() {
        return context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) ? 9 : 8;
    }
}

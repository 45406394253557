import { LocaleType } from "../constants/constants";
import { assign } from "lodash";
import { label } from "../common/dto/dto-annotation";
import { IUserWebhook } from "./user-webhook";

export class Profile {

    constructor(profile?: Profile) {
        assign(this, profile);
    }

    @label("model.profile.username")
    public username: string;

    public contactEmail: string;

    @label("model.profile.lang")
    public lang: LocaleType;

    @label("model.profile.isDownload")
    public isDownload: boolean;

    @label("model.profile.printerPaperFormat")
    public printerPaperFormat: string;

    @label("model.profile.updateDate")
    public updateDate: string;

    public webhooks: IUserWebhook[];
}

import React from "react";
import { Add, CloseRounded, UploadFile } from "@mui/icons-material";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { SupportTicketAttachment } from "./support-ticket-attachment";
import { Guid } from "guid-typescript";
import { t } from "i18next";

interface ISupportTicketFormAttachmentListProps {
    attachmentsList: SupportTicketAttachment[];
    onFileAdded: (file: SupportTicketAttachment) => void;
    onFileRemove: (id: Guid) => void;
}

export const SupportTicketFormAttachments = (props: ISupportTicketFormAttachmentListProps) => {
    const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        props.onFileAdded(new SupportTicketAttachment(event.target.files[0]));
    };

    return <div>
        <input id={"file-input"}
               className={"support-ticket__file-handler"}
               onChange={handleFileSelected}
               type={"file"} />
        <Typography>
            {t(`components.supportTicket.form.attachments.label`)}
        </Typography>
        <div className={"support-ticket__files-list"}>
            {props.attachmentsList.map(attachment =>
                <div className={"support-ticket__file"} key={`file-${attachment.id}`}>
                    <UploadFile />
                    <Typography>
                        {attachment.fileName}
                    </Typography>
                    <Tooltip title={t("components.supportTicket.form.action.removeFile")} placement={"right-end"}>
                        <IconButton onClick={() => props.onFileRemove(attachment.id)}>
                            <CloseRounded />
                        </IconButton>
                    </Tooltip>
                </div>)}
        </div>
        <label htmlFor={"file-input"} className={"support-ticket__file-header"}>
            <Button color={"inherit"}
                    component={"span"}
                    variant={"contained"}
                    size={"small"}
                    startIcon={<Add />}>
                {t(`components.supportTicket.form.attachments.${props.attachmentsList.length === 0 ? "new" : "addAnother"}`)}
            </Button>
        </label>
    </div>;
};

import { assign } from "lodash";
import { label50, label100, label20, label15, label255, label2 } from "../../domain/label";
import { label, domain } from "../../common/dto/dto-annotation";
import { identifier } from "../../domain/identifier";
import { observable } from "mobx";
import { email } from "../../domain/email";

export class AddressFull {

    constructor(addressFull?: AddressFull) {
        assign(this, addressFull);
    }

    @label("model.addressFull.firstName")
    @domain(label50)
    @observable
    public firstName: string;

    @label("model.addressFull.lastName")
    @domain(label50)
    @observable
    public lastName: string;

    @label("model.addressFull.companyName")
    @domain(label50)
    @observable
    public companyName: string;

    @label("model.addressFull.digicode")
    @domain(label20)
    @observable
    public digicode: string;

    @label("model.addressFull.floor")
    @domain(identifier)
    @observable
    public floor?: number;

    @label("model.addressFull.pickupPoint")
    @domain(label20)
    @observable
    public pickupPoint: string;

    @label("model.addressFull.address1")
    @domain(label100)
    @observable
    public address1: string;

    @label("model.addressFull.address2")
    @domain(label100)
    @observable
    public address2: string;

    @label("model.addressFull.address3")
    @domain(label100)
    @observable
    public address3: string;

    @label("model.addressFull.zipCode")
    @domain(label15)
    @observable
    public zipCode: string;

    @label("model.addressFull.city")
    @domain(label50)
    @observable
    public city: string;

    @label("model.addressFull.state")
    @domain(label50)
    @observable
    public state: string;

    @label("model.addressFull.phone")
    @domain(label50)
    @observable
    public phone: string;

    @label("model.addressFull.email")
    @domain(email)
    @observable
    public email: string;

    @label("model.addressFull.country")
    @domain(label255)
    @observable
    public country: string;

    @label("model.addressFull.countryIso")
    @domain(label2)
    @observable
    public countryIso: string;
}

import { IParcel, Parcel } from "./parcel";
import { ParcelCreationErrorItem } from "./parcel-creation-error-item";

export interface IParcelCreationResponse {
    parcels: IParcel[];
    errors: ParcelCreationErrorItem[];
}

export class ParcelCreationResponse {
    private readonly _parcels: Parcel[];
    private readonly _errors: ParcelCreationErrorItem[];

    public constructor(response: IParcelCreationResponse) {
        this._parcels = response.parcels.map(p => new Parcel(p));
        this._errors = response.errors.map(e => new ParcelCreationErrorItem(e));
    }

    public get parcels(): Parcel[] {
        return this._parcels;
    }

    public get parcelIds() {
        return this._parcels.map(p => p.parcelId);
    }

    public get createdMultiple() {
        return this._parcels.length > 1;
    }

    public get errors(): ParcelCreationErrorItem[] {
        return this._errors;
    }

    public get hasErrors(): boolean {
        return this._errors.length > 0;
    }
}

import { CarrierCountryPalletDeclaration } from "../../model/carrier-country-pallet-declaration";
import { action, computed, observable } from "mobx";

export class CarrierCountryPalletDeclarationFormModel {
    private readonly _carrier: string;
    private readonly _country: string;
    private readonly _openPalletForecast?: number;
    private readonly _closedPalletForecast?: number;
    @observable
    private _palletCount: string;

    public constructor(carrierCountryPalletDeclaration: CarrierCountryPalletDeclaration) {
        this._carrier = carrierCountryPalletDeclaration.carrier;
        this._country = carrierCountryPalletDeclaration.country;
        this._palletCount = carrierCountryPalletDeclaration.palletCount.toString();
        this._openPalletForecast = carrierCountryPalletDeclaration.openPalletForecast;
        this._closedPalletForecast = carrierCountryPalletDeclaration.closedPalletForecast;
    }

    get carrier(): string {
        return this._carrier;
    }

    get country(): string {
        return this._country;
    }

    public get palletCount(): string {
        return this._palletCount;
    }

    get openPalletForecast(): number | undefined {
        return this._openPalletForecast;
    }

    get closedPalletForecast(): number | undefined {
        return this._closedPalletForecast;
    }

    @action
    public validateAndUpdateValue(value: string) {
        this._palletCount = value;
    }

    @computed
    public get isValid(): boolean {
        const palletCountNumber = Number(this._palletCount);

        return palletCountNumber.toString() === this._palletCount && palletCountNumber >= 0;
    }

    public toDataModel() {
        return new CarrierCountryPalletDeclaration({
            carrier: this._carrier,
            country: this._country,
            palletCount: Number(this._palletCount),
            openPalletForecast: this._openPalletForecast,
            closedPalletForecast: this._closedPalletForecast,
        });
    }
}

import "./style.scss";

import * as React from "react";

import { Card, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Table, TableRow, TableHead, TableCell, TableBody } from "@mui/material";

import { BatchProgress } from "../../../model/batch-progress";
import { OperationCounterType } from "../../../constants/constants";
import { ReportingModel, ReportingFilterMode} from "./reporting-model";
import { autorun } from "mobx";
import { countersService } from "../../../services/counters";
import { observer } from "mobx-react";
import { orderBy } from "lodash";
import { stringValue } from "../../../../common/field/renderer";
import { t } from "i18next";
import { FeatureToggle } from "../../../../context/app-configuration";
import { context } from "../../../../context/context";
import { Permission } from "../../../../context/permission";
import { IWithRouterProps, withRouter } from "../../../../common/component/hoc/withRouter";

@observer
class ReportingComponent extends React.Component<IWithRouterProps, {}> {

    private autoload: any;
    private reportingModel = new ReportingModel();

    public componentWillMount() {
        this.autoload = autorun(async () => {
            this.reportingModel.onListLoaded(
                await countersService.loadOperationCounter(
                    this.props.params.operationCode,
                    Number(this.props.params.batchId)));
        });
    }

    public componentWillUnmount() {
        this.autoload();
    }

    public render() {
        return <div>
            {this.renderFilter()}
            <Table>
                <TableHead className="reporting-header">
                    {this.renderProgressModeHeaders(this.reportingModel.filter.mode)}
                </TableHead>
                <TableBody className="reporting-body">
                    {this.reportingModel.batchProgressList.length > 1 ?
                        this.renderProgress(this.reportingModel.generalCounterMap, false)
                        : undefined}
                    {orderBy(this.reportingModel.batchProgressList, batchProgress => batchProgress.batchCreationDate)
                        .map(batchProgress => this.renderBatchProgress(batchProgress))}
                </TableBody>
            </Table></div>;
    }

    private renderProgressModeHeaders(
        mode: ReportingFilterMode) {
        switch (mode) {
            case "product":
            case "delivery-order":
                const labelPrefix = this.reportingModel.filter.mode === "product" ? "headerProduct" : "headerDeliveryOrder";
                return <TableRow>
                        <TableCell/>
                        <TableCell/>
                        <TableCell>{t("model.batch.logisticCommitmentDate")}</TableCell>
                        <TableCell colSpan={2} className="counterCol">
                            {t(`reporting.${labelPrefix}.nbAvailable`)}</TableCell>
                        <TableCell colSpan={2} className="counterCol">
                            {t(`reporting.${labelPrefix}.nbToLabel`)}</TableCell>
                        {context.hasPermission(Permission.FeatureReadyToShip) && <TableCell colSpan={2} className="counterCol">
                            {t(`reporting.${labelPrefix}.nbToPrepareToShip`)}</TableCell>}
                         <TableCell colSpan={2} className="counterCol">
                             {t(`reporting.${labelPrefix}.nbToShip`)}</TableCell>
                         <TableCell colSpan={2} className="counterCol">
                             {t(`reporting.${labelPrefix}.nbShipped`)}</TableCell>
                        {context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) && <TableCell colSpan={2} className="counterCol">
                            {t(`reporting.${labelPrefix}.nbStockout`)}</TableCell>}
                         <TableCell>{t(`reporting.${labelPrefix}.nbTotal`)}</TableCell>
                    </TableRow>;

            case "parcel":
                return <TableRow>
                    <TableCell/>
                    <TableCell/>
                    <TableCell>{t("model.batch.logisticCommitmentDate")}</TableCell>
                    <TableCell colSpan={2} className="counterCol">
                        {t(`reporting.headerParcel.nbToLabel`)}</TableCell>
                    {context.hasPermission(Permission.FeatureReadyToShip) && <TableCell colSpan={2} className="counterCol">
                        {t(`reporting.headerParcel.nbToPrepareToShip`)}</TableCell>}
                    <TableCell colSpan={2} className="counterCol">
                        {t(`reporting.headerParcel.nbToShip`)}</TableCell>
                    <TableCell colSpan={2} className="counterCol">
                        {t(`reporting.headerParcel.nbShipped`)}</TableCell>
                    <TableCell>{t(`reporting.headerParcel.nbTotal`)}</TableCell>
                </TableRow>;
        }
    }

    private renderBatchProgress(batchProgress: BatchProgress) {
        if (this.isBatchProgressDisplayed(batchProgress)) {
            return this.renderProgress(
                batchProgress.counterMap,
                batchProgress.batchStatus === "Done",
                batchProgress);
        }
        return undefined;
    }

    private isBatchProgressDisplayed(batchProgress: BatchProgress) {
        return this.reportingModel.filter.isDisplayEndedBatch || batchProgress.batchStatus !== "Done";
    }

    private renderFilter() {
        return <Card className="reporting-filter" elevation={0}>
            <RadioGroup
                value={`${this.reportingModel.filter.mode}`}
                onChange={(event: any) => this.reportingModel.setMode(event.target.value)}
                style={{ flexDirection: "row" }}
            >
                <FormControlLabel
                    value={"delivery-order"} control={<Radio />}
                    label={t("reporting.filter.showDeliveryOrder")} />
                <FormControlLabel
                    value={"parcel"} control={<Radio />}
                    label={t("reporting.filter.showParcel")} />
                <FormControlLabel
                    value={"product"} control={<Radio />}
                    label={t("reporting.filter.showProduct")} />
            </RadioGroup>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={this.reportingModel.filter.isDisplayEndedBatch}
                            onChange={() => this.reportingModel.toggleIsDisplayEndedBatch()}
                        />
                    }
                    label={t("reporting.filter.showEndedBatch")}
                />
            </FormGroup>
        </Card>;
    }

    private renderProgressModeData(
        counterMap: ReadonlyMap<OperationCounterType, number>,
        mode: ReportingFilterMode) {
        switch (mode) {
            case "product":
                return this.renderBatchProductProgress(counterMap);
            case "delivery-order":
                return this.renderBatchDeliveryOrderProgress(counterMap);
            case "parcel":
                return this.renderBatchParcelProgress(counterMap);
        }
    }

    private renderProgress(
        counterMap: ReadonlyMap<OperationCounterType, number>,
        isFinished: boolean,
        batchProgress?: BatchProgress) {

        const title = batchProgress ? `${stringValue(batchProgress, "operationCode")} -
            ${stringValue(batchProgress, "batchId")}
            (${stringValue(batchProgress, "batchCreationDate")})` : t("reporting.totalLine");
        const status = batchProgress ? stringValue(batchProgress, "batchStatusLabel") : "";
        const warehouseId = batchProgress ? batchProgress.warehouseId : "";
        const logisticCommitmentDate = batchProgress ? stringValue(batchProgress, "logisticCommitmentDate") : "";

        const className = isFinished ? "finished" : (batchProgress ? batchProgress.logisticCommitmentDateLevel : "");

        return <TableRow key={title} className={className}>
            <TableCell className="counterTitle">
                <div>{title}</div>
                <div>{status}</div>
            </TableCell>
            <TableCell>{warehouseId}</TableCell>
            <TableCell>{logisticCommitmentDate}</TableCell>

            {this.renderProgressModeData(counterMap, this.reportingModel.filter.mode)}

        </TableRow>;
    }

    private renderBatchDeliveryOrderProgress(counterMap: ReadonlyMap<OperationCounterType, number>) {
        return this.renderCounterColumns({
            nbAvailable: counterMap.get("NbDoAvailable")!,
            nbParcelled: counterMap.get("NbDoParceled")!,
            nbLabeled: counterMap.get("NbDoLabeled")!,
            nbReadyToShip: counterMap.get("NbDoReadyToShip")!,
            nbShipped: counterMap.get("NbDoShipped")!,
            nbStockout: counterMap.get("NbDoStockout")!,
            nbTotal: counterMap.get("NbDo")! - counterMap.get("NbDoCanceled")!,
        });
    }

    private renderBatchParcelProgress(counterMap: ReadonlyMap<OperationCounterType, number>) {
    return this.renderCounterColumns({
            nbAvailable: undefined,
            nbParcelled: counterMap.get("NbParcelParcelled")!,
            nbLabeled: counterMap.get("NbParcelLabeled")!,
            nbReadyToShip: counterMap.get("NbParcelReadyToShip")!,
            nbShipped: counterMap.get("NbParcelShipped")!,
            nbStockout: undefined,
            nbTotal: counterMap.get("NbParcel")!,
        });
    }

    private renderBatchProductProgress(counterMap: ReadonlyMap<OperationCounterType, number>) {
        return this.renderCounterColumns({
            nbAvailable: counterMap.get("NbProduct")!
                - counterMap.get("NbProductParcelled")!
                - counterMap.get("NbProductLabeled")!
                - counterMap.get("NbProductShipped")!
                - counterMap.get("NbProductReadyToShip")!
                - counterMap.get("NbProductStockout")!,
            nbParcelled: counterMap.get("NbProductParcelled")!,
            nbLabeled: counterMap.get("NbProductLabeled")!,
            nbReadyToShip: counterMap.get("NbProductReadyToShip")!,
            nbShipped: counterMap.get("NbProductShipped")!,
            nbStockout: counterMap.get("NbProductStockout")!,
            nbTotal: counterMap.get("NbProduct")!,
        });
    }

    private renderCounterColumns(
        counter: { nbAvailable?: number, nbParcelled?: number, nbLabeled?: number, nbReadyToShip?: number, nbShipped?: number, nbStockout?: number, nbTotal?: number }) {
        return [
            this.getCounter("nbAvailable", counter.nbAvailable, counter.nbTotal),
            this.getCounter("nbParcelled", counter.nbParcelled, counter.nbTotal),
            this.getCounter("nbLabeled", counter.nbLabeled, counter.nbTotal),
            context.hasPermission(Permission.FeatureReadyToShip) ? this.getCounter("nbReadyToShip", counter.nbReadyToShip, counter.nbTotal) : undefined,
            this.getCounter("nbShipped", counter.nbShipped, counter.nbTotal),
            context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) ? this.getCounter("nbStockout", counter.nbStockout, counter.nbTotal) : undefined,
            <TableCell key="nbTotal">{counter.nbTotal}</TableCell>,
        ];
    }

    private getCounter(key: string, value?: number, total?: number) {
        if (value === undefined || total === undefined) {
            return;
        }

        const percent = total === 0 ? 0 : Math.round((value * 10000) / total) / 100;
        return [
            <TableCell className="counter-value" key={key}>{value}</TableCell>,
            <TableCell className="counter-percent" key={`${key}-percent`}>{`(${percent} %)`}</TableCell>,
        ];
    }
}

export const Reporting = withRouter(ReportingComponent);

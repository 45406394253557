import * as React from "react";
import { CircularProgress, IconButton, useTheme } from "@mui/material";
import { Done, Save } from "@mui/icons-material";

interface ISettingsFieldItemStatusProps {
    isSaveOptionEnabled: boolean;
    isSaveInProgress: boolean;
    isSuccess: boolean | null;
    onSaveClick: () => Promise<void>;
}

export const SettingsFieldItemStatus = (props: ISettingsFieldItemStatusProps) => {
    const theme = useTheme();

    if (props.isSuccess) {
        return <IconButton style={{color: theme.palette.success.dark}}>
            <Done color={"inherit"} />
        </IconButton>;
    }

    if (props.isSaveInProgress) {
        return <IconButton style={{color: theme.palette.text.primary}} size={"small"}>
            <CircularProgress />
        </IconButton>;
    }

    if (props.isSaveOptionEnabled) {
        return <IconButton onClick={async () => await props.onSaveClick()}>
            <Save />
        </IconButton>;
    }

    return <></>;
};

import * as React from "react";

import { FilterBlock } from "../../../../common/component/filter/filter-block";
import { FormFilter } from "../../../../common/component/list/filter/form-filter";
import { Parcel } from "../../../model/parcel";
import { ParcelDetail } from "../../../model/parcel-detail";
import { ParcelFilter } from "../../../model/parcel-filter";
import { observer } from "mobx-react";
import { t } from "i18next";
import { uniqBy } from "lodash";
import { computed } from "mobx";
import { ISelectItem } from "../../../../common/component/input/iselect-item";
import { DetailContentFilterType, detailContentFilterTypeList } from "../../../model/common-filter";
import { DangerousGoods } from "../../../../constants/constants";

interface IParcelListFilter {
    allowOrderPendingCancellationFiltering: boolean;
    allowOrderCancellationStatusFiltering: boolean;
    allowPalletCodeFiltering: boolean;
}

@observer
export class ParcelListFilter extends
    FormFilter<ParcelFilter, Parcel, ParcelDetail, IParcelListFilter> {

    protected renderForm(data: ParcelFilter) {
        return <div className="containerFilter">
            <div className="multiBlockFilter">
                <FilterBlock
                    className=""
                    label={t("filter.category.parcel")}
                    filterBlock={this.renderDeliveryOrderFilterBlock(data)}/>
                <FilterBlock
                    className=""
                    label={t("filter.category.shipping")}
                    filterBlock={this.renderShippingFilterBlock(data)}/>
            </div>
            <div className="oneBlockFilter">
                <FilterBlock
                    className=""
                    label={t("filter.category.details")}
                    filterBlock={this.renderDetailsFilterBlock(data)}
                />
            </div>
        </div>;
    }

    private renderDeliveryOrderFilterBlock(data: ParcelFilter) {
        return <div className="parcel">
            {this.getFieldFilter(data, "orderId")}
            {this.getFieldFilter(data, "deliveryOrderId")}
            {this.getFieldFilter(data, "parcelTracker")}
            {this.getFieldFilter(data, "parcelId")}
            {this.props.allowOrderPendingCancellationFiltering && this.getFieldFilter(data, "isOrderPendingCancellation")}
            {this.props.allowOrderCancellationStatusFiltering && this.getFieldFilter(data, "orderNonRejectedCancellationRequestStatus", this.cancellationStatusList)}
            {this.props.allowPalletCodeFiltering && this.getFieldFilter(data, "palletCode")}
        </div>;
    }

    private renderShippingFilterBlock(data: ParcelFilter) {
        return <div className="shipping">
            {this.getFieldFilter(data, "carrier", this.carriers)}
            {this.getFieldFilter(data, "country", this.countries)}
        </div>;
    }

    @computed
    private get countries() {
        return uniqBy(this.props.searchListModel.list, "country").map(parcel => {
            return {
                display: parcel.country,
                value: parcel.country,
            };
        }) as Array<ISelectItem<string>>;
    }

    @computed
    private get carriers() {
        return uniqBy(this.props.searchListModel.list, "carrier").map(parcel => {
            return {
                display: parcel.carrier,
                value: parcel.carrier,
            };
        }) as Array<ISelectItem<string>>;
    }

    private get cancellationStatusList() {
        return ["Pending", "Confirmed"].map(i => {
            return {
                display: i,
                value: i,
            };
        }) as Array<ISelectItem<string>>;
    }

    @computed
    private get detailContentTypes() {
        return detailContentFilterTypeList.map(type => {
                return {
                    display: t(`model.commonFilter.${type}`),
                    value: type,
                };
            }) as Array<ISelectItem<string>>;
    }

    private get dangerousGoods(): Array<ISelectItem<string>> {
        return [
            { value: DangerousGoods.Lithium.toString(), display: "Lithium" },
            { value: DangerousGoods.LiquidQuantity.toString(), display: "Liquid Quantity" },
            { value: DangerousGoods.Both.toString(), display: "Both" },
        ];
    }

    private renderDetailsFilterBlock(data: ParcelFilter) {
        return <div className="details">
            <div className="wide">
                {this.getField(data, "type", {
                    options: this.detailContentTypes,
                    inputComponent: "radioList",
                    onChange: (type: DetailContentFilterType) => {
                        this.switchOffFilterAction("isMultiRef");
                        this.switchOffFilterAction("isMonoRef");
                        this.switchOffFilterAction("isSingleRef");
                        switch (type) {
                            case "MultiReference":
                                this.switchOnFilterAction("isMultiRef");
                                break;
                            case "MonoReference":
                                this.switchOnFilterAction("isMonoRef");
                                break;
                            case "SingleReference":
                                this.switchOnFilterAction("isSingleRef");
                                break;
                        }
                    },
                })}
            </div>
            <div className="wide">
                <div className="left">
                    {this.getFieldSubFilter(data, "supplierReference", "details")}
                    {this.getFieldSubFilter(data, "labelReference", "details")}
                    {this.getFieldFilter(data, "dangerousGoods", this.dangerousGoods)}
                    {this.renderAdditionalDetailFilterFieds(data, "details")}
                </div>
            </div>
        </div>;
    }
}

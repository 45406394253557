import { IActionCommand } from "./bulky-action-picker";
import { DialogMessageIcon, dialogModel } from "../../dialog/dialog-model";
import { t } from "i18next";
import { IParamActionValidator } from "./interface-param-action-validator";
import { ParamActionValidationStatus } from "./param-action-validation-result";
import { loaderModel } from "../../loader/loader-model";

export class SingleActionPickerHandler {
    private readonly _action: IActionCommand<any, any>;
    private readonly _item: any;

    constructor(action: IActionCommand<any, any>, item: any) {
        this._action = action;
        this._item = item;
    }

    public async handle() {
        if (this._action.paramValidator === undefined) {
            await this.processSelectedAction(!this._action.hideExecutionConfirmation);
        } else {
            await this.validateAndExecuteAction(this._action.paramValidator);
        }
    }

    private async processSelectedAction(withConfirmationBox: boolean) {
        if (withConfirmationBox) {
            dialogModel.confirmBox(
                t(this._action.label),
                t(this._action.description || "components.selectAction.confirm", {
                    action: t(this._action.label),
                    nbElement: 1,
                }),
                async () => await this.executeAction());
        } else {
            await this.executeAction();
        }
    }

    private async validateAndExecuteAction(validator: IParamActionValidator<any>) {
        const validationResult = validator.validate([this._item]);

        if (validationResult.status === ParamActionValidationStatus.Error) {
            dialogModel.alert(
                t(this._action.label),
                validationResult.violationMessage,
                DialogMessageIcon.Error,
            );
        }

        if (validationResult.status === ParamActionValidationStatus.Success) {
            await this.processSelectedAction(!this._action.hideExecutionConfirmation);
        }

        if (validationResult.status === ParamActionValidationStatus.Warning) {
            await this.processSelectedActionWithWarning(validationResult.violationMessage);
        }
    }

    private async processSelectedActionWithWarning(warningMessage: string) {
        dialogModel.confirmBox(
            t(this._action.label),
            warningMessage,
            async () => await this.executeAction(),
            DialogMessageIcon.Warning);
    }

    private async executeAction() {
        if (this._action.hideActionProgressLoader) {
            this._action.execute!([this._item]);
        } else {
            const actionId = loaderModel.foregroundActionStart(t("components.actionLoader.pendingAction", {
                actionName: t(this._action.label),
            }));

            try {
                await this._action.execute!([this._item]);
            } finally {
                loaderModel.foregroundActionEnd(actionId);
            }

        }
    }
}

import { Dispatch, SetStateAction, useEffect } from "react";

export const useRefreshValue = <T>(callback: () => Promise<T>,
                                   onValueRefresh: Dispatch<SetStateAction<T>>,
                                   timeout: number = 5000,
                                   fireOnStart: boolean = true) => {
    const refreshValue = () => {
        (async () => onValueRefresh(await callback()))();
    };

    return useEffect(() => {
        if (fireOnStart) {
            refreshValue();
        }

        const refreshJob = setInterval(() => {
            refreshValue();
        }, timeout);

        return () => clearInterval(refreshJob);
    }, []);
};

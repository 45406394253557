import React from "react";
import { ListItem, ListItemText } from "@mui/material";
import { t } from "i18next";
import { PageRouteType, SectionRouteType } from "../../../front-office/constants/constants";
import { IMenuItem } from "./vp-menu";
import { VpMenuIconBadge } from "./vp-menu-icon-badge";

export interface IVpMenuItemProps {
    isRouteActive: boolean;
    badgeValue: number | undefined;
    item: IMenuItem<PageRouteType>;
    sectionRoute: SectionRouteType;
    changeTab: (sectionRoute: SectionRouteType, value: PageRouteType) => void;
}

export const VpMenuItem = (props: IVpMenuItemProps) => {
    return <ListItem button
                     key={props.item.iconLabel}
                     onClick={() => props.changeTab(props.sectionRoute, props.item.route)}
                     className={`expansion-panel__second-level-container ${props.isRouteActive ? "expansion-panel__second-level-container--active" : ""}`}>
        <VpMenuIconBadge selected={props.isRouteActive ? "selected" : ""} badgeValue={props.badgeValue} icon={props.item.icon} />
        <ListItemText primary={t(props.item.iconLabel)}
                      primaryTypographyProps={{className: "expansion-panel__second-level-label-text"}}
                      secondary={t(props.item.subLabel!)}
                      secondaryTypographyProps={{color: "inherit"}}
                      className={`expansion-panel__second-level-label  ${props.isRouteActive ? "expansion-panel__second-level-label--active" : ""}`} />
    </ListItem>;
};

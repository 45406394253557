import { observable, computed, action } from "mobx";

export class IconModel {

    @observable private _isPrimary = false;

    @computed
    public get isPrimary() {
        return this._isPrimary;
    }

    @action
    public highlight() {
        return this._isPrimary = true;
    }

    @action
    public lowlight() {
        return this._isPrimary = false;
    }
}

import React, { useEffect, useState } from "react";
import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { t } from "i18next";
import { VPUITheme } from "../../theme/vpui-theme";
import { Close } from "@mui/icons-material";
import { PrinterDevice } from "../../model/printer-device";
import QRCode from "react-qr-code";
import { ProgressDialog } from "../progress-dialog/progress-dialog";
import { useRefreshValue } from "../../hooks/use-refresh-value";
import { authenticationService } from "../../../services/authentication";
import { MobileToken } from "../../model/mobile-token";
import { MobileLoginQrCode } from "../../model/mobile-login-qr-code";
import { PrinterDeviceManager } from "./printer-device-manager";
import { useEffectAsync } from "../../hooks/use-effect-async";
import { PrinterSelector } from "./printer-selector";
import { Guid } from "guid-typescript";

import "./style.scss";

interface IMobilePrinterConnectorProps {
    onClose: () => void;
}

export const MobileConnector = (props: IMobilePrinterConnectorProps) => {
    const [mobileToken, setMobileToken] = useState<MobileToken | undefined>(undefined);
    const [qrLoginCode, setQrLoginCode] = useState<MobileLoginQrCode | undefined>(undefined);
    const [printerList, setPrinterList] = useState<PrinterDevice[] | undefined>(undefined);
    const [selectedPrinter, setSelectedPrinter] = useState<PrinterDevice | undefined>();

    const mobilePrinterManager = new PrinterDeviceManager();

    useRefreshValue(
        async () =>
            await authenticationService.tokenForMobile(),
        setMobileToken,
        30000);

    useEffect(() => {
        if (mobileToken) {
            setQrLoginCode(new MobileLoginQrCode(mobileToken.userName, mobileToken.token, selectedPrinter));
        }
    }, [mobileToken, selectedPrinter]);

    useEffectAsync(async () => {
        setPrinterList(await mobilePrinterManager.synchronizeWithQZ());
    }, []);

    const handlePrinterTypeChange = (printerId: Guid, isZpl: boolean) => {
        const updatedDevices = mobilePrinterManager.updatePrinterType(printerId, isZpl);

        setPrinterList(updatedDevices);
        setSelectedPrinter(updatedDevices.find(d => d.id.equals(printerId)));
    };

    if (!qrLoginCode) {
        return <ProgressDialog open={true}
                               className={"mobile-printer"}
                               message={t("components.mobileConnector.loading")} />;
    }

    return <Dialog open={true}
                   className={"mobile-connector-dialog"}
                   classes={{paper: "mobile-connector__paper"}}>
        <ThemeProvider theme={VPUITheme}>
            <div className={"mobile-connector"}>
                <div className="mobile-connector__close">
                    <IconButton onClick={() => props.onClose()}>
                        <Close />
                    </IconButton>
                </div>
                <DialogTitle>{t("components.mobileConnector.title")}</DialogTitle>
                <DialogContent>
                    <div className={"mobile-connector__content-item"}>
                        <Typography className={"mobile-connector__description"}>
                            {t("components.mobileConnector.description")}
                        </Typography>
                    </div>
                    <div className={"mobile-connector__content-item"}>
                        <div className={"mobile-connector__settings"}>
                            <Typography>
                                {t("components.mobileConnector.printer.settings")}
                            </Typography>
                            <PrinterSelector value={selectedPrinter}
                                             list={printerList}
                                             onChange={printer => setSelectedPrinter(printer)}/>
                            {selectedPrinter &&
                                <FormControlLabel label={t("components.mobileConnector.printer.isZebraPrinter")}
                                                  control={
                                    <Checkbox checked={selectedPrinter.type === "zpl"}
                                              onChange={(_event, checked) =>
                                                  handlePrinterTypeChange(selectedPrinter.id, checked)} />} />}
                        </div>
                        <div className={"mobile-connector__spacer"}></div>
                        <div className={"mobile-connector__code"}>
                            <QRCode size={240} value={JSON.stringify(qrLoginCode.toData())} />
                        </div>
                    </div>
                </DialogContent>
            </div>
        </ThemeProvider>
    </Dialog>;
};

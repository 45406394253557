import { t } from "i18next";

export class RegexValidator implements IValidator {

    private regex: RegExp;
    private errorMessage: string;

    constructor(regex: RegExp, errorMessage: string) {
        this.regex = regex;
        this.errorMessage = errorMessage;
    }

    public validate(valueToCheck: string | undefined): string | undefined {
        if (valueToCheck && !this.regex.test(valueToCheck)) {
            return t(this.errorMessage);
        }
        return;
    }
}

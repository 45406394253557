import React from "react";
import { SnackbarContent, Button, IconButton, Icon as MaterialIcon } from "@mui/material";
import { Icon, IconType } from "../../icon/icon";
import { observer } from "mobx-react";

import "./style.scss";

interface INotificationBarProps {
    index: number;
    removeNotification(index: number): void;
    message: string;
    iconClassName: string;
    iconType: IconType;
    dataTestid: string;
    action?: {onClick: () => void, title: string};
}

@observer
export class NotificationBar extends React.Component<INotificationBarProps, {}> {

    public render() {
        return <SnackbarContent
            data-testid={this.props.dataTestid}
            action={
                <>
                    {this.props.action && <Button color="secondary" size="small" onClick={() => this.props.action?.onClick()}>
                        {this.props.action?.title}
                    </Button>}
                    <IconButton className={"notification-bar-icon-right"}
                                onClick={() => this.props.removeNotification(this.props.index)}>
                        <MaterialIcon className={"close-notification"}>close</MaterialIcon>
                    </IconButton>
                </>
            }
            message={
                <div className={"notification-bar"}>
                    <div className={"notification-bar-icon-left"}>
                        <Icon icon={this.props.iconType} className={this.props.iconClassName} />
                    </div>
                    <span id={"message-id"}>{this.props.message}</span>
                </div>
            }
        />;
    }
}

import { httpGetList } from "../../common/network/fetch";
import { IWarehouseDto, Warehouse } from "../model/warehouse";

/**
 * Service responsible for API requests related to Warehouse model
 */
class WarehousesService {
    public async list() {
        return (await httpGetList<IWarehouseDto>({url: "/warehouses"}))
            .map(warehouse => new Warehouse(warehouse));
    }
}

export const warehousesService = new WarehousesService();

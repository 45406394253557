import React, { useEffect, useState } from "react";
import { DeliveryOrder } from "../../../model/delivery-order";
import { DeliveryOrderHeaderLine } from "./delivery-order-header-line";
import { DeliveryOrderListSubSort } from "./delivery-order-list-sub-sort";
import { DeliveryOrderContents } from "./delivery-order-contents";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { DeliveryOrderFilter } from "../../../model/delivery-order-filter";

interface IDeliveryOrderListLineProps {
    deliveryOrder: DeliveryOrder;
    searchListModel: SearchListModel<DeliveryOrder, DeliveryOrderDetail, DeliveryOrderFilter>;
    isExpanded: boolean;
    withSingleActionMenu: boolean;

    onSingleActionMenuClick: (anchor: EventTarget) => void;
    onParcellingPageOpen: () => void;
    onCancellationRequestMenuOpen: (anchor: HTMLElement) => void;
    onOrderIdQuickFilterEnabled: (value: number) => void;
}

export const DeliveryOrderListLine = (props: IDeliveryOrderListLineProps) => {
    const [isExpanded, setIsExpanded] = useState(props.isExpanded);

    useEffect(() => {
        setIsExpanded(props.isExpanded);
    }, [props.isExpanded]);

    return <>
        <DeliveryOrderHeaderLine key={`dor-${props.deliveryOrder.id}`}
                                 deliveryOrder={props.deliveryOrder}
                                 displaySingleActionPickerButton={props.withSingleActionMenu}
                                 isItemSelected={props.searchListModel.isSelected(props.deliveryOrder.id)}
                                 isExpanded={isExpanded}
                                 onExpandToggle={toggleValue => setIsExpanded(toggleValue)}
                                 additionalDataSize={props.searchListModel.additionalFields.length}
                                 onSelectionToggle={value => props.searchListModel.selectItem(props.deliveryOrder, value)}
                                 onSingleActionMenuClick={anchor => props.onSingleActionMenuClick(anchor)}
                                 onHeaderClick={() => props.onParcellingPageOpen()}
                                 onCancellationRequestMenuOpen={anchor => props.onCancellationRequestMenuOpen(anchor)} />
        {isExpanded && <DeliveryOrderListSubSort searchListModel={props.searchListModel}
                                                 key={`dod-header-${props.deliveryOrder.id}`}
                                                 data-testid={`dor-header-detail-${props.deliveryOrder.id}`} />}
        {isExpanded && <DeliveryOrderContents deliveryOrder={props.deliveryOrder}
                                              onOrderIdentifierFilterEnabled={orderId =>
                                                  props.onOrderIdQuickFilterEnabled(orderId)}
                                              additionalDataFields={props.searchListModel.additionalFields} />}
    </>;
};

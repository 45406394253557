import "./style.scss";

import * as React from "react";

import { Typography } from "@mui/material";
import { observer } from "mobx-react";
import { t } from "i18next";

interface ITitleProps {
    title: string;
    className?: string;
}

@observer
export class Title extends React.Component<ITitleProps, {}> {

    public render() {
        return <div className="title__container">
            <Typography variant="h6" className={`title__item ${this.props.className ?? ""}`}>
                {t(this.props.title)}
            </Typography>
        </div>;
    }
}

import {DeliveryOrder} from "../../../model/delivery-order";
import {operationsService} from "../../../services/operations";
import { filter } from "lodash";
import {OrdersDataProvider} from "../delivery-order-list/order-data-provider";

export class StockoutOrdersDataProvider extends OrdersDataProvider {

    public async loadList(operationCode: string, batchId: number): Promise<DeliveryOrder[]> {
        return filter((await operationsService.loadStockoutOrderList(
                operationCode,
                batchId)
        ));
    }

    public isInPageScope(deliveryOrder: DeliveryOrder): boolean {
        return deliveryOrder.details.filter(deliveryOrderDetail => deliveryOrderDetail.quantityStockout > 0).length > 0;
    }
}

import React, { PropsWithChildren, ReactElement } from "react";
import { FormControlLabelProps } from "@mui/material/FormControlLabel/FormControlLabel";

export interface IDialogActionsOptionProps {
    children: ReactElement<PropsWithChildren<FormControlLabelProps>>;
}

export const DialogActionsOption = (props: IDialogActionsOptionProps) => {
    return <div>
        {props.children}
    </div>;
};

export class UploadedStockout {
    private readonly _productId: number;
    private readonly _stockoutQuantity: number;

    constructor(productId: number, stockoutQuantity: number) {
        this._productId = productId;
        this._stockoutQuantity = stockoutQuantity;
    }

    get productId(): number {
        return this._productId;
    }

    get stockoutQuantity(): number {
        return this._stockoutQuantity;
    }
}

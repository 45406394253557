import "./style.scss";

import * as React from "react";

import { loaderModel } from "./loader-model";
import { observer } from "mobx-react";
import { Dialog, CircularProgress, DialogContent, DialogContentText } from "@mui/material";

interface ILoaderProps {
    isBackground: boolean;
}

@observer
export class Loader extends React.Component<ILoaderProps, {}> {

    public render() {
        if (this.props.isBackground) {
            if (loaderModel.hasBackgroundAction) {
                return <CircularProgress className="circular-progress" />;
            }
            return <div />;
        }
        return (
            <Dialog
                data-testid="loader"
                disableEscapeKeyDown
                disableEnforceFocus
                open={loaderModel.hasForegroundAction}>
                <DialogContent>
                    <div >
                        <div><ul>{this.renderDescription()}</ul>
                        </div>
                        <div className="action-loader-spinner"><CircularProgress /></div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    private renderDescription() {
        const lines: JSX.Element[] = [];
        loaderModel.foregroundActionList.forEach(description =>
            lines.push(<li key={description}><DialogContentText>{description}</DialogContentText></li>));
        return lines;
    }
}

import React from "react";
import { Button, DialogActions, DialogContent, Typography } from "@mui/material";
import { t } from "i18next";

interface ISupportTicketConfirmationProps {
    ticketId: string;
    onClose: () => void;
}

export const SupportTicketConfirmation = (props: ISupportTicketConfirmationProps) => {
    return <>
        <DialogContent className={"support-ticket__form"}>
            <Typography>
                {t("components.supportTicket.confirmation.header")}
            </Typography>
            <Typography className={"support-ticket__number"}>
                {props.ticketId}
            </Typography>
            <Typography>
                {t("components.supportTicket.confirmation.description")}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button color={"inherit"}
                    variant={"contained"}
                    onClick={props.onClose}
                    type={"reset"}>
                {t("components.supportTicket.confirmation.close")}
            </Button>
        </DialogActions>
    </>;
};

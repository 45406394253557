import React, { useEffect, useState } from "react";
import {
    ClearIndicatorProps,
    components,
    ControlProps,
    DropdownIndicatorProps,
    FormatOptionLabelMeta,
    InputProps,
    MenuProps,
    OptionProps,
} from "react-select";
import Select from "react-select";
import { VPUITheme } from "../../theme/vpui-theme";
import { blue } from "../../theme/colors";
import { FormControl, FormHelperText } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

import "./style.scss";

export interface ISelectAutocompleteProps<T> {
    propertyKey?: string;
    list: T[];
    isMulti?: boolean;
    onSelect?: (item: T | null) => void;
    onSelectMulti?: (item: T[] | null) => void;
    selectedItem?: T | null;
    selectedItems?: T[];
    label: string;
    disabled?: boolean;
    placeholder: string;
    noOptionsMessage: string;
    getOptionLabel?: (o: T) => string;
    getOptionValue: (o: T) => string;
    formatOptionLabel?: (option: T, labelMeta: FormatOptionLabelMeta<T>) => React.ReactNode;
    errorMessage?: string;
}

interface IControlComponentProps extends ControlProps {
    label: string;
    errorMessage?: string;
}

interface IOptionComponentProps<T, IsMulti extends boolean> extends OptionProps<T, IsMulti> {
    getOptionValue: (option: T | null) => string;
}

const ClearIndicatorComponent = (props: ClearIndicatorProps) => {
    return <div data-testid={"clear-indicator"}>
        <components.ClearIndicator {...props} children={props.children} />
    </div>;
};

const ControlComponent = (props: IControlComponentProps) => {
    return props.label ?
        <ControlComponentWithLabel {...props} children={props.children}/>
        : <components.Control {...props} children={props.children}
                              data-testid={"control"}
                              className={`select-autocomplete__control ${props.errorMessage ? "select-autocomplete__control--with-error" : ""}`} />;
};

const ControlComponentWithLabel = (props: IControlComponentProps) => {
    return <fieldset className={"select-autocomplete__control-container"}
                     data-testid={"control"}
                     style={{
                         borderColor: props.menuIsOpen ?
                             VPUITheme.palette.secondary.main :
                             props.getStyles("control", props).borderColor?.toString(),
                         backgroundColor: props.getStyles("control", props).backgroundColor?.toString(),
                     }}>
        <legend className={"select-autocomplete__control-label"}>
            {props.label}
        </legend>
        <components.Control {...props} className={"select-autocomplete__control--with-label"}
                            children={props.children} />
    </fieldset>;
};

const DropdownIndicatorComponent = (props: DropdownIndicatorProps) => {
    return <components.DropdownIndicator {...props}>
        <ArrowDropDown />
    </components.DropdownIndicator>;
};

const MenuComponent = (props: MenuProps) => {
    return <components.Menu {...props} children={props.children}
                            className={"select-autocomplete__menu"} />;
 };

const OptionComponent = <T extends unknown, IsMulti extends boolean = false>(props: IOptionComponentProps<T, IsMulti>) => {
    return  <div data-testid={`option-${props.getOptionValue(props.data)}`}
                 className={"select-autocomplete__option-container"}
                 style={{borderColor: props.getStyles("option", props).borderColor?.toString()}}>
        <components.Option {...props} children={props.children} className={"select-autocomplete__option"} />
    </div>;
};

export const SelectAutocomplete = <T extends unknown>(props: ISelectAutocompleteProps<T>) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(props.errorMessage);

    useEffect(() => {
        setErrorMessage(props.errorMessage);
    }, [props.errorMessage]);

    const handleValueChange = (item: T | null) => {
        setErrorMessage(undefined);

        if (props.onSelect) {
            return props.onSelect(item);
        }
    };

    const handleMultiValueChange = (item: T[] | null) => {
        setErrorMessage(undefined);

        if (props.onSelectMulti) {
            return props.onSelectMulti(item);
        }
    };

    return <FormControl fullWidth variant={"outlined"}>
        <Select options={props.list}
                key={props.propertyKey}
                className={"select-autocomplete"}
                styles={{
                    container: (base: {}) => ({
                        ...base,
                        fontFamily: VPUITheme.typography.body1.fontFamily,
                        width: "100%",
                    }),
                    control: (base: {}, componentProps: ControlProps) => {
                        return ({
                            ...base,
                            "borderColor": componentProps.menuIsOpen ? blue[500] : VPUITheme.palette.grey[400],
                            "&:hover": {
                                borderColor: componentProps.menuIsOpen ? blue[500] : VPUITheme.palette.grey.A400,
                            },
                            "borderWidth": 0,
                            "backgroundColor": !componentProps.isDisabled ? "transparent" : VPUITheme.palette.grey["100"],
                        });
                    },
                    input: (base: {}, componentProps: InputProps) => ({
                        ...base,
                        caretColor: componentProps.hasValue && componentProps.value === "" ? "transparent" : undefined,
                    }),
                    menu: (base: {}) => ({
                        ...base,
                        borderColor: VPUITheme.palette.grey[300],
                    }),
                    menuList: (base: {}) => ({
                        ...base,
                        padding: "0px",
                    }),
                    option: (base: {}, componentProps: OptionProps) => ({
                        ...base,
                        "backgroundColor": !componentProps.isFocused ? "transparent" : VPUITheme.palette.grey["100"],
                        "borderColor": VPUITheme.palette.grey[400],
                        "color": VPUITheme.palette.text.primary,
                        ":active": {
                            backgroundColor: VPUITheme.palette.grey["100"],
                        },
                    }),
                    noOptionsMessage: (base: {}) => ({
                        ...base,
                        textAlign: "left",
                    }),
                }}
                isSearchable
                isClearable
                isDisabled={props.disabled} isMulti={props.isMulti}
                noOptionsMessage={() => props.noOptionsMessage}
                value={props.isMulti ? props.selectedItems : props.selectedItem}
                onChange={props.isMulti ? handleMultiValueChange : handleValueChange}
                placeholder={props.placeholder}
                formatOptionLabel={props.formatOptionLabel}
                getOptionValue={props.getOptionValue}
                getOptionLabel={props.getOptionLabel || props.getOptionValue}
                components={{
                    ClearIndicator: ClearIndicatorComponent,
                    Control: (componentProps: ControlProps) =>
                        <ControlComponent {...componentProps} children={componentProps.children}
                                          errorMessage={errorMessage}
                                          label={props.label} />,
                    DropdownIndicator: DropdownIndicatorComponent,
                    Menu: MenuComponent,
                    Option: (componentProps: OptionProps) =>
                        <OptionComponent {...componentProps} children={componentProps.children}
                                         getOptionValue={props.getOptionValue} />,
                }} />
        {errorMessage && <FormHelperText error={true}>{errorMessage}</FormHelperText>}
    </FormControl>;
};

import { IActionEligibilityValidator } from "./interface-action-eligibility-validator";
import { context } from "../../../../context/context";

class EligibleForAdministratorValidator implements IActionEligibilityValidator {
    public validate(): boolean {
        return context.isAdministrator || context.isSuperAdministrator;
    }
}

export const eligibleForAdministratorValidator = new EligibleForAdministratorValidator();

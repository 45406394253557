import { Parcel } from "../../model/parcel";
import { t } from "i18next";

export class CreatePalletCommand {
    private readonly _warehouseCode: string;
    private readonly _palletCarrierGroup: string;
    private readonly _palletCountryGroup?: string;
    private readonly _parcels: Parcel[];
    private readonly _externalId?: string[];
    private readonly _maxParcelWeight: number;
    private readonly _maxWeight: number;
    private readonly _creationComment: string;

    constructor(warehouseCode: string, palletCarrierGroup: string, palletCountryGroup?: string,
                parcelList: Parcel[] = [], maxParcelWeight: number = 0, maxWeight: number = 0,
                creationComment: string = "", externalId?: string[]) {
        this._warehouseCode = warehouseCode;
        this._palletCarrierGroup = palletCarrierGroup;
        this._palletCountryGroup = palletCountryGroup;
        this._parcels = parcelList;
        this._externalId = externalId;
        this._maxParcelWeight = maxParcelWeight;
        this._maxWeight = maxWeight;
        this._creationComment = creationComment;

        if (this._palletCarrierGroup === undefined && this._parcels.length === 0) {
            throw new Error(t("error.command.createPallet.missingParcelsOrCarrier"));
        }
    }

    public static withParcels(warehouseCode: string, parcelsList: Parcel[]) {
        if (parcelsList.some(parcel => parcel.suggestedPalletCarrierGroup === undefined)) {
            throw new Error(t("error.command.createPallet.parcelWithoutRecommendedCarrier"));
        }

        if (!parcelsList[0].canBePalletizedWith(parcelsList)) {
            throw new Error(t("error.command.createPallet.parcelNotInTheSameCarrierGroup"));
        }

        return new CreatePalletCommand(warehouseCode, parcelsList[0].suggestedPalletCarrierGroup!, undefined, parcelsList);
    }

    get warehouseCode(): string {
        return this._warehouseCode;
    }

    get palletCarrierGroup(): string {
        return this._palletCarrierGroup;
    }

    get palletCountryGroup(): string | undefined {
        return this._palletCountryGroup;
    }

    get parcels(): Parcel[] {
        return this._parcels;
    }

    get maxParcelWeight(): number {
        return this._maxParcelWeight;
    }

    get maxWeight(): number {
        return this._maxWeight;
    }

    get creationComment(): string {
        return this._creationComment;
    }

    get externalId(): string[] | undefined {
        return this._externalId;
    }
}

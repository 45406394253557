import {
    httpDelete,
    httpFileRequest,
    httpGet,
    httpGetList,
    httpPatch,
    httpPost,
} from "../../common/network/fetch";

import { IDeliveryOrder } from "../model/delivery-order";
import {LabelFormatType, ParcelBulkCreationMode, ParcelDocumentType} from "../../constants/constants";
import { IParcel, Parcel } from "../model/parcel";
import { ParcelCommandReturn } from "../model/parcel-command-return";
import { ParcelStatusType } from "../constants/constants";
import { PickAndPackCommandReturn } from "../model/pick-and-pack-command-return";
import { UrlBuilder } from "./url-builder";
import { documentService } from "./document";
import { ParcelDetailsArrangement } from "../model/parcel-details-arrangement";
import { IParcelCreationResponse, ParcelCreationResponse } from "../model/parcel-creation-response";

class ParcelsService {

    public async loadParcels(operationCode: string, batchId: number, parcelStatus?: ParcelStatusType) {
        let parcelUrl = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId);

        if (parcelStatus) {
            parcelUrl = parcelUrl.setParcelStatus(parcelStatus);
        }

        return (await httpGetList<IParcel>({url: parcelUrl.build()})).map(parcel => new Parcel(parcel));
    }

    public async loadParcel(operationCode: string, batchId: number, deliveryOrderId: number, parcelId: number) {
        const parcelUrl = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setParcelId(parcelId)
            .build();

        const [parcel, deliveryOrder] = await Promise.all([
            httpGet<IParcel>({url: parcelUrl}),
            httpGet<IDeliveryOrder>({url: `/operations/${operationCode}/batches/${batchId}/deliveryOrder/${deliveryOrderId}`}),
        ]);
        return this.fusionParcel(parcel, deliveryOrder);
    }

    public async loadParcelByParcelId(operationCode: string, batchId: number, parcelId: number) {
        const parcelUrl = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setParcelId(parcelId)
            .build();

        const parcel = await httpGet<IParcel>({url: parcelUrl});
        const deliveryOrder = await httpGet<IDeliveryOrder>({
            url: `/operations/${operationCode}/batches/${batchId}/deliveryOrder/${parcel.deliveryOrderId}`,
        });

        return this.fusionParcel(parcel, deliveryOrder);
    }

    public async findParcel(locator: string) {
        return new Parcel(await httpGet<IParcel>({
            url: new UrlBuilder("/parcels")
                .search(locator)
                .build(),
        }));
    }

    private fusionParcel(parcel: IParcel, deliveryOrder: IDeliveryOrder) {
        if (!deliveryOrder) {
            throw new Error(`This parcel "${parcel.id}" doesn"t have delivery order associated.`);
        }

        return new Parcel(parcel, deliveryOrder);
    }

    /**
     * Method that call Document webservice.
     * @param operationCode Operation code.
     * @param batchId Batch identifier.
     * @param parcelIds
     * @param documentType
     * @param detailsArrangement
     */
    public async multipageDocument(operationCode: string, batchId: number, parcelIds: number[], documentType: ParcelDocumentType, detailsArrangement?: ParcelDetailsArrangement[]) {
        const createdDocument = await documentService.createDocumentWithDetailsArrangement(operationCode, batchId, parcelIds, documentType, detailsArrangement);

        return await documentService.getDocumentForOperation(operationCode, batchId, createdDocument.id,  10000 + parcelIds.length * 3000);
    }

    /**
     * Method that call Label webservice.
     * @param operationCode Operation code.
     * @param batchId Batch identifier.
     * @param parcelId Parcel identifier.
     * @param labelFormat
     */
    public async label(operationCode: string, batchId: number, parcelId: number, labelFormat: LabelFormatType) {

        const url = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setParcelId(parcelId)
            .setLabelType(labelFormat)
            .build();
        return await httpFileRequest("PUT", url);
    }

    public async preparationOrder(operationCode: string, batchId: number, parcelId: number, parcelDetailsArrangement: number[]) {
        const url = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setParcelId(parcelId)
            .setPreparationOrder()
            .build();
        return await httpFileRequest("PUT", url, true, {parcelDetailsArrangement});
    }

    public async deleteParcelList(operationCode: string, batchId: number, parcelIdList: number[]) {
        const url = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId);
        return await httpDelete<ParcelCommandReturn>({url: url.build(), data: parcelIdList});
    }

    public async deleteParcelShipment(operationCode: string, batchId: number, parcelId: number): Promise<any> {
        const url = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setParcelId(parcelId)
            .setShipment();
        return await httpDelete({url: url.build()});
    }

    public async deleteParcelById(operationCode: string, batchId: number, parcelId: number) {
        const url = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setParcelId(parcelId);
        return await httpDelete({url: url.build()});
    }

    public async updateParcel(
        operationCode: string,
        batchId: number,
        parcel: Parcel,
    ) {
        const parcelUrl = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setParcelId(parcel.id)
            .build();

        return await httpPatch<number>({url: parcelUrl, data: this.parseParcelDetail(parcel)});
    }

    public async createParcelsBulk(
        operationCode: string,
        batchId: number,
        deliveryOrderIdList: number[],
        mode: ParcelBulkCreationMode,
    ) {
        const url = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setParcelBulkCreationMode(mode);

        return new ParcelCreationResponse(
            await httpPost<IParcelCreationResponse>({url: url.build(), data: deliveryOrderIdList}));
    }

    public async createParcel(
        operationCode: string,
        parcel: Parcel,
        displayNotificationError: boolean = true,
    ) {
        const parcelUrl = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(parcel.batchId)
            .setDeliveryOrderId(parcel.deliveryOrderId)
            .build();

        return await httpPost<number>({url: parcelUrl, data: this.parseParcelDetail(parcel)}, displayNotificationError);
    }

    public async pickAndPack(operationCode: string, batchId: number, ean: string, labelFormat: LabelFormatType) {
        const pickAndPackUrl = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setEan(ean)
            .setPickAndPack(labelFormat)
            .build();

        const pickAndPackResult = await httpPost<PickAndPackCommandReturn>({url: pickAndPackUrl}, false);
        return new PickAndPackCommandReturn(pickAndPackResult);
    }

    public async shipParcels(operationCode: string, batchId: number, parcelsId?: number[]) {
        const parcelUrl = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setShip()
            .build();

        return await httpPost<ParcelCommandReturn>({url: parcelUrl, data: parcelsId || []});
    }

    public async markParcelsAsReadyToBeShipped(operationCode: string, batchId: number, parcelsId?: number[]) {
        const parcelUrl = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setMarkAsReadyToBeShipped()
            .build();

        return await httpPost<ParcelCommandReturn>({url: parcelUrl, data: parcelsId || []});
    }

    private parseParcelDetail(parcel: Parcel) {
        const quantities: { [key: string]: number } = {};
        parcel.details.forEach(d => {
            if (d.quantity) {
                quantities[d.productId] = d.quantity;
            }
        });
        return quantities;
    }

    public async announceParcelTracker(operationCode: string, batchId: number, parcelId: number, parcelTracker: string, carrier: string) {
        const parcelUrl = new UrlBuilder("/parcels/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setParcelId(parcelId)
            .setTrackingAnnouncement()
            .build();

        return await httpPost<ParcelCommandReturn>({url: parcelUrl, data: {parcelTracker, carrier}});
    }
}

export const parcelsService = new ParcelsService();

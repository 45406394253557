import { DeliveryOrderStatusType } from "../../constants/constants";
import { label, domain } from "../../common/dto/dto-annotation";
import { identifier } from "../../domain/identifier";
import { label50 } from "../../domain/label";
import { localDate } from "../../domain/local-date";
import { assign } from "lodash";
import { AddressFull } from "./address-full";
import { AddressWarehouseFull } from "./address-warehouse-full";
import { DeliveryOrderDetailFull } from "./delivery-order-detail-full";

export class DeliveryOrderFull {

    constructor(deliveryOrderFull?: DeliveryOrderFull) {
        assign(this, deliveryOrderFull);
        if (deliveryOrderFull) {
            this.shippingAddress = new AddressFull(deliveryOrderFull.shippingAddress);
            this.returnAddress = new AddressWarehouseFull(deliveryOrderFull.returnAddress);
            this.details = deliveryOrderFull.details && deliveryOrderFull.details.map(
                detail => new DeliveryOrderDetailFull(detail),
            );
        }
    }

    @label("model.deliveryOrderFull.id")
    @domain(identifier)
    public id: number;

    @label("model.deliveryOrderFull.orderId")
    @domain(identifier)
    public orderId: number;

    @label("model.deliveryOrderFull.batchId")
    @domain(identifier)
    public batchId: number;

    @label("model.deliveryOrderFull.operationCode")
    @domain(label50)
    public operationCode: string;

    @label("model.deliveryOrderFull.carrier")
    @domain(label50)
    public carrier: string;

    @label("model.deliveryOrderFull.status")
    @domain(label50)
    public status: DeliveryOrderStatusType;

    @label("model.deliveryOrderFull.logisticCommitmentDate")
    @domain(localDate)
    public logisticCommitmentDate: string;

    public shippingAddress: AddressFull;
    public returnAddress: AddressWarehouseFull;

    public details: DeliveryOrderDetailFull[];
}

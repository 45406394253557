import { action, computed, observable } from "mobx";

import { Parcel} from "../../../model/parcel";
import { paginationSize } from "../../../../constants/constants";
import { parcelsService } from "../../../services/parcels";
import { DeliveryOrder } from "../../../model/delivery-order";
import { Area } from "./area";
import { operationsService } from "../../../services/operations";
import { orderBy } from "lodash";

export class AreaListModel {
    private _operationCode: string;
    private _batchId: number;

    @observable private _list: Area[] = [];
    @observable private _pageSize: number = paginationSize;
    @observable private _listMode: string;

    @computed
    public get list() {
        return this._list as ReadonlyArray<Area>;
    }

    @action
    private updateAreaList(list: Area[]) {
        this._list = orderBy(list, "areaNumber", "asc");
    }

    @computed
    public get pageSize() {
        return this._pageSize;
    }

    @computed
    public get listMode() {
        return this._listMode;
    }

    @action
    public initStore() {
        this._pageSize = paginationSize;
        this._listMode = "tile";
    }

    @action
    public showMore() {
        this._pageSize += paginationSize;
    }

    @action
    public switchMode() {
        if (this._listMode === "tile") {
            this._listMode = "list";
        } else  {
            this._listMode = "tile";
        }
    }

    @action
    public async load(operationCode: string, batchId: number) {
        this._operationCode = operationCode;
        this._batchId = batchId;

        const [deliveryOrderList, parcels] = await Promise.all([
            await operationsService.loadDeliveryOrderList(this._operationCode, this._batchId),
            await parcelsService.loadParcels(this._operationCode, this._batchId, "New"),
        ]);

        this.updateAreaList(parcels.filter(p => p.area !== null)
            .map(p => Area.createForParcel(p, this.findOrderForParcelOrError(deliveryOrderList, p))));
    }

    @action
    public async reload() {
        await this.load(this._operationCode, this._batchId);
    }

    private findOrderForParcelOrError(deliveryOrderList: DeliveryOrder[], parcel: Parcel): DeliveryOrder {
        const deliveryOrder = deliveryOrderList.find(d => d.deliveryOrderId === parcel.deliveryOrderId);

        if (deliveryOrder === undefined) {
            throw new Error("No order matching parcel was found in this batch.");
        }

        return deliveryOrder;
    }
}

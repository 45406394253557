import * as React from "react";
import { SelectAutocomplete } from "../select-autocomplete/select-autocomplete";

export class InputSelectOption {
    constructor(public label: string, public value: string) {}
}

export interface IInputSelectProps {
    list: InputSelectOption[];
    onSelect: (item: InputSelectOption | null) => void;
    label: string;
    selectedItem: InputSelectOption | null;
    propertyKey: string;
    disabled?: boolean;
    placeholder: string;
    noOptionsMessage: string;
}

export const InputSelect = (props: IInputSelectProps) => {
    return <SelectAutocomplete {...props}
                            getOptionValue={option => option.value}
                            getOptionLabel={option => option.label}
                            formatOptionLabel={option => option.label} />;
};

import { PalletsListItem } from "../pallets-list/pallets-list-item";

export enum PalletShippingValidationStrategy {
    ForShippingList,
    ForScanMode,
}

export class PalletShippingValidationRequest {
    private readonly _shippingPalletsList: PalletsListItem[];
    private readonly _strategy: PalletShippingValidationStrategy;

    constructor(shippingPalletsList: PalletsListItem[], strategy: PalletShippingValidationStrategy) {
        this._shippingPalletsList = shippingPalletsList;
        this._strategy = strategy;
    }

    get shippingPalletsList(): PalletsListItem[] {
        return this._shippingPalletsList;
    }

    get strategy(): PalletShippingValidationStrategy {
        return this._strategy;
    }
}

import "./style.scss";

import React from "react";
import { Button, Stepper, Step, StepLabel, Paper } from "@mui/material";
import { t } from "i18next";
import { observer, inject } from "mobx-react";
import { SelectFileImport } from "./select-file-import";
import { SelectColumnFileImport } from "./select-column-file-import";
import { SummaryFileImport } from "./summary-file-import";
import { ImportFileModel } from "../../../../common/component/list/import-export-list/import-file-model";
import { ImportExecutionReport } from "./import-execution-report";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { IWithRouterProps, withRouter } from "../../../../common/component/hoc/withRouter";

@inject("routing")
@observer
class ImportModuleComponent extends React.Component<IHistoryProps & IWithRouterProps> {

    public constructor(props: IWithRouterProps & IHistoryProps) {
        super(props);
        this.importFileModel = new ImportFileModel(this.props.params.operationCode);
    }

    private importFileModel: ImportFileModel;

    private get steps(): ReadonlyArray<string> {
        return [t("components.importList.fileSelection"), t("components.importList.linkSelection"), t("components.importList.summary")];
    }

    private getStepContent(stepIndex: number) {
        switch (stepIndex) {
            case 0:
                return <SelectFileImport importFileModel={this.importFileModel} />;
            case 1:
                return <SelectColumnFileImport importFileModel={this.importFileModel} />;
            case 2:
                return <SummaryFileImport importFileModel={this.importFileModel} />;
            default:
                throw new Error("There is no step at this index");
        }
    }

    private renderImportStepper() {
        const steps = this.steps;
        return <div className="import-content">
            <div className="import-file-stepper">
                <Stepper activeStep={this.importFileModel.importActiveStep} alternativeLabel data-testid="stepper">
                    {steps.map(label => {
                        return <Step key={label} className="step">
                            <StepLabel>{<div className={label === steps[this.importFileModel.importActiveStep]  ? "isActiveStep" : ""}>{label}</div>}</StepLabel>
                        </Step>;
                    })}
                </Stepper>
                <div className="import-file-content" data-testid="import-content">
                    <div>
                        <Paper component="div" className="import-body">{this.getStepContent(this.importFileModel.importActiveStep)}</Paper>
                        <div className="import-file-buttons">
                            {this.renderPreviousButton()}
                            {this.renderNextButton()}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    private renderPreviousButton() {
        if (this.importFileModel.importActiveStep === 0) {
            return <div />;
        }
        return <Button onClick={() => this.importFileModel.prevStepImport()} className="step-back-button" variant="contained">
            {t("components.importList.back")}
        </Button>;
    }

    private renderNextButton() {
        const isLastStep = this.importFileModel.importActiveStep >= (this.steps.length - 1);
        return <Button color="primary" onClick={() => this.importFileModel.nextStepImport()} data-testid="next-step-button" variant="contained">
            {isLastStep ? t("components.importList.finish") : t("components.importList.next")}
        </Button>;
    }

    private renderExecutionReport() {
        return <ImportExecutionReport importFileModel={this.importFileModel} routing={this.props.routing} />;
    }

    public render() {
        return this.importFileModel.importActiveStep === -1 ? this.renderExecutionReport() : this.renderImportStepper();
    }
}

export const ImportModule = withRouter(ImportModuleComponent);

import { observable, action, computed } from "mobx";

class BackToTopModel {
    @observable private _isScrolled: boolean = false;

    @computed
    public get isScrolled() {
        return this._isScrolled;
    }

    @action
    public handleScroll() {
        if (!this._isScrolled && window.pageYOffset > 0) {
            this._isScrolled = true;
        } else if (this._isScrolled && window.pageYOffset === 0) {
            this._isScrolled = false;
        }
    }
}

export const backToTopModel = new BackToTopModel();

import "./style.scss";

import { Icon } from "../icon/icon";
import React from "react";
import { observer } from "mobx-react";
import { backToTopModel } from "./back-to-top-model";

@observer
export class BackToTop extends React.Component<{}> {

    private scrollToTop() {
        const scrollDuration = 200;
        const scrollStep = -this.getScrollTop() / (scrollDuration / 15);
        const scrollInterval = setInterval(() => {
            if (this.getScrollTop() !== 0) {
                window.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 15);
    }

    private getScrollTop() {
        return window.pageYOffset;
    }

    public render() {
        return backToTopModel.isScrolled ? <div className={"back-to-top fade-in"}>
            <Icon icon="vertical_align_top" isRounded={true}
                onClick={() => this.scrollToTop()} />
        </div > : <div />;
    }
}

import { CommonFilter } from "./common-filter";
import { domain, filterSettings, label } from "../../common/dto/dto-annotation";

import { observable } from "mobx";
import { quantity } from "../../domain/quantity";

export class DeliveryOrderFilter extends CommonFilter {

    /** Number of parcels in the current delivery order.  */
    @label("model.deliveryOrderFilter.parcelCount")
    @domain(quantity)
    @observable
    public parcelCount: number;

    /** Is order pending cancellation  */
    @label("model.deliveryOrderFilter.isPendingCancellation")
    @filterSettings({
        actions: ["all", "isFalse", "isTrue"],
    })
    @observable
    public isPendingCancellation: boolean;

    /** Is containing product sets  */
    @label("model.deliveryOrderFilter.isContainingProductSet")
    @filterSettings({
        actions: ["all", "isFalse", "isTrue"],
    })
    @observable
    public isContainingProductSet: boolean;
}

import React, { ReactNode, ReactNodeArray } from "react";

interface IDialogActionsButtonsProps {
    children: ReactNode | ReactNodeArray;
}

export const DialogActionsButtons = (props: IDialogActionsButtonsProps) => {
    return <div className={"dialog-actions__button-list"}>
        {props.children}
    </div>;
};

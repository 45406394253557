import * as React from "react";

import { IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableRow, Tooltip, Icon } from "@mui/material";
import { getReadOnlyField, stringValue } from "../../../../common/field/renderer";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { observer } from "mobx-react";
import { parcellingModel } from "./parcelling-model";
import { t } from "i18next";
import { context } from "../../../../context/context";
import { FeatureToggle } from "../../../../context/app-configuration";
import { Permission } from "../../../../context/permission";
import { UnCodeIcon } from "../un-code-icon/un-code-icon";

@observer
export class ParcellingDeliveryOrderDetail extends React.Component<{}, {}> {

    public render() {
        return <div>
            <Table className="delivery-order-product-table">
                <TableBody className="table-body">
                    <TableRow className="level1">
                        <TableCell colSpan={2}>
                            {getReadOnlyField(parcellingModel.deliveryOrder!, "orderId")}
                        </TableCell>
                        <TableCell colSpan={6}>
                            {getReadOnlyField(parcellingModel.deliveryOrder!, "id")}
                        </TableCell>
                    </TableRow>
                    <TableRow className="level2 header">
                        <TableCell>{t("model.deliveryOrderDetail.productId")}</TableCell>
                        <TableCell>{t("model.deliveryOrderDetail.ean13List")}</TableCell>
                        <TableCell>{t("model.deliveryOrderDetail.supplierReference")}</TableCell>
                        <TableCell>{t("model.deliveryOrderDetail.name")}</TableCell>
                        <TableCell>{t("model.deliveryOrderDetail.weight")}</TableCell>
                        <TableCell>{t("deliveryOrderParcelling.productList.affectedQuantity")}</TableCell>
                        {context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) && <TableCell>{t("model.deliveryOrderDetail.quantityStockout")}</TableCell>}
                        <TableCell colSpan={2}/>
                    </TableRow>
                    {this.renderList()}
                </TableBody>
            </Table>
            <Menu open={parcellingModel.actionsMenuOpen}
                  anchorEl={parcellingModel.actionsMenuAnchorEl}
                  onClose={() => parcellingModel.onActionsMenuClose()}
                  data-testid={`parcelling-stockout-menu`}>
                <MenuItem
                    disabled={parcellingModel.actionDodIsFulfilled}
                    data-testid={"parcelling-stockout-menu-create"}
                    onClick={() => parcellingModel.onMarkAsStockout()}>
                        {t("deliveryOrderParcelling.stockouts.create")}
                </MenuItem>
                {context.hasPermission(Permission.BackOffice) && <MenuItem
                    disabled={!parcellingModel.actionDodHasStockouts}
                    data-testid={"parcelling-stockout-menu-revoke"}
                    onClick={() => parcellingModel.onRemoveStockout()}>
                        {t("deliveryOrderParcelling.stockouts.delete")}
                </MenuItem>}
            </Menu>
        </div>;
    }

    private renderList() {
        return parcellingModel.deliveryOrder &&
            parcellingModel.deliveryOrder.details.map((deliveryOrderDetail, index) =>
                this.renderLine(deliveryOrderDetail, index));
    }

    private renderLine(deliveryOrderDetail: DeliveryOrderDetail, index: number) {
        return <TableRow key={"row-order-detail-" + index} className="level2 content"
                         data-testid={"dod-prd-" + deliveryOrderDetail.productId}>
            <TableCell>
                {stringValue(deliveryOrderDetail, "productId")}
            </TableCell>
            <TableCell>
                {this.renderEanList(deliveryOrderDetail.ean13List)}
            </TableCell>
            <TableCell>
                {stringValue(deliveryOrderDetail, "supplierReference")}
            </TableCell>
            <TableCell>
                {deliveryOrderDetail.unCode && <UnCodeIcon value={deliveryOrderDetail.unCode}></UnCodeIcon>}
                {stringValue(deliveryOrderDetail, "labelReference")}
            </TableCell>
            <TableCell>
                {stringValue(deliveryOrderDetail, "weight")}
            </TableCell>
            <TableCell>
                {this.renderQuantity(deliveryOrderDetail, "quantityParcelled")}
            </TableCell>
                {context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) &&
            <TableCell>
                {this.renderQuantity(deliveryOrderDetail, "quantityStockout")}
            </TableCell>
            }
            <TableCell>
                {this.renderLineActions(deliveryOrderDetail)}
            </TableCell>
        </TableRow>;
    }

    private renderLineActions(deliveryOrderDetail: DeliveryOrderDetail) {
        const icon = deliveryOrderDetail.isFulfilled ? "done" : "error";
        return <div className="actions">
            <Tooltip title={t("deliveryOrderParcelling.productList.warningTooltip")} placement={"top-end"}>
                <Icon color="primary" data-testid={icon}>{icon}</Icon>
            </Tooltip>
            {context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) &&
            <IconButton aria-label="Actions"
                        data-testid={`stockout-action-detail-${deliveryOrderDetail.productId}-icon`}
                        className={deliveryOrderDetail.stockoutActionsAvailable ? "" : "hidden"}
                        disabled={!deliveryOrderDetail.stockoutActionsAvailable}
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                            parcellingModel.onActionsMenuOpen(event.target as HTMLElement, deliveryOrderDetail)}>
                <Icon>more_horiz</Icon>
            </IconButton>
            }
        </div>;
    }

    private renderEanList(ean13List: string[]) {
        return <ul>{ean13List.map((ean, idx) => <li key={"ean" + idx}>{ean}</li>)}</ul>;
    }

    private renderQuantity(deliveryOrderDetail: DeliveryOrderDetail, quantityToRenderPropertyKey: "quantityParcelled" | "quantityStockout") {
        return <div>
            <span data-testid="dod-qty-parcelled">{stringValue(deliveryOrderDetail, quantityToRenderPropertyKey)}</span>
            <span>&nbsp;/&nbsp;</span>
            <span data-testid="dod-qty-total">{stringValue(deliveryOrderDetail, "quantity")}</span>
        </div>;
    }
}

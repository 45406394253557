import { Parcel } from "../../../model/parcel";
import { domain } from "../../../../common/dto/dto-annotation";
import { weight } from "../../../../domain/weight";
import { sumBy } from "lodash";
import { action, observable } from "mobx";

export class PalletsListSubItem {
    private readonly _id: number;
    private readonly _operationCode: string;
    private readonly _batchId: number;
    private readonly _deliveryOrderId: number;
    private readonly _orderId: number;
    private readonly _zipCode: string;
    private readonly _weight: number;
    private readonly _articlesCount: number;
    private readonly _productsCount: number;
    private readonly _parcelTracker: string;
    public additionalDataMap: { [key: string]: string } | undefined;

    @observable
    private _isHighlighted: boolean;

    public constructor(parcel: Parcel) {
        this._id = parcel.id;
        this._operationCode = parcel.operationCode;
        this._batchId = parcel.batchId;
        this._deliveryOrderId = parcel.deliveryOrderId;
        this._orderId = parcel.orderId;
        this._zipCode = parcel.zipCode;
        this._weight = parcel.weight;
        this._articlesCount = parcel.details.length;
        this._productsCount = sumBy(parcel.details, "quantity");
        this._parcelTracker = parcel.parcelTracker;
    }

    public get id(): number {
        return this._id;
    }

    public get parcelId(): number {
        return this._id;
    }

    public get operationCode(): string {
        return this._operationCode;
    }

    public get batchId(): number {
        return this._batchId;
    }

    public get deliveryOrderId(): number {
        return this._deliveryOrderId;
    }

    public get parcelTracker(): string {
        return this._parcelTracker;
    }

    public get orderId(): number {
        return this._orderId;
    }

    public get zipCode(): string {
        return this._zipCode;
    }

    public get articlesCount() {
        return this._articlesCount;
    }

    public get productsCount() {
        return this._productsCount;
    }

    @domain(weight)
    public get weight(): number {
        return this._weight;
    }

    get isHighlighted(): boolean {
        return this._isHighlighted;
    }

    public isMatchingLocator(locator: string) {
        return this._id.toString() === locator || this._parcelTracker === locator;
    }

    @action
    public highlight() {
        this._isHighlighted = true;
    }

    @action
    public disableHighlight() {
        this._isHighlighted = false;
    }
}

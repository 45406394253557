import { IDomain, HtmlType } from "../common/dto/idomain";
import { formatNumber } from "../common/field/renderer";
import { InputComponentType } from "../common/component/input/input";

class Quantity implements IDomain<number> {

    public htmlType: HtmlType = "text";
    public inputComponent: InputComponentType = "textField";

    public format(valueToFormat?: number) {
        if (valueToFormat !== undefined) {
            return valueToFormat.toString();
        }
        return "";
    }

    public unformat(valueToUnformat: string) {
        if (valueToUnformat) {
            const escaped = formatNumber(valueToUnformat);
            if (escaped) {
                return +escaped;
            }
        }
        return undefined;
    }

    public validators = [];
}

export const quantity = new Quantity();

import { IParamActionValidator } from "../../../../common/component/action/action-select/interface-param-action-validator";
import { DeliveryOrder } from "../../../model/delivery-order";
import { ParamActionValidationResult } from "../../../../common/component/action/action-select/param-action-validation-result";
import { t } from "i18next";
import { ParcelBulkCreationMode } from "../../../../constants/constants";

class ParcelizationByDeliveryOrderDetailTypeValidator implements IParamActionValidator<DeliveryOrder> {
    private readonly _parcelBulkCreationMode: ParcelBulkCreationMode;
    private readonly _deliveryOrderFilter: (deliveryOrder: DeliveryOrder) => boolean;

    constructor(parcelBulkCreationMode: ParcelBulkCreationMode,
                deliveryOrderFilter: (deliveryOrder: DeliveryOrder) => boolean) {
        this._parcelBulkCreationMode = parcelBulkCreationMode;
        this._deliveryOrderFilter = deliveryOrderFilter;
    }

    public validate(list: DeliveryOrder[]) {
        const filteredDeliveryOrderList = list.filter(d => this._deliveryOrderFilter(d));

        if (filteredDeliveryOrderList.length === 0) {
            return ParamActionValidationResult.error(
                t(`batchPreparation.validation.noOrdersWithSelectedType.${this._parcelBulkCreationMode}`),
            );
        }

        return filteredDeliveryOrderList.length === list.length ?
            ParamActionValidationResult.success(filteredDeliveryOrderList) :
            ParamActionValidationResult.warning(t("batchPreparation.validation.ordersWithMixedType", {
                action: t(`batchPreparation.action.create.strategy.${this._parcelBulkCreationMode}`),
                nbElements: list.length,
                nbValidElements: filteredDeliveryOrderList.length,
            }), filteredDeliveryOrderList);
    }
}

export const monoRefParcelCreationValidator =
    new ParcelizationByDeliveryOrderDetailTypeValidator("OneParcelPerOrderWithMonoRef",
            deliveryOrder => deliveryOrder.hasMonoRef);
export const multiRefParcelCreationValidator =
    new ParcelizationByDeliveryOrderDetailTypeValidator("OneParcelPerOrderWithMultiRef",
            deliveryOrder => deliveryOrder.hasMultiRef);
export const componentParcelCreationValidator =
    new ParcelizationByDeliveryOrderDetailTypeValidator("OneParcelPerComponentProduct",
            deliveryOrder => deliveryOrder.isContainingProductSet);

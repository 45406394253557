import { Button, CardContent, CircularProgress, FormControl, FormHelperText, FormLabel, InputAdornment, TextField, Typography } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { usersService } from "../../../back-office/services/users";
import { t } from "i18next";

interface ITokenValidationFormProps {
    token: string;
    onComplete: (userName: string) => void;
    onCancel: () => void;
}

export const TokenValidationForm = (props: ITokenValidationFormProps) => {
    const [userName, setUserName] = useState("");

    const [isInProgress, setIsInProgress] = useState(false);
    const [isValidationError, setIsValidationError] = useState<boolean>(false);

    useEffect(() => setIsInProgress(false), [isValidationError]);

    const handleTokenVerification = async (event: React.ChangeEvent<{}>) => {
        event.preventDefault();

        try {
            setIsInProgress(true);
            setIsValidationError(false);
            await usersService.verifyResetPasswordToken(userName, props.token);

            props.onComplete(userName);
        } catch (e) {
            setIsValidationError(true);
        }
    };

    return <form onSubmit={async event => await handleTokenVerification(event)}>
        <CardContent>
            <Typography className={"password-reset__description"}>
                {t("components.passwordChange.description")}
            </Typography>
            <FormControl className={`password-reset__form-element-container${isValidationError ? "--with-error" : ""}`}
                         variant={"outlined"}
                         fullWidth>
                <FormLabel className={"password-reset__form-element"}>
                    <Typography className={"password-reset__form-label"}>
                        {t("model.login.login")}
                    </Typography>
                    <TextField value={userName}
                               className={"password-reset__form-input"}
                               InputProps={{
                                   endAdornment: (
                                       isInProgress && <InputAdornment position="end">
                                           <CircularProgress color={"primary"} />
                                       </InputAdornment>
                                   )}}
                               variant={"outlined"}
                               onChange={event => setUserName(event.target.value ?? "")}
                    />
                </FormLabel>
                {isValidationError && <FormHelperText className={"password-reset__form-error"} error>
                    <Cancel />
                    <Typography component={"span"} className={"password-reset__form-error-text"}>
                        {t("components.passwordChange.error.tokenInvalid")}
                    </Typography>
                </FormHelperText>}
            </FormControl>
            <div className={"password-reset__button-container"}>
                <Button variant={"contained"}
                        onClick={event => handleTokenVerification(event)}
                        color={"secondary"}
                        key={"submit-button"}>
                    {t("components.passwordChange.submit")}
                </Button>
                <Button variant="contained"
                        color={"inherit"}
                        onClick={() => props.onCancel()}
                        key="cancel-btn">
                    {t("components.passwordChange.cancel")}
                </Button>
            </div>
        </CardContent>
    </form>;
};

import { action, computed, observable } from "mobx";

export class CancellationRequestMenuModel {

    private readonly _operationCode: string;
    private readonly _batchId: number;

    @observable
    private _anchorElement?: HTMLElement | undefined;

    @observable
    private _deliveryOrderId: number | undefined;

    constructor(operationCode: string, batchId: number) {
        this._operationCode = operationCode;
        this._batchId = batchId;
    }

    public get operationCode(): string {
        return this._operationCode;
    }

    public get batchId(): number {
        return this._batchId;
    }

    @action
    public openMenuForElementAndDeliveryOrder(deliveryOrderId: number, element: HTMLElement) {
        this._deliveryOrderId = deliveryOrderId;
        this._anchorElement = element;
    }

    public get anchorElement(): HTMLElement | undefined {
        return this._anchorElement;
    }

    @computed
    public get isOpened() {
        return this._anchorElement !== undefined;
    }

    @computed
    public get deliveryOrderId(): number | undefined {
        return this._deliveryOrderId;
    }

    @action
    public close() {
        this._deliveryOrderId = undefined;
        this._anchorElement = undefined;
    }
}

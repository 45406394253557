export interface IProductName {
    productName: string;
}

export class ProductName {
    constructor(productName?: IProductName) {
        if (productName) {
            this._productName = productName.productName;
        }
    }

    private readonly _productName: string;

    public get productName(): string {
        return this._productName;
    }
}

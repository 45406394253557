import React, { ReactNode, useState } from "react";
import { IconButton, Menu, MenuItemProps, Tooltip } from "@mui/material";
import { Publish } from "@mui/icons-material";
import { t } from "i18next";

interface IExportMenuProps {
    children?: ReactNode;
}

export const ExportMenu = (props: IExportMenuProps) => {
    const [exportMenuAnchor, setExportMenuAnchor] = useState<HTMLElement | null>(null);
    const exportMenuItemsCount = React.Children.toArray(props.children)
        .filter(c => React.isValidElement<MenuItemProps>(c))
        .length;

    const handleMenuOpen = (anchor: HTMLElement) => {
        setExportMenuAnchor(anchor);
    };

    const handleExport = (event: React.MouseEvent<any>) => {
        React.Children.forEach(props.children, c => {
            if (React.isValidElement<MenuItemProps>(c) && c.props.onClick) {
                c.props.onClick(event);
                return;
            }
        });
    };

    const handleMenuItemClick = (event: React.MouseEvent<any>, menuItemProps: MenuItemProps) => {
        if (menuItemProps.onClick) {
            menuItemProps.onClick(event);
        }

        setExportMenuAnchor(null);
    };

    if (exportMenuItemsCount === 0) {
        return <></>;
    }

    return <div className="export-action">
        <IconButton onClick={event => exportMenuItemsCount === 1 ? handleExport(event) : handleMenuOpen(event.currentTarget)}
                    data-testid={"export-action-button"}>
            <Tooltip title={t("components.exportList.exportTooltip")}
                     placement="top-start"
                     data-testid="exportListButton">
                <Publish />
            </Tooltip>
        </IconButton>
        <Menu
            anchorEl={exportMenuAnchor}
            open={Boolean(exportMenuAnchor)}
            onClose={() => setExportMenuAnchor(null)}
            data-testid="exportListMenu"
        >
            {React.Children.map(props.children, c => {
                if (React.isValidElement<MenuItemProps>(c) && c.props.onClick) {
                    return React.cloneElement<MenuItemProps>(c, {
                        ...c.props,
                        onClick: event => handleMenuItemClick(event, c.props),
                    });
                }

                return undefined;
            })}
        </Menu>
    </div>;
};

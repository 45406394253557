import { includes } from "lodash";
import { localDateTime } from "../../domain/local-date";
import { domain } from "../../common/dto/dto-annotation";
import { label50, label100 } from "../../domain/label";
import { OperationModeType, OperationStatusType } from "../constants/constants";
import { t } from "i18next";
import moment from "moment";

export interface IOperation {
    code: string;
    beginDate: string;
    endDate: string;
    status: OperationStatusType;
    modes: OperationModeType[];
    warehouseId: string;
}

export class Operation {
    private readonly _code: string;
    private readonly _beginDate: moment.Moment;
    private readonly _endDate: moment.Moment;
    private readonly _status: OperationStatusType;
    private readonly _modes: OperationModeType[];
    private readonly _warehouseId: string;

    constructor(operation: IOperation) {
        this._code = operation.code;
        this._beginDate = moment(operation.beginDate);
        this._endDate = moment(operation.endDate);
        this._status = operation.status;
        this._modes = operation.modes;
        this._warehouseId = operation.warehouseId;
    }

    @domain(label50)
    public get code() {
        return this._code;
    }

    @domain(localDateTime)
    public get beginDate() {
        return this._beginDate;
    }

    @domain(localDateTime)
    public get endDate() {
        return this._endDate;
    }

    @domain(label50)
    public get status() {
        return this._status;
    }

    public get modes() {
        return this._modes;
    }

    public get warehouseId() {
        return this._warehouseId;
    }

    @domain(label100)
    public get statusLabel() {
        return `${t(`model.operationStatus.${this.status}`)}`;
    }

    public get isBlueBrandMode(): boolean {
        return includes(this.modes, "BlueBrand");
    }

    public get isPalletMode(): boolean {
        return includes(this.modes, "PalletMode");
    }
}

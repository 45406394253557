import { action, computed, observable } from "mobx";
import { Operation } from "../front-office/model/operation";
import { Batch } from "../front-office/model/batch";

class OperationContext {
    @observable
    private _operation: Operation | undefined;
    @observable
    private _batch: Batch | undefined;

    @action
    public setOperation(operation: Operation) {
        this._operation = operation;
    }

    @action
    public setBatchId(batch: Batch) {
        this._batch = batch;
    }

    @action
    public removeOperation() {
        this._operation = undefined;
    }

    @action
    public removeBatch() {
        this._batch = undefined;
    }

    public get operation(): Operation | undefined {
        return this._operation;
    }

    @computed
    public get operationCodeOrEmpty(): string {
        return this._operation?.code ?? "";
    }

    public get batch(): Batch | undefined {
        return this._batch;
    }

    public get isBlueBrandOperation(): boolean {
        return this._operation !== undefined && this._operation.isBlueBrandMode;
    }

    public get isPalletOperationOrEmpty(): boolean {
        return this._operation === undefined || this._operation.isPalletMode;
    }
}

export const operationContext = new OperationContext();

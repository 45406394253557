import * as React from "react";

import { TableCell, TableRow } from "@mui/material";

import { ListSort } from "../../../../common/component/list/search-list/list-sort";
import { Parcel } from "../../../model/parcel";
import { ParcelDetail } from "../../../model/parcel-detail";
import { ParcelFilter } from "../../../model/parcel-filter";
import { observer } from "mobx-react";

@observer
export class ParcelListSubSort extends ListSort<Parcel, ParcelDetail, ParcelFilter, {}> {

    public render() {
        return <TableRow className="search-item__sub-header">
            <TableCell />
            {this.renderColumnSort("model.parcelDetail.productId", "details.productId")}
            {this.renderColumnSort("model.parcelDetail.ean13List", "details.ean13List")}
            {this.renderColumnSort("model.parcelDetail.supplierReference", "details.supplierReference")}
            {this.renderColumnSort("model.parcelDetail.labelReference", "details.labelReference", 2)}
            {this.renderColumnSort("model.parcelDetail.quantity", "details.quantity")}
            {this.renderColumnSort("model.parcelDetail.weight", "details.weight")}
            {this.renderAdditionalColumnSubSort()}
        </TableRow>;
    }
}

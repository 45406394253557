import React from "react";
import {
    Table, TableHead, TableRow, TableCell, TableBody,
    Checkbox,
} from "@mui/material";
import { t } from "i18next";
import { observer } from "mobx-react";
import { IImportModuleProps } from "./iimport-module-props";
import { Input } from "../../../../common/component/input/input";

@observer
export class SelectColumnFileImport extends React.Component<IImportModuleProps> {
    private renderTableSelectColumn() {
        return <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("components.importList.columns")}</TableCell>
                        <TableCell>{t("components.importList.referenceColumn")}*</TableCell>
                        <TableCell>{t("components.importList.columnsToImport")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.from(this.props.importFileModel.fileImportData[0].keys()).map(data => {
                        return (
                            <TableRow key={data}>
                                <TableCell>{data}</TableCell>
                                <TableCell>
                                    <Input errors={this.props.importFileModel.refPropertyError}
                                           onChange={(newValue: any) => this.props.importFileModel.changeRefProperty(data, newValue)}
                                           propertyKey={ "selectRefProperty" + data }
                                           value={this.props.importFileModel.refPropertyColumnValue(data)}
                                           options={this.props.importFileModel.referencePropertyArray(data)}
                                           inputComponent="select"
                                           disabled={
                                               this.props.importFileModel.referencePropertyArray(data).length === 0 ||
                                               this.props.importFileModel.checkboxIsChecked(data)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Checkbox disabled={this.props.importFileModel.isColumnMappedToAnyReference(data)}
                                        onClick={() => this.props.importFileModel.handleCheckBoxClick(data)}
                                        checked={this.props.importFileModel.checkboxIsChecked(data)}
                                        value={data}
                                        data-testid={`col-to-import-${data}`}
                                        color="primary" />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>;
    }

    public render() {
        return <div>
            {this.renderTableSelectColumn()}
        </div>;
    }
}

import { httpGetList } from "../../common/network/fetch";
import { BusinessDocumentation, IBusinessDocumentation } from "../model/business-documentation";

class BusinessDocumentationService {
    public async list(): Promise<BusinessDocumentation[]> {
        return (await httpGetList<IBusinessDocumentation>({
            url: "/business-documentation/list",
        })).map(bd => new BusinessDocumentation(bd));
    }
}

export const businessDocumentationService = new BusinessDocumentationService();

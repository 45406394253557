import { CustomParcelizationCommand } from "./custom-parcelization-command";
import { DialogMessageIcon, dialogModel } from "../../../../common/component/dialog/dialog-model";
import { t } from "i18next";
import { DeliveryOrder } from "../../../model/delivery-order";
import { ParcelBulkCreationMode } from "../../../../constants/constants";
import { parcelsService } from "../../../services/parcels";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { ParcelCreationResponse } from "../../../model/parcel-creation-response";
import { ParcelCreationErrorItem } from "../../../model/parcel-creation-error-item";

export class CustomParcelizationCommandHandler {
    public async handle(command: CustomParcelizationCommand, onComplete: () => Promise<void>) {
        if (!command.deliveryOrderList || command.deliveryOrderList.length === 0) {
            throw new Error("No data selected, filter should be done previously.");
        }

        return command.deliveryOrderListValidator ?
            await this.validateCommandAndExecute(command, onComplete) :
            await this.execute(command.operationCode, command.batchId, command.deliveryOrderList,
                command.preparationMode, onComplete);
    }

    private async validateCommandAndExecute(command: CustomParcelizationCommand, onComplete: () => Promise<void>) {
        const deliveryOrderSelectionValidationResult = command.deliveryOrderListValidator!.validate(command.deliveryOrderList);
        if (deliveryOrderSelectionValidationResult.isError) {
            dialogModel.alert(t(`batchPreparation.action.create.strategy.${command.preparationMode}`),
                deliveryOrderSelectionValidationResult.violationMessage, DialogMessageIcon.Error);
            return;
        }

        dialogModel.confirmBox(
            t(`batchPreparation.action.create.strategy.${command.preparationMode}`),
            deliveryOrderSelectionValidationResult.isSuccess ?
                t("components.selectAction.confirm", {
                    action: t(`batchPreparation.action.create.strategy.${command.preparationMode}`),
                    nbElement: command.deliveryOrderList.length,
                }) :
                deliveryOrderSelectionValidationResult.violationMessage,
            async () => await this.execute(command.operationCode, command.batchId,
                deliveryOrderSelectionValidationResult.validItems, command.preparationMode, onComplete));
    }

    private async execute(operationCode: string, batchId: number, deliveryOrderList: DeliveryOrder[],
                          preparationMode: ParcelBulkCreationMode, onComplete: () => Promise<void>) {
        const parcelCreationResponse = await parcelsService.createParcelsBulk(operationCode, batchId,
                deliveryOrderList.map(d => d.id), preparationMode);

        if (parcelCreationResponse.parcelIds.length) {
            this.renderSuccessMessageFromCreationResponse(parcelCreationResponse);
        }

        if (parcelCreationResponse.hasErrors) {
            this.renderErrorMessages(parcelCreationResponse.errors);
        }

        await onComplete();
        return;
    }

    private renderSuccessMessageFromCreationResponse(creationResponse: ParcelCreationResponse) {
        notificationModel.addSuccessMessage(
            t("batchPreparation.action.create.success" + (creationResponse.createdMultiple ? "s" : ""),
                {
                    count: creationResponse.parcelIds.length,
                }));
    }

    private renderErrorMessages(errors: ParcelCreationErrorItem[]) {
        for (const error of errors.slice(0, 5).map(e => e.errors).flatMap(e => e)) {
            notificationModel.addErrorMessage(error);
        }

        if (errors.length > 5) {
            notificationModel.addErrorMessage(t("batchPreparation.action.create.errorForDeliveryOrders", {
                count: errors.slice(5).length,
            }));
        }
    }
}

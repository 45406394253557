import { DeliveryOrderList } from "../delivery-order-list/delivery-order-list";
import { inject, observer } from "mobx-react";

import { DeliveryOrder } from "../../../model/delivery-order";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { DeliveryOrderFilter } from "../../../model/delivery-order-filter";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { OrderDataExporter } from "../delivery-order-list/order-data-exporter";
import { DefaultOrderDataProvider } from "../delivery-order-list/default-order-data-provider";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { PrepareBatchActionType } from "../../../constants/constants";
import {IWithRouterProps, withRouter} from "../../../../common/component/hoc/withRouter";

const deliveryOrderCancelledModel = new SearchListModel<DeliveryOrder, DeliveryOrderDetail, DeliveryOrderFilter>();

interface IDeliveryOrderCancelledProps {
    singleActionPickerAnchorElement: HTMLElement | null;
    singleActionPickerDeliveryOrderItem: DeliveryOrder | null;
}

@inject("routing")
@observer
class DeliveryOrderCancelledComponent extends DeliveryOrderList<IDeliveryOrderCancelledProps> {
    public state: IDeliveryOrderCancelledProps = {
        singleActionPickerAnchorElement: null,
        singleActionPickerDeliveryOrderItem: null,
    };

    constructor(props: IWithRouterProps & IHistoryProps) {
        super(
            props,
            deliveryOrderCancelledModel,
            new OrderDataExporter("Canceled delivery order"),
            new DefaultOrderDataProvider("Cancelled"),
        );
    }

    protected get actionsList(): Array<IActionCommand<DeliveryOrder, PrepareBatchActionType>> {
        return [];
    }

    protected openSingleActionPickerMenu(anchor: any, item: DeliveryOrder) {
        this.setState({
            singleActionPickerAnchorElement: anchor,
            singleActionPickerDeliveryOrderItem: item,
        });
    }

    protected get allowOrderPendingCancellationFiltering() {
        return false;
    }
}

export const DeliveryOrderCancelled = withRouter(DeliveryOrderCancelledComponent);

import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { sumBy } from "lodash";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";
import { blue } from "@mui/material/colors";
import { t } from "i18next";
import moment from "moment";

import "./style.scss";
import { PalletsListItem } from "../pallets-list/pallets-list-item";

interface IPalletShippingDetailsProps {
    shippingPalletsList: PalletsListItem[];
    isTruckIdentifierRequired: boolean;

    onCancel: () => void;
    onSubmit: (palletList: PalletsListItem[], truckId: string, pickingDate: moment.Moment, sealNumber: string) => void;
}

interface IPalletShippingDetailsTitleProps {
    shippingPalletsList: PalletsListItem[];
}

export const PalletShippingDetails = (props: IPalletShippingDetailsProps) => {
    const [truckId, setTruckId] = useState("");
    const [sealNumber, setSealNumber] = useState("");

    const isFormValid = (!props.isTruckIdentifierRequired || truckId !== "");

    const handleFormSubmission = () => {
        props.onSubmit(props.shippingPalletsList, truckId, moment(), sealNumber);
    };

    return <>
        <PalletShippingDetailsTitle shippingPalletsList={props.shippingPalletsList} />
        <DialogContent>
            <div className={"pallet-shipping-summary"}>
                <Typography>
                    {t("components.pallet.shipping.description", {
                        count: props.shippingPalletsList.length,
                    })}
                </Typography>
                <div className={"pallet-shipping-summary__list"}>
                    {props.shippingPalletsList.map(c => <SquaredChip propertyKey={`item-${c.code}`} color={blue} label={c.code} />)}
                </div>
                <Typography className={"pallet-shipping-details__summary"}>
                    {t("components.pallet.shipping.descriptionParcelCount", {
                        count: sumBy(props.shippingPalletsList, "parcelCount"),
                    })}
                </Typography>
            </div>
        </DialogContent>
        <DialogContent>
            <div className={"pallet-shipping-form"}>
                <Typography className={"pallet-shipping-form__headline"}>
                    {t("components.pallet.shipping.informationTitle")}
                </Typography>
                <Typography>
                    {t("components.pallet.shipping.informationDetails")}
                </Typography>
                <div className={"pallet-shipping-form__container"}>
                    <FormControl variant={"outlined"}
                                 fullWidth>
                        <FormLabel htmlFor={"ship-picking-date"}>
                            {t("components.pallet.shipping.pickingDate")}
                        </FormLabel>
                        <TextField id="ship-picking-date"
                                   variant={"outlined"}
                                   data-testid={"ship-picking-date"}
                                   value={moment().format("Y-MM-DD")}
                                   disabled={true}/>
                    </FormControl>
                    <FormControl variant={"outlined"}
                                 fullWidth>
                        <FormLabel htmlFor={"ship-truck-id"}>
                            {t("components.pallet.shipping.truckId")}
                        </FormLabel>
                        <TextField id={"ship-truck-id"}
                                   variant={"outlined"}
                                   data-testid={"ship-truck-id"}
                                   onChange={event => setTruckId(event.target.value)}
                                   value={truckId} />
                    </FormControl>
                    <FormControl variant={"outlined"}
                                 fullWidth>
                        <FormLabel htmlFor={"ship-truck-id"}>
                            {t("components.pallet.shipping.containerSealNumber")}
                        </FormLabel>
                        <TextField id={"ship-truck-id"}
                                   variant={"outlined"}
                                   data-testid={"ship-truck-id"}
                                   onChange={event => setSealNumber(event.target.value)}
                                   value={sealNumber} />
                    </FormControl>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <Button color={"inherit"}
                    variant="contained"
                    type="submit"
                    data-testid={"pallet-shipping-cancel"}
                    onClick={async () => props.onCancel()}>
                {t("components.popin.cancel")}
            </Button>
            <Button color="secondary"
                    variant="contained"
                    type="submit"
                    data-testid={"pallet-shipping-submit"}
                    key="submit-btn"
                    disabled={!isFormValid}
                    onClick={() => handleFormSubmission()}>
                {t("components.pallet.shipping.ship")}
            </Button>
        </DialogActions>
    </>;
};

const PalletShippingDetailsTitle = (props: IPalletShippingDetailsTitleProps) => {
    return <DialogTitle>
        {props.shippingPalletsList.length > 1 ?
            t("components.pallet.shipping.multiplePalletsTitle", {
                count: props.shippingPalletsList.length,
            }) :
            t("components.pallet.shipping.singlePalletTitle", {
                palletCode: props.shippingPalletsList[0].code,
            })}
    </DialogTitle>;
};

import { observable, computed, action } from "mobx";
import { Batch } from "../../../model/batch";
import { Operation } from "../../../model/operation";

export class OperationBatchSelectorModel {
    @observable private _operationList: Operation[] = [];
    @observable private _batchList: Batch[] = [];

    @computed public get operationList() {
        return this._operationList as ReadonlyArray<Operation>;
    }
    @computed public get operationListOrdered() {
        return this._operationList.sort((a: Operation, b: Operation) => {
            return a.code > b.code ? 1 : -1;
        }) as ReadonlyArray<Operation>;
    }
    @computed public get batchList() {
        return this._batchList as ReadonlyArray<Batch>;
    }

    @action
    public resetOperationList(operationList: Operation[]) {
        this._operationList = operationList;
    }

    @action
    public resetBatchList(batchList: Batch[]) {
        this._batchList = batchList;
    }
}

import { orderBy, sortBy, sumBy } from "lodash";
import { domain, label } from "../../common/dto/dto-annotation";
import { label15, label255, label50 } from "../../domain/label";

import { IParcelDetail, ParcelDetail } from "./parcel-detail";
import { DetailContentType, ParcelStatusType } from "../constants/constants";
import { localDate } from "../../domain/local-date";
import { identifier } from "../../domain/identifier";
import { weight } from "../../domain/weight";
import { action, computed } from "mobx";
import { DeliveryOrderCancellationRequestStatus } from "./delivery-order-cancellation-request-status";
import { DeliveryOrder, IDeliveryOrder } from "./delivery-order";
import { ParcelTrackingData, ParcelTrackingDataStatus } from "./parcel-tracking-data";
import { Guid } from "guid-typescript";
import { DangerousGoods } from "../../constants/constants";
import { lithiumUnCodeList } from "../../constants/constants";

export interface IParcel {
    /** Parcel identifier */
    id?: number;

    /** Parcel batch identifier */
    batchId: number;

    /** Parcel operation code */
    operationCode: string;

    /** Order identifier */
    orderId: number;

    /** Delivery order identifier */
    deliveryOrderId: number;

    /** Parcel tracker */
    parcelTracker?: string;

    /** Carrier name */
    carrier?: string;

    /** Parcel status */
    status: ParcelStatusType;

    /** Parcel weight */
    weight: number;

    /** Parcel shipment date */
    shipmentDate?: string;

    /** Parcel details */
    details: IParcelDetail[];

    /** Delivery zip code */
    zipCode: string;

    /** Delivery city */
    city: string;

    /** Delivery country */
    country: string;

    /** Mono-ref flag */
    isMonoRef: boolean;

    /** Single-ref flag */
    isSingleRef: boolean;

    /** Delivery order cancellation request */
    orderCancellationRequestStatus?: DeliveryOrderCancellationRequestStatus;

    /** Pick and pack area number */
    area?: number;

    /** Pallet identifiers (if parcel is on a pallet) */
    palletId?: Guid;
    palletCode?: string;
    suggestedPalletCarrierGroup?: string;
}

export class Parcel {

    /**
     * Default constructor.
     * @param parcel Parcel object (with or without proptotype).
     * @param deliveryOrder
     */
    constructor(parcel: IParcel, deliveryOrder?: IDeliveryOrder) {
        if (parcel.id) {
            this._id = parcel.id;
        }
        this._batchId = parcel.batchId;
        this._operationCode = parcel.operationCode;
        this._orderId = parcel.orderId ?? deliveryOrder?.orderId;
        this._deliveryOrderId = parcel.deliveryOrderId;
        this._parcelTrackingData = ParcelTrackingData.buildFromParcel(parcel, deliveryOrder);
        this._status = parcel.status;
        this._weight = parcel.weight;
        this._shipmentDate = parcel.shipmentDate ?? "";
        this._details = sortBy(parcel.details, "supplierReference").map(detail => new ParcelDetail(detail));
        this._zipCode = parcel.zipCode ?? deliveryOrder?.shippingAddress.zipCode;
        this._city = parcel.city ?? deliveryOrder?.shippingAddress.city;
        this._country = parcel.country ??  deliveryOrder?.shippingAddress.country;
        this._contentsSortingKey = this.details.map(detail => detail.supplierReference).join("-");
        this._detailSortingKey = (parcel.isMonoRef ? 0 : 1) + (parcel.isSingleRef ? 0 : 1) + `0000${parcel.details.length}_`.slice(-5)
            + this.contentsSortingKey
            + `-0000${sumBy(parcel.details, p => p.quantity)}_`.slice(-5);
        this._isMonoRef = parcel.isMonoRef;
        this._isSingleRef = parcel.isSingleRef;
        this._orderCancellationRequestStatus = parcel.orderCancellationRequestStatus;
        this._area = parcel.area;
        this._palletId = parcel.palletId ?? undefined;
        this._palletCode = parcel.palletCode ?? undefined;
        this._suggestedPalletCarrierGroup = parcel.suggestedPalletCarrierGroup ?? undefined;
        this._dangerousGoods = this.aggregateDangerouseGoodsFromDetails(parcel.details);
    }

    /** Parcel identifier */
    private _id: number;

    /** Parcel batch identifier */
    private readonly _batchId: number;

    /** Parcel operation code */
    private readonly _operationCode: string;

    /** Order identifier */
    private readonly _orderId: number;

    /** Delivery order identifier */
    private readonly _deliveryOrderId: number;

    /** Parcel shipping data. */
    private readonly _parcelTrackingData: ParcelTrackingData;

    /** Parcel status */
    private _status: ParcelStatusType;

    /** Parcel weight */
    private readonly _weight: number;

    /** Parcel shipment date. */
    private readonly _shipmentDate: string;

    /** Parcel details */
    private _details: ParcelDetail[];

    /** Contents sorting key */
    private _detailSortingKey: string;

    /** Parcel content type with contents sorting key */
    private _contentsSortingKey: string;

    /** Zip code */
    private readonly _zipCode: string;

    /** City code */
    private readonly _city: string;

    /** Country ISO code */
    private readonly _country: string;

    /** Mono ref parcel flag */
    private readonly _isMonoRef: boolean;

    /** Single ref parcel flag */
    private readonly _isSingleRef: boolean;

    /** Delivery order cancellation request status */
    private readonly _orderCancellationRequestStatus: DeliveryOrderCancellationRequestStatus | undefined;

    /** Pick & Pack area code */
    private readonly _area: number | undefined;

    /** Pallet identifiers (if parcel is on a pallet) */
    private _palletId?: Guid;
    private _palletCode?: string;
    private readonly _suggestedPalletCarrierGroup?: string;

    /** Included dangerous goods type */
    private readonly _dangerousGoods: DangerousGoods;

    @label("model.parcel.parcelId")
    @domain(identifier)
    public get id(): number {
        return this._id;
    }

    @label("model.parcel.parcelId")
    @domain(identifier)
    public get parcelId(): number {
        return this._id;
    }

    @domain(identifier)
    public get batchId(): number {
        return this._batchId;
    }

    @label("model.parcel.operationCode")
    @domain(label50)
    public get operationCode(): string {
        return this._operationCode;
    }
    @label("model.parcel.orderId")
    @domain(identifier)
    public get orderId(): number {
        return this._orderId;
    }

    @label("model.parcel.deliveryOrderId")
    @domain(identifier)
    public get deliveryOrderId(): number {
        return this._deliveryOrderId;
    }

    public get parcelTrackingData(): ParcelTrackingData {
        return this._parcelTrackingData;
    }

    @label("model.parcel.parcelTracker")
    @domain(label50)
    public get parcelTracker(): string {
        return this._parcelTrackingData.tracker;
    }

    @label("model.parcel.carrier")
    @domain(label50)
    public get carrier(): string {
        return this._parcelTrackingData.carrier;
    }

    @label("model.parcel.status")
    @domain(label50)
    public get status(): ParcelStatusType {
        return this._status;
    }

    @label("model.parcel.weight")
    @domain(weight)
    public get weight(): number {
        return this._weight;
    }

    @label("model.parcel.shipmentDate")
    @domain(localDate)
    public get shipmentDate(): string {
        return this._shipmentDate;
    }

    public get details(): ParcelDetail[] {
        return this._details;
    }

    @action
    public addParcelDetail(parcelDetail: ParcelDetail) {
        this._details.push(parcelDetail);
        this.recalculateDetailKeys();
    }

    @action
    public removeParcelDetail(parcelDetail: ParcelDetail) {
        this._details = this._details.filter(d => d.productId !== parcelDetail.productId);
        this.recalculateDetailKeys();
    }

    @action
    public recalculateDetailKeys() {
        this._contentsSortingKey = this.details.map(detail => detail.supplierReference).join("-");
        this._detailSortingKey = (this.isMonoRef ? 0 : 1) + (this.isSingleRef ? 0 : 1) + `0000${this.details.length}_`.slice(-5)
            + this.contentsSortingKey
            + `-0000${sumBy(this.details, p => p.quantity)}_`.slice(-5);
    }

    public get detailProductIds(): number[] {
        return this._details.map(d => d.productId);
    }

    @domain(label15)
    public get zipCode(): string {
        return this._zipCode;
    }

    @domain(label50)
    public get city(): string {
        return this._city;
    }

    @domain(label255)
    public get country(): string {
        return this._country;
    }

    public get isMonoRef(): boolean {
        return this._isMonoRef;
    }

    public get isSingleRef(): boolean {
        return this._isSingleRef;
    }

    @domain(identifier)
    public get area(): number | undefined {
        return this._area;
    }

    @label("model.parcel.detailSortingKey")
    public get detailSortingKey(): string {
        return this._detailSortingKey;
    }

    @label("model.deliveryOrder.contentsSortingKey")
    public get contentsSortingKey(): string {
        return this._contentsSortingKey;
    }

    public get palletId(): Guid | undefined {
        return this._palletId;
    }

    @label("model.parcel.palletCode")
    @domain(label255)
    public get palletCode(): string | undefined {
        return this._palletCode;
    }

    public get isOnPallet() {
        return (this._palletCode ?? "") !== "";
    }

    public canBePalletizedWith(parcels: Parcel[]) {
        return parcels.every(parcel => parcel.suggestedPalletCarrierGroup === this._suggestedPalletCarrierGroup);
    }

    @domain(label255)
    public get suggestedPalletCarrierGroup(): string | undefined {
        return this._suggestedPalletCarrierGroup;
    }

    public get orderCancellationRequestStatus(): DeliveryOrderCancellationRequestStatus | undefined {
        return this._orderCancellationRequestStatus;
    }

    @computed
    public get isOrderPendingCancellationOrCancelled() {
        return this.orderCancellationRequestStatus === DeliveryOrderCancellationRequestStatus.Pending ||
            this.orderCancellationRequestStatus === DeliveryOrderCancellationRequestStatus.Confirmed;
    }

    @computed
    public get isOrderPendingCancellation() {
        return this.orderCancellationRequestStatus === DeliveryOrderCancellationRequestStatus.Pending;
    }

    @computed
    public get orderNonRejectedCancellationRequestStatus() {
        return this.orderCancellationRequestStatus !== DeliveryOrderCancellationRequestStatus.Rejected ?
            this.orderCancellationRequestStatus : null;
    }

    @computed
    public get isMultiRef() {
        return !this.isMonoRef;
    }

    @computed
    public get type(): DetailContentType {
        if (!this.isMultiRef) {
            return this.isSingleRef ? "SingleReference" : "MonoReference";
        }

        return "MultiReference";
    }

    @label("model.parcel.detailsQuantity")
    public get detailsQuantity() {
        return sumBy(this.details, "quantity");
    }

    @label("model.parcel.address")
    @domain(label255)
    public get address() {
        return this.zipCode + " " + this.city + " " + this.country;
    }

    public get dangerousGoods() {
        return this._dangerousGoods;
    }

    public sortDetails() {
        this._details = sortBy(this.details, "supplierReference");
    }

    @action
    public sortDetailsBy(subProperty: keyof ParcelDetail, sortOrder: "asc" | "desc") {
        this._details = orderBy(this.details, subProperty, sortOrder);
    }

    public cancel() {
        this._status = "Cancelled";
    }

    public setParcelId(parcelId: number) {
        this._id = parcelId;
    }

    public get hasParcelTrackingDataAltered(): boolean {
        return this._parcelTrackingData.status === ParcelTrackingDataStatus.Altered;
    }

    public updateParcelTrackingData(carrier: string, tracker: string, deliveryOrder?: DeliveryOrder) {
        if (deliveryOrder) {
            const originalParcelTracker = tracker;
            let i = 0;

            while (!deliveryOrder.parcels.every(p => p.parcelTracker !== tracker)) {
                tracker = `${originalParcelTracker}_SRM${++i}`;
            }
        }

        this._parcelTrackingData.updateParcelTrackingData(carrier, tracker);
    }

    public matchesParcelLocator(locator: string) {
        return this.parcelId.toString() === locator || (this.parcelTracker !== "" && this.parcelTracker === locator);
    }

    public get parcelLocators() {
        return [
            this._id.toString(),
            this.parcelTracker,
        ];
    }

    private aggregateDangerouseGoodsFromDetails(details: IParcelDetail[]) {
        const unCodes = details.filter(d => d.unCode !== null)
            .flatMap(d => d.unCode);

        let lithium: DangerousGoods | undefined;
        let liquidQuantity: DangerousGoods | undefined;

        if (unCodes.some(unCode => lithiumUnCodeList.includes(unCode))) {
            lithium = DangerousGoods.Lithium;
        }

        if (unCodes.filter(unCode => !lithiumUnCodeList.includes(unCode)).length !== 0) {
            liquidQuantity = DangerousGoods.LiquidQuantity;
        }

        if (lithium !== undefined && liquidQuantity !== undefined) {
            return DangerousGoods.Both;
        } else {
            return lithium ?? liquidQuantity ?? DangerousGoods.None;
        }
    }
}

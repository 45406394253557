import React, { useState } from "react";
import { Parcel } from "../../../model/parcel";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import { Dialog, IconButton, ThemeProvider } from "@mui/material";
import { Close } from "@mui/icons-material";
import { LabelAndAssignScanParcels } from "./label-and-assign-scan-parcels";
import { LabelAndAssignConfigureDocument } from "./label-and-assign-configure-document";
import { IParcelDocumentProvider } from "../printer/document-provider/parcel-document-provider-interface";
import { LabelAndAssignPalletSelector } from "./label-and-assign-pallet-selector";
import { documentPrinter, DocumentPrintoutMode } from "../printer/document-printer";
import { context } from "../../../../context/context";
import { PrinterSettingsValidator } from "../../../../common/component/printer-settings-validator/printer-settings-validator";
import { palletService } from "../../../services/pallet";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { t } from "i18next";

import "./style.scss";
import { preparationOrderProvider } from "../printer/document-provider/preparation-order-provider";

interface ILabelAndAssignDialogProps {
    operationCode: string;
    batchId: number;
    parcelList: Parcel[];

    getParcelItemByScan: (parcelId: string) => Parcel | undefined;

    onParcelLabel: () => Promise<void>;
    onCancel: () => void;
}

enum LabelAndAssignDialogStep {
    ProvideParcelList,
    ProvidePrintoutSettings,
    ProvidePalletId,
}

export const LabelAndAssignDialog = (props: ILabelAndAssignDialogProps) => {
    const [step, setStep] = useState<LabelAndAssignDialogStep>(props.parcelList.length === 0 ?
        LabelAndAssignDialogStep.ProvideParcelList : LabelAndAssignDialogStep.ProvidePrintoutSettings);

    const [parcelList, setParcelList] = useState<Parcel[]>(props.parcelList);
    const [printedParcelsList, setPrintedParcelsList] = useState<Parcel[]>([]);

    const [isPrinterConfigurationValidated, setIsPrinterConfigurationValidated] = useState(context.settings.isDownload);
    const [isPrinterValid, setPrinterValid] = useState(!context.settings.isDownload);
    const [isPrintingInProgress, setPrintingInProgress] = useState(false);

    const handleScanCompleted = (scannedParcelList: Parcel[]) => {
        setParcelList(scannedParcelList);
        setStep(LabelAndAssignDialogStep.ProvidePrintoutSettings);
    };

    const handlePrinterValidationCompleted = (isValid: boolean) => {
        setPrinterValid(isValid);
        setIsPrinterConfigurationValidated(true);
    };

    const handleDocumentPrint = async (strategy: IParcelDocumentProvider) => {
        setPrintingInProgress(true);

        try {
            const summary = await documentPrinter.printOrDownloadWithProgressDrawer(props.operationCode, props.batchId,
                parcelList, strategy, isPrinterValid ?
                    DocumentPrintoutMode.UserDefined : DocumentPrintoutMode.ForceDownload);
            setPrintedParcelsList(summary.printedParcelsList);
        } finally {
            setStep(LabelAndAssignDialogStep.ProvidePalletId);
            setPrintingInProgress(false);
            await props.onParcelLabel();
        }
    };

    const handleParcelAssignment = async (palletCode: string, printPreparationOrder: boolean) => {
        let errorCounter: number = 0;
        for (const parcel of printedParcelsList) {
            try {
                await palletService.assignParcelToPallet(palletCode, parcel.id);
            } catch {
                errorCounter++;
            }
        }

        if (printPreparationOrder) {
            try {
                setPrintingInProgress(true);
                await documentPrinter.printOrDownloadWithProgressDrawer(props.operationCode, props.batchId,
                    printedParcelsList, preparationOrderProvider, isPrinterValid ?
                        DocumentPrintoutMode.UserDefined : DocumentPrintoutMode.ForceDownload);
            } finally {
                setPrintingInProgress(false);
            }
        }

        if (errorCounter !== printedParcelsList.length) {
            notificationModel.addSuccessMessage(t("parcelManagement.action.labelAndAssign.success", {
                count: printedParcelsList.length - errorCounter,
            }));
            props.onCancel();
        }
    };

    return <ThemeProvider theme={VPUITheme}>
        <Dialog open={true}>
            <div className={"label-and-assign-dialog"}
                 data-testid={"label-and-assign-dialog"}>
                <div className="label-and-assign-dialog__close">
                    <IconButton onClick={() => props.onCancel()}>
                        <Close />
                    </IconButton>
                </div>
                {!isPrinterConfigurationValidated &&
                    <PrinterSettingsValidator confirmDownloadFallback
                                              onError={props.onCancel}
                                              onValidationComplete={isValid => handlePrinterValidationCompleted(isValid)} />}
                {!isPrintingInProgress && step === LabelAndAssignDialogStep.ProvideParcelList &&
                    <LabelAndAssignScanParcels getParcelItemByScan={parcelId => props.getParcelItemByScan(parcelId)}
                                               onCancel={() => props.onCancel()}
                                               onFinished={scannedParcelList => handleScanCompleted(scannedParcelList)} />}
                {!isPrintingInProgress && step === LabelAndAssignDialogStep.ProvidePrintoutSettings &&
                    <LabelAndAssignConfigureDocument parcelList={parcelList}
                                                     onLabelRequest={async strategy => await handleDocumentPrint(strategy)}
                                                     onCancel={() => props.onCancel()} />}
                {!isPrintingInProgress && step === LabelAndAssignDialogStep.ProvidePalletId &&
                    <LabelAndAssignPalletSelector parcelList={printedParcelsList}
                                                  onPalletSelected={async (palletCode, printPreparationOrder) =>
                                                      await handleParcelAssignment(palletCode, printPreparationOrder)}
                                                  onCancel={() => props.onCancel()} />}
            </div>
        </Dialog>
    </ThemeProvider>;
};

import React from "react";
import { DeliveryOrder } from "../../../model/delivery-order";
import { flatMap, groupBy, map } from "lodash";
import { DeliveryOrderContentsItem } from "./delivery-order-contents-item";

interface IDeliveryOrderContentsProps {
    deliveryOrder: DeliveryOrder;
    onOrderIdentifierFilterEnabled: (orderId: number) => void;
    additionalDataFields: string[];
}

export const DeliveryOrderContents = (props: IDeliveryOrderContentsProps) => {
    return <>
        {flatMap(groupBy(props.deliveryOrder.details.filter(detail => detail.componentProductId), "componentProductId"),
            groupedDetails => map(groupedDetails,
                (detail, index) =>
                    <DeliveryOrderContentsItem deliveryOrderId={props.deliveryOrder.id}
                                               key={`${props.deliveryOrder.id}-${detail.productId}-${index}`}
                                               orderId={props.deliveryOrder.orderId}
                                               detail={detail}
                                               onOrderIdentifierFilterEnabled={props.onOrderIdentifierFilterEnabled}
                                               componentProductItemsLength={groupedDetails.length}
                                               index={Number(index)}
                                               additionalDataFields={props.additionalDataFields} />))}
        {props.deliveryOrder.details.filter(d => !d.componentProductId).map((detail, index) =>
            <DeliveryOrderContentsItem deliveryOrderId={props.deliveryOrder.id}
                                       key={`${props.deliveryOrder.id}-${detail.productId}`}
                                       orderId={props.deliveryOrder.orderId}
                                       detail={detail}
                                       onOrderIdentifierFilterEnabled={props.onOrderIdentifierFilterEnabled}
                                       componentProductItemsLength={0}
                                       index={index}
                                       additionalDataFields={props.additionalDataFields} />)}
    </>;
};

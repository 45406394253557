import "./style.scss";

import * as React from "react";

import { CircularProgress } from "@mui/material";
import { observer } from "mobx-react";

interface IProgressionProps {
    size: number;
    nbCurrent: number;
    nbLimit: number;
}

@observer
export class Progression extends React.Component<IProgressionProps, {}> {
    public render() {
        return <div className="progression">
            <div className="spinner">
                <CircularProgress
                    color="primary"
                    size={this.props.size}
                    variant="determinate"
                    value={(this.props.nbCurrent / this.props.nbLimit) * 100}
                    data-testid="progression" />
            </div>
        </div>;
    }
}

import { useEffect } from "react";

export const useTimeout = (action: () => void, timeout: number, dependency: boolean) => {
    return useEffect(() => {
        if (!dependency) {
            return;
        }

        const timeoutHandle = setTimeout(() => {
            action();
        }, timeout);

        return () => clearTimeout(timeoutHandle);

    }, [dependency]);
};

import { PickAndPackCommandReturn } from "./../../../model/pick-and-pack-command-return";
import { action, computed, observable } from "mobx";
import { find, some } from "lodash";
import * as moment from "moment";

import { Parcel } from "../../../model/parcel";
import { ParcelDetail } from "../../../model/parcel-detail";
import { PickAndPackStatus } from "../../../constants/constants";
import { label } from "../../../../common/dto/dto-annotation";

class PickAndPackModel {

    @observable private _eanScanned?: string;
    @observable private _area?: number;
    @observable private _itemScanned?: Parcel;
    @observable private _detailItemScanned?: ParcelDetail;
    @observable private _status?: PickAndPackStatus;
    @observable private _errorMessage?: string;

    private _eanStreamed: string = "";
    private _eanStreamedInputMoment: moment.Moment;

    @computed public get area() {
        return this._area;
    }

    @computed public get status() {
        return this._status;
    }

    @label("model.pickAndPack.eanScanned")
    @computed public get eanScanned() {
        return this._eanScanned;
    }

    @computed public get isScanProgressing() {
        return this.status === "Processing";
    }

    @computed public get itemScanned() {
        return this._itemScanned;
    }

    @computed public get detailItemScanned() {
        return this._detailItemScanned;
    }

    @computed public get errorMessage() {
        return this._errorMessage;
    }

    private getDetailItemScanned() {
        if (this._itemScanned) {
            return find(this._itemScanned.details, (pad: ParcelDetail) => {
                return some(pad.ean13List, (ean: string) => {
                    return ean === this._eanScanned;
                }) === true;
            });
        }
        return undefined;
    }

    @action
    public scanProcessingStarted(ean: string) {
        this._eanScanned = ean;
        this._area = undefined;
        this._itemScanned = undefined;
        this._detailItemScanned = undefined;
        this._status = "Processing";
        this._errorMessage = undefined;
    }

    @action
    public scanProcessingError(status: PickAndPackStatus, messageError: string) {
        this._errorMessage = messageError;
        this._status = status;
    }

    @action
    public scanProcessingSuccessful(response: PickAndPackCommandReturn, parcel: Parcel) {
        this._area = response.area;
        this._itemScanned = parcel;
        this._detailItemScanned = this.getDetailItemScanned();
        this._status = response.status;
        this._errorMessage = undefined;
    }

    @action
    public listenNumericKeyBoard(e: any) {
        if (this._eanStreamedInputMoment && moment().diff(this._eanStreamedInputMoment, "ms") > 500) {
            this._eanStreamed = "";
        }
        this._eanStreamedInputMoment = moment();
        if (e.keyCode >= 33 && e.keyCode <= 126) {
            this._eanStreamed = this._eanStreamed + e.key;
        }
    }

    @action
    public fireEanStreamed(e: any, scanAction: (n: string) => any) {
        if (e.keyCode === 13) {
            scanAction(this._eanStreamed);
            this._eanStreamed = "";
        }
    }
}

export const pickAndPackModel = new PickAndPackModel();

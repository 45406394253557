import { palletService } from "../../../services/pallet";
import {
    PalletShippingValidationRequest,
    PalletShippingValidationStrategy,
} from "./pallet-shipping-validation-request";
import { PalletShippingValidationResponse } from "./pallet-shipping-validation-response";
import { t } from "i18next";
import { uniq } from "lodash";
import { PalletsListItem } from "../pallets-list/pallets-list-item";

export class PalletShippingValidationHandler {
    public async handle(request: PalletShippingValidationRequest) {
        if (request.shippingPalletsList.length === 0) {
            return PalletShippingValidationResponse.success();
        }

        switch (request.strategy) {
            case PalletShippingValidationStrategy.ForShippingList:
                return this.handleShippingPalletsList(request.shippingPalletsList);
            case PalletShippingValidationStrategy.ForScanMode:
                return this.handlePallet(request.shippingPalletsList[0]);
        }
    }

    private async handleShippingPalletsList(shippingPalletsList: PalletsListItem[]) {
        const result = await palletService.validateShippingDependencies(shippingPalletsList.map(p => p.id));
        if (result.hasDeliveryOrderDependencies) {
            return PalletShippingValidationResponse.error(t("components.pallet.shipping.error.orderDependency", {
                deliveryOrderList: result.deliveryOrders.map(d => d.label).join(", "),
            }));
        }

        if (result.hasParcelDependencies) {
            return PalletShippingValidationResponse.error(t("components.pallet.shipping.error.parcelDependency", {
                parcelList: result.parcels.map(d => d.label).join(", "),
            }));
        }

        if (result.hasPalletDependencies) {
            const closedPalletDependencies = result.pallets.filter(p => p.status === "Closed");
            if (closedPalletDependencies.length === result.pallets.length) {
                return PalletShippingValidationResponse.warning(t("components.pallet.shipping.error.closedPalletDependency", {
                    palletList: uniq(closedPalletDependencies.map(p => p.code)).join(", "),
                }));
            }

            const openPalletDependencies = result.pallets.filter(p => p.status === "Open");
            return PalletShippingValidationResponse.error(t("components.pallet.shipping.error.openPalletDependency", {
                palletList: uniq(openPalletDependencies.map(p => p.code)).join(", "),
            }));
        }

        return PalletShippingValidationResponse.success();
    }

    private async handlePallet(pallet: PalletsListItem) {
        const result = await palletService.validateShippingDependencies([pallet.id]);

        if (result.hasDeliveryOrderDependencies || result.hasParcelDependencies) {
            return PalletShippingValidationResponse.error(t("components.pallet.shipping.error.scannedWithNonPalletisedDependency"));
        }

        if (result.hasPalletDependencies) {
            const closedPalletDependencies = result.pallets.filter(p => p.status === "Closed");
            if (closedPalletDependencies.length === result.pallets.length) {
                return PalletShippingValidationResponse.warning(t("components.pallet.shipping.error.scannedWithClosedPalletDependency", {
                    palletCode: pallet.code,
                    palletList: uniq(closedPalletDependencies.map(p => p.code)).join(", "),
                }));
            }

            const openPalletDependencies = result.pallets.filter(p => p.status === "Open");
            return PalletShippingValidationResponse.error(t("components.pallet.shipping.error.scannedWithOpenPalletDependency", {
                palletList: uniq(openPalletDependencies.map(p => p.code)).join(", "),
            }));
        }

        return PalletShippingValidationResponse.success();
    }
}

import React, { createContext, Dispatch, ReactNode, ReactNodeArray, useContext, useReducer } from "react";
import { useContextTypeSafe } from "../../../hooks/use-context-type-safe";
import { IUserNotificationCenterDispatcherPayload, UserNotificationCenterReducer } from "./user-notification-center-reducer";
import moment from "moment";

export interface IUserNotificationCenterContext {
    isOpen: boolean;
    unreadNotificationsCount: number;
    notificationCenterLastOpen: moment.Moment;
}
const defaultUserNotificationCenterContext: IUserNotificationCenterContext = {
    isOpen: false,
    unreadNotificationsCount: 0,
    notificationCenterLastOpen: localStorage.getItem("notification_center_last_open") === null ?
        moment.unix(0) : moment.unix(Number(localStorage.getItem("notification_center_last_open"))),
};

const UserNotificationCenterContext = createContext<IUserNotificationCenterContext>(defaultUserNotificationCenterContext);
const UserNotificationCenterDispatcherContext = createContext<Dispatch<IUserNotificationCenterDispatcherPayload> | null>(null);

export const UserNotificationCenterContextProvider = (props: {children: ReactNode | ReactNodeArray}) => {
    const [notificationCenterContext, notificationCenterContextDispatcher] = useReducer(UserNotificationCenterReducer, defaultUserNotificationCenterContext);

    return <UserNotificationCenterContext.Provider value={notificationCenterContext}>
        <UserNotificationCenterDispatcherContext.Provider value={notificationCenterContextDispatcher}>
            {props.children}
        </UserNotificationCenterDispatcherContext.Provider>
    </UserNotificationCenterContext.Provider>;
};

export const useUserNotificationCenter = () => {
    return useContext(UserNotificationCenterContext);
};

export const useUserNotificationCenterDispatcher = () => {
    return useContextTypeSafe(UserNotificationCenterDispatcherContext);
};

import { PrinterDeviceManager } from "../printer-device-manager";
import { remotePrintingService } from "../../../../front-office/services/remote-printing";
import { PageSettings, qzManager } from "../../../../front-office/qz/qz-manager";
import { documentService } from "../../../../front-office/services/document";
import { PrinterDevice } from "../../../model/printer-device";
import { RemoteDocumentJob } from "./remote-document-job";
import { Guid } from "guid-typescript";

export class RemoteDocumentsPrinter {
    private readonly _printerDeviceManager = new PrinterDeviceManager();
    public async listAll() {
        return (await Promise.all(this._printerDeviceManager.list()
            .map(async device => await this.listForPrinter(device)))).flatMap(e => e);
    }

    public async listForPrinter(printer: PrinterDevice) {
        return (await remotePrintingService.list(printer.id))
            .map(documentId => new RemoteDocumentJob(documentId, printer));
    }

    public async printDocument(printer: PrinterDevice, documentId: Guid) {
        const data = (await documentService.getDocumentFile(documentId.toString())).Value!;

        if (printer.type === "zpl") {
            return await this.printPdfDocument(printer.name, data);
        } else {
           return await this.printZplDocument(printer.name, data);
        }
    }

    public async printPdfDocument(printerName: string, data: File) {
        await qzManager.qzPrintPdfOnPrinter(data, printerName, PageSettings.Default);
    }

    public async printZplDocument(printerName: string, data: File) {
        await qzManager.qzPrintRawFileOnPrinter(data, printerName);
    }
}

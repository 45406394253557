import { observable, action, computed } from "mobx";

interface IDialogMessage {
    title: string;
    message: string;
    icon?: DialogMessageIcon;
    onConfirm?: () => void;
}

export enum DialogMessageIcon {
    Warning = "warning",
    Error = "error",
}

class DialogModel {
    @observable private _messageList: IDialogMessage[] = [];

    @computed
    public get messageList() {
        return this._messageList as ReadonlyArray<IDialogMessage>;
    }

    @action
    public alert(title: string, message: string, icon?: DialogMessageIcon) {
        this._messageList.push({ title, message, icon });
    }

    @action
    public confirmBox(title: string, message: string, onConfirm: () => void, icon?: DialogMessageIcon) {
        this._messageList.push({ title, message, icon, onConfirm });
    }

    @action
    public removeMessage(idx: number) {
        this._messageList.splice(idx, 1);
    }
}

export const dialogModel = new DialogModel();

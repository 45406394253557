import * as React from "react";

import { ParcelList } from "../parcel-list/parcel-list";
import { inject, observer } from "mobx-react";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { Parcel } from "../../../model/parcel";
import { ParcelDetail } from "../../../model/parcel-detail";
import { ParcelFilter } from "../../../model/parcel-filter";
import { ParcelShipmentHeader } from "./parcel-shipment-header";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { context } from "../../../../context/context";
import { ParcelStatusType, ShipmentParcelActionType } from "../../../constants/constants";
import { ParcelDataExporter } from "../parcel-list/parcel-data-exporter";
import { Permission } from "../../../../context/permission";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { parcelProcessingValidator } from "../parcel-list/parcel-processing-validator";
import { notEligibleForPalletFlowValidator } from "../../../../common/component/action/action-select/not-eligible-for-pallet-flow-validator";
import { CompositeEligibilityValidator } from "../../../../common/component/action/action-select/composite-eligibility-validator";
import { notEligibleForBluebrandValidator } from "../../../../common/component/action/action-select/not-eligible-for-bluebrand-validator";
import { documentPrinter } from "../printer/document-printer";
import { deliverySlipProvider } from "../printer/document-provider/delivery-slip-provider";
import { parcelsService } from "../../../services/parcels";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { t } from "i18next";
import { SingleActionPicker } from "../../../../common/component/action/action-select/single-action-picker";
import { IWithRouterProps, withRouter } from "../../../../common/component/hoc/withRouter";

const parcelManagementModel = new SearchListModel<Parcel, ParcelDetail, ParcelFilter>();

interface IParcelShipmentProps {
    singleActionPickerAnchorElement: HTMLElement | null;
    singleActionPickerParcelItem: Parcel | null;
}

@inject("routing")
@observer
class ParcelShipmentComponent extends ParcelList<IParcelShipmentProps> {
    public state: IParcelShipmentProps = {
        singleActionPickerAnchorElement: null,
        singleActionPickerParcelItem: null,
    };

    constructor(props: IWithRouterProps & IHistoryProps) {
        super(props, parcelManagementModel, new ParcelDataExporter("Ship parcel", true, true));
    }

    protected get parcelStatusFilter(): ParcelStatusType {
        if (context.hasPermission(Permission.FeatureReadyToShip)) {
            return "ReadyToShip";
        }
        return "Labeled";
    }

    protected renderActions() {
        return <>
            <ParcelShipmentHeader actionsList={this.actionsList}
                                  reloadParcelsList={() => this.reloadParcelList()} />
            {this.state.singleActionPickerAnchorElement &&
                <SingleActionPicker anchorElement={this.state.singleActionPickerAnchorElement}
                                    item={this.state.singleActionPickerParcelItem}
                                    list={this.singleActionsList}
                                    onActionComplete={() => this.reloadParcelList()}
                                    onPickerClose={() => this.closeSingleActionPickerMenu()} />}
        </>;
    }

    protected get actionsList(): Array<IActionCommand<Parcel, ShipmentParcelActionType>> {
        return [{
            execute: async parcelList => await this.executeShip(parcelList),
            label: "parcelShipment.action.ship.title",
            loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
            value: "ship",
            description: "parcelShipment.action.ship.description",
            paramValidator: parcelProcessingValidator,
            actionEligibilityValidator: notEligibleForPalletFlowValidator,
        },
            this.getPrintSlipAction("parcelShipment"),
            {
                execute: async parcelList => await this.executePrintSlipAndShip(parcelList),
                label: "parcelShipment.action.printSlipAndShip.title",
                loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
                value: "printSlipAndShip",
                description: "parcelShipment.action.printSlipAndShip.description",
                actionEligibilityValidator: new CompositeEligibilityValidator([notEligibleForBluebrandValidator, notEligibleForPalletFlowValidator]),
            },
            this.getPrintLabelAction("parcelShipment"),
            this.getPrintPreparationOrderAction("parcelShipment"),
            this.getPrintPreparationOrderAndLabelAction("parcelShipment"),
            this.getCustomDocumentAction("parcelShipment"),
            {
                execute: async parcelList => await this.executeCancelParcel(parcelList),
                label: "parcelShipment.action.cancel.title",
                loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
                value: "cancel",
            }];
    }

    protected openSingleActionPickerMenu(anchor: any, item: Parcel) {
        this.setState({
            singleActionPickerAnchorElement: anchor,
            singleActionPickerParcelItem: item,
        });
    }

    private closeSingleActionPickerMenu() {
        this.setState({
            singleActionPickerParcelItem: null,
            singleActionPickerAnchorElement: null,
        });
    }

    private async executePrintSlipAndShip(parcelList: Parcel[]) {
        if (parcelList && parcelList.length > 0) {
            const summary = await documentPrinter.downloadMultiparcelDocument(this.props.params.operationCode,
                +this.props.params.batchId, parcelList, deliverySlipProvider);

            if (summary.nbParcelsPrinted > 0) {
                const shippedResult = await parcelsService.shipParcels(
                    this.props.params.operationCode,
                    +this.props.params.batchId,
                    parcelList.map(p => p.id));

                await this.reloadParcelList();
                notificationModel.addSuccessMessage(
                    this.getSuccessMessage("printSlipAndShip", shippedResult.parcelsValid.length));
            } else {
                notificationModel.addErrorMessage(t(`parcelShipment.action.printSlipAndShip.noItems`));
            }
        } else {
            throw new Error("No data selected, filter should be done previously.");
        }
    }

    private async executeShip(parcelList: Parcel[]) {
        const shippedResult = await parcelsService.shipParcels(
            this.props.params.operationCode,
            +this.props.params.batchId,
            parcelList.map(p => p.id));

        await this.reloadParcelList();
        notificationModel.addSuccessMessage(
            this.getSuccessMessage("ship", shippedResult.parcelsValid.length));
    }

    private async executeCancelParcel(parcelList: Parcel[]) {
        const deleteResult = await parcelsService.deleteParcelList(
            this.props.params.operationCode,
            +this.props.params.batchId,
            parcelList.map(p => p.id));

        await this.reloadParcelList();
        notificationModel.addSuccessMessage(
            this.getSuccessMessage("cancel", deleteResult.parcelsValid.length));
    }

    protected getSuccessMessage(shipmentParcelAction: string, nbProcessed: number) {
        if (nbProcessed === 0) {
            return t(`parcelShipment.action.${shipmentParcelAction}.noItems`);
        }
        const isMultipleParcels = nbProcessed > 1;
        return t(`parcelShipment.action.${shipmentParcelAction}.success` + (isMultipleParcels ? "s" : ""),
            { count: nbProcessed });
    }

    protected get hiddenSortFields() {
        return ["shipmentDate"];
    }

    protected get isSelectionEnabled() {
        return context.hasPermission(Permission.FrontWrite);
    }

    protected get isParcelTrackerDisplayed() {
        return true;
    }

    protected get isPalletCodeDisplayed() {
        return true;
    }

    protected get allowOrderPendingCancellationFiltering() {
        return true;
    }

    protected get allowOrderCancellationStatusFiltering() {
        return false;
    }
}

export const ParcelShipment = withRouter(ParcelShipmentComponent);

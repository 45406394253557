import React, { useState } from "react";

import { Permission } from "../../../../context/permission";
import { Input, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Icon } from "../../icon/icon";
import { BarCodeIcon } from "../../icon/icon-custom";
import { BulkyActionPickerHandler } from "./bulky-action-picker-handler";
import { context } from "../../../../context/context";
import { t } from "i18next";
import { IActionEligibilityValidator } from "./interface-action-eligibility-validator";
import { IParamActionValidator } from "./interface-param-action-validator";
import { BulkyActionScanDialog } from "../bulky-action-scan-dialog/bulky-action-scan-dialog";

import "./style.scss";

export interface IActionCommand<T, ActionType> {
    label: string;
    value: ActionType;
    description?: string;
    hideActionProgressLoader?: boolean;
    hideExecutionConfirmation?: boolean;
    disableScanMode?: boolean;
    disableSingleActionPicker?: boolean;
    loadExecuteParam: (isScan: boolean, param?: any) => T[];
    actionEligibilityValidator?: IActionEligibilityValidator;
    paramValidator?: IParamActionValidator<T>;
    singleActionParamEligibilityValidator?: IParamActionValidator<T>;
    execute: (identifierList: T[]) => (any | Promise<any>);
    executeCustomScanMode?: () => void;
}

interface IBulkyActionPickerProps {
    propertyKey: string;
    scanFieldType: "text" | "number";
    inputLabel: string;
    contentPrefix: string;
    actions: Array<IActionCommand<any, string>>;
    withScanMode?: boolean;
    permission: Permission;
}

export const BulkyActionPicker = (props: IBulkyActionPickerProps) => {
    const availableActions = props.actions.filter(action =>
        action.actionEligibilityValidator === undefined || action.actionEligibilityValidator.validate());

    const [selectedAction, setSelectedAction] = useState(
        availableActions.length > 0 ? availableActions[0] : undefined,
    );
    const [defaultScanModeDialogOpen, setDefaultScanModeDialogOpen] = useState(false);

    const handleScanAction = () => {
        if (selectedAction?.executeCustomScanMode === undefined) {
            setDefaultScanModeDialogOpen(true);
        } else {
            selectedAction.executeCustomScanMode();
        }
    };

    if (!context.hasPermission(props.permission) || selectedAction === undefined) {
        return <div />;
    }

    return <div className="printComponent" key={props.propertyKey}>
        <InputLabel htmlFor={t(props.inputLabel)}>{t(props.inputLabel)}</InputLabel>
        <Select value={selectedAction.value}
                onChange={(event: SelectChangeEvent) =>
                    setSelectedAction(props.actions.find(x => x.value === event.target.value))}
                input={<Input id={t(props.inputLabel)} />}
                className="action-select"
                data-testid={"action-select"}>
            {availableActions.map((act, idx) => {
                return <MenuItem value={act.value} key={idx} data-testid={`action-${act.value}`}>
                    {t(act.label)}
                </MenuItem>;
            })}
        </Select>
        <Icon icon="play_circle_filled"
              onClick={async () => await new BulkyActionPickerHandler(selectedAction).execute()}
              tooltipTitle={`${props.contentPrefix}.${selectedAction.value}.selectionActionDescription`}
              tooltipPlacement="top-end" />
        {props.withScanMode && <Icon icon={<BarCodeIcon className="material-icons" />}
                                     onClick={() => handleScanAction()}
                                     tooltipTitle={selectedAction.disableScanMode ?
                  "" : `${props.contentPrefix}.${selectedAction.value}.scanActionDescription`}
                                     tooltipPlacement="top-end"
                                     disabled={selectedAction.disableScanMode}
        />}
        {defaultScanModeDialogOpen && props.withScanMode && <BulkyActionScanDialog
            open={defaultScanModeDialogOpen}
            scanFieldType={props.scanFieldType}
            contentPrefix={props.contentPrefix}
            action={selectedAction}
            onClose={() => setDefaultScanModeDialogOpen(false)}
            onScan={async value => await new BulkyActionPickerHandler(selectedAction).scan(value)}
        />}
    </div>;
};

import React from "react";
import { observer } from "mobx-react";
import { IImportModuleProps } from "./iimport-module-props";
import { Paper, Typography, Button } from "@mui/material";
import { Icon } from "../../../../common/component/icon/icon";
import { AddAdditionalValueCommandReturn } from "../../../model/add-additional-values-command-return";
import { t } from "i18next";
import { values, size } from "lodash";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { routeBatchPreparation } from "../../../navigation/preparation-route-props";

@observer
export class ImportExecutionReport extends React.Component<IImportModuleProps & IHistoryProps> {

    private getIconReport(importResult: AddAdditionalValueCommandReturn) {
        if (importResult.nbAdded === 0) {
            return "cancel";
        } else {
            if (size(importResult.errorMap) > 0) {
                return "warning";
            } else {
                return "check";
            }
        }
    }

    private getReportMessage(nbAdded: number) {
        if (nbAdded === 0) {
            return t("components.importList.noLineAdded");
        } else if (nbAdded === 1) {
            return t("components.importList.oneLineAdded");
        } else {
            return t("components.importList.nbSuccessAdded", { nbAdded });
        }
    }

    public render() {
        const reportValues = this.props.importFileModel.executionReturnValues;
        const icon = this.getIconReport(reportValues);
        const errorValues = values(reportValues.errorMap);
        return <div>
            <Paper component="div" className="execution_report_container">
                <Typography component="div">
                    <div className={`nb_success_added ${icon}`} data-testid={`${icon}-import`}>
                        <Icon icon={icon} />
                        <div>{this.getReportMessage(reportValues.nbAdded)}</div>
                    </div>
                    <div>
                        {errorValues.length > 0 ? <div className="unimported-rows-label">{t("components.importList.errorListLabel")}</div> : ""}
                        {errorValues.map(x => {
                            return <div key={x} className="error_import_item" data-testid="failed-imported-rows">{x}</div>;
                        })}
                    </div>
                </Typography>
            </Paper>
            <div className="import-file-buttons">
                <div />
                <Button variant="contained" color="primary" onClick={() => this.quitImport()} >
                    {t("components.importList.finished")}
                </Button>
            </div>
        </div>;
    }

    private quitImport() {
        this.props.routing.push(`/operations/${routeBatchPreparation}/${this.props.importFileModel.selectedOperationCode}`);
}
}

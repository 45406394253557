import { extend, orderBy } from "lodash";
import { ContentType, httpDelete, httpGet, httpGetList, httpPost, httpPut } from "../../common/network/fetch";

import { User } from "../model/user";
import { UserNew } from "../model/user-new";
import { UserSummary } from "../model/user-summary";
import { UserWithWarehouseDetails } from "../model/user-with-warehouse-details";
import { UserAttribute } from "../../constants/constants";

class UsersService {

    public async getUsers() {
        const userList = await httpGetList<UserWithWarehouseDetails>({url: "/users"});
        return (orderBy(userList.map(user => new UserWithWarehouseDetails(user)), ["sortColumn"]));
    }

    public async getUser(userId: string) {
        const user = await httpGet<User>({url: `/users/${userId}`}) as User;
        return new User(user);
    }

    public async saveUser(userId: string, user: User) {
        return httpPut({url: `/users/${userId}`, data: this.getUserData(user)});
    }

    private getUserData(user: User | UserNew, password?: string): Partial<User | UserNew> {
        return extend(password == null ? {} : {password}, user, {
            roles: [...user.featureRoles, ...[user.level]],
        });
    }

    public async register(userNew: UserNew, password: string) {
        return httpPost<string>({url: "/users/register", data: this.getUserData(userNew, password)});
    }

    public async changePassword(userId: string, password: string) {
        return httpPut({url: `/users/${userId}/password`, data: password, contentType: ContentType.APPLICATION_JSON});
    }

    public async deleteUser(userId: string) {
        return httpDelete({url: `/users/${userId}`});
    }

    public async getOwners() {
        return await httpGetList<UserSummary>({url: "/admin/user/list-administrator"});
    }

    public async getAttributes() {
        return await httpGetList<UserAttribute>({url: "/admin/attribute/list"});
    }

    public async createResetPasswordToken(userName: string) {
        return await httpPost({url: "/users/password/forgot", data: {userName}});
    }

    public async verifyResetPasswordToken(userName: string, token: string) {
        return await httpPost({url: "/users/password/token/validate", data: {userName, token}});
    }

    public async resetPasswordChange(userName: string, token: string, password: string) {
        return await httpPost({url: "/users/password/change", data: {userName, token, password}});
    }
}

export const usersService = new UsersService();

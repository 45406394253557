import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { DialogContent, FormControl, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import React, {useState} from "react";
import { notificationModel } from "../../../../common/component/notifications/notification-model";

interface IPalletCloseDialogScanProps {
    getPalletItemByScan: (palletCode: string) => PalletsListItem | undefined;
    onItemScanned: (pallet: PalletsListItem) => Promise<void>;
}

export const PalletCloseDialogScan = (props: IPalletCloseDialogScanProps) => {
    const [textValue, setTextValue] = useState("");

    const handleOnKeyDown = async (keyDown: string) => {
        if (keyDown !== "Enter" || textValue === "") {
            return;
        }

        const item = props.getPalletItemByScan(textValue.trim());

        if (item !== undefined) {
            await props.onItemScanned(item);
        } else {
            notificationModel.addErrorMessage(t("components.scanAction.noElementSelected"));
        }

        setTextValue("");
    };

    return <>
        <DialogContent>
            <div className={"pallet-closing-scan-elements"}>
                <Typography className={"pallet-closing-dialog__description"}>
                    {t("components.pallet.closing.scan.description")}
                </Typography>
                <FormControl variant={"outlined"}
                             fullWidth>
                    <TextField id={"scan-field"}
                               value={textValue}
                               variant={"outlined"}
                               autoFocus
                               onKeyDown={async (event: React.KeyboardEvent<any>) => await handleOnKeyDown(event.key)}
                               onChange={event => setTextValue(event.target.value ?? "")}/>
                </FormControl>
            </div>
        </DialogContent>
    </>;
};

import { CommonFilter } from "../../../model/common-filter";
import { filterSettings, label } from "../../../../common/dto/dto-annotation";
import {
    filterActionAllValuesWithCsv,
    filterActionStringValues,
} from "../../../../common/component/list/filter/filter-action-data";
import { observable } from "mobx";

export class PalletFilter extends CommonFilter {
    @label("model.palletFilter.palletCode")
    @filterSettings({ actions: filterActionStringValues })
    @observable
    public code: string;

    @label("model.palletFilter.parcelCount")
    @filterSettings({
        actions: ["all", "equals", "different", "greater", "lower", "greaterOrEqual", "lowerOrEqual"],
    })
    @observable
    public parcelCount: number;

    @label("model.palletFilter.weight")
    @filterSettings({
        actions: ["all", "equals", "different", "greater", "lower", "greaterOrEqual", "lowerOrEqual"],
    })
    @observable
    public weight: number;

    @label("model.palletFilter.operationCode")
    @filterSettings({ actions: filterActionStringValues })
    @observable
    public operationCode: string;

    @label("model.palletFilter.batchId")
    @filterSettings({ actions: filterActionAllValuesWithCsv })
    @observable
    public batchId: string;

    @label("model.palletFilter.parcelId")
    @filterSettings({ actions: filterActionAllValuesWithCsv })
    @observable
    public parcelId: string;

    @label("model.palletFilter.parcelTracker")
    @filterSettings({ actions: filterActionStringValues })
    @observable
    public parcelTracker: string;

    @label("model.palletFilter.isLabelPrinted")
    @filterSettings({ actions: ["all", "isTrue", "isFalse"] })
    @observable
    public isLabelPrinted: string;
}

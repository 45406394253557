export enum PalletShippingValidationStatus {
    Success,
    Warning,
    Error,
}

export class PalletShippingValidationResponse {
    private readonly _status: PalletShippingValidationStatus;
    private readonly _message: string;

    constructor(status: PalletShippingValidationStatus, message: string) {
        this._status = status;
        this._message = message;
    }

    public static success() {
        return new PalletShippingValidationResponse(PalletShippingValidationStatus.Success, "");
    }

    public static error(message: string) {
        return new PalletShippingValidationResponse(PalletShippingValidationStatus.Error, message);
    }

    public static warning(message: string) {
        return new PalletShippingValidationResponse(PalletShippingValidationStatus.Warning, message);
    }

    get message(): string {
        return this._message;
    }

    get status(): PalletShippingValidationStatus {
        return this._status;
    }

    get isError() {
        return this._status === PalletShippingValidationStatus.Error;
    }

    get isWarning() {
        return this._status === PalletShippingValidationStatus.Warning;
    }
}

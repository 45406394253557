import * as React from "react";
import { Operation } from "../../../model/operation";
import { FormatOptionLabelMeta } from "react-select";
import { SelectAutocomplete } from "../../../../common/component/select-autocomplete/select-autocomplete";
import * as ParcelWorkflowIcon from "./parcel-workflow.svg";
import * as PalletWorkflowIcon from "./pallet-workflow.svg";
import { Tooltip, Typography, Icon } from "@mui/material";
import { stringValue } from "../../../../common/field/renderer";
import { OperationStatusType } from "../../../constants/constants";
import {
    AccessTimeOutlined,
    CheckOutlined,
    LoopOutlined,
} from "@mui/icons-material";
import { green, orange } from "@mui/material/colors";
import { t } from "i18next";

import "./style.scss";

export interface IOperationSelectProps {
    list: Operation[];
    onSelect: (item: Operation | null) => void;
    label: string;
    selectedItem: Operation | null;
    propertyKey: string;
    disabled?: boolean;
    placeholder: string;
    noOptionsMessage: string;
}

interface IOperationOptionLabelProps {
    operation: Operation;
    labelMeta: FormatOptionLabelMeta<Operation>;
}

interface IOperationWorkflowIconProps {
    operation: Operation;
}

interface IOperationStatusIconProps {
    operationStatus: OperationStatusType;
}

export const OperationSelect = (props: IOperationSelectProps) => {
    return <SelectAutocomplete {...props}
                               getOptionValue={operation => operation.code}
                               formatOptionLabel={(operation, labelMeta) =>
                                   <OperationOptionLabel operation={operation} labelMeta={labelMeta} />} />;
};

const OperationOptionLabel = (props: IOperationOptionLabelProps) => {
    return <div className={`operation-label operation-label--${props.labelMeta.context}`}>
        <div className={"operation-label__icon-container"}>
            <OperationWorkflowIcon operation={props.operation} />
        </div>
        <Typography variant={"body1"}>{props.operation.code}</Typography>
        <div className={"operation-label__date"}>
            <Typography variant={"subtitle1"} color={"textSecondary"}>
                {stringValue(props.operation, "beginDate")}
            </Typography>
            <Typography variant={"subtitle1"} color={"textSecondary"} className={"operation-label__date-separator"}>
                {t("components.operationSelector.dateRangeTo")}
            </Typography>
            <Typography variant={"subtitle1"} color={"textSecondary"}>
                {stringValue(props.operation, "endDate")}
            </Typography>
        </div>
        <Typography variant={"subtitle1"} component={"div"} className={"operation-label__status-container"}>
            <Tooltip title={props.operation.statusLabel} placement={"right"}>
                <Icon fontSize={"inherit"}>
                    <OperationStatusIcon operationStatus={props.operation.status} />
                </Icon>
            </Tooltip>
        </Typography>
    </div>;
};

const OperationWorkflowIcon = (props: IOperationWorkflowIconProps) => {
    const iconDescription = t(`components.operationSelector.operationMode${props.operation.isPalletMode ? "Pallet" : "Parcel"}`);

    return <Tooltip title={iconDescription}
                    placement={"right"}>
        <img src={props.operation.isPalletMode ? PalletWorkflowIcon : ParcelWorkflowIcon}
             alt={iconDescription}
             className={"operation-label__icon"} />
    </Tooltip>;
};

const OperationStatusIcon = (props: IOperationStatusIconProps) => {
    switch (props.operationStatus) {
        case "Synching":
            return <LoopOutlined htmlColor={orange.A700} fontSize={"inherit"} />;
        case "InProgress":
            return <AccessTimeOutlined htmlColor={orange.A700} fontSize={"inherit"} />;
        default:
            return <CheckOutlined htmlColor={green.A700} fontSize={"inherit"} />;
    }
};

/**
 * Encapsulates an error for http response.
 */
export class DropNetError {

    /**
     *
     */
    constructor(code: number, description: string) {
        this.Code = code;
        this.Description = description;
    }

    /**
     * Error description.
     */
    public Description: string;

    /**
     * Error code.
     */
    public Code: number;
}

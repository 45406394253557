import { createMuiTheme } from "@mui/material";
import { pink, red } from "@mui/material/colors";

export const theme = createMuiTheme({
    palette: {
        error: red,
        primary: {
            ...pink,
            500: "#ec008c",
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "13px",
                },
            },
        },
    },
});

theme.typography.fontFamily = "VPSans";
theme.typography.body1.fontFamily = "VPSans";
theme.typography.body2.fontFamily = "VPSans";
theme.typography.body1.fontSize = "16px";
theme.typography.body2.fontSize = "13px";

theme.typography.h6.color = "#ec008c";
theme.typography.h5.color = "#ec008c";

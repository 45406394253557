import { domain } from "../../common/dto/dto-annotation";
import { identifier } from "../../domain/identifier";
import { label50 } from "../../domain/label";
import { observable } from "mobx";
import { quantity } from "../../domain/quantity";

export class Article {

    @domain(identifier)
    @observable
    public id: number;

    @domain(label50)
    @observable
    public name: string;

    @domain(label50)
    @observable
    public reference: string;

    @domain(quantity)
    @observable
    public availableQuantity: number = 0;

    constructor(id: number, name: string, reference: string, availableQuantity: number) {
        this.id = id;
        this.name = name;
        this.reference = reference;
        this.availableQuantity = availableQuantity;
    }
}

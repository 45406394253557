import { User } from "./user";

export class WarehouseContext {
    private readonly _code: string;
    private readonly _countryIso: string;

    public static initializeForUser(user: User) {
        if (!user.isSupplier) {
            return undefined;
        }

        if (user.warehouseCode === undefined) {
            throw new Error("Unable to create supplier warehouse context.");
        }

        return new WarehouseContext(user.warehouseCode, user.warehouseCountryIso ?? "UNKNOWN");
    }

    public constructor(code: string, country: string) {
        this._code = code;
        this._countryIso = country;
    }

    public get code(): string {
        return this._code;
    }

    get countryIso(): string {
        return this._countryIso;
    }

    get isFrenchWarehouse() {
        return this._countryIso === "FR";
    }
}

import { domain, label } from "../../common/dto/dto-annotation";
import { label50, label255 } from "../../domain/label";

import { assign } from "lodash";
import { identifier } from "../../domain/identifier";
import { action, computed, observable } from "mobx";
import { quantity } from "../../domain/quantity";
import { weight } from "../../domain/weight";
import { AdditionalDataModel } from "./additional-data-model";
import { DeliveryOrderDetailType } from "./delivery-order-detail-type";

export interface IDeliveryOrderDetail {
    additionalDataMap: { [key: string]: string } | undefined;
    supplierReference: string;
    productId: number;
    name: string;
    quantity: number;
    quantityParcelled: number;
    quantityLabeled: number;
    quantityShipped: number;
    quantityStockout: number;
    weight: number;
    ean13List: string[];
    componentProductId?: string;
    detailType: DeliveryOrderDetailType;
    unCode: string;
}

export class DeliveryOrderDetail extends AdditionalDataModel {
    constructor(deliveryOrderDetail?: IDeliveryOrderDetail) {
        super(deliveryOrderDetail && deliveryOrderDetail.additionalDataMap);

        if (deliveryOrderDetail) {
            assign(this, deliveryOrderDetail);
        }
    }

    @label("model.deliveryOrderDetail.supplierReference")
    @domain(label50)
    public supplierReference: string;

    @label("model.deliveryOrderDetail.productId")
    @domain(identifier)
    public productId: number;

    @label("model.deliveryOrderDetail.name")
    public name: string;

    /** Product label. */
    @label("model.deliveryOrderDetail.labelReference")
    @domain(label255)
    public get labelReference() {
        return this.name;
    }

    @label("model.deliveryOrderDetail.quantity")
    @domain(quantity)
    public quantity: number;

    @label("model.deliveryOrderDetail.quantityParcelled")
    @domain(quantity)
    @observable
    public quantityParcelled: number;

    @label("model.deliveryOrderDetail.quantityLabeled")
    @domain(quantity)
    @observable
    public quantityLabeled: number;

    @label("model.deliveryOrderDetail.quantityShipped")
    @domain(quantity)
    @observable
    public quantityShipped: number;

    @label("model.deliveryOrderDetail.quantityStockout")
    @domain(quantity)
    @observable
    public quantityStockout: number = 0;

    @label("model.deliveryOrderDetail.weight")
    @domain(weight)
    public weight: number;

    @label("model.deliveryOrderDetail.ean13List")
    public ean13List: string[];

    @label("model.deliveryOrderDetail.componentProductId")
    public componentProductId?: string;

    @label("model.deliveryOrderDetail.type")
    public detailType: DeliveryOrderDetailType;

    @label("model.deliveryOrderDetail.unCode")
    public unCode: string;

    @label("model.deliveryOrderDetail.isContainingProductSet")
    public get isContainingProductSet() {
        return (this.componentProductId ?? "") !== "";
    }

    @computed
    public get quantityAvailable(): number {
        return this.quantity - this.quantityParcelled - this.quantityStockout;
    }

    @computed
    public get quantityNotParcelled(): number {
        return this.quantity - this.quantityParcelled;
    }

    @action
    public stockout() {
        this.quantityStockout = this.quantityAvailable;
    }

    @action
    public setStockoutQuantity(stockoutQuantity: number) {
        this.quantityStockout = stockoutQuantity;
    }

    @action
    public removeStockout() {
        this.quantityStockout = 0;
    }

    @computed
    public get isFulfilled(): boolean {
        return this.quantityAvailable === 0;
    }

    @computed
    public get stockoutActionsAvailable(): boolean {
        return !this.isFulfilled || this.quantityStockout > 0;
    }
}

import { httpDelete, httpGet, httpPost } from "../common/network/fetch";

import { Profile } from "../context/profile";
import { IUserWebhook } from "../context/user-webhook";

class ProfileService {

    public async load() {
        return await httpGet<Profile>({url: "/profile", versionApi: false});
    }

    public async saveProfile(profile: Profile) {
        return await httpPost<number>({url: "/profile",
            data: {
                contactEmail: profile.contactEmail,
                lang: profile.lang,
                isDownload: profile.isDownload,
                printerPaperFormat: profile.printerPaperFormat,
            },
            versionApi: false,
            failsOnUnauthorized: false});
    }

    public async saveWebhookUrl(webhook: IUserWebhook) {
        return await httpPost<number>({url: "/userWebhooks",
            data: {
                id: webhook.id,
                url: webhook.url,
                type: "Slack",
            },
            failsOnUnauthorized: false});
    }

    public async delete() {
        return await httpDelete({url: "/profile", versionApi: false});
    }
}

export const profileService = new ProfileService();

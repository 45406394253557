import { Pallet } from "../../model/pallet";

export class PalletAddParcelCommand {
    private readonly _pallet: Pallet;
    private readonly _parcelId?: number;
    private readonly _parcelLocator?: string;

    constructor(pallet: Pallet, parcelId?: number, parcelLocator?: string) {
        this._pallet = pallet;
        this._parcelId = parcelId;
        this._parcelLocator = parcelLocator;

        if (!this._parcelId && !this._parcelLocator) {
            throw new Error("Invalid arguments passed to PalletAddParcelCommand");
        }
    }

    public static createWithParcelId(pallet: Pallet, parcelId: number) {
        return new PalletAddParcelCommand(pallet, parcelId);
    }

    public static createWithParcelLocator(pallet: Pallet, parcelLocator: string) {
        return new PalletAddParcelCommand(pallet, undefined, parcelLocator);
    }

    get pallet(): Pallet {
        return this._pallet;
    }

    get parcelId(): number | undefined {
        return this._parcelId;
    }

    get parcelLocator(): string | undefined {
        return this._parcelLocator;
    }
}

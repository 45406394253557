import * as React from "react";

import { BulkyActionPicker, IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { ManagePalletActionType } from "../../../constants/constants";
import { observer } from "mobx-react";
import { Permission } from "../../../../context/permission";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { CreatePallet } from "../create-pallet/create-pallet";
import { context } from "../../../../context/context";

export interface IPalletManagementHeaderProps {
    actionsList: Array<IActionCommand<PalletsListItem, ManagePalletActionType>>;
    reloadPalletList: () => Promise<void>;
}

@observer
export class PalletManagementHeader extends React.Component<IPalletManagementHeaderProps, {}> {
    public render() {
        return <div className="list-actions-container">
            <div className="list-actions">
                <div className={"action-item"}>
                    <CreatePallet onPalletCreated={async () => await this.props.reloadPalletList()} />
                </div>
                <div className={"action-item"}>
                    <BulkyActionPicker withScanMode={true}
                                       propertyKey={context.warehouseCode}
                                       scanFieldType="text"
                                       inputLabel=""
                                       contentPrefix="palletManagement.action"
                                       actions={this.props.actionsList}
                                       permission={Permission.FrontWrite} />
                </div>
            </div>
        </div>;
    }
}

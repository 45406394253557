import * as React from "react";
import { ReactNodeArray } from "react";
import { Typography } from "@mui/material";

interface IPalletPickupFormCarrierCountryDeclarationInputGroupProps {
    name: string;
    children: ReactNodeArray;
    key: string;
}

export class PalletPickupFormCarrierCountryDeclarationInputGroup extends React.Component<IPalletPickupFormCarrierCountryDeclarationInputGroupProps, {}> {
    public render() {
        return <div className={"pallet-pickup-form-group__container"}>
            <Typography className={"pallet-pickup-form-group__title"} component={"h2"}>{this.props.name}:</Typography>
            <ul className={"pallet-pickup-form-group__list"}>
                {this.props.children}
            </ul>
        </div>;
    }
}

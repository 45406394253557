import * as React from "react";

import { SelectAutocomplete } from "../../../../common/component/select-autocomplete/select-autocomplete";
import { Article } from "../../../model/article";

export interface IArticleSelectProps {
    list: Article[];
    onSelect: (item: Article | null) => void;
    label: string;
    selectedItem: Article | null;
    propertyKey: string;
    disabled?: boolean;
    placeholder: string;
    noOptionsMessage: string;
}

export const ArticleSelect = (props: IArticleSelectProps) => {
    const getOptionValue = (article: Article) => `${article.name} ${article.reference} ${article.id}`;
    const formatOptionLabel = (article: Article) => `${article.name} (${article.reference})`;

    return <SelectAutocomplete {...props}
                               getOptionValue={getOptionValue}
                               formatOptionLabel={formatOptionLabel} />;
};

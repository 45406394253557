import { Badge, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import React, { ReactNode } from "react";

interface IUserAccountWidgetMenuItemProps {
    label: string;
    propertyKey: string;
    icon: ReactNode;
    badge?: number;
    onClick: () => void;
}

export const UserAccountWidgetMenuItem = (props: IUserAccountWidgetMenuItemProps) => {
    return <MenuItem onClick={props.onClick}
                     key={props.propertyKey}
                     data-testid={`user-account-item-${props.propertyKey}`}>
        <ListItemIcon>
            {props.icon}
        </ListItemIcon>
        <ListItemText>
            {props.label}
        </ListItemText>
        <Badge badgeContent={props.badge}
               className={"user-account-menu__badge"}
               color={"primary"}
               invisible={!Boolean(props.badge)} />
    </MenuItem>;
};

import { IOperationRouteProps, OperationPageType } from "./preparation-route-props";

import { merge } from "lodash";
import { operationContext } from "../../context/operation-context";

export function parseOperationUrl(url: string): IOperationRouteProps {
    const fragments = url.split("/");

    const page = fragments.length >= 1 ? fragments[2] : undefined;
    return {
        batchId: fragments.length >= 3 && isOperationPage(page) ? +fragments[4] : undefined,
        operationCode: fragments.length >= 2 && isOperationPage(page) ? fragments[3] : undefined,
        page: page as OperationPageType,
    };
}

export function createOperationUrl(params: IOperationRouteProps) {
    let url = "/operations";
    [params.page, params.operationCode, params.batchId].forEach(fragment => {
        if (fragment) {
            url += `/${fragment}`;
        }
    });
    return url;
}

function isOperationPage(page: string | undefined) {
    if (!page) {
        return false;
    }

    return page === "batch-preparation" || page === "parcel-management" || page === "ship-parcel" ||
        page === "reporting" || page === "pick-and-pack" || page === "import-data" || page === "stockouts" ||
        page === "prepare-parcel-shipping" || page === "bulk-parcel-upload" || page === "consult-shipped-parcel" ||
        page === "do-canceled" || page === "export-operation-products";
}

export function updateOperationUrl(url: string, params: IOperationRouteProps) {
    const fragments = parseOperationUrl(url);

    const updatedFragments = merge( {
        operationCode: operationContext.operation?.code,
        batchId: operationContext.batch?.id,
    }, fragments, params);
    return createOperationUrl(updatedFragments);
}

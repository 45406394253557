import * as React from "react";

import { context } from "../../../context/context";
import { Card, ClickAwayListener, IconButton, Switch, Tooltip, Typography } from "@mui/material";
import { HelpOutlineRounded, Settings as SettingsIcon } from "@mui/icons-material";
import { t } from "i18next";
import { SettingsFieldItem } from "./settings-field-item";
import { SettingsTextField } from "./settings-text-field";
import { useState } from "react";

export const GeneralSettings = () => {
    const handleMuteSounds = (value: boolean) => {
        (async () => {
            await context.updateMute(`${value}`);
        })();
    };

    const handleEmailAddressSave = async (email: string) => {
        return await context.updateEmail(email);
    };

    const handleWebhookUrlSave = async (url: string) => {
        return await context.updateWebhookUrl(url);
    };

    return <Card elevation={0} className={"settings-card"}>
        <Typography variant={"h2"} className={"settings-card__title"}>
            <SettingsIcon />
            {t("settings.header.profile")}
        </Typography>
        <div className={"settings-card__contents"}>
            <SettingsFieldItem id={"email-address"} label={t("settings.general.email")}>
                <SettingsTextField id={"email-address"}
                                   type={"email"}
                                   defaultValue={context.settings.contactEmail}
                                   onValueSave={value => handleEmailAddressSave(value)} />
            </SettingsFieldItem>
            <SettingsFieldItem id={"webhook-url"}
                               label={t("settings.general.webhook")}
                               labelIcon={<WebhookDescriptionTooltip />}>
                <SettingsTextField id={"webhook-url"}
                                   defaultValue={context.settings.webhookUrl}
                                   onValueSave={value => handleWebhookUrlSave(value)} />
            </SettingsFieldItem>
        </div>
        <Typography variant={"h2"} className={"settings-card__title"}>
            <SettingsIcon />
            {t("settings.header.general")}
        </Typography>
        <div className={"settings-card__contents"}>
            <SettingsFieldItem id={"mute-sounds"} label={t("settings.general.mute")}>
                <Switch id="mute-sounds"
                        onChange={() => handleMuteSounds(!context.settings.muted)}
                        checked={context.settings.muted} />
            </SettingsFieldItem>
        </div>
    </Card>;
};

const WebhookDescriptionTooltip = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleTooltipClose = () => {
        setIsOpen(false);
    };

    const handleTooltipOpen = () => {
        setIsOpen(true);
    };

    return <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
            <Tooltip PopperProps={{
                        disablePortal: true,
                     }}
                     onClose={handleTooltipClose}
                     open={isOpen}
                     disableFocusListener
                     disableHoverListener
                     disableTouchListener
                     placement={"right"}
                     title={<Typography variant={"subtitle1"}>{t("settings.general.webhookDescription")}</Typography>}>
                <IconButton onClick={handleTooltipOpen}>
                    <HelpOutlineRounded fontSize={"small"} />
                </IconButton>
            </Tooltip>
        </div>
    </ClickAwayListener>;
};

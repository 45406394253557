import React, { MutableRefObject, useEffect, useRef } from "react";
import { PalletsListItem } from "./pallets-list-item";
import { TableCell, TableRow } from "@mui/material";
import { PalletParcelRemove } from "../pallet-parcel-remove/pallet-parcel-remove";
import { stringValue } from "../../../../common/field/renderer";
import { t } from "i18next";
import { PalletsListSubItem } from "./pallets-list-sub-item";
import { observer } from "mobx-react";

interface IPalletContentsProps {
    pallet: PalletsListItem;
    additionalDataFields: string[];
    reloadPalletList: () => Promise<void>;
}

interface IPalletContentsItemProps {
    palletId: string;
    palletCode: string;
    propertyKey: string;
    parcel: PalletsListSubItem;
    isHighlighted: boolean;
    additionalDataFields: string[];
    reloadPalletList: () => Promise<void>;
}

interface IPalletContentsEmptyProps {
    palletCode: string;
}

export const PalletContents = observer((props: IPalletContentsProps) => {
    if (props.pallet.details.length === 0) {
        return <PalletContentsEmpty palletCode={props.pallet.code} />;
    }

    return <>
        {props.pallet.details.map((parcelItem, index) =>
            <PalletContentsItem propertyKey={"detail-" + index}
                                isHighlighted={parcelItem.isHighlighted}
                                parcel={parcelItem}
                                palletId={props.pallet.palletId}
                                palletCode={props.pallet.code}
                                reloadPalletList={props.reloadPalletList}
                                additionalDataFields={props.additionalDataFields} />)}
    </>;
});

const PalletContentsItem = (props: IPalletContentsItemProps) => {
    const elementRef: MutableRefObject<HTMLTableRowElement | null> = useRef(null);

    useEffect(() => {
        if (props.isHighlighted) {
            elementRef.current?.scrollIntoView({
                block: "center",
            });
        }
    }, [props.isHighlighted]);

    return <TableRow key={props.propertyKey}
                     className={`search-item__detail-line${props.isHighlighted ? " search-item__detail-line--highlighted" : ""}`}
                     data-testid={`pallet-line-${props.palletId}-parcel-${props.parcel.id}`}>
        <TableCell className={"search-item__detail-cell"} ref={elementRef}>
            <PalletParcelRemove palletId={props.palletId}
                                palletCode={props.palletCode}
                                parcelId={props.parcel.id}
                                onParcelRemoved={async () => await props.reloadPalletList()} />
        </TableCell>
        <TableCell className={"search-item__detail-cell"}>{stringValue(props.parcel, "operationCode")}</TableCell>
        <TableCell className={"search-item__detail-cell"}>{stringValue(props.parcel, "batchId")}</TableCell>
        <TableCell className={"search-item__detail-cell"} data-testid="parcel-id">{stringValue(props.parcel, "id")}</TableCell>
        <TableCell className={"search-item__detail-cell"}>{stringValue(props.parcel, "deliveryOrderId")}</TableCell>
        <TableCell className={"search-item__detail-cell"}>{stringValue(props.parcel, "orderId")}</TableCell>
        <TableCell className={"search-item__detail-cell search-item__detail-cell--wrap"}>{stringValue(props.parcel, "parcelTracker")}</TableCell>
        <TableCell className={"search-item__detail-cell"}>{stringValue(props.parcel, "zipCode")}</TableCell>
        <TableCell className={"search-item__detail-cell"}>{stringValue(props.parcel, "weight")}</TableCell>
        {props.additionalDataFields.map(additionalDataItem => {
            const additionalValue = (props.parcel as any)[additionalDataItem];
            return <TableCell  className={"search-item__detail-cell"} key={additionalDataItem}>
                {additionalValue || ""}
            </TableCell>;
        })}
    </TableRow>;
};

const PalletContentsEmpty = (props: IPalletContentsEmptyProps) => {
    return <TableRow key={"empty-pallet-"}
                     className="search-item__detail-line search-item__detail-line--empty"
                     data-testid={`pallet-${props.palletCode}-parcel-empty`}>
        <TableCell colSpan={8} className={"search-item__detail-cell search-item__detail-cell--empty"}>
            {t("palletList.emptyPallet")}
        </TableCell>
    </TableRow>;
};

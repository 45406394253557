import * as React from "react";
import { Checkbox } from "@mui/material";

import "./style.scss";

interface ISearchListItemSelectorProps {
    onToggle: (value: boolean) => void;
    isSelected: boolean;
}

export const SearchListItemSelector = (props: ISearchListItemSelectorProps) => {
    return <div onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
        <Checkbox
            color="primary"
            className="search-list-checkbox"
            onChange={() => props.onToggle(!props.isSelected)}
            checked={props.isSelected}
            data-testid={`list-checkbox-${props.isSelected}`}
        />
    </div>;
};

import { httpFileRequest, httpGet, httpGetList, httpPatch, httpPost } from "../../common/network/fetch";

import { AddressFull } from "../model/address-full";
import { AddressWarehouseFull } from "../model/address-warehouse-full";
import { DeliveryOrderDetailFull } from "../model/delivery-order-detail-full";
import { DeliveryOrderFull } from "../model/delivery-order-full";
import { EanUpdateResponse, IEanUpdateResponse } from "../model/ean-update-response";
import { LabelError } from "../model/label-error";
import {
    ISupplierReferenceUpdateResponse,
    SupplierReferenceUpdateResponse,
} from "../model/supplier-reference-update-response";
import { IProductName, ProductName } from "../model/product-name";
import { IProductNameUpdateResponse, ProductNameUpdateResponse } from "../model/product-name-update-response";
import { IProductComponent, ProductComponent } from "../model/product-component";
import { IOperationProduct, OperationProduct } from "../model/operation-product";

class AdminService {

    private static readonly maxLabelErrorsPerPage = 500;

    public async reopenBatch(
        operationCode: string, batchId: number) {
        await httpPatch({
            url: `/operations/${operationCode}/batches/${batchId}/reopen`});
    }

    public async closeBatch(
        operationCode: string, batchId: number) {
        await httpPatch({
            url: `/operations/${operationCode}/batches/${batchId}/close`});
    }

    public async loadDeliveryOrderFull(operationCode: string, deliveryOrderId: number) {
        const response = await httpGet<DeliveryOrderFull>({
            url: `/admin/operations/${operationCode}/deliveryOrders/${deliveryOrderId}`});
        return new DeliveryOrderFull(response);
    }

    public async loadDeliveryOrderFullByParcelById(operationCode: string, parcelId: number) {
        const response = await httpGet<DeliveryOrderFull>({
            url: `/admin/operations/${operationCode}/deliveryOrders/parcel/${parcelId}`});
        return new DeliveryOrderFull(response);
    }

    public async loadLabelErrorList(operationCode?: string) {
        let route = "/admin/labelErrors/";
        if (operationCode) {
            route += `operations/${operationCode}`;
        }
        route += `?limit=${AdminService.maxLabelErrorsPerPage}`;
        const response = await httpGetList<LabelError>({url: route});
        return response.map(error => new LabelError(error));
    }

    public async updateShippingAddress(
        operationCode: string, batchId: number, deliveryOrderId: number, shippingAddress: AddressFull) {
        await httpPatch({
            url: `/admin/operations/${operationCode}/batches/${batchId}/deliveryOrders/${deliveryOrderId}/shippingAddress`,
            data: shippingAddress});
    }

    public async updateReturnAddress(
        operationCode: string, batchId: number, deliveryOrderId: number, returnAddress: AddressWarehouseFull) {
        await httpPatch({
            url: `/admin/operations/${operationCode}/batches/${batchId}/deliveryOrders/${deliveryOrderId}/returnAddress`,
            data: returnAddress});
    }

    public async updateEans(operationCode: string, eans: { [key: string]: string }) {
        return new EanUpdateResponse(
            await httpPatch<IEanUpdateResponse>({url: `/admin/operations/${operationCode}/eans`,
            data: eans}),
        );
    }

    public async updateSupplierReferences(operationCode: string, supplierReferences: { [key: string]: string }) {
        return new SupplierReferenceUpdateResponse(
            await httpPatch<ISupplierReferenceUpdateResponse>({
                url: `/admin/operations/${operationCode}/supplierReferences`,
                data: supplierReferences}),
        );
    }

    public async updateProductNames(operationCode: string, productNames: { [key: string]: string }) {
        return new ProductNameUpdateResponse(
            await httpPatch<IProductNameUpdateResponse>({
                url: `/admin/operations/${operationCode}/productNames`,
                data: productNames}),
        );
    }

    public async synchroniseOperation(operationCode: string, warehouseId: string) {
        return await httpPost({
            url: `/admin/synch/operation/${operationCode}/warehouse/${warehouseId}`}, false);
    }

    public async duplicateOperation(operationCode: string, warehouseId: string) {
        return await httpPost<string>({
            url: `/admin/operations/${operationCode}/duplicate/${warehouseId}`,
        });
    }

    public async getTestLabel(operationCode: string, parcelId: number) {
        return await httpFileRequest("GET", `/admin/operation/${operationCode}/deliveryOrders/parcel/${parcelId}/testlabel`);
    }

    public async updateDeliveryOrderDetail(
        operationCode: string,
        batchId: number,
        deliveryOrderDetailFull: DeliveryOrderDetailFull) {
        await httpPatch({url: `/admin/operations/${operationCode}/batches/${batchId}/deliveryOrders/${deliveryOrderDetailFull.deliveryOrderId}/` +
            `deliveryOrderDetails/${deliveryOrderDetailFull.id}`,
            data: { quantityOverShipped: deliveryOrderDetailFull.quantityOverShipped },
        });
    }

    public async getAllProducts(operationCode: string) {
        return (await httpGetList<IProductName>({url: `/admin/operations/${operationCode}/product/list`}))
            .map(p => new ProductName(p));
    }

    public async getProductComponents(operationCode: string) {
        return (await httpGetList<IProductComponent>({url: `/component-product/operation/${operationCode}/export`}))
            .map(p => new ProductComponent(p));
    }

    public async updateProductComponents(operationCode: string, requestBody: IProductComponent[]) {
        return await httpPatch({
            url: `/component-product/operation/${operationCode}/import`,
            data: requestBody,
        });
    }

    public async getOperationProducts(operationCode: string) {
        return (await httpGetList<IOperationProduct>({ url: `/operation-product/operation/${operationCode}/export` }))
            .map(op => new OperationProduct(op));
    }

    public async updateOperationProductsMonoParcel(operationCode: string, requestBody: Array<{ productId: number, monoParcel: boolean }>) {
        return await httpPatch({
            url: `/operation-product/operation/${operationCode}/import`,
            data: requestBody,
        });
    }

    public async getOperationCodesForWarehouse(warehouseId: string) {
        return await httpGetList<string>({ url: `/operations/warehouse/${warehouseId}/code/list` });
    }
}

export const adminService = new AdminService();

import React, { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from "react";
import { SynchronizedHistory } from "mobx-react-router";
import { User } from "../../../../context/user";
import { IWarehouseContextDispatcherPayload, WarehouseContextReducer } from "./warehouse-context-reducer";
import { useContextTypeSafe } from "../../../../common/hooks/use-context-type-safe";

interface IWarehouseContextProviderProps {
    routing: SynchronizedHistory;
    user: User;
    children: ReactNode;
}

export interface IWarehouseContext {
    code: string | undefined;
    country: string | undefined;
}

const defaultWarehouseContext: IWarehouseContext = {
    code: undefined,
    country: undefined,
};

const WarehouseContext = createContext<IWarehouseContext>(defaultWarehouseContext);
const WarehouseContextDispatcher = createContext<Dispatch<IWarehouseContextDispatcherPayload> | null>(null);

export const WarehouseContextProvider = (props: IWarehouseContextProviderProps) => {
    const [warehouseContext, warehouseContextDispatcher] = useReducer(WarehouseContextReducer, defaultWarehouseContext);

    useEffect(() => {
        if (props.user.isSupplier) {
            warehouseContextDispatcher({
                action: "initialize",
                user: props.user,
            });
        }
    }, []);

    return <WarehouseContext.Provider value={warehouseContext}>
        <WarehouseContextDispatcher.Provider value={warehouseContextDispatcher}>
            {props.children}
        </WarehouseContextDispatcher.Provider>
    </WarehouseContext.Provider>;
};

export const useWarehouseContext = () => {
    return useContext(WarehouseContext);
};

export const useWarehouseDispatcher = () => {
    return useContextTypeSafe(WarehouseContextDispatcher);
};

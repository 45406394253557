import { RefObject, useEffect, useState } from "react";

export type CameraStreamState = "initializing" | "playing" | "refused";

export const useCameraStream = (elementRef: RefObject<HTMLVideoElement>, cameraDeviceId: string, onError?: (message: string) => void) => {
    const [cameraStreamState, setCameraStreamState] = useState<CameraStreamState>("initializing");
    const [cameraStream, setCameraStream] = useState<MediaStream | undefined>(undefined);

    useEffect(() => {
        (async () => {
            try {
                setCameraStreamState("initializing");
                if (!elementRef.current) {
                    return;
                }

                if (cameraStream) {
                    cameraStream.getTracks().forEach(track => track.stop());
                }

                setCameraStream(await navigator
                    .mediaDevices
                    .getUserMedia({
                        video: {
                            deviceId: {
                                exact: cameraDeviceId,
                            },
                        },
                    }));

                setCameraStreamState("playing");
            } catch (err) {
                if (onError) {
                    onError(`${err.name} ${err.message}`);
                }
                setCameraStreamState("refused");
            }
        })();

        return () => {
            if (cameraStream) {
                cameraStream.getTracks().forEach(track => track.stop());
            }

            if (elementRef.current?.srcObject) {
                elementRef.current.srcObject = null;
                elementRef.current.removeAttribute("src");
            }
        };
    }, [elementRef, cameraDeviceId]);

    useEffect(() => {
        if (!cameraStream || !elementRef.current) {
            return;
        }

        elementRef.current.srcObject = cameraStream;
    }, [cameraStream]);

    return cameraStreamState;
};

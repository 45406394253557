import React, { useEffect, useState } from "react";
import { Grid, Paper, TextField, ThemeProvider, Typography } from "@mui/material";
import { VPUITheme } from "../../../common/theme/vpui-theme";
import { t } from "i18next";
import { businessDocumentationService } from "../../services/business-documentation-service";
import { BusinessDocumentation, BusinessDocumentationFileType } from "../../model/business-documentation";
import { VideoPlayerDialog } from "./video-player-dialog";
import { PdfViewerDialog } from "./pdf-viewer-dialog";
import { chain } from "lodash";
import { BusinessDocumentationCategory } from "./business-documentation-category";
import { BusinessDocumentationItem } from "./business-documentation-item";

import "./style.scss";

export const BusinessDocumentationList = () => {
    const [businessDocumentationList, setBusinessDocumentationList] = useState<BusinessDocumentation[]>([]);
    const [searchedText, setSearchedText] = useState<string>("");
    const [activeLink, setActiveLink] = useState<string>("");
    const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);
    const [pdfViewerOpen, setPdfViewerOpen] = useState(false);

    const getFilteredElements = () => {
        if (!searchedText) {
            return businessDocumentationList;
        }

        return businessDocumentationList.filter(bd => bd.title.toLowerCase().includes(searchedText.toLowerCase()));
    };

    const filteredElementList: BusinessDocumentation[] = getFilteredElements();

    const handleBusinessDocumentationListLoad = async () => {
        setBusinessDocumentationList(await businessDocumentationService.list());
    };

    const onDocumentationOpen = (selected: BusinessDocumentation) => {
        setActiveLink(selected.link);

        switch (selected.fileType) {
            case BusinessDocumentationFileType.Video:
                setVideoPlayerOpen(true);
                return;
            case BusinessDocumentationFileType.Pdf:
                setPdfViewerOpen(true);
                return;
        }
    };

    useEffect(() => {
        (async () => {
            await handleBusinessDocumentationListLoad();
        })();
    }, []);

    return <ThemeProvider theme={VPUITheme}>
        <Paper elevation={0} className={"business-documentation"}>
            <div className={"business-documentation__header"}>
                <Typography className={"business-documentation__header-label"} variant={"h2"}>{t("businessDocumentation.list.title")}</Typography>
                <TextField className={"business-documentation__header-search-input"} variant={"outlined"} size={"small"} label={t("businessDocumentation.list.search")}
                           onChange={event => setSearchedText(event.target.value)}/>
            </div>
            <div className={"business-documentation__content"}>
                <div>
                    {
                        filteredElementList && chain(filteredElementList)
                            .orderBy("category")
                            .groupBy("category")
                            .map((value, key) => ({ category: key, items: value }))
                            .value()
                            .map(categoryItem =>
                                <BusinessDocumentationCategory key={categoryItem.category} category={categoryItem.category}>
                                    {categoryItem.items.map(item =>
                                        <Grid key={item.id.toString()} item xs={2} sm={2} md={2}>
                                            <BusinessDocumentationItem data={item} onOpen={onDocumentationOpen} />
                                        </Grid>)}
                                </BusinessDocumentationCategory>)
                    }
                </div>
            </div>
            <div>
                {videoPlayerOpen && <VideoPlayerDialog link={activeLink} onClose={() => setVideoPlayerOpen(false)} />}
                {pdfViewerOpen && <PdfViewerDialog link={activeLink} onClose={() => setPdfViewerOpen(false)} />}
            </div>
        </Paper>
    </ThemeProvider>;
};

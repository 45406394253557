import * as React from "react";
import { FormControl, FormLabel, Typography } from "@mui/material";
import { ReactNode, ReactNodeArray } from "react";

import "./style.scss";

interface ISettingsFieldItemProps {
    id: string;
    label: React.ReactNode;
    labelIcon?: React.ReactNode;
    required?: boolean;
    disabled?: boolean;
    children: ReactNode | ReactNodeArray;
}

export const SettingsFieldItem = (props: ISettingsFieldItemProps) => {
    return <div>
        <FormControl disabled={props.disabled ?? false} className={"settings-field__container"}>
            <FormLabel htmlFor={props.id} className={"settings-field__label"}>
                <Typography>
                    {props.label}
                </Typography>
                {props.labelIcon}
            </FormLabel>
            {props.children}
        </FormControl>
    </div>;
};

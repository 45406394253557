import { t } from "i18next";

class MandatoryValidator implements IValidator {
    public validate(valueToCheck: string | undefined): string | undefined {
        if (valueToCheck === undefined || valueToCheck === null || valueToCheck === "") {
            return t("error.general.mandatory.field");
        }
        return;
    }
}

export const mandatoryValidator = new MandatoryValidator();

import { Guid } from "guid-typescript";
import { NotificationLevel } from "./notification-with-delivery-details";
import moment from "moment";

export interface IUserNotification {
    id: string;
    createdBy: string;
    createdAt: string;
    level: string;
    title: string;
    message: string;
}

export class UserNotification {
    private readonly _id: Guid;
    private readonly _createdBy: string;
    private readonly _createdAt: moment.Moment;
    private readonly _level: NotificationLevel;
    private readonly _title: string;
    private readonly _message: string;

    public constructor(data: IUserNotification) {
        this._id = Guid.parse(data.id);
        this._createdBy = data.createdBy;
        this._createdAt = moment(data.createdAt);
        this._level = this.buildNotificationLevel(data.level);
        this._title = data.title;
        this._message = data.message;
    }

    get id(): Guid {
        return this._id;
    }

    get createdBy(): string {
        return this._createdBy;
    }

    get createdAt(): moment.Moment {
        return this._createdAt;
    }

    get level(): NotificationLevel {
        return this._level;
    }

    get title(): string {
        return this._title;
    }

    get message(): string {
        return this._message;
    }

    private buildNotificationLevel(value: string) {
        switch (value) {
            case "Warning":
                return NotificationLevel.Warning;
            case "Critical":
                return NotificationLevel.Critical;
            default:
                return NotificationLevel.Information;
        }
    }
}

import * as React from "react";

import { ParcelList } from "../parcel-list/parcel-list";
import { inject, observer } from "mobx-react";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { Parcel } from "../../../model/parcel";
import { ParcelDetail } from "../../../model/parcel-detail";
import { ParcelFilter } from "../../../model/parcel-filter";
import { ParcelPrepareShippingHeader } from "./parcel-prepare-shipping-header";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { context } from "../../../../context/context";
import {
    ParcelStatusType,
    PrepareParcelShippingActionType,
} from "../../../constants/constants";
import { ParcelDataExporter } from "../parcel-list/parcel-data-exporter";
import { Permission } from "../../../../context/permission";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { parcelProcessingValidator } from "../parcel-list/parcel-processing-validator";
import { notEligibleForPalletFlowValidator } from "../../../../common/component/action/action-select/not-eligible-for-pallet-flow-validator";
import { eligibleForPalletFlowValidator } from "../../../../common/component/action/action-select/eligible-for-pallet-flow";
import { parcelsService } from "../../../services/parcels";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { palletService } from "../../../services/pallet";
import { t } from "i18next";
import { PalletAssignmentDialog } from "../pallet-assignment-dialog/pallet-assignment-dialog";
import { SingleActionPicker } from "../../../../common/component/action/action-select/single-action-picker";
import { Sound, useSound } from "../../../../common/hooks/use-sound";
import { IWithRouterProps, withRouter } from "../../../../common/component/hoc/withRouter";

const parcelManagementModel = new SearchListModel<Parcel, ParcelDetail, ParcelFilter>();

interface IParcelPrepareShippingState {
    isPalletAssignmentDialogOpen: boolean;
    palletAssignmentDialogParcels: Parcel[];

    singleActionPickerAnchorElement: HTMLElement | null;
    singleActionPickerParcelItem: Parcel | null;
}

@inject("routing")
@observer
class ParcelPrepareShippingComponent extends ParcelList<IParcelPrepareShippingState> {
    public state: IParcelPrepareShippingState = {
        isPalletAssignmentDialogOpen: false,
        palletAssignmentDialogParcels: [],

        singleActionPickerAnchorElement: null,
        singleActionPickerParcelItem: null,
    };

    constructor(props: IWithRouterProps & IHistoryProps) {
        super(props, parcelManagementModel, new ParcelDataExporter("Prepare parcel shipping", true, true));
    }

    protected get parcelStatusFilter(): ParcelStatusType {
        return "Labeled";
    }

    protected renderActions() {
        return <>
            <ParcelPrepareShippingHeader actionsList={this.actionsList}
                                         reloadParcelsList={() => this.reloadParcelList()} />
            {this.state.isPalletAssignmentDialogOpen &&
                <PalletAssignmentDialog parcelsList={this.state.palletAssignmentDialogParcels}
                                        getParcelItemByScan={parcelId => this.getParcelItemByScan(parcelId)}
                                        onCancel={() => this.closePalletAssignment()}
                                        onSubmit={async (parcelsList, palletCode, closeOnExit) =>
                                            await this.executePalletAssignment(parcelsList, palletCode, closeOnExit)} />}
            {this.state.singleActionPickerAnchorElement &&
                <SingleActionPicker anchorElement={this.state.singleActionPickerAnchorElement}
                                    item={this.state.singleActionPickerParcelItem}
                                    list={this.singleActionsList}
                                    onActionComplete={() => this.reloadParcelList()}
                                    onPickerClose={() => this.closeSingleActionPickerMenu()} />}
        </>;
    }

    protected get actionsList(): Array<IActionCommand<Parcel, PrepareParcelShippingActionType>> {
        return [
            {
                execute: async parcelList => await this.executeMarkReadyToShip(parcelList),
                label: "parcelPrepareShipping.action.markReadyToShip.title",
                loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
                value: "markReadyToShip",
                description: "parcelPrepareShipping.action.markReadyToShip.description",
                paramValidator: parcelProcessingValidator,
                actionEligibilityValidator: notEligibleForPalletFlowValidator,
            },
            {
                execute: parcelList => this.openPalletAssignmentForSelection(parcelList),
                hideActionProgressLoader: true,
                executeCustomScanMode: () => this.openPalletAssignmentForScan(),
                label: "parcelPrepareShipping.action.assignToPallet.title",
                loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
                value: "assignToPallet",
                description: "parcelPrepareShipping.action.assignToPallet.description",
                paramValidator: parcelProcessingValidator,
                actionEligibilityValidator: eligibleForPalletFlowValidator,
                hideExecutionConfirmation: true,
            },
            this.getPrintLabelAction("parcelPrepareShipping"),
            this.getPrintPreparationOrderAction("parcelPrepareShipping"),
            this.getPrintPreparationOrderAndLabelAction("parcelPrepareShipping"),
            this.getCustomDocumentAction("parcelPrepareShipping"),
            {
                execute: async parcelList => await this.executeCancelParcel(parcelList),
                label: "parcelPrepareShipping.action.cancel.title",
                loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
                value: "cancel",
            }];
    }

    protected openSingleActionPickerMenu(anchor: any, item: Parcel) {
        this.setState({
            singleActionPickerAnchorElement: anchor,
            singleActionPickerParcelItem: item,
        });
    }

    private closeSingleActionPickerMenu() {
        this.setState({
            singleActionPickerParcelItem: null,
            singleActionPickerAnchorElement: null,
        });
    }

    private async executeMarkReadyToShip(parcelList: Parcel[]) {
        const shippedResult = await parcelsService.markParcelsAsReadyToBeShipped(
            this.props.params.operationCode,
            +this.props.params.batchId,
            parcelList.map(p => p.id));

        await this.reloadParcelList();
        notificationModel.addSuccessMessage(
            this.getSuccessMessage("markReadyToShip", shippedResult.parcelsValid.length));
    }

    private openPalletAssignmentForSelection(identifierList: Parcel[]) {
        this.setState({
            isPalletAssignmentDialogOpen: true,
            palletAssignmentDialogParcels: identifierList,
        });
    }

    private openPalletAssignmentForScan() {
        this.setState({
            isPalletAssignmentDialogOpen: true,
            palletAssignmentDialogParcels: [],
        });
    }

    private closePalletAssignment() {
        this.setState({
            isPalletAssignmentDialogOpen: false,
            palletAssignmentDialogParcels: [],
        });
    }

    private async executePalletAssignment(parcelList: Parcel[], palletCode: string, closeOnExit: boolean) {
        let failedAssignmentsCount = 0;
        for (const parcel of parcelList) {
            try {
                await this.assignOrReassignParcelToPallet(palletCode, parcel);
            } catch (err) {
                failedAssignmentsCount++;
            }
        }
        if (parcelList.length === 1 && failedAssignmentsCount === 0) {
            notificationModel.addSuccessMessage(t(`components.pallet.assignment.successSingle`, {
                palletCode,
                parcelId: parcelList[0].id,
            }));
        } else if (failedAssignmentsCount !== parcelList.length) {
            notificationModel.addSuccessMessage(t(`components.pallet.assignment.successMultiple`, {
                palletCode,
                parcelCount: parcelList.length - failedAssignmentsCount,
            }));
        }

        if (failedAssignmentsCount) {
            useSound(Sound.Error);
        }

        if (closeOnExit) {
            this.closePalletAssignment();
        }

        await this.reloadParcelList();
    }

    private async assignOrReassignParcelToPallet(palletCode: string, parcel: Parcel) {
        return parcel.palletCode === undefined ?
            await palletService.assignParcelToPallet(palletCode, parcel.id) :
            await palletService.reassignParcelToPallet(palletCode, parcel.id);
    }

    private async executeCancelParcel(parcelList: Parcel[]) {
        const deleteResult = await parcelsService.deleteParcelList(
            this.props.params.operationCode,
            +this.props.params.batchId,
            parcelList.map(p => p.id));

        await this.reloadParcelList();
        notificationModel.addSuccessMessage(
            this.getSuccessMessage("cancel", deleteResult.parcelsValid.length));
    }

    protected getSuccessMessage(prepareParcelShipmentAction: string, nbProcessed: number) {
        if (nbProcessed === 0) {
            return t(`parcelPrepareShipping.action.${prepareParcelShipmentAction}.noItems`);
        }
        const isMultipleParcels = nbProcessed > 1;
        return t(`parcelPrepareShipping.action.${prepareParcelShipmentAction}.success` + (isMultipleParcels ? "s" : ""),
            { count: nbProcessed });
    }

    protected get hiddenSortFields() {
        return ["shipmentDate"];
    }

    protected get isSelectionEnabled() {
        return context.hasPermission(Permission.FrontWrite);
    }

    protected get isParcelTrackerDisplayed() {
        return true;
    }

    protected get isPalletCodeDisplayed() {
        return true;
    }

    protected get allowOrderPendingCancellationFiltering() {
        return true;
    }

    protected get allowOrderCancellationStatusFiltering() {
        return false;
    }

    protected get allowPalletCodeFiltering() {
        return true;
    }
}

export const ParcelPrepareShipping = withRouter(ParcelPrepareShippingComponent);

import { IBulkParcelUploadImportValidationConstraint } from "../bulk-parcel-upload-import-validator";
import { BulkParcelUploadDataReader } from "../bulk-parcel-upload-data-reader";
import { t } from "i18next";
import { context } from "../../../../../../context/context";
import { Permission } from "../../../../../../context/permission";
import { CarrierCountryGroup } from "../../../../../carrier-country-group";

export class BulkParcelUploadPalletWorkflowConstraint implements IBulkParcelUploadImportValidationConstraint {
    private readonly _validCarrierCountryList: CarrierCountryGroup[];

    public constructor(validCarrierCountryList: CarrierCountryGroup[]) {
        this._validCarrierCountryList = validCarrierCountryList;
    }

    public isEligible() {
        return context.hasPermission(Permission.FeaturePalletWorkflow);
    }

    public validateHeader(headerColumns: string[]) {
        if (headerColumns.filter(c => c === BulkParcelUploadDataReader.columnPalletIndex).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerPalletIndexMissing"));
        }

        if (headerColumns.filter(c => c === BulkParcelUploadDataReader.columnPalletCode).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerPalletCodeMissing"));
        }

        if (headerColumns.filter(c => c === BulkParcelUploadDataReader.columnPalletCarrier).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerPalletCarrierMissing"));
        }

        if (headerColumns.filter(c => c === BulkParcelUploadDataReader.columnPalletCountry).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerPalletCountryMissing"));
        }

        return true;
    }

    public validateLine(lineNumber: number, content: Map<string, string>) {
        if (this.getValueAsString(content, BulkParcelUploadDataReader.columnPalletIndex) === "") {
            return true;
        }

        if (this.getValueAsString(content, BulkParcelUploadDataReader.columnPalletCode) !== "") {
            throw new Error(t("bulkParcelUpload.error.palletizationWithPalletIndexAndCode", {lineNumber}));
        }

        if (this.getValueAsString(content, BulkParcelUploadDataReader.columnParcelId) === "") {
            throw new Error(t("bulkParcelUpload.error.palletizationUnparcelledContentViolation", {lineNumber}));
        }

        if (this.getValueAsString(content, BulkParcelUploadDataReader.columnParcelTracker) === "") {
            throw new Error(t("bulkParcelUpload.error.palletizationWithoutLabelViolation", {
                parcelId: content.get(BulkParcelUploadDataReader.columnParcelId),
            }));
        }

        if (this.getValueAsString(content, BulkParcelUploadDataReader.columnPalletCountry) === "" ||
            this.getValueAsString(content, BulkParcelUploadDataReader.columnPalletCarrier) === "") {
            throw new Error(t("bulkParcelUpload.error.palletizationWithMissingPalletCarrierOrCountry", {
                parcelId: content.get(BulkParcelUploadDataReader.columnParcelId),
            }));
        }

        if (!this._validCarrierCountryList.find(c => content.get(BulkParcelUploadDataReader.columnPalletCarrier) === c.carrier)) {
            throw new Error(t("bulkParcelUpload.error.invalidPalletCarrier", {
                parcelId: content.get(BulkParcelUploadDataReader.columnParcelId),
            }));
        }

        if (!this._validCarrierCountryList.find(c => content.get(BulkParcelUploadDataReader.columnPalletCountry) === c.countryGroupName)) {
            throw new Error(t("bulkParcelUpload.error.invalidPalletCountry", {
                parcelId: content.get(BulkParcelUploadDataReader.columnParcelId),
            }));
        }

        return true;
    }

    private getValueAsString(content: Map<string, string>, key: string): string {
        return content.get(key)?.trim() ?? "";
    }
}

import "./style.scss";

import * as React from "react";
import * as logoSrc from "../../../common/component/img/logo-vente-privee-group.png";

import { inject, observer } from "mobx-react";

import { Form } from "../form/form";
import { IHistoryProps } from "../../navigation/ihistory-props";
import { Loader } from "../loader/loader";
import { LoginForm } from "./login-form";
import { context } from "../../../context/context";
import { Button } from "@mui/material";
import { User } from "../../../context/user";
import { QrCode } from "@mui/icons-material";
import { t } from "i18next";

interface ILoginProps {
    appName: string;
    login: (login: string, password: string) => Promise<{ access_token: string }>;
    defaultRoute: string;
    enableSso?: boolean;
    enableQrLogin?: boolean;
    onQrRequested?: () => void;
    onForgotPasswordRequested?: (userName?: string) => void;
}

@inject("routing")
@observer
export class Login extends Form<LoginForm, IHistoryProps & ILoginProps> {
    constructor(props: IHistoryProps & ILoginProps) {
        super(props, {
            title: props.appName,
            isDefaultEdit: true,
            isCancelEnabled: false,
            isActionInFooter: true,
            renderFooter: () => {
                return <div className="loginFooter">
                    <img className="logo" src={logoSrc} />
                </div>;
            },
        });
    }

    protected getDataKey() {
        return undefined;
    }

    protected async load(_dataKey: any) {
        return new LoginForm();
    }

    protected renderForm(data: LoginForm) {
        return <div className="login">
            {this.getField(data, "login", { isRequired: true })}
            {this.getField(data, "password", { isRequired: true })}
            <div className="spinner">
                <Loader isBackground={true} />
            </div>
        </div>;
    }

    protected renderActions() {
        return <div className="buttonContainer">
            <Button color={"secondary"} variant={"contained"} type={"submit"} autoFocus key="submit-btn">
                {t("login.action.submit")}
            </Button>
            {context.isSsoActive() && this.renderSsoButton()}
            {!context.isSsoActive() && !this.props.enableQrLogin && this.renderForgotPasswordButton()}
            {this.props.enableQrLogin && this.props.onQrRequested && this.renderQrCodeButton()}
        </div>;
    }

    protected renderSsoButton() {
        return <Button variant="contained" onClick={() => this.onSsoButtonClicked()} key="sso-btn">Login with SSO</Button>;
    }

    private renderQrCodeButton() {
        return <Button variant="contained" onClick={() => this.props.onQrRequested!()} key="qr-btn" endIcon={<QrCode />}>Login with QR</Button>;
    }

    private renderForgotPasswordButton() {
        if (!this.props.onForgotPasswordRequested) {
            return <></>;
        }

        return <Button variant="contained" color={"inherit"} onClick={() => this.props.onForgotPasswordRequested!(this.formModel.data.login ?? "")} key="password-reminder">
            {t("login.action.passwordReset")}
        </Button>;
    }

    private getRedirectRoute(): string {
        return this.props.routing.location.state && this.props.routing.location.state.from !== "/login" ?
            this.props.routing.location.state.from : this.props.defaultRoute;
    }

    private getRedirectHref(): string {
        return this.props.routing.createHref({pathname: this.getRedirectRoute()});
    }

    private onSsoButtonClicked() {
        window.location.href = context.getSsoLoginUrl(this.getRedirectHref());
    }

    protected async submitForm(data: LoginForm) {
        const response = await this.props.login(data.login, data.password);

        if (response !== null) {
            const user = new User(data.login, response.access_token, "ds");
            context.setCurrentUser(user);
            await context.initAuthenticatedContextAsync(true);
        }
    }

    protected onSuccess(_reponse: any) {
        this.props.routing.push(this.getRedirectRoute());
    }
}

import * as React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import PublishIcon from "@mui/icons-material/Publish";
import GetAppIcon from "@mui/icons-material/GetApp";
import { t } from "i18next";
import { ChangeEvent } from "react";
import { inject, observer } from "mobx-react";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel, IconButton,
    ThemeProvider,
    Radio,
    RadioGroup,
    Typography,
    Checkbox,
} from "@mui/material";
import { bulkParcelUploadModel } from "./bulk-parcel-upload-model";
import { ProgressDrawer } from "../../../../common/component/progress-drawer/progress-drawer";
import { ProgressDrawerSummary } from "../../../../common/component/progress-drawer/progress-drawer-summary";
import { ProductExportStrategy } from "./export/product-export-strategy";
import { ParcelExportStrategy } from "./export/parcel-export-strategy";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import { Close } from "@mui/icons-material";
import { operationContext } from "../../../../context/operation-context";
import { autorun, IReactionDisposer } from "mobx";

import "./style.scss";

@inject("routing")
@observer
export class BulkParcelUpload extends React.Component<IHistoryProps, {}> {
    private _autoload: IReactionDisposer;

    public async componentDidMount() {
        this._autoload = autorun(async () => {
            if (operationContext.operation) {
                bulkParcelUploadModel.setOperation(operationContext.operation);
            }

            if (operationContext.batch) {
                bulkParcelUploadModel.setBatchId(operationContext.batch.id);
            } else {
                bulkParcelUploadModel.setBatchId(undefined);
            }
        });
    }

    public componentWillUnmount() {
        if (this._autoload) {
            this._autoload();
        }
    }

    public render() {
        return <ThemeProvider theme={VPUITheme}>
            <div className="bulk-parcel-upload">
                <Paper elevation={0} className="bulk-parcel-upload__menu">
                    <Button disabled={!bulkParcelUploadModel.isOperationSelected}
                            className="bulk-parcel-upload__button"
                            size="large" variant="contained"
                            data-testid="bpu-export-button"
                            onClick={() => bulkParcelUploadModel.openExportDialog()}>
                        {t("bulkParcelUpload.menu.exportButton")} <GetAppIcon className="bulk-parcel-upload__button-icon"/>
                    </Button>
                    {bulkParcelUploadModel.isOperationSelected && <input
                        accept={bulkParcelUploadModel.uploadFileAllowedTypes.join(",")}
                        data-testid="bpu-file-input"
                        className="bulk-parcel-upload__file-input"
                        id="bulk-parcel-upload-file"
                        onChange={e => this.uploadParcels(e)}
                        type="file"/>}
                    <label htmlFor="bulk-parcel-upload-file">
                        <Button disabled={!bulkParcelUploadModel.isOperationSelected}
                                className={`bulk-parcel-upload__button ${!bulkParcelUploadModel.isOperationAndBatchSelected ? "bulk-parcel-upload__button--disabled" : ""}`}
                                size="large"
                                component="span"
                                variant="contained"
                                data-testid="bpu-import-button">
                            {t("bulkParcelUpload.menu.importButton")} <PublishIcon className="bulk-parcel-upload__button-icon"/>
                        </Button>
                    </label>
                    {!bulkParcelUploadModel.isOperationSelected && <Typography
                        className="bulk-parcel-upload__selector-info"
                        variant="caption"
                        data-testid="bpu-selector-info"
                        gutterBottom>
                        {t("bulkParcelUpload.menu.selectorInfo")}
                    </Typography>}
                </Paper>
                <Dialog
                    onClose={() => bulkParcelUploadModel.closeExportDialog()}
                    open={bulkParcelUploadModel.isExportDialogOpen}
                    data-testid="bpu-export-dialog"
                    className={"bulk-parcel-upload__export-dialog"}>
                    <DialogTitle className="bulk-parcel-upload__export-dialog-element">{t("bulkParcelUpload.export.title")}</DialogTitle>
                    <div className="bulk-parcel-upload__dialog-close">
                        <IconButton onClick={() => bulkParcelUploadModel.closeExportDialog()}>
                            <Close />
                        </IconButton>
                    </div>
                    <DialogContent>
                        <FormControl component="fieldset" className="bulk-parcel-upload__export-dialog-element">
                            <FormLabel component="legend">{t("bulkParcelUpload.export.parcelStrategy.label")}</FormLabel>
                            <RadioGroup row aria-label="parcel-strategy"
                                        name="parcel-strategy"
                                        value={bulkParcelUploadModel.parcelExportStrategy}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => bulkParcelUploadModel.setParcelExportStrategy(e.target.value)}>
                                <FormControlLabel
                                    value={ParcelExportStrategy.ParcelPerOrder.toString()}
                                    data-testid={"bpu-parcel-strategy-parcel-per-order"}
                                    className={bulkParcelUploadModel.parcelExportStrategyIs(ParcelExportStrategy.ParcelPerOrder) ? "js-test-checked" : ""}
                                    control={<Radio/>}
                                    label={t("bulkParcelUpload.export.parcelStrategy.parcelPerOrder")}/>
                                <FormControlLabel
                                    value={ParcelExportStrategy.ParcelPerProduct.toString()}
                                    data-testid={"bpu-parcel-strategy-parcel-per-product"}
                                    className={bulkParcelUploadModel.parcelExportStrategyIs(ParcelExportStrategy.ParcelPerProduct) ? "js-test-checked" : ""}
                                    control={<Radio/>}
                                    label={t("bulkParcelUpload.export.parcelStrategy.parcelPerProduct")}/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl
                            component="fieldset"
                            className="bulk-parcel-upload__export-dialog-element"
                            disabled={bulkParcelUploadModel.parcelExportStrategyIs(ParcelExportStrategy.ParcelPerProduct)}>
                            <FormLabel component="legend">{t("bulkParcelUpload.export.productStrategy.label")}</FormLabel>
                            <RadioGroup row aria-label="product-strategy"
                                        name="product-strategy"
                                        value={bulkParcelUploadModel.productExportStrategy}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => bulkParcelUploadModel.setProductExportStrategy(e.target.value)}>
                                <FormControlLabel
                                    value={ProductExportStrategy.AllPerLine.toString()}
                                    data-testid={"bpu-product-strategy-all-per-line"}
                                    className={bulkParcelUploadModel.productExportStrategyIs(ProductExportStrategy.AllPerLine) ? "js-test-checked" : ""}
                                    control={<Radio/>}
                                    label={t("bulkParcelUpload.export.productStrategy.allPerLine")}
                                />
                                <FormControlLabel
                                    value={ProductExportStrategy.SinglePerLine.toString()}
                                    data-testid={"bpu-product-strategy-single-per-line"}
                                    className={bulkParcelUploadModel.productExportStrategyIs(ProductExportStrategy.SinglePerLine) ? "js-test-checked" : ""}
                                    control={<Radio/>}
                                    label={t("bulkParcelUpload.export.productStrategy.singlePerLine")}/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl
                            component="fieldset"
                            className="bulk-parcel-upload__export-dialog-element"
                            disabled={false}>
                            <FormLabel component="legend">{t("bulkParcelUpload.export.settings.title")}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox checked={bulkParcelUploadModel.exportOperation}
                                          disabled={!bulkParcelUploadModel.isOperationAndBatchSelected}
                                          onChange={(_event, checked) => bulkParcelUploadModel.setExportOperation(checked)} />}
                                              label={t("bulkParcelUpload.export.settings.allBatches")} />
                            <FormControlLabel control={
                                <Checkbox checked={bulkParcelUploadModel.skipShippedOrders}
                                          onChange={(_event, checked) => bulkParcelUploadModel.setSkipShippedOrders(checked)} />}
                                              label={t("bulkParcelUpload.export.settings.skipShippedOrders")} />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained"
                                data-testid="bpu-export-dialog-cancel"
                                onClick={() => bulkParcelUploadModel.closeExportDialog()}
                                key="cancel-btn">{t(`components.popin.cancel`)}</Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            type="submit"
                            data-testid="bpu-export-dialog-confirm"
                            autoFocus key="submit-btn"
                            onClick={async () => await this.handleExportBatch()}>
                            {t(`components.popin.ok`)}
                        </Button>
                    </DialogActions>
                </Dialog>
                <ProgressDrawer
                    onCancel={() => bulkParcelUploadModel.cancelUpload()}
                    onClose={() => bulkParcelUploadModel.cancelUploadAndClose()}
                    current={bulkParcelUploadModel.importer.numberOfProcessedDeliveryOrders + bulkParcelUploadModel.importer.numberOfFailedDeliveryOrders}
                    errors={bulkParcelUploadModel.importer.errorList}
                    total={bulkParcelUploadModel.importer.numberOfDeliveryOrdersToProcess}>
                    <ProgressDrawerSummary
                        total={bulkParcelUploadModel.importer.numberOfDeliveryOrdersToProcess}
                        successful={bulkParcelUploadModel.importer.numberOfProcessedDeliveryOrders}
                        failed={bulkParcelUploadModel.importer.numberOfFailedDeliveryOrders}
                        successfulTitle={t("bulkParcelUpload.progress.ordersSuccessful")}
                        failedTitle={t("bulkParcelUpload.progress.ordersFailed")}
                    />
                </ProgressDrawer>
            </div>
        </ThemeProvider>;
    }

    private async uploadParcels(e: ChangeEvent<HTMLInputElement>) {
        const file = e.target!.files![0];
        e.target.value = "";
        await bulkParcelUploadModel.uploadFile(file);
    }

    private async handleExportBatch() {
        await bulkParcelUploadModel.exportBatch();
        bulkParcelUploadModel.closeExportDialog();
    }
}

import * as React from "react";

import { IReactionDisposer, reaction } from "mobx";
import { FilterActionType } from "../../../../common/component/list/filter/filter-action-data";
import { FilterData } from "../../../../common/component/list/filter/filter-data";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import InfiniteScroll from "react-infinite-scroller";
import { Parcel } from "../../../model/parcel";
import { ParcelDetail } from "../../../model/parcel-detail";
import { ParcelFilter } from "../../../model/parcel-filter";
import { ParcelListFilter } from "./parcel-list-filter";
import { ParcelListSort } from "./parcel-list-sort";
import { SearchList } from "../../../../common/component/list/search-list/search-list";
import { Table, TableHead, TableBody, ThemeProvider, MenuItem } from "@mui/material";
import { filter, groupBy, map } from "lodash";
import { menuOperationModel } from "../../preparation-menu/preparation-menu-model";
import { parcellingCommand } from "../parcelling/parcelling-command";
import { parcellingModel } from "../parcelling/parcelling-model";
import { ParcelStatusType } from "../../../constants/constants";
import { ParcelDataExporter } from "./parcel-data-exporter";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { ExportDataContents, ExportType } from "../../../model/export-data-contents";
import { excelDataExporter } from "../../../../common/component/list/import-export-list/excel-data-exporter";
import { ExportDataValue} from "../../../model/export-data-item";
import { t } from "i18next";
import { ExcelBackgroundColorRowDecorator } from "../../../../common/component/list/import-export-list/excel-background-color-row-decorator";
import { CancellationRequestMenu } from "../cancellation-request/cancellation-request-menu";
import { CancellationRequestMenuModel } from "../cancellation-request/cancellation-request-menu-model";
import { IParcelListDataProvider } from "./parcel-list-data-provider-interface";
import { ParcelListDefaultDataProvider } from "./parcel-list-default-data-provider";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import { parcelProcessingValidator } from "./parcel-processing-validator";
import { notEligibleForBluebrandValidator } from "../../../../common/component/action/action-select/not-eligible-for-bluebrand-validator";
import { labelProvider } from "../printer/document-provider/label-provider";
import { customDocumentPrinterModel } from "../printer/custom-document-printer-model";
import { preparationOrderProvider } from "../printer/document-provider/preparation-order-provider";
import { documentPrinter } from "../printer/document-printer";
import { deliverySlipProvider } from "../printer/document-provider/delivery-slip-provider";
import { IParcelDocumentProvider } from "../printer/document-provider/parcel-document-provider-interface";
import { pdfLabelProvider } from "../printer/document-provider/pdf-label-provider";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { QuickFilterSelect } from "../../../../common/component/quick-filter/quick-filter-select";
import { getFieldLabel } from "../../../../common/field/renderer";
import { QuickFilterOption } from "../../../../common/component/quick-filter/quick-filter-option";
import { DeliveryOrderDetailType } from "../../../model/delivery-order-detail-type";
import { context } from "../../../../context/context";
import { Permission } from "../../../../context/permission";
import { ParcelListLine } from "./parcel-list-line";
import { ExportMenu } from "../../../../common/component/list/import-export-list/export-menu";
import { IWithRouterProps } from "../../../../common/component/hoc/withRouter";
import { matchPath } from "react-router-dom";

import "./style.scss";

type ParcelAction = "label" | "preparationOrder" | "preparationOrderAndLabel" | "preparationOrderAndLabelOnOnePage" | "printSlip";
type PageName = "parcelManagement" | "parcelShipment" | "parcelPrepareShipping" | "parcelShipped";

export abstract class ParcelList<TState extends {}> extends
    SearchList<IWithRouterProps & IHistoryProps, Parcel, ParcelDetail, ParcelFilter, TState> {

    private autoOpenParcelling: IReactionDisposer;
    private autoReloadCancellationRequestMenuModel: IReactionDisposer;
    protected prefixLabel: string = "model.parcel.";

    private cancellationRequestMenuModel = new CancellationRequestMenuModel(this.props.params.operationCode, Number(this.props.params.batchId));
    protected defaultQuickFilterFields = ["carrier"] as Array<keyof ParcelFilter & keyof Parcel>;

    private readonly _customDataProvider?: IParcelListDataProvider;
    private readonly _dataExporter: ParcelDataExporter;

    protected constructor(props: IWithRouterProps & IHistoryProps,
                          searchListModel: SearchListModel<Parcel, ParcelDetail, ParcelFilter>,
                          dataExporter: ParcelDataExporter,
                          customDataProvider?: IParcelListDataProvider) {
        super(props, searchListModel);
        this._dataExporter = dataExporter;
        this._customDataProvider = customDataProvider;
    }

    protected get listIdentifier() {
        return `${this.props.params.operationCode}/${this.props.params.batchId}`;
    }

    protected loadDefaultFilter() {
        return new ParcelFilter();
    }

    protected get sortAndFilterColumnsToIgnore() {
        return [
            "parcelCount",
            "isPendingCancellation",
            this.allowOrderCancellationStatusFiltering ? "isOrderPendingCancellation" : "orderNonRejectedCancellationRequestStatus",
            "isContainingProductSet",
            !this.isPalletCodeDisplayed ? "palletCode" : "",
        ];
    }

    protected async loadList() {
        return await (this._customDataProvider ?? new ParcelListDefaultDataProvider(this.parcelStatusFilter))
            .load(this.props.params.operationCode, Number(this.props.params.batchId));
    }

    protected abstract get actionsList(): Array<IActionCommand<Parcel, any>>;
    protected abstract openSingleActionPickerMenu(anchor: any, parcel: Parcel): void;

    public async componentWillMount() {
        await super.componentWillMount();

        await this.openParcellingPage(this.props.params.deliveryOrderId);
        this.autoOpenParcelling = reaction(
            () => this.props.params.deliveryOrderId,
            (deliveryOrderId?: string) => this.openParcellingPage(deliveryOrderId),
        );

        this.autoReloadCancellationRequestMenuModel = reaction(
            () => this.listIdentifier,
            () => {
                this.cancellationRequestMenuModel = new CancellationRequestMenuModel(this.props.params.operationCode,
                    Number(this.props.params.batchId));
            },
        );
    }

    private async openParcellingPage(deliveryOrderId?: string) {
        if (deliveryOrderId) {
            await parcellingCommand.openParcellingPage(
                this.props.params.operationCode,
                Number(this.props.params.batchId),
                Number(deliveryOrderId),
                async () => {
                    await this.reloadParcelList();
                    this.props.routing.push(this.getParcellingDetailRoute());
                },
            );
        } else if (parcellingModel.deliveryOrder) {
            parcellingCommand.closeParcelling();
        }
    }
    public async componentWillUnmount() {
        await super.componentWillUnmount();

        if (this.autoOpenParcelling) {
            this.autoOpenParcelling();
        }

        if (this.autoReloadCancellationRequestMenuModel) {
            this.autoReloadCancellationRequestMenuModel();
        }
    }

    protected abstract get parcelStatusFilter(): ParcelStatusType;
    protected abstract get allowOrderPendingCancellationFiltering(): boolean;
    protected abstract get allowOrderCancellationStatusFiltering(): boolean;
    protected get allowPalletCodeFiltering(): boolean {
        return false;
    }

    protected renderFilterContent(currentFilter: FilterData<ParcelFilter, Parcel, ParcelDetail>): JSX.Element {
        return <ParcelListFilter
            initFilter={() => this.loadDefaultFilter()}
            searchListModel={this.searchListModel}
            defaultFilter={currentFilter}
            allowOrderPendingCancellationFiltering={this.allowOrderPendingCancellationFiltering}
            allowOrderCancellationStatusFiltering={this.allowOrderCancellationStatusFiltering}
            allowPalletCodeFiltering={this.allowPalletCodeFiltering}
        />;
    }

    protected get singleActionsList() {
        return this.actionsList.filter(a => !a.disableSingleActionPicker)
            .filter(a => a.actionEligibilityValidator === undefined || a.actionEligibilityValidator.validate());
    }

    protected renderLine(parcel: Parcel) {
        return <ParcelListLine parcel={parcel}
                               searchListModel={this.searchListModel}
                               isExpanded={true}
                               withSingleActionMenu={this.singleActionsList.length > 0}
                               onSingleActionMenuClick={anchor => this.openSingleActionPickerMenu(anchor, parcel)}
                               onParcellingPageOpen={() =>
                                   this.props.routing.push(this.getParcellingDetailRoute(parcel.deliveryOrderId))}
                               onCancellationRequestMenuOpen={anchor =>
                                   this.cancellationRequestMenuModel.openMenuForElementAndDeliveryOrder(parcel.deliveryOrderId, anchor)} />;
    }

    private getParcellingDetailRoute(deliveryOrderId?: number) {
        const match = matchPath("operations/:site/:operationCode/:batchId/:deliveryOrderId", this.props.location.pathname);

        return deliveryOrderId
            ? this.props.location.pathname.concat(`/${deliveryOrderId}`)
            : this.props.location.pathname.replace(`/${match?.params.deliveryOrderId}`, "");
    }

    protected getParcelListByScan(scannedValue: any): Parcel[] {
        return filter(this.searchListModel.list, parcel => parcel.id === +scannedValue ||
            parcel.parcelTracker === scannedValue.toString());
    }

    protected getParcelItemByScan(scannedValue: any): Parcel | undefined {
        return this.searchListModel.list.find(p => p.id === +scannedValue ||
            p.parcelTracker === scannedValue.toString());
    }

    protected applyQuickFilter(value: any): void {
        if (value) {
            this.searchListModel.applyQuickFilter(this.loadDefaultFilter(), this.quickFilterCondition(value));
        } else {
            this.searchListModel.resetFilter(this.loadDefaultFilter());
        }
    }

    public render() {
        const list = this.getList();
        return <InfiniteScroll
            pageStart={0}
            loadMore={() => this.searchListModel.showMore()}
            hasMore={this.hasMoreElement} >
            <div>
                {this.renderListBar(list.total, this.quickFilterPlaceHolder, this.quickFilterTooltip, "text")}
                <CancellationRequestMenu
                    model={this.cancellationRequestMenuModel}
                    refreshSearchList={() => this.reloadParcelList()} />
                <Table>
                    <TableHead>
                        {this.renderSortBar()}
                    </TableHead>
                    <TableBody className="table-body">
                        <ThemeProvider theme={VPUITheme}>
                            {list.list}
                        </ThemeProvider>
                    </TableBody>
                </Table>
            </div>
        </InfiniteScroll>;
    }

    protected renderExportMenu() {
        const handleMenuClick = (strategy: ExportType) => {
            this.renderExcelExport(this.getFilteredList(), strategy);
        };

        return <ExportMenu>
            <MenuItem onClick={() => handleMenuClick(ExportType.AllColumns)} data-testid="exportAllColumnsMenuItem">
                {t("components.exportList.exportAllColumns")}
            </MenuItem>
        </ExportMenu>;
    }

    protected renderAdditionalQuickFiltering() {
        const filteredDetailParcelList = this.loadQuickFilterOptions(["isSingleRef", "isMonoRef", "isMultiRef"]);

        return <>
            <QuickFilterSelect title={getFieldLabel(this.searchListModel.filterData.filter, "country")}
                               propertyKey={"country"}
                               loadDefaultValue={() => this.getCurrentFilterValue("country")}
                               onQuickFilterSelect={value => this.onQuickFilterSelected("country", value)}>
                {map(groupBy(this.loadQuickFilterOptions(["country"]), "country"), (values, v) =>
                    <QuickFilterOption count={values.length} value={v}>
                        {t(`components.quickFilter.country.${v}`)}
                    </QuickFilterOption>)}
            </QuickFilterSelect>
            <QuickFilterSelect title={getFieldLabel(this.searchListModel.filterData.filter, "type")}
                               propertyKey={"type"}
                               loadDefaultValue={() => this.loadDeliveryOrderTypeQuickFilterValues()}
                               onQuickFilterSelect={value => this.onDeliveryOrderTypeFilterSelect(value)}>
                {[DeliveryOrderDetailType.SingleReference, DeliveryOrderDetailType.MonoReference,
                    DeliveryOrderDetailType.MultiReference]
                    .map(type => <QuickFilterOption value={type}
                                                    count={this.fetchOrderCountForType(filteredDetailParcelList, type)}>
                        {t(`model.deliveryOrder.detailContentType.${type}`)}
                    </QuickFilterOption>)}
            </QuickFilterSelect>
        </>;
    }

    private fetchOrderCountForType(list: ReadonlyArray<Parcel>, type: DeliveryOrderDetailType) {
        switch (type) {
            case DeliveryOrderDetailType.SingleReference:
            case DeliveryOrderDetailType.MonoReference:
            case DeliveryOrderDetailType.MultiReference:
                return list.filter(e => e.type === type).length;
            default:
                return 0;
        }
    }

    private fetchDetailTypeNameFromFilterField(filterKey: keyof ParcelFilter & (keyof Parcel | keyof ParcelDetail)) {
        switch (filterKey) {
            case "isSingleRef":
                return DeliveryOrderDetailType.SingleReference.toString();
            case "isMonoRef":
                return DeliveryOrderDetailType.MonoReference.toString();
            case "isMultiRef":
                return DeliveryOrderDetailType.MultiReference.toString();
            default:
                return "";
        }
    }

    private loadDeliveryOrderTypeQuickFilterValues() {
        const selectedOptions = this.searchListModel.filterData.filterAction
            .filter(f => ["isSingleRef", "isMonoRef", "isMultiRef"].indexOf(f.filterField) !== -1);

        return selectedOptions.length === 1 ?
            this.fetchDetailTypeNameFromFilterField(selectedOptions[0].filterField) : undefined;
    }

    private onDeliveryOrderTypeFilterSelect(detailType: string) {
        if (!this.searchListModel.filterData.isAndSearch) {
            this.applyQuickFilter("");
        }

        switch (detailType) {
            case DeliveryOrderDetailType.SingleReference.toString():
                this.searchListModel.filterData.removeFilterActionList(["isMonoRef", "isMultiRef"]);
                this.searchListModel.filterData.pushFilterActionData("isSingleRef", "isTrue");
                break;
            case DeliveryOrderDetailType.MonoReference.toString():
                this.searchListModel.filterData.removeFilterActionList(["isSingleRef", "isMultiRef"]);
                this.searchListModel.filterData.pushFilterActionData("isMonoRef", "isTrue");
                break;
            case DeliveryOrderDetailType.MultiReference.toString():
                this.searchListModel.filterData.removeFilterActionList(["isSingleRef", "isMonoRef"]);
                this.searchListModel.filterData.pushFilterActionData("isMultiRef", "isTrue");
                break;
        }
    }

    protected abstract get isParcelTrackerDisplayed(): boolean;
    protected get isPalletCodeDisplayed(): boolean {
        return false;
    }

    protected get quickFilterPlaceHolder() {
        return this.isParcelTrackerDisplayed ? "parcelList.quickFilter.label" :
            "parcelList.quickFilter.labelWithoutParcelTracker";
    }

    protected get quickFilterTooltip() {
        return this.isParcelTrackerDisplayed ? "parcelList.quickFilter.tooltip" :
            "parcelList.quickFilter.tooltipWithoutParcelTracker";
    }

    protected renderSorterHeaderContent() {
        return <ParcelListSort searchListModel={this.searchListModel} hiddenSortFields={this.hiddenSortFields} />;
    }

    protected abstract get hiddenSortFields(): string[];

    private quickFilterCondition(value: any) {
        const conditions: Array<{
            filterKey: keyof ParcelFilter & (keyof Parcel | keyof ParcelDetail),
            filterAction: FilterActionType,
            filterValue: any,
            rowKey?: keyof Parcel,
        }> = +value ? [
            { filterKey: "parcelId", filterAction: "contains", filterValue: +value },
            { filterKey: "deliveryOrderId", filterAction: "contains", filterValue: +value },
            { filterKey: "orderId", filterAction: "contains", filterValue: +value },
            { filterKey: "ean13List", filterAction: "contains", filterValue: value, rowKey: "details" },
            { filterKey: "supplierReference", filterAction: "contains", filterValue: value, rowKey: "details" },
        ] : [
                    { filterKey: "ean13List", filterAction: "contains", filterValue: value, rowKey: "details" },
                    { filterKey: "supplierReference", filterAction: "contains", filterValue: value, rowKey: "details" },
                ];
        if (this.isParcelTrackerDisplayed) {
            conditions.push({ filterKey: "parcelTracker", filterAction: "contains", filterValue: value });
        }
        if (context.hasPermission(Permission.FeaturePalletWorkflow) && this.isPalletCodeDisplayed) {
            conditions.push({ filterKey: "palletCode", filterAction: "equals", filterValue: value });
        }
        return conditions;
    }

    protected async reloadParcelList() {
        this.searchListModel.resetList(await this.loadList());
        await menuOperationModel.refreshCounters(this.props.params.operationCode, +this.props.params.batchId);
    }

    protected getExportData(sortFilterData: ReadonlyArray<Parcel>, exportType: ExportType): ExportDataContents {
        return this._dataExporter.getExportData(
            this.props.params.operationCode,
            Number(this.props.params.batchId),
            this.searchListModel.additionalFields,
            sortFilterData,
            exportType,
        );
    }

    protected renderExcelExport(sortFilterData: ReadonlyArray<Parcel>, exportType: ExportType) {
        const exportData = this.getExportData(sortFilterData, exportType);

        excelDataExporter.export(exportData, [
            this.excelBackgroundDecoratorForExportType(exportType, exportData.headerColumns),
        ]);
    }

    private excelBackgroundDecoratorForExportType(exportType: ExportType, headerColumns: ExportDataValue[]): ExcelBackgroundColorRowDecorator {
        switch (exportType) {
            case ExportType.AllColumns:
                return ExcelBackgroundColorRowDecorator.forHeaderColumn(headerColumns, t("model.parcel.parcelId"));
            default:
                return new ExcelBackgroundColorRowDecorator();
        }
    }
    protected getPrintLabelAction(pageName: PageName): IActionCommand<Parcel, "label"> {
        return {
            execute: async parcelList => await this.executePrintLabel(parcelList),
            hideActionProgressLoader: true,
            label: `${pageName}.action.label.title`,
            loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
            value: "label",
            paramValidator: parcelProcessingValidator,
            actionEligibilityValidator: notEligibleForBluebrandValidator,
        };
    }

    protected getCustomDocumentAction(pageName: PageName): IActionCommand<Parcel, "customDocument"> {
        return {
            execute: async parcelList => await this.executeCustomPrinter(parcelList),
            hideActionProgressLoader: true,
            label: `${pageName}.action.customDocument.title`,
            loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
            value: "customDocument",
            disableScanMode: true,
            hideExecutionConfirmation: true,
            paramValidator: parcelProcessingValidator,
            disableSingleActionPicker: true,
        };
    }

    protected getBatchPrintLabelAction(pageName: PageName): IActionCommand<Parcel, "multipageLabel"> {
        return {
            execute: async parcelList => await this.executeMultipagePrintLabel(parcelList),
            hideActionProgressLoader: true,
            label: `${pageName}.action.multipageLabel.title`,
            loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
            value: "multipageLabel",
            disableScanMode: true,
            paramValidator: parcelProcessingValidator,
            actionEligibilityValidator: notEligibleForBluebrandValidator,
        };
    }

    protected getPrintPreparationOrderAction(pageName: PageName): IActionCommand<Parcel, "preparationOrder"> {
        return {
            execute: async parcelList => await this.executePrintPreparationOrder(parcelList),
            hideActionProgressLoader: true,
            label: `${pageName}.action.preparationOrder.title`,
            loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
            value: "preparationOrder",
            paramValidator: parcelProcessingValidator,
        };
    }

    protected getPrintPreparationOrderAndLabelAction(pageName: PageName): IActionCommand<Parcel, "preparationOrderAndLabel"> {
        return {
            execute: async parcelList => await this.executePrintPreparationOrderAndLabel(parcelList),
            hideActionProgressLoader: true,
            label: `${pageName}.action.preparationOrderAndLabel.title`,
            loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
            value: "preparationOrderAndLabel",
            paramValidator: parcelProcessingValidator,
            actionEligibilityValidator: notEligibleForBluebrandValidator,
        };
    }

    protected getPrintSlipAction(pageName: PageName): IActionCommand<Parcel, "printSlip"> {
        return {
            execute: async parcelList => await this.executePrintSlip(parcelList),
            label: `${pageName}.action.printSlip.title`,
            loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
            value: "printSlip",
        };
    }

    protected loadExecuteActionParam(isScan: boolean, param: any) {
        return isScan ?
            this.getParcelListByScan(param) :
            this.searchListModel.selectedItemsList;
    }

    protected abstract getSuccessMessage(action: ParcelAction, nbProcessed: number): string;

    private async executePrintLabel(parcelList: Parcel[]) {
        if (parcelList && parcelList.length > 0) {
            const nb = (await this.printParcel(parcelList, labelProvider));
            await this.reloadParcelList();
            this.notifyPrintLabelEnded(parcelList.length, nb.nbParcelsPrinted);
        } else {
            throw new Error("No data selected, filter should be done previously.");
        }
    }

    private async executeCustomPrinter(parcelList: Parcel[]) {
        await customDocumentPrinterModel.openCustomPrinterMenu(
            this.props.params.operationCode, +this.props.params.batchId, parcelList,
        );

        await this.reloadParcelList();
    }

    private async executeMultipagePrintLabel(parcelList: Parcel[]) {
        if (parcelList && parcelList.length > 0) {
            await this.printMultipageLabel(parcelList);
            await this.reloadParcelList();
        } else {
            throw new Error("No data selected, filter should be done previously.");
        }
    }

    private async executePrintPreparationOrder(parcelList: Parcel[]) {
        if (parcelList && parcelList.length > 0) {
            const nb = (await this.printParcel(parcelList, preparationOrderProvider));
            this.notifyPrintPreparationOrderEnded(parcelList.length, nb.nbPreparationOrderPrinted);
        } else {
            throw new Error("No data selected, filter should be done previously.");
        }
    }

    private async executePrintPreparationOrderAndLabel(parcelList: Parcel[]) {
        if (parcelList && parcelList.length > 0) {
            const nb = (await documentPrinter.printOrDownloadAllWithProgressDrawer(this.props.params.operationCode,
                +this.props.params.batchId,
                parcelList,
                [
                    preparationOrderProvider,
                    labelProvider,
                ]));
            await this.reloadParcelList();
            this.notifyPrintLabelEnded(parcelList.length, nb.nbParcelsPrinted);
            this.notifyPrintPreparationOrderEnded(parcelList.length, nb.nbPreparationOrderPrinted);
        } else {
            throw new Error("No data selected, filter should be done previously.");
        }
    }

    private async executePrintSlip(parcelList: Parcel[]) {
        if (parcelList && parcelList.length > 0) {
            const summary = await documentPrinter.downloadMultiparcelDocument(this.props.params.operationCode,
                +this.props.params.batchId, parcelList, deliverySlipProvider);

            this.notifyPrintSlipEnded(parcelList.length, summary.nbParcelsPrinted);
        } else {
            throw new Error("No data selected, filter should be done previously.");
        }
    }

    private async printParcel(parcelList: Parcel[], parcelDocumentProvider: IParcelDocumentProvider) {
        return await documentPrinter.printOrDownloadWithProgressDrawer(this.props.params.operationCode,
            +this.props.params.batchId,
            parcelList,
            parcelDocumentProvider);
    }

    private async printMultipageLabel(parcelList: Parcel[]) {
        const summary = await documentPrinter.downloadMultiparcelDocument(this.props.params.operationCode,
            +this.props.params.batchId,
            parcelList,
            pdfLabelProvider,
        );

        if (summary.nbParcelsPrinted > 0) {
            notificationModel.addSuccessMessage(t(`components.customDocumentPrint.success`, {count: summary.nbParcelsPrinted}));
        }

        if (summary.nbFailedParcels > 0) {
            notificationModel.addErrorMessage(t(`components.customDocumentPrint.errors`, {errorsCount: summary.nbFailedParcels}));
        }

        await this.reloadParcelList();
    }

    private notifyPrintLabelEnded(nbParcel: number, nbParcelPrinted: number) {
        const msg = this.getSuccessMessage("label", nbParcelPrinted);
        if (nbParcelPrinted === nbParcel) {
            notificationModel.addSuccessMessage(msg);
        } else {
            notificationModel.addWarningMessage(msg);
        }
    }

    private notifyPrintPreparationOrderEnded(nbPreparationOrder: number, nbPreparationOrderPrinted: number) {
        const msg = this.getSuccessMessage("preparationOrder", nbPreparationOrderPrinted);
        if (nbPreparationOrder === nbPreparationOrderPrinted) {
            notificationModel.addSuccessMessage(msg);
        } else {
            notificationModel.addWarningMessage(msg);
        }
    }

    private notifyPrintSlipEnded(nbParcel: number, nbParcelPrinted: number) {
        const msg = this.getSuccessMessage("printSlip", nbParcelPrinted);
        if (nbParcel === nbParcelPrinted) {
            notificationModel.addSuccessMessage(msg);
        } else {
            notificationModel.addWarningMessage(msg);
        }
    }
}

import { IParamActionValidator } from "../../../../common/component/action/action-select/interface-param-action-validator";
import { Parcel } from "../../../model/parcel";
import { ParamActionValidationResult } from "../../../../common/component/action/action-select/param-action-validation-result";
import { t } from "i18next";
import { DeliveryOrderCancellationRequestStatus } from "../../../model/delivery-order-cancellation-request-status";

class ParcelProcessingValidator implements IParamActionValidator<Parcel> {
    public validate(parcels: Parcel[]) {
        const parcelsToCancel = parcels.filter(p => p.orderCancellationRequestStatus === DeliveryOrderCancellationRequestStatus.Confirmed);
        if (parcelsToCancel.length > 0) {
            return ParamActionValidationResult.error(t("parcelList.validation.processCancelledOrdersError", {
                parcelIdList: parcelsToCancel.map(p => p.id).join(", "),
            }));
        }

        const parcelsPendingToCancel = parcels.filter(p => p.orderCancellationRequestStatus === DeliveryOrderCancellationRequestStatus.Pending);
        if (parcelsPendingToCancel.length > 0) {
            return ParamActionValidationResult.warning(t("parcelList.validation.processCancelledOrdersWarning", {
                parcelIdList: parcelsPendingToCancel.map(p => p.id).join(", "),
            }), parcelsPendingToCancel);
        }

        return ParamActionValidationResult.success(parcels);
    }
}

export const parcelProcessingValidator = new ParcelProcessingValidator();

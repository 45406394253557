import * as React from "react";

import { ParcelList } from "../parcel-list/parcel-list";
import { inject, observer } from "mobx-react";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { Parcel } from "../../../model/parcel";
import { ParcelDetail } from "../../../model/parcel-detail";
import { ParcelFilter } from "../../../model/parcel-filter";
import { ParcelManagementHeader } from "./parcel-management-header";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { context } from "../../../../context/context";
import { ManageParcelActionType, ParcelStatusType } from "../../../constants/constants";
import { ParcelDataExporter } from "../parcel-list/parcel-data-exporter";
import { Permission } from "../../../../context/permission";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { parcelsService } from "../../../services/parcels";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { t } from "i18next";
import { SingleActionPicker } from "../../../../common/component/action/action-select/single-action-picker";
import { LabelAndAssignDialog } from "../label-and-assign/label-and-assign-dialog";
import { eligibleForPalletFlowValidator } from "../../../../common/component/action/action-select/eligible-for-pallet-flow";
import { IWithRouterProps, withRouter } from "../../../../common/component/hoc/withRouter";

const parcelManagementModel = new SearchListModel<Parcel, ParcelDetail, ParcelFilter>();

interface IParcelManagementProps {
    isLabelAndPrintDialogOpen: boolean;
    labelAndPrintDialogParcels: Parcel[];

    singleActionPickerAnchorElement: HTMLElement | null;
    singleActionPickerParcelItem: Parcel | null;
}

@inject("routing")
@observer
class ParcelManagementComponent extends ParcelList<IParcelManagementProps> {
    public state: IParcelManagementProps = {
        isLabelAndPrintDialogOpen: false,
        labelAndPrintDialogParcels: [],

        singleActionPickerAnchorElement: null,
        singleActionPickerParcelItem: null,
    };

    constructor(props: IWithRouterProps & IHistoryProps) {
        super(props, parcelManagementModel, new ParcelDataExporter("Parcel management"));
    }

    protected get parcelStatusFilter(): ParcelStatusType {
        return "New";
    }

    protected renderActions() {
        return <>
            <ParcelManagementHeader actionsList={this.actionsList}
                                    reloadParcelsList={() => this.reloadParcelList()} />
            {this.state.singleActionPickerAnchorElement &&
                <SingleActionPicker anchorElement={this.state.singleActionPickerAnchorElement}
                                    item={this.state.singleActionPickerParcelItem}
                                    list={this.singleActionsList}
                                    onActionComplete={() => this.reloadParcelList()}
                                    onPickerClose={() => this.closeSingleActionPickerMenu()} />}
            {this.state.isLabelAndPrintDialogOpen &&
                <LabelAndAssignDialog operationCode={this.props.params.operationCode}
                                      batchId={+this.props.params.batchId}
                                      parcelList={this.state.labelAndPrintDialogParcels}
                                      getParcelItemByScan={parcelId => this.getParcelItemByScan(parcelId)}
                                      onParcelLabel={() => this.reloadParcelList()}
                                      onCancel={() => this.closeLabelAndAssign()} />}
        </>;
    }

    protected get actionsList(): Array<IActionCommand<Parcel, ManageParcelActionType>> {
        return [
            this.getPrintLabelAction("parcelManagement"),
            {
                label: t("parcelManagement.action.labelAndAssign.title"),
                value: "labelAndAssign" as ManageParcelActionType,
                loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
                execute: async identifierList => this.openLabelAndAssignForSelection(identifierList),
                executeCustomScanMode: () => this.openLabelAnsAssignForScan(),
                hideExecutionConfirmation: true,
                actionEligibilityValidator: eligibleForPalletFlowValidator,
            },
            this.getPrintPreparationOrderAction("parcelManagement"),
            this.getPrintPreparationOrderAndLabelAction("parcelManagement"),
            this.getCustomDocumentAction("parcelManagement"),
            {
                execute: async parcelList => await this.executeCancelParcel(parcelList),
                label: "parcelManagement.action.cancel.title",
                loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
                value: "cancel",
            }];
    }

    protected openLabelAndAssignForSelection(identifierList: Parcel[]) {
        this.setState({
            isLabelAndPrintDialogOpen: true,
            labelAndPrintDialogParcels: identifierList,
        });
    }

    protected openLabelAnsAssignForScan() {
        this.setState({
            isLabelAndPrintDialogOpen: true,
            labelAndPrintDialogParcels: [],
        });
    }

    protected closeLabelAndAssign() {
        this.setState({
            isLabelAndPrintDialogOpen: false,
            labelAndPrintDialogParcels: [],
        });
    }

    protected openSingleActionPickerMenu(anchor: any, item: Parcel) {
        this.setState({
            singleActionPickerAnchorElement: anchor,
            singleActionPickerParcelItem: item,
        });
    }

    private closeSingleActionPickerMenu() {
        this.setState({
            singleActionPickerParcelItem: null,
            singleActionPickerAnchorElement: null,
        });
    }

    private async executeCancelParcel(parcelList: Parcel[]) {
        const deleteResult = await parcelsService.deleteParcelList(
            this.props.params.operationCode,
            +this.props.params.batchId,
            parcelList.map(p => p.id));

        await this.reloadParcelList();
        notificationModel.addSuccessMessage(
            this.getSuccessMessage("cancel", deleteResult.parcelsValid.length));
    }

    protected getSuccessMessage(manageParcelAction: ManageParcelActionType, nbProcessed: number) {
        if (nbProcessed === 0) {
            return t(`parcelManagement.action.${manageParcelAction}.noItems`);
        }
        const isMultiple = nbProcessed > 1;
        return t(`parcelManagement.action.${manageParcelAction}.success` + (isMultiple ? "s" : ""),
            { count: nbProcessed });
    }

    protected get hiddenSortFields() {
        return ["shipmentDate", "parcelTracker"];
    }

    protected get isSelectionEnabled() {
        return context.hasPermission(Permission.FrontWrite);
    }

    protected get isParcelTrackerDisplayed() {
        return false;
    }

    protected get allowOrderPendingCancellationFiltering() {
        return false;
    }

    protected get allowOrderCancellationStatusFiltering() {
        return true;
    }
}

export const ParcelManagement = withRouter(ParcelManagementComponent);

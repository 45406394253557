import { I18nManager } from "../../i18n/i18n-manager";
import { LocaleType } from "../../constants/constants";
import { translationEnGB } from "./en-GB/index";
import { translationEsES } from "./es-ES/index";
import { translationFrFR } from "./fr-FR/index";
import { translationItIT } from "./it-IT/index";

class I18nManagerFrontOffice extends I18nManager {

    protected load(locale: LocaleType) {
        // TODO : decide where the translation must be stored (server, external files,...)
        switch (locale) {
            case "en-GB":
                return translationEnGB;
            case "fr-FR":
                return translationFrFR;
            case "es-ES":
                return translationEsES;
            case "it-IT":
                return translationItIT;
            default:
                throw new Error(`This locale is not supported`);
        }
    }
}

export const i18nManagerFrontOffice = new I18nManagerFrontOffice();

import { useEffect, useState } from "react";

interface IWindowSize {
    width: number;
    height: number;
    isDesktopFullSize: boolean;
    isMobile: boolean;
}

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState<IWindowSize>({
        width: -1,
        height: -1,
        isDesktopFullSize: false,
        isMobile: false,
    });

    useEffect(() => {
        const handleResize = () => setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            isDesktopFullSize: window.innerWidth > 1500,
            isMobile: window.innerWidth < 768,
        });
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}

import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";
import { ImportData } from "../../../../common/component/list/import-export-list/import-data";
import { Icon } from "../../../../common/component/icon/icon";
import { IImportModuleProps } from "./iimport-module-props";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { t } from "i18next";
import { Input } from "../../../../common/component/input/input";
import { includes } from "lodash";
import { FormControl, FormHelperText } from "@mui/material";

@observer
export class SelectFileImport extends React.Component<IImportModuleProps> {
    private typesAllowed = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];

    private async changeFileInput(event: ChangeEvent<HTMLInputElement>) {
        const selectedFile = event.target.files![0];
        notificationModel.removePersistantMessage(0);

        if (!selectedFile || !includes(this.typesAllowed, selectedFile.type)) {
            this.props.importFileModel.changeInputFileSelected(undefined);
            this.props.importFileModel.changeFileImportLabel(t("components.importList.importFileLabel"));
            // Add notification error
            notificationModel.addErrorMessage(t("components.importList.excelFileTypeError"));
        } else {
            const importData = await (new ImportData()).readUploadedFile(selectedFile);

            if (importData.length < 2) {
                notificationModel.addErrorMessage(t("components.importList.excelFileContentError"));
            } else {
                this.props.importFileModel.updateFileImportData(importData);
                this.props.importFileModel.changeFileSelectionError([]);
                this.props.importFileModel.changeInputFileSelected(selectedFile.name);
                this.props.importFileModel.changeFileImportLabel("");
            }
        }
    }

    public render() {
        return <div className="import-step-content">
            <span className={`import-input-file ${this.props.importFileModel.fileSelectionError.length > 0 ? "error-input" : ""}`}>
                <input
                    accept={this.typesAllowed.join(",")}
                    style={{ display: "none" }}
                    id="raised-button-file"
                    type="file"
                    onChange={e => this.changeFileInput(e)}
                    required={true}
                />
                <label htmlFor="raised-button-file">
                    <Icon icon="cloud_upload" color="primary" />
                </label>
            </span>
            <label htmlFor="raised-button-file" className="import-file-label">
                <FormControl error={this.props.importFileModel.fileSelectionError.length > 0} className="input-form">
                    <Input errors={this.props.importFileModel.fileSelectionError}
                        isRequired={true}
                        disabled={true}
                        label={this.props.importFileModel.fileImportLabel}
                        onChange={(newValue: any) => newValue}
                        propertyKey="selectFile"
                        value={this.props.importFileModel.inputFileSelected}
                        inputComponent="textField"
                    />
                    {this.renderError()}
                </FormControl>
            </label>
        </div>;
    }

    private renderError() {
        if (this.props.importFileModel.fileSelectionError.length > 0) {
            return <FormHelperText error={true} data-testid="error-no-selected-file">
                {this.props.importFileModel.fileSelectionError.map(error => t(error)).join(", ")}
            </FormHelperText>;
        }
        return;
    }
}

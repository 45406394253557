import React from "react";
import { ErrorOutline } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { t } from "i18next";
import { CameraListProvider } from "./camera-list-provider";

interface ICameraAccessDeniedNotificationProps {
    onClick?: () => void;
}

export const CameraAccessDeniedNotification = (props: ICameraAccessDeniedNotificationProps) => {
    const handleClick = props.onClick ? () => {
        new CameraListProvider().reset();
        if (props.onClick) {
            props.onClick();
        }
    } : undefined;

    return <div className={"video-loader__icon"} onClick={handleClick}>
        <ErrorOutline color={"error"} className={"video-loader__image"} />
        <Typography className={"video-loader__text"}>
            {props.onClick ?
                t("components.scanAndShip.camera.noAccessWithFallback") :
                t("components.scanAndShip.camera.noAccess")}
        </Typography>
    </div>;
};

import { useEffect } from "react";
import * as moment from "moment/moment";

export const useScanner = (onScanCompleted: (data: string) => void, isEnabled: boolean = true) => {
    let lastTimeKeyPressed: moment.Moment | undefined;
    let scannerValue = "";

    const onKeyDown = (keyboardEvent: KeyboardEvent) => {
        keyboardEvent.stopPropagation();
        keyboardEvent.preventDefault();

        if (lastTimeKeyPressed === undefined || moment().diff(lastTimeKeyPressed, "ms") > 500) {
            scannerValue = "";
            lastTimeKeyPressed = moment();
        }

        if (keyboardEvent.keyCode >= 33 && keyboardEvent.keyCode <= 126) {
            scannerValue += `${keyboardEvent.key}`;
        }
    };

    const onKeyUp = (keyboardEvent: KeyboardEvent) => {
        keyboardEvent.stopPropagation();
        keyboardEvent.preventDefault();

        if (keyboardEvent.keyCode === 13) {
            onScanCompleted(scannerValue);
            scannerValue = "";
        }
    };

    const handleScannerStart = () => {
        window.addEventListener("keydown", onKeyDown);
        window.addEventListener("keyup", onKeyUp);

        return () => handleScannerStop();
    };

    const handleScannerStop = () => {
        window.removeEventListener("keydown", onKeyDown);
        window.removeEventListener("keyup", onKeyUp);
    };

    useEffect(() => {
        return isEnabled ? handleScannerStart() : handleScannerStop();
    }, [isEnabled]);
};

import { RefObject } from "react";
import { useBrowserQrCodeDetector } from "./use-browser-qr-code-detector";
import { useZxingQrCodeDetector } from "./use-zxing-qr-code-detector";

export const useCameraQrCodeScanner = (cameraDeviceId: string,
                                       inputScannerRef: RefObject<HTMLVideoElement>,
                                       onValueScanned: (value: string) => void) => {
    try {
        useBrowserQrCodeDetector(inputScannerRef, onValueScanned);
    } catch (err) {
        useZxingQrCodeDetector(cameraDeviceId, inputScannerRef, onValueScanned);
    }
};

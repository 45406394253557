import "./style.scss";

import * as React from "react";
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Input, InputLabel, Menu, MenuItem, Typography, Icon as IconMaterial } from "@mui/material";
import { action, computed, observable } from "mobx";
import { Icon } from "../../icon/icon";
import { observer } from "mobx-react";
import { t } from "i18next";

export interface ISearchListSelector {
    isAllChecked: boolean;
    selectAction: (active: boolean, limit?: number) => void;
}

@observer
export class SearchListSelector extends React.Component<ISearchListSelector, {}> {

    @observable
    private selectorStrategyMenu: HTMLElement | undefined = undefined;

    @observable
    private selectorLimitPopupOpened: boolean = false;

    @observable
    private _selectorLimit: string = "";

    @computed
    private get selectorLimit(): string {
        return this._selectorLimit;
    }

    @observable
    private selectorLimitErrorMessage: string | undefined = undefined;

    @computed
    private get isSelectorLimitValid(): boolean {
        return this.selectorLimit !== "" && this.selectorLimitErrorMessage === undefined;
    }

    public render() {
        return <div className={"search-selector"}>
            <Checkbox onChange={(_event: React.ChangeEvent<{}>, checked: boolean) => this.props.selectAction(checked)}
                      checked={this.props.isAllChecked}
                      data-testid="list-select-all"
                      color="primary" />
            <IconMaterial data-testid="search-list-strategy-icon"
                          className={"search-selector__strategy-selector"}
                          onClick={(event: any) => this.openSelectorStrategyMenu(event.currentTarget)}
            >
                keyboard_arrow_down
            </IconMaterial>
            <Menu id="search-list-strategy-menu"
                  anchorEl={this.selectorStrategyMenu}
                  open={Boolean(this.selectorStrategyMenu)}
                  onClose={() => this.hideSelectorStrategyMenu()}
            >
                <MenuItem data-testid="search-list-strategy-all" onClick={() => this.selectAllAndHideMenu()}>
                    {t("components.searchList.selectorStrategyAll")}
                </MenuItem>
                <MenuItem data-testid="search-list-strategy-custom" onClick={() => this.openSelectorLimitPopup()}>
                    {t("components.searchList.selectorStrategyCustom")}
                </MenuItem>
            </Menu>
            <Dialog open={this.selectorLimitPopupOpened} className={"search-selector-popup"} data-testid={"search-selector-popup"}>
                <form onSubmit={async event => await this.executeAction(event)}>
                    <div className={"search-selector-popup__container"}>
                        <div className="search-selector-popup__close">
                            <Icon icon="close" onClick={() => this.closeSelectorLimitPopup()} color="primary" />
                        </div>
                        <DialogTitle className={"search-selector-popup__title"}>
                            {t("components.searchList.customSelector.title")}
                        </DialogTitle>

                        <DialogContent className={"search-selector-popup__content"}>
                            <Typography className={"search-selector-popup__description"}>
                                {t("components.searchList.customSelector.description")}
                            </Typography>
                            <InputLabel htmlFor="quantity" className={"search-selector-popup__input-label"}>
                                <Typography className={"search-selector-popup__input-text"}>{t("components.searchList.customSelector.quantity")}</Typography>
                                <Input
                                    autoFocus={true}
                                    data-testid="search-list-custom-quantity"
                                    key={"select-quantity"}
                                    className={"search-selector-popup__input"}
                                    id={"select-quantity"}
                                    onChange={(e: any) => this.setSelectorLimit(e.target.value)}
                                    value={this.selectorLimit}
                                />
                                {this.selectorLimitErrorMessage && <Typography className={"search-selector-popup__error-message"} data-testid={"search-list-custom-error"}>
                                    {this.selectorLimitErrorMessage}
                                </Typography>}
                            </InputLabel>
                        </DialogContent>
                        <div className={"search-selector-popup__submit-container"}>
                            <Button
                                    variant="contained"
                                    className={"search-selector-popup__action-button search-selector-popup__action-button--cancel"}
                                    data-testid="search-list-custom-cancel"
                                    onClick={() => this.closeSelectorLimitPopup()}
                            >
                                {t(`components.popin.cancel`)}
                            </Button>
                            <Button
                                data-testid="search-list-custom-action"
                                color={"primary"}
                                variant={"contained"}
                                type={"submit"}
                                disabled={!this.isSelectorLimitValid}
                                className={"search-selector-popup__action-button search-selector-popup__action-button--submit"}
                                onClick={(event: React.SyntheticEvent<HTMLElement>) => this.executeAction(event)}
                            >
                                {t(`components.popin.ok`)}
                            </Button>
                        </div>
                    </div>
                </form>
            </Dialog>
        </div>;
    }

    @action
    private openSelectorStrategyMenu(element: HTMLElement) {
        this.selectorStrategyMenu = element;
    }

    @action
    private hideSelectorStrategyMenu() {
        this._selectorLimit = "";
        this.selectorLimitErrorMessage = undefined;
        this.selectorStrategyMenu = undefined;
    }

    @action
    private selectAllAndHideMenu() {
        this.props.selectAction(true);
        this.hideSelectorStrategyMenu();
    }

    @action
    private openSelectorLimitPopup() {
        this.selectorLimitPopupOpened = true;
    }

    @action
    private closeSelectorLimitPopup() {
        this.selectorLimitPopupOpened = false;
        this.hideSelectorStrategyMenu();
    }

    @action
    private setSelectorLimit(limit: string) {
        this._selectorLimit = limit;

        if (limit === "") {
            return;
        }

        const numberLimit = Number(limit);
        if (limit !== numberLimit.toString()) {
            this.selectorLimitErrorMessage = t("components.searchList.customSelector.error.invalidNumber");
            return;
        }
        if (numberLimit <= 0) {
            this.selectorLimitErrorMessage = t("components.searchList.customSelector.error.nonPositiveValue");
            return;
        }
        this.selectorLimitErrorMessage = undefined;
    }

    private executeAction(event: React.SyntheticEvent<{}>) {
        event.preventDefault();

        if (!this.isSelectorLimitValid) {
            return;
        }

        this.props.selectAction(true, Number(this.selectorLimit));
        this.closeSelectorLimitPopup();
    }
}

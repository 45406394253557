import { BatchStatusType, OperationCounterType } from "../constants/constants";
import { label100, label50 } from "../../domain/label";

import { assign } from "lodash";
import { localDate } from "../../domain/local-date";
import { domain } from "../../common/dto/dto-annotation";
import { identifier } from "../../domain/identifier";
import { quantity } from "../../domain/quantity";
import { utcDateTime } from "../../domain/utc-date";
import * as moment from "moment";

export class OperationCounter {
    constructor(operationCounter?: OperationCounter) {
        assign(this, operationCounter);

        if (operationCounter !== undefined) {
            this.batchCreationDate = moment(operationCounter.batchCreationDate);
        }
    }

    @domain(label50)
    public operationCode: string;

    @domain(identifier)
    public batchId: number;

    @domain(label50)
    public counterName: OperationCounterType;

    @domain(quantity)
    public counterValue: number;

    @domain(utcDateTime)
    public batchCreationDate: moment.Moment;

    @domain(localDate)
    public logisticCommitmentDate: string;

    @domain(label50)
    public batchStatus: BatchStatusType;

    @domain(label100)
    public warehouseId: string;
}

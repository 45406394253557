import { includes } from "lodash";
import { observable } from "mobx";
import {
    AuthScheme,
    UserRoleType,
    roleAdmin,
    roleBlueBrand,
    roleExposeShippingAddress,
    rolePalletPickUpForm,
    rolePalletWorkflow,
    roleReadyToShipFeature,
    roleSuperAdmin,
    roleSupervisor,
    roleSupplier,
} from "../constants/constants";
import { Permission } from "./permission";

interface ISessionUser {
    name: string;
    token: string;
    ssoToken: string;
    spareToken: string;
    authScheme: AuthScheme;
}

export class User {

    @observable
    private _name: string;

    @observable
    private _impersonationName: string;

    @observable
    public ssoToken: string;

    @observable
    public spareToken: string;

    @observable
    private _token: string;

    @observable
    private _impersonationToken?: string = undefined;

    public permissions: Set<Permission>;

    public authScheme: AuthScheme;

    public roles: UserRoleType[];

    public warehouseCode?: string = undefined;
    public warehouseCountryIso?: string = undefined;

    constructor(name: string = "", token: string = "", ssoToken: string = "", authScheme: AuthScheme = "none", spareToken = "") {
        this._name = name;
        this._token = token;
        this.ssoToken = ssoToken;
        this.spareToken = spareToken;
        this.authScheme = authScheme;
        this.initializeUserAttributes();
    }

    public static unserialize(serialized: string): User {
        const sessionUser  = JSON.parse(serialized) as ISessionUser;
        return new User(sessionUser.name, sessionUser.token, sessionUser.ssoToken, sessionUser.authScheme, sessionUser.spareToken);
    }

    public serialize(): string {
        const sessionUser: ISessionUser = {
            name: this._name,
            token: this._token,
            ssoToken: this.ssoToken,
            spareToken: this.spareToken,
            authScheme: this.authScheme,
        };
        return JSON.stringify(sessionUser);
    }

    public useSpareToken() {
        this._token = this.spareToken;
        this.initializeUserAttributes();
    }

    public get token(): string | undefined {
        return this._impersonationToken ?? this._token;
    }

    public set impersonationToken(impersonationToken: string | undefined) {
        this._impersonationToken = impersonationToken;

        if (impersonationToken) {
            const parsedToken = User.parseUserToken(impersonationToken);
            this._impersonationName = parsedToken.sub;
            this.initializeUserAttributes();
        }
    }

    public get impersonationToken() {
        return this._impersonationToken;
    }

    public get name() {
        return this._impersonationName ?? this._name;
    }

    public get isSuperAdministrator() {
        return includes(this.roles, roleSuperAdmin);
    }

    public get isAdministrator() {
        return includes(this.roles, roleAdmin);
    }

    public get isSupplier() {
        return includes(this.roles, roleSupplier);
    }

    public get isAuthenticatedWithSso() {
        return this.authScheme === "sso";
    }

    private initializeUserAttributes() {
        if (this.token) {
            const parsedToken = User.parseUserToken(this.token);

            this.roles = User.getRolesFromToken(parsedToken);
            this.permissions = User.getPermissionsFromUserRoles(this.roles);
            this.warehouseCode = includes(this.roles, roleSupplier) ? parsedToken.WarehouseId : undefined;
            this.warehouseCountryIso = includes(this.roles, roleSupplier) ? parsedToken.WarehouseCountryIso : undefined;
        }
    }

    private static parseUserToken(token: string): any {
        const splitToken = token.split(".");
        if (splitToken.length !== 3) {
            throw new Error(`Invalid token : "${token}"`);
        }

        return JSON.parse(atob(splitToken[1]));
    }

    private static getRolesFromToken(parsedToken: any): UserRoleType[] {
        if (Array.isArray(parsedToken.roles)) {
            return parsedToken.roles as UserRoleType[];
        }

        const tokenDescription = parsedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

        return `${tokenDescription}`.split(",") as UserRoleType[];
    }

    private static getPermissionsFromUserRoles(roles: UserRoleType[]): Set<Permission> {
        const permissions = new Set<Permission>();

        if (includes(roles, roleAdmin) || includes(roles, roleSuperAdmin)) {
            permissions.add(Permission.BackOffice);
            permissions.add(Permission.FrontRead);
            permissions.add(Permission.FrontWrite);
        } else if (includes(roles, roleSupervisor)) {
            permissions.add(Permission.FrontRead);
        } else if (includes(roles, roleSupplier)) {
            permissions.add(Permission.FrontRead);
            permissions.add(Permission.FrontWrite);
        }

        if (includes(roles, roleReadyToShipFeature)) {
            permissions.add(Permission.FeatureReadyToShip);
        }

        if (includes(roles, roleBlueBrand)) {
            permissions.add(Permission.FeatureBlueBrand);
        }

        if (includes(roles, roleExposeShippingAddress)) {
            permissions.add(Permission.FeatureExposeShippingAddress);
        }

        if (includes(roles, rolePalletPickUpForm)) {
            permissions.add(Permission.FeaturePalletPickUpForm);
        }

        if (includes(roles, rolePalletWorkflow)) {
            permissions.add(Permission.FeaturePalletWorkflow);
        }

        return permissions;
    }
}

import { palletService } from "../../services/pallet";
import { PalletAddParcelCommand } from "./pallet-add-parcel-command";

export class PalletAddParcelHandler {
    public async handle(command: PalletAddParcelCommand) {
        if (command.parcelLocator) {
            await palletService.pushParcelOnPallet(command.pallet.id.toString(), command.parcelLocator);
        } else if (command.parcelId) {
            await palletService.assignParcelToPallet(command.pallet.code, command.parcelId);
        }

        const updatedPallet = command.pallet.id.isEmpty() ?
            await palletService.findPallet(command.pallet.code) :
            await palletService.loadPalletById(command.pallet.id);
        const parcel = updatedPallet?.parcels.find(par =>
            (command.parcelId ? par.matchesParcelLocator(command.parcelId.toString()) : false) ||
            (command.parcelLocator ? par.matchesParcelLocator(command.parcelLocator) : false));
        if (parcel) {
            command.pallet.addParcel(parcel);
        }

        return command.pallet;
    }

    public async handleMultiple(commandList: PalletAddParcelCommand[]) {
        const pallets = [];
        for (const command of commandList) {
            pallets.push(await this.handle(command));
        }

        return pallets;
    }
}

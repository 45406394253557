import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { palletService } from "../../../services/pallet";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { t } from "i18next";

export class PalletParcelRemoveHandler {
    public static async handle(pallet: PalletsListItem, parcelLocator: string) {
        const parcel = pallet.getDetailByLocator(parcelLocator);

        if (!parcel) {
            notificationModel.addErrorMessage(t("components.pallet.removeParcel.parcelNotFound", {
                locator: parcelLocator,
            }));

            return;
        }

        await palletService.removeParcelFromPallet(pallet.id, parcel.parcelId);
        notificationModel.addSuccessMessage(t("components.pallet.removeParcel.success", {
            palletCode: pallet.code,
        }));
    }
}

import { assign } from "lodash";
import { observable } from "mobx";
import { domain, label } from "../../common/dto/dto-annotation";
import { identifier } from "../../domain/identifier";
import { label50, label150 } from "../../domain/label";
import { quantity } from "../../domain/quantity";

export class DeliveryOrderDetailFull {

    constructor(deliveryOrderDetailFull?: DeliveryOrderDetailFull) {
        assign(this, deliveryOrderDetailFull);
    }

    public id: number;

    @label("model.deliveryOrderFull.id")
    @domain(identifier)
    public deliveryOrderId: number;

    @label("model.deliveryOrderDetail.productName")
    @domain(label150)
    public productName: string;

    public weight: number;

    @label("model.deliveryOrderDetail.supplierReference")
    @domain(label50)
    @observable
    public supplierReference: string;

    public productId: number;

    @label("model.deliveryOrderDetail.quantity")
    @domain(quantity)
    public quantity: number;

    @label("model.deliveryOrderDetail.quantityParcelled")
    @domain(quantity)
    public quantityParcelled: number;

    @label("model.deliveryOrderDetail.quantityLabeled")
    @domain(quantity)
    public quantityLabeled: number;

    @label("model.deliveryOrderDetail.quantityShipped")
    @domain(quantity)
    public quantityShipped: number;

    @label("model.deliveryOrderDetail.quantityOverShipped")
    @domain(quantity)
    @observable
    public quantityOverShipped: number;

    @label("model.deliveryOrderDetail.quantityStockout")
    @domain(quantity)
    public quantityStockout: number;

    @label("model.deliveryOrderDetail.ean")
    public barCodes: string[];
}

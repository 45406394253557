import { IParamActionValidator } from "../../../../common/component/action/action-select/interface-param-action-validator";
import { DeliveryOrder } from "../../../model/delivery-order";
import { ParamActionValidationResult } from "../../../../common/component/action/action-select/param-action-validation-result";
import { t } from "i18next";

class ParcelCreationValidator implements IParamActionValidator<DeliveryOrder> {
    public validate(identifierList: DeliveryOrder[]) {
        const pendingCancellationDeliveryOrders = identifierList.filter(i => i.isPendingCancellation);

        if (pendingCancellationDeliveryOrders.length > 0) {
            return ParamActionValidationResult.warning(t("deliveryOrderList.validation.createFromOrderPendingCancellationWarning", {
                deliveryOrderIdList: pendingCancellationDeliveryOrders.map(d => d.id).join(", "),
            }), pendingCancellationDeliveryOrders);
        }

        return ParamActionValidationResult.success(identifierList);
    }
}

export const parcelCreationValidator = new ParcelCreationValidator();

import { IMultiParcelDocumentProvider } from "./multiparcel-document-provider-interface";
import { parcelsService  } from "../../../../services/parcels";
import { DocumentFormat} from "../document-printer";
import { Parcel } from "../../../../model/parcel";
import { MultiParcelDocument } from "./multiparcel-document";

class DeliverySlipProvider implements IMultiParcelDocumentProvider {
    public async fetchMultiparcel(operationCode: string, batchId: number, parcel: Parcel[]): Promise<MultiParcelDocument> {
        const response = await parcelsService.multipageDocument(operationCode, batchId, parcel.map(p => p.id), "DeliverySlip");

        return new MultiParcelDocument(response.succeededParcelIds, DocumentFormat.Pdf, response.file);
    }
}

export const deliverySlipProvider = new DeliverySlipProvider();

import React, { useState } from "react";
import { Badge, Button, Tooltip } from "@mui/material";
import { Smartphone } from "@mui/icons-material";
import { MobileConnector } from "./mobile-connector";
import { t } from "i18next";
import { useRemotePrintingContext } from "./remote-printing/remote-printing-context";

import "./style.scss";

export const MobileConnectorWidget = () => {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const printingContext = useRemotePrintingContext();

    return <>
        <Tooltip title={t("components.mobileConnector.title")}>
            <Badge badgeContent={printingContext.queue.filter(d => !d.isCompleted).length}
                   color={"secondary"}
                   showZero={false}>
                <Button className={"mobile-printing-widget"}
                        variant={"outlined"}
                        color={"inherit"}
                        onClick={() => setDialogOpen(true)}
                        size={"large"}>
                    <Smartphone />
                </Button>
            </Badge>
        </Tooltip>
        {isDialogOpen && <MobileConnector onClose={() => setDialogOpen(false)} />}
    </>;
};

import { BulkParcelUploadBaseConstraint } from "./validator/bulk-parcel-upload-base-constraint";
import { context } from "../../../../../context/context";
import { Permission } from "../../../../../context/permission";
import { BulkParcelUploadBluebrandConstraint } from "./validator/bulk-parcel-upload-bluebrand-constraint";
import { IBulkParcelUploadImportValidationConstraint } from "./bulk-parcel-upload-import-validator";
import { palletService } from "../../../../services/pallet";
import { BulkParcelUploadPalletWorkflowConstraint } from "./validator/bulk-parcel-upload-pallet-workflow-constraint";
import { FeatureToggle } from "../../../../../context/app-configuration";
import { BulkParcelUploadStockoutConstraint } from "./validator/bulk-parcel-upload-stockout-constraint";
import { operationsService } from "../../../../services/operations";
import { BulkParcelUploadBatchIdConstraint } from "./validator/bulk-parcel-upload-batch-id-constraint";
import { BulkParcelUploadOperationCodeConstraint } from "./validator/bulk-parcel-upload-operation-code-constraint";

export class BulkParcelUploadImportValidationConstraintsProvider {
    public getBaseConstraints() {
        return [new BulkParcelUploadBaseConstraint()];
    }

    public async getConstraintsForWarehouse(warehouse: string) {
        const constraints: IBulkParcelUploadImportValidationConstraint[] = [];

        if (context.hasPermission(Permission.FeatureBlueBrand)) {
            constraints.push(new BulkParcelUploadBluebrandConstraint());
        }

        if (context.hasPermission(Permission.FeaturePalletWorkflow)) {
            constraints.push(new BulkParcelUploadPalletWorkflowConstraint(await palletService.loadCarrierCountryList(warehouse)));
        }

        if (context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS)) {
            constraints.push(new BulkParcelUploadStockoutConstraint());
        }

        return constraints;
    }

    public async getConstraintsForOperation(operationCode: string) {
        return [
            new BulkParcelUploadOperationCodeConstraint(operationCode),
            new BulkParcelUploadBatchIdConstraint((await operationsService.loadBatches(operationCode)).map(batch => batch.id)),
        ];
    }
}

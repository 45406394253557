import React, { useState } from "react";
import { Card, CardHeader, ThemeProvider } from "@mui/material";
import { VPUITheme } from "../../../common/theme/vpui-theme";
import { t } from "i18next";
import { RequestPasswordResetForm } from "./request-password-reset-form";
import { ResetPasswordNotification } from "./reset-password-notification";

import "./style.scss";

interface IRequestPasswordResetProps {
    userName: string;
    onCancel: () => void;
}

export const RequestPasswordReset = (props: IRequestPasswordResetProps) => {
    const [isCompleted, setIsCompleted] = useState(false);

    return <ThemeProvider theme={VPUITheme}>
        <section className={"form password-reset"}>
            <Card className={"container"}>
                <CardHeader title={t("components.passwordReset.title")} />
                {!isCompleted ?
                    <RequestPasswordResetForm userName={props.userName}
                                              onComplete={() => setIsCompleted(true)}
                                              onCancel={props.onCancel} /> :
                    <ResetPasswordNotification text={t("components.passwordReset.success")}
                                               onClose={props.onCancel} />}
            </Card>
        </section>
    </ThemeProvider>;
};

/**
 * Interface for Warehouse object returned by API
 */
export interface IWarehouseDto {
    code: string;
    name: string;
    country: string;
    city: string;
    streetName: string;
    streetNumber: string;
    additionalAddress: string;
    isActive: boolean;
    isBlueBrand: boolean;
}

/**
 * Warehouse model
 */
export class Warehouse {
    private readonly _code: string;
    private readonly _name: string;
    private readonly _country: string;
    private readonly _city: string;
    private readonly _streetName: string;
    private readonly _streetNumber: string;
    private readonly _additionalAddress: string;
    private readonly _isActive: boolean;
    private readonly _isBlueBrand: boolean;

    /**
     * Constructs warehouse
     * @param warehouse
     */
    constructor(warehouse: IWarehouseDto) {
        this._code = warehouse.code;
        this._name = warehouse.name;
        this._country = warehouse.country;
        this._city = warehouse.city;
        this._streetName = warehouse.streetName;
        this._streetNumber = warehouse.streetNumber;
        this._additionalAddress = warehouse.additionalAddress;
        this._isActive = warehouse.isActive;
        this._isBlueBrand = warehouse.isBlueBrand;
    }

    /**
     * Get code.
     */
    get code(): string {
        return this._code;
    }

    /**
     * Get name.
     */
    get name(): string {
        return this._name;
    }

    /**
     * Get country.
     */
    get country(): string {
        return this._country;
    }

    /**
     * Get city.
     */
    get city(): string {
        return this._city;
    }

    /**
     * Get street name.
     */
    get streetName(): string {
        return this._streetName;
    }

    /**
     * Get street number.
     */
    get streetNumber(): string {
        return this._streetNumber;
    }

    /**
     * Get additional address.
     */
    get additionalAddress(): string {
        return this._additionalAddress;
    }

    /**
     * Get warehouse status.
     */
    get isActive(): boolean {
        return this._isActive;
    }

    /**
     * True if warehouse operates in Brand To Member flow, false otherwise.
     */
    get isBlueBrand(): boolean {
        return this._isBlueBrand;
    }

    /**
     * Get full address, created from other address properties.
     */
    get address(): string {
        if (!(this.streetName == null || this.streetName.trim() === "")) {
            return `${this.country}, ${this.city}, ${this.streetName} ${this.streetNumber}`;
        }

        return `${this.country}, ${this.city}, ${this.additionalAddress}`;
    }

    /**
     * Get full address without country code.
     */
    get shortAddress(): string {
        if (!(this.streetName == null || this.streetName.trim() === "")) {
            return `${this.city}, ${this.streetName} ${this.streetNumber}`;
        }

        return `${this.city}, ${this.additionalAddress}`;
    }

    public getTruncatedAddress(maxLength: number = 50) {
        const address = this.address.trim();

        if (address.length > maxLength) {
            return `${this.address.substr(0, 50).trim()}...`;
        }

        return address;
    }
}

import { computed } from "mobx";

export interface ICarrierCountryGroup {
    carrier: string;
    countryGroupName: string;
    countryIsoCodes: string[];
}

export class CarrierCountryGroup {
    private readonly _carrier: string;
    private readonly _countryGroupName: string;
    private readonly _countryIsoCodes: string[];

    public constructor(carrierCountryGroup: ICarrierCountryGroup) {
        this._carrier = carrierCountryGroup.carrier;
        this._countryGroupName = carrierCountryGroup.countryGroupName;
        this._countryIsoCodes = carrierCountryGroup.countryIsoCodes;
    }

    public get carrier(): string {
        return this._carrier;
    }

    public get countryGroupName(): string {
        return this._countryGroupName;
    }

    public get countryIsoCodes(): string[] {
        return this._countryIsoCodes;
    }

    @computed
    public get key(): string {
        return `${this._countryGroupName}-${this.carrier}`;
    }
}

import * as React from "react";

import { SvgIcon } from "@mui/material";

export const BarCodeIcon = (props: any) => (
    <SvgIcon {...props} data-testid="barcode" viewBox="0 0 297 297">
      {/* tslint:disable-next-line:max-line-length */}
      <path d="M251.701 11.96H104.125a10.393 10.393 0 0 0-9.649 14.252l45.621 114.052a10.392 10.392 0 0 0 9.649 6.533h25.871l-18.97 107.314c-1.359 7.689.739 15.531 5.757 21.515s12.376 9.416 20.185 9.416h36.032a26.31 26.31 0 0 0 25.942-21.759l20.974-118.654C283.768 138.764 297 121.65 297 101.498v-44.24c0-24.977-20.321-45.298-45.299-45.298zM119.474 32.744h22.923l37.307 93.268h-22.922l-37.308-93.268zm104.623 226.919a5.553 5.553 0 0 1-5.475 4.593H182.59c-2.257 0-3.638-1.245-4.26-1.987-.622-.743-1.608-2.319-1.215-4.54l7.513-42.502h15.924c5.739 0 10.392-4.653 10.392-10.392s-4.653-10.392-10.392-10.392h-12.25l8.423-47.647h47.324l-19.952 112.867zm52.119-158.165c0 13.518-10.997 24.515-24.515 24.515H202.09l-37.307-93.268h86.918c13.518 0 24.515 10.997 24.515 24.515v44.238zM89.951 121.243c-8.668-3.664-15.389-10.483-18.927-19.203-3.539-8.72-3.469-18.295.195-26.962 2.235-5.286-.24-11.383-5.526-13.618-5.286-2.235-11.384.24-13.618 5.526-5.825 13.781-5.936 29.005-.311 42.869s16.314 24.706 30.094 30.533a10.36 10.36 0 0 0 4.041.823c4.051 0 7.901-2.383 9.577-6.349 2.235-5.287-.239-11.384-5.525-13.619z"/>
      {/* tslint:disable-next-line:max-line-length */}
      <path d="M71.396 165.137C29.3 147.343 9.53 98.619 27.325 56.524c2.235-5.286-.24-11.383-5.526-13.618-5.288-2.233-11.384.241-13.618 5.526-22.257 52.651 2.472 113.593 55.123 135.85 1.321.558 2.692.823 4.041.823 4.051 0 7.901-2.384 9.577-6.349 2.235-5.287-.239-11.384-5.526-13.619z"/>
    </SvgIcon>
);

export const FunnelIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 1000 1000" data-testid="funnel">
        <g>
            <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                {/* tslint:disable-next-line:max-line-length */}
                <path d="M100,3830.4c3.9-7.9,400.4-430,883.7-937l877.8-919.2H5002h3140.4l877.8,921.2c483.3,507,879.8,929.1,879.8,937c0,9.9-2207.4,15.8-4903.9,15.8C2283.7,3848.1,96.1,3840.2,100,3830.4z" />
                {/* tslint:disable-next-line:max-line-length */}
                <path d="M2378.4,1433.6c29.6-35.5,361-386.6,735.8-779.2l680.6-712.1H5000h1203.3l720,753.5c394.5,412.3,725.9,763.4,733.8,779.2c13.8,21.7-428.1,25.6-2659.1,25.6H2323.1L2378.4,1433.6z" />
                <path d="M3923-1201.8v-670.7H4998h1075.1v670.7v670.7H4998H3923V-1201.8z" />
                {/* tslint:disable-next-line:max-line-length */}
                <path d="M3936.8-2359.7c-7.9-5.9-13.8-295.9-13.8-641.1v-627.3H4998h1075.1v641.1v641.1H5011.8C4427.9-2345.9,3942.7-2351.8,3936.8-2359.7z" />
            </g>
        </g>
    </SvgIcon>
);

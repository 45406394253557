import React, { useEffect, useState } from "react";
import { CarrierCountryGroup } from "../../../carrier-country-group";
import { groupBy, map } from "lodash";
import { t } from "i18next";
import { FormControl, FormLabel, TextField, Typography } from "@mui/material";
import { Icon } from "../../../../common/component/icon/icon";
import { isTextValidNonNegativeNumber } from "../../../../common/utils/is-text-valid-non-negative-number";
import { CreatePalletRequestCarrierCountryItem } from "./create-pallet-request-carrier-country-item";

interface IMultipleCarrierCountryGroupSelectProps {
    carrierCountryGroupList: CarrierCountryGroup[];
    onChange: (countryItem: CreatePalletRequestCarrierCountryItem) => void;
}

interface IMultipleCarrierCountryGroupSelectGroupProps {
    groupName: string;
    carriersList: CarrierCountryGroup[];
    onChange: (countryItem: CreatePalletRequestCarrierCountryItem) => void;
}

interface IMultipleCarrierCountryGroupSelectItemProps {
    carrierDetails: CarrierCountryGroup;
    groupName: string;
    onChange: (countryItem: CreatePalletRequestCarrierCountryItem) => void;
}

export const MultipleCarrierCountryGroupSelect = (props: IMultipleCarrierCountryGroupSelectProps) => {
    const countryGroupListGrouped = groupBy(props.carrierCountryGroupList, item => item.countryGroupName);

    return <div>
        {map(countryGroupListGrouped, (carriersList, groupName) =>
            <MultipleCarrierCountryGroupSelectGroup groupName={groupName}
                                                    carriersList={carriersList}
                                                    onChange={item => props.onChange(item)} />)}
    </div>;
};

const MultipleCarrierCountryGroupSelectGroup = (props: IMultipleCarrierCountryGroupSelectGroupProps) => {
    return <div data-testid={"multiple-carrier-country-form"}>
        <Typography className={"multi-carrier-select__group-label"}>
            {t(`components.pallet.createPalletDialog.country.${props.groupName}`)}
        </Typography>
        {props.carriersList.map(carrier =>
            <MultipleCarrierCountryGroupSelectItem carrierDetails={carrier}
                                                   groupName={carrier.countryGroupName}
                                                   onChange={item => props.onChange(item)} />)}
    </div>;
};

const MultipleCarrierCountryGroupSelectItem = (props: IMultipleCarrierCountryGroupSelectItemProps) => {
    const [value, setValue] = useState("");

    const isValid = value === "" || isTextValidNonNegativeNumber(value);

    useEffect(() => {
        if (value !== "" && isTextValidNonNegativeNumber(value)) {
            props.onChange(new CreatePalletRequestCarrierCountryItem(props.carrierDetails, Number(value)));
        } else {
            props.onChange(new CreatePalletRequestCarrierCountryItem(props.carrierDetails, 0));
        }
    }, [value]);

    return <FormControl variant={"outlined"}
                        className={"multi-carrier-select__item"}
                        fullWidth
                        data-testid={`carrier-country-item-${props.carrierDetails.key}`}>
        <FormLabel htmlFor={`${props.carrierDetails.key}-input`}
                   className={"multi-carrier-select__label"}>
            {props.carrierDetails.carrier}
        </FormLabel>
        <TextField id={`${props.carrierDetails.key}-input`}
                   variant={"outlined"}
                   data-testid={`carrier-country-input-${props.carrierDetails.key}`}
                   className={"multi-carrier-select__input"}
                   onChange={event => setValue(event.target.value)}
                   size={"small"}
                   value={value} />
        <div className={"multi-carrier-select__error"}>
            {!isValid && <Icon icon={"error"}
                               color={"primary"}
                               tooltipPlacement={"right-start"}
                               tooltipTitle={t("palletPickup.declarationForm.errorInvalidNumber")} />}
        </div>
    </FormControl>;
};

import React, { ReactNode, ReactNodeArray, useEffect, useState } from "react";
import { ParcelBulkCreationMode } from "../../../../constants/constants";
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

interface IParcelCreationStrategyMultiVariantItemProps {
    title: string;
    children: ReactNode | ReactNodeArray;
    onValueChange: (newStrategy: ParcelBulkCreationMode | undefined, previousStrategy?: ParcelBulkCreationMode) => void;
    propertyKey: string;
    disabled?: boolean;
}

interface IParcelCreationStrategyItemVariantProps {
    value: ParcelBulkCreationMode;
    title: string;
}

export const ParcelCreationStrategyMultiVariantItem = (props: IParcelCreationStrategyMultiVariantItemProps) => {
    const [isSelected, setSelected] = useState(false);

    const defaultVariant = React.Children.map(props.children, c =>
        React.isValidElement<IParcelCreationStrategyItemVariantProps>(c) && c.props.value ?
            c.props.value : undefined)?.shift();
    const [checkedVariant, setCheckedVariant] = useState(defaultVariant);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setSelected(event.target.checked);
    const handleVariantChange = (selectedVariant: ParcelBulkCreationMode) => setCheckedVariant(prevVariant => {
        props.onValueChange(selectedVariant, prevVariant);

        return selectedVariant;
    });

    useEffect(() => {
        if (isSelected) {
            props.onValueChange(checkedVariant);
        } else {
            props.onValueChange(undefined, checkedVariant);
            setCheckedVariant(defaultVariant);
        }
    }, [isSelected]);

    useEffect(() => {
        if (props.disabled) {
            setSelected(!props.disabled);
        }
    }, [props.disabled]);

    return <div className={"creation-strategy"}>
        <FormControlLabel label={props.title}
                          control={<Checkbox onChange={handleChange} checked={isSelected} />}
                          data-testid={`strategy-${props.propertyKey}`}
                          className={"creation-strategy__multi-label"} />
        <FormControl disabled={!isSelected}>
            <RadioGroup onChange={event => handleVariantChange(event.target.value as ParcelBulkCreationMode)}
                        value={isSelected && checkedVariant ? checkedVariant : ""}>
                {props.children}
            </RadioGroup>
        </FormControl>
    </div>;
};

export const ParcelCreationStrategyItemVariant = (props: IParcelCreationStrategyItemVariantProps) => {
    return <FormControlLabel value={props.value.toString()}
                             data-testid={`strategy-variant-${props.value}`}
                             control={<Radio />}
                             label={props.title} />;
};

import React, { useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { t } from "i18next";
import { CameraAccessDeniedNotification } from "./camera-access-denied-notification";
import { useEffectAsync } from "../../../common/hooks/use-effect-async";
import { CameraListProvider } from "./camera-list-provider";

interface ICameraListLoaderProps {
    onLoad: (cameraList: MediaDeviceInfo[]) => void;
    onCameraAccessFailed: () => void;
    onCameraAccessDenied?: (errorMessage: string) => void;
}

export const CameraListLoader = (props: ICameraListLoaderProps) => {
    const [cameraListAccessDenied, setAccessDenied] = useState(false);

    useEffectAsync(async () => {
        try {
            props.onLoad(await new CameraListProvider().fromReader());
        } catch (err) {
            if (props.onCameraAccessDenied) {
                props.onCameraAccessDenied(err);
            } else {
                setAccessDenied(true);
            }
        }
    }, []);

    if (cameraListAccessDenied) {
        return <CameraAccessDeniedNotification onClick={props.onCameraAccessFailed} />;
    }

    return <>
        <div className={"video-loader__icon"}>
            <CircularProgress size={"80vw"} />
            <Typography className={"video-loader__text"}>
                {t("components.scanAndShip.camera.loader")}
            </Typography>
        </div>
    </>;
};

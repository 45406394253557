import * as React from "react";

import { IconButton, TableCell, TableRow } from "@mui/material";

import { ListSort } from "../../../../common/component/list/search-list/list-sort";
import { Parcel } from "../../../model/parcel";
import { ParcelDetail } from "../../../model/parcel-detail";
import { ParcelFilter } from "../../../model/parcel-filter";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { indexOf } from "lodash";
import { observer } from "mobx-react";

interface IParcelListSortProps {
    hiddenSortFields: string[];
}

@observer
export class ParcelListSort extends ListSort<Parcel, ParcelDetail, ParcelFilter, IParcelListSortProps> {

    public render() {
        return [
            <TableRow className="level1 header" key="parcel-sort-1">
                <TableCell className="cellHeader">
                    <IconButton>
                        <SwapVertIcon></SwapVertIcon>
                    </IconButton>
                </TableCell>
                <TableCell className="cellHeader" colSpan={7 + this.props.searchListModel.additionalFields.length}>
                    <div className="level1-field-container">
                        {this.renderHeaderSort("model.parcel.parcelId", "parcelId")}
                        {this.renderHeaderSort("model.parcel.orderId", "orderId")}
                        {this.renderHeaderSort("model.parcel.deliveryOrderId", "deliveryOrderId")}
                        {this.renderHeaderSort("model.parcel.carrier", "carrier")}
                        {this.renderHeaderSort("model.parcel.weight", "weight")}
                        {indexOf(this.props.hiddenSortFields, "parcelTracker") === -1 &&
                            this.renderHeaderSort("model.parcel.parcelTracker", "parcelTracker")}
                        {indexOf(this.props.hiddenSortFields, "shipmentDate") === -1 &&
                            this.renderHeaderSort("model.parcel.shipmentDate", "shipmentDate")}
                        < div className="sortField">
                            {this.renderHeaderSort("model.parcel.zipCode", "zipCode")}&nbsp;&nbsp;&nbsp;
                        {this.renderHeaderSort("model.parcel.city", "city")}&nbsp;&nbsp;&nbsp;
                        {this.renderHeaderSort("model.parcel.country", "country")}&nbsp;&nbsp;&nbsp;
                    </div>
                    </div>
                </TableCell>
            </TableRow >,
            <TableRow className="level2 header" key="parcel-sort-2">
                <TableCell className="cellHeader" colSpan={8 + this.props.searchListModel.additionalFields.length}>
                    <div className="level2-field-container">
                        {this.renderHeaderSort("model.parcel.detailSortingKey", "detailSortingKey")}
                        {this.renderHeaderSort("model.parcel.contentsSortingKey", "contentsSortingKey")}
                    </div>
                </TableCell>
            </TableRow >];
    }
}

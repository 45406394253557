import { IActionEligibilityValidator } from "./interface-action-eligibility-validator";

export class CompositeEligibilityValidator implements IActionEligibilityValidator {
    private readonly _validators: IActionEligibilityValidator[];

    public constructor(validators: IActionEligibilityValidator[]) {
        this._validators = validators;
    }

    public validate() {
        for (const validator of this._validators) {
            if (!validator.validate()) {
                return false;
            }
        }

        return true;
    }
}

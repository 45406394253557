export enum Permission {
    BackOffice = "BackOffice",
    FrontWrite = "Front-Write",
    FrontRead = "Front-Read",

    FeatureReadyToShip = "Feature-ReadyToShip",
    FeatureBlueBrand = "Feature-FeatureBlueBrand",
    FeatureExposeShippingAddress = "Feature-FeatureExposeShippingAddress",
    FeaturePalletPickUpForm = "Feature-FeaturePalletPickUpForm",
    FeaturePalletWorkflow = "Feature-FeaturePalletWorkflow",
}

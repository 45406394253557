import { assign, toUpper } from "lodash";
import { observable } from "mobx";
import { label, domain } from "../../common/dto/dto-annotation";
import { identifier } from "../../domain/identifier";
import { label50, label64 } from "../../domain/label";
import { email } from "../../domain/email";
import { yesNo } from "../../domain/yes-no";

export class UserSummary {

    constructor(userSummary?: UserSummary) {
        assign(this, userSummary);
    }

    @label("model.user.id")
    @domain(identifier)
    @observable
    public id: string;

    @label("model.user.userName")
    @domain(label50)
    @observable
    public userName: string;

    @label("model.user.email")
    @domain(email)
    @observable
    public email: string;

    @label("model.user.warehouse")
    @domain(label64)
    @observable
    public warehouseId?: string;

    @label("model.user.ipNetwork")
    @domain(label50)
    @observable
    public ipNetwork: string;

    @label("model.user.isLocked")
    @domain(yesNo)
    @observable
    public isLocked: boolean;

    public get sortColumn() {
        return toUpper(this.userName);
    }
}

import React, { ReactNode, useEffect, useState } from "react";
import { Button, List, Popover } from "@mui/material";
import { IQuickFilterOptionProps } from "./quick-filter-option";
import { KeyboardArrowDown } from "@mui/icons-material";

import "./style.scss";

interface IQuickFilterSelectProps {
    title: string;
    propertyKey: string;
    children: ReactNode;
    loadDefaultValue: () => string | undefined;
    onQuickFilterSelect: (filterValue: string) => void;
}

export const QuickFilterSelect = (props: IQuickFilterSelectProps) => {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const [value, setValue] = useState<string | undefined>(undefined);

    const handleValueChange = (newValue: string) => {
        props.onQuickFilterSelect(newValue);
        setAnchorElement(null);
    };

    useEffect(() => {
        if (anchorElement !== null) {
            setValue(props.loadDefaultValue());
        }
    }, [anchorElement]);

    return <div className={"quick-filter-list__item"}>
        <Button onClick={event => setAnchorElement(event.currentTarget)}
                className={"quick-filter-list__button"}
                data-testid={`quick-filter-button-${props.propertyKey}`}>
            {props.title} <KeyboardArrowDown />
        </Button>
        <Popover anchorEl={anchorElement}
                 elevation={0}
                 open={anchorElement !== null}
                 onClose={() => setAnchorElement(null)}
                 className={"quick-filter"}
                 anchorOrigin={{horizontal: "left", vertical: "bottom"}}
                 data-testid={`quick-filter-options-${props.propertyKey}`}>
            <List className={"quick-filter__list"}>
                {React.Children.map(props.children, item => React.isValidElement<IQuickFilterOptionProps>(item) ?
                    React.cloneElement<IQuickFilterOptionProps>(item, {
                        ...item.props,
                        onClick: () => handleValueChange(item.props.value),
                        isChecked: value === item.props.value,
                    }) : undefined)}
            </List>
        </Popover>
    </div>;
};

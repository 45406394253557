import { TableCell, TableRow } from "@mui/material";

import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { Icon } from "../../../../common/component/icon/icon";
import { ParcelDetail } from "../../../model/parcel-detail";
import React from "react";
import { computed } from "mobx";
import { observer } from "mobx-react";
import { parcellingModel } from "./parcelling-model";
import { stringValue } from "../../../../common/field/renderer";
import {Permission} from "../../../../context/permission";
import { UnCodeIcon } from "../un-code-icon/un-code-icon";

interface IProductListProps {
    deliveryOrderDetail: DeliveryOrderDetail;
    parcelId: number;
    parcelDetail: ParcelDetail;
    isEditingMode: boolean;
}

@observer
export default class ParcellingParcelDetailRow extends React.Component<IProductListProps, {}> {

    public render() {
        return (
            <TableRow className="level2 content"
                data-testid=
                {`par-${this.props.parcelId || "new"}-prd-${this.props.parcelDetail.productId}`}>
                <TableCell>{stringValue(this.props.parcelDetail, "productId")}</TableCell>
                <TableCell><ul>{this.props.parcelDetail.ean13List.map(ean => <li key={ean}>{ean}</li>)}</ul></TableCell>
                <TableCell>{stringValue(this.props.parcelDetail, "supplierReference")}</TableCell>
                <TableCell>
                    {this.props.parcelDetail.unCode && <UnCodeIcon value={this.props.parcelDetail.unCode}></UnCodeIcon>}
                    {stringValue(this.props.parcelDetail, "labelReference")}
                </TableCell>
                <TableCell>{stringValue(this.props.parcelDetail, "weight")}</TableCell>
                <TableCell className="cell-quantity">
                    <div className="actions" >
                        <Icon key="icon-remove"
                            isHidden={!this.props.isEditingMode}
                            onClick={() => this.updateQuantity(-1)}
                            disabled={!this.canDecrease}
                            icon="remove" color="primary"
                            permission={Permission.FrontWrite} />
                        <span className="input-quantity"
                            data-testid={`prd-qty-${this.props.parcelDetail.productId}`}>
                            {this.props.parcelDetail.quantity}
                        </span>
                        <Icon key="icon-add"
                            isHidden={!this.props.isEditingMode}
                            onClick={() => this.updateQuantity(1)}
                            disabled={!this.canIncrease}
                            icon="add" color="primary"
                            permission={Permission.FrontWrite} />
                    </div>
                </TableCell>
            </TableRow>
        );
    }

    private updateQuantity(quantityToAdd: number) {
        parcellingModel.assignParcelProductQuantity(
            this.props.parcelId,
            [{
                productId: this.props.parcelDetail.productId,
                quantity: this.props.parcelDetail.quantity + quantityToAdd,
            }]);
    }

    @computed
    private get canIncrease() {
        return this.props.deliveryOrderDetail.quantityAvailable > 0;
    }

    @computed
    private get canDecrease() {
        return this.props.parcelDetail.quantity > 0;
    }
}

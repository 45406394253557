import * as React from "react";

import { getDomain, getLabel } from "../dto/dto-annotation";

import { DisplayField } from "../component/display-field/display-field";
import { t } from "i18next";

export interface IFieldProperty {
    error?: string[];
    onChange: (e: any) => void; // TODO :ugly type definition
}

export function stringValue<T, K extends keyof T>(target: T, propertyKey: K) {
    const value = target[propertyKey];
    const domain = getDomain(target, propertyKey);
    return domain ? domain.format(value) : `${value || ""}`;
}

export function stringListValue<T, K extends keyof T>(target: T, propertyKey: K): string[] {
    const values = target[propertyKey] as unknown as any[];

    const domain = getDomain(target, propertyKey);
    return domain ? values.map(value => domain.format(value)) : values as string[];
}

export function getReadOnlyField<T, K extends keyof T>(target: T, propertyKey: K, suffix?: string) {
    let value = stringValue(target, propertyKey);
    if (suffix) {
        value += " " + suffix;
    }
    return React.createElement(DisplayField, {
        propertyKey: propertyKey as string,
        label: getFieldLabel(target, propertyKey),
        value,
    });
}

export function getFieldLabel<T, K extends keyof T>(target: T, propertyKey: K) {
    return t(getLabel(target, propertyKey));
}

export function formatNumber(value: string) {
    return value.replace(/\D/g, "");
}

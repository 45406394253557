import React, { useCallback } from "react";
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { BusinessDocumentation, BusinessDocumentationFileType } from "../../model/business-documentation";
import { ArticleOutlined, OndemandVideo} from "@mui/icons-material";

import documentPlaceholderMedia from "/static/images/PdfIcon.png";
import videoPlaceholderMedia from "/static/images/VideoIcon.png";

interface IBusinessDocumentationItemProps {
    data: BusinessDocumentation;
    onOpen: (selected: BusinessDocumentation) => void;
}

export const BusinessDocumentationItem = (props: IBusinessDocumentationItemProps) => {
    const isVideoItem = useCallback(() => {
        return props.data.fileType === BusinessDocumentationFileType.Video;
    }, [props.data.fileType]);

    return <Card className={"business-documentation__item"}>
            <CardActionArea onClick={() => props.onOpen(props.data)}>
                <CardMedia
                    component="img"
                    height="150"
                    image={isVideoItem() ? videoPlaceholderMedia : documentPlaceholderMedia}
                />
                <CardContent>
                    <Box className={"business-documentation__item-card-content"}>
                        <Typography gutterBottom variant={"body1"}>
                            {props.data.title}
                        </Typography>
                        {isVideoItem() ? <OndemandVideo /> : <ArticleOutlined />}
                    </Box>
                    <Typography variant={"caption"} color={"text.secondary"}>
                        {props.data.createdAtText}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>;
};

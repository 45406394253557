import { observable, action, computed } from "mobx";

export class FormModel<TData extends {}> {

    @observable private _readonly: boolean = false;
    @observable private _data: TData = {} as TData;
    @observable private _errorMap: Map<keyof TData, string[]> = new Map<keyof TData, string[]>();
    @observable private _isEdit: boolean;

    constructor(isEdit?: boolean) {
        this._isEdit = !!isEdit;
    }

    @computed
    public get data() {
        return this._data;
    }

    @computed
    public get isEdit() {
        return this._isEdit;
    }

    @action
    public toggleEdit() {
        this._isEdit = !this._isEdit;
    }

    @computed
    public get isReadOnly() {
        return this._readonly;
    }

    @action
    public setReadOnly() {
        this._readonly = true;
    }

    @action
    public resetForm(data: TData) {
        this._data = data;
        this._errorMap.clear();
        this._isEdit = false;
    }

    @action
    public setData(data: TData) {
        this._data = data;
        this._errorMap.clear();
    }

    @action
    public setFormValue(propertyKey: keyof TData, newValue: any) {
        this._data[propertyKey] = newValue;
        this._errorMap.delete(propertyKey);
    }

    @action
    public resetErrors(errorMap: Map<keyof TData, string[]>) {
        this._errorMap = errorMap;
    }

    public getFieldErrors(propertyKey: keyof TData) {
        return this._errorMap.get(propertyKey) || [];
    }
}

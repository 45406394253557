import { Profile } from "./profile";
import { ISettings, Settings } from "./settings";
import { LocaleType, PrinterExtensionType, PrinterPaperFormat } from "../constants/constants";
import { startsWith } from "lodash";

export class SettingsProvider {
    private readonly DEFAULT_PRINTER_TYPE = "Pdf" as PrinterExtensionType;
    private readonly DEFAULT_PAPER_TYPE = "A4" as PrinterPaperFormat;

    public defaultSettings() {
        return new Settings("", "", this.DEFAULT_PRINTER_TYPE, "",
            this.DEFAULT_PAPER_TYPE, false, this.navigatorLanguageToLocale, false, "", "");
    }

    public forUser(profile: Profile) {
        const settings = this.fromProfile(profile);

        const localStorageSettings = this.provideFromLocalStorage();
        if (!localStorageSettings) {
            return settings;
        }

        return this.mergeSettingsWithLocal(settings, localStorageSettings);
    }

    private fromProfile(profile: Profile) {
        return new Settings(profile.username, profile.contactEmail, "Pdf",
            "", "A4" as PrinterPaperFormat, profile.isDownload,
            profile.lang || this.navigatorLanguageToLocale, false,
            profile.webhooks.length >= 1 ? profile.webhooks[0].id : "",
            profile.webhooks.length >= 1 ? profile.webhooks[0].url : "");
    }

    private provideFromLocalStorage() {
        const rawSettings = localStorage.getItem("user_settings");

        if (rawSettings) {
            return JSON.parse(rawSettings) as ISettings;
        }

        return undefined;
    }

    private mergeSettingsWithLocal(settings: Settings, localStorage: ISettings) {
        if (localStorage.printerType) {
            settings.printerType = localStorage.printerType as PrinterExtensionType;
        }

        if (localStorage.printerName) {
            settings.printerName = localStorage.printerName;
        }

        if (localStorage.printerType) {
            settings.printerPaperFormat = localStorage.printerPaperFormat as PrinterPaperFormat;
        }

        if (localStorage.isDownload) {
            settings.isDownload = localStorage.isDownload;
        }

        if (localStorage.lang) {
            settings.lang = localStorage.lang as LocaleType;
        }

        settings.muted = localStorage.muted;

        return settings;
    }

    private get navigatorLanguageToLocale(): LocaleType {
        const language = navigator.language;
        if (startsWith(language, "fr")) {
            return "fr-FR";
        }
        return "en-GB";
    }
}

import { action, observable } from "mobx";
import { palletPickupService } from "../../services/pallet-pickup-service";
import { CarrierCountryPalletDeclarationFormModel } from "./carrier-country-pallet-declaration-input-model";
import { context } from "../../../context/context";
import { uniq } from "lodash";
import { PalletReportForm, PalletReportFormStatus } from "../../model/pallet-report-form";
import { DialogMessageIcon, dialogModel } from "../../../common/component/dialog/dialog-model";
import { t } from "i18next";
import * as moment from "moment";

class PalletPickupFormDialogModel {
    @observable
    private _isFormOpen: boolean = false;

    @observable
    private _declarationElements: CarrierCountryPalletDeclarationFormModel[] = [];

    @observable
    private _creationComment: string = "";

    @observable
    private _closingComment: string = "";

    private _allowSubmission: boolean = true;

    private _deadline: moment.Moment = moment();

    @action
    public async openDialogIfFormModified() {
        if (!context.isSupplier) {
            return;
        }

        const palletForm = await palletPickupService.getPalletForm();
        if (palletForm.status === PalletReportFormStatus.Modified && palletForm.carrierCountryPalletDeclarations.length > 0) {
            this.openDialogWithForm(palletForm, true);
        }
    }

    @action
    public async openDialog() {
        if (!context.isSupplier) {
            return;
        }

        const palletForm = await palletPickupService.getPalletForm();
        if (palletForm.carrierCountryPalletDeclarations.length === 0) {
            dialogModel.alert(
                t("palletPickup.declarationForm.title"),
                t("palletPickup.declarationForm.errorFormNotAvailable"),
                DialogMessageIcon.Error,
            );

            return;
        }

        this.openDialogWithForm(palletForm, palletForm.status !== PalletReportFormStatus.Locked);
    }

    @action
    public openDialogWithForm(palletForm: PalletReportForm, allowSubmission: boolean) {
        this._declarationElements = palletForm.carrierCountryPalletDeclarations
            .map(declaration => new CarrierCountryPalletDeclarationFormModel(declaration));
        this._allowSubmission = allowSubmission;
        this._deadline = palletForm.deadline;
        this._closingComment = palletForm.palletClosingComments;
        this._creationComment = palletForm.palletCreationComments;
        this.openForm();
    }

    public get isFormOpen(): boolean {
        return this._isFormOpen;
    }

    public get declarationElements(): CarrierCountryPalletDeclarationFormModel[] {
        return this._declarationElements;
    }

    public get closingComment(): string {
        return this._closingComment;
    }

    public get creationComment(): string {
        return this._creationComment;
    }

    public get declarationCountryList(): string[] {
        return uniq(this._declarationElements
            .map(e => e.country));
    }

    public declarationElementForCountry(country: string) {
        return this._declarationElements.filter(item => item.country === country);
    }

    public get allowSubmission(): boolean {
        return this._allowSubmission;
    }

    public get isDeadlineToday(): boolean {
        return this._deadline.isSame(moment(), "day");
    }

    public get deadlineDateFormatted(): string {
        return this._deadline.format("d.m.Y");
    }

    public get deadlineTimeFormatted(): string {
        return this._deadline.format("H:mm");
    }

    public get isFormValidated(): boolean {
        return this._declarationElements.every(element => element.isValid);
    }

    public get isCompletelyFulfilled(): boolean {
        return this._declarationElements.every(element => element.palletCount !== "0");
    }

    @action
    public openForm() {
        this._isFormOpen = true;
    }

    @action
    public closeForm() {
        this._isFormOpen = false;
    }

    @action
    public setClosingComment(value: string) {
        this._closingComment = value;
    }

    @action
    public setCreationComment(value: string) {
        this._creationComment = value;
    }

    @action
    public async submitFormAndClose() {
        await palletPickupService
            .savePalletForm(this._declarationElements.map(d => d.toDataModel()), this.closingComment, this.creationComment);
        this.closeForm();
    }
}

export const palletPickupFormDialogModel = new PalletPickupFormDialogModel();

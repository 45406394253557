import React, { useState } from "react";
import { Parcel } from "../../../model/parcel";
import { Dialog, IconButton, ThemeProvider } from "@mui/material";
import { Close } from "@mui/icons-material";
import { PalletAssignmentScanParcels } from "./pallet-assignment-scan-parcels";
import { PalletAssignmentSelectPallet } from "./pallet-assignment-select-pallet";
import { VPUITheme } from "../../../../common/theme/vpui-theme";

import "./style.scss";

interface IPalletAssignmentDialogProps {
    parcelsList: Parcel[];
    getParcelItemByScan: (parcelId: string) => Parcel | undefined;

    onCancel: () => void;
    onSubmit: (parcelsList: Parcel[], palletCode: string, closeOnExit: boolean) => void;
}

enum PalletAssignmentDialogMode {
    ProvideParcelListMode,
    ScanPalletMode,
}

export const PalletAssignmentDialog = (props: IPalletAssignmentDialogProps) => {
    const isScanMode = props.parcelsList.length === 0;
    const [parcelAssignmentList, setParcelAssignmentList] = useState<Parcel[]>(props.parcelsList);
    const [step, setStep] = useState(isScanMode ?
        PalletAssignmentDialogMode.ProvideParcelListMode : PalletAssignmentDialogMode.ScanPalletMode);

    const handleScanCompleted = (parcelList: Parcel[]) => {
        setParcelAssignmentList(parcelList);
        setStep(PalletAssignmentDialogMode.ScanPalletMode);
    };

    const handleSubmit = async (parcelsList: Parcel[], palletCode: string) => {
        await props.onSubmit(parcelsList, palletCode, !isScanMode);

        if (isScanMode) {
            setParcelAssignmentList([]);
            setStep(PalletAssignmentDialogMode.ProvideParcelListMode);
        }
    };

    return <Dialog open={true}>
        <ThemeProvider theme={VPUITheme}>
            <div className={"pallet-assignment-dialog"}>
                <div className="pallet-assignment-dialog__close">
                    <IconButton onClick={() => props.onCancel()}>
                        <Close />
                    </IconButton>
                </div>
                {step === PalletAssignmentDialogMode.ProvideParcelListMode &&
                    <PalletAssignmentScanParcels getParcelItemByScan={id => props.getParcelItemByScan(id)}
                                                 onCancel={() => props.onCancel()}
                                                 onFinished={parcelList => handleScanCompleted(parcelList)} />}
                {step === PalletAssignmentDialogMode.ScanPalletMode &&
                    <PalletAssignmentSelectPallet parcelsList={parcelAssignmentList}
                                                  onScan={async (parcelsList, palletCode) =>
                                                      await handleSubmit(parcelsList, palletCode)} />}
            </div>
        </ThemeProvider>
    </Dialog>;
};

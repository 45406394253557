import { FormControlLabel, ListItem, Radio } from "@mui/material";
import React, { ReactText } from "react";

import "./style.scss";

export interface IQuickFilterOptionProps {
    children: ReactText;
    value: string;
    count: number;
    onClick?: (isChecked: boolean) => void;
    isChecked?: boolean;
}

export const QuickFilterOption = (props: IQuickFilterOptionProps) => {
    const handleChange = (isChecked: boolean) => {
        if (props.onClick) {
            props.onClick(isChecked);
        }
    };

    return <ListItem className={"quick-filter-option__item"}>
        <FormControlLabel control={<Radio defaultChecked={props.isChecked} size={"small"} />}
                          onChange={(_event, isChecked) => handleChange(isChecked)}
                          label={<span className={"quick-filter-option__label"}>
                              {props.children} ({props.count})</span>}
                          data-testid={`filter-option-${props.value}`}/>
    </ListItem>;
};

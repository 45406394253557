import { qzManager } from "../../../front-office/qz/qz-manager";
import { IPrinterDevice, PrinterDevice } from "../../model/printer-device";
import { Guid } from "guid-typescript";

export class PrinterDeviceManager {
    public async synchronizeWithQZ() {
        const qzTrayPrinters = await qzManager.loadAllPrinters();
        const mobilePrinters = this.list();

        qzTrayPrinters.forEach(printer => {
            if (!mobilePrinters.find(p => p.name === printer)) {
                mobilePrinters.push(new PrinterDevice(printer, "pdf"));
            }
        });

        localStorage.setItem("remote_printer_db", JSON.stringify(mobilePrinters.map(p => p.toData())));
        return mobilePrinters;
    }

    public list() {
        const printers: PrinterDevice[] = [];
        for (const printer of JSON.parse(localStorage.getItem("remote_printer_db") ?? "[]") as IPrinterDevice[]) {
            try {
                printers.push(PrinterDevice.fromData(printer));
            } catch {
                localStorage.setItem("remote_printer_db", "[]");

                return [];
            }
        }

        return printers;
    }

    public updatePrinterType(printerId: Guid, isZpl: boolean) {
        const devices = this.list()
            .map(p => p.id.equals(printerId) ? new PrinterDevice(p.name, isZpl ? "zpl" : "pdf", p.id) : p);

        localStorage.setItem("remote_printer_db", JSON.stringify(devices.map(p => p.toData())));

        return devices;
    }
}

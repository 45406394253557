import FileSave from "file-saver";
import * as Excel from "exceljs";
import { ExportDataContents } from "../../../../front-office/model/export-data-contents";
import { IExcelRowDecorator } from "./iexcel-row-decorator";

class ExcelDataExporter {
    public export(contents: ExportDataContents, decorators: IExcelRowDecorator[]) {
        const workbook = new Excel.Workbook();

        contents.worksheets.forEach(sheet => {
            const worksheet = workbook.addWorksheet(sheet.name);
            decorators.forEach(decorator => decorator.reset());
            sheet.headerWithItems.forEach((row, rowIndex) => {
                row.columns.forEach((cell, columnIndex) =>
                    worksheet.getRow(rowIndex + 1).getCell(columnIndex + 1).value = cell);
                decorators.forEach(decorator => decorator.decorate(worksheet.getRow(rowIndex + 1)));
            });
        });

        workbook.xlsx.writeBuffer()
            .then(result => this.saveFile(result, contents.fileName + `.xlsx`));
    }

    private saveFile(blob: Excel.Buffer, fileName: string) {
        FileSave.saveAs(new Blob([blob]), fileName);
    }
}

export const excelDataExporter = new ExcelDataExporter();

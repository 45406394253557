
export interface ISupportTicket {
    id: string;
    externalId: string;
}

export class SupportTicket {
    private readonly _id: string;
    private readonly _externalId: string;

    public constructor(data: ISupportTicket) {
        this._id = data.id;
        this._externalId = data.externalId;
    }

    get id(): string {
        return this._id;
    }

    get externalId(): string {
        return this._externalId;
    }
}

import { Sound, useSound } from "./use-sound";
import { useEffect } from "react";

export const useBeep = (dependency: string | boolean | undefined, sound: Sound) => {
    return useEffect(() => {
        if (dependency) {
            useSound(sound);
        }
    }, [dependency]);
};

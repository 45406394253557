import * as React from "react";

import { BulkyActionPicker, IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { Parcel } from "../../../model/parcel";
import { PrepareParcelShippingActionType } from "../../../constants/constants";
import { observer } from "mobx-react";
import { Permission } from "../../../../context/permission";
import { operationContext } from "../../../../context/operation-context";

interface IParcelPrepareShippingHeaderProps {
    actionsList: Array<IActionCommand<Parcel, PrepareParcelShippingActionType>>;
    reloadParcelsList: () => Promise<void>;
}

@observer
export class ParcelPrepareShippingHeader extends React.Component<IParcelPrepareShippingHeaderProps, {}> {
    public render() {
        return <div className="list-actions-container">
            <div className="list-actions">
                <BulkyActionPicker withScanMode={true}
                                   propertyKey={operationContext.operationCodeOrEmpty}
                                   scanFieldType="text"
                                   inputLabel=""
                                   contentPrefix="parcelPrepareShipping.action"
                                   actions={this.props.actionsList}
                                   permission={Permission.FrontWrite}/>
            </div>
        </div>;
    }
}

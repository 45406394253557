import React from "react";
import { Badge, Icon } from "@mui/material";

export interface IVpMenuIconBadgeProps {
    selected: string;
    badgeValue: number | undefined;
    icon: string;
}

export const VpMenuIconBadge = (props: IVpMenuIconBadgeProps) => {
    return <Badge badgeContent={props.badgeValue} className={`menu-badge ${props.selected}`} max={9999} showZero={props.badgeValue !== undefined}>
        <Icon className={`menu-badge ${props.selected}`} data-testid={props.icon}>{props.icon}</Icon>
    </Badge>;
};

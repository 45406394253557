import { CarrierCountryPalletDeclaration, ICarrierCountryPalletDeclaration } from "./carrier-country-pallet-declaration";
import * as moment from "moment";

export enum PalletReportFormStatus {
    Unmodified = "Unmodified",
    Modified = "Modified",
    Locked = "Locked",
}

export interface IPalletReportForm {
    carrierCountryPalletDeclarations: ICarrierCountryPalletDeclaration[];
    status: PalletReportFormStatus;
    deadline: string;
    palletCreationComments?: string;
    palletClosingComments?: string;
}

export class PalletReportForm {
    private readonly _carrierCountryPalletDeclarations: CarrierCountryPalletDeclaration[];
    private readonly _status: PalletReportFormStatus;
    private readonly _deadline: moment.Moment;
    private readonly _palletCreationComments: string;
    private readonly _palletClosingComments: string;

    constructor(data: IPalletReportForm) {
        this._carrierCountryPalletDeclarations = data.carrierCountryPalletDeclarations
            .map(d => new CarrierCountryPalletDeclaration(d));
        this._status = data.status;
        this._deadline = moment(data.deadline);
        this._palletCreationComments = data.palletCreationComments ?? "";
        this._palletClosingComments = data.palletClosingComments ?? "";
    }

    public get carrierCountryPalletDeclarations(): CarrierCountryPalletDeclaration[] {
        return this._carrierCountryPalletDeclarations;
    }

    public get status(): PalletReportFormStatus {
        return this._status;
    }

    public get deadline(): moment.Moment {
        return this._deadline;
    }

    get palletCreationComments(): string {
        return this._palletCreationComments;
    }

    public get palletClosingComments(): string {
        return this._palletClosingComments;
    }
}

import * as React from "react";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Icon } from "../icon/icon";

interface IProgressDrawerSummaryProps {
    successfulTitle: string;
    failedTitle: string;
    total: number;
    successful: number;
    failed: number;
}

export class ProgressDrawerSummary extends React.Component<IProgressDrawerSummaryProps, {}> {
    public render() {
        return <Table className="progress-drawer-summary">
            <TableBody>
                <TableRow>
                    <TableCell rowSpan={2} className="progress-drawer-summary__state">
                        {this.props.failed === 0 ?
                            <Icon icon="done" color="primary"/> :
                            <Icon icon="error" color="primary"/>}
                    </TableCell>
                    <TableCell className="progress-drawer-summary__description">{this.props.successfulTitle}</TableCell>
                    <TableCell className="progress-drawer-summary__counter">
                        {this.props.successful} / {this.props.total}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="progress-drawer-summary__description">{this.props.failedTitle}</TableCell>
                    <TableCell className="progress-drawer-summary__counter">
                        {this.props.failed} / {this.props.total}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>;
    }
}

import { Parcel } from "./parcel";

export class ParcelDetailsArrangement {
    private _parcelId: number;
    private _sortedProductIds: number[];

    constructor(parcelId: number, sortedProductIds: number[]) {
        this._parcelId = parcelId;
        this._sortedProductIds = sortedProductIds;
    }

    public get parcelId(): number {
        return this._parcelId;
    }

    public get sortedProductIds(): number[] {
        return this._sortedProductIds;
    }

    public static createFromParcel(parcel: Parcel) {
        return new ParcelDetailsArrangement(parcel.id, parcel.detailProductIds);
    }
}

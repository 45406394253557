import { UrlBuilder } from "./url-builder";
import { retryCommand } from "../../common/utils/retry-command";
import { httpFileRequest, httpGet, httpPost } from "../../common/network/fetch";
import { Document, IDocument } from "../model/document";
import { ParcelDocumentType } from "../../constants/constants";
import { ParcelDetailsArrangement } from "../model/parcel-details-arrangement";

class DocumentService {
    public async createDocumentWithDetailsArrangement(operationCode: string, batchId: number, parcelIds: number[], documentType: ParcelDocumentType,
                                                      parcelDetailsArrangement?: ParcelDetailsArrangement[]) {
        const documentUrl = new UrlBuilder("/documents/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .build();

        const data = {
            type: documentType,
            parcelIds,
            parcelDetailsArrangement: parcelDetailsArrangement?.map(c => {
                return {
                    parcelId: c.parcelId,
                    sortedProductIds: c.sortedProductIds,
                };
            }),
        };

        return await httpPost<IDocument>({url: documentUrl, data});
    }

    public async createDocument(operationCode: string, batchId: number, parcelIds: number[], documentType: ParcelDocumentType, remotePrinterId?: string) {
        const documentUrl = new UrlBuilder("/documents/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .build();

        const data = {
            type: documentType,
            parcelIds,
            remotePrinterId,
        };

        return await httpPost<IDocument>({url: documentUrl, data});
    }

    public async getDocumentStatusForOperation(operationCode: string, batchId: number, documentId: string, documentDownloadTimeout: number) {
        const documentUrl = new UrlBuilder("/documents/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setDocumentId(documentId)
            .setJobStatus()
            .build();

        const documentJobStatus = await retryCommand(
            () => httpGet<IDocument>({url: documentUrl}),
            (response: IDocument) => response.status === "Pending", {
                maxWaitTimeMs: documentDownloadTimeout,
            });

        return documentJobStatus.status;
    }

    public async getDocumentForOperation(operationCode: string, batchId: number, documentId: string, documentDownloadTimeout: number) {
        const documentUrl = new UrlBuilder("/documents/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setDocumentId(documentId)
            .setJobStatus()
            .build();

        const documentJobStatus = await retryCommand(
            () => httpGet<IDocument>({url: documentUrl}),
            (response: IDocument) => response.status === "Pending", {
                maxWaitTimeMs: documentDownloadTimeout,
            });

        return new Document(
            documentJobStatus,
            documentJobStatus.status !== "Failed" ?
                (await this.getDocumentFileForOperation(operationCode, batchId, documentId)).Value ?? undefined : undefined,
        );
    }

    public async getDocumentFileForOperation(operationCode: string, batchId: number, documentId: string) {
        const documentUrl = new UrlBuilder("/documents/operation")
            .setOperation(operationCode)
            .setBatchId(batchId)
            .setDocumentId(documentId)
            .setFile()
            .build();

        return httpFileRequest("GET", documentUrl, false);
    }

    public async getDocument(documentId: string, documentDownloadTimeout: number) {
        const documentUrl = new UrlBuilder("/documents")
            .setDocumentId(documentId)
            .build();

        const documentJobStatus = await retryCommand(
            () => httpGet<IDocument>({url: documentUrl}),
            (response: IDocument) => response.status === "Pending", {
                maxWaitTimeMs: documentDownloadTimeout,
            });

        return new Document(
            documentJobStatus,
            documentJobStatus.status !== "Failed" ?
                (await this.getDocumentFile(documentId)).Value ?? undefined : undefined,
        );
    }

    public async getDocumentFile(documentId: string) {
        const documentUrl = new UrlBuilder("/documents")
            .setDocumentId(documentId)
            .setFile()
            .build();

        return httpFileRequest("GET", documentUrl, false);
    }

    public async createPalletLabel(palletIds: string[], format: "Pdf" | "Zebra" = "Pdf", remotePrinterId?: string) {
        const documentUrl = new UrlBuilder("/documents/document")
            .setPallet()
            .setLabel()
            .setCreate()
            .build();

        return await httpPost<string>({
            url: documentUrl,
            data: {
                palletsIds: palletIds,
                format,
                remotePrinterId,
            }});
    }

    public async createPalletDeliverySlipDocument(palletIds: string[]) {
        const documentUrl = new UrlBuilder("/documents/document")
            .setPallet()
            .setDeliverySlip()
            .setCreate()
            .build();

        return await httpPost<string>({url: documentUrl, data: { palletsIds: palletIds}});
    }
}

export const documentService = new DocumentService();

import React, { useState } from "react";
import { CameraQrCodeScanner } from "./qr/camera-qr-code-scanner";
import { CameraBarcodeScanner } from "./barcode/camera-barcode-scanner";
import { CameraListProvider } from "./camera-list-provider";
import { CameraListLoader } from "./camera-list-loader";

interface  ICameraScannerProps {
    qrMode?: boolean;
    valueValidator?: (value: string) => boolean;
    onValueScan: (value: string) => void;
    onCameraListEnd?: () => void;
}

export const CameraScanner = (props: ICameraScannerProps) => {
    const cameraListProvider = new CameraListProvider();
    const [cameraList, setCameraList] =
        useState<MediaDeviceInfo[] | undefined>(cameraListProvider.fromStorage() ?? undefined);
    const [selectedCameraDevice, setSelectedCameraDevice] =
        useState<MediaDeviceInfo | undefined>({
            deviceId: localStorage.getItem("scan_ship_camera"),
        } as MediaDeviceInfo);

    const handleDeviceListLoad = (list: MediaDeviceInfo[]) => {
        if (!selectedCameraDevice || !list.find(d => d.deviceId === selectedCameraDevice.deviceId)) {
            setCameraList(list);
        } else {
            setCameraList([
                ...list.filter(d => d.deviceId === selectedCameraDevice.deviceId),
                ...list.filter(d => d.deviceId !== selectedCameraDevice.deviceId),
            ]);
        }
    };

    const handleSetCamera = (camera: MediaDeviceInfo) => {
        localStorage.setItem("scan_ship_camera", camera.deviceId);
        setSelectedCameraDevice(camera);
    };

    const handleNextCameraRequested = () => {
        if (!cameraList || !selectedCameraDevice) {
            return;
        }

        const deviceIndex = cameraList.findIndex(c => c.deviceId === selectedCameraDevice.deviceId);
        if (deviceIndex === -1 && cameraList.length > 0) {
            handleSetCamera(cameraList[0]);

            return;
        }

        if (deviceIndex === cameraList.length - 1) {
            handleSetCamera(cameraList[0]);

            return handleCameraListReachedEnd();
        } else {
            handleSetCamera(cameraList[deviceIndex + 1]);
        }
    };

    const handleCameraListReachedEnd = () => {
        if (props.onCameraListEnd) {
            props.onCameraListEnd();
        }
    };

    if (!selectedCameraDevice || !cameraList) {
        return <CameraListLoader onLoad={handleDeviceListLoad}
                                 onCameraAccessFailed={handleCameraListReachedEnd} />;
    }

    return props.qrMode ?
        <CameraQrCodeScanner cameraDevice={selectedCameraDevice}
                             onValueScan={props.onValueScan}
                             onNextCameraRequested={handleNextCameraRequested}
                             onCameraAccessFailed={handleNextCameraRequested} /> :
        <CameraBarcodeScanner cameraDevice={selectedCameraDevice}
                              valueValidator={props.valueValidator}
                              onValueScan={props.onValueScan}
                              onNextCameraRequested={handleNextCameraRequested}
                              onCameraAccessFailed={handleNextCameraRequested} />;
};

import * as moment from "moment";

import { IDomain, HtmlType } from "../common/dto/idomain";
import { InputComponentType } from "../common/component/input/input";

class UtcDateDomain implements IDomain<moment.Moment> {

    public htmlType: HtmlType = "text";
    public inputComponent: InputComponentType = "textField";
    public _format: string;

    public constructor(format: string) {
        this._format = format;
    }

    public format(valueToFormat?: moment.Moment) {
        if (valueToFormat) {
            return moment(valueToFormat).format(this._format);
        }
        return "";
    }

    public unformat(valueToUnformat?: string) {
        if (valueToUnformat) {
            return moment(valueToUnformat).utc();
        }
        return;
    }

    public validators = [];
}

export const utcDateTime = new UtcDateDomain("L LT");

export class CameraListProvider {
    public fromStorage() {
        const cameraListRaw = sessionStorage.getItem("scan_ship_camera_list");
        if (!cameraListRaw) {
            return undefined;
        }

        const parsedCameraList = JSON.parse(cameraListRaw) as MediaDeviceInfo[];
        const selectedCameraId = sessionStorage.getItem("scan_ship_camera");

        return selectedCameraId ? [
            ...parsedCameraList.filter(d => d.deviceId === selectedCameraId),
                ...parsedCameraList.filter(d => d.deviceId !== selectedCameraId)] :
            parsedCameraList;
    }

    public async fromReader() {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: {
                facingMode: {
                    ideal: "user",
                },
            },
        });

        const cameraList = (await navigator.mediaDevices.enumerateDevices())
            .filter(d => d.kind === "videoinput");

        stream.getTracks().forEach(track => {
            track.stop();
        });

        const cameraListSorted = [...cameraList.filter(d => !d.label.match(/front/i)),
            ...cameraList.filter(d => d.label.match(/front/i))];

        sessionStorage.setItem("scan_ship_camera_list", JSON.stringify(cameraListSorted));

        return cameraListSorted;
    }

    public reset() {
        sessionStorage.removeItem("scan_ship_camera");
        sessionStorage.removeItem("scan_ship_camera_list");
    }
}

import { IDomain, HtmlType } from "../common/dto/idomain";
import { InputComponentType } from "../common/component/input/input";

class Password implements IDomain<string> {

    public htmlType: HtmlType = "password";
    public inputComponent: InputComponentType = "textField";

    public format(valueToFormat?: string) {
        if (valueToFormat) {
            return valueToFormat;
        }
        return "";
    }

    public unformat(valueToUnformat?: string) {
        return valueToUnformat;
    }

    public validators = [];
}

export const password = new Password();

import { CreateSupportTicketCommand } from "./create-support-ticket-command";
import { ISupportTicketAttachmentData, supportTicketService } from "../../services/support-ticket-service";

export class CreateSupportTicketCommandHandler {
    public async handle(command: CreateSupportTicketCommand) {
        const ticket = await supportTicketService.create(
            command.warehouseCode,
            command.topic,
            command.team,
            command.technology,
            command.supplier,
            command.language,
            command.issue,
            command.severity,
            command.description,
            await Promise.all(command.attachments.map(await this.fileToSupportTicketAttachment)),
        );

        return ticket.externalId;
    }

    private async fileToSupportTicketAttachment(file: File) {
        const fileReader = new FileReader();

        return await new Promise<ISupportTicketAttachmentData>(resolve => {
            fileReader.onload = async () => {
                const content = fileReader.result as string;

                resolve({
                    fileName: file.name,
                    content: content.slice(content.indexOf(";base64,") + 8),
                });
            };

            fileReader.readAsDataURL(file);
        });
    }
}

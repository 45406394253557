import React, { MutableRefObject } from "react";
import { Chip, Color } from "@mui/material";
import { withStyles } from "@mui/styles";

export interface ISquaredChip {
    color?: Color;
    propertyKey: string;
    label: string;
    size?: "small" | "medium";
    className?: string;
    onClick?: () => void;
    withBorder?: boolean;
    lowContrast?: boolean;
    propertyRef?: MutableRefObject<HTMLDivElement | null>;
}

export const SquaredChip = (props: ISquaredChip) => {
    const StyledChip = withStyles({
        root: {
            borderRadius: "3px",
            backgroundColor: props.color?.[50] ?? "white",
            color: (props.lowContrast ? props.color?.[200] : props.color?.[500]) ?? "inherit",
            textTransform: "none",
            borderColor: (props.lowContrast ? props.color?.[200] : props.color?.[500]) ?? "black",
            borderStyle: "solid",
            borderWidth: props.withBorder ? "1px" : "0",
        },
    })(Chip);

    return <StyledChip key={props.propertyKey}
                       ref={props.propertyRef}
                       label={props.label}
                       size={props.size ?? "small"}
                       onClick={props.onClick}
                       className={props.className} />;
};

import React, { useEffect } from "react";

import { Route, Routes, useParams } from "react-router-dom";
import {
    PreparationSectionType,
    routeBatchPreparation,
    routeBulkParcelUpload,
    routeConsultShippedParcel,
    routeDeliveryOrderCanceled,
    routeImportData,
    routeExportOperationProducts,
    routeParcelManagement,
    routePickAndPack,
    routePrepareParcelShipping,
    routeReporting,
    routeShipParcel,
    routeStockouts,
} from "../../navigation/preparation-route-props";
import { BatchPreparation } from "./batch-preparation/batch-preparation";
import { DeliveryOrderCancelled } from "./delivery-order-canceled/delivery-order-cancelled";
import { IHistoryProps } from "../../../common/navigation/ihistory-props";
import { OperationBatchSelector } from "./operation-batch-selector/operation-batch-selector";
import { ParcelManagement } from "./parcel-management/parcel-management";
import { ParcelPrepareShipping } from "./parcel-prepare-shipping/parcel-prepare-shipping";
import { ParcelShipment } from "./parcel-shipment/parcel-shipment";
import { ParcelShipped } from "./parcel-shipped/parcel-shipped";
import { Parcelling } from "./parcelling/parcelling";
import { PickAndPack } from "./pick-and-pack/pick-and-pack";
import { PrinterProgressDrawer } from "./printer/printer-progress-drawer";
import { Reporting } from "./reporting/reporting";
import { ImportModule } from "./import-data/import-module";
import { Stockouts } from "./stockouts/stockouts";
import { BulkParcelUpload } from "./bulk-parcel-upload/bulk-parcel-upload";
import { CustomDocumentPrinter } from "./printer/custom-document-printer";
import { ExportOperationProducts } from "./export-operation-products/export-operation-products";

export interface IOperationRouterProps extends IHistoryProps {
    setRouteExpanded: (route: PreparationSectionType) => void;
}

export const OperationRouter = (props: IOperationRouterProps) => {
    useEffect(() => {
        props.setRouteExpanded("operations");
    }, []);

    const params = useParams();

    const renderNotSelectedBatchContent = () => {
        if (!params.batchId) {
            return <div data-testid="batch-not-selected" />;
        }
        return undefined;
    };

    const routesWithoutBatchSelector = [routeImportData, routeExportOperationProducts];

    return <>
        <OperationBatchSelector routing={props.routing}
                                          notDisplayBatchSelector={routesWithoutBatchSelector.some(route => props.routing.location.pathname.includes(route))}/>
        <Routes>
            <Route path={`${routeBatchPreparation}/:operationCode/:batchId/:deliveryOrderId?`}
                   element={<BatchPreparation routing={props.routing} />} />
            <Route path={`${routeParcelManagement}/:operationCode/:batchId/:deliveryOrderId?`}
                   element={<ParcelManagement routing={props.routing} />} />
            <Route path={`${routePrepareParcelShipping}/:operationCode/:batchId/:deliveryOrderId?`}
                   element={<ParcelPrepareShipping routing={props.routing} />} />
            <Route path={`${routeShipParcel}/:operationCode/:batchId/:deliveryOrderId?`}
                   element={<ParcelShipment routing={props.routing} />} />
            <Route path={`${routeConsultShippedParcel}/:operationCode/:batchId/:deliveryOrderId?`}
                   element={<ParcelShipped routing={props.routing} />} />
            <Route path={`${routeDeliveryOrderCanceled}/:operationCode/:batchId/:deliveryOrderId?`}
                   element={<DeliveryOrderCancelled routing={props.routing}/>} />
            <Route path={`${routeStockouts}/:operationCode/:batchId/:deliveryOrderId?`}
                   element={<Stockouts routing={props.routing}/>} />
            <Route path={`${routeReporting}/:operationCode?/:batchId?`} element={<Reporting/>} />
            <Route path={`${routePickAndPack}/:operationCode?/:batchId?`} element={<PickAndPack routing={props.routing} />} />
            <Route path={`${routeBulkParcelUpload}/:operationCode?/:batchId?`} element={<BulkParcelUpload routing={props.routing} />} />
            <Route path={`${routeImportData}/:operationCode/*`} element={<ImportModule routing={props.routing} />} />
            <Route path={`${routeExportOperationProducts}/:operationCode/*`} element={<ExportOperationProducts/>} />
        </Routes>
            {renderNotSelectedBatchContent()}
             <PrinterProgressDrawer />
             <CustomDocumentPrinter />
             <Parcelling />
         </>;
};

import * as React from "react";
import { IReactionDisposer, reaction } from "mobx";
import { MenuItem, ThemeProvider, Table, TableBody, TableHead } from "@mui/material";
import { DeliveryOrder } from "../../../model/delivery-order";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { DeliveryOrderFilter } from "../../../model/delivery-order-filter";
import { DeliveryOrderListFilter } from "./delivery-order-list-filter";
import { DeliveryOrderListSort } from "./delivery-order-list-sort";
import { FilterData } from "../../../../common/component/list/filter/filter-data";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import InfiniteScroll from "react-infinite-scroller";
import { SearchList } from "../../../../common/component/list/search-list/search-list";
import { menuOperationModel } from "../../preparation-menu/preparation-menu-model";
import { parcellingCommand } from "../parcelling/parcelling-command";
import { parcellingModel } from "../parcelling/parcelling-model";
import { OrderDataExporter } from "./order-data-exporter";
import { OrdersDataProvider } from "./order-data-provider";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { filter, flatMap, groupBy, head, map, sortBy, sumBy } from "lodash";
import { Article } from "../../../model/article";
import { DeliveryOrderDetailQuantityType } from "../../../constants/constants";
import { ExportDataContents, ExportType } from "../../../model/export-data-contents";
import { excelDataExporter } from "../../../../common/component/list/import-export-list/excel-data-exporter";
import { t } from "i18next";
import { ExportDataValue } from "../../../model/export-data-item";
import { ExcelBackgroundColorRowDecorator } from "../../../../common/component/list/import-export-list/excel-background-color-row-decorator";
import { CancellationRequestMenu } from "../cancellation-request/cancellation-request-menu";
import { CancellationRequestMenuModel } from "../cancellation-request/cancellation-request-menu-model";
import { FilterActionData } from "../../../../common/component/list/filter/filter-action-data";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { QuickFilterOption } from "../../../../common/component/quick-filter/quick-filter-option";
import { QuickFilterSelect } from "../../../../common/component/quick-filter/quick-filter-select";
import { getFieldLabel } from "../../../../common/field/renderer";
import { DeliveryOrderDetailType } from "../../../model/delivery-order-detail-type";
import { DeliveryOrderListLine } from "./delivery-order-list-line";
import { ExportMenu } from "../../../../common/component/list/import-export-list/export-menu";
import { IWithRouterProps } from "../../../../common/component/hoc/withRouter";
import { matchPath } from "react-router-dom";

import "./style.scss";

export abstract class DeliveryOrderList<TState extends {} = {}> extends
    SearchList<IWithRouterProps & IHistoryProps, DeliveryOrder, DeliveryOrderDetail, DeliveryOrderFilter, TState> {

    private autoOpenParcelling: IReactionDisposer;
    private autoReloadCancellationRequestMenuModel: IReactionDisposer;
    protected prefixLabel: string = "model.deliveryOrder.";

    private cancellationRequestMenuModel = new CancellationRequestMenuModel(this.props.params.operationCode, Number(this.props.params.batchId));
    protected defaultQuickFilterFields = ["carrier"] as Array<keyof DeliveryOrderFilter & keyof DeliveryOrder>;

    protected constructor(
        props: IWithRouterProps & IHistoryProps,
        searchListModel: SearchListModel<DeliveryOrder, DeliveryOrderDetail, DeliveryOrderFilter>,
        private dataExporter: OrderDataExporter,
        private dataProvider: OrdersDataProvider,
    ) {
        super(props, searchListModel);
    }

    protected get listIdentifier() {
        return `${this.props.params.operationCode}/${this.props.params.batchId}`;
    }

    public async componentWillMount() {
        await super.componentWillMount();

        await this.openParcellingPage(this.props.params.deliveryOrderId);
        this.autoOpenParcelling = reaction(
            () => this.props.params.deliveryOrderId,
            (deliveryOrderId?: string) => this.openParcellingPage(deliveryOrderId),
        );

        this.autoReloadCancellationRequestMenuModel = reaction(
            () => this.listIdentifier,
            () => {
                this.cancellationRequestMenuModel = new CancellationRequestMenuModel(this.props.params.operationCode,
                    Number(this.props.params.batchId));
                },
        );
    }

    private async openParcellingPage(deliveryOrderId?: string) {
        if (deliveryOrderId) {
            await parcellingCommand.openParcellingPage(
                this.props.params.operationCode,
                Number(this.props.params.batchId),
                Number(deliveryOrderId),
                async () => {
                    await this.reloadDeliveryOrderList();
                    this.props.routing.push(this.getParcellingDetailRoute());
                },
            );
        } else if (parcellingModel.deliveryOrder) {
            parcellingCommand.closeParcelling();
        }
    }

    public async componentWillUnmount() {
        await super.componentWillUnmount();

        if (this.autoOpenParcelling) {
            this.autoOpenParcelling();
        }

        if (this.autoReloadCancellationRequestMenuModel) {
            this.autoReloadCancellationRequestMenuModel();
        }
    }

    protected async loadList() {
        return this.dataProvider.loadList(
            this.props.params.operationCode,
            Number(this.props.params.batchId),
        );
    }

    protected getProductsList(deliveryOrders: DeliveryOrder[], quantityType: DeliveryOrderDetailQuantityType): Article[] {
        return sortBy(map(groupBy(flatMap(deliveryOrders, item => item.details), "productId"),
            items => {
                const itemData = head(items)!;

                return new Article(itemData.productId, itemData.name, itemData.supplierReference, sumBy(items, quantityType));
            })
            .filter(product => product.availableQuantity > 0), "name");
    }

    protected loadExecuteParam(isScan: boolean, param: any) {
        return isScan ?
            this.getDeliveryOrderListByScan(+param) :
            this.searchListModel.selectedItemsList;
    }

    protected getDeliveryOrderListByScan(orderId: number) {
        return filter(this.searchListModel.list, deliveryOrder => deliveryOrder.orderId === +orderId);
    }

    protected abstract get actionsList(): Array<IActionCommand<DeliveryOrder, any>>;
    protected abstract openSingleActionPickerMenu(anchor: EventTarget, deliveryOrder: DeliveryOrder): void;

    public render() {
        const list = this.getList();
        return <div>
            <InfiniteScroll
                pageStart={0}
                loadMore={() => this.searchListModel.showMore()}
                hasMore={this.hasMoreElement}>
                <div>
                    {this.renderListBar(
                        list.total, "batchPreparation.quickFilter.label", "batchPreparation.quickFilter.tooltip", "text")}
                    <CancellationRequestMenu
                        model={this.cancellationRequestMenuModel}
                        refreshSearchList={() => this.reloadDeliveryOrderList()} />
                    <Table data-testid="dor-list">
                        <TableHead>
                            {this.renderSortBar()}
                        </TableHead>
                        <TableBody className="table-body">
                            <ThemeProvider theme={VPUITheme}>
                                {list.list}
                            </ThemeProvider>
                        </TableBody>
                    </Table>
                </div>
            </InfiniteScroll>
        </div>;
    }

    protected renderAdditionalQuickFiltering() {
        const filteredOrderDetailList = this.loadQuickFilterOptions(["isSingleRef", "isMonoRef", "isMultiRef",
            "isContainingProductSet"]);

        return <>
            <QuickFilterSelect title={getFieldLabel(this.searchListModel.filterData.filter, "country")}
                               propertyKey={"country"}
                               loadDefaultValue={() => this.getCurrentFilterValue("country")}
                               onQuickFilterSelect={value => this.onQuickFilterSelected("country", value)}>
                {map(groupBy(this.loadQuickFilterOptions(["country"]), "country"), (values, v) =>
                    <QuickFilterOption count={values.length} value={v}>
                        {t(`components.quickFilter.country.${v}`)}
                    </QuickFilterOption>)}
            </QuickFilterSelect>
            <QuickFilterSelect title={getFieldLabel(this.searchListModel.filterData.filter, "parcelCount")}
                               propertyKey={"parcelCount"}
                               loadDefaultValue={() => this.getCurrentFilterValue("parcelCount")}
                               onQuickFilterSelect={value => this.onQuickFilterSelected("parcelCount", value)}>
                {map(groupBy(this.loadQuickFilterOptions(["parcelCount"]), "parcelCount"), (values, v) =>
                    <QuickFilterOption value={v}
                                       count={values.length}>
                        {v}
                    </QuickFilterOption>)}
            </QuickFilterSelect>
            <QuickFilterSelect title={getFieldLabel(this.searchListModel.filterData.filter, "type")}
                               propertyKey={"type"}
                               loadDefaultValue={() => this.loadDeliveryOrderTypeQuickFilterValues()}
                               onQuickFilterSelect={value => this.onDeliveryOrderTypeFilterSelect(value)}>
                {[DeliveryOrderDetailType.SingleReference, DeliveryOrderDetailType.MonoReference,
                    DeliveryOrderDetailType.MultiReference, DeliveryOrderDetailType.Component]
                    .map(type => <QuickFilterOption value={type}
                                                    count={this.fetchOrderCountForType(filteredOrderDetailList, type)}>
                        {t(`model.deliveryOrder.detailContentType.${type}`)}
                    </QuickFilterOption>)}
            </QuickFilterSelect>
        </>;
    }

    private fetchOrderCountForType(list: ReadonlyArray<DeliveryOrder>, type: DeliveryOrderDetailType) {
        switch (type) {
            case DeliveryOrderDetailType.SingleReference:
            case DeliveryOrderDetailType.MonoReference:
            case DeliveryOrderDetailType.MultiReference:
                return list.filter(e => e.type === type).length;
            case DeliveryOrderDetailType.Component:
                return list.filter(e => e.isContainingProductSet).length;
            default:
                return 0;
        }
    }

    private fetchDetailTypeNameFromFilterField(filterKey: keyof DeliveryOrderFilter & (keyof DeliveryOrder | keyof DeliveryOrderDetail)) {
        switch (filterKey) {
            case "isSingleRef":
                return DeliveryOrderDetailType.SingleReference.toString();
            case "isMonoRef":
                return DeliveryOrderDetailType.MonoReference.toString();
            case "isMultiRef":
                return DeliveryOrderDetailType.MultiReference.toString();
            case "isContainingProductSet":
                return DeliveryOrderDetailType.Component.toString();
            default:
                return "";
        }
    }

    private loadDeliveryOrderTypeQuickFilterValues() {
        const selectedOptions = this.searchListModel.filterData.filterAction
            .filter(f => ["isSingleRef", "isMonoRef", "isMultiRef", "isContainingProductSet"]
                .indexOf(f.filterField) !== -1);

        return selectedOptions.length === 1 ?
            this.fetchDetailTypeNameFromFilterField(selectedOptions[0].filterField) : undefined;
    }

    private onDeliveryOrderTypeFilterSelect(detailType: string) {
        if (!this.searchListModel.filterData.isAndSearch) {
            this.applyQuickFilter("");
        }

        switch (detailType) {
            case DeliveryOrderDetailType.SingleReference.toString():
                this.searchListModel.filterData.removeFilterActionList(["isMonoRef", "isMultiRef",
                    "isContainingProductSet"]);
                this.searchListModel.filterData.pushFilterActionData("isSingleRef", "isTrue");
                break;
            case DeliveryOrderDetailType.MonoReference.toString():
                this.searchListModel.filterData.removeFilterActionList(["isSingleRef", "isMultiRef",
                    "isContainingProductSet"]);
                this.searchListModel.filterData.pushFilterActionData("isMonoRef", "isTrue");
                break;
            case DeliveryOrderDetailType.MultiReference.toString():
                this.searchListModel.filterData.removeFilterActionList(["isSingleRef", "isMonoRef",
                    "isContainingProductSet"]);
                this.searchListModel.filterData.pushFilterActionData("isMultiRef", "isTrue");
                break;
            case DeliveryOrderDetailType.Component.toString():
                this.searchListModel.filterData.removeFilterActionList(["isSingleRef", "isMonoRef", "isMultiRef"]);
                this.searchListModel.filterData.pushFilterActionData("isContainingProductSet", "isTrue");
                break;
        }
    }

    private onOrderIdQuickFilterEnabled(orderId: number) {
        const defaultFilter = this.loadDefaultFilter();
        defaultFilter.orderId = orderId.toString();

        const filterActions: Array<FilterActionData<DeliveryOrderFilter, DeliveryOrder, DeliveryOrderDetail>> = [];
        filterActions.push(new FilterActionData<DeliveryOrderFilter, DeliveryOrder, DeliveryOrderDetail>(
            "orderId",
            "equals",
        ));

        this.searchListModel.applyFilter(new FilterData<DeliveryOrderFilter, DeliveryOrder, DeliveryOrderDetail>(
            defaultFilter,
            true,
            filterActions,
        ));
    }

    protected abstract get allowOrderPendingCancellationFiltering(): boolean;

    protected renderFilterContent(
        currentFilter: FilterData<DeliveryOrderFilter, DeliveryOrder, DeliveryOrderDetail>) {
        return <DeliveryOrderListFilter
            initFilter={() => this.loadDefaultFilter()}
            searchListModel={this.searchListModel}
            defaultFilter={currentFilter}
            allowOrderPendingCancellationFiltering={this.allowOrderPendingCancellationFiltering}
        />;
    }

    protected renderSorterHeaderContent() {
        return <DeliveryOrderListSort searchListModel={this.searchListModel} />;
    }

    protected loadDefaultFilter(): DeliveryOrderFilter {
        return new DeliveryOrderFilter();
    }

    protected get sortAndFilterColumnsToIgnore() {
        return ["parcelId", "parcelTracker", "weight", "isOrderPendingCancellation" , "orderNonRejectedCancellationRequestStatus", "isContainingProductSet", "palletCode"];
    }

    protected applyQuickFilter(value: any) {
        if (value) {
            this.searchListModel.applyQuickFilter(this.loadDefaultFilter(), +value ?
                [
                    { filterKey: "deliveryOrderId", filterAction: "contains", filterValue: +value },
                    { filterKey: "orderId", filterAction: "contains", filterValue: +value },
                    { filterKey: "ean13List", filterAction: "contains", filterValue: value, rowKey: "details" },
                    { filterKey: "supplierReference", filterAction: "contains", filterValue: value, rowKey: "details" },
                ] :
                [
                    { filterKey: "ean13List", filterAction: "contains", filterValue: value, rowKey: "details" },
                    { filterKey: "supplierReference", filterAction: "contains", filterValue: value, rowKey: "details" },
                ]);
        } else {
            this.searchListModel.resetFilter(this.loadDefaultFilter());
        }
    }

    protected get singleActionsList() {
        return this.actionsList.filter(a => !a.disableSingleActionPicker)
            .filter(a => a.actionEligibilityValidator === undefined || a.actionEligibilityValidator.validate());
    }

    protected renderLine(deliveryOrder: DeliveryOrder) {
        return <DeliveryOrderListLine deliveryOrder={deliveryOrder}
                                      searchListModel={this.searchListModel}
                                      isExpanded={true}
                                      withSingleActionMenu={this.singleActionsList.length > 0}
                                      onSingleActionMenuClick={anchor =>
                                          this.openSingleActionPickerMenu(anchor, deliveryOrder)}
                                      onParcellingPageOpen={() =>
                                          this.props.routing.push(this.getParcellingDetailRoute(deliveryOrder.id))}
                                      onCancellationRequestMenuOpen={event =>
                                          this.cancellationRequestMenuModel.openMenuForElementAndDeliveryOrder(deliveryOrder.id, event)}
                                      onOrderIdQuickFilterEnabled={orderId => this.onOrderIdQuickFilterEnabled(orderId)}/>;
    }

    private getParcellingDetailRoute(deliveryOrderId?: number) {
        const match = matchPath("operations/:site/:operationCode/:batchId/:deliveryOrderId", this.props.location.pathname);

        return deliveryOrderId
            ? this.props.location.pathname.concat(`/${deliveryOrderId}`)
            : this.props.location.pathname.replace(`/${match?.params.deliveryOrderId}`, "");
    }

    protected async reloadDeliveryOrderList() {
        this.searchListModel.resetList(await this.loadList());
        await menuOperationModel.refreshCounters(this.props.params.operationCode, Number(this.props.params.batchId));
    }

    protected renderExportMenu() {
        const handleSearchListMenuItemClick = (strategy: ExportType) => {
            this.renderExcelExport(this.getFilteredList(), strategy);
        };

        return <ExportMenu>
            <MenuItem onClick={() => handleSearchListMenuItemClick(ExportType.AllColumns)} data-testid="exportAllColumnsMenuItem">
                {t("components.exportList.exportAllColumns")}
            </MenuItem>
        </ExportMenu>;
    }

    protected getExportData(sortFilterData: ReadonlyArray<DeliveryOrder>, exportType: ExportType): ExportDataContents {
        return this.dataExporter.getExportData(
            this.props.params.operationCode,
            Number(this.props.params.batchId),
            this.searchListModel.additionalFields,
            sortFilterData,
            exportType,
        );
    }

    protected renderExcelExport(sortFilterData: ReadonlyArray<DeliveryOrder>, exportType: ExportType) {
        const exportData = this.getExportData(sortFilterData, exportType);

        excelDataExporter.export(exportData, [
            this.excelBackgroundDecoratorForExportType(exportType, exportData.headerColumns),
        ]);
    }

    private excelBackgroundDecoratorForExportType(exportType: ExportType, headerColumns: ExportDataValue[]): ExcelBackgroundColorRowDecorator {
        switch (exportType) {
            case ExportType.AllColumns:
                return ExcelBackgroundColorRowDecorator.forHeaderColumn(headerColumns, t("model.deliveryOrder.deliveryOrderId"));
            default:
                return new ExcelBackgroundColorRowDecorator();
        }
    }

    protected renderActions(): JSX.Element | null {
        return null;
    }

    protected get isSelectionEnabled() {
        return false;
    }
}

import { action, computed, observable } from "mobx";

import { BatchProgress } from "../../../model/batch-progress";
import { OperationCounter } from "../../../model/operation-counter";
import { OperationCounterType } from "../../../constants/constants";
import { orderBy } from "lodash";

export type ReportingFilterMode = "delivery-order" | "product" | "parcel";

interface IReportingFilter {
    mode: ReportingFilterMode;
    isDisplayEndedBatch: boolean;
}

export class ReportingModel {

    @observable private _batchProgressList: BatchProgress[] = [];
    @observable private _generalCounterMap: Map<OperationCounterType, number> = new Map<OperationCounterType, number>();
    @observable private _filter: IReportingFilter =
        { mode: "delivery-order", isDisplayEndedBatch: false };

    @computed public get batchProgressList() {
        return this._batchProgressList as ReadonlyArray<BatchProgress>;
    }

    @computed public get generalCounterMap() {
        return this._generalCounterMap as ReadonlyMap<OperationCounterType, number>;
    }

    @computed public get filter() {
        return this._filter as Readonly<IReportingFilter>;
    }

    @action
    public setMode(mode: ReportingFilterMode) {
        this._filter.mode = mode;
    }

    @action
    public toggleIsDisplayEndedBatch() {
        this._filter.isDisplayEndedBatch = !this._filter.isDisplayEndedBatch;
    }

    @action
    public onListLoaded(counterList: OperationCounter[]) {
        this._batchProgressList = [];
        this._generalCounterMap = new Map<OperationCounterType, number>();

        let batchProgress: BatchProgress | undefined;
        orderBy(counterList, ["batchId"]).forEach(counter => {
            if (!batchProgress) {
                batchProgress = new BatchProgress(counter);
            } else if (batchProgress.batchId !== counter.batchId) {
                this._batchProgressList.push(batchProgress);
                batchProgress = new BatchProgress(counter);
            }
            batchProgress.counterMap.set(counter.counterName, counter.counterValue);

            if (!this._generalCounterMap.has(counter.counterName)) {
                this._generalCounterMap.set(counter.counterName, 0);
            }
            this._generalCounterMap.set(counter.counterName,
                this._generalCounterMap.get(counter.counterName)! + counter.counterValue);
        });
        if (batchProgress !== undefined) {
            this._batchProgressList.push(batchProgress);
        }
    }
}

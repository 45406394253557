import React from "react";
import { Dialog, DialogTitle, IconButton, ThemeProvider } from "@mui/material";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import { Close } from "@mui/icons-material";
import { t } from "i18next";
import "./style.scss";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { PalletCloseHandler } from "./close-pallet-handler";
import { PalletCloseDialogScan } from "./close-pallet-dialog-scan";
import { PalletCloseDialogComment } from "./close-pallet-dialog-comment";

interface IPalletCloseDialogProps {
    closingPalletsList: PalletsListItem[];
    getPalletItemByScan: (palletCode: string) => PalletsListItem | undefined;

    onPalletClosed: () => Promise<void>;
    onClose: () => void;
}

export const PalletCloseDialog = (props: IPalletCloseDialogProps) => {

    const handleSingleSubmit = async (pallet: PalletsListItem) => {
        await PalletCloseHandler.handle([pallet], "");
        await props.onPalletClosed();
    };

    const handleSubmit = async (comment: string) => {
        await PalletCloseHandler.handle(props.closingPalletsList, comment);
        await props.onPalletClosed();
        props.onClose();
    };

    return <Dialog open={true}>
        <ThemeProvider theme={VPUITheme}>
            <div className={"pallet-closing-dialog"}
                 data-testid={"pallet-closing-dialog"}>
                <div className="pallet-closing-dialog__close">
                    <IconButton onClick={() => props.onClose()}
                                data-testid={"pallet-close-close"}>
                        <Close />
                    </IconButton>
                </div>
                <DialogTitle>
                    {(t("components.pallet.closing.close"))}
                </DialogTitle>
                {props.closingPalletsList.length === 0 &&
                    <PalletCloseDialogScan getPalletItemByScan={code => props.getPalletItemByScan(code)}
                                             onItemScanned={pallet => handleSingleSubmit(pallet)} />}
                {props.closingPalletsList.length !== 0 &&
                    <PalletCloseDialogComment closingPalletsList={props.closingPalletsList}
                                        onClose={() => props.onClose()}
                                        onSubmit={async comment => await handleSubmit(comment)}/>}
            </div>
        </ThemeProvider>
    </Dialog>;
};

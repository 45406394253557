import "./style.scss";

import * as React from "react";

import { observer } from "mobx-react";

interface IFilterBlockProps {
    label: string;
    filterBlock: JSX.Element;
    className: string;
}

@observer
export class FilterBlock extends React.Component<IFilterBlockProps, {}> {

    public render() {
        const classname = "filterBlock " + this.props.className;

        return <div className={classname}>
            <div className="label">{this.props.label}</div>
            {this.props.filterBlock}
        </div>;
    }
}

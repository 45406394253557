import { Guid } from "guid-typescript";

export class SupportTicketAttachment {
    private readonly _id: Guid;
    private readonly _fileName: string;
    private readonly _file: File;

    constructor(file: File) {
        this._id = Guid.create();
        this._fileName = file.name;
        this._file = file;
    }

    get id(): Guid {
        return this._id;
    }

    get fileName(): string {
        return this._fileName;
    }

    get file(): File {
        return this._file;
    }
}

import { label, domain } from "../../common/dto/dto-annotation";
import { observable } from "mobx";
import { label100, label10, label15, label255 } from "../../domain/label";
import { assign } from "lodash";

export class AddressWarehouseFull {

    constructor(addressWarehouseFull?: AddressWarehouseFull) {
        assign(this, addressWarehouseFull);
    }

    @label("model.addressWarehouseFull.city")
    @domain(label100)
    @observable
    public city: string;

    @label("model.addressWarehouseFull.country")
    @domain(label100)
    @observable
    public country: string;

    @label("model.addressWarehouseFull.houseNo")
    @domain(label10)
    @observable
    public houseNo: string;

    @label("model.addressWarehouseFull.name1")
    @domain(label100)
    @observable
    public name1: string;

    @label("model.addressWarehouseFull.name2")
    @domain(label100)
    @observable
    public name2: string;

    @label("model.addressWarehouseFull.name3")
    @domain(label100)
    @observable
    public name3: string;

    @label("model.addressWarehouseFull.postCode")
    @domain(label15)
    @observable
    public postCode: string;

    @label("model.addressWarehouseFull.refNo")
    @domain(label10)
    @observable
    public refNo: string;

    @label("model.addressWarehouseFull.street")
    @domain(label255)
    @observable
    public street: string;

    @label("model.addressWarehouseFull.title")
    @domain(label100)
    @observable
    public title: string;
}

import { assign } from "lodash";

export interface IProductNameUpdateResponse {
    updatedNames: string[];
    unmatchedNames: string[];
}

export class ProductNameUpdateResponse {
    constructor(productNameUpdateResponse: IProductNameUpdateResponse) {
        assign(this, productNameUpdateResponse);
    }

    public updatedNames: string[] = [];
    public unmatchedNames: string[] = [];
}

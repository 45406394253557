import { UserNotification } from "../../../model/user-notification";

export const UserNotificationMessagesReducer = (list: UserNotification[], message: UserNotification) => {
    if (list.find(item => item.id.equals(message.id)) !== undefined) {
        return list;
    }

    return [...list, ...[message]].sort((message1, message2) => {
        return message2.createdAt.diff(message1.createdAt);
    });
};

import { httpGet, httpPost } from "../common/network/fetch";
import { IMobileToken, MobileToken } from "../common/model/mobile-token";

class AuthenticationService {
    public async login(login: string, password: string) {
        return await httpPost<{access_token: string}>({url: "/auth/login", data: {username: login, password}, failsOnUnauthorized: true});
    }

    public async loginAs(userName: string) {
        return await httpPost<{access_token: string}>({url: "/auth/loginAs", data: {userName}, failsOnUnauthorized: true});
    }

    public async loginWithMobileToken(token: string) {
        return await httpPost<{access_token: string}>({
            url: `/auth/login/mobile/token/${token}`,
        });
    }

    public async token() {
        return await httpPost<{ access_token: string }>({ url: "/auth/token" });
    }

    public async tokenForMobile() {
        return new MobileToken(await httpPost<IMobileToken>({
            url: "/auth/token/mobile",
        }));
    }

    public async tokenForSsoUser(ssoToken: string) {
        return await httpPost<{access_token: string}>({url: "/auth/sso/token", customToken: ssoToken});
    }

    public async logout() {
        await httpPost({url: "/auth/logout", data: {}});
    }

    public async whoami() {
        return await httpGet<{ userName: string}>({url: "/auth/whoami"}, false);
    }
}

export const authenticationService = new AuthenticationService();

import { ShippingAddress } from "./shipping-address";

export class BulkParcelUploadExportLine {
    private readonly _operationCode: string;
    private readonly _batchId: number;
    private readonly _orderId: number;
    private readonly _deliveryOrderId: number;
    private readonly _productId: number;
    private readonly _ean13: string;
    private readonly _supplierReference: string;
    private readonly _productLabel: string;
    private readonly _quantityAvailable: number;
    private readonly _quantityAssigned: number;
    private readonly _quantityStockout: number;
    private readonly _parcelIdentifier: number;
    private readonly _parcelId: number | undefined;
    private readonly _parcelTracker: string | undefined;
    private readonly _carrier: string;
    private readonly _shippingAddress: ShippingAddress;
    private readonly _palletCode: string;
    private readonly _palletExternalId: string;
    private readonly _palletCountry: string;
    private readonly _palletCarrier: string;
    private readonly _palletCreationComment: string;

    constructor(
        operationCode: string,
        batchId: number,
        orderId: number,
        deliveryOrderId: number,
        productId: number,
        ean13: string,
        supplierReference: string,
        productLabel: string,
        quantityAvailable: number,
        quantityAssigned: number,
        quantityStockout: number,
        parcelIdentifier: number,
        carrier: string,
        shippingAddress: ShippingAddress,
        parcelId?: number,
        parcelTracker?: string,
        palletCode?: string,
        palletExternalId?: string,
        palletCountry?: string,
        palletCarrier?: string,
        palletCreationComment?: string,
    ) {
        this._operationCode = operationCode;
        this._batchId = batchId;
        this._orderId = orderId;
        this._deliveryOrderId = deliveryOrderId;
        this._productId = productId;
        this._ean13 = ean13;
        this._supplierReference = supplierReference;
        this._productLabel = productLabel;
        this._quantityAvailable = quantityAvailable;
        this._quantityAssigned = quantityAssigned;
        this._quantityStockout = quantityStockout;
        this._parcelIdentifier = parcelIdentifier;
        this._parcelId = parcelId;
        this._parcelTracker = parcelTracker;
        this._carrier = carrier;
        this._shippingAddress = shippingAddress;
        this._palletCode = palletCode ?? "";
        this._palletExternalId = palletExternalId ?? "";
        this._palletCountry = palletCountry ?? "";
        this._palletCarrier = palletCarrier ?? "";
        this._palletCreationComment = palletCreationComment ?? "";
    }

    get operationCode(): string {
        return this._operationCode;
    }

    get batchId(): number {
        return this._batchId;
    }

    get orderId(): number {
        return this._orderId;
    }

    get deliveryOrderId(): number {
        return this._deliveryOrderId;
    }

    get productId(): number {
        return this._productId;
    }

    get ean13(): string {
        return this._ean13;
    }

    get supplierReference(): string {
        return this._supplierReference;
    }

    get productLabel(): string {
        return this._productLabel;
    }

    get quantityAvailable(): number {
        return this._quantityAvailable;
    }

    get quantityAssigned(): number {
        return this._quantityAssigned;
    }

    get quantityStockout(): number {
        return this._quantityStockout;
    }

    get parcelIdentifier(): number {
        return this._parcelIdentifier;
    }

    get parcelIdOrEmpty(): string {
        return this._parcelId !== undefined ? this._parcelId.toString() : "";
    }

    get parcelTrackerOrEmpty(): string {
        return this._parcelTracker  !== undefined ? this._parcelTracker : "";
    }

    get palletCode(): string {
        return this._palletCode;
    }

    get palletExternalId(): string {
        return this._palletExternalId;
    }

    get palletCountry(): string {
        return this._palletCountry;
    }

    get palletCarrier(): string {
        return this._palletCarrier;
    }

    get palletCreationComment(): string {
        return this._palletCreationComment;
    }

    get carrier(): string {
        return this._carrier;
    }

    get shippingAddress(): ShippingAddress {
        return this._shippingAddress;
    }
}

import { IActionEligibilityValidator } from "./interface-action-eligibility-validator";
import { context } from "../../../../context/context";
import { Permission } from "../../../../context/permission";
import { operationContext } from "../../../../context/operation-context";

class NotEligibleForPalletFlowValidator implements IActionEligibilityValidator {
    public validate(): boolean {
        return !context.hasPermission(Permission.FeaturePalletWorkflow) || (!(operationContext.operation?.isPalletMode ?? true));
    }
}

export const notEligibleForPalletFlowValidator = new NotEligibleForPalletFlowValidator();

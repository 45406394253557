import React from "react";
import {
    CounterType,
    OperationPageType, PalletPageType,
    PreparationPageType,
    PreparationSectionType,
    routeBatchPreparation, routeBulkParcelUpload, routeExportOperationProducts,
    routeConsultShippedParcel, routeDeliveryOrderCanceled, routeImportData,
    routeOperationPreparation, routePalletManagement, routePalletPreparation, routePalletShipping,
    routeParcelManagement, routePickAndPack,
    routePrepareParcelShipping, routeReporting,
    routeShipParcel, routeShippedPallets, routeStockouts,
} from "../../navigation/preparation-route-props";
import { PanelMenuType } from "../../../constants/constants";
import { context } from "../../../context/context";
import { Permission } from "../../../context/permission";
import { operationContext } from "../../../context/operation-context";
import { FeatureToggle } from "../../../context/app-configuration";
import { IHistoryProps } from "../../../common/navigation/ihistory-props";
import { IPanelMenuItem, VpMenu } from "../../../common/component/menu/vp-menu";
import { menuOperationModel } from "./preparation-menu-model";
import { OperationCounterType, PalletCounterType } from "../../constants/constants";
import { updateOperationUrl } from "../../navigation/operation-route-parser";
import { updatePalletUrl } from "../../navigation/pallet-route-parser";
import { VpMenuContextProvider } from "../../../common/component/menu/vp-menu-context";
import { VpMenuCategory } from "../../../common/component/menu/vp-menu-category";
import { VpMenuItem } from "../../../common/component/menu/vp-menu-item";
import { Observer } from "mobx-react";

const counterRouteMap = new Map<PreparationPageType, CounterType>([
    [routeBatchPreparation, "NbDoAvailable"],
    [routeParcelManagement, "NbParcelParcelled"],
    [routePrepareParcelShipping, "NbParcelLabeled"],
    [routeShipParcel, "NbParcelReadyToShip"],
    [routeConsultShippedParcel, "NbParcelShipped"],
    [routeDeliveryOrderCanceled, "NbDoCanceled"],
    [routeStockouts, "NbDoWithStockouts"],
    [routePalletManagement, "NbPalletOpen"],
    [routePalletShipping, "NbPalletClosed"],
    [routeShippedPallets, "NbPalletShipped"],
]);

export interface IVPPreparationMenu extends IHistoryProps {
    defaultRouteExpanded: PreparationSectionType;
}

export const VPPreparationMenu = (props: IVPPreparationMenu) => {
    const menuItemList: Array<IPanelMenuItem<PreparationSectionType, PreparationPageType>> = [{
            defaultExpanded: props.defaultRouteExpanded === routeOperationPreparation,
            icon: "",
            label: "navigation.panel.parcelPreparation",
            key: "OPERATION_PREPARATION" as PanelMenuType,
            route: routeOperationPreparation,
            items: [{
                icon: "archive",
                iconLabel: "navigation.createParcel",
                route: routeBatchPreparation,
            }, {
                icon: "print",
                iconLabel: "navigation.parcelManagement",
                route: routeParcelManagement,
            }, {
                icon: "assignment_turned_in",
                iconLabel: "navigation.prepareParcelShipping",
                route: routePrepareParcelShipping,
                eligibilityValidator: () => context.hasPermission(Permission.FeatureReadyToShip),
            }, {
                icon: "local_shipping",
                iconLabel: "navigation.shipParcel",
                route: routeShipParcel,
                eligibilityValidator: () => !context.hasPermission(Permission.FeaturePalletWorkflow) || !operationContext.isPalletOperationOrEmpty,
            }, {
                icon: "content_paste",
                iconLabel: "navigation.consultShippedParcel",
                route: routeConsultShippedParcel,
                eligibilityValidator: () => !context.hasPermission(Permission.FeaturePalletWorkflow) || !operationContext.isPalletOperationOrEmpty,
            }, {
                icon: "cancel",
                iconLabel: "navigation.cancelDo",
                route: routeDeliveryOrderCanceled,
            }, {
                icon: "report_problem",
                iconLabel: "navigation.stockouts",
                route: routeStockouts,
                eligibilityValidator: () => context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS),
            }, {
                icon: "insert_chart",
                iconLabel: "navigation.reporting",
                route: routeReporting,
            }],
        }, {
            defaultExpanded: props.defaultRouteExpanded === routePalletPreparation,
            icon: "",
            label: "navigation.panel.palletPreparation",
            key: "PALLET_PREPARATION" as PanelMenuType,
            route: routePalletPreparation,
            eligibilityValidator: () => context.hasPermission(Permission.FeaturePalletWorkflow),
            items: [{
                icon: "archive",
                iconLabel: "navigation.managePallets",
                route: routePalletManagement,
            }, {
                icon: "local_shipping",
                iconLabel: "navigation.shipPallets",
                route: routePalletShipping,
            }, {
                icon: "content_paste",
                iconLabel: "navigation.shippedPallets",
                route: routeShippedPallets,
            }],
        }, {
            icon: "",
            label: "navigation.panel.others",
            key: "OTHERS" as PanelMenuType,
            route: routeOperationPreparation,
            items: [{
                icon: "grid_on",
                iconLabel: "navigation.pickAndPack",
                route: routePickAndPack,
                eligibilityValidator: () => !context.hasPermission(Permission.FeatureBlueBrand),
            }, {
                icon: "note_add",
                iconLabel: "navigation.importDataFile",
                route: routeImportData,
            }, {
                icon: "library_add",
                iconLabel: "navigation.bulkParcelUpload",
                route: routeBulkParcelUpload,
            }, {
                icon: "shop_two",
                iconLabel: "navigation.exportOperationProducts",
                route: routeExportOperationProducts,
            }],
        }];

    const isRouteActive = (sectionRoute: PreparationSectionType, route: PreparationPageType) => {
        return props.routing.location.pathname.startsWith(`/${sectionRoute}/${route}`);
    };

    const getBadgeValue = (sectionRoute: PreparationSectionType, route: PreparationPageType) => {
        const counter = !context.hasPermission(Permission.FeatureReadyToShip) && route === routeShipParcel ?
            "NbParcelLabeled" :
            counterRouteMap.get(route);

        if (counter === undefined) {
            return undefined;
        }

        return sectionRoute === "pallet"
            ? menuOperationModel.getPalletCounter(counter as PalletCounterType)
            : menuOperationModel.getCounter(counter as OperationCounterType);
    };

    const changeTab = (sectionRoute: PreparationSectionType, route: PreparationPageType) => {
        if (sectionRoute === "operations") {
            props.routing.push(changeOperationTab(route as OperationPageType));
        } else if (sectionRoute === "pallet") {
            props.routing.push(changePalletTab(route as PalletPageType));
        } else {
            props.routing.push(`/${sectionRoute}/${route}`);
        }
    };

    const changeOperationTab = (route: OperationPageType) => {
        return updateOperationUrl(props.routing.location.pathname, {
            page: route,
        });
    };

    const changePalletTab = (route: PalletPageType) => {
        return updatePalletUrl(props.routing.location.pathname, {
            page: route,
        });
    };

    return <Observer>
        {() => <VpMenuContextProvider>
            <VpMenu menuItemList={menuItemList}>
                {menuItemList.filter(itemPanel => itemPanel.eligibilityValidator ? itemPanel.eligibilityValidator() : true)
                    .map((itemPanel, idx) =>
                        <VpMenuCategory key={idx} itemPanel={itemPanel}>
                            {itemPanel.items.filter(item => item.eligibilityValidator ? item.eligibilityValidator() : true)
                                .map(item =>
                                    <VpMenuItem key={`${itemPanel.route}-${item.route}`} item={item} sectionRoute={itemPanel.route}
                                                isRouteActive={isRouteActive(itemPanel.route, item.route)}
                                                badgeValue={getBadgeValue(itemPanel.route, item.route)}
                                                changeTab={changeTab} />)}
                    </VpMenuCategory>)}
            </VpMenu>
        </VpMenuContextProvider>}
    </Observer>;
};

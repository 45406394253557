import React from "react";
import { pickAndPackModel } from "./pick-and-pack-model";
import { Card, CircularProgress, Typography } from "@mui/material";
import { Icon } from "../../../../common/component/icon/icon";
import { BarCodeIcon } from "../../../../common/component/icon/icon-custom";
import { t } from "i18next";
import { getReadOnlyField } from "../../../../common/field/renderer";
import { observer } from "mobx-react";

@observer
export class PickAndPackHeader extends React.Component {
    public render() {
        const classScanAvailable = pickAndPackModel.isScanProgressing ? "noavailable" : "available";

        return <Card className="pickcontainer">
            <div className="pickinstructions">
                <div className="iconaction">
                    <Icon icon={<BarCodeIcon className="material-icons"/>}
                          className={classScanAvailable}/>
                </div>
                <div className="areaindicator" data-testid="area-indicator">
                    {this.renderIndicator()}
                </div>
                {this.renderScanDetail()}
            </div>
        </Card>;
    }

    private renderScanDetail() {
        if (pickAndPackModel.status === "Labeled") {
            if (pickAndPackModel.area) {
                return <div>
                    <Typography variant="h6">{t("pickAndPack.message.printAndGetArea", {
                        area: pickAndPackModel.area,
                        deliveryOrderId: pickAndPackModel.itemScanned!.deliveryOrderId,
                    })}</Typography>
                </div>;
            } else {
                return <div>
                    <Typography variant="h6">{t("pickAndPack.message.printWithoutArea", {
                        parcelId: pickAndPackModel.itemScanned!.parcelId,
                        deliveryOrderId: pickAndPackModel.itemScanned!.deliveryOrderId,
                    })}</Typography>
                </div>;
            }
        } else if (pickAndPackModel.isScanProgressing) {
            return <div/>;
        }
        if (pickAndPackModel.itemScanned != null) {
            return <div className="itemScanned">
                {this.renderOrderLine()}
                {this.renderArticleLine()}
            </div>;
        }
        if (pickAndPackModel.status === "Error") {
            return <div className="errorMessage">
                <Typography variant="h6" data-testid="ppack-error-message">{pickAndPackModel.errorMessage}</Typography>
            </div>;
        }
        return <div>
            <Typography variant="h6">{t("pickAndPack.message.noItemScanned")}</Typography>
        </div>;
    }

    private renderOrderLine() {
        return <div className="level1-field-container level1 content">
            {getReadOnlyField(pickAndPackModel.itemScanned!, "orderId")}
            {getReadOnlyField(pickAndPackModel.itemScanned!, "parcelId")}
            {getReadOnlyField(pickAndPackModel.itemScanned!, "deliveryOrderId")}
        </div>;
    }

    private renderArticleLine() {
        return <div className="level2-field-container level2 content">
            {getReadOnlyField(pickAndPackModel, "eanScanned")}
            {getReadOnlyField(pickAndPackModel.detailItemScanned!, "labelReference")}
            {getReadOnlyField(pickAndPackModel.detailItemScanned!, "supplierReference")}
        </div>;
    }

    private renderIndicator() {
        if (pickAndPackModel.isScanProgressing) {
            return <CircularProgress className="circular-progress"/>;
        }
        if (pickAndPackModel.status === "HoldInArea") {
            return <Typography className="iconIndicator">
                {pickAndPackModel.area}
            </Typography>;
        }
        if (pickAndPackModel.status === "Labeled") {
            return <Icon icon="print" className="iconIndicator" tooltipTitle="pickAndPack.tooltip.area" tooltipPlacement="top-start"/>;
        }
        if (pickAndPackModel.status === "Error") {
            return <Icon icon="error" className="errorIndicator"/>;
        }
        return <div className="font35 iconIndicator">{t("pickAndPack.action.scan")}</div>;
    }
}

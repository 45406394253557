import React, { useState } from "react";
import { FormHelperText, FormControl, FormLabel, TextField, InputAdornment, CircularProgress } from "@mui/material";
import { SquaredChip } from "../squared-chip/squared-chip";
import { blue } from "@mui/material/colors";
import { t } from "i18next";

import "./style.scss";

interface IScanFieldMultipleProps {
    list?: string[];
    label: string;
    autoFocus: boolean;

    onScan: (scannedValue: string) => Promise<void> | void;
}

export const ScanFieldMultiple = (props: IScanFieldMultipleProps) => {
    const [textValue, setTextValue] = useState("");
    const [list, setList] = useState<string[]>(props.list ?? []);
    const [errorMessage, setErrorMessage] = useState("");
    const [isScanningInProgress, setIsScanningInProgress] = useState(false);

    const handleOnKeyDown = async (keyDown: string) => {
        if (keyDown !== "Enter" || textValue === "") {
            return;
        }

        setIsScanningInProgress(true);
        const cleanTextValue = textValue.trim();
        if (list.find(i => i === cleanTextValue)) {
            setErrorMessage(t("components.scanActionMultiple.duplicate"));
            setIsScanningInProgress(false);
            return;
        }

        try {
            await props.onScan(cleanTextValue);
            setList(array => [...array, cleanTextValue]);
            setErrorMessage("");
        } catch (err) {
            setErrorMessage(err.toString());
        } finally {
            setTextValue("");
            setIsScanningInProgress(false);
        }
    };

    return <div className={"scan-field__container"}>
        <FormControl className={"scan-field__input-container"}
                     variant={"outlined"}
                     fullWidth>
            <FormLabel htmlFor={"multi-scan-field"}>
                {props.label}
            </FormLabel>
            <TextField id={"multi-scan-field"}
                       autoFocus={props.autoFocus}
                       value={textValue}
                       InputProps={{
                           endAdornment: (
                               isScanningInProgress && <InputAdornment position="end">
                                   <CircularProgress color={"inherit"} />
                               </InputAdornment>
                           )}}
                       variant={"outlined"}
                       onKeyDown={async (event: React.KeyboardEvent<any>) => await handleOnKeyDown(event.key)}
                       onChange={event => setTextValue(event.target.value ?? "")}
            />
            {errorMessage && <FormHelperText data-testid={"scan-field-error-text"} error>{errorMessage}</FormHelperText>}
        </FormControl>
        <div className={"scan-field__elements-container"}>
            {list.map(item =>
                <SquaredChip propertyKey={`item-${item}`} color={blue} label={item} />)}
        </div>
    </div>;
};

import { Batch } from "../../../model/batch";
import { SelectAutocomplete } from "../../../../common/component/select-autocomplete/select-autocomplete";
import { stringValue } from "../../../../common/field/renderer";
import * as React from "react";
import { FormatOptionLabelMeta } from "react-select";

export interface IBatchSelectProps {
    list: Batch[];
    onSelect: (item: Batch | null) => void;
    label: string;
    selectedItem: Batch | null;
    propertyKey: string;
    disabled?: boolean;
    placeholder: string;
    noOptionsMessage: string;
}

export const BatchSelect = (props: IBatchSelectProps) => {
    const formatOptionLabel = (batch: Batch, labelMeta: FormatOptionLabelMeta<Batch>) => {
        return labelMeta.context === "menu" ?
            `${batch.id.toString()} (${stringValue(batch, "creationDate")}) : ${batch.statusLabel}` :
            `${batch.id.toString()} (${stringValue(batch, "creationDate")})`;
    };

    return <SelectAutocomplete {...props}
                               getOptionValue={batch => batch.id.toString()}
                               formatOptionLabel={formatOptionLabel} />;
};

import React, { ReactNode, useEffect, useState } from "react";
import { PalletsListItem } from "./pallets-list-item";
import { PalletHeaderLine } from "./pallet-header-line";
import { PalletsListSubSort } from "./pallets-list-sub-sort";
import { PalletContents } from "./pallet-contents";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { PalletsListSubItem } from "./pallets-list-sub-item";
import { PalletFilter } from "./pallet-filter";

interface IPalletsListLineProps {
    pallet: PalletsListItem;
    searchListModel: SearchListModel<PalletsListItem, PalletsListSubItem, PalletFilter>;
    isExpanded: boolean;
    withSingleActionMenu: boolean;
    customItemActionComponent?: ReactNode;

    onSingleActionMenuClick: (anchor: EventTarget) => void;
    onPalletListReload: () => Promise<void>;
}

export const PalletsListLine = (props: IPalletsListLineProps) => {
    const [isExpanded, setIsExpanded] = useState(props.isExpanded);

    useEffect(() => {
        setIsExpanded(props.isExpanded);
    }, [props.isExpanded]);

    return <>
        <PalletHeaderLine key={`dor-${props.pallet.id}`}
                          pallet={props.pallet}
                          displaySingleActionPickerButton={props.withSingleActionMenu}
                          isItemSelected={props.searchListModel.isSelected(props.pallet.id)}
                          isExpanded={isExpanded}
                          customItemActionComponent={props.customItemActionComponent}
                          onExpandToggle={toggleValue => setIsExpanded(toggleValue)}
                          additionalDataSize={props.searchListModel.additionalFields.length}
                          onSelectionToggle={value => props.searchListModel.selectItem(props.pallet, value)}
                          onSingleActionMenuClick={anchor => props.onSingleActionMenuClick(anchor)} />
        {isExpanded && <PalletsListSubSort searchListModel={props.searchListModel}
                                           key={`dod-header-${props.pallet.id}`}
                                           data-testid={`dor-header-detail-${props.pallet.id}`} />}
        {isExpanded && <PalletContents pallet={props.pallet}
                                       reloadPalletList={props.onPalletListReload}
                                       additionalDataFields={props.searchListModel.additionalFields} />}
    </>;
};

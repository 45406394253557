import React from "react";
import { IActionCommand } from "./bulky-action-picker";
import { Menu, MenuItem } from "@mui/material";
import { t } from "i18next";
import { SingleActionPickerHandler } from "./single-action-picker-handler";

interface ISingleActionPickerProps {
    list: Array<IActionCommand<any, any>>;
    anchorElement: HTMLElement | null;
    item: any;
    onActionComplete: (item: IActionCommand<any, any>) => Promise<void> | void;
    onPickerClose: () => void;
}

export const SingleActionPicker = (props: ISingleActionPickerProps) => {
    const filteredActionsList = props.list
        .filter(item => item.singleActionParamEligibilityValidator === undefined ||
            item.singleActionParamEligibilityValidator.validate([props.item]).isSuccess);

    const handleMenuActionItemClick = async (actionItem: IActionCommand<any, any>) => {
        await (new SingleActionPickerHandler(actionItem, props.item)).handle();

        props.onActionComplete(actionItem);
        props.onPickerClose();
    };

    return <Menu id="single-action-picker"
                 anchorPosition={{top: 0, left: 0}}
                 anchorEl={props.anchorElement}
                 open={Boolean(props.anchorElement)}
                 onClose={() => props.onPickerClose()}
                 data-testid="single-action-picker">
        {filteredActionsList.map(item => <MenuItem onClick={async () => await handleMenuActionItemClick(item)}
                                                   date-testid={`single-action-picker-item-${item.value}`}
                                                   key={item.value}>
            {t(item.label)}
        </MenuItem>)}
    </Menu>;
};

import { observable, computed, action } from "mobx";
import { FilterData } from "../filter/filter-data";
import { FilterActionData } from "../filter/filter-action-data";
import { IFieldSort } from "./ifield-sort";
import { ISearchListRow, ISearchListSubRow } from "./search-list";

class CommonSearchListModel<TRow extends ISearchListRow<TSubRow>, TSubRow extends ISearchListSubRow, TFilter extends {}> {

    @observable private _filterData: FilterData<TFilter, TRow, TSubRow>;
    @observable private _isSorterOpened = false;
    @observable private _sort: Map<number, IFieldSort> = new Map<number, IFieldSort>();

    @computed
    public get filterData() {
        return this._filterData;
    }
    @computed
    public get isSorterOpened() {
        return this._isSorterOpened;
    }
    @computed
    public get hasSort() {
        return this._sort && this._sort.size > 0;
    }
    public getSortByLevel(level: number) {
        return this._sort.get(level);
    }
    @action
    public resetFilterData(defaultFilterData: FilterData<TFilter, TRow, TSubRow>) {
        this._filterData = defaultFilterData;
    }

    @action
    public removeFilter(filterAction: FilterActionData<TFilter, TRow, TSubRow>) {
        this._filterData.removeFilterActionList([filterAction.filterField]);
    }

    @action
    public clickSort() {
        this._isSorterOpened = !this._isSorterOpened;
    }

    @action
    public applySort(field: string, priority: number) {
        const sort = this._sort.get(priority);
        if (!sort || sort.fieldName !== field) {
            this._sort.set(priority, { fieldName: field, priority, sortOrder: "asc" });
        } else if (sort.sortOrder === "asc") {
            sort.sortOrder = "desc";
        } else {
            sort.sortOrder = "asc";
        }
    }

    @action
    public resetSort() {
        this._sort = new Map<number, IFieldSort>();
    }
}

export const commonSearchListModel = new CommonSearchListModel<any, any, any>();

import { DeliveryOrderDetail } from "../../../../model/delivery-order-detail";

export class ProductExportStrategy {

    public static AllPerLine = new ProductExportStrategy(
        "all-per-line",
        deliveryOrderDetail => Array<number>(1).fill(deliveryOrderDetail.quantityNotParcelled),
    );

    public static SinglePerLine = new ProductExportStrategy(
        "single-per-line",
        deliveryOrderDetail => Array<number>(deliveryOrderDetail.quantityNotParcelled).fill(1),
    );

    private constructor(private id: string, private quantitiesPerLineFn: (deliveryOrderDetail: DeliveryOrderDetail) => number[]) {
    }

    public toString(): string {
        return this.id;
    }

    public getQuantitiesPerLine(deliveryOrderDetail: DeliveryOrderDetail): number[] {
        return this.quantitiesPerLineFn(deliveryOrderDetail);
    }

    public static fromString(strStrategy: string): ProductExportStrategy {
        switch (strStrategy) {
            case ProductExportStrategy.AllPerLine.id:
                return ProductExportStrategy.AllPerLine;
            case ProductExportStrategy.SinglePerLine.id:
                return ProductExportStrategy.SinglePerLine;
        }
        throw Error("Unknown ProductExportStrategy");
    }
}

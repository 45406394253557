import "./style.scss";

import * as React from "react";

import {
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select as SelectMI, SelectChangeEvent,
    TextField,
} from "@mui/material";

import { ISelectItem } from "./iselect-item";
import { InputAbstract } from "./input-abstract";
import { InputReadonlyValue } from "./input-readonly-value";
import { observer } from "mobx-react";
import { t } from "i18next";
import { InputSelect } from "./input-select";

export type InputComponentType = "textField" | "select" | "boolean" | "autocomplete" | "linkable" | "radioList" | "selectAutocomplete";

interface IInputProps {
    htmlType?: string | undefined;
    autoFocus?: boolean;
    inputRef?: (instance: any) => any;
    inputComponent?: InputComponentType;
}

@observer
export class Input extends InputAbstract<IInputProps, string> {

    protected renderDisabledContent() {
        if (this.props.inputComponent === "boolean" || this.props.inputComponent === "radioList" || this.props.htmlType === "password") {
            return this.renderInput();
        }

        return <InputReadonlyValue
            propertyKey={this.props.propertyKey}
            value={this.props.value}
            inputComponent={this.props.inputComponent}
            options={this.props.options} />;
    }

    protected get inputClassName() { return "single"; }

    protected renderInput() {
        const inputComponent = this.props.inputComponent || "textField";
        if (inputComponent === "textField" || inputComponent === "linkable") {
            return this.renderTextField();
        }
        if (inputComponent === "boolean") {
            return this.renderRadioList([
                { value: `${true}`, display: "components.radio.true" },
                { value: `${false}`, display: "components.radio.false" },
            ]);
        }
        if (inputComponent === "select") {
            return this.renderSelect();
        }
        if (inputComponent === "selectAutocomplete") {
            return this.renderSelectAutocomplete();
        }
        if (inputComponent === "radioList") {
            return this.renderRadioList(this.props.options);
        }
        throw new Error(`"${inputComponent}" not implmeneted yet`);
    }

    private renderTextField() {
        return <TextField id={this.props.propertyKey}
            className="input-field"
            error={this.hasError}
            label={this.props.isLabelShrunk && this.props.label}
            value={this.props.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChange(e)}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => this.onKeyDown(e)}
            type={this.props.htmlType}
            inputRef={this.props.inputRef}
            autoFocus={this.props.autoFocus} />;
    }

    private renderSelectAutocomplete() {
        return <InputSelect
            list={this.props.options === undefined ? [] : this.props!.options?.map(o => ({label: o.display, value: o.value}))}
            label={""}
            selectedItem={this.props.value === undefined ? null : {label: this.props.options?.find(o => o.value === this.props.value)?.display || "", value: this.props.value}}
            propertyKey={"input-select-autocomplete"}
            placeholder={""}
            disabled={this.props.disabled}
            noOptionsMessage={"no results"}
            onSelect={item => this.props.onChange(item == null ? undefined : item.value)}
        />;
    }

    private renderSelect() {
        if (!this.props.options) {
            throw new Error("You need to define props.options for a select element.");
        }
        return <SelectMI
            id={this.props.propertyKey}
            className={`select-field ${this.props.disabled ? "disabled" : "enabled"}`}
            error={this.hasError}
            value={this.props.value}
            data-testid={`select-field-${this.props.propertyKey}`}
            onChange={e => this.onChange(e)}
            type={this.props.htmlType}
            disabled={this.props.disabled}
            autoFocus={this.props.autoFocus}
        >
            {this.props.options!.map(item => <MenuItem
                data-testid={`select-option-${item.value}`}
                value={item.value}
                key={item.value}>{t(item.display)}</MenuItem>)}
        </SelectMI>;
    }

    private renderRadioList(radioValueList?: Array<ISelectItem<string>>) {
        if (!radioValueList) {
            throw new Error("You need to define props.options for a radioList element.");
        }
        return <RadioGroup
            value={this.props.value}
            onChange={e => this.onChange(e)}
            style={{ flexDirection: "row" }}
        >
            {radioValueList.map(value =>
                <FormControlLabel
                    key={value.value}
                    value={value.value} control={<Radio />}
                    label={t(`${value.display}`)}
                    disabled={this.props.disabled} />,
            )}
        </RadioGroup>;
    }

    private onChange(e: SelectChangeEvent<any>) {
        this.props.onChange((e.target as any).value);
    }

    private onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e.key);
        }
    }
}

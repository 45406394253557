import { Dictionary } from "lodash";
import { DeliveryOrder } from "../../../../model/delivery-order";
import { map, size } from "lodash";
import { DeliveryOrderDetail } from "../../../../model/delivery-order-detail";
import { ParcelDetail } from "../../../../model/parcel-detail";
import { Parcel } from "../../../../model/parcel";
import { t } from "i18next";

export class UploadedParcel {
    private readonly _id?: number;
    private readonly _tracker?: string;
    private readonly _carrierName?: string;
    private readonly _contents: Dictionary<number>;

    constructor(contents: Dictionary<number>, tracker?: string, carrierName?: string, id?: number) {
        this._id = id;
        this._tracker = tracker;
        this._carrierName = carrierName;
        this._contents = contents;

        if (this.carrierNameOrEmpty === "" && this.trackerOrEmpty !== "") {
            throw new Error(t("bulkParcelUpload.error.trackingDataWithoutCarrierViolation", {
                parcelTracker: this.trackerOrEmpty,
            }));
        }
    }

    get id(): number | undefined {
        return this._id;
    }

    get contents(): Dictionary<number> {
        return this._contents;
    }

    get carrierNameOrEmpty(): string {
        return this._carrierName ?? "";
    }

    get trackerOrEmpty(): string {
        return this._tracker ?? "";
    }

    public get isSubmitted(): boolean {
        return this._id !== undefined;
    }

    public isUploadedContentUpdated(originalContent: ParcelDetail[]): boolean {
        return originalContent.length !== size(this._contents) ||
            originalContent.some(op => this._contents[op.productId] !== op.quantity);
    }

    public toParcelDetails(deliveryOrder: DeliveryOrder): ParcelDetail[] {
        return map(this._contents,
            (quantity: number, productId: string) => this.buildParcelDetail(deliveryOrder.getDetailByProductId(Number(productId)), quantity));
    }

    private buildParcelDetail(deliveryOrderDetail: DeliveryOrderDetail, quantity: number): ParcelDetail {
        const parcelDetail = new ParcelDetail();

        parcelDetail.assignDeliveryOrderDetailData(deliveryOrderDetail);
        parcelDetail.quantity = quantity;
        parcelDetail.initialQuantity = quantity;

        return parcelDetail;
    }

    public isCarrierUpdatedForParcel(parcel: Parcel) {
        return parcel.carrier !== this.carrierNameOrEmpty;
    }

    public isParcelTrackerUpdatedForParcel(parcel: Parcel) {
        return parcel.parcelTracker !== this.trackerOrEmpty;
    }

    public isParcelTrackerOrCarrierRemovedFromParcel(parcel: Parcel) {
        return parcel.parcelTracker !== "" && this.trackerOrEmpty === "" ||
            parcel.carrier !== "" && this.carrierNameOrEmpty === "";
    }

    public isParcelTrackerValid(deliveryOrder: DeliveryOrder, parcel: Parcel) {
        return [
            deliveryOrder.orderId.toString(),
            deliveryOrder.deliveryOrderId.toString(),
            parcel.id?.toString() ?? "",
            `VP${deliveryOrder.orderId}`,
            `VP${deliveryOrder.deliveryOrderId}`,
            `VP${parcel.id ?? ""}`,
        ].every(f => f !== this._tracker);
    }
}

import * as React from "react";
import { dialogModel } from "../../../../common/component/dialog/dialog-model";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { palletService } from "../../../services/pallet";
import { t } from "i18next";
import { IconButton, Tooltip } from "@mui/material";
import { Cancel } from "@mui/icons-material";

import "./style.scss";

export interface IPalletParcelRemoveProps {
    palletId: string;
    palletCode: string;
    parcelId: number;

    onParcelRemoved?: () => Promise<void>;
}

export class PalletParcelRemove extends React.Component<IPalletParcelRemoveProps, {}> {
    public render() {
        return <Tooltip title={t("components.pallet.removeParcel.tooltipTitle")}>
            <IconButton className={"pallet-parcel-remove__icon"}
                        color={"primary"}
                        onClick={() => this.palletParcelRemoveConfirmBox()}>
                <Cancel />
            </IconButton>
        </Tooltip>;
    }

    private palletParcelRemoveConfirmBox() {
        dialogModel.confirmBox(
            t("components.pallet.removeParcel.confirmTitle"),
            t("components.pallet.removeParcel.confirmDescription", {
                palletCode: this.props.palletCode,
            }),
            async () => await this.executePalletParcelRemove());
    }

    private async executePalletParcelRemove() {
        await palletService.removeParcelFromPallet(this.props.palletId, this.props.parcelId);

        if (this.props.onParcelRemoved) {
            await this.props.onParcelRemoved();
        }

        notificationModel.addSuccessMessage(t("components.pallet.removeParcel.success", {
            palletCode: this.props.palletCode,
        }));
    }
}

import * as React from "react";

import { ThemeProvider } from "@mui/material";
import { PrinterSettings } from "./printer-settings";
import { VPUITheme } from "../../../common/theme/vpui-theme";
import { GeneralSettings } from "./general-settings";

import "./style.scss";

export const Settings = () => {
    return <ThemeProvider theme={VPUITheme}>
        <div className={"settings"}>
            <div className={"settings__card-container"}>
                <GeneralSettings />
                <PrinterSettings />
            </div>
        </div>
    </ThemeProvider>;
};

import { computed } from "mobx";
import { DocumentFormat } from "../document-printer";
import { IParcelDocument } from "./parcel-document";

export class MultiParcelDocument implements IParcelDocument {
    private readonly _parcelIds: number[];
    private readonly _documentFormat: DocumentFormat;
    private readonly _contents: File | undefined;

    constructor(parcelId: number[], documentFormat: DocumentFormat, contents: File | undefined) {
        this._parcelIds = parcelId;
        this._documentFormat = documentFormat;
        this._contents = contents;
    }

    public get parcelIds(): number[] {
        return this._parcelIds;
    }

    public get contents(): File | undefined {
        return this._contents;
    }

    get documentFormat(): DocumentFormat {
        return this._documentFormat;
    }

    @computed
    public get hasContents(): boolean {
        return this._contents !== undefined;
    }
}

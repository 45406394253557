import React from "react";
import {
    useLocation,
    useNavigate,
    useParams,
    useMatch,
} from "react-router-dom";

/** @deprecated Use `React Router hooks` instead */
export interface IWithRouterProps {
    location: ReturnType<typeof useLocation>;
    params: Record<string, string>;
    navigate: ReturnType<typeof useNavigate>;
    match: ReturnType<typeof useMatch>;
}

/** @deprecated Use `React Router hooks` instead */
export const withRouter = <Props extends IWithRouterProps>(Component: React.ComponentType<Props>) => {
    return (props: Omit<Props, keyof IWithRouterProps>) => {
        const location = useLocation();
        const params = useParams();
        const navigate = useNavigate();
        const match = useMatch("");

        return (
            <Component
                {...(props as Props)}
                location={location}
                params={params}
                navigate={navigate}
                match={match}
            />
        );
    };
};

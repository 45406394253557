import * as React from "react";

import { TableCell, TableRow } from "@mui/material";
import { ListSort } from "../../../../common/component/list/search-list/list-sort";
import { observer } from "mobx-react";
import { PalletsListItem } from "./pallets-list-item";
import { PalletsListSubItem } from "./pallets-list-sub-item";
import { PalletFilter } from "./pallet-filter";

@observer
export class PalletsListSubSort extends ListSort<PalletsListItem, PalletsListSubItem, PalletFilter, {}> {
    public render() {
        return <TableRow className="search-item__sub-header">
            <TableCell />
            {this.renderColumnSort("model.parcel.operationCode", "details.operationCode")}
            {this.renderColumnSort("model.parcel.batchId", "details.batchId")}
            {this.renderColumnSort("model.parcel.parcelId", "details.id")}
            {this.renderColumnSort("model.parcel.deliveryOrderId", "details.deliveryOrderId")}
            {this.renderColumnSort("model.parcel.orderId", "details.orderId")}
            {this.renderColumnSort("model.parcel.parcelTracker", "details.parcelTracker")}
            {this.renderColumnSort("model.parcel.zipCode", "details.zipCode")}
            {this.renderColumnSort("model.deliveryOrderDetail.weight", "details.weight")}
            {this.renderAdditionalColumnSubSort()}
        </TableRow >;
    }
}

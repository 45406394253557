export interface IBulkParcelUploadImportValidationConstraint {
    validateHeader(headerColumns: string[]): boolean;
    validateLine(index: number, content: Map<string, string>): boolean;
}

export class BulkParcelUploadImportValidator {
    private readonly _validatorsList: IBulkParcelUploadImportValidationConstraint[];

    public constructor(validatorsList: IBulkParcelUploadImportValidationConstraint[]) {
        this._validatorsList = validatorsList;
    }

    public validateHeader(headerColumns: string[]) {
        return this._validatorsList
            .every(validator => validator.validateHeader(headerColumns));
    }

    public validateLine(index: number, content: Map<string, string>) {
        return this._validatorsList
            .every(validator => validator.validateLine(index, content));
    }
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoginPageLayout } from "../../../common/component/login/login-page-layout";
import { SynchronizedHistory } from "mobx-react-router";
import { Card, CardHeader, ThemeProvider } from "@mui/material";
import { ResetPasswordForm } from "./reset-password-form";
import { TokenValidationForm } from "./token-validation-form";
import { VPUITheme } from "../../../common/theme/vpui-theme";
import { ResetPasswordNotification } from "./reset-password-notification";
import { t } from "i18next";

import "./style.scss";

interface IChangePasswordProps {
    routing: SynchronizedHistory;
}

enum ChangePasswordStage {
    TokenValidation,
    PasswordForm,
    Notification,
}

export const ChangePassword = (props: IChangePasswordProps) => {
    const [userName, setUserName] = useState("");
    const [isPasswordChanged, setPasswordChanged] = useState(false);
    const [stage, setStage] = useState<ChangePasswordStage>(ChangePasswordStage.TokenValidation);
    const params = useParams();

    const handleCancel = () => {
        props.routing.replace("/login");
    };

    useEffect(() => {
        if (isPasswordChanged) {
            setStage(ChangePasswordStage.Notification);

            return;
        }

        setStage(userName ? ChangePasswordStage.PasswordForm : ChangePasswordStage.TokenValidation);
    }, [userName, isPasswordChanged]);

    return <ThemeProvider theme={VPUITheme}>
        <LoginPageLayout>
            <section className={"form password-reset"}>
                <Card className={"container"}>
                    <CardHeader title={t("components.passwordChange.title")} />
                    {stage === ChangePasswordStage.TokenValidation &&
                        <TokenValidationForm token={decodeURIComponent(params.token ?? "")}
                                             onComplete={setUserName}
                                             onCancel={handleCancel} />}
                    {stage === ChangePasswordStage.PasswordForm &&
                        <ResetPasswordForm token={decodeURIComponent(params.token ?? "")}
                                           userName={userName}
                                           onComplete={() => setPasswordChanged(true)}
                                           onCancel={handleCancel} />}
                    {stage === ChangePasswordStage.Notification &&
                        <ResetPasswordNotification text={t("components.passwordChange.success")} onClose={handleCancel}/>}
                </Card>
            </section>
        </LoginPageLayout>
    </ThemeProvider>;
};

import { httpGetList, httpPut } from "../../common/network/fetch";
import { Guid } from "guid-typescript";

export class RemotePrinting {
    public async list(printerId: Guid) {
        return (await httpGetList<string>({
            url: `/remote-printing/list/active/${printerId}`,
        })).map(i => Guid.parse(i));
    }

    public async confirmPrintout(documentId: Guid) {
        return httpPut({
            url: `/remote-printing/complete/${documentId}`,
        });
    }
}

export const remotePrintingService = new RemotePrinting();

import * as React from "react";

import { inject, observer } from "mobx-react";

import { IHistoryProps } from "../../../common/navigation/ihistory-props";
import { Settings } from "./settings";
import { HeaderWidgetGroup } from "../../../common/component/header/header-widget-group";
import { Breadcrumbs } from "../../../common/component/breadcrumbs/breadcrumbs";
import { LanguageSelector } from "../../../common/component/language-selector/language-selector";
import { context } from "../../../context/context";
import { UserAccountWidget } from "../user-account-widget/user-account-widget";
import { Header } from "../../../common/component/header/header";
import { authenticationService } from "../../../services/authentication";
import { LoaderIndicatorWidget } from "../../../common/component/loader/loader-indicator-widget";
import { PalletPickupFormWidget } from "../pallet-pickup/pallet-pickup-form-widget";
import { Permission } from "../../../context/permission";
import { FrontendBreadcrumbsBuilder } from "../../../common/component/breadcrumbs/frontend-breadcrumbs-builder";
import { t } from "i18next";

@inject("routing")
@observer
export class ControlPanelRouter extends React.Component<IHistoryProps, {}> {

    public render() {
        return <div className="pageView">
            <div className="page-paper">
                <div className={"pageHeader"}>
                    <Header routing={this.props.routing}>
                        <HeaderWidgetGroup>
                            <Breadcrumbs routing={this.props.routing} builder={new FrontendBreadcrumbsBuilder()} />
                        </HeaderWidgetGroup>
                        <HeaderWidgetGroup>
                            <LoaderIndicatorWidget />
                            {context.isSupplier && context.hasPermission(Permission.FeaturePalletPickUpForm) &&
                                <PalletPickupFormWidget />}
                            <LanguageSelector locale={context.settings.lang} />
                            <UserAccountWidget name={context.currentUser.name}
                                               logoutText={t(context.isImpersonatedUser ? "navigation.backToBackoffice" : "navigation.logout")}
                                               onSettingsOpen={() => this.settings()}
                                               onBusinessDocumentationOpen={() => this.businessDocumentation()}
                                               onLogout={() => this.logout()} />
                        </HeaderWidgetGroup>
                    </Header>
                </div>
                <div className={"page-content__wrapper"}>
                    <Settings/>
                </div>
            </div>
        </div>;
    }

    private settings() {
        this.props.routing.replace("/settings");
    }

    private businessDocumentation() {
        this.props.routing.replace("/business-documentation");
    }

    private async logout() {
        if (context.isImpersonatedUser) {
            context.clearImpersonation();
            window.location.replace(`${window.location.origin}/back-office/user`);
        } else {
            await authenticationService.logout();
            this.props.routing.replace("/login");
            context.reset();
        }
    }
}

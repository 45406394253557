export const paginationSize = 25;
export const lithiumUnCodeList: string[] = ["3090", "3091", "3480", "3481"];

export type LabelFormatType = "Pdf" | "Zebra";

export type ParcelDocumentType = "PdfLabel203dpi" | "ZebraLabel203dpi" | "PreparationOrder" | "LabelAndPreparationOrderOnSamePage" | "LabelAndPreparationOrderOnConsecutivePages" | "DeliverySlip";

export type ParcelBulkCreationMode = "OneParcelPerOrder" | "OneParcelPerProductItem" | "OneParcelPerOrderWithMonoRef" | "OneParcelPerOrderWithMultiRef" | "OneParcelPerComponentProduct" | "OneParcelPerProductWithMonoParcelFlag";

export type LocaleType = "en-GB" | "fr-FR" | "es-ES" | "it-IT";

export type HttpMethodType = "GET" | "GETLIST" | "POST" | "PUT" | "PATCH" | "DELETE";

export type PrinterExtensionType = "Pdf" | "Zpl";
export type PrinterPaperFormat = "A4" | undefined;
export const printerExtensionList: PrinterExtensionType[] = ["Pdf", "Zpl"];

export function getPrinterExtension(labelFormat: LabelFormatType): PrinterExtensionType {
    if (labelFormat === "Pdf") {
        return "Pdf";
    }
    if (labelFormat === "Zebra") {
        return "Zpl";
    }
    return "Pdf";
}

export function getPrinterLabelExtension(printerExtensionType: PrinterExtensionType): string {
    if (printerExtensionType === "Pdf") {
        return "Pdf";
    }
    if (printerExtensionType === "Zpl") {
        return "Zpl (Zebra)";
    }
    return "Pdf";
}

export function getLabelFormat(printerExtensionType: PrinterExtensionType): LabelFormatType {
    if (printerExtensionType === "Pdf") {
        return "Pdf";
    }
    if (printerExtensionType === "Zpl") {
        return "Zebra";
    }
    return "Pdf";
}

export type NotificationKeyType = "QzConnection";

export type DeliveryOrderStatusType = "Available" | "Cancelled" | "Parcelled" | "Labeled" | "Shipped";
export type CampaignStatus = "Scheduled" | "Cancelled";

export const roleAdmin: UserRoleType = "Administrator";
export const roleSuperAdmin: UserRoleType = "SuperAdministrator";
export const roleSupplier: UserRoleType = "Supplier";
export const roleSupervisor: UserRoleType = "Supervisor";
export const roleMerchant: UserRoleType = "Merchant";
export const roleInternalService: UserRoleType = "Service";
export const roleReadyToShipFeature: FeatureRoleType = "FeatureReadyToShip";
export const roleBlueBrand: FeatureRoleType = "FeatureBlueBrand";
export const roleExposeShippingAddress: FeatureRoleType = "FeatureExposeShippingAddress";
export const rolePalletPickUpForm: FeatureRoleType = "FeaturePalletPickUpForm";
export const rolePalletWorkflow: FeatureRoleType = "FeaturePalletWorkflow";
export const roleFeatureManageOperationAccess: FeatureRoleType = "FeatureManageOperationAccess";
export const roleBiDataExtractor: UserRoleType = "BiDataExtractor";
export const roleReadOnlyIntegrator: UserRoleType = "ReadOnlyIntegrator";

export type UserRoleType = "SuperAdministrator" | "Administrator" | "Supplier" | "Supervisor" | "Merchant" | "Service" | "BiDataExtractor"| "ReadOnlyIntegrator";
export type FeatureRoleType = "FeatureReadyToShip" | "FeatureBlueBrand" | "FeatureExposeShippingAddress" | "FeaturePalletPickUpForm" | "FeaturePalletWorkflow" | "FeatureMondialRelayMixedPalletsAll" | "FeatureMondialRelayMixedPalletsFR_BNL_IT" | "FeatureMixedPalletsAny" | "FeatureManageOperationAccess";
export type UserAttribute = "ClickAndShipUser" | "ApiUser";

export type AuthScheme = "sso" | "ds" | "none";

export const A4LandscapePrinterConfig = {
    orientation: "landscape",
    margins: 0.25,
    size: navigator.platform.toLowerCase().indexOf("mac") >= 0 ? undefined : {
        width: 8.27,
        height: 11.69,
    },
};

export const A4AutoPrinterConfig = {
    margins: 0,
    size: {
        width: 8.27,
        height: 11.69,
    },
};

export type PanelMenuType = "OPERATION_PREPARATION" | "PALLET_PREPARATION" | "OTHERS" | "ADMINISTRATION";

export enum DangerousGoods {
    None,
    Lithium = "Lithium",
    LiquidQuantity = "Liquid Quantity",
    Both = "Lithium, Liquid Quantity",
}

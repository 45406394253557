import { IDomain, HtmlType } from "../common/dto/idomain";
import { RegexValidator } from "../common/validators/regex-validator";
import { InputComponentType } from "../common/component/input/input";

class Email implements IDomain<string> {

    public htmlType: HtmlType = "text";
    public inputComponent: InputComponentType = "linkable";

    public format(valueToFormat?: string) {
        if (valueToFormat) {
            return valueToFormat;
        }
        return "";
    }

    public unformat(valueToUnformat?: string) {
        if (valueToUnformat) {
            return valueToUnformat.substring(0, 128);
        }
        return valueToUnformat;
    }

    public validators = [
        new RegexValidator(/\S+@\S+\.\S+/, "error.general.invalidEmail"),
    ];
}

export const email = new Email();

import { t } from "i18next";

class MandatoryMultipleValidator implements IValidator {
    public validate(valueToCheck: string[] | undefined): string | undefined {
        if (valueToCheck === undefined || valueToCheck.length === 0) {
            return t("error.general.mandatory.field");
        }
        return;
    }
}

export const mandatoryMultipleValidator = new MandatoryMultipleValidator();

import { observable, computed, action } from "mobx";
import { remove, some, Dictionary } from "lodash";
import { AdditionalValues } from "./additional-values";
import { notificationModel } from "../../notifications/notification-model";
import { operationsService } from "../../../../front-office/services/operations";
import { AddAdditionalValueCommandReturn } from "../../../../front-office/model/add-additional-values-command-return";
import { t } from "i18next";
import {AdditionalValuesItem} from "./additional-values-item";
import {AdditionalValuesLocator} from "./additional-values-locator";

export class ImportFileModel {

    private _fileImportData: Array<Map<string, string>>;
    private _selectedOperationCode: string;
    private _parsedData: AdditionalValues;

    @observable private _importActiveStep: number;
    @observable private _fileImportLabel: string;
    @observable private _refColumnsSelected: string[];
    @observable private _selectedCheckBoxList: string[];
    @observable private _executionReturnValues: AddAdditionalValueCommandReturn;

    @observable private _refPropertyColumnToReferenceMap: Dictionary<string>;
    @observable private _refPropertyError: string[];
    @observable private _inputFileSelected: string | undefined;
    @observable private _fileSelectionError: string[];
    @observable private _availableReferenceColumns: any[];

    public constructor(operationCode: string) {
        this.init(operationCode);
    }

    @action
    public init(operationCode: string) {
        this._selectedOperationCode = operationCode;
        this._fileImportLabel = t("components.importList.importFileLabel");
        this._importActiveStep = 0;
        this._refColumnsSelected = [];
        this._selectedCheckBoxList = [];
        this._refPropertyColumnToReferenceMap = {};
        this._refPropertyError = [];
        this._inputFileSelected = undefined;
        this._fileSelectionError = [];
        this._availableReferenceColumns = [
            { display: t("model.deliveryOrderDetail.productId"), value: "ProductId", group: "Product" },
            { display: t("model.deliveryOrderDetail.ean13List"), value: "BarCodes", group: "Product" },
            { display: t("model.deliveryOrderDetail.supplierReference"), value: "SupplierReference", group: "Product" },
            { display: t("model.deliveryOrderDetail.name"), value: "ProductName", group: "Product"},
            { display: t("model.deliveryOrder.deliveryOrderId"), value: "DeliveryOrderId", group: "DeliveryOrder"},
        ];
    }

    private static emptyReferenceColumn = { display: "", value: "", group: "None" };

    private getReferenceGroupFromValue(value: string): string {
        return this._availableReferenceColumns
            .find(column => column.value === value)!
            .group;
    }

    @computed get fileImportLabel(): string {
        return this._fileImportLabel;
    }

    @computed get inputFileSelected(): string | undefined {
        return this._inputFileSelected;
    }

    @computed get fileSelectionError(): string[] {
        return this._fileSelectionError;
    }

    @computed get refColumnSelected(): string[] {
        return this._refColumnsSelected;
    }

    @computed get refPropertyColumnToReferenceMap(): Dictionary<string> {
        return this._refPropertyColumnToReferenceMap;
    }

    @computed get availableReferenceColumns(): any[] {
        return this._availableReferenceColumns;
    }

    public refPropertyColumnValue(column: string): string {
        return this._refPropertyColumnToReferenceMap.hasOwnProperty(column) ?
            this._refPropertyColumnToReferenceMap[column] :
            "";
    }

    @computed get selectedCheckBoxList(): string[] {
        return this._selectedCheckBoxList;
    }

    @computed get importActiveStep(): number {
        return this._importActiveStep;
    }

    @computed get refPropertyError(): string[] {
        return this._refPropertyError;
    }

    @computed
    public get executionReturnValues() {
        return this._executionReturnValues;
    }

    public get fileImportData(): Array<Map<string, string>> {
        return this._fileImportData;
    }

    public get selectedOperationCode() {
        return this._selectedOperationCode;
    }

    public referencePropertyArray(forColumn: string) {
        const selectedReferenceGroups = this.listSelectedReferenceGroups();
        const isColumnMappedToAnyReference = this.isColumnMappedToAnyReference(forColumn);

        if (isColumnMappedToAnyReference) {
            remove(selectedReferenceGroups, value =>
                value === this.getReferenceGroupFromValue(this._refPropertyColumnToReferenceMap[forColumn]));
        }

        const references = this._availableReferenceColumns
            .filter(property => !selectedReferenceGroups.find(value => value === property.group));

        if (references.length > 0 && isColumnMappedToAnyReference) {
            references.unshift(ImportFileModel.emptyReferenceColumn);
        }

        return references;
    }

    @action
    public changeFileImportLabel(labelValue: string) {
        this._fileImportLabel = labelValue;
    }

    @action
    public handleCheckBoxClick(value: string) {
        if (this.checkboxIsChecked(value)) {
            remove(this._selectedCheckBoxList, v => {
                return v === value;
            });
        } else {
            this._selectedCheckBoxList.push(value);
        }
    }

    @action
    public executionReportingStep() {
        this._importActiveStep = -1;
    }

    @action
    public prevStepImport() {
        this._importActiveStep -= 1;
    }

    @action
    public nextStepImport() {
        switch (this._importActiveStep) {
            case 0:
                this.validateFileSelection();
                break;
            case 1:
                this.validationColumnsSelection();
                break;
            case 2:
                this.sendAdditionalValues();
                break;
        }
    }

    @action
    public updateImportReturnValues(returnValue: AddAdditionalValueCommandReturn) {
        this._executionReturnValues = returnValue;
    }

    @action
    public changeRefProperty(importColumn: string, newReference: any) {
        if (newReference === "") {
            this.unsetRefProperty(importColumn);
            this.refreshPropertyColumnToReferenceMap();
            return;
        }

        if (this._refColumnsSelected.find(v => v === importColumn) === undefined) {
            this._refColumnsSelected.push(importColumn);
        }
        this._refPropertyColumnToReferenceMap[importColumn] = newReference;
        this._refPropertyError = [];
    }

    @action
    private unsetRefProperty(importColumn: string) {
        remove(this._refColumnsSelected, v => v === importColumn);
    }

    @action
    private refreshPropertyColumnToReferenceMap() {
        const previousPropertyMap = this._refPropertyColumnToReferenceMap;

        this._refPropertyColumnToReferenceMap = {};
        this._refColumnsSelected.forEach(column => {
            this._refPropertyColumnToReferenceMap[column] = previousPropertyMap[column];
        });
    }

    public isColumnMappedToAnyReference(column: string): boolean {
        return this._refPropertyColumnToReferenceMap.hasOwnProperty(column);
    }

    @action
    public changeInputFileSelected(fileName: string | undefined) {
        this._inputFileSelected = fileName;
    }

    @action
    public changeFileSelectionError(errors: string[]) {
        this._fileSelectionError = errors;
    }

    public updateFileImportData(data: Array<Map<string, string>>) {
        this._fileImportData = data;
    }

    public selectOperationCode(operationCode: string) {
        this._selectedOperationCode = operationCode;
    }

    public checkboxIsChecked(value: string) {
        return some(this._selectedCheckBoxList, val => val === value);
    }

    private validateFileSelection() {
        this._fileSelectionError = []; // clear error on "file selection" field

        if (this._inputFileSelected) {
            this._importActiveStep += 1;
        } else {
            this._fileSelectionError.push(t("error.general.mandatory.field"));
        }
    }

    private validationColumnsSelection() {
        this._refPropertyError = []; // clear errors on "ref property" field
        notificationModel.removePersistantMessage(0);

        const colToImport = this._selectedCheckBoxList;

        if (this._refColumnsSelected.length === 0) {
            notificationModel.addErrorMessage(t("components.importList.referenceColumnRequired"));
            return;
        }

        if (this._refPropertyError.length === 0) {
            const uniqueKeyLocatorValueMap: Dictionary<Dictionary<string>> = {};

            if (this.hasDuplicatedReferencesValue()) {
                notificationModel.addErrorMessage(t("components.importList.excelFileRefColumnNotUnique"));
            } else {
                this._importActiveStep += 1;
                this._parsedData = new AdditionalValues("Product");

                this._fileImportData.forEach(x => {
                    const additionalValueMap: Dictionary<string> = {};
                    const compositeLocatorKey = this._refColumnsSelected.map(column => x.get(column)).join("-");

                    for (const rowKey of Array.from(x.keys())) {
                        if (colToImport.find(y => y === rowKey)) {
                            additionalValueMap[rowKey] = x.get(rowKey)!;
                        }
                    }

                    if (!uniqueKeyLocatorValueMap.hasOwnProperty(compositeLocatorKey)
                        && !this._refColumnsSelected.every(column => x.get(column) === "")) {
                        this._parsedData.items.push(
                            new AdditionalValuesItem(
                                this._refColumnsSelected.map(column =>
                                    new AdditionalValuesLocator(this._refPropertyColumnToReferenceMap[column], x.get(column)!)),
                                additionalValueMap,
                            ),
                        );
                    }

                    uniqueKeyLocatorValueMap[compositeLocatorKey] = additionalValueMap;
                });
            }
        }
    }

    private hasDuplicatedReferencesValue() {
        const uniqueReferenceColumns: Dictionary<string> = {};

        return !this._fileImportData.every(data => {
            const uniqueReferenceIndex = this._refColumnsSelected.map((column: any) => data.get(column)).join("-");
            const uniqueReferenceValues = this._selectedCheckBoxList.map((column: any) => data.get(column)).join("-");

            if (uniqueReferenceColumns.hasOwnProperty(uniqueReferenceIndex) &&
                uniqueReferenceColumns[uniqueReferenceIndex] !== uniqueReferenceValues) {
                return false;
            }
            uniqueReferenceColumns[uniqueReferenceIndex] = uniqueReferenceValues;

            return true;
        });
    }

    public async sendAdditionalValues() {
        await operationsService.importAdditionalValues(this._selectedOperationCode, this._parsedData).then(x => {
            this.updateImportReturnValues(x);
            this.executionReportingStep();
        });
    }

    private listSelectedReferenceGroups(): string[] {
        return this._refColumnsSelected
            .map(column => this._refPropertyColumnToReferenceMap[column])
            .map(reference =>
                this._availableReferenceColumns.find(ref => ref.value === reference)!.group);
    }
}

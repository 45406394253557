import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import { Close } from "@mui/icons-material";
import { t } from "i18next";
import { ParcelBulkCreationMode } from "../../../../constants/constants";
import {
    ParcelCreationStrategyItemVariant,
    ParcelCreationStrategyMultiVariantItem,
} from "./parcel-creation-strategy-multi-variant-item";
import { ParcelCreationStrategyItem } from "./parcel-creation-strategy-item";
import { ParcelCustomCreationCommandHandler } from "./parcel-custom-creation-command-handler";
import { ParcelCustomCreationCommand } from "./parcel-custom-creation-command";
import { DeliveryOrder } from "../../../model/delivery-order";
import { ParcelCustomCreationResponseStatus } from "./parcel-custom-creation-response";
import { notificationModel } from "../../../../common/component/notifications/notification-model";

import "./style.scss";

interface IParcelCreationStrategyPickerProps {
    operationCode: string;
    batchId: number;
    deliveryOrderList: ReadonlyArray<DeliveryOrder>;
    onComplete: () => Promise<void>;
    onClose: () => void;
}

export const ParcelCreationStrategyPicker = (props: IParcelCreationStrategyPickerProps) => {
    const [selectedStrategies, setSelectedStrategies] = useState<ParcelBulkCreationMode[]>([]);
    const [isOneParcelPerOrderSelected, setIsOneParcelPerOrderSelected] = useState(true);
    const [isOneParcelPerProductItemSelected, setIsOneParcelPerProductItemSelected] = useState(false);
    const [isOneParcelPerProductWithMonoParcelFlagSelected, setIsOneParcelPerProductWithMonoParcelFlagSelected] =
        useState(false);
    const [disableTypeSelectors, setDisableTypeSelectors] = useState(false);

    const handleEveryOrderTypeStrategyUpdate = (newStrategy?: ParcelBulkCreationMode,
                                                previousStrategy?: ParcelBulkCreationMode) => {
        if (newStrategy === undefined) {
            handleSelectedStrategyUpdate(undefined, "OneParcelPerProductWithMonoParcelFlag");
        }
        handleSelectedStrategyUpdate(newStrategy, previousStrategy);
    };

    const handleSelectedStrategyUpdate = (newStrategy?: ParcelBulkCreationMode,
                                          previousStrategy?: ParcelBulkCreationMode) => {
        setSelectedStrategies(prevSelectedStrategies => {
            return (newStrategy ?
                [...prevSelectedStrategies.filter(s => s !== newStrategy), newStrategy] : prevSelectedStrategies)
                .filter(s => previousStrategy === undefined || s.toString() !== previousStrategy.toString());
        });
    };

    const handleSubmit = async () => {
        const result = await (new ParcelCustomCreationCommandHandler())
            .handle(new ParcelCustomCreationCommand(props.operationCode, props.batchId, props.deliveryOrderList,
                selectedStrategies));

        if (result.errorMessages) {
            for (const error of result.errorMessages.slice(0, 5)) {
                notificationModel.addErrorMessage(error);
            }

            if (result.errorMessages.length > 5) {
                notificationModel.addErrorMessage(t("batchPreparation.action.create.errorForDeliveryOrders", {
                    count: result.errorMessages.slice(5).length,
                }));
            }
        }

        await props.onComplete();
        if (result.status === ParcelCustomCreationResponseStatus.Success) {
            notificationModel.addSuccessMessage(t("batchPreparation.action.create.successs", {
                count: result.numberOfCreated}));
        }

        props.onClose();
    };

    useEffect(() => {
        setDisableTypeSelectors(selectedStrategies
            .some(s => s.toString() === "OneParcelPerOrder" || s.toString() === "OneParcelPerProductItem"));
        setIsOneParcelPerOrderSelected(
            selectedStrategies.find(s => s.toString() === "OneParcelPerOrder") !== undefined);
        setIsOneParcelPerProductItemSelected(
            selectedStrategies.find(s => s.toString() === "OneParcelPerProductItem") !== undefined);
        setIsOneParcelPerProductWithMonoParcelFlagSelected(
            selectedStrategies.find(s => s.toString() === "OneParcelPerProductWithMonoParcelFlag") !== undefined);
    }, [selectedStrategies]);

    return <Dialog open={true}>
        <ThemeProvider theme={VPUITheme}>
            <div className={"parcel-creation-strategy"}
                 data-testid={"parcel-creation-strategy-dialog"}>
                <div className="parcel-creation-strategy__close">
                    <IconButton onClick={() => props.onClose()}
                                data-testid={"parcel-creation-strategy-close"}>
                        <Close />
                    </IconButton>
                </div>
                <DialogTitle>
                    {t("components.customParcelCreator.title")}
                </DialogTitle>
                <DialogContent>
                    <Typography className={"parcel-creation-strategy__description"}>
                        {t("components.customParcelCreator.description", {
                            count: props.deliveryOrderList.length,
                        })}
                    </Typography>
                    <ParcelCreationStrategyMultiVariantItem title={t("components.customParcelCreator.strategy.all")}
                                                            propertyKey={"all"}
                                                            onValueChange={handleEveryOrderTypeStrategyUpdate}>
                        <ParcelCreationStrategyItemVariant value={"OneParcelPerOrder"}
                                                           title={t("components.customParcelCreator.strategy.OneParcelPerOrder")} />
                        {isOneParcelPerOrderSelected &&
                            <ParcelCreationStrategyItem title={t("components.customParcelCreator.strategy.OneParcelPerOrderAndOneParcelPerProductWithMonoParcelFlag")}
                                                        value={"OneParcelPerProductWithMonoParcelFlag"}
                                                        onValueChange={handleSelectedStrategyUpdate}
                                                        defaultChecked={isOneParcelPerProductWithMonoParcelFlagSelected} />}
                        <ParcelCreationStrategyItemVariant value={"OneParcelPerProductItem"}
                                                           title={t("components.customParcelCreator.strategy.OneParcelPerProductItem")} />
                    </ParcelCreationStrategyMultiVariantItem>
                    {!isOneParcelPerOrderSelected &&
                        <ParcelCreationStrategyItem title={t("components.customParcelCreator.strategy.OneParcelPerProductWithMonoParcelFlag")}
                                                    value={"OneParcelPerProductWithMonoParcelFlag"}
                                                    onValueChange={handleSelectedStrategyUpdate}
                                                    disabled={isOneParcelPerProductItemSelected}
                                                    defaultChecked={isOneParcelPerProductWithMonoParcelFlagSelected} />}
                    <ParcelCreationStrategyItem title={t("components.customParcelCreator.strategy.OneParcelPerOrderWithMonoRef")}
                                                value={"OneParcelPerOrderWithMonoRef"}
                                                onValueChange={handleSelectedStrategyUpdate}
                                                disabled={disableTypeSelectors} />
                    <ParcelCreationStrategyItem title={t("components.customParcelCreator.strategy.OneParcelPerOrderWithMultiRef")}
                                                value={"OneParcelPerOrderWithMultiRef"}
                                                onValueChange={handleSelectedStrategyUpdate}
                                                disabled={disableTypeSelectors} />
                    <ParcelCreationStrategyItem title={t("components.customParcelCreator.strategy.OneParcelPerComponentProduct")}
                                                value={"OneParcelPerComponentProduct"}
                                                onValueChange={handleSelectedStrategyUpdate}
                                                disabled={disableTypeSelectors} />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                            color={"inherit"}
                            type="submit"
                            key="cancel-btn"
                            data-testid="pallet-shipping-cancel"
                            onClick={() => props.onClose()}>
                        {t("components.popin.cancel")}
                    </Button>
                    <Button color="secondary"
                            variant="contained"
                            type="submit"
                            key="submit-btn"
                            data-testid="parcel-creation-execute"
                            onClick={async () => handleSubmit()}>
                        {t("components.customParcelCreator.execute")}
                    </Button>
                </DialogActions>
            </div>
        </ThemeProvider>
    </Dialog>;
};

export interface IPalletShippingDeliveryOrderDependency {
    operationCode: string;
    batchId: number;
    deliveryOrderId: number;
}

export class PalletShippingDeliveryOrderDependency {
    private readonly _operationCode: string;
    private readonly _deliveryOrderId: number;

    constructor(data: IPalletShippingDeliveryOrderDependency) {
        this._operationCode = data.operationCode;
        this._deliveryOrderId = data.deliveryOrderId;
    }

    get operationCode(): string {
        return this._operationCode;
    }

    get deliveryOrderId(): number {
        return this._deliveryOrderId;
    }

    get label() {
        return `${this.deliveryOrderId} (${this.operationCode})`;
    }
}

import * as React from "react";

import {
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import { observer } from "mobx-react";
import { Icon } from "../icon/icon";

interface IErrorListProps {
    errors: ReadonlyArray<string>;
}

@observer
export class ErrorList extends React.Component<IErrorListProps, {}> {
    public render() {
        return <List className="error-list">
            {this.props.errors.map((item, idx) => <ListItem key={idx} dense={false} className="error-list__error">
                <Icon icon="error" color="primary"/>
                <ListItemText primary={item}/>
            </ListItem>)}
        </List>;
    }
}

import { assign } from "lodash";
import { domain } from "../../common/dto/dto-annotation";
import { identifier } from "../../domain/identifier";
import { label50, label100 } from "../../domain/label";
import { observable, computed } from "mobx";
import { t } from "i18next";
import { utcDateTime } from "../../domain/utc-date";
import * as moment from "moment";

export class Batch {

    constructor(batch?: Batch) {
        assign(this, batch);

        if (batch !== undefined) {
            this.creationDate = moment(batch.creationDate);
            this.endDate = moment(batch.endDate);
        }
    }

    @domain(identifier)
    @observable
    public id: number;

    @domain(label50)
    @observable
    public status: string;

    @domain(utcDateTime)
    @observable
    public creationDate: moment.Moment;

    @domain(utcDateTime)
    @observable
    public endDate: moment.Moment;

    @domain(label100)
    @computed
    public get statusLabel() {
        return t(`model.batchStatus.${this.status}`);
    }
}

import { Guid } from "guid-typescript";

export interface IPrinterDevice {
    id: string;
    name: string;
    type: string;
}

export type MobilePrinterDeviceType = "pdf" | "zpl";

export class PrinterDevice {
    private readonly _id: Guid;
    private readonly _name: string;
    private readonly _type: MobilePrinterDeviceType;

    constructor(name: string, type: MobilePrinterDeviceType, id?: Guid) {
        this._id = id ?? Guid.create();
        this._name = name;
        this._type = type;
    }

    public static fromData(data: IPrinterDevice) {
        const parsedPrinterId = Guid.parse(data.id);

        if (data.name === "" || data.id === "" || parsedPrinterId.isEmpty()) {
            throw new Error("Invalid input data");
        }

        return new PrinterDevice(data.name, data.type === "zpl" ? "zpl" : "pdf", Guid.parse(data.id));
    }

    public toData() {
        return {
            id: this._id.toString(),
            name: this._name,
            type: this._type,
        } as IPrinterDevice;
    }

    get id(): Guid {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get type(): MobilePrinterDeviceType {
        return this._type;
    }
}

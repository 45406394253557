import { Dispatch, SetStateAction, useEffect } from "react";

export const useClearOnTimeout = (value: string | number | undefined,
                                  setValue: Dispatch<SetStateAction<string | number | undefined>>,
                                  timeout: number = 4000) => {
    return useEffect(() => {
        if (value?.toString() === "") {
            return;
        }

        const timeoutHandle = setTimeout(() => {
            setValue("");
        }, timeout);

        return () => clearTimeout(timeoutHandle);

    }, [value]);
};

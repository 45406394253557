import "./style.scss";

import * as React from "react";

import { IconButton, Switch, Table, TableBody, TableCell, TableRow } from "@mui/material";

import { AreaLine } from "./area-line";
import { AreaListModel } from "./area-list-model";
import { AreaListSubLine } from "./area-list-sub-line";
import { AreaTile } from "./area-tile";
import { Icon } from "../../../../common/component/icon/icon";
import { stringValue } from "../../../../common/field/renderer";
import { observer } from "mobx-react";
import { Area } from "./area";
import { AreaDetail } from "./area-detail";
import { context } from "../../../../context/context";
import { FeatureToggle } from "../../../../context/app-configuration";
import { dialogModel } from "../../../../common/component/dialog/dialog-model";
import { loaderModel } from "../../../../common/component/loader/loader-model";
import { t } from "i18next";

export interface IAreaListProps {
    areaListModel: AreaListModel;
    executeStockoutProductFromArea: (area: Area, areaDetail: AreaDetail) => Promise<void>;
}

@observer
export class AreaList extends React.Component<IAreaListProps, {}> {

    public async componentWillMount() {
        this.props.areaListModel.initStore();
    }

    protected renderLine(areaItem: Area) {
        return [<AreaLine areaItem={areaItem}
            key={`par-${areaItem.parcelId}`} />,
        this.renderDeliveryOrderDetailLineHeader(areaItem.parcelId),
        ...areaItem.details.map((detail, idx) => this.renderParcelDetailLine(areaItem, detail, idx)),
        ];
    }

    protected renderTile(areaItem: Area) {
        return <AreaTile
            area={areaItem}
            key={`par-${areaItem.parcelId}`}
        />;
    }

    public render() {
        return <div>
                {this.renderSwitchMode()}
                {this.renderListContent()}
            </div>;
    }

    private renderSwitchMode() {
        return <div className="switchMode">
            <Icon icon="view_comfy"
                tooltipTitle="components.area.action.viewSummarizeTooltip"
                tooltipPlacement="top-start" />
            <Switch onChange={() => this.props.areaListModel.switchMode()} data-testid="switchListMode"/>
            <Icon icon="list"
                tooltipTitle="components.area.action.viewDetailsTooltip"
                tooltipPlacement="top-start" />
        </div>;
    }

    private renderListContent() {
        if (this.props.areaListModel.listMode === "list") {
            return <Table>
                <TableBody className="table-body area-detailed-list">
                    {this.props.areaListModel.list.map((row, _idx) => this.renderLine(row))}
                </TableBody>
            </Table>;
        }

        return <div className="listSummarize" data-testid="listSummarize">
            {this.props.areaListModel.list.map((row, _idx) => this.renderTile(row))}
        </div>;
    }

    private renderDeliveryOrderDetailLineHeader(parcelId: number) {
        return <AreaListSubLine areaListModel={this.props.areaListModel} key={`par-header-${parcelId}`} />;
    }

    private renderParcelDetailLine(area: Area, areaDetail: AreaDetail, key: number) {
        return <TableRow key={"detail-" + key} data-testid={`detail-area-${area.areaNumber}-ean-${areaDetail.eansList}`} className="level2 content">
            <TableCell />
            <TableCell>{stringValue(areaDetail, "productId")}</TableCell>
            <TableCell>
                <ul>{areaDetail.eansList.map(ean => <li key={ean}>{ean}</li>)}</ul>
            </TableCell>
            <TableCell>{stringValue(areaDetail, "supplierReference")}</TableCell>
            <TableCell colSpan={2}>{stringValue(areaDetail, "labelReference")}</TableCell>
            <TableCell>{stringValue(areaDetail, "parcelledQuantity")}</TableCell>
            <TableCell className={context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) ? "area-detailed-list__stockout-button-container" : ""}>
                {stringValue(areaDetail, "availableQuantity")}
                {context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) && areaDetail.availableQuantity > 0 &&
                    <IconButton
                        data-testid={`stockout-button`}
                        className={"area-detailed-list__stockout-icon-button"}
                        onClick={() => this.confirmStockoutRemainingQuantityForAreaItem(area, areaDetail)}>
                        <Icon
                            color="primary"
                            className={"area-detailed-list-list__stockout-icon"}
                            icon={"report_problem"}
                            tooltipTitle="components.area.stockoutAction.title" />
                    </IconButton>}
            </TableCell>
            {context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) &&
                <TableCell>{stringValue(areaDetail, "stockoutQuantity")}</TableCell>}
            <TableCell>{stringValue(areaDetail, "productWeight")}</TableCell>
        </TableRow>;
    }

    private confirmStockoutRemainingQuantityForAreaItem(areaItem: Area, areaItemDetail: AreaDetail) {
        dialogModel.confirmBox(
            t("components.area.stockoutAction.confirmTitle"),
            t("components.area.stockoutAction.confirmDescription", {
                productName: areaItemDetail.labelReference,
            }),
            async () => await this.stockoutRemainingQuantityForAreaItem(areaItem, areaItemDetail),
        );
    }

    private async stockoutRemainingQuantityForAreaItem(area: Area, areaDetail: AreaDetail) {
        const actionId = loaderModel.foregroundActionStart(t("components.area.stockoutAction.inProgress"));

        await this.props.executeStockoutProductFromArea(area, areaDetail);
        await this.props.areaListModel.reload();

        loaderModel.foregroundActionEnd(actionId);
    }
}

import { BreadcrumbItem } from "./breadcrumb-item";
import {
    OperationPageType,
    PalletPageType,
    routeBatchPreparation, routeBulkParcelUpload,
    routeConsultShippedParcel,
    routeDeliveryOrderCanceled, routeImportData,
    routePalletManagement,
    routePalletShipping,
    routeParcelManagement, routePickAndPack,
    routePrepareParcelShipping,
    routeReporting,
    routeShipParcel, routeShippedPallets,
    routeStockouts,
    routeExportOperationProducts,
} from "../../../front-office/navigation/preparation-route-props";
import { t } from "i18next";
import { updateOperationUrl } from "../../../front-office/navigation/operation-route-parser";
import { updatePalletUrl } from "../../../front-office/navigation/pallet-route-parser";
import { IBreadcrumbsBuilder } from "./breadcrumbs-builder-interface";

export class FrontendBreadcrumbsBuilder implements IBreadcrumbsBuilder {
    private routesMap = new Map<string, string>([
        [routeBatchPreparation, t("navigation.createParcel")],
        [routeParcelManagement, t("navigation.parcelManagement")],
        [routePrepareParcelShipping, t("navigation.prepareParcelShipping")],
        [routeShipParcel, t("navigation.shipParcel")],
        [routeConsultShippedParcel, t("navigation.consultShippedParcel")],
        [routeDeliveryOrderCanceled, t("navigation.cancelDo")],
        [routeStockouts, t("navigation.stockouts")],
        [routeReporting, t("navigation.reporting")],
        [routeReporting, t("navigation.reporting")],
        [routePalletManagement, t("navigation.managePallets")],
        [routePalletShipping, t("navigation.shipPallets")],
        [routeShippedPallets, t("navigation.shippedPallets")],
        [routePickAndPack, t("navigation.pickAndPack")],
        [routeImportData, t("navigation.importDataFile")],
        [routeBulkParcelUpload, t("navigation.bulkParcelUpload")],
        [routeExportOperationProducts, t("navigation.exportOperationProducts")],
        ["settings", t("navigation.settings")],
        ["business-documentation", t("navigation.businessDocumentation")],
    ]);

    public buildFromRoute(path: string) {
        const breadcrumbItems = [this.homeBreadcrumb];

        if (this.isParcelPreparation(path)) {
            breadcrumbItems.push(this.parcelPreparation(path));
        }

        if (this.isPalletPreparation(path)) {
            breadcrumbItems.push(this.palletPreparation(path));
        }

        const item = this.getRouteFromPath(path);
        if (item) {
            breadcrumbItems.push(item);
        }

        return breadcrumbItems;
    }

    private isParcelPreparation(path: string) {
        return path.startsWith("/operations/");
    }

    private isPalletPreparation(path: string) {
        return path.startsWith("/pallet/");
    }

    private get homeBreadcrumb() {
        return new BreadcrumbItem(t("navigation.home"), updateOperationUrl("", {
            page: routeReporting,
        }));
    }

    private parcelPreparation(path: string) {
        return new BreadcrumbItem(t("navigation.panel.parcelPreparation"), updateOperationUrl(path, {
            page: routeReporting,
        }));
    }

    private palletPreparation(path: string) {
        return new BreadcrumbItem(t("navigation.panel.palletPreparation"), updatePalletUrl(path, {
            page: routePalletManagement,
        }));
    }

    private getRouteFromPath(path: string) {
        let route;

        this.routesMap.forEach((routeLabel: string, routeKey: string) => {
            if (path.indexOf(`/${routeKey}`) !== -1) {
                route = new BreadcrumbItem(routeLabel, this.isParcelPreparation(path) ?
                    updateOperationUrl(path, {
                        page: routeKey as OperationPageType,
                    }) : updatePalletUrl(path, {
                        page: routeKey as PalletPageType,
                    }));
            }
        });

        return route;
    }
}

import { IDomain, HtmlType } from "../common/dto/idomain";
import { InputComponentType } from "../common/component/input/input";

class YesNo implements IDomain<boolean> {

    public htmlType: HtmlType = "text";
    public inputComponent: InputComponentType = "boolean";

    public format(valueToFormat?: boolean) {
        if (valueToFormat !== undefined) {
            return `${valueToFormat}`;
        }
        return "";
    }

    public unformat(valueToUnformat: string) {
        if (valueToUnformat) {
            return valueToUnformat === `${true}`;
        }
        return undefined;
    }

    public validators = [];
}

export const yesNo = new YesNo();

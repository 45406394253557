import { BulkParcelUploadImportLine } from "../../../../model/bulk-parcel-upload-import-line";
import { ImportData } from "../../../../../common/component/list/import-export-list/import-data";
import { t } from "i18next";
import { context } from "../../../../../context/context";
import { FeatureToggle } from "../../../../../context/app-configuration";
import { Permission } from "../../../../../context/permission";
import { BulkParcelUploadImportValidator } from "./bulk-parcel-upload-import-validator";
import { Operation } from "../../../../model/operation";
import { BulkParcelUploadImportValidationConstraintsProvider } from "./bulk-parcel-upload-import-validation-constraints-provider";

export class BulkParcelUploadDataReader {
    private readonly _operation: Operation;
    private readonly _file: File;

    public static get columnNameOperationCode() {
        return t("model.parcelExport.operationCode");
    }

    public static get columnNameBatchId() {
        return t("model.parcelExport.batchId");
    }

    public static get columnNameDeliveryOrderId() {
        return t("model.parcelExport.deliveryOrderId");
    }

    public static get columnNameProductId() {
        return t("model.parcelExport.productId");
    }

    public static get columnNameQuantityAssigned() {
        return t("model.parcelExport.quantityParcelled");
    }

    public static get columnNameQuantityStockout() {
        return t("model.parcelExport.quantityStockout");
    }

    public static get columnNameParcelIdentifier() {
        return t("model.parcelExport.parcelIndex");
    }

    public static get columnParcelId() {
        return t("model.parcelExport.parcelId");
    }

    public static get columnParcelTracker() {
        return t("model.parcelExport.parcelTracker");
    }

    public static get columnCarrierName() {
        return t("model.parcelExport.carrier");
    }
    public static get columnPalletIndex() {
        return t("model.parcelExport.palletIndex");
    }

    public static get columnPalletExternalId() {
        return t("model.parcelExport.palletExternalId");
    }

    public static get columnPalletCode() {
        return t("model.parcelExport.palletCode");
    }

    public static get columnPalletCarrier() {
        return t("model.parcelExport.palletCarrier");
    }

    public static get columnPalletCountry() {
        return t("model.parcelExport.palletCountry");
    }

    public static get columnPalletCreationComment() {
        return t("model.parcelExport.palletCreationComment");
    }

    constructor(operation: Operation, file: File) {
        this._operation = operation;
        this._file = file;
    }

    public async readData(): Promise<BulkParcelUploadImportLine[]> {
        const data = await (new ImportData()).readUploadedFile(this._file);
        if (data.length === 0) {
            return [];
        }

        const constraintsProvider = new BulkParcelUploadImportValidationConstraintsProvider();
        const validator = new BulkParcelUploadImportValidator(
            [
                ...constraintsProvider.getBaseConstraints(),
                ...await constraintsProvider.getConstraintsForWarehouse(this._operation.warehouseId),
                ...await constraintsProvider.getConstraintsForOperation(this._operation.code),
            ]);
        validator.validateHeader(Array.from(data[0].keys()));

        const items: BulkParcelUploadImportLine[] = [];

        data.forEach((content, line: number) => {
            validator.validateLine(line + 1, content);

            items.push(new BulkParcelUploadImportLine(
                this.getValueAsStringOrEmpty(content, BulkParcelUploadDataReader.columnNameOperationCode),
                this.getValueAsNumber(content, BulkParcelUploadDataReader.columnNameBatchId),
                this.getValueAsNumber(content, BulkParcelUploadDataReader.columnNameDeliveryOrderId),
                this.getValueAsNumber(content, BulkParcelUploadDataReader.columnNameProductId),
                this.getValueAsNumber(content, BulkParcelUploadDataReader.columnNameQuantityAssigned),
                context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) ?
                    this.getValueAsNumber(content, BulkParcelUploadDataReader.columnNameQuantityStockout) : 0,
                this.getValueAsStringOrUndefined(content, BulkParcelUploadDataReader.columnNameParcelIdentifier),
                content.get(BulkParcelUploadDataReader.columnParcelId) !== "" ?
                    this.getValueAsNumber(content, BulkParcelUploadDataReader.columnParcelId) : undefined,
                context.hasPermission(Permission.FeatureBlueBrand)
                    ? this.getValueAsStringOrUndefined(content, BulkParcelUploadDataReader.columnParcelTracker) : undefined,
                context.hasPermission(Permission.FeatureBlueBrand) ?
                    this.getValueAsStringOrUndefined(content, BulkParcelUploadDataReader.columnCarrierName) : undefined,
                context.hasPermission(Permission.FeaturePalletWorkflow) ?
                    this.getValueAsStringOrUndefined(content, BulkParcelUploadDataReader.columnPalletIndex) : undefined,
                context.hasPermission(Permission.FeaturePalletWorkflow) ?
                    this.getValueAsStringOrUndefined(content, BulkParcelUploadDataReader.columnPalletExternalId) : undefined,
                context.hasPermission(Permission.FeaturePalletWorkflow) ?
                    this.getValueAsStringOrUndefined(content, BulkParcelUploadDataReader.columnPalletCode) : undefined,
                context.hasPermission(Permission.FeaturePalletWorkflow) ?
                    this.getValueAsStringOrUndefined(content, BulkParcelUploadDataReader.columnPalletCarrier) : undefined,
                context.hasPermission(Permission.FeaturePalletWorkflow) ?
                    this.getValueAsStringOrUndefined(content, BulkParcelUploadDataReader.columnPalletCountry) : undefined,
                context.hasPermission(Permission.FeaturePalletWorkflow) ?
                    this.getValueAsStringOrUndefined(content, BulkParcelUploadDataReader.columnPalletCreationComment) : undefined,
            ));
        });

        return items;
    }

    private getValueAsNumber(content: Map<string, string>, key: string): number {
        return Number(content.get(key));
    }

    private getValueAsStringOrUndefined(content: Map<string, string>, key: string): string | undefined {
        const value = content.get(key)?.trim();

        return value !== "" ? value : undefined;
    }

    private getValueAsStringOrEmpty(content: Map<string, string>, key: string): string {
        const value = content.get(key)?.trim();

        return value ?? "";
    }
}

import { Context, useContext } from "react";

export const useContextTypeSafe = <T>(context: Context<T | null>) => {
    const contextValidated = useContext(context);

    if (contextValidated === null) {
        throw new Error("Error");
    }

    return contextValidated;
};

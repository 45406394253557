import { observable, computed, action } from "mobx";
import { Article } from "../../../model/article";

class StockoutProductModel {
    @observable private _deliveryOrderIds: number[] = [];
    @observable private _articles: Article[] = [];
    @observable private _openedModal: boolean = false;
    @observable private _selectedProduct: Article | null = null;
    @observable private _missingQuantity: string | null = null;
    @observable private _affectedDeliveryOrdersCount: number | null = null;
    @observable private _calculatedQuantity: number | null = null;

    @computed
    public get openedModal() {
        return this._openedModal;
    }

    @action
    public setDeliveryOrderIds(deliveryOrderIds: number[]) {
        return this._deliveryOrderIds = deliveryOrderIds;
    }

    @computed
    public get deliveryOrderIds() {
        return this._deliveryOrderIds;
    }

    @action
    public setOpenedModal(status: boolean) {
        return this._openedModal = status;
    }

    @computed
    public get missingQuantity() {
        return this._missingQuantity;
    }

    @action
    public setMissingQuantity(missingQuantity: string | null) {
        return this._missingQuantity = missingQuantity;
    }

    @computed
    public get selectedProduct() {
        return this._selectedProduct;
    }

    @action
    public setSelectedProduct(product: Article | null) {
        return this._selectedProduct = product;
    }

    @computed
    public get affectedDeliveryOrdersCount() {
        return this._affectedDeliveryOrdersCount;
    }

    @action
    public setAffectedDeliveryOrdersCount(affectedDeliveryOrdersCount: number | null) {
        return this._affectedDeliveryOrdersCount = affectedDeliveryOrdersCount;
    }

    @computed
    public get calculatedQuantity() {
        return this._calculatedQuantity;
    }

    @action
    public setCalculatedQuantity(calculatedQuantity: number | null) {
        return this._calculatedQuantity = calculatedQuantity;
    }

    @computed
    public get articles() {
        return this._articles;
    }

    @action
    public setArticles(articles: Article[]) {
        this._articles = articles;
    }

    @computed
    public get hasSelectedProduct() {
        return this._selectedProduct !== null;
    }

    @computed
    public get isMissingQuantityValid() {
        return this.missingQuantity !== null
            && !isNaN(Number(this.missingQuantity))
            && Number(this.missingQuantity) > 0;
    }

    @computed
    public get isStockoutFormCompleted() {
        return this.selectedProduct
            && this.missingQuantity;
    }

    @computed
    public get isSimulationPrepared() {
        return this.selectedProduct
            && this.affectedDeliveryOrdersCount;
    }

    @action
    public openModalForDeliveryOrderIds(deliveryOrderIds: number[], articles: Article[]) {
        this._deliveryOrderIds = deliveryOrderIds;
        this.setArticles(articles);
        this.setOpenedModal(true);
    }

    @action
    public closeAndResetModel() {
        this._selectedProduct = null;
        this._affectedDeliveryOrdersCount = null;
        this._missingQuantity = null;

        this.setOpenedModal(false);
    }
}

export const stockoutProductModel = new StockoutProductModel();

import { Warehouse } from "./warehouse";
import { User } from "./user";

export class UserWithWarehouseDetails extends User {

    public warehouse: Warehouse;

    constructor(userSummaryWithWarehouse: UserWithWarehouseDetails) {
        super(userSummaryWithWarehouse);
        if (userSummaryWithWarehouse !== undefined && userSummaryWithWarehouse.warehouse !== null) {
            this.warehouse = new Warehouse(userSummaryWithWarehouse.warehouse);
        }
    }
}

import React, { ReactNode, useEffect } from "react";
import { Done, ErrorOutline, SvgIconComponent } from "@mui/icons-material";
import { useScanner } from "../../../common/hooks/use-scanner";
import { TypographyProps } from "@mui/material";

import "./style.scss";

interface INotificationProps {
    children: ReactNode;
    withAutoClose?: boolean;
    onClose?: () => void;
}

interface INotificationGenericProps extends INotificationProps {
    icon: SvgIconComponent;
}

export const Notification = (props: INotificationGenericProps) => {
    const handleNotificationClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    useScanner(handleNotificationClose);
    if (props.withAutoClose) {
        useEffect(() => {
            setTimeout(() => {
                handleNotificationClose();
            }, 4500);
        }, []);
    }

    return <div className={"scan-notification"}>
        <props.icon className={"scan-notification__icon"} onClick={handleNotificationClose} />
        <div className={"scan-notification__text-container"}>
            {React.Children.map(props.children, element => {
                if (React.isValidElement<TypographyProps>(element)) {
                    return React.cloneElement<TypographyProps>(element, {
                        ...element.props,
                       className: `${element.props.className} scan-notification__text`.trim(),
                    });
                }

                return undefined;
            })}
        </div>
    </div>;
};

export const NotificationError = (props: INotificationProps) => {
    return <Notification {...props} icon={ErrorOutline}>
        {props.children}
    </Notification>;
};

export const NotificationSuccess = (props: INotificationProps) => {
    return <Notification {...props} icon={Done}>
        {props.children}
    </Notification>;
};

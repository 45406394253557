import { t } from "i18next";
import { FeatureToggle } from "../../../../../../context/app-configuration";
import { context } from "../../../../../../context/context";
import { Permission } from "../../../../../../context/permission";
import { BulkParcelUploadExportLine } from "../../../../../model/bulk-parcel-upload-export-line";
import { ExportDataItem, ExportDataValue } from "../../../../../model/export-data-item";
import { IExportDataFormat } from "./export-data-format";

export class ShippingAddressExportDataFormat implements IExportDataFormat {
    public get header(): ExportDataItem {
        return new ExportDataItem([
            t("model.parcelExport.operationCode"),
            t("model.parcelExport.batchId"),
            t("model.parcelExport.orderId"),
            t("model.parcelExport.deliveryOrderId"),
            t("model.parcelExport.productId"),
            t("model.parcelExport.ean13List"),
            t("model.parcelExport.supplierReference"),
            t("model.parcelExport.labelReference"),
            t("model.parcelExport.quantity"),
            t("model.parcelExport.quantityParcelled"),
            context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) ? t("model.parcelExport.quantityStockout") : null,
            t("model.parcelExport.parcelIndex"),
            t("model.parcelExport.parcelId"),
            t("model.parcelExport.parcelTracker"),
            context.hasPermission(Permission.FeaturePalletWorkflow) ? t("model.parcelExport.palletIndex") : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? t("model.parcelExport.palletExternalId") : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? t("model.parcelExport.palletCode") : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? t("model.parcelExport.palletCountry") : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? t("model.parcelExport.palletCarrier") : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? t("model.parcelExport.palletCreationComment") : null,
            t("model.parcelExport.carrier"),
            t("model.parcelExport.shippingAddress.firstName"),
            t("model.parcelExport.shippingAddress.lastName"),
            t("model.parcelExport.shippingAddress.companyName"),
            t("model.parcelExport.shippingAddress.country"),
            t("model.parcelExport.shippingAddress.city"),
            t("model.parcelExport.shippingAddress.zipCode"),
            t("model.parcelExport.shippingAddress.address1"),
            t("model.parcelExport.shippingAddress.address2"),
            t("model.parcelExport.shippingAddress.address3"),
            t("model.parcelExport.shippingAddress.pickupPoint"),
            t("model.parcelExport.shippingAddress.floor"),
            t("model.parcelExport.shippingAddress.digicode"),
            t("model.parcelExport.shippingAddress.phone"),
            t("model.parcelExport.shippingAddress.emailAddress"),
        ].filter(item => item !== null));
    }

    public getDataForLine(item: BulkParcelUploadExportLine): ExportDataItem {
        return new ExportDataItem([
            item.operationCode,
            item.batchId,
            item.orderId,
            item.deliveryOrderId,
            item.productId,
            item.ean13,
            item.supplierReference,
            item.productLabel,
            (item.quantityAvailable + item.quantityAssigned),
            item.quantityAssigned,
            context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) ? item.quantityStockout : null,
            item.parcelIdentifier.toString(),
            item.parcelIdOrEmpty,
            item.parcelTrackerOrEmpty,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? "" : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? item.palletExternalId : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? item.palletCode : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? item.palletCountry : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? item.palletCarrier : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? item.palletCreationComment : null,
            item.carrier,
            item.shippingAddress.firstName,
            item.shippingAddress.lastName,
            item.shippingAddress.companyName,
            item.shippingAddress.country,
            item.shippingAddress.city,
            item.shippingAddress.zipCode,
            item.shippingAddress.address1,
            item.shippingAddress.address2,
            item.shippingAddress.address3,
            item.shippingAddress.pickupPoint,
            item.shippingAddress.floor,
            item.shippingAddress.digicode,
            item.shippingAddress.phone,
            item.shippingAddress.email,
        ].filter(value => value !== null) as ExportDataValue[]);
    }
}

import { IActionEligibilityValidator } from "./interface-action-eligibility-validator";
import { context } from "../../../../context/context";
import { Permission } from "../../../../context/permission";

class NotEligibleForBluebrandValidator implements IActionEligibilityValidator {
    public validate(): boolean {
        return !context.hasPermission(Permission.FeatureBlueBrand);
    }
}

export const notEligibleForBluebrandValidator = new NotEligibleForBluebrandValidator();

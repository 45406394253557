import { CarrierCountryPalletDeclaration } from "../model/carrier-country-pallet-declaration";
import { httpGet, httpPost } from "../../common/network/fetch";
import { IPalletReportForm, PalletReportForm } from "../model/pallet-report-form";

class PalletPickupService {
    public async getPalletForm(): Promise<PalletReportForm> {
        return new PalletReportForm(
            await httpGet<IPalletReportForm>({ url: `/pallet-report/form` }, false),
        );
    }

    public async savePalletForm(palletDeclarationList: CarrierCountryPalletDeclaration[], palletClosingComments: string,
                                palletCreationComments: string): Promise<void> {
        const url = "/pallet-report/form";
        const dataOb = {
            palletClosingComments: palletClosingComments.trim(),
            palletCreationComments: palletCreationComments.trim(),
            items: this.buildPalletDeclarationData(palletDeclarationList)};
        await httpPost({ url, data: dataOb});
    }

    private buildPalletDeclarationData(palletDeclarationList: CarrierCountryPalletDeclaration[]) {
        return palletDeclarationList.map(d => {
            return {
                country: d.country,
                carrier: d.carrier,
                palletCount: d.palletCount,
            };
        });
    }
}

export const palletPickupService = new PalletPickupService();

import React, { ReactNode } from "react";
import { Typography } from "@mui/material";

import "./style.scss";

interface ISearchListHeaderLabelProps {
    propertyKey?: string;
    label: string;
    value: string;
    chip?: ReactNode;
    whenNotEmpty?: boolean;
}

export const SearchListHeaderLabel = (props: ISearchListHeaderLabelProps) => {
    if (props.whenNotEmpty && (props.value ?? "") === "") {
        return <></>;
    }

    return <Typography variant={"h4"} className={"list-detail__item"}>
        {props.label}
        <div className={"list-detail__value"}>
            <span data-testid={props.propertyKey}>{props.value}</span>
            {props.chip}
        </div>
    </Typography>;
};

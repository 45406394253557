import React from "react";
import { FormLabel, FormHelperText, FormControl } from "@mui/material";
import { t } from "i18next";
import { ISelectItem } from "./iselect-item";

export interface IInputAbstractProps<ValueType> {
    propertyKey: string;
    label?: string;
    errors: string[];
    isRequired?: boolean;
    isLabelShrunk?: boolean;
    disabled?: boolean;
    value?: ValueType;
    onChange: (newValue?: ValueType) => void;
    onKeyDown?: (keyDown: string) => void;
    options?: Array<ISelectItem<string>>;
}

export abstract class InputAbstract<P, ValueType> extends React.Component<P & IInputAbstractProps<ValueType>> {

    public render() {
        return <div className={`input ${this.inputClassName}`}>
            {this.renderLabel()}
            {this.renderContent()}
        </div>;
    }

    protected renderContent() {
        if (this.props.disabled) {
            return this.renderDisabledContent();
        }

        return <FormControl error={this.hasError} className="input-form">
            {this.renderInput()}
            {this.renderError()}
        </FormControl >;
    }

    protected abstract get inputClassName(): string;

    protected abstract renderDisabledContent(): JSX.Element;
    protected abstract renderInput(): JSX.Element;

    private renderLabel() {
        if (this.props.label && !this.props.isLabelShrunk) {
            return <FormLabel className="input-field-label"
                required={this.props.isRequired && !this.props.disabled}
                error={this.hasError}>
                {this.props.label}
            </FormLabel >;
        }
        return undefined;
    }

    protected get hasError() {
        return this.props.errors.length > 0;
    }

    protected renderError() {
        if (this.hasError) {
            return <FormHelperText error={true} data-testid={`${this.props.propertyKey}-error`} >
                {this.props.errors.map(error => t(error)).join(", ")}
            </FormHelperText>;
        }
        return;
    }
}

import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import { Support } from "@mui/icons-material";
import { SupportTicketDialog } from "./support-ticket-dialog";
import { useWarehouseContext } from "../pallet/warehouse-context/warehouse-context-provider";
import { t } from "i18next";

export const SupportTicketWidget = () => {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const warehouseContext = useWarehouseContext();

    if (!warehouseContext.code) {
        return <></>;
    }

    return <>
        <Tooltip title={t("components.supportTicket.tooltip")}>
            <Button variant={"outlined"}
                    color={"inherit"}
                    onClick={() => setDialogOpen(true)}
                    size={"large"}>
                <Support />
            </Button>
        </Tooltip>
        {isDialogOpen && <SupportTicketDialog warehouseCode={warehouseContext.code} onClose={() => setDialogOpen(false)} />}
    </>;
};

import React, { SyntheticEvent } from "react";

import { Button, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { SearchListItemSelector } from "../../../../common/component/list/search-list/search-list-item-selector";
import { SearchListHeaderLabel } from "../../../../common/component/list/search-list/search-list-header-label";
import { Cancel, DoubleArrowOutlined, MoreVert } from "@mui/icons-material";
import { getFieldLabel, stringValue } from "../../../../common/field/renderer";
import { Parcel } from "../../../model/parcel";
import { operationContext } from "../../../../context/operation-context";
import { t } from "i18next";
import { observer } from "mobx-react";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";
import { pink } from "@mui/material/colors";
import { DeliveryOrderCancellationRequestStatus } from "../../../model/delivery-order-cancellation-request-status";

interface IParcelHeaderLineProps {
    parcel: Parcel;
    isItemSelected: boolean;
    isExpanded: boolean;
    displaySingleActionPickerButton: boolean;
    additionalDataSize: number;

    onHeaderClick: () => void;
    onSelectionToggle: (value: boolean) => void;
    onSingleActionMenuClick: (item: EventTarget) => void;
    onCancellationRequestMenuOpen: (anchor: HTMLElement) => void;
    onExpandToggle: (isExpanded: boolean) => void;
}

interface IPendingCancellationIconProps {
    parcel: Parcel;
    onCancellationRequestMenuOpen: (anchor: HTMLElement) => void;
}

interface IPendingCancellationWithMenu {
    cancellationRequestStatus: DeliveryOrderCancellationRequestStatus;
    onCancellationRequestMenuOpen: (anchor: HTMLElement) => void;
}

interface IParcelContentTypeChipProps {
    contentType: "MonoReference" | "SingleReference" | "MultiReference";
}

export const ParcelHeaderLine = (props: IParcelHeaderLineProps) => {

    const handleLineExpandCollapseToggle = (event: SyntheticEvent, isExpanded: boolean) => {
        event.stopPropagation();
        props.onExpandToggle(isExpanded);
    };

    return <TableRow key={props.parcel.id}
                     data-testid={`par-line-${props.parcel.id}`}
                     onClick={() => props.onHeaderClick()}
                     className={"search-item__row list-row--level1"}>
        <TableCell colSpan={8 + props.additionalDataSize}
                   className={"search-item__header"}>
            <div className={"search-item__header-contents"}>
                <SearchListItemSelector onToggle={value => props.onSelectionToggle(value)}
                                        isSelected={props.isItemSelected} />
                <IconButton onClick={event => handleLineExpandCollapseToggle(event, !props.isExpanded)}
                            data-testid={`expand-toggle-${props.isExpanded ? "expanded" : "collapsed"}`}>
                    <Tooltip title={t(`components.searchList.${props.isExpanded ? "collapse" : "expand"}`)}
                             placement={"top-start"}>
                        <DoubleArrowOutlined
                            className={`search-item__expand-toggle-icon${!props.isExpanded ? "--collapsed" : ""}`} />
                    </Tooltip>
                </IconButton>
                {props.parcel.isOrderPendingCancellationOrCancelled &&
                    <PendingCancellationIcon parcel={props.parcel}
                                             onCancellationRequestMenuOpen={props.onCancellationRequestMenuOpen}/>}
                <div className={"search-item__header-details-id"}>
                    <SearchListHeaderLabel propertyKey={"id"}
                                           label={getFieldLabel(props.parcel, "id")}
                                           value={stringValue(props.parcel, "id")}
                                           chip={<ParcelContentTypeChip contentType={props.parcel.type} />} />
                </div>
                <div className={"search-item__header-details"}>
                    <SearchListHeaderLabel label={getFieldLabel(props.parcel, "orderId")}
                                           value={stringValue(props.parcel, "orderId")} />
                    <SearchListHeaderLabel label={getFieldLabel(props.parcel, "deliveryOrderId")}
                                           value={stringValue(props.parcel, "deliveryOrderId")} />
                    <SearchListHeaderLabel label={getFieldLabel(props.parcel, "carrier")}
                                           value={stringValue(props.parcel, "carrier")} />
                    <SearchListHeaderLabel label={getFieldLabel(props.parcel, "weight")}
                                           value={stringValue(props.parcel, "weight")} />
                    {props.parcel.status !== "New" &&
                        <SearchListHeaderLabel label={getFieldLabel(props.parcel, "parcelTracker")}
                                           value={stringValue(props.parcel, "parcelTracker")} />}
                    {!!props.parcel.shipmentDate &&
                        <SearchListHeaderLabel label={getFieldLabel(props.parcel, "shipmentDate")}
                                           value={stringValue(props.parcel, "shipmentDate")} />}
                    {props.parcel.palletCode ?
                        <SearchListHeaderLabel label={getFieldLabel(props.parcel, "palletCode")}
                                           value={stringValue(props.parcel, "palletCode")} /> :
                        <SearchListHeaderLabel label={getFieldLabel(props.parcel, "address")}
                                               value={stringValue(props.parcel, "address")} />}
                </div>
                {props.displaySingleActionPickerButton &&
                    <div className={"search-item__header-action"}>
                        <Button variant={"outlined"}
                                onClick={event => {
                                    event.stopPropagation();
                                    props.onSingleActionMenuClick(event.target);
                                }}
                                size={"small"}>
                            <MoreVert />
                        </Button>
                    </div>}
            </div>
        </TableCell>
    </TableRow>;
};

const PendingCancellationIcon = observer((props: IPendingCancellationIconProps) => {
    return <div className={"cancellation-request__container"}>
        {operationContext.isBlueBrandOperation && props.parcel.orderCancellationRequestStatus
            && props.parcel.isOrderPendingCancellation ?
            <PendingCancellationWithMenu cancellationRequestStatus={props.parcel.orderCancellationRequestStatus}
                                     onCancellationRequestMenuOpen={props.onCancellationRequestMenuOpen} />
            : <Cancel data-testid={"order-cancellation-request-icon"} />}
    </div>;
});

const PendingCancellationWithMenu = (props: IPendingCancellationWithMenu) => {
    return <IconButton className={"cancellation-request__button"}
                       data-testid={"order-cancellation-request-menu-icon"}
                       onClick={event => {
                           props.onCancellationRequestMenuOpen(event.currentTarget);
                           event.stopPropagation();
                       }}>
        <Tooltip title={t(`parcelList.tooltip.orderWith${props.cancellationRequestStatus}CancellationRequest`)}>
            <Cancel data-testid={"order-cancellation-request-icon"} />
        </Tooltip>
        <MoreVert fontSize={"small"} className={"cancellation-request__menu-opener"} />
    </IconButton>;
};

const ParcelContentTypeChip = (props: IParcelContentTypeChipProps) => {
    return <SquaredChip color={pink} propertyKey={"content-type"} label={
        t(`model.deliveryOrder.detailContentType.${props.contentType}`)
    } />;
};

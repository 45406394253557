import { useWindowSize } from "../../hooks/use-window-size";
import { Login } from "./login";
import { routeReporting } from "../../../front-office/navigation/preparation-route-props";
import { authenticationService } from "../../../services/authentication";
import { Message } from "../notifications/message";
import * as React from "react";
import { SynchronizedHistory } from "mobx-react-router";
import { useState } from "react";
import { ScanShipLoginComponent } from "../../../scan-and-ship/screen/login-screen/scan-ship-login";
import { LoginPageLayout } from "./login-page-layout";
import { RequestPasswordReset } from "../../../front-office/components/reset-password/request-password-reset";

export interface ILoginPageProps {
    routing: SynchronizedHistory;
}

export interface ILoginWithCredentialsProps {
    routing: SynchronizedHistory;
    isMobile: boolean;
    onChangeLoginMode: () => void;
}

enum LoginMode {
    Credentials,
    QrCode,
}

export const LoginPage = (props: ILoginPageProps) => {
    const windowSize = useWindowSize();
    const [loginMode, setLoginMode] = useState(windowSize.isMobile && false ? LoginMode.QrCode : LoginMode.Credentials);

    return loginMode === LoginMode.Credentials ?
        <LoginPageWithCredentials routing={props.routing}
                                  isMobile={windowSize.isMobile}
                                  onChangeLoginMode={() => setLoginMode(LoginMode.QrCode)} /> :
        <ScanShipLoginComponent routing={props.routing}
                                onChangeLoginMode={() => setLoginMode(LoginMode.Credentials)} />;
};

const LoginPageWithCredentials = (props: ILoginWithCredentialsProps) => {
    const [resetPasswordFormOpen, setResetPasswordFormOpen] = useState(false);
    const [resetPasswordUsername, setResetPasswordUsername] = useState("");

    const handleForgotPasswordRequested = (userName?: string) => {
        setResetPasswordFormOpen(userName !== undefined);
        setResetPasswordUsername(userName ?? "");
    };

    return <LoginPageLayout>
        {!resetPasswordFormOpen ?
            <Login appName={props.isMobile ? "Scan & Ship" : "Click & Ship"}
                   enableQrLogin={props.isMobile}
                   onQrRequested={() => props.onChangeLoginMode()}
                   onForgotPasswordRequested={handleForgotPasswordRequested}
                   defaultRoute={props.isMobile ? "/scan-and-ship" : `/operations/${routeReporting}`}
                   login={authenticationService.login} routing={props.routing} /> :
            <RequestPasswordReset userName={resetPasswordUsername} onCancel={() => handleForgotPasswordRequested(undefined)} />}
        <Message />
    </LoginPageLayout>;
};

import React from "react";
import { CircularProgress, DialogContent, Typography } from "@mui/material";

import "./style.scss";

interface IProgressDialogProps {
    message: string;
    className?: string;
}

export const ProgressDialogContent = (props: IProgressDialogProps) => {
    return <DialogContent className={["progress-dialog", props.className ?? ""].join(" ")}>
        <div className={"progress-dialog__icon"}>
            <CircularProgress color={"secondary"} />
        </div>
        <Typography className={"progress-dialog__message"} color={"textSecondary"}>
            {props.message}
        </Typography>
    </DialogContent>;
};

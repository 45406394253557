import { includes } from "lodash";
import { operationsService } from "../../../services/operations";
import { warehousesService } from "../../../../back-office/services/warehouses";

export class WarehouseListProvider {
    public async list() {
        const activeWarehouseIDList = (await operationsService.loadOperations()).map(w => w.warehouseId);
        return (await warehousesService.list()).filter(w => includes(activeWarehouseIDList, w.code));
    }
}

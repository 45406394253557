import React, { useState } from "react";
import { Button, CardContent, CircularProgress, FormControl, FormLabel, InputAdornment, TextField, Typography } from "@mui/material";
import { usersService } from "../../../back-office/services/users";
import { t } from "i18next";

interface IRequestPasswordResetFormProps {
    userName: string;
    onComplete: () => void;
    onCancel: () => void;
}

export const RequestPasswordResetForm = (props: IRequestPasswordResetFormProps) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [userName, setUserName] = useState(props.userName);

    const handlePasswordReset = async (event: React.ChangeEvent<{}>) => {
        event.preventDefault();

        setIsProcessing(true);
        await usersService.createResetPasswordToken(userName);
        props.onComplete();
    };

    return <form onSubmit={async event => await handlePasswordReset(event)}>
        <CardContent>
            <Typography className={"password-reset__description"}>
                {t("components.passwordReset.description")}
            </Typography>
            <FormControl className={"password-reset__form-element-container"}
                         variant={"outlined"}
                         fullWidth>
                <FormLabel className={"password-reset__form-element"}>
                    <Typography className={"password-reset__form-label"}>
                        {t("model.login.login")}
                    </Typography>
                    <TextField className={"password-reset__form-input"}
                               InputProps={{
                                   endAdornment: (
                                       isProcessing && <InputAdornment position="end">
                                           <CircularProgress color={"primary"} />
                                       </InputAdornment>
                                   )}}
                               value={userName}
                               variant={"outlined"}
                               onChange={event => setUserName(event.target.value ?? "")}
                    />
                </FormLabel>
            </FormControl>
            <div className={"buttonContainer"}>
                <Button variant={"contained"}
                        color={"secondary"}
                        onClick={event => handlePasswordReset(event)}
                        key="reset-button"
                        disabled={isProcessing}>
                    {t("components.passwordReset.submit")}
                </Button>
                <Button variant={"contained"}
                        color={"inherit"}
                        onClick={() => props.onCancel()}
                        key="cancel-btn">
                    {t("components.passwordReset.cancel")}
                </Button>
            </div>
        </CardContent>
    </form>;
};

import { PalletStatusType } from "../constants/constants";
import { Guid } from "guid-typescript";

export interface IPalletShippingPalletDependency {
    palletId: string;
    code: string;
    status: PalletStatusType;
}

export class PalletShippingPalletDependency {
    private readonly _palletId: Guid;
    private readonly _code: string;
    private readonly _status: PalletStatusType;

    constructor(data: IPalletShippingPalletDependency) {
        this._palletId = Guid.parse(data.palletId);
        this._code = data.code;
        this._status = data.status;
    }

    get palletId(): Guid {
        return this._palletId;
    }

    get code(): string {
        return this._code;
    }

    get status(): PalletStatusType {
        return this._status;
    }
}

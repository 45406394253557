import * as areaSound from "../../../../common/sounds/area.mp3";
import * as errorSound from "../../../../common/sounds/error.mp3";
import * as labelSound from "../../../../common/sounds/label.mp3";

import { context } from "../../../../context/context";
import { getLabelFormat } from "../../../../constants/constants";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { parcelsService } from "../../../services/parcels";
import { pickAndPackModel } from "./pick-and-pack-model";
import { qzManager } from "../../../qz/qz-manager";
import { stocksService } from "../../../services/stocks";
import { menuOperationModel } from "../../preparation-menu/preparation-menu-model";
import { Area } from "../area-list/area";
import { AreaDetail } from "../area-list/area-detail";
import { t } from "i18next";

const ERROR_SOUND: HTMLAudioElement = new Audio(errorSound);
const AREA_SOUND: HTMLAudioElement = new Audio(areaSound);
const LABEL_SOUND: HTMLAudioElement = new Audio(labelSound);

class PickAndPackCommand {

    get labelFormat() {
        return getLabelFormat(context.settings.printerType);
    }

    public async pickAndPack(eanScanned: string, operationCode: string, batchId: number) {
        if (!pickAndPackModel.isScanProgressing) {
            if (!context.settings.isDownload) {
                const error = await qzManager.checkPrintConfig();
                if (error) {
                    notificationModel.addErrorMessage(error);
                    return;
                }
            }

            pickAndPackModel.scanProcessingStarted(eanScanned);

            try {
                const response = await parcelsService.pickAndPack(operationCode,
                                                batchId,
                                                eanScanned,
                                                this.labelFormat);
                // Load itemScanned
                const parcel = await parcelsService.loadParcelByParcelId(operationCode,
                    batchId,
                    response.parcelId);

                pickAndPackModel.scanProcessingSuccessful(response, parcel);

                if (response.status === "Labeled") {
                    this.playLabelSound();
                    const file = response.labelResult.loadFile();
                    await qzManager.qzPrintOrDownloadFile(file, context.settings.printerType);
                } else {
                    this.playAreaSound();
                }
            } catch (e) {
                this.playErrorSound();
                pickAndPackModel.scanProcessingError("Error", e.message);
            }
        }

        await this.refreshOperationCounters(operationCode, batchId);
    }

    public async stockoutProductFromArea(area: Area, areaDetail: AreaDetail) {
        await stocksService.stockoutDeliveryOrderDetailByProductId(
            area.operationCode,
            area.batchId,
            area.deliveryOrderId,
            areaDetail.productId,
        );
        areaDetail.stockoutRemainingQuantity();

        if (area.isFulfilled) {
            await this.printParcelLabel(area.operationCode, area.batchId, area.parcelId);

            notificationModel.addSuccessMessage(t("components.pickAndPack.stockoutAction.completedAndLabelled"));
            await this.refreshOperationCounters(area.operationCode, area.batchId);

            return;
        }

        notificationModel.addSuccessMessage(t("components.pickAndPack.stockoutAction.completed"));
    }

    public async printParcelLabel(operationCode: string, batchId: number, parcelId: number) {
        const label = await parcelsService.label(operationCode, batchId, parcelId, this.labelFormat);

        await qzManager.qzPrintOrDownloadFile(label.Value!, context.settings.printerType);
    }

    private async refreshOperationCounters(operationCode: string, batchId: number) {
        await menuOperationModel.refreshCounters(operationCode, batchId);
    }

    private playErrorSound() {
        ERROR_SOUND.play();
    }

    private playAreaSound() {
        AREA_SOUND.play();
    }

    private playLabelSound() {
        LABEL_SOUND.play();
    }
}

export const pickAndPackCommand = new PickAndPackCommand();

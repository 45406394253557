import { assign } from "lodash";
import { domain } from "../../common/dto/dto-annotation";
import { identifier } from "../../domain/identifier";
import { createFileFromRawData } from "../../common/utils/file";

/**
 * Class representating an object file.
 */
export class FileResponse {

    constructor(result?: FileResponse) {
        assign(this, result);
    }

    /**
     * File contents.
     */
    public fileContents: string;

    /**
     * Content type.
     */
    public contentType: string;

    /**
     * Filename.
     */
    public fileName: string;

    /**
     * Id.
     */
    @domain(identifier)
    public id: number;

    public loadFile() {
        return createFileFromRawData(this.fileName, this.fileContents, this.contentType);
    }
}

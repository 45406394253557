export interface IMobileToken {
    token: string;
    userName: string;
}

export class MobileToken {
    private readonly _token: string;
    private readonly _userName: string;

    constructor(data: IMobileToken) {
        this._token = data.token;
        this._userName = data.userName;
    }

    get token(): string {
        return this._token;
    }

    get userName(): string {
        return this._userName;
    }
}

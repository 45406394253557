import { action, observable } from "mobx";

export interface ICarrierCountryPalletDeclaration {
    country: string;
    carrier: string;
    palletCount: number;
    openPalletForecast?: number;
    closedPalletForecast?: number;
}

export class CarrierCountryPalletDeclaration {
    private readonly _country: string;
    private readonly _carrier: string;
    private readonly _openPalletForecast?: number;
    private readonly _closedPalletForecast?: number;

    @observable
    private _palletCount: number;

    constructor(carrierCountryPalletDeclaration: ICarrierCountryPalletDeclaration) {
        this._country = carrierCountryPalletDeclaration.country;
        this._carrier = carrierCountryPalletDeclaration.carrier;
        this._palletCount = carrierCountryPalletDeclaration.palletCount;
        this._openPalletForecast = carrierCountryPalletDeclaration.openPalletForecast;
        this._closedPalletForecast = carrierCountryPalletDeclaration.closedPalletForecast;
    }

    public get country(): string {
        return this._country;
    }

    public get carrier(): string {
        return this._carrier;
    }

    public get palletCount(): number {
        return this._palletCount;
    }

    get openPalletForecast(): number | undefined {
        return this._openPalletForecast;
    }

    get closedPalletForecast(): number | undefined {
        return this._closedPalletForecast;
    }

    @action
    public updatePalletCount(count: number) {
        this._palletCount = count;
    }
}

import React, { useState, useEffect, ReactNode } from "react";
import { PageRouteType, SectionRouteType } from "../../../front-office/constants/constants";
import { PanelMenuType } from "../../../constants/constants";
import { Drawer, IconButton, List } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";

import * as logoSrc from "./logo.png";
import * as logoHeadlineSrc from "./logo-headline.png";

import "./style.scss";
import { useVpMenuContext } from "./vp-menu-context";

export interface IPanelMenuItem<S extends SectionRouteType, P extends PageRouteType> {
    eligibilityValidator?: () => boolean;
    icon: string;
    label: string;
    defaultExpanded?: boolean;
    key: PanelMenuType;
    route: S;
    items: Array<IMenuItem<P>>;
}

export interface IMenuItem<P extends PageRouteType> {
    icon: string;
    iconLabel: string;
    subLabel?: string;
    route: P;
    eligibilityValidator?: () => boolean;
}

export interface IVpMenuProps {
    children: ReactNode;
    menuItemList: Array<IPanelMenuItem<SectionRouteType, PageRouteType>>;
}

export const VpMenu = (props: IVpMenuProps) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    const context = useVpMenuContext();

    useEffect(() => {
        let itemToExpand = props.menuItemList.find(menuItem => {
            return menuItem.defaultExpanded === true;
        });
        if (itemToExpand === undefined) {
            itemToExpand = props.menuItemList[0];
        }

        context.setExpandedPanel(itemToExpand.key);
        context.setIsExpandedEnabled(props.menuItemList.length > 1);
    }, []);

    return <div className="menu" data-testid="menu">
        <Drawer
            classes={{ paper: `drawerMenu ${isMenuOpened ? " open" : " close"}` }}
            variant="permanent"
            anchor="left"
            onMouseOver={() => setIsMenuOpened(true)}
            onMouseOut={() => setIsMenuOpened(false)}
            open={isMenuOpened}>
            <div className={"menu__logo-wrapper"}>
                <div className={"menu__logo-image-wrapper"}>
                    <img src={logoSrc} className={"menu__logo"} />
                </div>
                <div className={"menu__logo-headline"}>
                    <img src={logoHeadlineSrc} className={"menu__headline-image"} />
                </div>
                <div className={"menu__close"}>
                    <IconButton onClick={() => setIsMenuOpened(false)}
                                data-testid={"close-menu"}>
                        <ChevronLeft />
                    </IconButton>
                </div>
            </div>
            <List>
                {props.children}
            </List>
        </Drawer>
    </div>;
};

import { pdfLabelProvider } from "../printer/document-provider/pdf-label-provider";
import { IParcelDocumentProvider } from "../printer/document-provider/parcel-document-provider-interface";
import { preparationOrderAndLabelOnOnePageProvider } from "../printer/document-provider/preparation-order-and-label-on-one-page-provider";
import { pdfPreparationOrderAndLabelProvider } from "../printer/document-provider/pdf-preparation-order-and-label-provider";

export class DocumentStrategyProvider {
    public static getStrategyFromOptions(withPreparationOrder: boolean, fitOnOnePage: boolean): IParcelDocumentProvider {
        if (withPreparationOrder && fitOnOnePage) {
            return preparationOrderAndLabelOnOnePageProvider;
        }

        if (withPreparationOrder) {
            return pdfPreparationOrderAndLabelProvider;
        }

        return pdfLabelProvider;
    }
}

type DocumentStatus =  "Pending" | "Completed" | "PartiallyCompleted" | "Failed";

export interface IDocument {
    /** Document identifier */
    id: string;

    /** Document type */
    type: string;

    /** Document generation status */
    status: DocumentStatus;

    /** Array of parcels associated with document */
    parcelIds: number[];

    /** Array of parcels which failed during printing document */
    failedParcelsIds: number[];
}

export class Document implements IDocument {
    private readonly _id: string;
    private readonly _type: string;
    private readonly _status: DocumentStatus;
    private readonly _parcelIds: number[];
    private readonly _failedParcelsIds: number[];
    private readonly _file: File | undefined;

    constructor(document: IDocument, contents?: File) {
        this._id = document.id;
        this._type = document.type;
        this._status = document.status;
        this._parcelIds = document.parcelIds;
        this._failedParcelsIds = document.failedParcelsIds;
        this._file = contents;
    }

    public get id(): string {
        return this._id;
    }

    public get type(): string {
        return this._type;
    }

    public get status(): DocumentStatus {
        return this._status;
    }

    public get parcelIds(): number[] {
        return this._parcelIds;
    }

    public get failedParcelsIds(): number[] {
        return this._failedParcelsIds;
    }

    public get succeededParcelIds(): number[] {
        return this._parcelIds
            .filter(parcelId => this._failedParcelsIds
                .every(failedParcelId => failedParcelId !== parcelId));
    }

    public get file(): File | undefined {
        return this._file;
    }
}

export function createFileFromRawData(fileName: string, contents: string, contentType: string) {
    const byteCharacters = atob(contents);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const hexData = new Uint8Array(byteNumbers);

    return new File([hexData], fileName, {type: contentType});
}

export function createPdfFromRawData(fileName: string, contents: string) {
    return createFileFromRawData(fileName, contents, "application/pdf");
}

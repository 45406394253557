export class ParcelExportStrategy {

    public static ParcelPerProduct = new ParcelExportStrategy(
        "parcel-per-product",
        currentIndex => ++currentIndex,
    );

    public static ParcelPerOrder = new ParcelExportStrategy(
        "parcel-per-order",
        currentIndex => currentIndex,
    );

    private constructor(private id: string, private nextIndexFn: (currentIndex: number) => number) {
    }

    public toString(): string {
        return this.id;
    }

    public nextIndex(currentIndex: number): number {
        return this.nextIndexFn(currentIndex);
    }

    public static fromString(strStrategy: string): ParcelExportStrategy {
        switch (strStrategy) {
            case ParcelExportStrategy.ParcelPerProduct.id:
                return ParcelExportStrategy.ParcelPerProduct;
            case ParcelExportStrategy.ParcelPerOrder.id:
                return ParcelExportStrategy.ParcelPerOrder;
        }
        throw Error("Unknown ParcelExportStrategy");
    }
}

import React, { useEffect, useState } from "react";
import { IHistoryProps } from "../../navigation/ihistory-props";
import { Breadcrumbs as MaterialBreadcrumbs, Link, Typography } from "@mui/material";
import { BreadcrumbColor, BreadcrumbItem } from "./breadcrumb-item";
import { NavigateNext } from "@mui/icons-material";
import { IBreadcrumbsBuilder } from "./breadcrumbs-builder-interface";
import { useLocation } from "react-router-dom";

import "./style.scss";

interface IBreadcrumbsProps {
    builder: IBreadcrumbsBuilder;
}

export const Breadcrumbs = (props: IBreadcrumbsProps & IHistoryProps) => {
    const [breadcrumbsList, setBreadcrumbsList] = useState<BreadcrumbItem[]>([]);
    const location = useLocation();

    useEffect(() => {
        setBreadcrumbsList(props.builder.buildFromRoute(props.routing.location.pathname));
    }, [location.pathname]);

    const handleLinkClick = (targetUrl: string) => {
        props.routing.push(targetUrl);
    };

    const breadcrumbColor = (color: BreadcrumbColor) => {
        switch (color) {
            case "production":
                return "primary";
            case "testing":
                return "secondary";
            default:
                return "textPrimary";
        }
    };

    return <MaterialBreadcrumbs separator={<NavigateNext fontSize="small" color={"inherit"} />}
                                className={"breadcrumbs"}>
        {breadcrumbsList.slice(0, breadcrumbsList.length - 1).map((item, index) =>
            <Link key={`breadcrumb-${index}`}
                  data-testid={`breadcrumb-${index === 0 ? "home" : index}`}
                  className={"breadcrumbs__item--link"}
                  onClick={() => handleLinkClick(item.url)}
                  color={breadcrumbColor(item.color)}>
                {item.label}
            </Link>)}
        {breadcrumbsList.slice(breadcrumbsList.length - 1).map(item =>
            <Typography key={"breadcrumb-title"}
                        data-testid={`breadcrumb-title`}
                        color={breadcrumbColor(item.color)}
                        className={"breadcrumbs__item"}>
                {item.label}
            </Typography>)}
    </MaterialBreadcrumbs>;
};

import { UploadedParcel } from "./uploaded-parcel";
import { UploadedStockout } from "./uploaded-stockout";
import { Parcel } from "../../../../model/parcel";

export class UploadedDeliveryOrder {
    private readonly _id: number;
    private readonly _parcels: UploadedParcel[];
    private readonly _stockouts: UploadedStockout[];

    constructor(id: number, parcels: UploadedParcel[], stockout: UploadedStockout[]) {
        this._id = id;
        this._parcels = parcels;
        this._stockouts = stockout;
    }

    get id(): number {
        return this._id;
    }

    get parcels(): UploadedParcel[] {
        return this._parcels;
    }

    get stockouts(): UploadedStockout[] {
        return this._stockouts;
    }

    public parcelsNotReuploaded(parcels: Parcel[]): Parcel[] {
        return parcels.filter(parcel => !this.isParcelReuploaded(parcel));
    }

    private isParcelReuploaded(parcel: Parcel): boolean {
        return this._parcels.find(p => parcel.parcelId === p.id) !== undefined;
    }
}

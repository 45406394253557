import { IDomain, HtmlType } from "../common/dto/idomain";
import { InputComponentType } from "../common/component/input/input";

class Label implements IDomain<string> {

    private length: number;
    public htmlType: HtmlType = "text";
    public inputComponent: InputComponentType = "textField";

    constructor(length: number) {
        this.length = length;
    }

    public format(valueToFormat?: string) {
        if (valueToFormat) {
            return valueToFormat;
        }
        return "";
    }

    public unformat(valueToUnformat?: string) {
        if (valueToUnformat) {
            return valueToUnformat.substring(0, this.length);
        }
        return valueToUnformat;
    }

    public validators = [];
}

export const label2 = new Label(2);
export const label10 = new Label(10);
export const label15 = new Label(15);
export const label20 = new Label(20);
export const label50 = new Label(50);
export const label64 = new Label(64);
export const label100 = new Label(100);
export const label150 = new Label(150);
export const label128 = new Label(128);
export const label255 = new Label(255);

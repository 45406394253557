import { SupportSeverity, SupportSupplier, SupportTeam, SupportTicketIssue, SupportTicketLanguage, SupportTicketTechnology, SupportTopic } from "../../components/support-ticket/support-ticket-options-provider";

export class CreateSupportTicketCommand {
    private readonly _warehouseCode: string;
    private readonly _topic: SupportTopic;
    private readonly _team: SupportTeam;
    private readonly _supplier: SupportSupplier;
    private readonly _language: SupportTicketLanguage;
    private readonly _issue: SupportTicketIssue;
    private readonly _technology: SupportTicketTechnology;
    private readonly _severity: SupportSeverity;
    private readonly _description: string;
    private readonly _attachments: File[];

    constructor(warehouseCode: string, language: SupportTicketLanguage, issue: SupportTicketIssue, technology: SupportTicketTechnology,
                description: string, attachmentsList: File[]) {
        this._warehouseCode = warehouseCode;
        this._topic = SupportTopic.SupplierSupport;
        this._team = SupportTeam.France;
        this._supplier = SupportSupplier.Brand;
        this._language = language;
        this._issue = issue;
        this._technology = technology;
        this._severity = SupportSeverity.ProductionSlowedDown;
        this._description = description;
        this._attachments = attachmentsList;
    }

    get warehouseCode(): string {
        return this._warehouseCode;
    }

    get topic(): SupportTopic {
        return this._topic;
    }

    get team(): SupportTeam {
        return this._team;
    }

    get supplier(): SupportSupplier {
        return this._supplier;
    }

    get language(): SupportTicketLanguage {
        return this._language;
    }

    get issue(): SupportTicketIssue {
        return this._issue;
    }

    get technology(): SupportTicketTechnology {
        return this._technology;
    }

    get severity(): SupportSeverity {
        return this._severity;
    }

    get description(): string {
        return this._description;
    }

    get attachments(): File[] {
        return this._attachments;
    }
}

import { Guid } from "guid-typescript";
import { IParcel, Parcel } from "./parcel";
import { domain } from "../../common/dto/dto-annotation";
import { weight } from "../../domain/weight";
import { sumBy } from "lodash";
import { PalletStatusType } from "../constants/constants";
import moment from "moment";

export interface IPallet {
    id: string;
    code: string;
    externalId?: string;
    countryGroup: string;
    carrier: string;
    status: string;
    parcels: IParcel[];
    maxParcelWeight: number;
    maxWeight: number;
    isLabelPrinted: boolean;
    creationComment: string;
    pickingDate: string;
}

export class Pallet {
    private readonly _id: Guid;
    private readonly _externalId?: string;
    private readonly _code: string;
    private readonly _countryGroup: string;
    private readonly _carrier: string;
    private _parcels: Parcel[];
    private readonly _status: PalletStatusType;
    private readonly _maxParcelWeight: number;
    private readonly _maxWeight: number;
    private readonly _isLabelPrinted: boolean;
    private readonly _creationComment: string;
    private readonly _pickingDate: moment.Moment | null;

    public constructor(pallet: IPallet) {
        this._id = Guid.parse(pallet.id);
        this._countryGroup = pallet.countryGroup;
        this._carrier = pallet.carrier;
        this._parcels = pallet.parcels.map(p => new Parcel(p));
        this._code = pallet.code;
        this._externalId = pallet.externalId;
        this._status = pallet.status as PalletStatusType;
        this._maxParcelWeight = pallet.maxParcelWeight;
        this._maxWeight = pallet.maxWeight;
        this._isLabelPrinted = pallet.isLabelPrinted;
        this._creationComment = pallet.creationComment;
        this._pickingDate = pallet.pickingDate && pallet.pickingDate !== "" ? moment(pallet.pickingDate) : null;
    }

    public get id(): Guid {
        return this._id;
    }

    public get countryGroup(): string {
        return this._countryGroup;
    }

    public get carrier(): string {
        return this._carrier;
    }

    public get parcels(): Parcel[] {
        return this._parcels;
    }

    public addParcel(parcel: Parcel) {
        this._parcels = this._parcels.filter(p => !p.matchesParcelLocator(parcel.id.toString()));
        this._parcels.push(parcel);
    }

    public deleteParcel(parcelLocator: string) {
        this._parcels = this._parcels.filter(p => !p.matchesParcelLocator(parcelLocator));
    }

    public get code(): string {
        return this._code;
    }

    public get externalId(): string | undefined {
        return this._externalId;
    }

    public get status(): PalletStatusType {
        return this._status;
    }

    public get maxParcelWeight(): number {
        return this._maxParcelWeight;
    }

    public get maxWeight(): number {
        return this._maxWeight;
    }

    public get isLabelPrinted(): boolean {
        return this._isLabelPrinted;
    }

    public get parcelsCount(): number {
        return this._parcels.length;
    }

    @domain(weight)
    public get parcelsWeight(): number {
        return sumBy(this._parcels, "weight");
    }

    get creationComment(): string {
        return this._creationComment;
    }

    get pickingDate(): moment.Moment | null {
        return this._pickingDate;
    }
}

import { assign } from "lodash";

export interface ISupplierReferenceUpdateResponse {
    updatedRefs: string[];
    unknownRefs: string[];
}

export class SupplierReferenceUpdateResponse {

    constructor(supplierReferenceUpdateResponse: ISupplierReferenceUpdateResponse) {
        assign(this, supplierReferenceUpdateResponse);
    }

    public updatedRefs: string[] = [];
    public unknownRefs: string[] = [];
}

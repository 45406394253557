import { Grid, TableCell, TableRow } from "@mui/material";

import { Icon } from "../../../../common/component/icon/icon";
import { Parcel } from "../../../model/parcel";
import React from "react";
import { context } from "../../../../context/context";
import { DialogMessageIcon, dialogModel } from "../../../../common/component/dialog/dialog-model";
import { getReadOnlyField } from "../../../../common/field/renderer";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { observer } from "mobx-react";
import { parcellingModel } from "./parcelling-model";
import { parcelsService } from "../../../services/parcels";
import { documentPrinter } from "../printer/document-printer";
import { t } from "i18next";
import { Permission } from "../../../../context/permission";
import { operationsService } from "../../../services/operations";
import { labelProvider } from "../printer/document-provider/label-provider";
import { DeliveryOrderCancellationRequestStatus } from "../../../model/delivery-order-cancellation-request-status";

interface IParcelListParcelRowProps {
    operationCode: string;
    batchId: number;
    uniqueParcelIdentifier: number;
    parcel: Parcel;
    index: number;
}

@observer
export default class ParcellingParcelRow extends React.Component<IParcelListParcelRowProps, {}> {

    public render() {
        return (
            <TableRow className="level1 center" key={this.props.parcel.id} onClick={() =>
                parcellingModel.expandParcel(this.props.uniqueParcelIdentifier)}
                data-testid={`parcelling-parcel-line-${this.props.parcel.id || "new"}`}>
                <TableCell colSpan={6}>
                    <Grid container alignContent="center">
                        {this.renderParcelFields()}
                        <Grid item xs={3}><div className="actions">{this.renderActions()}</div></Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        );
    }

    private renderParcelFields() {
        if (this.props.parcel.id === undefined) {
            return <Grid item xs={9}></Grid>;
        }
        return [
            <Grid item xs={2} key="id">{getReadOnlyField(this.props.parcel, "id")}</Grid>,
            <Grid item xs={2} key="detailsQuantity">{getReadOnlyField(this.props.parcel, "detailsQuantity")}</Grid>,
            <Grid item xs={2} key="weight">{getReadOnlyField(this.props.parcel, "weight")}</Grid>,
            <Grid item xs={3} key="parcelTracker">{this.props.parcel.parcelTracker ?
                getReadOnlyField(this.props.parcel, "parcelTracker") : undefined}</Grid>,
        ];
    }

    private renderActions() {
        if (!context.hasPermission(Permission.FrontWrite) ||
            this.props.parcel.status === "Shipped" ||
            (context.hasPermission(Permission.FeaturePalletWorkflow) && this.props.parcel.palletId !== undefined)) {
            return <></>;
        }

        return parcellingModel.isEdit(this.props.uniqueParcelIdentifier) ?
            this.renderActionsForEditMode() : this.renderActionsForViewMode();
    }

    private renderActionsForEditMode() {
        return <>
            <Icon
                key="icon-cancel"
                onClick={event => this.toggleEdit(event, false)}
                icon="reply"
            />
            <Icon
                key="icon-save"
                onClick={async event => await this.doSaveParcel(event, this.props.parcel)}
                icon="save"
            />
        </>;
    }

    private renderActionsForViewMode() {
        return <>
            <Icon
                key="icon-edit"
                icon="mode_edit"
                disabled={parcellingModel.hasParcelInEditMode}
                onClick={event => this.toggleEdit(event, true)}
            />
            <Icon
                key="icon-delete"
                icon="delete"
                onClick={event => this.openDeleteConfirmPopin(event)}
            />
            {!context.hasPermission(Permission.FeatureBlueBrand) && <Icon
                key="icon-print"
                icon="print"
                onClick={async event => await this.executePrint(event)}
            />}
        </>;
    }

    private toggleEdit(event: React.SyntheticEvent<any>, isEdit: boolean) {
        event.stopPropagation();
        parcellingModel.toggleEdit(
            this.props.parcel,
            this.props.uniqueParcelIdentifier,
            isEdit,
            this.props.index);
    }

    private async doSaveParcel(event: React.SyntheticEvent<any>, parcel: Parcel) {
        event.stopPropagation();
        if (parcel.parcelTracker) {
            dialogModel.confirmBox(
                t("deliveryOrderParcelling.parcelList.updateParcelLabeledPopin.title"),
                t("deliveryOrderParcelling.parcelList.updateParcelLabeledPopin.content"),
                async () => await this.saveParcel(parcel));
        } else {
            await this.saveParcel(parcel);
        }
    }

    private async saveParcel(parcel: Parcel) {
        const isCreation = !parcel.id;
        if (isCreation) {
            await parcelsService.createParcel(this.props.operationCode, parcel);
        } else {
            await parcelsService.updateParcel(this.props.operationCode, this.props.batchId, parcel);
        }
        const deliveryOrder = await operationsService.loadDeliveryOrder(this.props.operationCode, this.props.batchId, parcel.deliveryOrderId);
        parcellingModel.onParcelSaved(deliveryOrder, isCreation);
    }

    private openDeleteConfirmPopin(event: React.SyntheticEvent<any>) {
        event.stopPropagation();
        dialogModel.confirmBox(
            t("deliveryOrderParcelling.parcelList.deleteConfirmPopin.title"),
            t("deliveryOrderParcelling.parcelList.deleteConfirmPopin.content"),
            async () => {
                await parcelsService.deleteParcelById(this.props.operationCode,
                    this.props.batchId,
                    this.props.parcel.id);
                parcellingModel.onParcelDeleted(this.props.parcel);
            });
    }

    private async executePrint(event: React.SyntheticEvent<any>) {
        event.stopPropagation();

        switch (parcellingModel.deliveryOrder!.cancellationRequestStatus) {
            case DeliveryOrderCancellationRequestStatus.Pending:
                dialogModel.confirmBox(
                    t("deliveryOrderParcelling.parcelList.labelParcel"),
                    t("deliveryOrderParcelling.validation.orderPendingCancellationWarning"),
                    async () => await this.print(),
                    DialogMessageIcon.Warning,
                );
                break;
            case DeliveryOrderCancellationRequestStatus.Confirmed:
                dialogModel.alert(
                    t("deliveryOrderParcelling.parcelList.labelParcel"),
                    t("deliveryOrderParcelling.validation.orderPendingCancellationError"),
                    DialogMessageIcon.Error,
                );
                break;
            default:
                await this.print();
        }
    }

    private async print() {
        await documentPrinter.printOrDownloadWithProgressDrawer(
            this.props.operationCode,
            this.props.batchId,
            [this.props.parcel],
            labelProvider,
        );
        parcellingModel.refreshParcel(await parcelsService.loadParcel(
            this.props.operationCode,
            this.props.batchId,
            this.props.parcel.deliveryOrderId,
            this.props.parcel.id));
        notificationModel.addSuccessMessage("deliveryOrderParcelling.parcelList.notifications.printSuccess");
    }
}

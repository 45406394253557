import React, { useState } from "react";
import { Parcel } from "../../../model/parcel";
import {
    Button, Checkbox,
    DialogContent,
    DialogTitle, FormControl, FormControlLabel, FormLabel, TextField,
    Typography,
} from "@mui/material";
import { t } from "i18next";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";
import { blue } from "@mui/material/colors";
import { DialogActionsWithOption } from "../../../../common/component/dialog-actions-with-option/dialog-actions-with-option";
import { DialogActionsOption } from "../../../../common/component/dialog-actions-with-option/dialog-actions-option";
import { DialogActionsButtons } from "../../../../common/component/dialog-actions-with-option/dialog-actions-buttons";

interface ILabelAndAssignPalletSelectorProps {
    parcelList: Parcel[];

    onPalletSelected: (palletCode: string, printPreparationOrder: boolean) => Promise<void>;
    onCancel: () => void;
}

export const LabelAndAssignPalletSelector = (props: ILabelAndAssignPalletSelectorProps) => {
    const [palletIdentifier, setPalletIdentifier] = useState("");
    const [printPreparationOrder, setPrintPreparationOrder] = useState(false);

    const handleOnKeyDown = async (keyDown: string) => {
        if (keyDown !== "Enter" || palletIdentifier === "") {
            return;
        }

        await handleFormSubmit();
    };

    const handleFormSubmit = async () => {
        await props.onPalletSelected(palletIdentifier, printPreparationOrder);
    };

    return <>
        <DialogTitle>
            {(t("components.parcel.labelAndAssign.title"))}
        </DialogTitle>
        <DialogContent>
            <Typography className={"label-and-assign__description"}>
                {t("components.parcel.labelAndAssign.descriptionAssign")}
            </Typography>
            <Typography className={"label-and-assign__list-title"}>
                {t("components.parcel.labelAndAssign.parcelsListTitle")}
            </Typography>
            <div className={"label-and-assign__list"}>
                {props.parcelList.map(c => <SquaredChip propertyKey={`item-${c.id}`} color={blue} label={c.id.toString()} />)}
            </div>
            <FormControl variant={"outlined"}
                         fullWidth>
                <FormLabel htmlFor={"multi-scan-field"}>
                    {t("model.pallet.code")}
                </FormLabel>
                <TextField id={"assignment-code-scan"}
                           value={palletIdentifier}
                           variant={"outlined"}
                           autoFocus
                           onKeyDown={async (event: React.KeyboardEvent<any>) => await handleOnKeyDown(event.key)}
                           onChange={event => setPalletIdentifier(event.target.value ?? "")}
                />
            </FormControl>
        </DialogContent>
        <DialogActionsWithOption>
            <DialogActionsOption>
                <FormControlLabel control={
                    <Checkbox checked={printPreparationOrder}
                              onChange={(_event, checked) => setPrintPreparationOrder(checked)} />}
                                  label={t("components.parcel.labelAndAssign.printPreparationOrderAfterAssignment")} />
            </DialogActionsOption>
            <DialogActionsButtons>
                <Button variant="outlined"
                        type="submit"
                        key="cancel-btn"
                        data-testid="pallet-assign-cancel"
                        onClick={() => props.onCancel()}>
                    {t("components.parcel.labelAndAssign.skip")}
                </Button>
                <Button color="secondary"
                        variant="contained"
                        type="submit"
                        key="submit-btn"
                        data-testid="pallet-assign-continue"
                        onClick={async () => await handleFormSubmit()}>
                    {(t("components.parcel.labelAndAssign.assign"))}
                </Button>
            </DialogActionsButtons>
        </DialogActionsWithOption>
    </>;
};

import React, { useEffect, useState } from "react";
import { ParcelHeaderLine } from "./parcel-header-line";
import { ParcelListSubSort } from "./parcel-list-sub-sort";
import { ParcelContents } from "./parcel-contents";
import { Parcel } from "../../../model/parcel";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { ParcelDetail } from "../../../model/parcel-detail";
import { ParcelFilter } from "../../../model/parcel-filter";

interface IParcelListLineProps {
    parcel: Parcel;
    searchListModel: SearchListModel<Parcel, ParcelDetail, ParcelFilter>;
    isExpanded: boolean;
    withSingleActionMenu: boolean;

    onSingleActionMenuClick: (anchor: EventTarget) => void;
    onParcellingPageOpen: () => void;
    onCancellationRequestMenuOpen: (anchor: HTMLElement) => void;
}

export const ParcelListLine = (props: IParcelListLineProps) => {
    const [isExpanded, setIsExpanded] = useState(props.isExpanded);

    useEffect(() => {
        setIsExpanded(props.isExpanded);
    }, [props.isExpanded]);

    return <>
        <ParcelHeaderLine key={`dor-${props.parcel.id}`}
                          displaySingleActionPickerButton={props.withSingleActionMenu}
                          parcel={props.parcel}
                          isItemSelected={props.searchListModel.isSelected(props.parcel.id)}
                          isExpanded={isExpanded}
                          onExpandToggle={toggleValue => setIsExpanded(toggleValue)}
                          additionalDataSize={props.searchListModel.additionalFields.length}
                          onSelectionToggle={value => props.searchListModel.selectItem(props.parcel, value)}
                          onSingleActionMenuClick={anchor => props.onSingleActionMenuClick(anchor)}
                          onHeaderClick={() => props.onParcellingPageOpen()}
                          onCancellationRequestMenuOpen={anchor => props.onCancellationRequestMenuOpen(anchor)} />
        {isExpanded && <ParcelListSubSort searchListModel={props.searchListModel}
                                          key={`par-header-${props.parcel.id}`} />}
        {isExpanded && <ParcelContents parcel={props.parcel}
                                       additionalDataFields={props.searchListModel.additionalFields} />}
    </>;
};

import { action, computed, observable } from "mobx";

import { IconType } from "../icon/icon";
import { t } from "i18next";

interface IMessageAction {
    onClick: () => void;
    title: string;
}

type NotificationLevel =  "success" | "warning" | "error";

interface IMessage {
    key?: string;
    message: string;
    level: NotificationLevel;
    icon: IconType;
    action?: IMessageAction;
}

class NotificationModel {
    @observable private _persistentMessageList: IMessage[] = [];
    @observable private _temporaryMessageList: IMessage[] = [];

    @computed public get persistentMessageList() {
        return this._persistentMessageList as ReadonlyArray<IMessage>;
    }
    @computed public get temporaryMessageList() {
        return this._temporaryMessageList as ReadonlyArray<IMessage>;
    }

    @action
    public addPersistentMessage(level: NotificationLevel, icon: IconType, message: string, messageAction: IMessageAction, params?: {}) {
            this._persistentMessageList.push({ message: t(message, params), level, icon, action: {onClick: messageAction.onClick, title: t(messageAction.title)}});
    }

    @action
    public addErrorMessage(error: string,  params?: {}) {
        this._persistentMessageList.push({ message: t(error, params), level: "error", icon: "error"});
    }

    @action
    public addTranslatedErrorMessage(error: string) {
        this._persistentMessageList.push({ message: error, level: "error", icon: "error"});
    }

    @action
    public addSuccessMessage(successMessage: string, params?: {}, key?: string) {
        this._temporaryMessageList.push({ key, message: t(successMessage, params), level: "success", icon: "check" });
    }

    @action
    public addWarningMessage(warningMessage: string, params?: {}) {
        this._temporaryMessageList.push({ message: t(warningMessage, params), level: "warning", icon: "warning" });
    }

    @action
    public removeTemporaryMessage(idx: number) {
        this._temporaryMessageList.splice(idx, 1);
    }

    @action
    public addOrUpdateSuccessMessage(msgKey: string, successMessage: string, params?: {}) {
        let isInList = false;
        this._temporaryMessageList.forEach(notification => {
            if (notification.key === msgKey) {
                isInList = true;
                notification.message = t(successMessage, params);
            }
        });

        if (!isInList) {
            this.addSuccessMessage(successMessage, params, msgKey);
        }
    }

    @action
    public removePersistantMessage(idx: number) {
        this._persistentMessageList.splice(idx, 1);
    }
}

export const notificationModel = new NotificationModel();

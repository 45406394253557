import { IBulkParcelUploadImportValidationConstraint } from "../bulk-parcel-upload-import-validator";
import { t } from "i18next";
import { BulkParcelUploadDataReader } from "../bulk-parcel-upload-data-reader";

export class BulkParcelUploadBluebrandConstraint implements IBulkParcelUploadImportValidationConstraint {
    public validateHeader(headerColumns: string[]) {
        if (headerColumns.filter(c => c === BulkParcelUploadDataReader.columnParcelTracker).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerParcelTrackerMissing"));
        }

        if (headerColumns.filter(c => c === BulkParcelUploadDataReader.columnCarrierName).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerCarrierNameMissing"));
        }

        return true;
    }

    public validateLine(lineNumber: number, content: Map<string, string>) {
        if (this.getValueAsString(content, BulkParcelUploadDataReader.columnParcelTracker).length > 50) {
            throw new Error(t("bulkParcelUpload.error.contentParcelTrackerTooLong", {lineNumber}));
        }

        return true;
    }

    private getValueAsString(content: Map<string, string>, key: string): string {
        return content.get(key)?.trim() ?? "";
    }
}

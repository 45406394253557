import { action, computed, observable } from "mobx";

import { FilterActionData, FilterActionType } from "./filter-action-data";
import { remove } from "lodash";
import { ISearchListRow, ISearchListSubRow } from "../search-list/search-list";

export class FilterData<TFilter extends {}, TRow extends ISearchListRow<TSubRow>, TSubRow extends ISearchListSubRow> {

    constructor(filter: TFilter, isAndSearch: boolean, filterAction?: Array<FilterActionData<TFilter, TRow, TSubRow>>) {
        this._filter = filter;
        this._filterAction = filterAction || [];
        this._isAndSearch = isAndSearch;
    }

    @observable private _isAndSearch: boolean;
    @computed
    public get isAndSearch(): boolean {
        return this._isAndSearch;
    }

    @observable private _filter: TFilter;
    @computed
    public get filter(): TFilter {
        return this._filter;
    }

    @observable private _filterAction: Array<FilterActionData<TFilter, TRow, TSubRow>>;
    @computed
    public get filterAction() {
        return this._filterAction;
    }

    public isInScope(row: TRow) {
        if (this.isAndSearch) {
            return !this._filterAction.some(filterAction => !filterAction.isInScope(this._filter, row));
        }
        return this._filterAction.some(filterAction => filterAction.isInScope(this._filter, row));
    }

    @action
    public removeFilterActionList(filterFieldList: Array<keyof TFilter & (keyof TRow | keyof TSubRow)>) {
        filterFieldList.forEach(filterField => {
            delete this._filter[filterField];
            remove(this._filterAction, f => {
                return f.filterField === filterField;
            });
        });

        if (this._filterAction.length === 0 && !this.isAndSearch) {
            this._isAndSearch = true;
        }
    }

    @action
    public pushFilterActionData(filterField: keyof TFilter & (keyof TRow | keyof TSubRow),
                                filterAction: FilterActionType) {
        this.filterAction.push(new FilterActionData<TFilter, TRow, TSubRow>(filterField, filterAction));
    }
}

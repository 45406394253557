import * as React from "react";
import { useState } from "react";
import { VPUITheme } from "../../../common/theme/vpui-theme";
import { Done } from "@mui/icons-material";
import { useMobileLogin } from "./use-mobile-login";
import { RemotePrinterContextProvider } from "../../context/printer-context/remote-printer-context";
import { t } from "i18next";
import { useClearOnTimeout } from "../../../common/hooks/use-clear-on-timeout";
import { useBeep } from "../../../common/hooks/use-beep";
import { SynchronizedHistory } from "mobx-react-router";
import { Notification } from "../../component/notification/notification";
import { Sound } from "../../../common/hooks/use-sound";
import { ScanShipLoginScreen } from "./scan-ship-login-screen";
import { CameraScanner } from "../../component/camera-scanner/camera-scanner";
import { useTimeout } from "../../../common/hooks/use-timeout";
import { ThemeProvider, Typography } from "@mui/material";
import { MobileLoginQrCode } from "../../../common/model/mobile-login-qr-code";

import "./styles.scss";

interface IScanShipLoginProps {
    routing: SynchronizedHistory;
    onChangeLoginMode: () => void;
}

interface ILoginErrorProps {
    errorMessage: string;
}

export const ScanShipLoginComponent = (props: IScanShipLoginProps) => {
    return <ThemeProvider theme={VPUITheme}>
        <RemotePrinterContextProvider>
            <ScanShipLogin {...props} />
        </RemotePrinterContextProvider>
    </ThemeProvider>;
};

const ScanShipLogin = (props: IScanShipLoginProps) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [scannedQrCode, setScannedQrCode] = useState<MobileLoginQrCode | undefined>();
    const [isSuccess, setIsSuccess] = useState(false);

    const handleCodeScan = (rawData: string) => {
        try {
            setScannedQrCode(MobileLoginQrCode.fromJSON(rawData));
        } catch (err) {
            setErrorMessage(err.message);
        }
    };

    const handleSuccessLogin = () => {
        setIsSuccess(true);
    };

    useMobileLogin(scannedQrCode,
        handleSuccessLogin,
        setErrorMessage);

    useBeep(errorMessage, Sound.Error);
    useBeep(isSuccess, Sound.Success);
    useClearOnTimeout(errorMessage, setErrorMessage);
    useTimeout(() => props.routing.push("/"), 2500, isSuccess);

    if (isSuccess && scannedQrCode) {
        return <ScanShipLoginScreen {...props}>
            <Notification icon={Done}>
                <Typography>
                    {t("components.scanAndShip.login.success", {
                        userName: scannedQrCode.userName,
                    })}
                </Typography>
            </Notification>
        </ScanShipLoginScreen>;
    }

    return <ScanShipLoginScreen {...props}>
        <div className={"item-scanner scanner"}>
            <CameraScanner onValueScan={handleCodeScan} qrMode />
            {!errorMessage ?
                <LoginDescription /> :
                <LoginError errorMessage={errorMessage} />}
        </div>
    </ScanShipLoginScreen>;
};

const LoginDescription = () => {
    return <Typography className={"scanner__description scanner__description--overlay"}>
        {t("components.scanAndShip.login.prompt")}
    </Typography>;
};

const LoginError = (props: ILoginErrorProps) => {
    return <Typography className={"scanner__description scanner__description--overlay scanner__description--error"}>
        {t("components.scanAndShip.login.error.message", {
            errorMessage: props.errorMessage,
        })}
    </Typography>;
};

import React, { useState } from "react";
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { ScanFieldMultiple } from "../../../../common/component/scan-field-multple/scan-field-multiple";
import { Warning } from "@mui/icons-material";
import { yellow } from "@mui/material/colors";
import { PalletShippingValidationHandler } from "./pallet-shipping-validation-handler";
import {
    PalletShippingValidationRequest,
    PalletShippingValidationStrategy,
} from "./pallet-shipping-validation-request";
import { t } from "i18next";

import "./style.scss";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { useWarehouseContext } from "../warehouse-context/warehouse-context-provider";

interface IPalletShippingScanElementsProps {
    palletList: PalletsListItem[];
    palletListWarning?: string;

    getPalletItemByScan: (palletCode: string) => PalletsListItem | undefined;

    onCancel: () => void;
    onFinished: (palletCodeList: PalletsListItem[]) => void;
}

export const PalletShippingScanElements = (props: IPalletShippingScanElementsProps) => {
    const [scannedPalletsList, setScannedPalletsList] = useState<PalletsListItem[]>(props.palletList);
    const [isScanningInProgress, setIsScanningInProgress] = useState(false);
    const [warningMessage, setWarningMessage] = useState<string | undefined>();

    const warehouseContext = useWarehouseContext();

    const handleItemScanned = async (scanValue: string) => {
        setIsScanningInProgress(true);
        const item = props.getPalletItemByScan(scanValue);
        if (item === undefined || !warehouseContext.code) {
            setIsScanningInProgress(false);
            throw new Error(t("components.pallet.shipping.scan.itemNotFound", {
                code: scanValue,
            }));
        }

        const validationResult = await (new PalletShippingValidationHandler())
            .handle(new PalletShippingValidationRequest([item], PalletShippingValidationStrategy.ForScanMode));
        if (validationResult.isError) {
            setIsScanningInProgress(false);
            throw new Error(validationResult.message);
        }

        if (validationResult.isWarning) {
            setIsScanningInProgress(false);
            setWarningMessage(validationResult.message);
        }

        setIsScanningInProgress(false);

        setScannedPalletsList(s => [...s, item]);
    };

    return <>
        <DialogTitle>
            {(t("components.pallet.shipping.shipMultiple"))}
        </DialogTitle>
        <DialogContent>
            <Typography className={"pallet-shipping-dialog__description"}>
                {t("components.pallet.shipping.scan.description")}
            </Typography>
            {(warningMessage || props.palletListWarning) && <Typography className={"pallet-shipping-dialog__warning"}>
                <Warning htmlColor={yellow[800]} />
                {warningMessage || props.palletListWarning}
            </Typography>}
            <ScanFieldMultiple
                autoFocus
                list={scannedPalletsList.map(p => p.code)}
                label={t("model.pallet.code")}
                onScan={async scanValue => await handleItemScanned(scanValue)} />
        </DialogContent>
        <DialogActions>
            <Button color={"inherit"}
                    variant="contained"
                    type="submit"
                    key="cancel-btn"
                    data-testid="pallet-shipping-cancel"
                    onClick={() => props.onCancel()}>
                {t("components.popin.cancel")}
            </Button>
            <Button color="secondary"
                    variant="contained"
                    type="submit"
                    key="submit-btn"
                    data-testid="pallet-shipping-continue"
                    disabled={scannedPalletsList.length === 0 || isScanningInProgress}
                    onClick={() => props.onFinished(scannedPalletsList)}>
                {(t("components.pallet.shipping.scan.continue"))}
            </Button>
        </DialogActions>
    </>;
};

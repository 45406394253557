import "./style.scss";

import * as React from "react";

import IconMaterial from "@mui/material/Icon/Icon";
import { IconModel } from "./icon-model";
import { Tooltip, Button, Fab } from "@mui/material";
import { context } from "../../../context/context";
import { observer } from "mobx-react";
import { t } from "i18next";
import { Permission } from "../../../context/permission";

export type IconType =
    "add" |
    "arrow_downward" |
    "arrow_upward" |
    "archive" |
    "arrow_back" |
    "cancel" |
    "check" |
    "check_box" |
    "close" |
    "cloud" |
    "cloud_upload" |
    "content_paste" |
    "delete" |
    "done" |
    "error" |
    "exit_to_app" |
    "flag" |
    "publish" |
    "insert_drive_file" |
    "local_shipping" |
    "lock" |
    "lock_open" |
    "search" |
    "help" |
    "home" |
    "input" |
    "keyboard_arrow_down" |
    "keyboard_arrow_up" |
    "local_see" |
    "list" |
    "mode_edit" |
    "notifications" |
    "note_add" |
    "person" |
    "print" |
    "refresh" |
    "reply" |
    "remove" |
    "save" |
    "settings" |
    "settings_ethernet" |
    "swap_vert" |
    "undo" |
    "vertical_align_top" |
    "play_circle_filled" |
    "view_comfy" |
    "warning" |
    "report_problem" |
    "date_range";

export interface IIconProps {
    align?: "left" | "right";
    disabled?: boolean;
    icon: IconType | JSX.Element;
    isHidden?: boolean;
    isRounded?: boolean;
    secondaryIcons?: IconType[];
    onClick?: (event: any) => void;
    type?: "submit" | "reset" | "button";
    className?: string;
    rightText?: string;
    contained?: boolean;
    tooltipTitle?: string;
    autoFocus?: boolean;
    color?: "inherit" | "primary" | "secondary";
    permission?: Permission;
    tooltipPlacement?: "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

@observer
export class Icon extends React.Component<IIconProps, {}> {

    private iconModel = new IconModel();

    public render() {
        if (this.props.permission && !context.hasPermission(this.props.permission)) {
            return <div />;
        }
        if (this.props.tooltipTitle) {
            return (
                <Tooltip title={t(this.props.tooltipTitle)} placement={this.props.tooltipPlacement || "bottom"}>
                    {this.isButton ? this.renderIconButton() : this.renderIcon()}
                </Tooltip>
            );
        }
        return this.isButton ? this.renderIconButton() : this.renderIcon();
    }

    private renderIconButton() {
        return <div className={"icon-button__container" +
                ` ${this.props.isHidden ? "icon-button__container--hidden" : ""}` +
                ` ${this.props.disabled ? "icon-button__container--disabled" : ""}` +
                ` ${this.props.align === "right" ? "icon-button__container--right" : ""}` +
                ` ${this.props.className || ""}`}>
            {this.renderButtonElementByType()}
        </div>;
    }

    private renderButtonElementByType() {
        return this.props.contained || !this.props.isRounded ?
            this.renderRegularButton() : this.renderActionButton();
    }

    private renderRegularButton() {
        return <Button variant={this.iconButtonVariant}
                       className={`icon-button__button icon-button__button--${this.iconButtonVariant}`}
                       onClick={this.props.onClick}
                       color={this.iconColor}
                       disabled={this.props.disabled === undefined ? false : this.props.disabled}
                       onMouseOver={() => this.iconModel.highlight()}
                       onMouseOut={() => this.iconModel.lowlight()}
                       onBlur={() => this.iconModel.lowlight()}
                       data-testid={typeof this.props.icon === "string" ? this.props.icon : ""}
                       type={this.props.type}
                       autoFocus={this.props.autoFocus}>
            {this.renderButtonIconElement()}
            {this.props.rightText}
        </Button>;
    }

    private renderActionButton() {
        return <Fab className={`icon-button__button icon-button__button-rounded`}
                    onClick={this.props.onClick} color={this.iconColor}
                    disabled={this.props.disabled === undefined ? false : this.props.disabled}
                    onMouseOver={() => this.iconModel.highlight()}
                    onMouseOut={() => this.iconModel.lowlight()}
                    onBlur={() => this.iconModel.lowlight()}
                    data-testid={typeof this.props.icon === "string" ? this.props.icon : ""}
                    type={this.props.type}
                    autoFocus={this.props.autoFocus}>
            {this.renderButtonIconElement()}
            {this.props.rightText}
        </Fab>;
    }

    private get iconButtonVariant() {
        return this.props.contained ? "contained" : "text";
    }

    private get iconColor() {
        return this.props.color || (this.iconModel.isPrimary ? "primary" : "inherit");
    }

    private renderButtonIconElement() {
        if (typeof this.props.icon === "string") {
            return this.props.icon.split(".").map(icon =>
                <i className={"material-icons " + (this.props.className || "")} key={icon}>{icon}</i>);
        } else {
            return this.props.icon;
        }
    }

    private renderIcon() {
        const color = this.props.color || (this.iconModel.isPrimary ? "primary" : "inherit");
        return (
            <div className={"icon-button__container " + (this.props.className || "") + (this.props.isHidden ? " icon-button__container-hidden" : "")}>
                {this.renderIconElement(color)}
                {this.props.rightText}
            </div>);
    }

    private renderIconElement(color: any) {
        if (typeof this.props.icon === "string") {
            return this.props.icon.split(".").map(icon =>
                <IconMaterial className={"material-icons " + (this.props.className || "")} key={icon} color={color}
                    data-testid={this.props.icon}>
                    {icon}
                </IconMaterial>);
        } else {
            return this.props.icon;
        }
    }

    private get isButton() {
        return this.props.onClick || this.props.type;
    }
}

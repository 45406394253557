import { observable, action } from "mobx";
import { OperationCounterType, PalletCounterType } from "../../constants/constants";
import { OperationCounter } from "../../model/operation-counter";
import { countersService } from "../../services/counters";
import { palletService } from "../../services/pallet";
import { PalletCounter } from "../../model/pallet-counter";

class PreparationMenuModel {

    @observable private _counterMap: Map<OperationCounterType, number> = new Map<OperationCounterType, number>();
    @observable private _palletCounterMap: Map<PalletCounterType, number> = new Map<PalletCounterType, number>();

    @action
    public updateCounters(operationCounterList: OperationCounter[]) {
        this._counterMap.clear();
        operationCounterList.forEach(counter => {
            this._counterMap.set(counter.counterName, counter.counterValue);
        });
    }

    @action
    public updatePalletCounters(palletCountersList: PalletCounter[]) {
        this._palletCounterMap.clear();
        palletCountersList.forEach(counter => {
            this._palletCounterMap.set(counter.counterName, counter.counterValue);
        });
    }

    public getCounter(counter: OperationCounterType) {
        return this._counterMap.get(counter);
    }

    public getPalletCounter(counter: PalletCounterType) {
        return this._palletCounterMap.get(counter);
    }

    public async refreshCounters(operationCode: string, batchId: number) {
        const counters = await countersService.loadOperationCounter(operationCode, batchId);
        this.updateCounters(counters);
    }

    public async refreshPalletCounters(warehouseId: string | undefined) {
        if (warehouseId === undefined) {
            this._palletCounterMap.clear();
            return;
        }

        const counters = await palletService.loadPalletCounters(warehouseId);
        this.updatePalletCounters(counters);
    }
}

export const menuOperationModel = new PreparationMenuModel();

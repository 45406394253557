import * as React from "react";

import { TableCell, TableSortLabel } from "@mui/material";

import { Icon } from "../../icon/icon";
import { SearchListModel } from "./search-list-model";
import { t } from "i18next";
import { ISearchListRow, ISearchListSubRow } from "./search-list";

interface IListSortProps<TRow extends ISearchListRow<TSubRow>, TSubRow extends ISearchListSubRow, TFilter extends {}> {
    searchListModel: SearchListModel<TRow, TSubRow, TFilter>;
}

export abstract class ListSort<TRow extends ISearchListRow<TSubRow>, TSubRow extends ISearchListSubRow, TFilter extends {}, P>
    extends React.Component<IListSortProps<TRow, TSubRow, TFilter> & P, {}> {

    protected renderHeaderSort(labelKey: string, fieldName: string) {
        return <div onClick={() => this.props.searchListModel.applySort(fieldName, fieldName.split(".").length)}
            className="sortField"
            data-testid={`column-${fieldName}`}>
            {t(labelKey)}&nbsp;{this.renderSortArrow(fieldName)}
        </div>;
    }

    private renderSortArrow(fieldName: string) {
        const sort = this.props.searchListModel.getSortByLevel(fieldName.split(".").length);
        if (sort && sort.fieldName === fieldName) {
            return sort.sortOrder === "desc" ? <Icon icon="arrow_downward" /> : <Icon icon="arrow_upward" />;
        }
        return undefined;
    }

    protected renderColumnSort(labelKey: string, fieldName: string, colSpan?: number) {
        return <TableCell key={labelKey}
                          className={"search-item__sub-header-cell"}
                          colSpan={colSpan}
                          data-testid={`column-lvl2-${fieldName}`}>
            {this.renderSort(labelKey, fieldName, fieldName.split(".").length)}
        </TableCell>;
    }

    protected renderAdditionalColumnSubSort() {
        return this.props.searchListModel.additionalFields.map(field => this.renderColumnSort(field, `details.${field}`));
    }

    private renderSort(labelKey: string, fieldName: string, priority: number) {
        const sort = this.props.searchListModel.getSortByLevel(priority);
        const isActive = sort && sort.fieldName === fieldName;
        return <TableSortLabel active={isActive}
            className={`${isActive ? "" : "inactive"}`}
            direction={sort ? sort.sortOrder : undefined}
            onClick={() => this.props.searchListModel.applySort(fieldName, priority)} >
            {t(labelKey)}
        </TableSortLabel>;
    }
}

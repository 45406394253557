import {DeliveryOrder} from "../../../model/delivery-order";
import {operationsService} from "../../../services/operations";

export abstract class OrdersDataProvider {

    public async abstract loadList(operationCode: string, batchId: number): Promise<DeliveryOrder[]>;
    public abstract isInPageScope(deliveryOrder: DeliveryOrder): boolean;

    public async loadByIds(operationCode: string, batchId: number, deliveryOrderIdList: number[]): Promise<DeliveryOrder[]> {
        const calls = deliveryOrderIdList.map(
            deliveryOrderId => operationsService.loadDeliveryOrder(
                operationCode,
                batchId,
                deliveryOrderId));

        return Promise.all(calls);
    }
}

import "typeface-roboto";
import "../../style/fonts.scss";

import * as React from "react";

import { Observer, Provider} from "mobx-react";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import { ClickAndShipApp } from "./click-and-ship-app";
import { AuthenticatedRoute } from "../../common/navigation/authenticated-route";
import { Router, Route, Routes } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";
import { ThemeProvider } from "@mui/material";
import { Permission } from "../../context/permission";
import { theme } from "../../back-office/components/app-theme";
import { UserNotificationCenterContextProvider } from "../../common/component/notifications/notification-center/user-notification-center-context-provider";
import { LoginPage } from "../../common/component/login/login-page";
import { useWindowSize } from "../../common/hooks/use-window-size";
import { ChangePassword } from "./reset-password/change-password";

const browserHistory = createBrowserHistory({basename: "/#"});
const routingStore = new RouterStore();
const history = syncHistoryWithStore(browserHistory, routingStore);

export interface IAppRouterProps {
    locale?: string;
}

export const AppRouter = (props: IAppRouterProps) => {
    const windowSize = useWindowSize();

    if (windowSize.height !== -1 && windowSize.isMobile) {
        window.location.replace(`${window.location.origin}/scan-and-ship`);
        return <></>;
    }

    return <Observer>
        {() => <div key={props.locale} data-reactroot>
            <Provider routing={routingStore} >
                <ThemeProvider theme={theme}>
                    <Router location={routingStore.location!} navigator={history} >
                        <Routes>
                            <Route path="/login" element={<LoginPage routing={history} />} />
                            <Route path="/change-password/:token?" element={<ChangePassword routing={history} />} />
                            <Route path="/*" element={
                                <AuthenticatedRoute routing={history} permission={Permission.FrontRead}>
                                    <UserNotificationCenterContextProvider>
                                        <ClickAndShipApp routing={history} />
                                    </UserNotificationCenterContextProvider>
                                </AuthenticatedRoute>
                            } />
                        </Routes>
                    </Router>
                </ThemeProvider>
            </Provider>
        </div>}
    </Observer>;
};

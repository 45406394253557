import * as React from "react";

import { ThemeProvider, Table, TableBody, TableHead } from "@mui/material";
import { FilterData } from "../../../../common/component/list/filter/filter-data";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import InfiniteScroll from "react-infinite-scroller";
import { SearchList } from "../../../../common/component/list/search-list/search-list";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { ExportType } from "../../../model/export-data-contents";
import { PalletDataProvider } from "./pallet-data-provider";
import { PalletsListItem } from "./pallets-list-item";
import { PalletsListSubItem } from "./pallets-list-sub-item";
import { PalletsListFilter } from "./pallets-list-filter";
import { PalletFilter } from "./pallet-filter";
import { PalletsListSort } from "./pallets-list-sort";
import { context } from "../../../../context/context";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { ManagePalletActionType } from "../../../constants/constants";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import { observable } from "mobx";
import { QuickFilterSelect } from "../../../../common/component/quick-filter/quick-filter-select";
import { getFieldLabel } from "../../../../common/field/renderer";
import { groupBy, map } from "lodash";
import { QuickFilterOption } from "../../../../common/component/quick-filter/quick-filter-option";
import { t } from "i18next";
import { PalletsListLine } from "./pallets-list-line";
import { ReactNode } from "react";
import { menuOperationModel } from "../../preparation-menu/preparation-menu-model";

export abstract class PalletsList<TState extends {} = {}> extends
    SearchList<IHistoryProps, PalletsListItem, PalletsListSubItem, PalletFilter, TState> {

    protected prefixLabel: string = "model.pallet.";

    @observable
    protected quickFilterData = new FilterData<PalletFilter, PalletsListItem, PalletsListSubItem>(new PalletFilter(), true, []);
    protected defaultQuickFilterFields = ["carrier"] as Array<keyof PalletFilter & keyof PalletsListItem>;

    protected constructor(
        props: IHistoryProps,
        searchListModel: SearchListModel<PalletsListItem, PalletsListSubItem, PalletFilter>,
        private dataProvider: PalletDataProvider,
    ) {
        super(props, searchListModel);
    }

    protected get listIdentifier() {
        return context.warehouseContext?.code;
    }

    public async componentWillMount() {
        await super.componentWillMount();
    }

    public async componentWillUnmount() {
        await super.componentWillUnmount();
    }

    protected async loadList() {
        await menuOperationModel.refreshPalletCounters(context.warehouseContext?.code);
        return await this.dataProvider.loadList();
    }

    protected abstract get actionsList(): Array<IActionCommand<PalletsListItem, ManagePalletActionType>>;
    protected abstract openSingleActionPickerMenu(anchor: any, pallet: PalletsListItem): void;

    public render() {
        const list = this.getList();
        return <div>
            <InfiniteScroll
                pageStart={0}
                loadMore={() => this.searchListModel.showMore()}
                hasMore={this.hasMoreElement}>
                <div>
                    {this.renderListBar(
                        list.total, "batchPreparation.quickFilter.label", "batchPreparation.quickFilter.tooltip", "text")}
                    <Table data-testid="dor-list">
                        <TableHead>
                            {this.renderSortBar()}
                        </TableHead>
                        <TableBody className="table-body">
                            <ThemeProvider theme={VPUITheme}>
                                {list.list}
                            </ThemeProvider>
                        </TableBody>
                    </Table>
                </div>
            </InfiniteScroll>
        </div>;
    }

    protected renderAdditionalQuickFiltering() {
        return <>
            <QuickFilterSelect title={getFieldLabel(this.searchListModel.filterData.filter, "country")}
                               propertyKey={"country"}
                               loadDefaultValue={() => this.getCurrentFilterValue("country")}
                               onQuickFilterSelect={value => this.onQuickFilterSelected("country", value)}>
                {map(groupBy(this.loadQuickFilterOptions(["country"]), "country"), (values, v) =>
                    <QuickFilterOption count={values.length} value={v}>
                        {t(`components.quickFilter.country.${v}`)}
                    </QuickFilterOption>)}
            </QuickFilterSelect>
            <QuickFilterSelect title={getFieldLabel(this.searchListModel.filterData.filter, "parcelCount")}
                               propertyKey={"parcelCount"}
                               loadDefaultValue={() => this.getCurrentFilterValue("parcelCount")}
                               onQuickFilterSelect={value => this.onQuickFilterSelected("parcelCount", value)}>
                {map(groupBy(this.loadQuickFilterOptions(["parcelCount"]), "parcelCount"), (values, v) =>
                    <QuickFilterOption value={v}
                                       count={values.length}>
                        {v}
                    </QuickFilterOption>)}
            </QuickFilterSelect>
        </>;
    }

    protected renderFilterContent(currentFilter: FilterData<PalletFilter, PalletsListItem, PalletsListSubItem>) {
        return <PalletsListFilter initFilter={() => this.loadDefaultFilter()}
                                  searchListModel={this.searchListModel}
                                  defaultFilter={currentFilter} />;
    }

    protected renderSorterHeaderContent() {
        return <PalletsListSort searchListModel={this.searchListModel} />;
    }

    protected loadDefaultFilter(): PalletFilter {
        return new PalletFilter();
    }

    protected get sortAndFilterColumnsToIgnore() {
        return ["parcelId", "parcelTracker", "weight"];
    }

    protected applyQuickFilter(value: any) {
        if (value) {
            this.searchListModel.applyQuickFilter(this.loadDefaultFilter(), +value ?
                [
                    { filterKey: "parcelId", filterAction: "equals", filterValue: value, rowKey: "details" },
                    { filterKey: "deliveryOrderId", filterAction: "equals", filterValue: value, rowKey: "details" },
                    { filterKey: "orderId", filterAction: "equals", filterValue: value, rowKey: "details" },
                    { filterKey: "parcelTracker", filterAction: "equals", filterValue: value, rowKey: "details" },
                ] :
                [
                    { filterKey: "code", filterAction: "equals", filterValue: value },
                    { filterKey: "parcelTracker", filterAction: "equals", filterValue: value, rowKey: "details" },
                    { filterKey: "carrier", filterAction: "equals", filterValue: value },
                    { filterKey: "country", filterAction: "equals", filterValue: value },
                    { filterKey: "operationCode", filterAction: "equals", filterValue: value, rowKey: "details" },
                    { filterKey: "zipCode", filterAction: "equals", filterValue: value, rowKey: "details" },
                ]);
        } else {
            this.searchListModel.resetFilter(this.loadDefaultFilter());
        }
    }

    protected get singleActionsList() {
        return this.actionsList.filter(a => !a.disableSingleActionPicker)
            .filter(a => a.actionEligibilityValidator === undefined || a.actionEligibilityValidator.validate());
    }

    protected renderLine(pallet: PalletsListItem) {
        return <PalletsListLine pallet={pallet}
                                searchListModel={this.searchListModel}
                                isExpanded={this.expandOnDefault}
                                withSingleActionMenu={this.singleActionsList.length > 0}
                                customItemActionComponent={this.customItemActionComponent(pallet)}
                                onSingleActionMenuClick={anchor => this.openSingleActionPickerMenu(anchor, pallet)}
                                onPalletListReload={async () => this.reloadPalletList()}/>;
    }

    protected async reloadPalletList() {
        this.searchListModel.resetList(await this.loadList());
    }

    protected async refreshPalletItem(item: PalletsListItem, parcelLocator: string) {
        const updatedData = (await this.loadList()).filter(e => item.palletId === e.palletId);

        this.searchListModel.refreshList(updatedData, () => updatedData.length > 0);
        updatedData.forEach(pallet => {
            const detail = pallet.details.find(d => d.isMatchingLocator(parcelLocator));

            if (detail) {
                detail.highlight();

                setTimeout(() => {
                    detail.disableHighlight();
                }, 5000);
            }
        });
    }

    protected renderExcelExport(_sortFilterData: ReadonlyArray<PalletsListItem>, _exportType: ExportType) {
        return null;
    }

    protected renderActions(): JSX.Element | null {
        return null;
    }

    protected get isSelectionEnabled() {
        return false;
    }

    protected get expandOnDefault() {
        return false;
    }

    protected customItemActionComponent(_pallet: PalletsListItem): ReactNode | undefined {
        return undefined;
    }
}

import { adminService } from "../../back-office/services/admin";
import { t } from "i18next";
import { ExportDataContents } from "../../front-office/model/export-data-contents";
import { ExportDataItem } from "../../front-office/model/export-data-item";
import { excelDataExporter } from "../component/list/import-export-list/excel-data-exporter";
import { editorHelper } from "../../back-office/components/operations/editor-helper";
import { notificationModel } from "../component/notifications/notification-model";
import { dialogModel } from "../component/dialog/dialog-model";

export class OperationProductsEditorModel {
    private readonly _operationCode: string;

    constructor(operationCode: string) {
        this._operationCode = operationCode;
    }

    public get operationCode(): string {
        return this._operationCode;
    }

    public async downloadTemplateWithExportedData(fileName?: string) {
        const exportDataContents = ExportDataContents.rawContentsForSingleWorksheet(
            fileName ?? "products_with_mono_parcel_management_template",
            this.buildHeaderExportDataItem(),
            await this.buildContentsExportDataItems());

        excelDataExporter.export(exportDataContents, []);
    }

    public async uploadOperationProducts(file: File) {
        const data = await editorHelper.readUploadedFile(file,
            "product.operationProduct.processingUploadedFile",
            "product.operationProduct.uploadedFileFailed");

        if (data.length === 0) {
            notificationModel.addWarningMessage("product.operationProduct.emptyUpload");
            return;
        }

        dialogModel.confirmBox(
            t("components.form.action.submitConfirmTitle"),
            t("product.operationProduct.saveConfirmMany", {count: data.length, operationCode: this._operationCode}),
            async () => await this.executeComponentsUpdate(data),
        );
    }

    private async executeComponentsUpdate(data: Array<Map<string, string>>) {
        if (!this.validateImportFile(data)) {
            throw new Error(t("product.operationProduct.uploadedFileIncorrect"));
        }

        const duplicates = this.validateImportData(data);
        if (duplicates.length !== 0) {
            notificationModel.addErrorMessage("product.operationProduct.uploadedDataIncorrect", { duplicatedIds: duplicates });
            return;
        }

        await adminService.updateOperationProductsMonoParcel(this._operationCode, data.map(row => {
            return {
                productId: Number(row.get(this.productIdColumnName) ?? 0),
                monoParcel: Boolean(Number(row.get(this.monoParcelColumnName) ?? 0)),
            };
        }));

        notificationModel.addSuccessMessage("product.operationProduct.savedSuccessMany", {
            count: data.length,
            operationCode: this._operationCode,
        });
    }

    private validateImportFile(data: Array<Map<string, string>>) {
        return data[0].get(this.productIdColumnName) !== undefined &&
            data[0].get(this.productNameColumnName) !== undefined &&
            data[0].get(this.itemIdColumnName) !== undefined &&
            data[0].get(this.supplierReferenceColumnName) !== undefined &&
            data[0].get(this.barcodesColumnName) !== undefined &&
            data[0].get(this.widthColumnName) !== undefined &&
            data[0].get(this.heightColumnName) !== undefined &&
            data[0].get(this.lengthColumnName) !== undefined &&
            data[0].get(this.weightColumnName) !== undefined &&
            data[0].get(this.axRefGroupTypeColumnName) !== undefined &&
            data[0].get(this.monoParcelColumnName) !== undefined;
    }

    private validateImportData(data: Array<Map<string, string>>) {
        const productsIds = data.map(row => Number(row.get(this.productIdColumnName) ?? 0));
        return productsIds.filter((item, index) => productsIds.indexOf(item) !== index);
    }

    private buildHeaderExportDataItem() {
        return new ExportDataItem([
            this.productIdColumnName,
            this.productNameColumnName,
            this.itemIdColumnName,
            this.supplierReferenceColumnName,
            this.barcodesColumnName,
            this.widthColumnName,
            this.heightColumnName,
            this.lengthColumnName,
            this.weightColumnName,
            this.axRefGroupTypeColumnName,
            this.monoParcelColumnName,
        ]);
    }

    private async buildContentsExportDataItems() {
        return (await adminService.getOperationProducts(this._operationCode))
            .map(p => new ExportDataItem([
                p.productId,
                p.productName,
                p.itemId,
                p.supplierReference,
                p.barcodes.join(", "),
                p.width,
                p.height,
                p.length,
                p.weight,
                p.axRefGroupType,
                +p.monoParcel,
            ]));
    }

    private get productIdColumnName() {
        return t("model.operationProductsFormData.productId");
    }

    private get itemIdColumnName() {
        return t("model.operationProductsFormData.itemId");
    }

    private get productNameColumnName() {
        return t("model.operationProductsFormData.productName");
    }

    private get supplierReferenceColumnName() {
        return t("model.operationProductsFormData.supplierReference");
    }

    private get barcodesColumnName() {
        return t("model.operationProductsFormData.barcodes");
    }

    private get widthColumnName() {
        return t("model.operationProductsFormData.width");
    }

    private get heightColumnName() {
        return t("model.operationProductsFormData.height");
    }

    private get lengthColumnName() {
        return t("model.operationProductsFormData.length");
    }

    private get weightColumnName() {
        return t("model.operationProductsFormData.weight");
    }

    private get axRefGroupTypeColumnName() {
        return t("model.operationProductsFormData.axRefGroupType");
    }

    private get monoParcelColumnName() {
        return t("model.operationProductsFormData.monoParcel");
    }
}

import React, { useState } from "react";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { DialogContent, DialogTitle, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import { t } from "i18next";

interface IPalletFulfillmentCodeScanProps {
    getPalletItemByScan: (palletCode: string) => PalletsListItem | undefined;
    onScan: (pallet: PalletsListItem) => void;
}

export const PalletFulfillmentCodeScan = (props: IPalletFulfillmentCodeScanProps) => {
    const [textValue, setTextValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleOnKeyDown = async (keyDown: string) => {
        if (keyDown !== "Enter" || textValue === "") {
            return;
        }

        const item = props.getPalletItemByScan(textValue.trim());
        if (item === undefined) {
            setErrorMessage(`Pallet ${textValue} was not found`);
            return;
        }

        props.onScan(item);
    };

    return <>
        <DialogTitle>
            {t("components.pallet.fulfillment.title")}
        </DialogTitle>
        <DialogContent>
            <div className={"pallet-fulfillment-pallet-scan"}>
                <Typography className={"pallet-fulfillment-pallet-scan__description"}>
                    {t("components.pallet.assignment.scanPallet")}
                </Typography>
                <FormControl variant={"outlined"}
                             fullWidth>
                    <TextField id={"pallet-code-scan"}
                               value={textValue}
                               variant={"outlined"}
                               autoFocus
                               onKeyDown={async (event: React.KeyboardEvent<any>) => await handleOnKeyDown(event.key)}
                               onChange={event => setTextValue(event.target.value ?? "")}
                    />
                    {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
                </FormControl>
            </div>
        </DialogContent>
    </>;
};

import "./style.scss";

import { ProductStockout } from "./product-stockout";
import { Article } from "../../../model/article";
import { StockoutProductSimulationResult } from "./stockout-product-simulation-result";
import { stocksService } from "../../../services/stocks";
import { sumBy } from "lodash";
import { Stockout } from "../../../model/stockout";
import { observer } from "mobx-react";

@observer
export class RemoveProductStockout extends ProductStockout {

    public async simulateAction(deliveryOrderIds: number[], article: Article, quantity: number): Promise<StockoutProductSimulationResult> {
        const stockoutSimulationResult = await stocksService.bulkUnstockoutSimulation(
            this.props.operationCode,
            this.props.batchId,
            deliveryOrderIds,
            article.id,
            quantity,
        );

        return new StockoutProductSimulationResult(
            sumBy(stockoutSimulationResult, element => element.quantity),
            stockoutSimulationResult.length,
        );
    }

    public async executeAction(deliveryOrderIds: number[], article: Article, quantity: number): Promise<Stockout[]> {
        return stocksService.bulkUnstockout(
                this.props.operationCode,
                this.props.batchId,
                deliveryOrderIds,
                article.id,
                quantity,
            );
    }
}

import * as React from "react";
import { CarrierCountryPalletDeclarationFormModel } from "./carrier-country-pallet-declaration-input-model";
import { FormControl, FormLabel, TextField, Tooltip, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { Icon } from "../../../common/component/icon/icon";
import { t } from "i18next";
import { SquaredChip } from "../../../common/component/squared-chip/squared-chip";
import { lightBlue } from "@mui/material/colors";
import { Permission } from "../../../context/permission";
import { context } from "../../../context/context";

export interface IPalletPickupFormCarrierCountryDeclarationInputProps {
    key: string;
    model: CarrierCountryPalletDeclarationFormModel;
    isReadOnly: boolean;
}

@observer
export class PalletPickupFormCarrierCountryDeclarationInput extends React.Component<IPalletPickupFormCarrierCountryDeclarationInputProps, {}> {
    public render() {
        return <li data-testid={`pallet-pickup-form-declaration-${this.props.model.carrier}x${this.props.model.country}`}>
            <FormControl variant={"outlined"}
                         className={"pallet-pickup-form-input__container"}>
                <FormLabel htmlFor={`item-${this.props.model.carrier}x${this.props.model.country}`}
                           className={"pallet-pickup-form-input__label"}>
                    <Typography className={"pallet-pickup-form-input__label-text"} component={"span"}>
                        {this.props.model.carrier} / {this.props.model.country}
                    </Typography>
                </FormLabel>
                <TextField id={`item-${this.props.model.carrier}x${this.props.model.country}`}
                           key={`item-${this.props.model.carrier}x${this.props.model.country}`}
                           className={"pallet-pickup-form-input__input"}
                           variant={"outlined"}
                           size={"medium"}
                           data-testid={`input-${this.props.model.carrier}x${this.props.model.country}`}
                           onChange={(e: any) => this.validateAndUpdateValue(e.target.value)}
                           value={this.props.model.palletCount}
                           disabled={this.props.isReadOnly} />
                {context.hasPermission(Permission.FeaturePalletWorkflow) && <div className={"pallet-pickup-form-input__forecast"}>
                    <Tooltip title={t("palletPickup.declarationForm.forecastOpenPallets")}>
                        <div className={"pallet-pickup-form-input__forecast-item-container"}>
                            <SquaredChip color={lightBlue}
                                         className={"pallet-pickup-form-input__forecast-item"}
                                     propertyKey={"forecast-open"}
                                     label={`${t("palletPickup.declarationForm.openPallets")}: ${this.props.model.openPalletForecast}`}/>
                        </div>
                    </Tooltip>
                    <Tooltip title={t("palletPickup.declarationForm.forecastClosedPallets")}>
                        <div className={"pallet-pickup-form-input__forecast-item-container"}>
                            <SquaredChip color={lightBlue}
                                         className={"pallet-pickup-form-input__forecast-item"}
                                         propertyKey={"forecast-open"}
                                         label={`${t("palletPickup.declarationForm.closedPallets")}: ${this.props.model.closedPalletForecast}`}/>
                        </div>
                    </Tooltip>
                </div>}
                <div className={"pallet-pickup-form-input__error-icon-container"}>
                    {!this.props.model.isValid && <Icon
                        icon={"error"}
                        color={"primary"}
                        tooltipPlacement={"right-start"}
                        tooltipTitle={t("palletPickup.declarationForm.errorInvalidNumber")} />}
                </div>
            </FormControl>
        </li>;
    }

    private validateAndUpdateValue(value: string) {
        this.props.model.validateAndUpdateValue(value);
    }
}

import { observer } from "mobx-react";
import React, { SyntheticEvent } from "react";
import { CancellationRequestMenuModel } from "./cancellation-request-menu-model";
import { Menu, MenuItem } from "@mui/material";
import { dialogModel } from "../../../../common/component/dialog/dialog-model";
import { operationsService } from "../../../services/operations";
import { t } from "i18next";
import { notificationModel } from "../../../../common/component/notifications/notification-model";

interface ICancellationRequestMenuProps {
    model: CancellationRequestMenuModel;
    refreshSearchList: () => void;
}

@observer
export class CancellationRequestMenu extends React.Component<ICancellationRequestMenuProps & {}> {

    public render() {
        return <Menu
            id="order-cancellation-request-action-menu"
            anchorEl={this.props.model.anchorElement}
            open={this.props.model.isOpened}
            onClose={(event: SyntheticEvent) => this.closeMenuWithNoPropagation(event)}
            data-testid={"order-cancellation-request-action-menu"}
        >
            <MenuItem onClick={event => this.executeConfirmation(event)}
                      data-testid="order-cancellation-request-action-confirm">
                {t("deliveryOrderCancellation.action.title")}
            </MenuItem>
        </Menu>;
    }

    private executeConfirmation(event: SyntheticEvent) {
        if (!this.props.model.deliveryOrderId) {
            return;
        }

        const deliveryOrderId = this.props.model.deliveryOrderId;
        dialogModel.confirmBox(
            t("deliveryOrderCancellation.action.title"),
            t("deliveryOrderCancellation.action.description", {deliveryOrderId: this.props.model.deliveryOrderId}),
            async () => await this.confirmCancellationAction(this.props.model.operationCode,
                this.props.model.batchId,
                deliveryOrderId),
        );

        this.closeMenuWithNoPropagation(event);
    }

    private async confirmCancellationAction(operationCode: string, batchId: number, deliveryOrderId: number) {
        try {
            await operationsService.confirmCancellationRequest(operationCode,
                batchId,
                deliveryOrderId);

            await this.props.refreshSearchList();
            notificationModel.addSuccessMessage(t("deliveryOrderCancellation.action.successMessage"));
        } catch (error) {
            notificationModel.addErrorMessage(t("deliveryOrderCancellation.action.failedMessage"));
        }
    }

    private closeMenuWithNoPropagation(event: SyntheticEvent) {
        this.props.model.close();
        event.stopPropagation();
    }
}

import * as React from "react";

import { Table, TableBody, TableRow, TableCell } from "@mui/material";

import { Parcel } from "../../../model/parcel";
import ParcellingParcelDetailRow from "./parcelling-parcel-detail-row";
import ParcellingParcelRow from "./parcelling-parcel-row";
import { Typography } from "@mui/material";
import { find } from "lodash";
import { observer } from "mobx-react";
import { parcellingModel } from "./parcelling-model";
import { t } from "i18next";

interface IParcellingParcelListProps {
    batchId: number;
    operationCode: string;
}

@observer
export class ParcellingParcelList extends React.Component<IParcellingParcelListProps, {}> {

    private getParcelUniqueIdentifier(parcel: Parcel, index: number) {
        return parcel.id || -index;
    }

    public render() {
        return (
            <div>
                {this.renderParcelList()}
            </div>
        );
    }

    private renderParcelList() {
        if (parcellingModel.parcels.length === 0) {
            return <Typography variant="body2" >{t("deliveryOrderParcelling.parcelList.noParcels")}</Typography>;
        }
        return <Table className="delivery-order-parcel-table">
            <TableBody className="table-body">
                {parcellingModel.parcels.map((parcel, index) =>
                    this.renderParcelLine(parcel, index),
                )}
            </TableBody>
        </Table>;
    }

    private renderParcelLine(parcel: Parcel, index: number) {
        const uniqueParcelIdentifier = this.getParcelUniqueIdentifier(parcel, index);
        return [
            <ParcellingParcelRow
                key={"row-parcel-detail-" + index}
                uniqueParcelIdentifier={uniqueParcelIdentifier}
                index={index}
                operationCode={this.props.operationCode}
                batchId={this.props.batchId}
                parcel={parcel}
            />,
            ...this.renderProducts(parcel, uniqueParcelIdentifier)];
    }

    private renderProductsHeader(parcelId: number) {
        return <TableRow className="level2 header" key={"row-header-" + parcelId}>
            <TableCell>{t("model.parcelDetail.productId")}</TableCell>
            <TableCell>{t("model.parcelDetail.ean13List")}</TableCell>
            <TableCell>{t("model.parcelDetail.supplierReference")}</TableCell>
            <TableCell>{t("model.parcelDetail.labelReference")}</TableCell>
            <TableCell>{t("model.parcelDetail.weight")}</TableCell>
            <TableCell>{t("model.parcelDetail.quantity")}</TableCell>
        </TableRow >;
    }

    private renderProducts(parcel: Parcel, uniqueParcelIdentifier: number) {
        if (!parcellingModel.isExpanded(uniqueParcelIdentifier)) {
            return [];
        }
        return [
            this.renderProductsHeader(parcel.id),
            ...parcel.details.map((parcelDetail, idx) => {
                const doDetail = find(parcellingModel.deliveryOrder!.details,
                    d => d.productId === parcelDetail.productId)!;
                return <ParcellingParcelDetailRow
                    key={"row-product-detail-" + idx}
                    deliveryOrderDetail={doDetail}
                    parcelId={parcel.id}
                    parcelDetail={parcelDetail}
                    isEditingMode={parcellingModel.isEdit(uniqueParcelIdentifier)}
                />;
            })];
    }
}

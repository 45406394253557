import React, { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from "react";
import { Guid } from "guid-typescript";
import { IPrinterContextReducerPayload, RemotePrinterContextReducer } from "./remote-printer-context-reducer";
import { useContextTypeSafe } from "../../../common/hooks/use-context-type-safe";

interface IPrinterContextProviderProps {
    children: ReactNode;
}

export interface IRemotePrinterContext {
    printerId: Guid | undefined;
    printerName: string | undefined;
    printerType: "pdf" | "zpl" | undefined;
}

const defaultRemotePrinterContext: IRemotePrinterContext = {
    printerId: undefined,
    printerName: undefined,
    printerType: undefined,
};

const RemotePrinterContext = createContext<IRemotePrinterContext>(defaultRemotePrinterContext);
const RemotePrinterContextDispatcher = createContext<Dispatch<IPrinterContextReducerPayload> | null>(null);

export const RemotePrinterContextProvider = (props: IPrinterContextProviderProps) => {
    const [printerContext, printerContextDispatcher] = useReducer(RemotePrinterContextReducer, defaultRemotePrinterContext);

    useEffect(() => {
        printerContextDispatcher({
            action: "initialize",
        });
    }, []);

    return <RemotePrinterContext.Provider value={printerContext}>
        <RemotePrinterContextDispatcher.Provider value={printerContextDispatcher}>
            {props.children}
        </RemotePrinterContextDispatcher.Provider>
    </RemotePrinterContext.Provider>;
};

export const useRemotePrinterContext = () => {
    return useContext(RemotePrinterContext);
};

export const useRemotePrinterContextDispatcher = () => {
    return useContextTypeSafe(RemotePrinterContextDispatcher);
};

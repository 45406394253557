import { DropNetError } from "./error";

export class DropNetResponse<T> {

    private value: T | null;

    get Value(): T | null {
        return this.value;
    }

    private _succeeded: boolean;

    get succeeded(): boolean {
        return this._succeeded;
    }

    private readonly errors: DropNetError[] = [];

    public get Errors() {
        return this.errors as ReadonlyArray<DropNetError>;
    }

    public get ErrorDescription(): string {
        return this.errors.map(error => error.Description).join(", ");
    }

    constructor(succeeded: boolean, value?: T) {
        this.value = value || null;
        this._succeeded = succeeded;
    }

    public static Failed<T>(errors: DropNetError[]) {
        const result = new DropNetResponse<T>(false);
        errors.forEach(error => result.errors.push(error));
        return result;
    }
}

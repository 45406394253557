import React, { useState } from "react";
import { Dialog, IconButton, ThemeProvider } from "@mui/material";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import { Close } from "@mui/icons-material";
import { PalletFulfillmentCodeScan } from "./pallet-fulfillment-code-scan";
import { PalletFulfillmentParcelsScan } from "./pallet-fulfillment-parcels-scan";

import "./style.scss";

interface IPalletFulfillmentDialogProps {
    palletsList: PalletsListItem[];
    getPalletItemByScan: (palletCode: string) => PalletsListItem | undefined;

    onCancel: () => void;
    onSubmit: (pallet: PalletsListItem, parcelLocator: string) => void;
}

enum PalletFulfillmentDialogMode {
    ScanPalletMode,
    ScanParcelsMode,
}

export const PalletFulfillmentDialog = (props: IPalletFulfillmentDialogProps) => {
    const isScanMode = props.palletsList.length === 0;

    const [palletFulfillmentList, setPalletFulfillmentList] = useState<PalletsListItem[]>(props.palletsList);
    const [step, setStep] = useState(isScanMode ?
        PalletFulfillmentDialogMode.ScanPalletMode : PalletFulfillmentDialogMode.ScanParcelsMode);

    const handlePalletScanned = (pallet: PalletsListItem) => {
        setPalletFulfillmentList(s => [...s, pallet]);
        setStep(PalletFulfillmentDialogMode.ScanParcelsMode);
    };

    const handlePalletCompleted = (pallet: PalletsListItem) => {
        if (isScanMode) {
            setStep(PalletFulfillmentDialogMode.ScanPalletMode);
            setPalletFulfillmentList([]);

            return;
        }

        if (palletFulfillmentList.length === 1) {
            props.onCancel();
        } else {
            setPalletFulfillmentList(palletFulfillmentList.filter(p => p.id !== pallet.id));
        }
    };

    return <Dialog open={true}
                   disableRestoreFocus={true}>
        <ThemeProvider theme={VPUITheme}>
            <div className={"pallet-fulfillment-dialog"}
                 data-testid={"pallet-fulfillment-dialog"}>
                <div className="pallet-fulfillment-dialog__close">
                    <IconButton onClick={() => props.onCancel()}
                                data-testid={"close"}>
                        <Close />
                    </IconButton>
                </div>
                {step === PalletFulfillmentDialogMode.ScanPalletMode &&
                    <PalletFulfillmentCodeScan getPalletItemByScan={code => props.getPalletItemByScan(code)}
                                               onScan={pallet => handlePalletScanned(pallet)} />}
                {step === PalletFulfillmentDialogMode.ScanParcelsMode && palletFulfillmentList.length > 0 &&
                    <PalletFulfillmentParcelsScan pallet={palletFulfillmentList[0]}
                                                  hasNextElement={palletFulfillmentList.length > 1 || isScanMode}
                                                  onScan={(pallet, parcelLocator) =>
                                                    props.onSubmit(pallet, parcelLocator)}
                                                  onCompleted={pallet => handlePalletCompleted(pallet)} />}
            </div>
        </ThemeProvider>
    </Dialog>;
};

import React, { useEffect, useRef } from "react";
import { useCameraQrCodeScanner } from "./use-camera-qrcode-scanner";
import { useCameraStream } from "../../../../common/hooks/use-camera-stream";

interface ICameraQrCodeScannerProps {
    cameraDevice: MediaDeviceInfo;
    onValueScan: (value: string) => void;
    onNextCameraRequested?: () => void;
    onCameraAccessFailed: () => void;
}

export const CameraQrCodeScanner = (props: ICameraQrCodeScannerProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const streamState = useCameraStream(videoRef, props.cameraDevice.deviceId);
    useCameraQrCodeScanner(props.cameraDevice.deviceId,
        videoRef,
        props.onValueScan);

    useEffect(() => {
        if (streamState === "refused") {
            setTimeout(() => props.onCameraAccessFailed(), 500);
        }
    }, [streamState]);

    return <video autoPlay={true} muted={true} playsInline={true} controls={false}
                  className={"scanner__video"} ref={videoRef} onClick={props.onNextCameraRequested} />;
};

import { BulkParcelUploadImportLine } from "../../../../model/bulk-parcel-upload-import-line";
import { UploadedDeliveryOrder } from "./uploaded-delivery-order";
import { Dictionary, sumBy, groupBy, map, mapValues } from "lodash";
import { t } from "i18next";
import { UploadedParcel } from "./uploaded-parcel";
import { UploadedStockout } from "./uploaded-stockout";

export class UploadedDeliveryOrderBuilder {
    public createFromImportLines(importDataLines: BulkParcelUploadImportLine[]): UploadedDeliveryOrder {
        if (!this.indexAndParcelMatch(importDataLines)) {
            throw new Error(t("bulkParcelUpload.error.parcelIndexAndIdNotMatch", {
                deliveryOrderId: importDataLines[0].deliveryOrderId,
            }));
        }

        return new UploadedDeliveryOrder(
            importDataLines[0].deliveryOrderId,
            this.buildParcelList(importDataLines),
            this.buildStockoutList(importDataLines),
        );
    }

    private filterLinesSelectedToImport(importDataLines: BulkParcelUploadImportLine[]): BulkParcelUploadImportLine[] {
        return importDataLines.filter(line => line.quantityAssigned > 0 && line.parcelIndex !== undefined);
    }

    private filterLinesSelectedToStockout(importDataLines: BulkParcelUploadImportLine[]): BulkParcelUploadImportLine[] {
        return importDataLines.filter(line => line.quantityStockout > 0);
    }

    private indexAndParcelMatch(importDataLines: BulkParcelUploadImportLine[]): boolean {
        return map(groupBy(importDataLines.filter(l => l.parcelId !== undefined), l => l.parcelId))
                .every(group => group.every(el => el.parcelIndex === group[0].parcelIndex)) &&
            map(groupBy(importDataLines.filter(l => l.parcelId !== undefined), l => l.parcelIndex))
                .every(group => group.every(el => el.parcelId === group[0].parcelId));
    }

    private buildParcelList(importDataLines: BulkParcelUploadImportLine[]) {
        return map(groupBy(this.filterLinesSelectedToImport(importDataLines), p => p.parcelIndexOrEmpty), (parcelContent: BulkParcelUploadImportLine[]) => {
            if (!this.validateTrackingDataConsistency(parcelContent)) {
                throw new Error(t("bulkParcelUpload.error.differentParcelTrackersOrCarrierNamesForSingleParcel", {
                    deliveryOrderId: importDataLines[0].deliveryOrderId,
                }));
            }

            if (parcelContent[0].parcelTracker !== "" && parcelContent[0].carrierName === "") {
                throw new Error(t("bulkParcelUpload.error.trackingDataWithoutCarrierNameSubmitted", {
                    deliveryOrderId: importDataLines[0].deliveryOrderId,
                }));
            }

            return new UploadedParcel(this.buildParcelContents(parcelContent), parcelContent[0].parcelTracker, parcelContent[0].carrierName, parcelContent[0].parcelId);
        });
    }

    private buildStockoutList(importDataLines: BulkParcelUploadImportLine[]) {
        return map(groupBy(this.filterLinesSelectedToStockout(importDataLines), p => p.productId),
            (productStockoutLines: BulkParcelUploadImportLine[], productId: string) => this.buildStockout(Number(productId), productStockoutLines));
    }

    private buildParcelContents(importDataLines: BulkParcelUploadImportLine[]): Dictionary<number> {
        return mapValues(groupBy(importDataLines, line => line.productId), product => sumBy(product, "quantityAssigned"));
    }

    private buildStockout(productId: number, importDataLines: BulkParcelUploadImportLine[]): UploadedStockout {
        return new UploadedStockout(productId, sumBy(importDataLines, "quantityStockout"));
    }

    private validateTrackingDataConsistency(importDataLines: BulkParcelUploadImportLine[]): boolean {
        return importDataLines.every(l => l.parcelTracker === importDataLines[0].parcelTracker) &&
            importDataLines.every(l => l.carrierName === importDataLines[0].carrierName);
    }
}

import { IParamActionValidator } from "../../../../common/component/action/action-select/interface-param-action-validator";
import {
    ParamActionValidationResult,
} from "../../../../common/component/action/action-select/param-action-validation-result";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { t } from "i18next";

class EmptyPalletParamActionValidator implements IParamActionValidator<PalletsListItem> {
    public validate(pallets: PalletsListItem[]) {
        const nonEmptyPallets = pallets.filter(p => p.details.length !== 0);

        if (nonEmptyPallets.length === 1) {
            return ParamActionValidationResult.error(t("palletManagement.action.cancel.validation.isNotEmpty", {
                palletCode: nonEmptyPallets[0].code,
            }));
        }

        if (nonEmptyPallets.length > 1) {
            return ParamActionValidationResult.error(t("palletManagement.action.cancel.validation.someNotEmpty", {
                identifiersList: pallets
                    .filter(p => p.details.length !== 0)
                    .map(p => p.code)
                    .join(", "),
            }));
        }

        return ParamActionValidationResult.success(pallets);
    }
}

export const emptyPalletParamActionValidator = new EmptyPalletParamActionValidator();

import { Guid } from "guid-typescript";
import { PrinterDevice } from "../../../model/printer-device";

export class RemoteDocumentJob {
    private readonly _documentId: Guid;
    private readonly _printer: PrinterDevice;
    private _isCompleted: boolean;

    constructor(documentId: Guid, printer: PrinterDevice) {
        this._documentId = documentId;
        this._printer = printer;
        this._isCompleted = false;
    }

    get documentId(): Guid {
        return this._documentId;
    }

    get printer() {
        return this._printer;
    }

    get isCompleted(): boolean {
        return this._isCompleted;
    }

    public complete() {
        this._isCompleted = true;

        return this;
    }
}

import { ParcelBulkCreationMode } from "../../../../constants/constants";
import { IParamActionValidator } from "../../../../common/component/action/action-select/interface-param-action-validator";
import { DeliveryOrder } from "../../../model/delivery-order";

export class CustomParcelizationCommand {
    private readonly _operationCode: string;
    private readonly _batchId: number;
    private readonly _deliveryOrderList: DeliveryOrder[];
    private readonly _preparationMode: ParcelBulkCreationMode;
    private readonly _deliveryOrderListValidator?: IParamActionValidator<DeliveryOrder>;

    constructor(operationCode: string, batchId: number, deliveryOrderList: DeliveryOrder[],
                preparationMode: ParcelBulkCreationMode, deliveryOrderListValidator?: IParamActionValidator<DeliveryOrder>) {
        this._operationCode = operationCode;
        this._batchId = batchId;
        this._deliveryOrderList = deliveryOrderList;
        this._preparationMode = preparationMode;
        this._deliveryOrderListValidator = deliveryOrderListValidator;
    }

    get operationCode(): string {
        return this._operationCode;
    }

    get batchId(): number {
        return this._batchId;
    }

    get deliveryOrderList(): DeliveryOrder[] {
        return this._deliveryOrderList;
    }

    get preparationMode(): ParcelBulkCreationMode {
        return this._preparationMode;
    }

    get deliveryOrderListValidator(): IParamActionValidator<DeliveryOrder> | undefined {
        return this._deliveryOrderListValidator;
    }
}

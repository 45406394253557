import { endsWith, includes, startsWith, toUpper } from "lodash";
import accents from "remove-accents";
/*
Tous
Egal à
Différent de
Commence par
Finit par
Contient

Supérieur à
Inférieur à
Supérieur ou égal à
N'est pas renseigné
Est renseigné

Entre : TODO
*/

export type FilterActionType = "all" | "equals" | "different" | "startsWith" | "endsWith" | "contains" | "notContain" |
    "greater" | "lower" | "greaterOrEqual" | "lowerOrEqual" | "empty" | "notEmpty" | "isTrue" | "isFalse" |
    "isMatchingOneOf" | "isMatchingNoneOf" | "containsItem" | "notContainItem";

export const filterActionAllValues: FilterActionType[] = ["all", "equals", "different", "startsWith",
    "endsWith", "contains", "notContain", "greater", "lower", "greaterOrEqual", "lowerOrEqual", "empty", "notEmpty"];
export const filterActionStringValues: FilterActionType[] = ["all", "equals", "different", "startsWith",
    "endsWith", "contains", "notContain", "empty", "notEmpty"];

export const filterActionCsv: FilterActionType[] = ["isMatchingOneOf", "isMatchingNoneOf"];
export const filterActionAllValuesWithCsv: FilterActionType[] = [...filterActionAllValues, ...filterActionCsv];

export class FilterActionData<TFilter extends {}, TRow extends {}, TSubRow extends {} | undefined> {

    constructor(
        filterField: keyof TFilter & (keyof TRow | keyof TSubRow),
        filterAction: FilterActionType,
        rowKey?: keyof TRow) {
        this._filterField = filterField;
        this._filterAction = filterAction;
        this.rowKey = rowKey;
    }

    private rowKey?: keyof TRow;

    private _filterField: keyof TFilter & (keyof TRow | keyof TSubRow);
    public get filterField() {
        return this._filterField;
    }

    private _filterAction: FilterActionType;
    public get filterAction() {
        return this._filterAction;
    }

    public isInScope(filter: TFilter, row: TRow) {
        const filterValue = filter[this.filterField];
        if (this.rowKey) {
            return this.isInListScope(filterValue, row[this.rowKey] as unknown as TSubRow[]);
        }
        return this.compare(filterValue, row[this.filterField as keyof TRow]);
    }

    public isInListScope(filterValue: any, rowValueList: TSubRow[]) {
        return rowValueList.some(subRow => {
            const subValue = subRow![this.filterField as keyof TSubRow];
            return this.compare(filterValue, subValue);
        });
    }

    private compare(filterValue: any, rowValue: any) {
        switch (this._filterAction) {
            case "all":
                return true;
            case "equals":
                return accents.remove(toUpper(filterValue)) === accents.remove(toUpper(rowValue));
            case "contains":
            case "containsItem":
                return includes(`${accents.remove(toUpper(rowValue))}`, `${accents.remove(toUpper(filterValue))}`);
            case "notContain":
            case "notContainItem":
                return !includes(`${accents.remove(toUpper(rowValue))}`, `${accents.remove(toUpper(filterValue))}`);
            case "startsWith":
                return startsWith(accents.remove(toUpper(rowValue)), accents.remove(toUpper(filterValue)));
            case "endsWith":
                return endsWith(accents.remove(toUpper(rowValue)), accents.remove(toUpper(filterValue)));
            case "different":
                return accents.remove(toUpper(rowValue)) !== accents.remove(toUpper(filterValue));
            case "greater":
                return rowValue > filterValue;
            case "lower":
                return rowValue < filterValue;
            case "greaterOrEqual":
                return rowValue >= filterValue;
            case "lowerOrEqual":
                return rowValue <= filterValue;
            case "empty":
                return !rowValue;
            case "notEmpty":
                return !!rowValue;
            case "isTrue":
                return rowValue === true;
            case "isFalse":
                return rowValue === false;
            case "isMatchingOneOf":
                return this.isMatchingOfCSVElements(filterValue, rowValue);
            case "isMatchingNoneOf":
                return !this.isMatchingOfCSVElements(filterValue, rowValue);
            default:
                throw new Error(`This comparator '${this._filterAction}' is not implemented yet`);
        }
    }

    private isMatchingOfCSVElements(filterValue: any, rowValue: any) {
        return `,${filterValue},`.indexOf(`,${rowValue},`) !== -1;
    }
}

import "./style.scss";

import * as React from "react";

import { Input } from "../input/input";
import { ScanFieldModel } from "./scan-field-model";
import { formatNumber } from "../../field/renderer";
import { observer } from "mobx-react";
import { t } from "i18next";
import { Tooltip } from "@mui/material";

interface IScanFieldProps {
    scanFieldType: "text" | "number";
    label: string;
    tooltipTitle: string;
    onScan: (value: any) => void;
    autofocus?: boolean;
    forceFocus?: boolean;
    disabled?: boolean;
}

@observer
export class ScanField extends React.Component<IScanFieldProps, {}> {

    private scanFieldModel = new ScanFieldModel();

    public render() {
        if (this.props.tooltipTitle) {
            return <Tooltip title={t(this.props.tooltipTitle)} placement="right-start">
                {this.renderScanField()}
            </Tooltip>;
        }
        return this.renderScanField();
    }

    private renderScanField() {
        return <div className={"scanField"}>
            <Input propertyKey="scanField" errors={[]}
                value={this.scanFieldModel.scanValue}
                onKeyDown={keyDown => this.onKeyDown(keyDown)}
                onChange={value => this.onChange(value ?? "")}
                label={t(this.props.label)}
                htmlType="string" // We do not use the number type, but we escape the invalid characters onChange.
                autoFocus={this.props.autofocus}
                disabled={this.props.disabled}
                // inputRef={input => this.props.forceFocus && input && input.addEventListener("blur", () => input.focus())}
                isLabelShrunk={true}
            />
        </div>;
    }

    private onChange(value: string) {
        if (this.props.scanFieldType === "number") {
            this.scanFieldModel.setValue(formatNumber(value));
        } else {
            this.scanFieldModel.setValue(value);
        }
    }

    private onKeyDown(keyDown: string) {
        if (keyDown === "Enter") {
            this.props.onScan(this.scanFieldModel.scanValue);
            this.scanFieldModel.setValue("");
        }
    }
}

import { IExportDataFormat } from "./export-data-format";
import { FeatureToggle } from "../../../../../../context/app-configuration";
import { ExportDataItem, ExportDataValue } from "../../../../../model/export-data-item";
import { BulkParcelUploadExportLine } from "../../../../../model/bulk-parcel-upload-export-line";
import { t } from "i18next";
import { context } from "../../../../../../context/context";
import { Permission } from "../../../../../../context/permission";

export class DefaultDataExportDataFormat implements IExportDataFormat {
    public get header(): ExportDataItem {
        return new ExportDataItem([
            t("model.parcelExport.operationCode"),
            t("model.parcelExport.batchId"),
            t("model.parcelExport.orderId"),
            t("model.parcelExport.deliveryOrderId"),
            t("model.parcelExport.productId"),
            t("model.parcelExport.ean13List"),
            t("model.parcelExport.supplierReference"),
            t("model.parcelExport.labelReference"),
            t("model.parcelExport.quantity"),
            t("model.parcelExport.quantityParcelled"),
            context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) ? t("model.parcelExport.quantityStockout") : null,
            t("model.parcelExport.parcelIndex"),
            t("model.parcelExport.parcelId"),
            t("model.parcelExport.parcelTracker"),
            context.hasPermission(Permission.FeaturePalletWorkflow) ? t("model.parcelExport.palletIndex") : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? t("model.parcelExport.palletExternalId") : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? t("model.parcelExport.palletCode") : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? t("model.parcelExport.palletCarrier") : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? t("model.parcelExport.palletCountry") : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? t("model.parcelExport.palletCreationComment") : null,
            t("model.parcelExport.carrier"),
            t("model.parcelExport.shippingAddress.country"),
        ].filter(item => item !== null));
    }

    public getDataForLine(item: BulkParcelUploadExportLine): ExportDataItem {
        return new ExportDataItem([
            item.operationCode,
            item.batchId,
            item.orderId,
            item.deliveryOrderId,
            item.productId,
            item.ean13,
            item.supplierReference,
            item.productLabel,
            (item.quantityAvailable + item.quantityAssigned),
            item.quantityAssigned,
            context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) ? item.quantityStockout : null,
            item.parcelIdentifier.toString(),
            item.parcelIdOrEmpty,
            item.parcelTrackerOrEmpty,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? "" : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? item.palletExternalId : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? item.palletCode : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? item.palletCarrier : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? item.palletCountry : null,
            context.hasPermission(Permission.FeaturePalletWorkflow) ? item.palletCreationComment : null,
            item.carrier,
            item.shippingAddress.country,
        ].filter(value => value !== null) as ExportDataValue[]);
    }
}

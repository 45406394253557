import { IRemotePrinterContext } from "./remote-printer-context";
import { Guid } from "guid-typescript";
import { IPrinterDevice } from "../../../common/model/printer-device";

interface IPrinterContextReducerInitializePayload {
    action: "initialize";
}

interface IPrinterContextReducerSetPayload {
    action: "set";
    printerId: Guid;
    printerName: string;
    isZpl: boolean;
}

export type IPrinterContextReducerPayload = IPrinterContextReducerInitializePayload | IPrinterContextReducerSetPayload;

export const RemotePrinterContextReducer = (context: IRemotePrinterContext, message: IPrinterContextReducerPayload) => {
    switch (message.action) {
        case "initialize":
            const selectedPrinterRaw = sessionStorage.getItem("mobile_printer");
            if (!selectedPrinterRaw) {
                return context;
            }

            const selectedPrinter = JSON.parse(selectedPrinterRaw) as IPrinterDevice;
            if (!selectedPrinter.id || !selectedPrinter.name || !Guid.parse(selectedPrinter.name)) {
                return context;
            }

            return {
                printerId: Guid.parse(selectedPrinter.id),
                printerName: selectedPrinter.name,
                printerType: selectedPrinter.type === "zpl" ? "zpl" : "pdf",
            } as IRemotePrinterContext;
        case "set":
            sessionStorage.setItem("mobile_printer", JSON.stringify({
                id: message.printerId.toString(),
                name: message.printerName,
                type: message.isZpl ? "zpl" : "pdf",
            } as IPrinterDevice));

            return {
                printerId: message.printerId,
                printerName: message.printerName,
                printerType: message.isZpl ? "zpl" : "pdf",
            } as IRemotePrinterContext;
    }
};

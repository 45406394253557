import React, { ReactNode, SyntheticEvent } from "react";
import { Button, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { PalletsListItem } from "./pallets-list-item";
import { SearchListItemSelector } from "../../../../common/component/list/search-list/search-list-item-selector";
import { SearchListHeaderLabel } from "../../../../common/component/list/search-list/search-list-header-label";
import { DoubleArrowOutlined, MoreVert } from "@mui/icons-material";
import { getFieldLabel, stringValue } from "../../../../common/field/renderer";
import { t } from "i18next";

interface IPalletHeaderLineProps {
    pallet: PalletsListItem;
    isItemSelected: boolean;
    isExpanded: boolean;
    displaySingleActionPickerButton: boolean;
    additionalDataSize: number;
    customItemActionComponent?: ReactNode;

    onSelectionToggle: (value: boolean) => void;
    onSingleActionMenuClick: (target: EventTarget) => void;
    onExpandToggle: (isExpanded: boolean) => void;
}

export const PalletHeaderLine = (props: IPalletHeaderLineProps) => {
    const handleLineExpandCollapseToggle = (event: SyntheticEvent, isExpanded: boolean) => {
        event.stopPropagation();
        props.onExpandToggle(isExpanded);
    };

    return <TableRow key={props.pallet.palletId}
                     data-testid={`pallet-line-${props.pallet.id}`}
                     className={"search-item__row list-row--level1"}>
        <TableCell colSpan={10 + props.additionalDataSize}
                   className={"search-item__header"}>
            <div className={"search-item__header-contents"}>
                <SearchListItemSelector onToggle={value => props.onSelectionToggle(value)}
                                        isSelected={props.isItemSelected} />
                <IconButton onClick={event => handleLineExpandCollapseToggle(event, !props.isExpanded)}
                            data-testid={`expand-toggle-${props.isExpanded ? "expanded" : "collapsed"}`}>
                    <Tooltip title={t(`components.searchList.${props.isExpanded ? "collapse" : "expand"}`)}
                             placement={"top-start"}>
                        <DoubleArrowOutlined
                            className={`search-item__expand-toggle-icon${!props.isExpanded ? "--collapsed" : ""}`} />
                    </Tooltip>
                </IconButton>
                <div className={"search-item__header-details-id"}>
                    <SearchListHeaderLabel propertyKey={"id"}
                                           label={getFieldLabel(props.pallet, "code")}
                                           value={props.pallet.code} />
                </div>
                <div className={"search-item__header-details"}>
                    <SearchListHeaderLabel label={getFieldLabel(props.pallet, "carrier")}
                                           value={stringValue(props.pallet, "carrier")} />
                    <SearchListHeaderLabel label={getFieldLabel(props.pallet, "country")}
                                           value={stringValue(props.pallet, "country")} />
                    <SearchListHeaderLabel label={getFieldLabel(props.pallet, "parcelCount")}
                                           value={stringValue(props.pallet, "parcelCount")} />
                    <SearchListHeaderLabel label={getFieldLabel(props.pallet, "weight")}
                                           value={stringValue(props.pallet, "weight")} />
                    <SearchListHeaderLabel whenNotEmpty label={getFieldLabel(props.pallet, "pickingDate")}
                                           value={stringValue(props.pallet, "pickingDate")} />
                    <SearchListHeaderLabel label={getFieldLabel(props.pallet, "maxWeight")}
                                           value={palletWeightConstraints(props.pallet)} />
                </div>
                <div className={"search-item__header-action"}>
                    {props.customItemActionComponent}
                    {props.displaySingleActionPickerButton &&
                        <Button variant={"outlined"}
                                onClick={event => {
                                    event.stopPropagation();
                                    props.onSingleActionMenuClick(event.target);
                                }}
                                size={"small"}>
                            <MoreVert />
                        </Button>}
                </div>
            </div>
        </TableCell>
    </TableRow>;
};

const palletWeightConstraints = (parcel: PalletsListItem) => {
    if (parcel.maxParcelWeight === 0 && parcel.maxWeight === 0) {
        return t("palletParcelList.noWeightLimits");
    }

    if (parcel.maxParcelWeight === 0) {
        return stringValue(parcel, "maxWeight");
    }

    if (parcel.maxWeight === 0) {
        return t("palletParcelList.noTotalWeightWithPerParcelLimit", {
            limit: stringValue(parcel, "maxParcelWeight"),
        });
    }

    return t("palletParcelList.weightLimits", {
        limitTotal: stringValue(parcel, "maxWeight"),
        limitPerParcel: stringValue(parcel, "maxParcelWeight"),
    });
};


export class AdditionalDataModel {

    protected constructor(additionalDataMap: { [key: string]: string } | undefined) {
        this.additionalDataMap = additionalDataMap;
        if (this.additionalDataMap) {
            // tslint:disable-next-line:forin
            for (const field in this.additionalDataMap) {
                // tslint:disable-next-line:no-string-literal
                (this as any)[field] = this.additionalDataMap[field];
            }
        }
    }

    public additionalDataMap: { [key: string]: string } | undefined;
}

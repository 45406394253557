import { TableCell, TableRow, Tooltip } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import { stringValue } from "../../../../common/field/renderer";
import { context } from "../../../../context/context";
import { FeatureToggle } from "../../../../context/app-configuration";
import { DeliveryOrderDetailType } from "../../../model/delivery-order-detail-type";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";
import { blue, green, pink, purple } from "@mui/material/colors";
import React from "react";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { UnCodeIcon } from "../un-code-icon/un-code-icon";
import { t } from "i18next";

interface IDeliveryOrderContentsItemProps {
    deliveryOrderId: number;
    orderId: number;
    detail: DeliveryOrderDetail;
    onOrderIdentifierFilterEnabled: (orderId: number) => void;
    componentProductItemsLength: number;
    index: number;
    additionalDataFields: string[];
}

interface IDeliveryOrderContentsItemTypeProps {
    isComponentProduct: boolean;
}

interface IDeliveryOrderContentsItemTypeIconProps {
    detail: DeliveryOrderDetail;
    onOrderIdentifierFilterEnabled: () => void;
}

interface IComponentMarkerItemProps {
    index: number;
    length: number;
}

export const DeliveryOrderContentsItem = (props: IDeliveryOrderContentsItemProps) => {
    const isComponentProduct = props.detail.componentProductId !== "" && props.componentProductItemsLength > 1;
    const renderDetailType = !isComponentProduct || props.index === 0;

    return <TableRow key={"detail-" + props.index}
                     className={"search-item__detail-line"}
                     data-testid={`dor-${props.deliveryOrderId}-prd-${props.detail.productId}`}>
        {renderDetailType && <DeliveryOrderContentsItemType isComponentProduct {...props} />}
        {isComponentProduct && <ComponentMarkerItem index={props.index} length={props.componentProductItemsLength} />}
        <TableCell className={"search-item__detail-cell"} data-testid="productId">
            {stringValue(props.detail, "productId")}
        </TableCell>
        <TableCell className={"search-item__detail-cell"}>
            <ul>{props.detail.ean13List.map(ean => <li key={ean}>{ean}</li>)}</ul>
        </TableCell>
        <TableCell className={"search-item__detail-cell"}>{stringValue(props.detail, "supplierReference")}</TableCell>
        <TableCell className={"search-item__detail-cell"}>
            {props.detail.unCode && <UnCodeIcon value={props.detail.unCode}></UnCodeIcon>}
            {stringValue(props.detail, "labelReference")}
        </TableCell>
        <TableCell className={"search-item__detail-cell"} data-testid="quantity">
            {stringValue(props.detail, "quantity")}
        </TableCell>
        <TableCell className={"search-item__detail-cell"} data-testid="quantityParcelled">
            {stringValue(props.detail, "quantityParcelled")}
        </TableCell>
        <TableCell className={"search-item__detail-cell"} data-testid="quantityLabeled">
            {stringValue(props.detail, "quantityLabeled")}
        </TableCell>
        <TableCell className={"search-item__detail-cell"} data-testid="quantityShipped">
            {stringValue(props.detail, "quantityShipped")}
        </TableCell>
        {context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) &&
        <TableCell className={"search-item__detail-cell"} data-testid="quantityStockout">
            {stringValue(props.detail, "quantityStockout")}
        </TableCell>}
        <TableCell className={"search-item__detail-cell"}>{stringValue(props.detail, "weight")}</TableCell>
        {props.additionalDataFields.map(additionalDataItem => {
            const additionalValue = (props.detail as any)[additionalDataItem];
            return <TableCell  className={"search-item__detail-cell"} key={additionalDataItem}>
                {additionalValue || ""}
            </TableCell>;
        })}
    </TableRow>;
};

const DeliveryOrderContentsItemType = (props: IDeliveryOrderContentsItemProps & IDeliveryOrderContentsItemTypeProps) => {
    const isComponentContainer = props.detail.componentProductId && props.componentProductItemsLength > 1;
    const containerClassNames = [
        isComponentContainer ? "search-item__detail-cell--component" : "",
        isComponentContainer && props.componentProductItemsLength % 2 === 0 ? "search-item__detail-cell--component-even" : "",
    ];

    return <TableCell className={`search-item__detail-cell search-item__detail-cell--type ${containerClassNames.join(" ")}`}
                      colSpan={!isComponentContainer ? 2 : 1}
                      rowSpan={isComponentContainer ? props.componentProductItemsLength : 1}>
            <DeliveryOrderContentsItemTypeIcon detail={props.detail}
                                               onOrderIdentifierFilterEnabled={() => props.onOrderIdentifierFilterEnabled(props.orderId)} />
    </TableCell>;
};

const ComponentMarkerItem = (props: IComponentMarkerItemProps) => {
    const containerClassNames = [
        props.index === 0 ? "component-marker__container--first" : "",
        props.index === props.length - 1 ? "component-marker__container--last" : "",
    ];
    const isMiddleNonEvenItem = props.length % 2 && ((props.length - 1) / 2) === props.index;

    return <TableCell className={`search-item__detail-cell component-marker__container ${containerClassNames.join(" ")}`}>
        <div className={`component-marker__icon ${isMiddleNonEvenItem ? "component-marker__icon--middle" : ""}`}>
            <ArrowRightAlt />
        </div>
    </TableCell>;
};

const DeliveryOrderContentsItemTypeIcon = (props: IDeliveryOrderContentsItemTypeIconProps) => {
    switch (props.detail.detailType) {
        case DeliveryOrderDetailType.MultiReference:
            return <SquaredChip propertyKey={`type-${props.detail.productId}`}
                                color={pink}
                                label={t("model.deliveryOrder.detailContentTypeShort.MultiReference")} />;
        case DeliveryOrderDetailType.MonoReference:
            return <SquaredChip propertyKey={`type-${props.detail.productId}`}
                                color={blue}
                                label={t("model.deliveryOrder.detailContentTypeShort.MonoReference")} />;
        case DeliveryOrderDetailType.SingleReference:
            return <SquaredChip propertyKey={`type-${props.detail.productId}`}
                                color={purple}
                                label={t("model.deliveryOrder.detailContentTypeShort.SingleReference")} />;
        case DeliveryOrderDetailType.Component:
            return <Tooltip title={t("deliveryOrderList.componentProduct.description")}>
                <div>
                    <SquaredChip propertyKey={`type-${props.detail.productId}`}
                                 onClick={() => props.onOrderIdentifierFilterEnabled()}
                                 color={green}
                                 label={t("model.deliveryOrder.detailContentTypeShort.Component")} />
                </div>
            </Tooltip>;
        default:
            return <></>;
    }
};

import { IUserNotificationCenterContext } from "./user-notification-center-context-provider";
import moment from "moment";

export interface IUserNotificationCenterDispatcherGenericPayload {
    type: "open" | "close" | "markAsRead";
}

export interface IUserNotificationCenterDispatcherNewNotificationPayload {
    type: "newNotification";
    unreadNotificationsCount: number;
}

export type IUserNotificationCenterDispatcherPayload =
    IUserNotificationCenterDispatcherGenericPayload | IUserNotificationCenterDispatcherNewNotificationPayload;

export const UserNotificationCenterReducer = (context: IUserNotificationCenterContext,
                                              message: IUserNotificationCenterDispatcherPayload) => {
    switch (message.type) {
        case "open":
            return {
                ...context,
                isOpen: true,
            };
        case "close":
            const currentDate = moment();
            localStorage.setItem("notification_center_last_open", currentDate.unix().toString());

            return {
                ...context,
                isOpen: false,
                unreadNotificationsCount: 0,
                notificationCenterLastOpen: currentDate,
            };
        case "newNotification":
            return {
                ...context,
                unreadNotificationsCount: message.unreadNotificationsCount,
            };
        default:
            throw new Error("Unknown action");
    }
};

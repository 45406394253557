import * as React from "react";

import { IconButton, TableCell, TableRow } from "@mui/material";

import { DeliveryOrder } from "../../../model/delivery-order";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { DeliveryOrderFilter } from "../../../model/delivery-order-filter";
import { ListSort } from "../../../../common/component/list/search-list/list-sort";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { observer } from "mobx-react";

@observer
export class DeliveryOrderListSort extends ListSort<DeliveryOrder, DeliveryOrderDetail, DeliveryOrderFilter, {}> {

    public render() {
        return [<TableRow className="level1 header" key="do-sort-1" data-testid="sort-header">
            <TableCell>
                <IconButton>
                    <SwapVertIcon></SwapVertIcon>
                </IconButton>
            </TableCell>
            <TableCell className="cellHeader" colSpan={11 + this.props.searchListModel.additionalFields.length}>
                <div className="level1-field-container">
                    {this.renderHeaderSort("model.deliveryOrder.orderId", "orderId")}
                    {this.renderHeaderSort("model.deliveryOrder.deliveryOrderId", "deliveryOrderId")}
                    {this.renderHeaderSort("model.deliveryOrder.parcelCount", "parcelCount")}
                    {this.renderHeaderSort("model.deliveryOrder.carrier", "carrier")}
                    <div className="sortField">
                        {this.renderHeaderSort("model.deliveryOrder.zipCode", "zipCode")}&nbsp;&nbsp;&nbsp;
                        {this.renderHeaderSort("model.deliveryOrder.city", "city")}&nbsp;&nbsp;&nbsp;
                        {this.renderHeaderSort("model.deliveryOrder.country", "country")}&nbsp;&nbsp;&nbsp;
                    </div>
                </div>
            </TableCell>
        </TableRow >,
        <TableRow className="level2 header" key="do-sort-2">
            <TableCell className="cellHeader" colSpan={12 + this.props.searchListModel.additionalFields.length}>
                <div className="level2-field-container">
                    {this.renderHeaderSort("model.deliveryOrder.detailSortingKey", "detailSortingKey")}
                    {this.renderHeaderSort("model.deliveryOrder.contentsSortingKey", "contentsSortingKey")}
                </div>
            </TableCell>
        </TableRow >,
        ];
    }
}

import * as React from "react";

import { Route, Routes } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { ControlPanelRouter } from "./control-panel/router";
import { BackToTop } from "../../common/component/back-to-top/back-to-top";
import { Dialog } from "../../common/component/dialog/dialog";
import { Help } from "./help/help";
import { IHistoryProps } from "../../common/navigation/ihistory-props";
import { Loader } from "../../common/component/loader/loader";
import { backToTopModel } from "../../common/component/back-to-top/back-to-top-model";
import { PalletPickupFormDialog } from "./pallet-pickup/pallet-pickup-form-dialog";
import { context } from "../../context/context";
import { Permission } from "../../context/permission";
import { UserNotificationCenter } from "../../common/component/notifications/notification-center/user-notification-center";
import { Message } from "../../common/component/notifications/message";
import { PreparationLayout } from "../navigation/preparation-layout";

import "../../style/style.scss";
import "./style.scss";

@inject("routing")
@observer
export class ClickAndShipApp extends React.Component<IHistoryProps, {}> {

    private scrollFunc() {
        backToTopModel.handleScroll();
    }

    constructor(props: IHistoryProps) {
        super(props);

        this.scrollFunc = this.scrollFunc.bind(this);
    }

    public componentWillMount() {
        window.addEventListener("scroll", this.scrollFunc );
    }
    public componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollFunc);
    }

    public render() {
        return <div data-component="app-root">
            <div data-component="page">
                <div className="content">
                    <Routes>
                        <Route path="help" element={<Help routing={this.props.routing}/>}/>
                        <Route path="settings" element={<ControlPanelRouter routing={this.props.routing}/>}/>
                        <Route path="/*" element={<PreparationLayout routing={this.props.routing}/>}/>
                    </Routes>
                </div>
            </div>
            <Dialog/>
            {context.hasPermission(Permission.FeaturePalletPickUpForm) && <PalletPickupFormDialog/>}
            <Loader isBackground={false}/>
            <UserNotificationCenter/>
            <Message/>
            <BackToTop/>
        </div>;
    }
}

import React, { useState } from "react";
import { TextField } from "@mui/material";
import { SettingsFieldItemStatus } from "./settings-field-item-status";

interface ISettingsTextFieldProps {
    id: string;
    type?: "text" | "email";
    defaultValue: string;
    onValueSave: (value: string) => Promise<boolean>;
}
export const SettingsTextField = (props: ISettingsTextFieldProps) => {
    const [textValue, setTextValue] = useState(props.defaultValue);
    const [isSaveInProgress, setSaveInProgress] = useState(false);
    const [isChangeConfirmed, setChangeConfirmed] = useState<boolean | null>(null);

    const handleOnKeyDown = async (keyDown: string, action: () => Promise<any>) => {
        if (keyDown === "Enter") {
            return await action();
        }
    };

    const handleValueSave = async () => {
        setSaveInProgress(true);
        try {
            const status = await props.onValueSave(textValue);

            if (status) {
                setChangeConfirmed(true);
                setTimeout(() => {
                    setChangeConfirmed(null);
                }, 1000);
            }
        } finally {
            setSaveInProgress(false);
        }
    };

    return <>
        <TextField id={props.id}
                   type={props.type ?? "text"}
                   value={textValue}
                   variant={"outlined"}
                   disabled={isSaveInProgress || (isChangeConfirmed ?? false)}
                   onKeyDown={async (event: React.KeyboardEvent<any>) =>
                       await handleOnKeyDown(event.key, handleValueSave)}
                   onChange={event => setTextValue(event.target.value ?? "")}/>
        <SettingsFieldItemStatus isSaveOptionEnabled={textValue !== props.defaultValue}
                                 isSaveInProgress={isSaveInProgress}
                                 isSuccess={isChangeConfirmed}
                                 onSaveClick={async () => await handleValueSave()} />
    </>;
};

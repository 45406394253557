import { Guid } from "guid-typescript";
import moment from "moment";

export interface IBusinessDocumentation {
    id: string;
    title: string;
    category: string;
    fileType: string;
    link: string;
    thumbnail: string;
    insertDate: string;
}

export enum BusinessDocumentationCategory {
    ParcelPreparation = "parcelPreparation",
    PalletPreparation = "palletPreparation",
    Other = "other",
}

export enum BusinessDocumentationFileType {
    Pdf = "pdf",
    Video = "video",
}

export class BusinessDocumentation {
    private readonly _id: Guid;
    private readonly _title: string;
    private readonly _category: BusinessDocumentationCategory;
    private readonly _fileType: BusinessDocumentationFileType;
    private readonly _link: string;
    private readonly _thumbnail: string;
    private readonly _createdAt: moment.Moment;

    public constructor(data: IBusinessDocumentation) {
        this._id = Guid.parse(data.id);
        this._title = data.title;
        this._category = this.buildCategory(data.category);
        this._fileType = this.buildFileType(data.fileType);
        this._link = data.link;
        this._thumbnail = data.thumbnail;
        this._createdAt = moment(data.insertDate);
    }

    get id(): Guid {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    get category(): BusinessDocumentationCategory {
        return this._category;
    }

    get fileType(): BusinessDocumentationFileType {
        return this._fileType;
    }

    get link(): string {
        return this._link;
    }

    get thumbnail(): string {
        return this._thumbnail;
    }

    get createdAtText() {
        return this.createdAt.format("YYYY-MM-DD");
    }

    get createdAt(): moment.Moment {
        return this._createdAt;
    }

    private buildCategory(value: string) {
        switch (value) {
            case "ParcelPreparation":
                return BusinessDocumentationCategory.ParcelPreparation;
            case "PalletPreparation":
                return BusinessDocumentationCategory.PalletPreparation;
            default:
                return BusinessDocumentationCategory.Other;
        }
    }

    private buildFileType(value: string) {
        switch (value) {
            case "Pdf":
                return BusinessDocumentationFileType.Pdf;
            case "Video":
                return BusinessDocumentationFileType.Video;
            default:
                throw new Error(`Unsupported documentation file type: ${value}`);
        }
    }
}

import * as React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { palletPickupFormDialogModel } from "./pallet-pickup-form-dialog-model";
import { observer } from "mobx-react";
import { t } from "i18next";
import { PalletPickupFormCarrierCountryDeclarationInput } from "./pallet-pickup-form-carrier-country-declaration-input";
import { dialogModel } from "../../../common/component/dialog/dialog-model";
import { PalletPickupFormCarrierCountryDeclarationInputGroup } from "./pallet-pickup-form-carrier-country-declaration-input-group";
import { PalletPickupFormComments } from "./pallet-pickup-form-comments";
import { VPUITheme } from "../../../common/theme/vpui-theme";
import { Close } from "@mui/icons-material";
import { DialogActionsWithOption } from "../../../common/component/dialog-actions-with-option/dialog-actions-with-option";
import { DialogActionsOption } from "../../../common/component/dialog-actions-with-option/dialog-actions-option";
import { DialogActionsButtons } from "../../../common/component/dialog-actions-with-option/dialog-actions-buttons";

import "./style.scss";
import { context } from "../../../context/context";
import { Permission } from "../../../context/permission";

@observer
export class PalletPickupFormDialog extends React.Component<{}> {

    public async componentDidMount() {
        await palletPickupFormDialogModel.openDialogIfFormModified();
    }

    public render() {
        return <Dialog
            open={palletPickupFormDialogModel.isFormOpen}
            className={"pallet-pickup-form"}
            data-testid={"pallet-pickup-form"}
        >
            <ThemeProvider theme={VPUITheme}>
                <div className={"pallet-pickup-form__container"}>
                    <IconButton onClick={() => palletPickupFormDialogModel.closeForm()}
                                className="pallet-pickup-form__close-button"
                                data-testid={"pallet-pickup-form-close"}>
                        <Close />
                    </IconButton>
                    <DialogTitle>
                        {t("palletPickup.declarationForm.title")}
                    </DialogTitle>
                    <DialogContent>
                        <Typography className={"pallet-pickup-form__description"}>
                            {t("palletPickup.declarationForm.description")}
                        </Typography>
                        {palletPickupFormDialogModel.allowSubmission && <Typography className={"pallet-pickup-form__description"}>
                            {this.deadlineMessage}
                        </Typography>}
                        {palletPickupFormDialogModel.declarationCountryList.map(country =>
                            <PalletPickupFormCarrierCountryDeclarationInputGroup
                                key={country}
                                name={t(`palletPickup.declarationForm.country.${country}`)}>
                                {palletPickupFormDialogModel.declarationElementForCountry(country).map(item =>
                                        <PalletPickupFormCarrierCountryDeclarationInput
                                            model={item}
                                            isReadOnly={!palletPickupFormDialogModel.allowSubmission}
                                            key={`${item.country}-${item.carrier}`}
                                        />)}
                            </PalletPickupFormCarrierCountryDeclarationInputGroup>)}
                        <PalletPickupFormComments closingCommentValue={palletPickupFormDialogModel.closingComment}
                                                  onClosingCommentUpdate={value => palletPickupFormDialogModel.setClosingComment(value)}
                                                  withCreationComment={context.hasPermission(Permission.FeaturePalletWorkflow)}
                                                  creationCommentValue={palletPickupFormDialogModel.creationComment}
                                                  onCreationCommentUpdate={value => palletPickupFormDialogModel.setCreationComment(value)}
                                                  isReadOnly={!palletPickupFormDialogModel.allowSubmission}/>
                    </DialogContent>
                    <DialogActionsWithOption>
                        <DialogActionsOption>
                            <div className={"pallet-pickup-form-footer__error-note"}>
                                {!palletPickupFormDialogModel.isFormValidated && <Typography className={"pallet-pickup-form-footer__error-note-text"}>
                                    {t("palletPickup.declarationForm.errorsDetected")}
                                </Typography>}
                                {!palletPickupFormDialogModel.allowSubmission && <Typography className={"pallet-pickup-form-footer__error-note-text"}>
                                    {t("palletPickup.declarationForm.formAlreadySubmitted")}
                                </Typography>}
                            </div>
                        </DialogActionsOption>
                        <DialogActionsButtons>
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                type={"submit"}
                                className={"pallet-pickup-form-footer__button"}
                                data-testid={"pallet-pickup-form-submit-button"}
                                disabled={!palletPickupFormDialogModel.isFormValidated || !palletPickupFormDialogModel.allowSubmission}
                                onClick={() => this.executeSubmit()}
                            >
                                {t("palletPickup.declarationForm.submit.action")}
                            </Button>
                        </DialogActionsButtons>
                    </DialogActionsWithOption>
                </div>
            </ThemeProvider>
        </Dialog>;
    }

    private executeSubmit() {
        dialogModel.confirmBox(
            t("palletPickup.declarationForm.submit.title"),
            palletPickupFormDialogModel.isCompletelyFulfilled ? t("palletPickup.declarationForm.submit.description") : t("palletPickup.declarationForm.submit.descriptionWithIncompleteFormWarning"),
            () => palletPickupFormDialogModel.submitFormAndClose(),
        );
    }

    private get deadlineMessage(): string {
        return palletPickupFormDialogModel.isDeadlineToday ? this.deadlineMessageForToday : this.deadlineMessageGeneric;
    }

    private get deadlineMessageForToday() {
        return t(`palletPickup.declarationForm.deadlineToday`, {
            deadlineTime: palletPickupFormDialogModel.deadlineTimeFormatted,
        });
    }

    private get deadlineMessageGeneric() {
        return t(`palletPickup.declarationForm.deadline`, {
            deadlineDate: palletPickupFormDialogModel.deadlineDateFormatted,
            deadlineTime: palletPickupFormDialogModel.deadlineTimeFormatted,
        });
    }
}

export class BulkParcelUploadImportLine {
    private readonly _operationCode: string;
    private readonly _batchId: number;
    private readonly _deliveryOrderId: number;
    private readonly _productId: number;
    private readonly _supplierReference: string;
    private readonly _quantityAssigned: number;
    private readonly _quantityStockout: number;
    private readonly _parcelIndex?: string;
    private readonly _parcelId?: number;
    private readonly _parcelTracker?: string;
    private readonly _carrierName?: string;
    private readonly _palletIndex?: string;
    private readonly _palletExternalId?: string;
    private readonly _palletCode?: string;
    private readonly _palletCarrier?: string;
    private readonly _palletCountry?: string;
    private readonly _palletCreationComment?: string;

    constructor(
        operationCode: string,
        batchId: number,
        deliveryOrderId: number,
        productId: number,
        quantityAssigned: number,
        quantityStockout: number,
        parcelIndex?: string,
        parcelId?: number,
        parcelTracker?: string,
        carrierName?: string,
        palletIndex?: string,
        palletExternalId?: string,
        palletCode?: string,
        palletCarrier?: string,
        palletCountry?: string,
        palletCreationComment?: string,
    ) {
        this._operationCode = operationCode;
        this._batchId = batchId;
        this._deliveryOrderId = deliveryOrderId;
        this._productId = productId;
        this._quantityAssigned = quantityAssigned;
        this._quantityStockout = quantityStockout;
        this._parcelIndex = parcelIndex;
        this._parcelId = parcelId;
        this._parcelTracker = parcelTracker;
        this._carrierName = carrierName;
        this._palletIndex = palletIndex;
        this._palletExternalId = palletExternalId;
        this._palletCode = palletCode;
        this._palletCarrier = palletCarrier;
        this._palletCountry = palletCountry;
        this._palletCreationComment = palletCreationComment;
    }

    get operationCode(): string {
        return this._operationCode;
    }

    get batchId(): number {
        return this._batchId;
    }

    get deliveryOrderId(): number {
        return this._deliveryOrderId;
    }

    get productId(): number {
        return this._productId;
    }

    get supplierReference(): string {
        return this._supplierReference;
    }

    get quantityAssigned(): number {
        return this._quantityAssigned;
    }

    get quantityStockout(): number {
        return this._quantityStockout;
    }

    get parcelIndex(): string | undefined {
        return this._parcelIndex;
    }

    get parcelIndexOrEmpty(): string {
        return this._parcelIndex ?? "";
    }

    get parcelId(): number | undefined {
        return this._parcelId;
    }

    get parcelTracker(): string | undefined {
        return this._parcelTracker;
    }

    get carrierName(): string | undefined {
        return this._carrierName;
    }

    get palletIndex(): string | undefined {
        return this._palletIndex;
    }

    get palletIndexOrEmpty(): string {
        return this._palletIndex ?? "";
    }

    get palletExternalId(): string | undefined {
        return this._palletExternalId;
    }

    get palletCode(): string | undefined {
        return this._palletCode;
    }

    get palletCodeOrEmpty(): string {
        return this._palletCode ?? "";
    }

    get palletCarrier(): string | undefined {
        return this._palletCarrier;
    }

    get palletCountry(): string | undefined {
        return this._palletCountry;
    }

    get palletCreationComment(): string | undefined {
        return this._palletCreationComment;
    }
}

import * as React from "react";
import { AppBar, ThemeProvider, Toolbar } from "@mui/material";
import { IHistoryProps } from "../../navigation/ihistory-props";
import { observer } from "mobx-react";
import { VPUITheme } from "../../theme/vpui-theme";
import { ReactNode, ReactNodeArray } from "react";
import { ImpersonationAppBar } from "../impersonation-app-bar/impersonation-app-bar";
import { context } from "../../../context/context";

import "./style.scss";

interface IHeaderProps {
    appName?: string;
    children?: ReactNode | ReactNodeArray;
}

@observer
export class Header extends React.Component<IHistoryProps & IHeaderProps, {}> {

    public render() {
        return <ThemeProvider theme={VPUITheme}>
            <AppBar position={"static"} elevation={0} className={`main-header ${context.isImpersonatedUser ? "main-header--impersonated" : ""}`}>
                {context.isImpersonatedUser && <ImpersonationAppBar userName={context.currentUser.name} onImpersonationEnd={() => context.clearImpersonation()} />}
                <Toolbar className={`main-header__wrapper ${context.isImpersonatedUser ? "main-header__wrapper--impersonated" : ""}`}>
                    <div className={"main-header__contents"}>
                        {this.props.children}
                    </div>
                </Toolbar>
            </AppBar>
        </ThemeProvider>;
    }
}

import * as React from "react";

import {
    BulkyActionPicker,
    IActionCommand,
} from "../../../../common/component/action/action-select/bulky-action-picker";
import { observer } from "mobx-react";
import { Permission } from "../../../../context/permission";
import { context } from "../../../../context/context";
import { operationContext } from "../../../../context/operation-context";
import { DeliveryOrder } from "../../../model/delivery-order";
import { StockoutManagementActionType } from "../../../constants/constants";

interface IStockoutManagementHeaderProps {
    actionsList: Array<IActionCommand<DeliveryOrder, StockoutManagementActionType>>;
}

@observer
export class StockoutManagementHeader extends React.Component<IStockoutManagementHeaderProps, {}> {
    public render() {
        return <div className="list-actions-container">
            {context.hasPermission(Permission.BackOffice) && <div className="list-actions">
                <BulkyActionPicker propertyKey={operationContext.operationCodeOrEmpty}
                                   scanFieldType="number"
                                   inputLabel=""
                                   contentPrefix="stockoutManagement.action"
                                   actions={this.props.actionsList}
                                   permission={Permission.FrontWrite} />
            </div>}
        </div>;
    }
}

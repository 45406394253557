import { httpGetList, httpPost } from "../../common/network/fetch";
import {
    INotificationWithDeliveryDetailsProps,
    NotificationLevel,
    NotificationWithDeliveryDetails,
} from "../../common/model/notification-with-delivery-details";
import { UrlBuilder } from "./url-builder";
import { IUserNotification, UserNotification } from "../../common/model/user-notification";

class NotificationService {

    public async create(title: string, level: NotificationLevel, message: string) {
        return await httpPost({
            url: "/notifications",
            data: {
                title,
                level: level.toString(),
                message,
            },
        });
    }

    public async createForSupplier(title: string, supplierUserName: string, level: NotificationLevel, message: string) {
        return await httpPost({
            url: "/notifications/users",
            data: {
                title,
                level: level.toString(),
                message,
                recipients: [supplierUserName],
            },
        });
    }

    public async list() {
        return (await httpGetList<INotificationWithDeliveryDetailsProps>({
            url: new UrlBuilder("/notifications")
                .build(),
        })).map(p => new NotificationWithDeliveryDetails(p));
    }

    public async fetchForUser() {
        return (await httpGetList<IUserNotification>({
            url: new UrlBuilder("/notifications/user")
                .build(),
        })).map(p => new UserNotification(p));
    }
}

export const notificationService = new NotificationService();

import React, { ReactNodeArray } from "react";
import { DialogActions } from "@mui/material";

import "./style.scss";

interface IDialogActionsWithOptionProps {
    disableSpacing?: boolean;
    children: ReactNodeArray;
}

export const DialogActionsWithOption = (props: IDialogActionsWithOptionProps) => {
    return <div className={"dialog-actions"}>
        <DialogActions disableSpacing={props.disableSpacing} className={"dialog-actions__container"}>
            {props.children}
        </DialogActions>
    </div>;
};

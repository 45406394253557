import { merge } from "lodash";
import { IPalletRouteProps, PalletPageType } from "./preparation-route-props";
import { context } from "../../context/context";

function parsePalletUrl(url: string): IPalletRouteProps {
    const fragments = url.split("/");

    const page = fragments.length >= 1 ? fragments[2] : undefined;
    return {
        warehouse: fragments.length >= 2 && isPalletPage(page) ? fragments[3] : undefined,
        page: page as PalletPageType,
    };
}

function createPalletUrl(params: IPalletRouteProps) {
    let url = "/pallet";
    [params.page, params.warehouse].forEach(fragment => {
        if (fragment) {
            url += `/${fragment}`;
        }
    });
    return url;
}

function isPalletPage(page: string | undefined) {
    if (!page) {
        return false;
    }

    return page ===  "pallet-management" || page === "pallet-shipping" || page === "shipped-pallets";
}

export function updatePalletUrl(url: string, params: IPalletRouteProps) {
    const fragments = parsePalletUrl(url);
    const updatedFragments = merge({
        warehouse: context.isSuperAdministrator ? context.warehouseContext?.code : undefined,
    }, fragments, params);
    return createPalletUrl(updatedFragments);
}

export interface IRetryCommandSchedule {
    maxWaitTimeMs?: number;
    initialDelayMs?: number;
    maxDelayMs?: number;
    increaseDelayPeriod?: number;
}

export async function retryCommand<T>(command: () => Promise<T>, retryUntil: (returnedValue: T) => boolean, retrySchedule?: IRetryCommandSchedule) {
    const delayGenerator = getRetryDelayMilliseconds(retrySchedule);
    let delay = delayGenerator.next();
    let lastResult = await command();

    while (retryUntil(lastResult) && !delay.done) {
        delay = delayGenerator.next();
        await sleep(delay.value);
        lastResult = await command();
    }

    return lastResult;
}

function* getRetryDelayMilliseconds(retrySchedule?: IRetryCommandSchedule): Generator<number, number> {
    let delay = retrySchedule?.initialDelayMs || 200;
    const maxDownloadWaitTimeMilliseconds = retrySchedule?.maxWaitTimeMs || 60000;
    const maxDelayMs = retrySchedule?.maxDelayMs || 25600;
    const increaseDelayPeriod = retrySchedule?.increaseDelayPeriod || 3;
    let retryCount = 0;
    let totalDownloadWaitTimeMs = 0;

    while (totalDownloadWaitTimeMs <= maxDownloadWaitTimeMilliseconds) {
        yield delay;

        totalDownloadWaitTimeMs = totalDownloadWaitTimeMs + delay;
        if (++retryCount % increaseDelayPeriod === 0) {
            delay = Math.min(delay * 2, maxDelayMs);
        }
    }

    return delay;
}

async function sleep(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

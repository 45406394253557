import { palletService } from "../../../services/pallet";
import { CreatePalletCommand } from "./create-pallet-command";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { t } from "i18next";
import { qzManager } from "../../../qz/qz-manager";
import { context } from "../../../../context/context";
import { getLabelFormat } from "../../../../constants/constants";

export class CreatePalletCommandHandler {
    public static async handle(command: CreatePalletCommand) {
        const createdPalletsList = (await Promise.all(command.carrierCountryGroup.map(async carrier =>
            await palletService.createPallet(command.warehouse, carrier.carrierCountryGroup.countryGroupName,
                carrier.carrierCountryGroup.carrier, carrier.quantity, command.maxParcelWeightValueKgs * 1000,
                command.maxTotalWeightValueKgs * 1000, command.creationComment)))).flatMap(p => p);

        notificationModel.addSuccessMessage(
            createdPalletsList.length > 1 ?
                t("components.pallet.createPalletDialog.successMultiple", {
                    count: createdPalletsList.length,
                }) :
                t("components.pallet.createPalletDialog.successSingle"));

        if (command.printPalletLabel) {
            await Promise.all(createdPalletsList.map(async pallet => {
                const data = await palletService.label(pallet.id.toString(), getLabelFormat(context.settings.printerType));
                await qzManager.qzPrintOrDownloadFile(data.Value!, context.settings.printerType);
            }));
        }
    }
}

export enum FeatureToggle {
    STOCKOUTS = "stockouts",
}

interface ISSOConfiguration {
    authServerUrl: string;
    realm: string;
    resource: string;
}

interface IFeatureTogglesConfiguration {
    [key: string]: boolean;
}

interface IAppEnvironment {
    name: string;
}

export interface IAppConfiguration {
    featureToggles: IFeatureTogglesConfiguration;
    sso?: ISSOConfiguration;
    environment?: IAppEnvironment;
}

export class AppConfiguration {
    private readonly _featureToggles: IFeatureTogglesConfiguration;
    private readonly _sso?: ISSOConfiguration;
    private readonly _environmentName: string | undefined;

    constructor(configuration: IAppConfiguration) {
        this._featureToggles = configuration.featureToggles;
        this._sso = configuration.sso;
        this._environmentName = configuration.environment?.name ?? "";
    }

    get sso(): ISSOConfiguration | undefined {
        return this._sso;
    }

    get environmentName(): string | undefined {
        return this._environmentName;
    }

    public isFeatureToggleEnabled(featureToggle: FeatureToggle): boolean {
        return this._featureToggles[featureToggle] || false;
    }
}

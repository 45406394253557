import { Parcel } from "../../../model/parcel";
import { action, computed, observable } from "mobx";
import { union } from "lodash";
import { IParcelDocumentProvider } from "./document-provider/parcel-document-provider-interface";
import { t } from "i18next";

class PrinterProgressDrawerModel {

    // General informations
    @observable private _isPrintCanceled: boolean = false;
    @observable private _counter: number = 0;
    @observable private _isPrintingLabels: boolean = false;
    @observable private _isPrintingPreparationOrder: boolean = false;

    // Parcel printing informations
    @observable private _nbParcelPrinted: number = 0;
    @observable private _nbParcelToPrint: number = 0;
    @observable private _errorParcelList: string[] = [];

    // Preparation order printing informations
    @observable private _nbPreparationOrderPrinted: number = 0;
    @observable private _nbPreparationOrderToPrint: number = 0;
    @observable private _errorPreparationOrderList: string[] = [];

    @computed public get nbParcelPrinted() {
        return this._nbParcelPrinted;
    }
    @computed public get nbPreparationOrderPrinted() {
        return this._nbPreparationOrderPrinted;
    }
    @computed public get isPrintCanceled() {
        return this._isPrintCanceled;
    }
    @computed public get errorList() {
        return union(this._errorParcelList, this._errorPreparationOrderList) as ReadonlyArray<string>;
    }
    @computed public get totalItemToPrint() {
        return this._nbParcelToPrint + this._nbPreparationOrderToPrint;
    }
    @computed public get totalItemPrinted() {
        return this._nbParcelPrinted + this._nbPreparationOrderPrinted;
    }

    @computed public get counter() {
        return this._counter;
    }
    @computed public get nbParcelToPrint() {
        return this._nbParcelToPrint;
    }
    @computed public get nbErrorParcel() {
        return this._errorParcelList.length;
    }
    @computed public get nbPreparationOrderToPrint() {
        return this._nbPreparationOrderToPrint;
    }
    @computed public get nbErrorPreparationOrder() {
        return this._errorPreparationOrderList.length;
    }

    @action
    public onStartPrinting(parcelList: Parcel[], parcelDocumentProviderList: IParcelDocumentProvider[]) {
        this._counter = 0;
        this._isPrintCanceled = false;
        this._errorParcelList = [];
        this._errorPreparationOrderList = [];

        this._nbParcelPrinted = 0;
        this._nbPreparationOrderPrinted = 0;

        this._isPrintingLabels = parcelDocumentProviderList.some(p => p.hasLabel);
        this._isPrintingPreparationOrder = parcelDocumentProviderList.some(p => p.hasPreparationOrder);

        this._nbParcelToPrint = this._isPrintingLabels ? parcelList.length : 0;
        this._nbPreparationOrderToPrint = this._isPrintingPreparationOrder ? parcelList.length : 0;
    }

    @action
    public cancelPrint() {
        this._isPrintCanceled = true;
    }

    @action
    public cancelAndClosePrint() {
        this._counter = 0;
        this._isPrintCanceled = true;
        this._nbParcelToPrint = 0;
        this._nbPreparationOrderToPrint = 0;
        this._errorParcelList = [];
    }

    @action
    public addParcelError(error: string) {
        this._errorParcelList.push(error);
    }

    @action
    public addPreparationError(error: string) {
        this._errorPreparationOrderList.push(error);
    }

    @action
    public completeParcelDocumentPrintout() {
        this._nbParcelPrinted += this._isPrintingLabels ? 1 : 0;
        this._nbPreparationOrderPrinted += this._isPrintingPreparationOrder ? 1 : 0;
    }

    @action
    public addParcelPrintoutErrors(parcel: Parcel) {
        if (this._isPrintingLabels) {
            this._errorParcelList.push(`${t("error.print.label", {parcelId: parcel.id})}`);
        }

        if (this._isPrintingPreparationOrder) {
            this._errorPreparationOrderList.push(t("error.print.preparationOrder", {parcelId: parcel.id}));
        }
    }

    @action
    public onEndPrinting() {
        if (this.totalItemToPrint === this.totalItemPrinted) {
            this._nbParcelToPrint = 0;
            this._nbPreparationOrderToPrint = 0;
        }
    }
}

export const printerProgressDrawerModel = new PrinterProgressDrawerModel();

import {domain, filterSettings, label} from "../../common/dto/dto-annotation";

import { label50 } from "../../domain/label";
import { observable } from "mobx";
import { CommonFilter } from "./common-filter";
import {
    filterActionAllValuesWithCsv,
    filterActionStringValues,
} from "../../common/component/list/filter/filter-action-data";
import { DeliveryOrderCancellationRequestStatus } from "./delivery-order-cancellation-request-status";

export class ParcelFilter extends CommonFilter {

    @label("model.parcelFilter.id")
    @filterSettings({ actions: filterActionAllValuesWithCsv })
    @observable
    public parcelId: string;

    /** Carrier Key (Transporteur). */
    @label("model.parcelFilter.parcelTracker")
    @domain(label50)
    @observable
    public parcelTracker: string;

    /** Is order cancelled or pending cancellation  */
    @label("model.parcelFilter.orderCancellationRequestStatus")
    @filterSettings({
        actions: ["all", "notEmpty", "equals", "empty"],
    })
    @observable
    public orderNonRejectedCancellationRequestStatus: DeliveryOrderCancellationRequestStatus;

    /** Is order pending cancellation  */
    @label("model.parcelFilter.orderPendingCancellation")
    @filterSettings({
        actions: ["all", "isFalse", "isTrue"],
    })
    @observable
    public isOrderPendingCancellation: boolean;

    /** Pallet code (if parcel on a pallet)  */
    @label("model.parcelFilter.palletCode")
    @filterSettings({
        actions: filterActionStringValues,
    })
    @observable
    public palletCode: string;
}

import { CarrierCountryGroup, ICarrierCountryGroup } from "../carrier-country-group";
import { IPallet, Pallet } from "../model/pallet";
import { PalletStatusType } from "../constants/constants";
import { httpFileRequest, httpGet, httpGetList, httpPatch, httpPost } from "../../common/network/fetch";
import { UrlBuilder } from "./url-builder";
import moment from "moment";
import { documentService } from "./document";
import { IPalletShippingDependencies, PalletShippingDependencies } from "../model/pallet-shipping-dependencies";
import { LabelFormatType } from "../../constants/constants";
import { Guid } from "guid-typescript";
import { IWarehousePalletCounters, PalletCounter } from "../model/pallet-counter";

class PalletService {
    public async loadPallets(warehouse: string, status: PalletStatusType): Promise<Pallet[]> {
        return (await httpGetList<IPallet>({
            url: new UrlBuilder("/pallet/list")
                .setWarehouse(warehouse)
                .setPalletStatus(status)
                .build(),
        })).map(p => new Pallet(p));
    }

    public async loadPalletsWithExternalId(warehouse: string, status: PalletStatusType): Promise<Pallet[]> {
        return (await httpGetList<IPallet>({
            url: new UrlBuilder("/pallet/external/list")
                .setWarehouse(warehouse)
                .setPalletStatus(status)
                .build(),
        })).map(p => new Pallet(p));
    }

    public async loadPalletById(id: Guid): Promise<Pallet | undefined> {
        const palletsList = (await httpGetList<IPallet>({
            url: `/pallet/list?id=${id}`,
        })).map(p => new Pallet(p));

        if (palletsList.length > 0) {
            return palletsList[0];
        }

        return undefined;
    }

    public async loadCarrierCountryList(warehouse: string): Promise<CarrierCountryGroup[]> {
        return (await httpGetList<ICarrierCountryGroup>({
            url: new UrlBuilder()
                .setCarrierGroupList()
                .setWarehouse(warehouse)
                .build(),
        })).map(c => new CarrierCountryGroup(c));
    }

    public async findPallet(code: string) {
        return new Pallet(await httpGet<IPallet>({
            url: new UrlBuilder()
                .setPalletCode(code)
                .build(),
        }));
    }

    public async createPallet(warehouseId: string, country: string, carrier: string, quantity: number,
                              maxParcelWeight: number, maxWeight: number, creationComment: string) {
        return (await httpPost<IPallet[]>({ url: "/pallet/create", data: {
                warehouseId, country, carrier, quantity, maxParcelWeight, maxWeight, creationComment,
                keepCustomerOrderIndivisible: false,
            }})).map(p => new Pallet(p));
    }

    public async createPalletWithExternalId(warehouseId: string, externalIds: string[], country: string, carrier: string,
                                            quantity: number, maxParcelWeight: number, maxWeight: number,
                                            creationComment: string) {
        return (await httpPost<IPallet[]>({ url: "/pallet/external/create", data: {
                warehouseId, externalIds, country, carrier, quantity, maxParcelWeight, maxWeight, creationComment,
                keepCustomerOrderIndivisible: false,
            }})).map(p => new Pallet(p));
    }

    public async removeParcelFromPallet(palletId: string, parcelId: number) {
        return await httpPatch({
            url: new UrlBuilder()
                .setPalletId(palletId)
                .setParcelRemove()
                .build(),
            data: [parcelId],
        });
    }

    public async removeParcelFromPalletByLocator(palletId: string, parcelLocator: string) {
        return await httpPatch({
            url: new UrlBuilder()
                .setPalletId(palletId)
                .setParcelRemoveByLocator(parcelLocator)
                .build(),
        });
    }

    public async closePallet(palletId: string, comment: string) {
        return await httpPatch({
            url: new UrlBuilder()
                .setPalletId(palletId)
                .setClose()
                .build(),
            data: {
                comment,
            },
        });
    }

    public async cancelPallet(palletId: string) {
        return await httpPatch({
            url: new UrlBuilder()
                .setPalletId(palletId)
                .setCancel()
                .build(),
        });
    }

    public async reopenPallet(palletId: string) {
        return await httpPatch({
            url: new UrlBuilder()
                .setPalletId(palletId)
                .setReopen()
                .build(),
        });
    }

    public async validateShippingDependencies(palletIds: string[]) {
        return new PalletShippingDependencies(await httpPost<IPalletShippingDependencies>({
            url: new UrlBuilder("/pallet")
                .setShippingDependencies()
                .build(),
            data: palletIds,
        }));
    }

    public async shipPallets(palletsIds: string[], truckIdentifier: string, pickingDate: moment.Moment,
                             containerSealNumber: string) {
        return await httpPatch({
            url: new UrlBuilder()
                .setMultiplePalletsShipping()
                .build(),
            data: {
                palletsIds, truckIdentifier, pickingDate: pickingDate.utc(true).format(), containerSealNumber,
            },
        });
    }

    public async label(palletId: string, labelFormat: LabelFormatType) {
        return await httpFileRequest("GET", new UrlBuilder()
            .setPalletId(palletId)
            .setLabelType(labelFormat)
            .build());
    }

    public async deliverySlip(palletsIds: string[]) {
        const createdDocumentId = await documentService.createPalletDeliverySlipDocument(palletsIds);
        return await documentService.getDocument(createdDocumentId, 10000 + palletsIds.length * 3000);
    }

    public async pushParcelOnPallet(palletId: string, parcelId: string) {
        return await httpPatch({
            url: new UrlBuilder()
                .setPalletId(palletId)
                .setParcelLocator(parcelId)
                .setAdd()
                .build(),
            data: [parcelId],
        });
    }

    public async assignParcelToPallet(palletCode: string, parcelId: number) {
        return await httpPatch({
            url: new UrlBuilder()
                .setPalletCode(palletCode)
                .setParcelId(parcelId)
                .setAdd()
                .build(),
        });
    }

    public async reassignParcelToPallet(palletCode: string, parcelId: number) {
        return await httpPatch({
            url: new UrlBuilder()
                .setPalletCode(palletCode)
                .setParcelId(parcelId)
                .setReassign()
                .build(),
        });
    }

    public async loadPalletCounters(warehouseId: string): Promise<PalletCounter[]> {
        const warehousePalletCounters = await httpGet<IWarehousePalletCounters>({
            url: new UrlBuilder()
                .setPallet()
                .setList()
                .setCounters()
                .setWarehouse(warehouseId)
                .build(),
        });

        return warehousePalletCounters.counters.map(c => new PalletCounter(c));
    }
}

export const palletService = new PalletService();

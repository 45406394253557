import { Guid } from "guid-typescript";
import moment from "moment";

export interface INotificationWithDeliveryDetailsProps {
    id: string;
    title: string;
    message: string;
    level: string;
    createdAt: string;
    totalDeliveries: number;
    failedDeliveries: number;
    successDeliveries: number;
}

export enum NotificationLevel {
    Information = "Information",
    Warning = "Warning",
    Critical = "Critical",
}

export class NotificationWithDeliveryDetails {
    private readonly _id: Guid;
    private readonly _title: string;
    private readonly _message: string;
    private readonly _level: NotificationLevel;
    private readonly _createdAt: moment.Moment;
    private readonly _totalDeliveries: number;
    private readonly _failedDeliveries: number;
    private readonly _successDeliveries: number;

    constructor(data: INotificationWithDeliveryDetailsProps) {
        this._id = Guid.parse(data.id);
        this._title = data.title;
        this._createdAt = moment(data.createdAt);
        this._message = data.message;
        this._level = this.buildNotificationLevel(data.level);
        this._totalDeliveries = data.totalDeliveries;
        this._failedDeliveries = data.failedDeliveries;
        this._successDeliveries = data.successDeliveries;
    }

    private buildNotificationLevel(value: string) {
        switch (value) {
            case "Warning":
                return NotificationLevel.Warning;
            case "Critical":
                return NotificationLevel.Critical;
            default:
                return NotificationLevel.Information;
        }
    }

    get id(): Guid {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    get createdAt() {
        return this._createdAt;
    }

    get createdAtText() {
        return this.createdAt.format("YYYY-MM-DD LT");
    }

    get message(): string {
        return this._message;
    }

    get level(): NotificationLevel {
        return this._level;
    }

    get totalDeliveries(): number {
        return this._totalDeliveries;
    }

    get failedDeliveries(): number {
        return this._failedDeliveries;
    }

    get successDeliveries(): number {
        return this._successDeliveries;
    }
}

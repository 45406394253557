import { assign } from "lodash";

export interface IEanUpdateResponse {
    updatedEans: string[];
    unknownEans: string[];
}

export class EanUpdateResponse {

    constructor(eanUpdateResponse: IEanUpdateResponse) {
        assign(this, eanUpdateResponse);
    }

    public updatedEans: string[] = [];
    public unknownEans: string[] = [];
}

import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { ParcelBulkCreationMode } from "../../../../constants/constants";

interface IParcelCreationStrategyItemProps {
    value: ParcelBulkCreationMode;
    title: string;
    onValueChange: (newStrategy: ParcelBulkCreationMode | undefined, previousStrategy?: ParcelBulkCreationMode) => void;
    defaultChecked?: boolean;
    disabled?: boolean;
}

export const ParcelCreationStrategyItem = (props: IParcelCreationStrategyItemProps) => {
    const [isOptionChecked, setOptionChecked] = useState(props.defaultChecked ?? false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setOptionChecked(event.target.checked);

    useEffect(() => {
        if (props.disabled) {
            setOptionChecked(!props.disabled);
        }
    }, [props.disabled]);

    useEffect(() => {
        if (isOptionChecked) {
            props.onValueChange(props.value);
        } else {
            props.onValueChange(undefined, props.value);
        }
    }, [isOptionChecked]);

    return <div className={"creation-strategy"}>
        <FormControlLabel control={<Checkbox onChange={handleChange} checked={isOptionChecked} disabled={props.disabled} />}
                          data-testid={`strategy-${props.value}`}
                          label={props.title} />
    </div>;
};

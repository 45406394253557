import {
    LocaleType,
    PrinterExtensionType,
    PrinterPaperFormat,
} from "../constants/constants";

import { observable } from "mobx";

export interface ISettings {
    printerType: string;
    printerName: string;
    printerPaperFormat: string;
    isDownload: boolean;
    lang: string;
    username: string;
    muted: boolean;
    contactEmail: string;
    webhookId: string;
    webhookUrl: string;
}

export class Settings {
    public constructor(username: string, contactEmailAddress: string, printerType: PrinterExtensionType,
                       printerName: string, printerPaperFormat: PrinterPaperFormat, isDownload: boolean,
                       lang: LocaleType, muted: boolean, webhookId: string, webhookUrl: string) {
        this.username = username;
        this.contactEmail = contactEmailAddress;
        this.printerType = printerType;
        this.printerName = printerName;
        this.printerPaperFormat = printerPaperFormat;
        this.isDownload = isDownload;
        this.lang = lang as LocaleType;
        this.muted = muted;
        this.webhookId = webhookId;
        this.webhookUrl = webhookUrl;
    }

    @observable
    public printerType: PrinterExtensionType;

    @observable
    public printerName: string;

    @observable
    public printerPaperFormat: PrinterPaperFormat;

    @observable
    public isDownload: boolean;

    @observable
    public lang: LocaleType;

    @observable
    public username: string;

    public contactEmail: string;
    public webhookId: string;
    public webhookUrl: string;

    @observable
    public muted: boolean;
}

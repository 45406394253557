import { Parcel } from "../../../../model/parcel";
import { parcelsService } from "../../../../services/parcels";
import { IParcelDocumentProvider } from "./parcel-document-provider-interface";
import { context } from "../../../../../context/context";
import { getLabelFormat } from "../../../../../constants/constants";
import { ParcelDocument } from "./parcel-document";
import { DocumentFormat } from "../document-printer";

class LabelProvider implements IParcelDocumentProvider {
    private readonly _hasLabel = true;
    private readonly _hasPreparationOrder = false;

    public get hasLabel(): boolean {
        return this._hasLabel;
    }

    public get hasPreparationOrder(): boolean {
        return this._hasPreparationOrder;
    }

    public async fetch(operationCode: string, batchId: number, parcel: Parcel): Promise<ParcelDocument> {
        const response = await parcelsService.label(operationCode, batchId, parcel.id, getLabelFormat(context.settings.printerType));

        return new ParcelDocument(
            parcel.id,
            this.documentFormatFromPrinterType,
            response.succeeded ? response.Value! : undefined,
        );
    }

    private get documentFormatFromPrinterType(): DocumentFormat {
        switch (context.settings.printerType) {
            case "Zpl":
                return DocumentFormat.Zebra;
            default:
                return DocumentFormat.Pdf;
        }
    }
}

export const labelProvider = new LabelProvider();

import * as React from "react";

import { inject, observer } from "mobx-react";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { context } from "../../../../context/context";
import { getLabelFormat } from "../../../../constants/constants";
import { Permission } from "../../../../context/permission";
import { filter } from "lodash";
import { PalletShippedHeader } from "./pallet-shipped-header";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { ManagePalletActionType } from "../../../constants/constants";
import { SingleActionPicker } from "../../../../common/component/action/action-select/single-action-picker";
import { t } from "i18next";
import { palletService } from "../../../services/pallet";
import { qzManager } from "../../../qz/qz-manager";
import { eligibleForAdministratorValidator } from "../../../../common/component/action/action-select/eligible-for-administrator-validator";
import { PalletsList } from "../pallets-list/pallets-list";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { PalletsListSubItem } from "../pallets-list/pallets-list-sub-item";
import { PalletFilter } from "../pallets-list/pallet-filter";
import { PalletDataProvider } from "../pallets-list/pallet-data-provider";

const parcelManagementModel = new SearchListModel<PalletsListItem, PalletsListSubItem, PalletFilter>();

interface IPalletShippedState {
    isPalletShippingDialogOpen: boolean;
    palletShippingDialogPallets: PalletsListItem[];

    singleActionPickerAnchorElement: HTMLElement | null;
    singleActionPickerPalletItem: PalletsListItem | null;
}

@inject("routing")
@observer
export class PalletShipped extends PalletsList<IPalletShippedState> {
    public state: IPalletShippedState = {
        isPalletShippingDialogOpen: false,
        palletShippingDialogPallets: [],

        singleActionPickerAnchorElement: null,
        singleActionPickerPalletItem: null,
    };

    constructor(props: IHistoryProps) {
        super(props, parcelManagementModel, new PalletDataProvider("Shipped"));
    }

    protected renderActions() {
        return <>
            <PalletShippedHeader actionsList={this.actionsList}
                                 reloadPalletList={() => this.reloadPalletList()} />
            {this.state.singleActionPickerAnchorElement &&
                <SingleActionPicker anchorElement={this.state.singleActionPickerAnchorElement}
                                    item={this.state.singleActionPickerPalletItem}
                                    list={this.singleActionsList}
                                    onActionComplete={() => this.reloadPalletList()}
                                    onPickerClose={() => this.closeSingleActionPickerMenu()} />}
            </>;
    }

    protected get actionsList(): Array<IActionCommand<PalletsListItem, ManagePalletActionType>> {
        return [
            {
                label: t("palletShipped.action.printDeliverySlip.title"),
                value: "printDeliverySlip" as ManagePalletActionType,
                loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
                execute: async identifierList => await this.printDeliverySlip(identifierList),
            }, {
                label: t("palletShipping.action.printLabel.title"),
                value: "printLabel" as ManagePalletActionType,
                loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
                execute: async identifierList => await this.printLabel(identifierList),
                actionEligibilityValidator: eligibleForAdministratorValidator,
            },
        ];
    }

    protected openSingleActionPickerMenu(anchor: any, item: PalletsListItem) {
        this.setState({
            singleActionPickerAnchorElement: anchor,
            singleActionPickerPalletItem: item,
        });
    }

    private closeSingleActionPickerMenu() {
        this.setState({
            singleActionPickerPalletItem: null,
            singleActionPickerAnchorElement: null,
        });
    }

    protected get isSelectionEnabled() {
        return context.hasPermission(Permission.FrontWrite);
    }

    private loadExecuteParam(isScan: boolean, param: string) {
        return isScan ?
            this.getPalletListByScan(param) :
            this.searchListModel.selectedItemsList;
    }

    protected getPalletListByScan(scannedValue: string): PalletsListItem[] {
        return filter(this.searchListModel.list, pallet => pallet.code.toString() === scannedValue);
    }

    private async printDeliverySlip(identifierList: PalletsListItem[]) {
        const document = await palletService.deliverySlip(identifierList.map(p => p.id));
        await qzManager.qzPrintOrDownloadFile(document.file!, context.settings.printerType);
        await this.reloadPalletList();
    }

    private async printLabel(identifierList: PalletsListItem[]) {
        for (const p of identifierList) {
            const data = await palletService.label(p.id, getLabelFormat(context.settings.printerType));
            await qzManager.qzPrintOrDownloadFile(data.Value!, context.settings.printerType);
        }

        await this.reloadPalletList();
    }
}

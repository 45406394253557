import { IBulkParcelUploadImportValidationConstraint } from "../bulk-parcel-upload-import-validator";
import { BulkParcelUploadDataReader } from "../bulk-parcel-upload-data-reader";
import { t } from "i18next";

export class BulkParcelUploadOperationCodeConstraint implements IBulkParcelUploadImportValidationConstraint {
    private readonly _operationCode: string;

    constructor(validBatchIds: string) {
        this._operationCode = validBatchIds;
    }

    public validateHeader(headerColumns: string[]) {
        if (headerColumns.filter(c => c === BulkParcelUploadDataReader.columnNameOperationCode).length !== 1) {
            throw new Error(t("bulkParcelUpload.error.headerOperationCodeMissing"));
        }

        return true;
    }

    public validateLine(lineNumber: number, content: Map<string, string>) {
        if (this._operationCode === content.get(BulkParcelUploadDataReader.columnNameOperationCode)) {
            return true;
        }

        throw new Error(t("bulkParcelUpload.error.invalidOperationCodeProvided", {
            value: content.get(BulkParcelUploadDataReader.columnNameOperationCode),
            lineNumber,
        }));
    }
}

import { ExportDataItem } from "./export-data-item";

export class ExportDataWorksheet {
    private readonly _name?: string;
    private readonly _header: ExportDataItem;
    private readonly _items: ExportDataItem[] = [];

    constructor(header: ExportDataItem, items: ExportDataItem[], name?: string) {
        this._name = name;
        this._header = header;
        this._items = items;
    }

    get name(): string | undefined {
        return this._name;
    }

    get header(): ExportDataItem {
        return this._header;
    }

    get items(): ExportDataItem[] {
        return this._items;
    }

    get headerWithItems(): ExportDataItem[] {
        return [
            this._header,
            ...this._items,
        ];
    }
}

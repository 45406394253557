import { IExcelRowDecorator } from "./iexcel-row-decorator";
import * as Excel from "exceljs";
import { ExportDataValue } from "../../../../front-office/model/export-data-item";

export class ExcelBackgroundColorRowDecorator implements IExcelRowDecorator {
    private static defaultCellBorderStyle: Excel.Border = {style: "thin", color: {argb: "AA999999"}};
    private readonly _groupByColumnIndex?: number ;
    private _lastGroupColumnValue?: Excel.CellValue;
    private _color: ExportAttributeColor = ExportAttributeColor.RowLight;

    constructor(groupByColumnIndex?: number) {
        this._groupByColumnIndex = groupByColumnIndex;
    }

    public static forHeaderColumn(headerColumns: ExportDataValue[], columnName: string) {
        const headerColumnIndex = headerColumns.findIndex(column => column === columnName);
        if (headerColumnIndex === -1) {
            throw new Error(`Header column to group by (${columnName}) was not found in header columns list.`);
        }

        return new ExcelBackgroundColorRowDecorator(headerColumnIndex);
    }

    public decorate(row: Excel.Row) {
        if (this._groupByColumnIndex === undefined) {
            this.toggleColor();
        } else if (row.getCell(this._groupByColumnIndex + 1).value !== this._lastGroupColumnValue) {
            this._lastGroupColumnValue = row.getCell(this._groupByColumnIndex + 1).value;
            this.toggleColor();
        }

        row.border = {
            top: ExcelBackgroundColorRowDecorator.defaultCellBorderStyle,
            right: ExcelBackgroundColorRowDecorator.defaultCellBorderStyle,
            bottom: ExcelBackgroundColorRowDecorator.defaultCellBorderStyle,
            left: ExcelBackgroundColorRowDecorator.defaultCellBorderStyle,
        };
        row.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
                argb: this._color,
            },
        };
    }

    public reset() {
        this._color = ExportAttributeColor.RowLight;
        this._lastGroupColumnValue = undefined;
    }

    private toggleColor() {
        this._color = this._color === ExportAttributeColor.RowLight ?
            ExportAttributeColor.RowDark : ExportAttributeColor.RowLight;
    }
}

export enum ExportAttributeColor {
    RowLight = "FFFFFFFF",
    RowDark = "FFEEEEEE",
}

import { IActionEligibilityValidator } from "./interface-action-eligibility-validator";
import { context } from "../../../../context/context";
import { Permission } from "../../../../context/permission";

class EligibleForBackOfficeValidator implements IActionEligibilityValidator {
    public validate(): boolean {
        return context.hasPermission(Permission.BackOffice);
    }
}

export const eligibleForBackOfficeValidator = new EligibleForBackOfficeValidator();

import React, { useState } from "react";
import { IParcelDocumentProvider } from "../printer/document-provider/parcel-document-provider-interface";
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Typography,
} from "@mui/material";
import { t } from "i18next";
import { Parcel } from "../../../model/parcel";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";
import { blue } from "@mui/material/colors";
import { DocumentStrategyProvider } from "./document-strategy-provider";

interface ILabelAndAssignConfigureDocumentProps {
    parcelList: Parcel[];

    onCancel: () => void;
    onLabelRequest: (strategy: IParcelDocumentProvider) => Promise<void>;
}

export const LabelAndAssignConfigureDocument = (props: ILabelAndAssignConfigureDocumentProps) => {
    const [isPreparationOrderOptionSelected, setPreparationOrderOption] = useState(false);
    const [fitBotOnOnePage, setFitBotOnOnePage] = useState(false);

    const handlePreparationOrderOptionClick = (isPreparationOrderEnabled: boolean) => {
        setPreparationOrderOption(isPreparationOrderEnabled);
        if (!isPreparationOrderEnabled) {
            setFitBotOnOnePage(false);
        }
    };

    const handlePrintAndContinueClick = async () => {
        await props.onLabelRequest(
            DocumentStrategyProvider.getStrategyFromOptions(isPreparationOrderOptionSelected, fitBotOnOnePage));
    };

    return <>
        <DialogTitle>
            {(t("components.parcel.labelAndAssign.title"))}
        </DialogTitle>
        <DialogContent>
            <Typography className={"label-and-assign__description"}>
                {t("components.parcel.labelAndAssign.description")}
            </Typography>
            <Typography>
                {t("components.parcel.labelAndAssign.documentSettings")}
            </Typography>
            <FormControlLabel control={
                <Checkbox checked={isPreparationOrderOptionSelected}
                          onChange={(_event, checked) => handlePreparationOrderOptionClick(checked)} />}
                              label={t("components.parcel.labelAndAssign.includePreparationOrder")} />
            <FormControlLabel control={
                <Checkbox checked={fitBotOnOnePage}
                          disabled={!isPreparationOrderOptionSelected}
                          onChange={(_event, checked) => setFitBotOnOnePage(checked)} />}
                              label={t("components.parcel.labelAndAssign.fitBothOnOnePage")} />
            <Typography className={"label-and-assign__list-title"}>
                {t("components.parcel.labelAndAssign.parcelsListTitle")}
            </Typography>
            <div className={"label-and-assign__list"}>
                {props.parcelList.map(c => <SquaredChip propertyKey={`item-${c.id}`} color={blue} label={c.id.toString()} />)}
            </div>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined"
                    type="submit"
                    key="cancel-btn"
                    data-testid="pallet-label-cancel"
                    onClick={() => props.onCancel()}>
                {t("components.popin.cancel")}
            </Button>
            <Button color="secondary"
                    variant="contained"
                    type="submit"
                    key="submit-btn"
                    data-testid="pallet-label-continue"
                    onClick={async () => await handlePrintAndContinueClick()}>
                {(t("components.parcel.labelAndAssign.continuePrint"))}
            </Button>
        </DialogActions>
    </>;
};

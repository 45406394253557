import { domain, label } from "../../common/dto/dto-annotation";

import { DeliveryOrderDetail } from "./delivery-order-detail";
import { assign } from "lodash";
import { identifier } from "../../domain/identifier";
import { label50 } from "../../domain/label";
import { observable } from "mobx";
import { quantity } from "../../domain/quantity";
import { weight } from "../../domain/weight";
import { AdditionalDataModel } from "./additional-data-model";

export interface IParcelDetail {
    /** Product identifier. */
    productId: number;

    /** EAN list. */
    ean13List: string[];

    /** Product supplier reference */
    supplierReference: string;

    /** Product label. */
    labelReference: string;

    /** Quantity of the product in the parcel. */
    quantity: number;

    /** Product weight. */
    weight: number;

    /** UN code */
    unCode: string;

    /** Additional data */
    additionalDataMap: { [key: string]: string };
}

export class ParcelDetail extends AdditionalDataModel {

    /**
     * Default constructor.
     * @param parcelDetail Parcel detail object (with or without proptotype).
     */
    constructor(parcelDetail?: IParcelDetail) {
        super(parcelDetail && parcelDetail.additionalDataMap);

        assign(this, parcelDetail);

        if (parcelDetail && parcelDetail.quantity) {
            this.initialQuantity = parcelDetail.quantity;
        }
        if (!this.quantity) {
            this.quantity = 0;
            this.initialQuantity = 0;
        }
        if (!this.ean13List) {
            this.ean13List = [];
        }
    }

    public assignDeliveryOrderDetailData(deliveryOrderDetail: DeliveryOrderDetail) {
        this.productId = deliveryOrderDetail.productId;
        this.ean13List = deliveryOrderDetail.ean13List;
        this.supplierReference = deliveryOrderDetail.supplierReference;
        this.labelReference = deliveryOrderDetail.name;
        this.weight = deliveryOrderDetail.weight;
        this.unCode = deliveryOrderDetail.unCode;
    }

    /** Product identifier. */
    @domain(identifier)
    public productId: number;

    /** EAN list. */
    @label("model.parcelDetail.ean13List")
    public ean13List: string[];

    /** Product supplier reference */
    @label("model.parcelDetail.supplierReference")
    @domain(label50)
    public supplierReference: string;

    /** Product label. */
    @label("model.parcelDetail.labelReference")
    @domain(label50)
    public labelReference: string;

    /** Quantity of the product in the parcel. */
    @label("model.parcelDetail.quantity")
    @domain(quantity)
    @observable
    public quantity: number;

    public initialQuantity: number;

    /** Product weight. */
    @label("model.parcelDetail.weight")
    @domain(weight)
    public weight: number;

    /** UN code. */
    @label("model.parcelDetail.unCode")
    @domain(label50)
    public unCode: string;

    public additionalDataMap: { [key: string]: string };
}

import "./style.scss";

import * as React from "react";

import { observer } from "mobx-react";
import {
    Button,
    Dialog as DialogMaterial,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    ThemeProvider,
} from "@mui/material";
import { t } from "i18next";
import { dialogModel, DialogMessageIcon } from "./dialog-model";
import { Icon } from "../icon/icon";
import { VPUITheme } from "../../theme/vpui-theme";

@observer
export class Dialog extends React.Component<{}, {}> {

    public render() {
        return <div>
            {dialogModel.messageList.map((message, idx) => this.renderDialog(
                message.title,
                message.message,
                () => this.confirm(idx, message.onConfirm),
                message.onConfirm ? () => this.cancel(idx) : undefined,
                message.icon,
            ))}
        </div>;
    }

    private confirm(idx: number, onSuccess?: () => void) {
        dialogModel.removeMessage(idx);
        if (onSuccess) {
            onSuccess();
        }
    }
    private cancel(idx: number) {
        dialogModel.removeMessage(idx);
    }

    private renderDialog(title: string, message: string, onSuccess: () => void, onCancel?: () => void, icon?: DialogMessageIcon) {
        return (
            <ThemeProvider theme={VPUITheme}>
                <DialogMaterial
                    className={"dialog"}
                    data-testid={"dialog"}
                    disableEscapeKeyDown
                    disableEnforceFocus
                    key={message}
                    open={true}>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent className={`dialog__content-container${icon ? "--with-icon" : ""}`}>
                        {icon && <div data-testid={`dialog-${icon}-icon`}>
                            <Icon icon={icon} color={"primary"} />
                        </div>}
                        <DialogContentText>{message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {onCancel ?
                            <Button onClick={() => onCancel()} variant="contained" color={"inherit"} data-testid="dialog-cancel">
                                {t("components.popin.cancel")}
                            </Button>
                            : undefined}
                        <Button onClick={() => onSuccess()} color="secondary" autoFocus variant="contained" data-testid="dialog-confirm">
                            {t("components.popin.ok")}
                        </Button>
                    </DialogActions>
                </DialogMaterial>
            </ThemeProvider>
        );
    }
}

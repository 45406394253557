import React, { ReactNode } from "react";
import { Keyboard } from "@mui/icons-material";
import { AppBar, Button, Toolbar } from "@mui/material";
import { t } from "i18next";
import { SynchronizedHistory } from "mobx-react-router";

interface IScanShipLoginScreenProps {
    children: ReactNode;
    routing: SynchronizedHistory;
    onChangeLoginMode: () => void;
}

export const ScanShipLoginScreen = (props: IScanShipLoginScreenProps) => {
    return <>
        <div className={"scan-and-ship scan-and-ship--login"}>
            {props.children}
        </div>
        <AppBar position={"static"} elevation={0} className={"nav"} >
            <Toolbar className={"nav__toolbar"}>
                <div className={"nav__toolbar-item"}>
                    <Button onClick={() => props.onChangeLoginMode()}
                            className={"nav__toolbar-button"}
                            startIcon={<Keyboard />}>
                        {t("components.scanAndShip.login.keyboard")}
                    </Button>
                </div>
            </Toolbar>
        </AppBar>
    </>;
};

import * as React from "react";
import { SelectAutocomplete } from "../../../../common/component/select-autocomplete/select-autocomplete";
import { Card, Grid, ThemeProvider } from "@mui/material";
import { t } from "i18next";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import { useEffect, useState } from "react";
import { Warehouse } from "../../../../back-office/model/warehouse";
import { WarehouseListProvider } from "./warehouse-list-provider";
import { useWarehouseDispatcher } from "../warehouse-context/warehouse-context-provider";
import { SynchronizedHistory } from "mobx-react-router";
import { PalletPageType } from "../../../navigation/preparation-route-props";

import "./style.scss";

export interface IWarehouseSelectorProps {
    routing: SynchronizedHistory;
    selectedWarehouse: string | undefined;
}

export const WarehouseSelector = (props: IWarehouseSelectorProps) => {
    const [isWarehouseListLoaded, setIsWarehouseListLoaded] = useState(false);
    const [warehouseList, setWarehouseList] = useState<Warehouse[]>([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse | undefined>(undefined);

    const warehouseContextDispatcher = useWarehouseDispatcher();

    const parsedPalletUrl = () => {
        const fragments = props.routing.location.pathname.split("/");

        return {
            page: fragments.length >= 1 ? fragments[2] as PalletPageType : undefined,
            warehouse: fragments.length >= 2 ? fragments[3] : undefined,
        };
    };

    const redirectToWarehouse = (warehouse?: string) => {
        const parsedUrl = parsedPalletUrl();
        const targetUrl = `/pallet/${parsedUrl.page}${warehouse ? `/${warehouse}` : `/`}`;

        props.routing.push(targetUrl);
    };

    useEffect(() => {
        (async () => {
            setWarehouseList(await (new WarehouseListProvider()).list());
            setIsWarehouseListLoaded(true);
        })();
    }, []);

    useEffect(() => {
        if (isWarehouseListLoaded && selectedWarehouse?.code !== props.selectedWarehouse) {
            handleWarehouseChange(warehouseList.find(w => w.code === props.selectedWarehouse));
        }
    }, [props.selectedWarehouse, isWarehouseListLoaded]);

    const handleWarehouseChange = (warehouse: Warehouse | undefined) => {
        if (!warehouse) {
            warehouseContextDispatcher({
                action: "unset",
            });
        } else {
            warehouseContextDispatcher({
                action: "update",
                warehouse,
            });
        }

        setSelectedWarehouse(warehouse);
        redirectToWarehouse(warehouse?.code);
    };

    return <ThemeProvider theme={VPUITheme}>
        <Card className="warehouse-selector" elevation={0}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} data-testid={"warehouse-select"}>
                    <SelectAutocomplete
                        list={warehouseList}
                        label={t("components.warehouseSelector.title")}
                        selectedItem={selectedWarehouse}
                        propertyKey={"warehouse-selector-autocomplete"}
                        placeholder={t("components.warehouseSelector.placeholder")}
                        noOptionsMessage={t("components.warehouseSelector.noResultsText")}
                        getOptionValue={option => option.code}
                        onSelect={warehouse => handleWarehouseChange(warehouse ?? undefined)}
                    />
                </Grid>
            </Grid>
        </Card>
    </ThemeProvider>;
};

import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { ProgressDialogContent } from "../progress-dialog/progress-dialog-content";
import { context } from "../../../context/context";
import { qzManager } from "../../../front-office/qz/qz-manager";
import { t } from "i18next";

interface IPrinterSettingsValidatorProps {
    onValidationComplete: (isSuccess: boolean) => void;
    onError: () => void;
    confirmDownloadFallback: boolean;
}

interface IDownloadFallbackConfirmProps {
    onCancel: () => void;
    onConfirm: () => void;
}

export const PrinterSettingsValidator = (props: IPrinterSettingsValidatorProps) => {
    const [isValidationCompleted, setIsValidationCompleted] = useState(false);
    const [isPrinterValid, setIsPrinterValid] = useState<boolean | null>(null);

    useEffect(() => {
        (async () => {
            if (context.settings.isDownload) {
                return handleValidationComplete(false);
            }

            const qzStarted = await qzManager.qzInitialization();
            const configuredPrinter = context.settings.printerName;

            if (qzStarted && configuredPrinter) {
                return handleValidationComplete(true);
            }

            if (!props.confirmDownloadFallback) {
                return handleValidationComplete(false);
            }

            setIsPrinterValid(false);
        })();
    }, []);

    const handleValidationComplete = (isSuccess: boolean) => {
        props.onValidationComplete(isSuccess);
        setIsValidationCompleted(true);
    };

    return <Dialog open={!isValidationCompleted} maxWidth={"xs"}>
        {isPrinterValid === null ?
            <ProgressDialogContent message={t("components.printerConfiguration.inProgress")} /> :
            <DownloadFallbackConfirm onConfirm={() => handleValidationComplete(false)}
                                     onCancel={props.onError} />}
    </Dialog>;
};

const DownloadFallbackConfirm = (props: IDownloadFallbackConfirmProps) => {
    return <>
        <DialogTitle>
            {t("components.printerConfiguration.fallback.title")}
        </DialogTitle>
        <DialogContent>
            <Typography>
                {t("components.printerConfiguration.fallback.description")}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined"
                    type="submit"
                    key="button-cancel"
                    onClick={() => props.onCancel()}>
                {t("components.printerConfiguration.fallback.cancel")}
            </Button>
            <Button color="secondary"
                    variant="contained"
                    type="submit"
                    key="button-confirm"
                    onClick={() => props.onConfirm()}>
                {(t("components.printerConfiguration.fallback.confirm"))}
            </Button>
        </DialogActions>
    </>;
};

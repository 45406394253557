export enum ParamActionValidationStatus {
    Success,
    Warning,
    Error,
}

export class ParamActionValidationResult<T> {
    private readonly _status: ParamActionValidationStatus;
    private readonly _message: string | undefined;
    private readonly _validItems: T[];

    private constructor(status: ParamActionValidationStatus, validItems: T[], message?: string) {
        this._status = status;
        this._message = message;
        this._validItems = validItems;
    }

    public static success<T>(validItems: T[]) {
        return new ParamActionValidationResult(ParamActionValidationStatus.Success, validItems);
    }

    public static warning<T>(warningMessage: string, validItems: T[]) {
        return new ParamActionValidationResult(ParamActionValidationStatus.Warning, validItems, warningMessage);
    }

    public static error(errorMessage: string) {
        return new ParamActionValidationResult(ParamActionValidationStatus.Error, [], errorMessage);
    }

    get status(): ParamActionValidationStatus {
        return this._status;
    }

    get isSuccess() {
        return this._status === ParamActionValidationStatus.Success;
    }

    get isError() {
        return this._status === ParamActionValidationStatus.Error;
    }

    get violationMessage(): string {
        return this._message !== undefined ? this._message : "";
    }

    get validItems(): T[] {
        return this._validItems;
    }
}

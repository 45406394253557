export class StockoutProductSimulationResult {
    private readonly _calculatedQuantity: number;
    private readonly _affectedDeliveryOrders: number;

    constructor(calculatedQuantity: number, affectedDeliveryOrders: number) {
        this._calculatedQuantity = calculatedQuantity;
        this._affectedDeliveryOrders = affectedDeliveryOrders;
    }

    get calculatedQuantity(): number {
        return this._calculatedQuantity;
    }

    get affectedDeliveryOrders(): number {
        return this._affectedDeliveryOrders;
    }
}

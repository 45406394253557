import * as React from "react";

import { DeliveryOrder } from "../../../model/delivery-order";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { DeliveryOrderFilter } from "../../../model/delivery-order-filter";
import { FilterBlock } from "../../../../common/component/filter/filter-block";
import { FormFilter } from "../../../../common/component/list/filter/form-filter";
import { observer } from "mobx-react";
import { t } from "i18next";
import { computed } from "mobx";
import { uniqBy } from "lodash";
import { ISelectItem } from "../../../../common/component/input/iselect-item";
import { DetailContentFilterType, detailContentFilterTypeList } from "../../../model/common-filter";
import { DangerousGoods } from "../../../../constants/constants";

interface IDeliveryOrderListFilter {
    allowOrderPendingCancellationFiltering: boolean;
}

@observer
export class DeliveryOrderListFilter extends
    FormFilter<DeliveryOrderFilter, DeliveryOrder, DeliveryOrderDetail, IDeliveryOrderListFilter> {

    protected renderForm(data: DeliveryOrderFilter) {
        return <div className="containerFilter">
            <div className="multiBlockFilter">
                <FilterBlock
                    className=""
                    label={t("filter.category.deliveryOrder")}
                    filterBlock={this.renderDeliveryOrderFilterBlock(data)}
                />
                <FilterBlock
                    className=""
                    label={t("filter.category.shipping")}
                    filterBlock={this.renderShippingFilterBlock(data)}
                />
            </div>
            <div className="oneBlockFilter">
                <FilterBlock
                    className=""
                    label={t("filter.category.details")}
                    filterBlock={this.renderDetailsFilterBlock(data)}
                />
            </div>
        </div>;
    }

    private renderDeliveryOrderFilterBlock(data: DeliveryOrderFilter) {
        return <div className="deliveryOrder">
            {this.getFieldFilter(data, "orderId")}
            {this.getFieldFilter(data, "deliveryOrderId")}
            {this.getFieldFilter(data, "parcelCount")}
            {this.props.allowOrderPendingCancellationFiltering && this.getFieldFilter(data, "isPendingCancellation")}
        </div>;
    }

    private renderShippingFilterBlock(data: DeliveryOrderFilter) {
        return <div className="shipping">
            {this.getFieldFilter(data, "carrier", this.carriers)}
            {this.getFieldFilter(data, "country", this.countries)}
        </div>;
    }

    @computed
    private get countries() {
        return uniqBy(this.props.searchListModel.list, "country").map(deliveryOrder => {
            return {
                display: deliveryOrder.country,
                value: deliveryOrder.country,
            };
        }) as Array<ISelectItem<string>>;
    }

    @computed
    private get carriers() {
        return uniqBy(this.props.searchListModel.list, "carrier").map(deliveryOrder => {
            return {
                display: deliveryOrder.carrier,
                value: deliveryOrder.carrier,
            };
        }) as Array<ISelectItem<string>>;
    }

    @computed
    private get detailContentTypes() {
        return detailContentFilterTypeList.map(type => {
                return {
                    display: t(`model.commonFilter.${type}`),
                    value: type,
                };
            }) as Array<ISelectItem<string>>;
    }

    private get dangerousGoods(): Array<ISelectItem<string>> {
        return [
            { value: DangerousGoods.Lithium.toString(), display: "Lithium" },
            { value: DangerousGoods.LiquidQuantity.toString(), display: "Liquid Quantity" },
            { value: DangerousGoods.Both.toString(), display: "Both" },
        ];
    }

    private renderDetailsFilterBlock(data: DeliveryOrderFilter) {
        return <div className="details">
            <div className="wide" data-testid={"content-type-filter"}>
                {this.getField(data, "type", {
                    options: this.detailContentTypes,
                    inputComponent: "radioList",
                    onChange: (type: DetailContentFilterType) => {
                        this.switchOffFilterAction("isMultiRef");
                        this.switchOffFilterAction("isMonoRef");
                        this.switchOffFilterAction("isSingleRef");
                        switch (type) {
                            case "MultiReference":
                                this.switchOnFilterAction("isMultiRef");
                                break;
                            case "MonoReference":
                                this.switchOnFilterAction("isMonoRef");
                                break;
                            case "SingleReference":
                                this.switchOnFilterAction("isSingleRef");
                                break;
                        }
                    },
                })}
            </div>
            <div className="wide">
                <div className="left">
                    {this.getFieldSubFilter(data, "supplierReference", "details")}
                    {this.getFieldSubFilter(data, "labelReference", "details")}
                    {this.getFieldSubFilter(data, "isContainingProductSet", "details")}
                    {this.getFieldFilter(data, "dangerousGoods", this.dangerousGoods)}
                    {this.renderAdditionalDetailFilterFieds(data, "details")}
                </div>
            </div>
        </div>;
    }
}

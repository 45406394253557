import { OperationCounter } from "../model/operation-counter";
import { UrlBuilder } from "./url-builder";
import { httpGetList } from "../../common/network/fetch";

class CountersService {

    public async loadOperationCounter(operationCode: string, batchId: number) {
        const urlBuilder = new UrlBuilder("/counters");
        if (operationCode) {
            urlBuilder.setOperation(operationCode);
        }
        if (batchId) {
            urlBuilder.setBatchId(batchId);
        }
        const dataList = await httpGetList({url: urlBuilder.build()});
        return dataList.map((data: OperationCounter) => new OperationCounter(data));
    }
}

export const countersService = new CountersService();

import { IUrlBuilder, UrlBuilder } from "./url-builder";
import { httpPost } from "../../common/network/fetch";
import { Stockout } from "../model/stockout";

class StocksService {

    public async saveStockout(
        stockout: Stockout,
    ): Promise<Stockout[]> {
        return (await httpPost<Stockout[]>({
            url: StocksService.buildStockoutUrl(stockout.operationCode, stockout.batchId),
            data: {
                deliveryOrderIds: [stockout.deliveryOrderId],
                productId: stockout.productId,
                quantity: stockout.quantity,
            },
        })).map((s: Stockout) => new Stockout(s));
    }

    public async stockoutDeliveryOrderDetailByProductId(
        operationCode: string,
        batchId: number,
        deliveryOrderId: number,
        productId: number,
    ): Promise<Stockout[]> {
        return (await httpPost<Stockout[]>({
            url: StocksService.buildStockoutUrl(operationCode, batchId),
            data: {
                deliveryOrderIds: [deliveryOrderId],
                productId,
            },
        })).map((stockout: Stockout) => new Stockout(stockout));
    }

    public async unstockoutDeliveryOrderDetailByProductId(
        operationCode: string,
        batchId: number,
        deliveryOrderId: number,
        productId: number,
    ) {
        return await httpPost({
            url: StocksService.buildUnstockoutUrl(operationCode, batchId),
            data: {
                deliveryOrderIds: [deliveryOrderId],
                productId,
            },
        });
    }

    public async bulkStockoutSimulation(
        operationCode: string,
        batchId: number,
        deliveryOrderIds: number[],
        productId: number,
        quantity: number,
    ): Promise<Stockout[]> {
        return (await httpPost<Stockout[]>({
            url: StocksService.buildStockoutSimulationUrl(operationCode, batchId),
            data: {deliveryOrderIds, productId, quantity},
        })).map((stockout: Stockout) => new Stockout(stockout));
    }

    public async bulkStockout(
        operationCode: string,
        batchId: number,
        deliveryOrderIds: number[],
        productId: number,
        quantity: number,
    ): Promise<Stockout[]> {
        return (await httpPost<Stockout[]>({
            url: StocksService.buildStockoutUrl(operationCode, batchId),
            data: {deliveryOrderIds, productId, quantity},
        })).map((stockout: Stockout) => new Stockout(stockout));
    }

    public async bulkUnstockoutSimulation(
        operationCode: string,
        batchId: number,
        deliveryOrderIds: number[],
        productId: number,
        quantity: number,
    ): Promise<Stockout[]> {
        return (await httpPost<Stockout[]>({
            url: StocksService.buildUnstockoutSimulationUrl(operationCode, batchId),
            data: {deliveryOrderIds, productId, quantity},
        })).map((stockout: Stockout) => new Stockout(stockout));
    }

    public async bulkUnstockout(
        operationCode: string,
        batchId: number,
        deliveryOrderIds: number[],
        productId?: number,
        quantity?: number,
    ): Promise<Stockout[]> {
        return (await httpPost<Stockout[]>({
            url: StocksService.buildUnstockoutUrl(operationCode, batchId),
            data: {deliveryOrderIds, productId, quantity},
        })).map((stockout: Stockout) => new Stockout(stockout));
    }

    private static buildStockoutBaseUrl(operationCode: string, batchId: number): IUrlBuilder {
        return new UrlBuilder("/stocks")
            .setOperation(operationCode)
            .setBatchId(batchId);
    }

    private static buildStockoutUrl(operationCode: string, batchId: number) {
        return this.buildStockoutBaseUrl(operationCode, batchId)
            .setStockout()
            .build();
    }

    private static buildStockoutSimulationUrl(operationCode: string, batchId: number) {
        return this.buildStockoutBaseUrl(operationCode, batchId)
            .setStockoutSimulation()
            .build();
    }

    private static buildUnstockoutUrl(operationCode: string, batchId: number) {
        return this.buildStockoutBaseUrl(operationCode, batchId)
            .setUnstockout()
            .build();
    }

    private static buildUnstockoutSimulationUrl(operationCode: string, batchId: number) {
        return this.buildStockoutBaseUrl(operationCode, batchId)
            .setUnstockoutSimulation()
            .build();
    }
}

export const stocksService = new StocksService();

import React from "react";
import { find } from "lodash";
import { t } from "i18next";
import { ISelectItem } from "./iselect-item";
import { InputComponentType } from "./input";
import { Typography } from "@mui/material";
import "./style.scss";

interface IInputReadonlyValueProps {
    value?: string;
    options?: Array<ISelectItem<string>>;
    inputComponent?: InputComponentType;
    propertyKey?: string;
}

export class InputReadonlyValue extends React.Component<IInputReadonlyValueProps, {}> {

    public render() {
        let value;
        if (this.props.value !== undefined && this.props.value !== "") {
            if (this.props.options) {
                const item = find(this.props.options, val => val.value === this.props.value);
                value = item ? t(item.display) : this.props.value;
            } else if (this.props.inputComponent === "boolean") {
                value = t(`components.radio.${this.props.value}`);
            } else {
                value = this.props.value;
            }
        }
        if (this.props.inputComponent === "linkable" && value) {
            const linkType = value.includes("@") ? "mailto" : "tel";
            value = <a href={`${linkType}:${value}`} onClick={e => e.stopPropagation()} className="linkable-value">
                {value}
            </a>;
        }

        return <Typography variant="body2" className="input-form" data-testid={this.props.propertyKey}>
            {value || ""}
        </Typography>;
    }
}

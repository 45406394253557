import { label, domain, filterSettings } from "../../common/dto/dto-annotation";
import { identifier } from "../../domain/identifier";
import { observable } from "mobx";
import { filterActionAllValuesWithCsv, filterActionStringValues } from "../../common/component/list/filter/filter-action-data";
import { label15, label50, label255 } from "../../domain/label";
import { weight } from "../../domain/weight";
import { quantity } from "../../domain/quantity";
import { DetailContentType } from "../constants/constants";

export type DetailContentFilterType = DetailContentType | "AnyContentType";
export const detailContentFilterTypeList: DetailContentFilterType[] = ["AnyContentType", "MultiReference", "MonoReference", "SingleReference"];

export class CommonFilter {

    @label("model.commonFilter.deliveryOrderId")
    @filterSettings({
        actions: filterActionAllValuesWithCsv,
    })
    @observable
    public deliveryOrderId: string;

    @label("model.commonFilter.orderId")
    @filterSettings({ actions: filterActionAllValuesWithCsv })
    @observable
    public orderId: string;

    /** Delivery zip code. */
    @filterSettings({ actions: filterActionStringValues })
    @label("model.commonFilter.zipCode")
    @domain(label15)
    @observable
    public zipCode: string;

    /** Delivery city. */
    @filterSettings({ actions: filterActionStringValues })
    @label("model.commonFilter.city")
    @domain(label50)
    @observable
    public city: string;

    /** Delivery country. */
    @filterSettings({ actions: ["all", "equals", "different"] })
    @label("model.commonFilter.country")
    @domain(label255)
    @observable
    public country: string;

    /** Carrier Key (Transporteur). */
    @filterSettings({ actions: ["all", "equals", "different"] })
    @label("model.commonFilter.carrier")
    @domain(label50)
    @observable
    public carrier: string;

    /** Parcel weight */
    @label("model.commonFilter.weight")
    @domain(weight)
    public weight: number;

    @label("model.commonFilter.detailsQuantity")
    @domain(quantity)
    @observable
    public detailsQuantity: number;

    @label("model.commonFilter.quantity")
    @domain(quantity)
    @observable
    public quantity: number;

    @filterSettings({ actions: filterActionStringValues })
    @label("model.commonFilter.ean13List")
    @domain(label255)
    @observable
    public ean13List: string;

    @label("model.commonFilter.productId")
    @domain(identifier)
    @observable
    public productId: number;

    @filterSettings({ actions: filterActionStringValues })
    @label("model.commonFilter.supplierReference")
    @domain(label50)
    @observable
    public supplierReference: string;

    @filterSettings({ actions: filterActionStringValues })
    @label("model.commonFilter.labelReference")
    @domain(label50)
    @observable
    public labelReference: string;

    @observable
    @label("model.commonFilter.detailType")
    public type: DetailContentFilterType;

    @label("model.commonFilter.MultiReference")
    @observable
    public isMultiRef: boolean;

    @label("model.commonFilter.MonoReference")
    @observable
    public isMonoRef: boolean;

    @label("model.commonFilter.SingleReference")
    @observable
    public isSingleRef: boolean;

    /** ADR un code. */
    @filterSettings({ actions: ["all", "empty", "notEmpty", "containsItem", "notContainItem"] })
    @label("model.commonFilter.dangerousGoods")
    @domain(label255)
    @observable
    public dangerousGoods: string;
}

import * as React from "react";

import { ParcelList } from "../parcel-list/parcel-list";
import { inject, observer } from "mobx-react";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { Parcel } from "../../../model/parcel";
import { ParcelDetail } from "../../../model/parcel-detail";
import { ParcelFilter } from "../../../model/parcel-filter";
import { ParcelShippedHeader } from "./parcel-shipped-header";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { context } from "../../../../context/context";
import { ParcelStatusType, ShipmentParcelActionType } from "../../../constants/constants";
import {ParcelDataExporter} from "../parcel-list/parcel-data-exporter";
import {Permission} from "../../../../context/permission";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { parcelsService } from "../../../services/parcels";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { t } from "i18next";
import { SingleActionPicker } from "../../../../common/component/action/action-select/single-action-picker";
import { IWithRouterProps, withRouter } from "../../../../common/component/hoc/withRouter";

const parcelManagementModel = new SearchListModel<Parcel, ParcelDetail, ParcelFilter>();

interface IParcelShippedState {
    singleActionPickerAnchorElement: HTMLElement | null;
    singleActionPickerParcelItem: Parcel | null;
}

@inject("routing")
@observer
class ParcelShippedComponent extends ParcelList<IParcelShippedState> {
    public state: IParcelShippedState = {
        singleActionPickerAnchorElement: null,
        singleActionPickerParcelItem: null,
    };

    constructor(props: IWithRouterProps & IHistoryProps) {
        super(props, parcelManagementModel, new ParcelDataExporter("Shipped parcel", true, true, true));
    }

    protected get parcelStatusFilter(): ParcelStatusType {
        return "Shipped";
    }

    protected renderActions() {
        return <>
            <ParcelShippedHeader actionsList={this.actionsList}
                                 reloadParcelsList={() => this.reloadParcelList()} />
            {this.state.singleActionPickerAnchorElement &&
                <SingleActionPicker anchorElement={this.state.singleActionPickerAnchorElement}
                                    item={this.state.singleActionPickerParcelItem}
                                    list={this.singleActionsList}
                                    onActionComplete={() => this.reloadParcelList()}
                                    onPickerClose={() => this.closeSingleActionPickerMenu()} />}
        </>;
    }

    protected get actionsList(): Array<IActionCommand<Parcel, ShipmentParcelActionType>> {
        const actionList: Array<IActionCommand<Parcel, ShipmentParcelActionType>> = [
            this.getPrintLabelAction("parcelShipped"),
            this.getBatchPrintLabelAction("parcelShipped"),
            this.getPrintSlipAction("parcelShipped"),
        ];

        if (context.hasPermission(Permission.BackOffice)) {
            actionList.push(this.getCancelShipmentAction());
        }

        return actionList;
    }

    protected openSingleActionPickerMenu(anchor: any, item: Parcel) {
        this.setState({
            singleActionPickerAnchorElement: anchor,
            singleActionPickerParcelItem: item,
        });
    }

    private closeSingleActionPickerMenu() {
        this.setState({
            singleActionPickerParcelItem: null,
            singleActionPickerAnchorElement: null,
        });
    }

    protected getCancelShipmentAction(): IActionCommand<Parcel, "cancelShipment"> {
        return {
            execute: async parcelList => await this.executeCancelShipment(parcelList),
            label: `parcelShipped.action.cancelShipment.title`,
            loadExecuteParam: (isScan, param) => this.loadExecuteActionParam(isScan, param),
            value: "cancelShipment",
        };
    }

    private async executeCancelShipment(parcelList: Parcel[]) {
        if (parcelList && parcelList.length > 0) {
            const results: any[] = [];
            await Promise.all(parcelList.map(async (parcel, _) => {
                const deleteResult = await parcelsService.deleteParcelShipment(
                    this.props.params.operationCode,
                    +this.props.params.batchId,
                    parcel.parcelId,
                );
                results.push(deleteResult);
            }));

            await this.reloadParcelList();
            notificationModel.addSuccessMessage(
                this.getSuccessMessage("cancelShipment", results.length));
        } else {
            throw new Error("No data selected, filter should be done previously.");
        }
    }

    protected getSuccessMessage(shipmentParcelAction: string, nbProcessed: number) {
        if (nbProcessed === 0) {
            return t(`parcelShipped.action.${shipmentParcelAction}.noItems`);
        }
        const isMultipleParcels = nbProcessed > 1;
        return t(`parcelShipped.action.${shipmentParcelAction}.success` + (isMultipleParcels ? "s" : ""),
            { count: nbProcessed });
    }

    protected get isSelectionEnabled() {
        return context.hasPermission(Permission.FrontWrite);
    }

    protected get isParcelTrackerDisplayed() {
        return true;
    }

    protected get isPalletCodeDisplayed() {
        return true;
    }

    protected get hiddenSortFields() {
        return [];
    }

    protected get allowOrderPendingCancellationFiltering() {
        return false;
    }

    protected get allowOrderCancellationStatusFiltering() {
        return false;
    }
}

export const ParcelShipped = withRouter(ParcelShippedComponent);

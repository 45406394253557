import * as moment from "moment";

import { localDate } from "../../domain/local-date";
import { label100, label50 } from "../../domain/label";

import { BatchStatusType } from "../constants/constants";
import { OperationCounter } from "./operation-counter";
import { OperationCounterType } from "../constants/constants";
import { domain } from "../../common/dto/dto-annotation";
import { identifier } from "../../domain/identifier";
import { t } from "i18next";
import { utcDateTime } from "../../domain/utc-date";

export class BatchProgress {

    constructor(operationCounter: OperationCounter) {
        this.operationCode = operationCounter.operationCode;
        this.batchId = operationCounter.batchId;
        this.batchCreationDate = operationCounter.batchCreationDate;
        this.logisticCommitmentDate = operationCounter.logisticCommitmentDate;
        this.batchStatus = operationCounter.batchStatus;
        this.warehouseId = operationCounter.warehouseId;
        this.counterMap = new Map<OperationCounterType, number>();
    }

    @domain(label50)
    public operationCode: string;

    @domain(identifier)
    public batchId: number;

    @domain(utcDateTime)
    public batchCreationDate: moment.Moment;

    @domain(label100)
    public warehouseId: string;

    @domain(localDate)
    public logisticCommitmentDate: string;

    public get logisticCommitmentDateLevel(): "ok" | "warning" | "error" {
        const today = moment().format("YYYY-MM-DD");
        const logisticCommitmentDate = moment(this.logisticCommitmentDate);
        const diff = logisticCommitmentDate.diff(today, "days");
        if (diff >= 1) {
            return "ok";
        } else if (diff === 0) {
            return "warning";
        }
        return "error";
    }

    @domain(label50)
    public batchStatus: BatchStatusType;

    @domain(label100)
    public get batchStatusLabel() {
        return t(`model.batchStatus.${this.batchStatus}`);
    }

    public counterMap: Map<OperationCounterType, number>;
}

import React from "react";

import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";

import { observer } from "mobx-react";
import { InputAbstract, IInputAbstractProps } from "./input-abstract";
import { InputMultipleSelect, InputMultipleSelectOption } from "./input-multiple-select";
import { findIndex, remove } from "lodash";
import { ISelectItem } from "./iselect-item";
import { t } from "i18next";

export type InputMultipleComponentType = "checkboxList" | "multiSelect";

interface IInputMultipleProps {
    inputComponent?: InputMultipleComponentType;
}

@observer
export class InputMultiple extends InputAbstract<IInputAbstractProps<string[]> & IInputMultipleProps, string[]> {

    public static defaultProps = { value: [] };

    public constructor(props: IInputAbstractProps<string[]> & IInputMultipleProps) {
        super(props);
    }
    protected get inputClassName() {
        const classes =  ["multiple"];
        if (this.props.inputComponent === "multiSelect") {
            classes.push("multi-select");
        }
        return classes.join(" ");
    }

    protected renderDisabledContent() {
        // Render with disabled attr.
        return <div className="input-form">{this.renderInput()}</div>;
    }

    protected renderInput() {
        // Here we will add differents implementations (checkboxlist, autocomplete multiple, toggle list...)
        return <div>{this.renderMultiSelect()}</div>;
    }

    private renderMultiSelect() {
        if (this.props.options === undefined) {
            return;
        }

        const list = this.props.options!.map(option => new InputMultipleSelectOption(option.display, option.value));
        const selectedItems = list.filter(option => this.props.value!.indexOf(option.value) !== -1);

        return <FormGroup row key={this.props.propertyKey}>
            {this.props.inputComponent === "multiSelect" && <InputMultipleSelect
                list={list}
                label={""}
                selectedItems={selectedItems}
                propertyKey={"input-multiple-select"}
                placeholder={""}
                disabled={this.props.disabled}
                noOptionsMessage={"no results"}
                onSelectMulti={items => this.props.onChange(items != null ? items.map(i => i.value) : [])}
            />}
            {this.props.inputComponent === "checkboxList" && this.renderCheckBoxList()}
        </FormGroup>;
    }

    private renderCheckBoxList() {
        if (this.props.options === undefined) {
            return;
        }

        return <FormGroup row key={this.props.propertyKey}>
            {this.props.options!.map(option => this.renderCheckBox(option))}
        </FormGroup>;
    }

    private renderCheckBox(option: ISelectItem<string>) {
        const isSelected = findIndex(this.props.value, val => val === option.value) !== -1;
        return <FormControlLabel key={option.value} disabled={this.props.disabled || option.isReadOnly}
            control={
                <Checkbox
                    checked={isSelected}
                    onChange={() => this.onToggleOption(option.value, !isSelected)}
                    value={option.value}
                    disabled={option.isReadOnly}
                    className="multiple-checkbox"
                />
            }
            label={t(option.display)}
        />;
    }

    private onToggleOption(value: string, isSelected: boolean) {
        let newValue: string[];
        if (!this.props.value!.map) {
            newValue = [];
        } else {
            newValue = this.props.value!.map(v => v); // clone
        }

        if (isSelected) {
            newValue.push(value);
        } else {
            remove(newValue, v => v === value);
        }
        this.props.onChange(newValue);
    }
}

import * as React from "react";

import { Card, Typography } from "@mui/material";

import { getReadOnlyField } from "../../../../common/field/renderer";
import { observer } from "mobx-react";
import { Area } from "./area";

interface IAreaTile {
    area: Area;
}

@observer
export class AreaTile extends React.Component<IAreaTile, {}> {

    public render() {
        return <Card
            className="areaTile"
            key={this.props.area.parcelId}
            data-testid={`area-tile-${this.props.area.areaNumber}`}>
            {this.renderAreaIndicator()}
            {this.renderOrderDataLine()}
        </Card>;
    }

    private renderAreaIndicator() {
        return <div className="areaIndicator">
                <Typography>
                    {this.props.area.areaNumber}
                </Typography>
        </div>;
    }

    private renderOrderDataLine() {
        return <div className="areaData">
            <div>{getReadOnlyField(this.props.area, "orderId")}</div>
            <div>{getReadOnlyField(this.props.area, "deliveryOrderId")}</div>
            <div>{getReadOnlyField(this.props.area, "detailsQuantity")}</div>
        </div>;
    }
}

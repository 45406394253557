export interface IUserWebhook {
    id: string;
    url: string;
    type?: string;
}

export class UserWebhook {
    private readonly _id: string;
    private readonly _url: string;
    private readonly _type: string;

    constructor(data: IUserWebhook) {
        this._id = data.id;
        this._url = data.url;
        this._type = data.type ?? "Slack";
    }

    get id(): string {
        return this._id;
    }

    get url(): string {
        return this._url;
    }

    get type(): string {
        return this._type;
    }
}

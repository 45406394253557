import { dialogModel } from "../../../../common/component/dialog/dialog-model";
import { operationsService } from "../../../services/operations";
import { parcellingModel } from "./parcelling-model";
import { t } from "i18next";

class ParcellingCommand {

    public async openParcellingPage(
        operationCode: string,
        batchId: number,
        deliveryOrderId: number,
        onClose: () => void) {

        const deliveryOrder = await operationsService.loadDeliveryOrder(operationCode, batchId, deliveryOrderId);

        parcellingModel.initParcellingData(
            operationCode,
            batchId,
            deliveryOrder,
            onClose);
    }

    public closeParcelling() {
        if (parcellingModel.hasParcelInEditMode) {
            dialogModel.confirmBox(
                t("deliveryOrderParcelling.close.stillEditParcel.title"),
                t("deliveryOrderParcelling.close.stillEditParcel.content"),
                () => {
                    parcellingModel.closeParcelling();
                    parcellingModel.onClose();
                },
            );
        } else {
            parcellingModel.closeParcelling();
            parcellingModel.onClose();
        }
    }
}
export const parcellingCommand = new ParcellingCommand();

import { IWarehouseContext } from "./warehouse-context-provider";
import { Warehouse } from "../../../../back-office/model/warehouse";
import { User } from "../../../../context/user";
import { context } from "../../../../context/context";

interface IWarehouseContextDispatcherInitializePayload {
    action: "initialize";
    user: User;
}

interface IWarehouseContextDispatcherUpdatePayload {
    action: "update";
    warehouse: Warehouse;
}
interface IWarehouseContextDispatcherUnsetPayload {
    action: "unset";
}

export type IWarehouseContextDispatcherPayload = IWarehouseContextDispatcherInitializePayload
    | IWarehouseContextDispatcherUpdatePayload | IWarehouseContextDispatcherUnsetPayload;

export const WarehouseContextReducer = (warehouseContext: IWarehouseContext, message: IWarehouseContextDispatcherPayload) => {
    let targetWarehouseContext = {...warehouseContext};
    switch (message.action) {
        case "initialize":
            targetWarehouseContext = {
                ...warehouseContext,
                code: message.user.warehouseCode ?? undefined,
                country: message.user.warehouseCountryIso ?? undefined,
            };
            break;
        case "update":
            targetWarehouseContext = {
                ...warehouseContext,
                code: message.warehouse.code,
                country: message.warehouse.country,
            };

            context.updateAdministratorWarehouseContext(targetWarehouseContext.code, targetWarehouseContext.country);
            break;
        case "unset":
            targetWarehouseContext = {
                ...warehouseContext,
                code: undefined,
                country: undefined,
            };

            context.updateAdministratorWarehouseContext(targetWarehouseContext.code, targetWarehouseContext.country);
            break;
        default:
            throw new Error("Unknown action");
    }

    return targetWarehouseContext;
};

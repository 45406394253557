export interface IShippingAddress {
    /** Country name */
    country: string;

    /** City */
    city: string;

    /** Zip code */
    zipCode: string;

    /** Recipients first name */
    firstName: string;

    /** Recipients last name */
    lastName: string;

    /** Company name */
    companyName: string;

    /** Entrance code */
    digicode: string;

    /** Floor */
    floor: string;

    /** Pickup point identifier */
    pickupPoint: string;

    /** First address line */
    address1: string;

    /** Second address line */
    address2: string;

    /** Third address line */
    address3: string;

    /** Phone number */
    phone: string;

    /** E-mail address */
    email: string;
}

export class ShippingAddress {
    public constructor(shippingAddress?: IShippingAddress) {
        if (shippingAddress) {
            this._country = shippingAddress.country ?? "";
            this._city = shippingAddress.city ?? "";
            this._zipCode = shippingAddress.zipCode  ?? "";
            this._firstName = shippingAddress.firstName ?? "";
            this._lastName = shippingAddress.lastName ?? "";
            this._companyName = shippingAddress.companyName ?? "";
            this._digicode = shippingAddress.digicode ?? "";
            this._floor = shippingAddress.floor ?? "";
            this._pickupPoint = shippingAddress.pickupPoint ?? "";
            this._address1 = shippingAddress.address1 ?? "";
            this._address2 = shippingAddress.address2 ?? "";
            this._address3 = shippingAddress.address3 ?? "";
            this._phone = shippingAddress.phone ?? "";
            this._email = shippingAddress.email ?? "";
        }
    }

    /** Country name */
    private readonly _country: string;

    /** City */
    private readonly _city: string;

    /** Zip code */
    private readonly _zipCode: string;

    /** Recipients first name */
    private readonly _firstName: string;

    /** Recipients last name */
    private readonly _lastName: string;

    /** Company name */
    private readonly _companyName: string;

    /** Entrance code */
    private readonly _digicode: string;

    /** Floor */
    private readonly _floor: string;

    /** Pickup point identifier */
    private readonly _pickupPoint: string;

    /** First address line */
    private readonly _address1: string;

    /** Second address line */
    private readonly _address2: string;

    /** Third address line */
    private readonly _address3: string;

    /** Phone number */
    private readonly _phone: string;

    /** E-mail address */
    private readonly _email: string;

    public get country(): string {
        return this._country;
    }

    public get city(): string {
        return this._city;
    }

    public get zipCode(): string {
        return this._zipCode;
    }

    public get firstName(): string {
        return this._firstName;
    }

    public get lastName(): string {
        return this._lastName;
    }

    public get companyName(): string {
        return this._companyName;
    }

    public get digicode(): string {
        return this._digicode;
    }

    public get floor(): string {
        return this._floor;
    }

    public get pickupPoint(): string {
        return this._pickupPoint;
    }

    public get address1(): string {
        return this._address1;
    }

    public get address2(): string {
        return this._address2;
    }

    public get address3(): string {
        return this._address3;
    }

    public get phone(): string {
        return this._phone;
    }

    public get email(): string {
        return this._email;
    }

    public get shortAddress() {
        return this.zipCode + " " + this.city + " " + this.country;
    }
}

import React, { useEffect, useRef } from "react";
import { useCameraBarcodeScanner } from "./use-camera-barcode-scanner";
import { useCameraStream } from "../../../../common/hooks/use-camera-stream";

interface ICameraBarcodeScannerProps {
    cameraDevice: MediaDeviceInfo;
    valueValidator?: (value: string) => boolean;
    onValueScan: (value: string) => void;
    onNextCameraRequested?: () => void;
    onCameraAccessFailed: () => void;
}

export const CameraBarcodeScanner = (props: ICameraBarcodeScannerProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const streamState = useCameraStream(videoRef, props.cameraDevice.deviceId);
    useCameraBarcodeScanner(props.cameraDevice.deviceId,
        videoRef,
        props.onValueScan, props.valueValidator);

    useEffect(() => {
        if (streamState === "refused") {
            setTimeout(() => props.onCameraAccessFailed(), 500);
        }
    }, [streamState]);

    return <video autoPlay={true} muted={true} playsInline={true} controls={false}
                  className={"scanner__video"} ref={videoRef} onClick={props.onNextCameraRequested} />;
};

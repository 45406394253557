import { action, observable, computed } from "mobx";

export class ScanFieldModel  {

    @observable private _scanValue = "";

    @computed public get scanValue() {
        return this._scanValue;
    }

    @action
    public setValue(value: string) {
        this._scanValue = value;
    }
}

import { ExportDataContents } from "../../../front-office/model/export-data-contents";
import { ExportDataItem } from "../../../front-office/model/export-data-item";
import { excelDataExporter } from "../../../common/component/list/import-export-list/excel-data-exporter";
import { loaderModel } from "../../../common/component/loader/loader-model";
import { t } from "i18next";
import { ImportData } from "../../../common/component/list/import-export-list/import-data";
import { notificationModel } from "../../../common/component/notifications/notification-model";
import _ from "lodash";

class EditorHelper {
    public downloadReplacementTemplate(fileName: string, fromColumnName: string, toColumnName: string, items?: ExportDataItem[]) {
        const exportDataContents = ExportDataContents.rawContentsForSingleWorksheet(fileName, new ExportDataItem([
            fromColumnName,
            toColumnName,
        ]), items ?? []);
        excelDataExporter.export(exportDataContents, []);
    }

    public async readUploadedFile(file: File, actionMessageKey: string, uploadFailedMessageKey: string) {
        let data: Array<Map<string, string>> = [];
        const actionId = loaderModel.foregroundActionStart(t(actionMessageKey));

        try {
            data = await (new ImportData()).readUploadedFile(file);
        } catch (err) {
            notificationModel.addErrorMessage(t(uploadFailedMessageKey, {
                message: err,
            }));
        } finally {
            loaderModel.foregroundActionEnd(actionId);
        }

        return data;
    }

    public createMapOfValuesForUpdate(data: Array<Map<string, string>>, fromColumnNameKey: string, toColumnNameKey: string, incorrectLineMessageKey: string) {
        const map: { [key: string]: string } = {};
        data.forEach((row, index) => {
            const fromValue = row.get(t(fromColumnNameKey));
            const toValue = row.get(t(toColumnNameKey));

            if (fromValue && toValue) {
                map[fromValue] = toValue;
            } else {
                notificationModel.addErrorMessage(t(incorrectLineMessageKey, {
                    row: index + 1,
                }));

                throw new Error(`Incorrect file at line ${index + 1}`);
            }
        });

        return map;
    }

    public downloadReport(
        fileName: string,
        fromColumnNameKey: string,
        toColumnNameKey: string,
        map: { [key: string]: string },
        unknownValues: string[]) {
        const exportDataContents = ExportDataContents.rawContentsForSingleWorksheet(fileName, new ExportDataItem([
            t(fromColumnNameKey),
            t(toColumnNameKey),
            t("product.downloadReport.status"),
        ]), _.keys(map).map(fromValue => new ExportDataItem([
            fromValue,
            map[fromValue],
            unknownValues.indexOf(fromValue) === -1 ?
                t("product.downloadReport.updated") :
                t("product.downloadReport.notFound"),
        ])));
        excelDataExporter.export(exportDataContents, []);
    }
}

export const editorHelper = new EditorHelper();

import { Parcel } from "../../../model/parcel";
import { t } from "i18next";
import { stringValue } from "../../../../common/field/renderer";
import { ExportDataItem } from "../../../model/export-data-item";
import { ExportDataContents, ExportType } from "../../../model/export-data-contents";
import { context } from "../../../../context/context";
import { Permission } from "../../../../context/permission";

export class ParcelDataExporter {
    constructor(private name: string,
                private includeParcelTracker: boolean = false,
                private includePalletCode: boolean = false,
                private includeShipmentDate = false) {}

    public getExportData(
        operationCode: string,
        batchId: number,
        additionalFields: string[],
        sortFilterData: ReadonlyArray<Parcel>,
        exportType: ExportType,
    ): ExportDataContents {
        if (exportType === ExportType.AllColumns) {
            return ExportDataContents.forOperationBatchWithName(
                `${this.name}_all_columns`,
                operationCode,
                batchId,
                this.getHeaderForAllColumnsData(additionalFields),
                this.getAllColumnsData(sortFilterData, additionalFields, batchId),
            );
        } else {
            throw new Error(`Export type ${ExportType[exportType]} is not supported in parcel management section.`);
        }
    }

    private getHeaderForAllColumnsData(additionalFields: string[]): ExportDataItem {
        const additionalHeaderData: string[] = [];

        if (this.includeParcelTracker) {
            additionalHeaderData.push(t("model.parcel.parcelTracker"));
        }

        if (context.hasPermission(Permission.FeaturePalletWorkflow) && this.includePalletCode) {
            additionalHeaderData.push(t("model.parcel.palletCode"));
        }

        if (this.includeShipmentDate) {
            additionalHeaderData.push(t("model.parcel.shipmentDate"));
        }

        // header
        const header = new ExportDataItem([
            t("model.parcel.batchId"),
            t("model.parcel.parcelId"),
            t("model.parcel.orderId"),
            t("model.parcel.deliveryOrderId"),
            t("model.parcel.carrier"),
            t("model.parcel.weight"),
            ...additionalHeaderData,
            t("model.parcel.address"),
            t("model.parcelDetail.productId"),
            t("model.parcelDetail.ean13List"),
            t("model.parcelDetail.supplierReference"),
            t("model.parcelDetail.labelReference"),
            t("model.parcelDetail.quantity"),
            t("model.parcelDetail.weight"),
            ...additionalFields.map(field => field),
        ]);

        return header;
    }

    private getAllColumnsData(sortFilterData: ReadonlyArray<Parcel>, additionalFields: string[], batchId: number): ExportDataItem[] {
        const data: ExportDataItem[] = [];

        // data
        sortFilterData.forEach(parcel => {
            parcel.details.forEach(parcelDetails => {
                const additionalRowData: string[] = [];
                if (this.includeParcelTracker) {
                    additionalRowData.push(stringValue(parcel, "parcelTracker"));
                }

                if (context.hasPermission(Permission.FeaturePalletWorkflow) && this.includePalletCode) {
                    additionalRowData.push(stringValue(parcel, "palletCode"));
                }

                if (this.includeShipmentDate) {
                    additionalRowData.push(stringValue(parcel, "shipmentDate"));
                }

                const orderArray: ExportDataItem = new ExportDataItem([
                    batchId,
                    parcel.parcelId,
                    parcel.orderId,
                    parcel.deliveryOrderId,
                    stringValue(parcel, "carrier"),
                    stringValue(parcel, "weight"),
                    ...additionalRowData.map(entry => entry),
                    stringValue(parcel, "address"),
                    parcelDetails.productId,
                    parcelDetails.ean13List.map(ean => ean).join(", "),
                    stringValue(parcelDetails, "supplierReference"),
                    stringValue(parcelDetails, "labelReference"),
                    parcelDetails.quantity,
                    stringValue(parcelDetails, "weight"),
                    ...additionalFields.map(field => stringValue(parcelDetails, field as any)),
                ]);

                data.push(orderArray);
            });
        });

        return data;
    }
}

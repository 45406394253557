import { ParcelCreationResponse } from "../../../model/parcel-creation-response";
import { sum } from "lodash";

export enum ParcelCustomCreationResponseStatus {
    Unknown,
    Success,
    Error,
}

export class ParcelCustomCreationResponse {
    private readonly _status: ParcelCustomCreationResponseStatus;
    private readonly _numberOfCreated: number;
    private readonly _errorMessages: string[];

    private constructor(status: ParcelCustomCreationResponseStatus, numberOfCreated: number, errorMessages: string[]) {
        this._status = status;
        this._numberOfCreated = numberOfCreated;
        this._errorMessages = errorMessages;
    }

    public static empty() {
        return new ParcelCustomCreationResponse(ParcelCustomCreationResponseStatus.Unknown, 0, []);
    }

    public static fromParcelCreationResponses(creationResponseList: ParcelCreationResponse[]) {
        const isSuccess = creationResponseList.some(r => r.parcels.length > 0);

        return new ParcelCustomCreationResponse(isSuccess ?
            ParcelCustomCreationResponseStatus.Success : ParcelCustomCreationResponseStatus.Error,
            sum(creationResponseList.map(r => r.parcels.length)),
            creationResponseList.flatMap(r => r.errors.flatMap(e => e.errors)));
    }

    public static fromParcelCreationResponse(creationResponse: ParcelCreationResponse) {
        return new ParcelCustomCreationResponse(creationResponse.parcels.length > 0 ?
            ParcelCustomCreationResponseStatus.Success : ParcelCustomCreationResponseStatus.Error,
            creationResponse.parcels.length, creationResponse.errors.flatMap(e => e.errors));
    }

    get status(): ParcelCustomCreationResponseStatus {
        return this._status;
    }

    get numberOfCreated(): number {
        return this._numberOfCreated;
    }

    get errorMessages(): string[] {
        return this._errorMessages;
    }
}

import { computed, observable, action } from "mobx";

import { FilterData } from "../filter/filter-data";
import { paginationSize } from "../../../../constants/constants";
import { FilterActionType, FilterActionData } from "../filter/filter-action-data";
import { findIndex, orderBy } from "lodash";
import { commonSearchListModel } from "./common-search-list-model";
import { ISearchListRow, ISearchListSubRow } from "./search-list";

export class SearchListModel<TRow extends ISearchListRow<TSubRow>, TSubRow extends ISearchListSubRow, TFilter extends {}> {

    @observable private _list: TRow[] = [];
    @observable private _isAllChecked = false;
    @observable private _pageSize: number = paginationSize;
    @observable private _isFilterOpened = false;
    @observable private _selectedItems = new Map<number | string, TRow>(); // need a map to trigger mobX events (vs Set).
    private _additionalFields: Set<string> = new Set<string>();

    @computed
    public get list() {
        return this._list as ReadonlyArray<TRow>;
    }
    @computed
    public get isAllChecked() {
        return this._isAllChecked;
    }
    @computed
    public get filterData(): FilterData<TFilter, TRow, TSubRow> {
        return commonSearchListModel.filterData;
    }
    @computed
    public get pageSize() {
        return this._pageSize;
    }

    @computed
    public get isFilterOpened() {
        return this._isFilterOpened;
    }

    @computed
    public get isSorterOpened() {
        return commonSearchListModel.isSorterOpened;
    }

    public isSelected(id: number | string) {
        return this._selectedItems.has(id);
    }

    @computed
    public get hasSort() {
        return commonSearchListModel.hasSort;
    }

    public getSortByLevel(level: number) {
        return commonSearchListModel.getSortByLevel(level);
    }

    @computed
    public get additionalFields() {
        const list: string[] = [];
        this._additionalFields.forEach(field => list.push(field));
        return list.sort((a: string, b: string) => {
            return a > b ? 1 : -1;
        });
    }

    @computed
    public get selectedItemsList() {
        let list: TRow[] = [];
        this._selectedItems.forEach((value: TRow) => list.push(value));

        const sortLevel1 = this.getSortByLevel(1);
        if (sortLevel1) {
            // Sort on priority 1
            list = orderBy(list, [sortLevel1.fieldName], [sortLevel1.sortOrder]);
        }

        return list || [];
    }

    @action
    public initStore(
        defaultFilter: TFilter,
        parseFilterData: (filterData: FilterData<any, any, any>) => FilterData<TFilter, TRow, TSubRow>) {
        if (commonSearchListModel.filterData) {
            commonSearchListModel.resetFilterData(parseFilterData(commonSearchListModel.filterData));
        } else {
            commonSearchListModel.resetFilterData(new FilterData(defaultFilter, true));
        }
        this._pageSize = paginationSize;
    }

    @action
    public clickSort() {
        commonSearchListModel.clickSort();
    }

    @action
    public resetList(list: TRow[]) {
        this._list = list;
        this._pageSize = paginationSize;
        this._additionalFields.clear();
        this._list.forEach(row => {
            row.details.forEach(subRow => {
                if (subRow.additionalDataMap) {
                    // tslint:disable-next-line:forin
                    for (const field in subRow.additionalDataMap) {
                        if (subRow.additionalDataMap[field] !== "") {
                            this._additionalFields.add(field);
                        }
                    }
                }
            });
        });
        this.clearSelectedItems();
    }

    @action
    public clearSelectedItems() {
        this._selectedItems.clear();
        this._isAllChecked = false;
    }

    @action
    public applySort(field: string, priority: number) {
        commonSearchListModel.applySort(field, priority);
        this._pageSize = paginationSize;
    }

    @action
    public applyFilter(filter: FilterData<TFilter, TRow, TSubRow>) {
        this._pageSize = paginationSize;
        this._isAllChecked = false;
        commonSearchListModel.resetFilterData(filter);
        this._isFilterOpened = false;
        this._selectedItems.clear();
    }

    @action
    public applyQuickFilter(defaultFilter: TFilter, filterConditions: Array<{
        filterKey: keyof TFilter & (keyof TRow | keyof TSubRow),
        filterAction: FilterActionType,
        filterValue: any,
        rowKey?: keyof TRow,
    }>) {

        const newFilter = defaultFilter;
        const filterActions: Array<FilterActionData<TFilter, TRow, TSubRow>> = [];
        filterConditions.forEach(condition => {
            newFilter[condition.filterKey] = condition.filterValue;
            filterActions.push(new FilterActionData<TFilter, TRow, TSubRow>(
                condition.filterKey,
                condition.filterAction,
                condition.rowKey));
        });

        this.applyFilter(new FilterData(newFilter, false, filterActions));

    }

    @action
    public resetFilter(defaultFilter: TFilter) {
        commonSearchListModel.resetFilterData(new FilterData(defaultFilter, true));
    }

    @action
    public removeFilter(filterAction: FilterActionData<TFilter, TRow, TSubRow>) {
        commonSearchListModel.removeFilter(filterAction);
    }

    @action
    public selectElements(items: TRow[], checked: boolean, limit?: number) {
        this._selectedItems.clear();
        if (!checked) {
            this._isAllChecked = false;
            return;
        }

        if (limit === undefined || limit >= this._list.length) {
            this._isAllChecked = true;
            return this.selectItemsFromList(items);
        }

        return this.selectItemsFromList(items.slice(0, limit));
    }

    @action
    private selectItemsFromList(items: TRow[]) {
        items.forEach(row => {
            this._selectedItems.set(row.id, row);
        });
    }

    @action
    public selectItem(row: TRow, checked: boolean) {
        if (!checked) {
            this._isAllChecked = false;
            this._selectedItems.delete(row.id);
        } else {
            this._selectedItems.set(row.id, row);
        }
    }

    @action
    public showMore() {
        this._pageSize += paginationSize;
    }

    @action
    public resetSort() {
        commonSearchListModel.resetSort();
    }

    @action
    public openFilter() {
        this._isFilterOpened = true;
    }

    @action
    public closeFilter() {
        this._isFilterOpened = false;
    }

    @action
    public refreshList(
        itemList: TRow[],
        isInPageScope: (item: TRow) => boolean,
        parseUpdatedItem?: (newItem: TRow, oldItem: TRow) => TRow) {
        itemList.forEach(item => {
            const idx = findIndex(this._list, row => row.id === item.id);
            const isInScope = isInPageScope(item);
            if (idx !== -1) {
                if (isInScope) {
                    // Item has been updated
                    this._list[idx] = parseUpdatedItem === undefined ?
                        item : parseUpdatedItem(item, this._list[idx]);
                } else {
                    // Item has been deleted.
                    this._list.splice(idx, 1);
                }
            } else if (isInScope) {
                // Item has been added
                this._list.push(item);
            }
        });

        this.clearSelectedItems();
    }
}

import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { t } from "i18next";

import "./style.scss";

interface IPasswordRequirementsValidatorProps {
    password: string;
    onChange: (isValid: boolean) => void;
}

export const PasswordRequirementsValidator = (props: IPasswordRequirementsValidatorProps) => {
    const [isPasswordLongEnough, setIsPasswordLongEnough] = useState(false);
    const [hasNonAlphanumericChar, setHasNonAlphanumericChar] = useState(false);
    const [hasDigit, setHasDigit] = useState(false);
    const [hasLowercase, setHasLowercase] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);

    useEffect(() => {
        setIsPasswordLongEnough(props.password.length >= 16);

        const regExpNonAlphaNumeric = /([^a-z0-9])/g;
        setHasNonAlphanumericChar(regExpNonAlphaNumeric.test(props.password.toLowerCase()));

        const regExpHasDigits = /([0-9])/g;
        setHasDigit(regExpHasDigits.test(props.password));

        const regExpHasLowercase = /([a-z])/g;
        setHasLowercase(regExpHasLowercase.test(props.password));

        const regExpHasUppercase = /([A-Z])/g;
        setHasUppercase(regExpHasUppercase.test(props.password));
    }, [props.password]);

    useEffect(() =>
        props.onChange(isPasswordLongEnough && hasNonAlphanumericChar && hasDigit && hasLowercase && hasUppercase),
        [isPasswordLongEnough, hasNonAlphanumericChar, hasDigit, hasUppercase]);

    return <div className={"password-validator"}>
        <Typography className={"password-validator__title"}>
            {t("components.passwordChange.requirements.label")}
        </Typography>
        <div className={"password-validator__items"}>
            <Typography className={`password-validator__item ${isPasswordLongEnough ? "password-validator__item--success" : ""}`}>
                {t("components.passwordChange.requirements.minLength")}
            </Typography>
            <Typography className={`password-validator__item ${hasNonAlphanumericChar ? "password-validator__item--success" : ""}`}>
                {t("components.passwordChange.requirements.hasNonAlphaNumeric")}
            </Typography>
            <Typography className={`password-validator__item ${hasDigit ? "password-validator__item--success" : ""}`}>
                {t("components.passwordChange.requirements.hasDigit")}
            </Typography>
            <Typography className={`password-validator__item ${hasLowercase ? "password-validator__item--success" : ""}`}>
                {t("components.passwordChange.requirements.hasLowercase")}
            </Typography>
            <Typography className={`password-validator__item ${hasUppercase ? "password-validator__item--success" : ""}`}>
                {t("components.passwordChange.requirements.hasUppercase")}
            </Typography>
        </div>
    </div>;
};

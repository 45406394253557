import * as React from "react";

import { BulkyActionPicker, IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { PrepareBatchActionType } from "../../../constants/constants";
import { observer } from "mobx-react";
import { Permission } from "../../../../context/permission";
import { DeliveryOrder } from "../../../model/delivery-order";
import { operationContext } from "../../../../context/operation-context";

interface IBatchPreparationAction {
    actionsList: Array<IActionCommand<DeliveryOrder, PrepareBatchActionType>>;
    reloadDeliveryOrderList: () => Promise<void>;
}

@observer
export class BatchPreparationHeader extends React.Component<IBatchPreparationAction, {}> {

    public render() {
        return <div className="list-actions-container">
            <div className="list-actions">
                <BulkyActionPicker propertyKey={operationContext.operationCodeOrEmpty}
                                   scanFieldType="number"
                                   inputLabel=""
                                   contentPrefix="batchPreparation.action"
                                   actions={this.props.actionsList}
                                   permission={Permission.FrontWrite} />
            </div>
        </div>;
    }
}

import { useState } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { CreatePalletDialog } from "./create-pallet-dialog";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import { useWarehouseContext } from "../warehouse-context/warehouse-context-provider";

interface ICreatePalletProps {
    onPalletCreated: () => Promise<void>;
}

export const CreatePallet = (props: ICreatePalletProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const warehouseContext = useWarehouseContext();

    return <>
        <ThemeProvider theme={VPUITheme}>
            <div>
                <Button key={"create-pallet-button"}
                        variant={"contained"}
                        color={"secondary"}
                        disabled={warehouseContext.code === undefined}
                        data-testid={"create-pallet-button"}
                        onClick={() => setIsDialogOpen(true)}>
                    {t("components.pallet.createPalletDialog.openButton")}
                </Button>
            </div>
            {isDialogOpen && warehouseContext.code &&
                <CreatePalletDialog warehouse={warehouseContext.code}
                                    onClose={() => setIsDialogOpen(false)}
                                    onPalletCreated={async () => await props.onPalletCreated()} />}
        </ThemeProvider>
    </>;
};

import * as React from "react";

import { DeliveryOrderList } from "../delivery-order-list/delivery-order-list";
import { inject, observer } from "mobx-react";
import { BatchPreparationHeader } from "./batch-preparation-header";
import { DeliveryOrder } from "../../../model/delivery-order";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { DeliveryOrderFilter } from "../../../model/delivery-order-filter";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { context } from "../../../../context/context";
import { OrderDataExporter } from "../delivery-order-list/order-data-exporter";
import { DefaultOrderDataProvider } from "../delivery-order-list/default-order-data-provider";
import { Permission } from "../../../../context/permission";
import { CreateProductStockout } from "../product-stockout/create-product-stockout";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { PrepareBatchActionType } from "../../../constants/constants";
import { parcelCreationValidator } from "./parcel-creation-validator";
import { FeatureToggle } from "../../../../context/app-configuration";
import { ParcelBulkCreationMode } from "../../../../constants/constants";
import { stockoutProductModel } from "../product-stockout/stockout-product-model";
import { SingleActionPicker } from "../../../../common/component/action/action-select/single-action-picker";
import {
    componentParcelCreationValidator,
    monoRefParcelCreationValidator,
    multiRefParcelCreationValidator,
} from "./parcelization-by-delivery-order-type-validator";
import { CustomParcelizationCommandHandler } from "./custom-parcelization-command-handler";
import { CustomParcelizationCommand } from "./custom-parcelization-command";
import { IParamActionValidator } from "../../../../common/component/action/action-select/interface-param-action-validator";
import { ExportType } from "../../../model/export-data-contents";
import { ExportOperationProductDataHandler } from "../delivery-order-list/export-operation-product-data-handler";
import { ExportMenu } from "../../../../common/component/list/import-export-list/export-menu";
import { MenuItem } from "@mui/material";
import { t } from "i18next";
import { ParcelCreationStrategyPicker } from "../parcel-creation-strategy-picker/parcel-creation-strategy-picker";
import { IWithRouterProps, withRouter } from "../../../../common/component/hoc/withRouter";

const batchPreparationModel = new SearchListModel<DeliveryOrder, DeliveryOrderDetail, DeliveryOrderFilter>();

interface IBatchPreparationState {
    singleActionPickerAnchorElement: HTMLElement | null;
    singleActionPickerDeliveryOrderItem: DeliveryOrder | null;

    isParcelCreationStrategyPickerOpen: boolean;
    parcelCreationStrategyDeliveryOrderList: DeliveryOrder[];
}

@inject("routing")
@observer
class BatchPreparationComponent extends DeliveryOrderList<IBatchPreparationState> {
    public state: IBatchPreparationState = {
        singleActionPickerAnchorElement: null,
        singleActionPickerDeliveryOrderItem: null,

        isParcelCreationStrategyPickerOpen: false,
        parcelCreationStrategyDeliveryOrderList: [],
    };

    private readonly _parcelizationHandler = new CustomParcelizationCommandHandler();

    constructor(props: IWithRouterProps & IHistoryProps) {
        super(
            props,
            batchPreparationModel,
            new OrderDataExporter("Delivery order", true),
            new DefaultOrderDataProvider("Available"),
        );
    }

    protected renderActions() {
        return <>
            <BatchPreparationHeader actionsList={this.actionsList}
                                    reloadDeliveryOrderList={() => this.reloadDeliveryOrderList()} />
            <CreateProductStockout contentPrefix={"batchPreparation.action.declareArticleStockout"}
                                   operationCode={this.props.params.operationCode}
                                   batchId={+this.props.params.batchId}
                                   reloadDeliveryOrderList={() => this.reloadDeliveryOrderList()} />
            {this.state.isParcelCreationStrategyPickerOpen &&
                <ParcelCreationStrategyPicker operationCode={this.props.params.operationCode}
                                              batchId={+this.props.params.batchId}
                                              deliveryOrderList={this.state.parcelCreationStrategyDeliveryOrderList}
                                              onComplete={async () => await this.reloadDeliveryOrderList()}
                                              onClose={() => this.closeParcelCreationStrategyPicker()} />}
            {this.state.singleActionPickerAnchorElement &&
                <SingleActionPicker anchorElement={this.state.singleActionPickerAnchorElement}
                                    item={this.state.singleActionPickerDeliveryOrderItem}
                                    list={this.singleActionsList}
                                    onActionComplete={() => this.reloadDeliveryOrderList()}
                                    onPickerClose={() => this.closeSingleActionPickerMenu()} />}
        </>;
    }

    protected get actionsList(): Array<IActionCommand<DeliveryOrder, PrepareBatchActionType>> {
        return ([{
            execute: async deliveryOrderList => await this.executeParcelization(deliveryOrderList, "OneParcelPerOrder"),
            label: "batchPreparation.action.create-per-order.title",
            loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
            value: "create-per-order",
            paramValidator: parcelCreationValidator,
        }, {
            execute: async deliveryOrderList => await this.executeParcelization(deliveryOrderList, "OneParcelPerProductItem"),
            label: "batchPreparation.action.create-per-product.title",
            loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
            value: "create-per-product",
            paramValidator: parcelCreationValidator,
        }, {
            execute: async deliveryOrderList => this.openParcelCreationStrategyPicker(deliveryOrderList),
            label: "batchPreparation.action.parcel-custom-create.title",
            loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
            value: "parcel-custom-create",
            hideExecutionConfirmation: true,
            disableSingleActionPicker: true,
        }, {
            execute: async deliveryOrderList => await this.executeParcelization(deliveryOrderList, "OneParcelPerOrderWithMonoRef", monoRefParcelCreationValidator),
            label: "batchPreparation.action.create-mono-ref.title",
            loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
            value: "create-mono-ref",
            singleActionParamEligibilityValidator: monoRefParcelCreationValidator,
            hideExecutionConfirmation: true,
        }, {
            execute: async deliveryOrderList => await this.executeParcelization(deliveryOrderList, "OneParcelPerOrderWithMultiRef", multiRefParcelCreationValidator),
            label: "batchPreparation.action.create-multi-ref.title",
            loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
            value: "create-multi-ref",
            singleActionParamEligibilityValidator: multiRefParcelCreationValidator,
            hideExecutionConfirmation: true,
        }, {
            execute: async deliveryOrderList => await this.executeParcelization(deliveryOrderList, "OneParcelPerComponentProduct", componentParcelCreationValidator),
            label: "batchPreparation.action.create-for-components.title",
            loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
            value: "create-for-components",
            singleActionParamEligibilityValidator: componentParcelCreationValidator,
            hideExecutionConfirmation: true,
        }, {
            execute: async deliveryOrderList => await this.startArticleStockout(deliveryOrderList),
            label: "batchPreparation.action.declareArticleStockout.title",
            loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
            value: "declareArticleStockout",
            hideExecutionConfirmation: true,
        }] as Array<IActionCommand<DeliveryOrder, PrepareBatchActionType>>)
            .filter(item => item.value === "declareArticleStockout" ? context.isFeatureToggleEnabled(FeatureToggle.STOCKOUTS) : true);
    }

    protected renderExportMenu() {
        const handleSearchListMenuItemClick = (strategy: ExportType) => {
            this.renderExcelExport(this.getFilteredList(), strategy);
        };

        const handleExportOperationProductData = async () => {
            (await (new ExportOperationProductDataHandler()).handle(this.props.params.operationCode));
        };

        return <ExportMenu>
            <MenuItem onClick={() => handleSearchListMenuItemClick(ExportType.AllColumns)} data-testid="exportAllColumnsMenuItem">
                {t("components.exportList.exportAllColumns")}
            </MenuItem>
            <MenuItem onClick={() => handleSearchListMenuItemClick(ExportType.OnlyRefProducts)} data-testid="exportRefProductsMenuItem">
                {t("components.exportList.exportOnlyRefProducts")}
            </MenuItem>
            <MenuItem onClick={() => handleSearchListMenuItemClick(ExportType.PickingList)} data-testid="exportPickingListMenuItem">
                {t("components.exportList.exportPickingList")}
            </MenuItem>
            <MenuItem onClick={async () => await handleExportOperationProductData()} data-testid="exportProductData">
                {t("components.exportList.exportProductData")}
            </MenuItem>
        </ExportMenu>;
    }

    protected openSingleActionPickerMenu(anchor: HTMLElement, item: DeliveryOrder) {
        this.setState({
            singleActionPickerAnchorElement: anchor,
            singleActionPickerDeliveryOrderItem: item,
        });
    }

    private closeSingleActionPickerMenu() {
        this.setState({
            singleActionPickerDeliveryOrderItem: null,
            singleActionPickerAnchorElement: null,
        });
    }

    private openParcelCreationStrategyPicker(deliveryOrderList: DeliveryOrder[]) {
        this.setState({
            isParcelCreationStrategyPickerOpen: true,
            parcelCreationStrategyDeliveryOrderList: deliveryOrderList,
        });
    }

    private closeParcelCreationStrategyPicker() {
        this.setState({
            isParcelCreationStrategyPickerOpen: false,
            parcelCreationStrategyDeliveryOrderList: [],
        });
    }

    private async executeParcelization(deliveryOrderList: DeliveryOrder[], preparationMode: ParcelBulkCreationMode,
                                       deliveryOrderListValidator?: IParamActionValidator<DeliveryOrder>) {
        if (!deliveryOrderList || deliveryOrderList.length === 0) {
            throw new Error("No data selected, filter should be done previously.");
        }

        await this._parcelizationHandler.handle(new CustomParcelizationCommand(
            this.props.params.operationCode,
            +this.props.params.batchId,
            deliveryOrderList,
            preparationMode,
            deliveryOrderListValidator,
        ), async () => await this.reloadDeliveryOrderList());

    }

    private async startArticleStockout(deliveryOrderList: DeliveryOrder[]) {
        if (!deliveryOrderList || deliveryOrderList.length === 0) {
            throw new Error("No data selected, filter should be done previously.");
        }

        stockoutProductModel.openModalForDeliveryOrderIds(deliveryOrderList.map(d => d.id),
            this.getProductsList(deliveryOrderList, "quantityAvailable"));
    }

    protected get isSelectionEnabled() {
        return context.hasPermission(Permission.FrontWrite);
    }

    protected get allowOrderPendingCancellationFiltering() {
        return true;
    }
}

export const BatchPreparation =  withRouter(BatchPreparationComponent);

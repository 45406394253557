import * as React from "react";

import { IconButton, TableCell, TableRow } from "@mui/material";

import { ListSort } from "../../../../common/component/list/search-list/list-sort";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { observer } from "mobx-react";
import { PalletsListItem } from "./pallets-list-item";
import { PalletFilter } from "./pallet-filter";
import { PalletsListSubItem } from "./pallets-list-sub-item";

@observer
export class PalletsListSort extends ListSort<PalletsListItem, PalletsListSubItem, PalletFilter, {}> {

    public render() {
        return [<TableRow className="level1 header" key="do-sort-1" data-testid="sort-header">
            <TableCell>
                <IconButton>
                    <SwapVertIcon></SwapVertIcon>
                </IconButton>
            </TableCell>
            <TableCell className="cellHeader" colSpan={10 + this.props.searchListModel.additionalFields.length}>
                <div className="level1-field-container">
                    {this.renderHeaderSort("model.pallet.code", "codeNumeric")}
                    {this.renderHeaderSort("model.pallet.carrier", "carrier")}
                    {this.renderHeaderSort("model.pallet.country", "country")}
                    {this.renderHeaderSort("model.pallet.parcelCount", "parcelCount")}
                </div>
            </TableCell>
        </TableRow >];
    }
}

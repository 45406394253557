import { RefObject, useEffect } from "react";
import { BrowserQRCodeReader } from "@zxing/library";

export const useZxingQrCodeDetector = (cameraDeviceId: string,
                                       videoElement: RefObject<HTMLVideoElement>,
                                       onValueScanned: (value: string) => void) => {
    const cameraCodeReader = new BrowserQRCodeReader();

    return useEffect(() => {
        if (!videoElement.current) {
            return;
        }

        (async () => {
            await cameraCodeReader.decodeFromVideoDevice(cameraDeviceId, videoElement.current,
                (result, _error) => {
                    if (result) {
                        onValueScanned(result.getText());
                    }
                });
        })();

        return () => cameraCodeReader.reset();
    }, [cameraDeviceId, videoElement]);
};

import { FileResponse } from "./file-response";
import { assign } from "lodash";
import { domain } from "../../common/dto/dto-annotation";
import { identifier } from "../../domain/identifier";
import { label50 } from "../../domain/label";
import { PickAndPackStatus } from "../constants/constants";

export class PickAndPackCommandReturn {

    constructor(result?: PickAndPackCommandReturn) {
        assign(this, result);

        this.labelResult = new FileResponse(result!.labelResult);
    }

    @domain(label50)
    public status: PickAndPackStatus;

    @domain(identifier)
    public parcelId: number;

    @domain(identifier)
    public area: number;

    public labelResult: FileResponse;
}

import { domain} from "../../common/dto/dto-annotation";
import { label50} from "../../domain/label";
import { PalletCounterType } from "../constants/constants";
import { quantity } from "../../domain/quantity";
import { assign } from "lodash";

export interface IWarehousePalletCounters {
    warehouseId: string;
    counters: PalletCounter[];
}

export class PalletCounter {
    constructor(palletCounter?: PalletCounter) {
        assign(this, palletCounter);
    }

    @domain(label50)
    public counterName: PalletCounterType;

    @domain(quantity)
    public counterValue: number;
}

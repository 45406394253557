import * as React from "react";

import { observer } from "mobx-react";
import { printerProgressDrawerModel } from "./printer-progress-drawer-model";
import { t } from "i18next";
import { ProgressDrawer } from "../../../../common/component/progress-drawer/progress-drawer";
import { ProgressDrawerSummary } from "../../../../common/component/progress-drawer/progress-drawer-summary";

@observer
export class PrinterProgressDrawer extends React.Component<{}, {}> {

    public render() {
        return <ProgressDrawer
            onClose={() => printerProgressDrawerModel.cancelAndClosePrint()}
            onCancel={() => printerProgressDrawerModel.cancelPrint()}
            current={printerProgressDrawerModel.counter}
            errors={printerProgressDrawerModel.errorList}
            total={printerProgressDrawerModel.totalItemToPrint}>
            {printerProgressDrawerModel.nbParcelToPrint > 0 && <ProgressDrawerSummary
                total={printerProgressDrawerModel.nbParcelToPrint}
                successful={printerProgressDrawerModel.nbParcelPrinted}
                failed={printerProgressDrawerModel.nbErrorParcel}
                successfulTitle={t("print.counter.nbParcelProcessed")}
                failedTitle={t("print.counter.nbParcelNotProcessed")}
            />}
            {printerProgressDrawerModel.nbPreparationOrderToPrint > 0 && <ProgressDrawerSummary
                total={printerProgressDrawerModel.nbPreparationOrderToPrint}
                successful={printerProgressDrawerModel.nbPreparationOrderPrinted}
                failed={printerProgressDrawerModel.nbErrorPreparationOrder}
                successfulTitle={t("print.counter.nbPreparationOrderProcessed")}
                failedTitle={t("print.counter.nbPreparationOrderNotProcessed")}
            />}
        </ProgressDrawer>;
    }
}

import { IPrinterDevice, PrinterDevice } from "./printer-device";
import { t } from "i18next";

export interface IMobileLoginQrCode {
    userName: string;
    token: string;
    printer: IPrinterDevice;
}

export class MobileLoginQrCode {
    private readonly _userName: string;
    private readonly _mobileToken: string;
    private readonly _printer?: PrinterDevice;

    constructor(userName: string, mobileToken: string, printer?: PrinterDevice) {
        this._userName = userName;
        this._mobileToken = mobileToken;
        this._printer = printer;
    }

    public static fromJSON(rawData: string) {
        let data;
        try {
            data = JSON.parse(rawData) as IMobileLoginQrCode;
        } catch (_err) {
            throw new Error(t("components.scanAndShip.login.error.invalidQrCode"));
        }

        if (!data.userName || !data.token) {
            throw new Error(t("components.scanAndShip.login.error.invalidQrCode"));
        }

        return new MobileLoginQrCode(data.userName, data.token,
            data.printer ? PrinterDevice.fromData(data.printer) : undefined);
    }

    public toData() {
        return {
            userName: this._userName,
            token: this._mobileToken,
            printer: this._printer?.toData(),
        } as IMobileLoginQrCode;
    }

    get userName(): string {
        return this._userName;
    }

    get mobileToken(): string {
        return this._mobileToken;
    }

    get printer(): PrinterDevice | undefined {
        return this._printer;
    }
}
